import React from 'react';
import { makeSelectAuthTransactionById } from 'src/selectors/authTransactions';
import { connect } from 'react-redux';
import * as graphAuthUsersAction from 'src/actions/graphAuthUsers';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import AuthUserInAnotherSpaceWarningModal from 'src/components/modals/AuthUserInAnotherSpaceWarningModal';

const FacebookAuthUserInAnotherSpaceAuthTransactionWarningIngModal = (props) => {
    const handleCompleteAuthenticationButtonClick = () => {
        const {
            authTransaction,
            identifier,
            authTransactionRequirementIds,
            shortLiveAccessToken,
            facebookAuthUserForceConnectRequestAction,
            facebookAuthUserExternalForceConnectRequestAction,
            isPublic,
            profileIds,
            adAccountIds
        } = props;

        if (isPublic) {
            facebookAuthUserExternalForceConnectRequestAction(
                authTransaction.hash,
                identifier,
                authTransaction.id,
                authTransactionRequirementIds,
                shortLiveAccessToken,
                profileIds,
                adAccountIds
            );
        } else {
            facebookAuthUserForceConnectRequestAction(
                identifier,
                authTransaction.id,
                authTransactionRequirementIds,
                shortLiveAccessToken,
                profileIds,
                adAccountIds
            );
        }
    };

    const { hideModal, enforceFocus } = props;
    return (
        <AuthUserInAnotherSpaceWarningModal
          onCompleteAuthenticationClick={handleCompleteAuthenticationButtonClick}
          networkType="graph"
          enforceFocus={enforceFocus}
          hideModal={hideModal}
        />
    );
};

FacebookAuthUserInAnotherSpaceAuthTransactionWarningIngModal.propTypes = {
    authTransaction: customPropTypes.authTransaction.isRequired,
    facebookAuthUserForceConnectRequestAction: PropTypes.func.isRequired,
    facebookAuthUserExternalForceConnectRequestAction: PropTypes.func.isRequired,
    identifier: PropTypes.string.isRequired,
    authTransactionRequirementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    shortLiveAccessToken: PropTypes.string.isRequired,
    isPublic: PropTypes.string.isRequired,
    profileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    adAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool
};

const makeMapStateToProps = () => {
    const selectAuthTransactionById = makeSelectAuthTransactionById();
    return (state, ownProps) => ({
        authTransaction: selectAuthTransactionById(state, ownProps.authTransactionId)
    });
};

export default connect(makeMapStateToProps, {
    facebookAuthUserForceConnectRequestAction: graphAuthUsersAction.facebookAuthUserForceConnectRequest,
    facebookAuthUserExternalForceConnectRequestAction: graphAuthUsersAction.facebookAuthUserExternalForceConnectRequest
})(FacebookAuthUserInAnotherSpaceAuthTransactionWarningIngModal);
