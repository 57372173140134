import React, { forwardRef, useState } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import NetworkIcon from 'src/components/NetworkIcon';
import ProfileSelectorLinkConnectionStatus from 'src/components/profileSelector/ProfileSelectorLinkConnectionStatus';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/profileSelectorLink.scss';
import Checkbox from 'src/components/forms/inputs/Checkbox';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import { Highlighter } from 'react-bootstrap-typeahead';
import Button from 'src/components/buttons/Button';

const ProfileSelectorAdAccountLink = forwardRef((props, ref) => {
    const {
        label, appendix, active, onClick, adAccount, selected, highlight, onOnlyClick
    } = props;

    const { useCases: requiredUseCases, accountUseCasesAuthUsers } = adAccount;
    const [hover, setHover] = useState(false);

    return (
        <a
          href="#"
          onClick={(event) => { onClick(event); event.preventDefault(); }}
          className={classnames(styles.link, { [styles.active]: active })}
          ref={ref}
          onMouseEnter={() => { setHover(true); }}
          onMouseLeave={() => { setHover(false); }}
        > <div className={styles.checkbox}><Checkbox readOnly checked={selected} /></div>
            <NetworkIcon
              platformType={adAccount.platformType}
              className={styles.icon}
            />

            <div className={styles.ellipsisWrapper}>
                <DivWithTooltip
                  tooltip={label}
                  className={classnames(styles.name, { [styles.withAppendix]: !!appendix })}
                >
                    <span dir="auto">
                        <Highlighter search={highlight}>{label}</Highlighter>
                    </span>
                </DivWithTooltip>
                {
                    appendix
                    && (
                        <DivWithTooltip
                          tooltip={appendix}
                          className={styles.appendix}
                        >
                            <Highlighter search={highlight}>{appendix}</Highlighter>
                        </DivWithTooltip>
                    )
                }
            </div>
            {
                requiredUseCases.length > 0
                && (
                <div className={styles.privateStatistic}>
                    <ProfileSelectorLinkConnectionStatus
                      requiredUseCases={requiredUseCases}
                      accountUseCasesAuthUsers={accountUseCasesAuthUsers}
                    />
                </div>
                )
            }
            {
                hover
                && (
                    <div className={styles.hoverMenu}>
                        <Button small label="Only" onClick={() => { onOnlyClick('adAccount', adAccount.id); }} />
                    </div>
                )
            }
        </a>
    );
});

ProfileSelectorAdAccountLink.propTypes = {
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
    label: PropTypes.string.isRequired,
    adAccount: customPropTypes.adAccount.isRequired,
    appendix: PropTypes.string,
    selected: PropTypes.bool,
    highlight: PropTypes.string,
    onOnlyClick: PropTypes.func.isRequired
};

ProfileSelectorAdAccountLink.defaultProps = {
    active: false,
    appendix: '',
    selected: false,
    highlight: ''
};

export default ProfileSelectorAdAccountLink;
