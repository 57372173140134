import React from 'react';
import styles from 'src/stylesheets/profile/profileNameAndMetaInfo.scss';
import classnames from 'classnames';
import NetworkIcon from 'src/components/NetworkIcon';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import withTooltip from 'src/components/withToolTip';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import PlatformProfileInfoButtons from 'src/components/profile/PlatformProfileInfoButtons';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const ProfileNameAndMetaInfo = (props) => {
    const {
        profile, isLarge, showPlatformProfileInfo, showAppendix
    } = props;
    const { defaultName, platformType } = profile;
    const appendix = _get(profile, 'appendix', null);
    const verified = _get(profile, 'verified', false);

    return (
        <div className={styles.wrapper}>
            <div className={styles.networkIconWrapper}>
                <NetworkIcon
                  platformType={platformType}
                  className={styles.networkIcon}
                />
            </div>
            <div className={classnames(styles.name, { [styles.large]: isLarge })}>
                <div className={styles.profileName}>
                    <DivWithTooltip
                      tooltip={defaultName}
                      className="profileNameForHover"
                    >
                        <span dir="auto">{defaultName}</span>
                    </DivWithTooltip>
                </div>
                {
                    (appendix !== null && showAppendix)
                    && (
                        <div className={styles.appendix}>
                            {appendix}
                        </div>
                    )
                }
                {
                    verified
                    && (
                        <div className={styles.iconWrapper}>
                            <IcomoonIconWithTooltip icon="verified" className={styles.verifiedIcon} tooltip="This is a verified profile" />
                        </div>
                    )
                }
                {
                    showPlatformProfileInfo
                    && (
                        <div className={classnames(styles.platformProfileInfoButtons)}>
                            <PlatformProfileInfoButtons profile={profile} />
                        </div>
                    )
                }
            </div>
        </div>
    );
};

ProfileNameAndMetaInfo.propTypes = {
    profile: PropTypes.oneOfType([
        customPropTypes.profile,
        customPropTypes.searchedProfile
    ]).isRequired,
    isLarge: PropTypes.bool,
    showPlatformProfileInfo: PropTypes.bool,
    showAppendix: PropTypes.bool
};

ProfileNameAndMetaInfo.defaultProps = {
    isLarge: false,
    showPlatformProfileInfo: false,
    showAppendix: true
};

export default ProfileNameAndMetaInfo;
