import React, { useEffect, useState } from 'react';
import { getDateConfigByString, isDynamicDateInPresets } from 'src/components/dateSelector/utils';
import PropTypes from 'prop-types';
import TextWithResetIcon from 'src/components/forms/inputs/TextWithResetIcon';

import * as customPropTypes from 'src/customPropTypes';

const defaultValidator = (newDate, newTextValue) => ((newTextValue === '') || !!newDate);

const getTextValues = (validator, value) => {
    const date = getDateConfigByString(value.trim());
    return {
        validCustomPeriodInput: validator(date, value),
        dateEntered: value,
        date
    };
};

const CustomPeriodInputBox = (props) => {
    const {
        selectedDate, validator, active, setActive
    } = props;
    const [value, setValue] = useState('');

    useEffect(() => {
        if (selectedDate.dynamicDate && !isDynamicDateInPresets(selectedDate.dynamicDate)) {
            setValue(selectedDate.dynamicDate);
            setActive(true);
        }
    }, []);

    const { validCustomPeriodInput } = getTextValues(validator, value);
    const setTextValue = (newValue) => {
        setValue(newValue);
    };

    const handleCustomPeriodInputChange = (e) => {
        const { value: newValue } = e.target;
        const { onInputChanged } = props;
        setTextValue(newValue);
        const { validCustomPeriodInput: newValidCustomPeriodInput, date: newDate } = getTextValues(validator, newValue);
        setActive(newValue !== '');
        if (newValidCustomPeriodInput) {
            onInputChanged(newDate);
        }
    };

    const handleOnKeyDown = (e) => {
        const {
            onValidEnterKeyDown
        } = props;
        if (onValidEnterKeyDown && e.keyCode === 13) {
            if (value && validCustomPeriodInput) {
                onValidEnterKeyDown();
                e.preventDefault();
            }
        }
    };

    const onResetFilterClick = () => {
        handleCustomPeriodInputChange({ target: { value: '' } });
    };

    return (
        <TextWithResetIcon
          onChange={handleCustomPeriodInputChange}
          onResetClick={onResetFilterClick}
          value={value}
          placeholder="Custom range"
          error={!validCustomPeriodInput}
          autoFocus
          onKeyDown={handleOnKeyDown}
          active={active}
          onClick={handleCustomPeriodInputChange}
          className="Test"
          layout="dateSelector"
        />
    );
};

CustomPeriodInputBox.propTypes = {
    onInputChanged: PropTypes.func.isRequired,
    validator: PropTypes.func,
    onValidEnterKeyDown: PropTypes.func,
    selectedDate: customPropTypes.selectedDate.isRequired,
    active: PropTypes.bool,
    setActive: PropTypes.func
};

CustomPeriodInputBox.defaultProps = {
    validator: defaultValidator,
    active: false,
    setActive: () => {}
};

export default CustomPeriodInputBox;
