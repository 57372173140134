import FolderName from 'src/components/views/folders/FolderName';
import PropTypes from 'prop-types';
import React from 'react';
import { SUPER_ROOT } from 'src/utils/dashboardFolders';

const FolderBrowserTitle = ({ toFolderId, actionName }) => (
    <>
        { toFolderId !== SUPER_ROOT && <> {actionName} {' to ' }</>}<FolderName folderId={toFolderId} />
    </>
);

FolderBrowserTitle.propTypes = {
    toFolderId: PropTypes.string.isRequired,
    actionName: PropTypes.string
};

FolderBrowserTitle.defaultProps = {
    actionName: 'Move'
};

export default FolderBrowserTitle;
