import React, { forwardRef } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/listItemWithActiveStateHandling.scss';
import withScrollIntoViewOfScrollParent from 'src/components/withScrollIntoViewOfScrollParent';

const ListItemWithActiveStateHandling = forwardRef((props, ref) => {
    const { children, active, onClick } = props;
    return (
        <div
          ref={ref}
          className={classnames(styles.listItem, { [styles.active]: active })}
          onClick={onClick}
        >
            {children}
        </div>
    );
});

ListItemWithActiveStateHandling.propTypes = {
    children: customPropTypes.children.isRequired,
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};

export default withScrollIntoViewOfScrollParent(ListItemWithActiveStateHandling);
