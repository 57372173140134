import * as customPropTypes from 'src/customPropTypes';
import { Checkbox } from 'src/components/forms/fields';
import classnames from 'classnames';
import { Field } from 'redux-form';
import IcomoonIcon from 'src/components/IcomoonIcon';
import { Checkbox as InputCheckbox } from 'src/components/forms/inputs';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/profileUseCasesTable.scss';
import UseCaseDescriptionPopover from 'src/components/popovers/UseCaseDescriptionPopover';
import withPopover from 'src/components/withPopover';
import { MAX_NUMBER_OF_COLUMNS } from 'src/utils/accountInsights';
import ListTableCell from 'src/components/listTable/ListTableCell';
import EmptyCells from 'src/components/listTable/EmptyCells';

const IcomoonIconWithPopover = withPopover(IcomoonIcon);

const UseCasesTableHeader = (props) => {
    const {
        useCaseFormFields,
        disabled,
        submitting,
        className
    } = props;
    const disabledColumnsLength = MAX_NUMBER_OF_COLUMNS - useCaseFormFields.length;

    return (
        <>
            {
                useCaseFormFields.map((field) => (
                    <ListTableCell isHeaderCell className={classnames(styles.column, className)} key={field.id} isHeaderViewLayout={false}>
                        <div className={styles.useCase}>
                            <div className={styles.field}>
                                <Field
                                  name={field.name}
                                  component={Checkbox}
                                  warning={field.hasWarning}
                                  disabled={disabled || submitting}
                                />
                            </div>
                            <div className={classnames(styles.labelAndIconHelp, { [styles.disabled]: disabled })}>
                                <div className={styles.label}>
                                    {field.label}
                                </div>
                                <IcomoonIconWithPopover
                                  icon="info"
                                  className={styles.helpIcon}
                                  overlay={<UseCaseDescriptionPopover useCaseId={field.id} />}
                                  usePortalToRender={false}
                                />
                            </div>
                        </div>
                    </ListTableCell>
                ))
                }
            {
                disabledColumnsLength > 0 && (
                    <EmptyCells emptyColumnLength={disabledColumnsLength} isHeader className={styles.column}>
                        <div className={styles.useCase}>
                            <InputCheckbox disabled />
                            <div className={classnames({ [styles.disabled]: disabled })} />
                        </div>
                    </EmptyCells>
                )
            }
        </>
    );
};

UseCasesTableHeader.propTypes = {
    useCaseFormFields: customPropTypes.useCaseFormFields.isRequired,
    disabled: PropTypes.bool,
    submitting: PropTypes.bool,
    className: PropTypes.string
};

UseCasesTableHeader.defaultProps = {
    disabled: false,
    submitting: false,
    className: ''
};

export default UseCasesTableHeader;
