import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/progressbar.scss';

/* from react-bootstrap */
const ROUND_PRECISION = 1000;
const getPercentage = (now, min, max) => {
    const percentage = ((now - min) / (max - min)) * 100;
    return Math.round(percentage * ROUND_PRECISION) / ROUND_PRECISION;
};

const ProgressBar = (props) => {
    const {
        min,
        now,
        max,
        barColor
    } = props;

    const width = `${getPercentage(now, min, max)}%`;
    const classes = classnames(
        styles.progressBar,
        styles[`progress-bar-${barColor}`]
    );
    return (
        <div className={styles.progressBarWrapper}>
            <div className={classes} style={{ width }} />
        </div>
    );
};

ProgressBar.propTypes = {
    min: PropTypes.number,
    now: PropTypes.number.isRequired,
    max: PropTypes.number,
    barColor: PropTypes.oneOf(['info', 'success', 'warning', 'danger', 'green'])
};

ProgressBar.defaultProps = {
    min: 0,
    max: 100,
    barColor: 'info'
};

export default ProgressBar;
