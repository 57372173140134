import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import FormError from 'src/components/forms/feedback/FormError';
import { Field } from 'redux-form';
import { Checkbox } from 'src/components/forms/fields';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';

const UserNotificationSettingsForm = (props) => {
    const { handleSubmit, error } = props;
    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <Field
              name="sendUserNotificationsViaEmail"
              text="Receive notifications by email for this user account"
              component={Checkbox}
            />

            <p>
                Deselect the checkbox to stop receiving emails from us.
            </p>
        </form>
    );
};

UserNotificationSettingsForm.propTypes = {
    error: customPropTypes.dataLoadingError,
    handleSubmit: PropTypes.func.isRequired
};

export default withReduxForm(UserNotificationSettingsForm);
