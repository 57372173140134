import * as customPropTypes from 'src/customPropTypes';
import AuthUserUseCasesLabel from 'src/components/labels/AuthUserUseCasesLabel';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/profileUseCasesTable.scss';
import UseCaseStatusLabel from 'src/components/labels/UseCaseStatusLabel';
import { MAX_NUMBER_OF_COLUMNS } from 'src/utils/accountInsights';
import ListTableRow from 'src/components/listTable/ListTableRow';
import ListTableCell from 'src/components/listTable/ListTableCell';
import EmptyCells from 'src/components/listTable/EmptyCells';
import classnames from 'classnames';

const getUserUseCase = (userUseCases, useCaseId) => {
    const finalUseCase = userUseCases.filter((useCase) => useCase.useCaseId === useCaseId);
    return finalUseCase.length === 1 ? finalUseCase[0] : false;
};

const isUseCase = (userUseCases, useCaseId) => {
    const finalUseCase = userUseCases.filter((useCase) => useCase.id === useCaseId);
    return finalUseCase.length === 1 ? finalUseCase[0] : false;
};

const AccountUseCasesTableRow = (props) => {
    const {
        accountUseCases, useCaseFormFields, authUser, accountUseCasesAuthUsers, authUsersAuthAccountUseCases
    } = props;
    const emptyColumnLength = MAX_NUMBER_OF_COLUMNS - useCaseFormFields.length;
    return (
        <ListTableRow hasBorder={false}>
            <ListTableCell className={classnames(styles.firstColumn, styles.authUser)}>
                <AuthUserUseCasesLabel
                  userName={authUser.name}
                  valid={authUser.valid}
                />
            </ListTableCell>
            {
                useCaseFormFields.map((field) => {
                    const { id: useCaseId } = field;
                    const isAccountUseCase = isUseCase(accountUseCases, useCaseId);
                    const accountUseCasesAuthUser = getUserUseCase(accountUseCasesAuthUsers, useCaseId);
                    const authUserAuthAccountUseCase = getUserUseCase(authUsersAuthAccountUseCases, useCaseId);

                    if (accountUseCasesAuthUser) {
                        return (
                            <ListTableCell key={useCaseId}>
                                <div className={styles.useCase}>
                                    <UseCaseStatusLabel valid={accountUseCasesAuthUser.valid} invalidReason={accountUseCasesAuthUser.invalidReason} />
                                </div>
                            </ListTableCell>
                        );
                    }
                    if (authUserAuthAccountUseCase && !isAccountUseCase) {
                        return (
                            <ListTableCell key={useCaseId}>
                                <div className={styles.useCase}>
                                    <UseCaseStatusLabel valid />
                                </div>
                            </ListTableCell>
                        );
                    }
                    return (
                        <ListTableCell key={useCaseId}>
                            <div className={styles.useCase}>
                                <div className={styles.unavailable} />
                            </div>
                        </ListTableCell>
                    );
                })
            }
            {
                emptyColumnLength > 0 && <EmptyCells emptyColumnLength={emptyColumnLength} />
            }
        </ListTableRow>
    );
};

AccountUseCasesTableRow.propTypes = {
    useCaseFormFields: customPropTypes.useCaseFormFields.isRequired,
    authUser: PropTypes.oneOfType([customPropTypes.authUser, PropTypes.bool]).isRequired,
    accountUseCasesAuthUsers: customPropTypes.accountUseCasesAuthUsers.isRequired,
    authUsersAuthAccountUseCases: customPropTypes.authUserAuthAccountUseCases.isRequired,
    accountUseCases: customPropTypes.useCases.isRequired
};

export default AccountUseCasesTableRow;
