import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Feedback from 'src/components/feedback/Feedback';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';

const EmptyDashboardExportError = (props) => {
    const { hideModal, enforceFocus } = props;
    return (
        <Modal dataViewName="empty-dashboard-export-warning" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Empty dashboard can{'\''}t be exported
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Feedback
                      responsive={false}
                      content="To export, you will first need to add metrics to this dashboard. You can add metrics from our Discover section."
                      type="warning"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button action label="Close" onClick={hideModal} />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

EmptyDashboardExportError.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool
};

export default EmptyDashboardExportError;
