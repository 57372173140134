import React, { useState } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as linkedInAuthUsersAction from 'src/actions/linkedInAuthUsers';
import { connect } from 'react-redux';
import LinkedInConnectButtonAndState from 'src/components/authTransactions/LinkedInConnectButtonAndState';
import PropTypes from 'prop-types';
import AuthTransactionRequirementsTable from 'src/components/authTransactions/table/AuthTransactionRequirementsTable';
import styles from 'src/stylesheets/authTransactions/authTransactionRequirementsTable.scss';
import classnames from 'classnames';
import _memoize from 'memoize-one';

const LinkedInAuthTransactionRequirementsConnect = (props) => {
    const {
        authTransaction,
        authTransactionRequirementsByProfileId,
        isPublic,
        showOnlyBasicInsightsUseCases,
    } = props;
    const [hovered, changeHoverState] = useState(false);
    const handleOnMountEnter = () => {
        changeHoverState(true);
    };

    const handleOnMountLeave = () => {
        changeHoverState(false);
    };

    const handleLinkedInConnectRequest = (identifier, authTransactionId) => {
        const { linkedInAuthUserConnectRequestAction, linkedInAuthUserExternalConnectRequestAction } = props;
        if (isPublic) {
            linkedInAuthUserExternalConnectRequestAction(authTransaction.hash, identifier, authTransactionId, authTransactionRequirementsByProfileId);
        } else {
            linkedInAuthUserConnectRequestAction(identifier, authTransactionId, authTransactionRequirementsByProfileId);
        }
    };

    const getLinkedInConnectButton = _memoize(() => {
        const buttonsByProfileId = [];
        const profileIds = Object.keys(authTransactionRequirementsByProfileId);
        if (profileIds.length > 0) {
            buttonsByProfileId[profileIds[0]] = (
                <div onMouseEnter={handleOnMountEnter} onMouseLeave={handleOnMountLeave}>
                    <LinkedInConnectButtonAndState
                      authTransaction={authTransaction}
                      connectAction={handleLinkedInConnectRequest}
                    />
                </div>
            );
        }
        return buttonsByProfileId;
    });

    return (
        <div className={classnames({ [styles.highlightRows]: hovered })}>
            <AuthTransactionRequirementsTable
              platformType="linkedIn"
              authTransactionRequirementsByProfileId={authTransactionRequirementsByProfileId}
              showOnlyBasicInsightsUseCases={showOnlyBasicInsightsUseCases}
              connectButtonsByAccountId={getLinkedInConnectButton()}
            />
        </div>
    );
};

LinkedInAuthTransactionRequirementsConnect.propTypes = {
    linkedInAuthUserConnectRequestAction: PropTypes.func.isRequired,
    linkedInAuthUserExternalConnectRequestAction: PropTypes.func.isRequired,
    authTransaction: customPropTypes.authTransaction.isRequired,
    authTransactionRequirementsByProfileId: PropTypes.objectOf(customPropTypes.profileAuthTransactionRequirements).isRequired,
    isPublic: PropTypes.bool.isRequired,
    showOnlyBasicInsightsUseCases: PropTypes.bool.isRequired
};

export default connect(null, {
    linkedInAuthUserConnectRequestAction: linkedInAuthUsersAction.linkedInAuthUserConnectRequest,
    linkedInAuthUserExternalConnectRequestAction: linkedInAuthUsersAction.linkedInAuthUserExternalConnectRequest
})(LinkedInAuthTransactionRequirementsConnect);
