import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { makeSelectOnlyAdAccountById } from 'src/selectors/adAccounts';
import PropTypes from 'prop-types';
import React from 'react';
import AuthTransactionRequirementsRow from 'src/components/authTransactions/table/AuthTransactionRequirementsRow';
import AdAccountInfoListItem from 'src/components/views/adAccounts/AdAccountInfoListItem';

const ProfileAuthTransactionRequirementsRow = (props) => {
    const {
        requirements,
        adAccount,
        platformUseCases,
        connectButton
    } = props;
    return (
        <AuthTransactionRequirementsRow
          accountInfo={<AdAccountInfoListItem adAccount={adAccount} />}
          requirements={requirements}
          platformUseCases={platformUseCases}
          connectButton={connectButton}
        />
    );
};

ProfileAuthTransactionRequirementsRow.propTypes = {
    adAccount: customPropTypes.adAccount.isRequired,
    adAccountId: PropTypes.string.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired,
    connectButton: PropTypes.node,
    requirements: customPropTypes.adAccountAuthTransactionRequirements.isRequired
};

ProfileAuthTransactionRequirementsRow.defaultProps = {
    connectButton: null
};

const makeMapStateToProps = () => {
    const selectOnlyAdAccountById = makeSelectOnlyAdAccountById();
    return (state, ownProps) => ({
        adAccount: selectOnlyAdAccountById(state, ownProps.adAccountId)
    });
};

export default connect(makeMapStateToProps)(ProfileAuthTransactionRequirementsRow);
