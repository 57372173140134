import React from 'react';
import _omit from 'lodash/omit';
import Feedback from 'src/components/feedback/Feedback';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/formFields.scss';

const withAceEditorFormField = (InputComponent) => {
    const WithAceEditorFormField = (props) => {
        const { meta, input, activeOnDirty } = props;
        const { touched, error, dirty } = meta;

        const cleanedProps = _omit(props, 'meta', 'input', 'activeOnDirty');
        return (
            <div className={styles.aceEditorFormField}>
                {
                        touched && error
                        && (
                        <div className={styles.error}>
                            <Feedback content={error} type="error" />
                        </div>
                        )
                    }
                <div className={styles.inputWrapper}>
                    <InputComponent
                      active={activeOnDirty && dirty}
                      error={!!error && !!touched}
                      {...cleanedProps}
                      {...input}
                    />
                </div>
            </div>
        );
    };

    WithAceEditorFormField.propTypes = {
        meta: PropTypes.shape({
            touched: PropTypes.bool,
            error: PropTypes.string,
            dirty: PropTypes.bool
        }).isRequired,
        input: PropTypes.shape({
            value: PropTypes.any,
            name: PropTypes.string.isRequired,
        }).isRequired,
        activeOnDirty: PropTypes.bool
    };

    WithAceEditorFormField.defaultProps = {
        activeOnDirty: false
    };

    return WithAceEditorFormField;
};

export default withAceEditorFormField;
