import React from 'react';
import Sidebar from 'src/components/sidebar/Sidebar';
import styles from 'src/stylesheets/account/accountAddSidebar.scss';
import AccountsTobeAddedInfo from 'src/components/account/layout/AccountsTobeAddedInfo';
import CheckedAccounts from 'src/components/account/layout/CheckedAccounts';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';

const labelByType = {
    profiles: 'Selected profiles',
    adAccounts: 'Selected ad accounts'
};

const AccountAddSidebar = (props) => {
    const {
        renderSelectedAccounts, accountType, accountIds, listName
    } = props;
    return (
        <Sidebar>
            <div className={styles.wrapper}>
                <div className={styles.accountsToBeAddedInfo}>
                    <AccountsTobeAddedInfo accountType={accountType} />
                </div>
                <div className={styles.checkedItems}>
                    <CheckedAccounts
                      checkedValues={accountIds}
                      renderListItem={renderSelectedAccounts}
                      listName={listName}
                      label={labelByType[accountType]}
                    />
                </div>
            </div>
        </Sidebar>
    );
};

AccountAddSidebar.propTypes = {
    accountType: customPropTypes.accountTypes.isRequired,
    accountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    renderSelectedAccounts: PropTypes.func.isRequired,
    listName: PropTypes.string.isRequired
};

export default AccountAddSidebar;
