import { deleteUserRequest } from 'src/actions/users';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { selectIsDeletePending } from 'src/selectors/users';

const UserTransferAndDeleteModal = (props) => {
    const {
        hideModal, email, isDeleting, enforceFocus, deleteUserRequestAction, userId
    } = props;
    return (
        <Modal dataViewName="delete-user" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Permanently Delete user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Do you really want to permanently delete the user (<b>{`${email}`}</b>)?
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button disabled={isDeleting} onClick={hideModal} label="Cancel" />
                        <Button
                          action
                          warning
                          icon="delete"
                          loading={isDeleting}
                          onClick={() => { deleteUserRequestAction(userId); }}
                          label="Delete"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

UserTransferAndDeleteModal.propTypes = {
    userId: PropTypes.string.isRequired,
    deleteUserRequestAction: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    isDeleting: PropTypes.bool,
    enforceFocus: PropTypes.bool
};

UserTransferAndDeleteModal.defaultProps = {
    isDeleting: false
};

function mapStateToProps(state, ownProps) {
    return {
        isDeleting: selectIsDeletePending(state, ownProps.userId)
    };
}

export default connect(mapStateToProps, {
    deleteUserRequestAction: deleteUserRequest
})(UserTransferAndDeleteModal);
