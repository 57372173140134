import * as customPropTypes from 'src/customPropTypes';
import ProfileInfoInDetailPage from 'src/components/profile/ProfileInfoInDetailPage';
import React from 'react';
import styles from 'src/stylesheets/profileDetailsPage.scss';
import ProfileGroupItemList from 'src/components/views/profiles/ProfileGroupItemList';
import ProfileUseCases from 'src/components/ProfileUseCases';
import CustomizeProfile from 'src/components/CustomizeProfile';
import ProfileEvents from 'src/components/profileEvents/ProfileEvents';
import classnames from 'classnames';
import ProfileHealth from 'src/components/profileHealth/ProfileHealth';
import PropTypes from 'prop-types';
import AccountTypeContext from 'src/components/context/AccountTypeContext';
import { profileCustomizeRequestFormAction } from 'src/actions/profiles';
import { getFormNameByAccountNameAndContext } from 'src/utils/accountInsights';

const contextValues = { accountType: 'profiles', isDetailPage: true };

const ProfileDetailsPage = (props) => {
    const { profile, hideViewAllAuthenticationButton } = props;
    return (
        <AccountTypeContext.Provider value={contextValues}>
            <div className={styles.wrapper}>
                <div className={styles.left}>
                    <div className={styles.profileSection}>
                        <div className={styles.profileInfo}>
                            <ProfileInfoInDetailPage profile={profile} />
                        </div>
                        <div className={styles.groupInfo}>
                            <ProfileGroupItemList profileId={profile.id} className={styles.groupListItem} />
                        </div>
                        <CustomizeProfile onSubmit={profileCustomizeRequestFormAction} profileId={profile.id} />
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.profileUseCaseEventAndHealths}>
                        <ProfileUseCases
                          profile={profile}
                          formName={getFormNameByAccountNameAndContext('detail_use_cases', profile.id)}
                          className={styles.spaceProfileUseCaseForm}
                          hideViewAllAuthenticationButton={hideViewAllAuthenticationButton}
                        />
                        <div className={classnames(styles.profileEvents)}>
                            <ProfileEvents profileId={profile.id} />
                        </div>
                        <div className={styles.profileHealth}>
                            <ProfileHealth profile={profile} />
                        </div>
                    </div>
                </div>
            </div>
        </AccountTypeContext.Provider>
    );
};

ProfileDetailsPage.propTypes = {
    profile: customPropTypes.profile.isRequired,
    hideViewAllAuthenticationButton: PropTypes.bool
};

ProfileDetailsPage.defaultProps = {
    hideViewAllAuthenticationButton: false
};

export default ProfileDetailsPage;
