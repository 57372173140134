import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/listOptionsBar.scss';

const ListOptionsBar = ({ filters, sorting }) => (
    <div className={styles.bar}>
        {
            filters && (
                <div className={styles.left}>
                    {filters}
                </div>
            )
        }
        {
            sorting && (
                <div className={styles.right}>
                    {sorting}
                </div>
            )
        }
    </div>
);

ListOptionsBar.propTypes = {
    filters: PropTypes.node,
    sorting: PropTypes.node
};

export default ListOptionsBar;
