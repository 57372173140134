import quintlyLogo from 'src/resources/images/facelift-logo-footer.svg';
import React from 'react';
import styles from 'src/stylesheets/footer.scss';

const Footer = () => (
    <div className={styles.wrapper}>
        <img src={quintlyLogo} alt="empty" />
    </div>
);

export default Footer;
