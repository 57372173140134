import EntityOwnershipNameResolver from 'src/components/onwership/EntityOwnershipNameResolver';
import { isSubmitting, submit } from 'redux-form';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import CreateOrCloneDashboardForm from 'src/components/forms/CreateOrCloneDashboardForm';
import { dashboardAddRequestFormAction } from 'src/actions/dashboard';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';

const formName = 'createDashboard';

const CreateDashboardModal = (props) => {
    const {
        hideModal,
        submitAction,
        submitting,
        enforceFocus,
        folderId,
        jumpToDashboard,
    } = props;

    const initialValues = {
        name: '',
        customLogo: { url: '' },
        folderId,
        jumpToDashboard
    };

    return (
        <Modal onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Create a new <EntityOwnershipNameResolver entityId={folderId} entityType="folder" /> dashboard</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateOrCloneDashboardForm
                      form={formName}
                      onSubmit={dashboardAddRequestFormAction}
                      initialValues={initialValues}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button disabled={submitting} onClick={hideModal} label="Cancel" />
                        <Button action loading={submitting} onClick={() => submitAction(formName)} label="Save" />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

CreateDashboardModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    enforceFocus: PropTypes.bool,
    folderId: PropTypes.string.isRequired,
    jumpToDashboard: PropTypes.bool,
};

CreateDashboardModal.defaultProps = {
    submitting: false,
    enforceFocus: true,
    jumpToDashboard: false
};

const mapStateToProps = (state) => ({
    submitting: isSubmitting(formName)(state)
});

export default connect(mapStateToProps, {
    submitAction: submit
})(CreateDashboardModal);
