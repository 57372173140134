import RelativeTime from 'src/components/RelativeTime';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

const styles = {
    display: 'flex'
};

const ListInfoCreatedBy = ({
    createdTime,
    loggedInUserId,
    cratedByUserId,
    createdByUserName
}) => {
    const userName = loggedInUserId === cratedByUserId ? 'you' : createdByUserName;
    if (createdTime && moment(createdTime).isValid()) {
        return <span style={styles}>Created&nbsp;<RelativeTime date={createdTime} thresholdTimestamp={5 * 86400} />&nbsp;by&nbsp;{userName}</span>;
    }
    return <span>Created by {userName}</span>;
};

ListInfoCreatedBy.propTypes = {
    createdTime: PropTypes.string,
    loggedInUserId: PropTypes.string.isRequired,
    cratedByUserId: PropTypes.string.isRequired,
    createdByUserName: PropTypes.string.isRequired
};

export default ListInfoCreatedBy;
