import React from 'react';
import PropTypes from 'prop-types';
import { makeSelectProfileSearchedById } from 'src/selectors/profileSearch';
import { connect } from 'react-redux';
import ProfileActionButtonWrapper from 'src/components/views/profiles/search/ProfileActionButtonWrapper';
import * as customPropTypes from 'src/customPropTypes';
import SearchedAuthAccountList from 'src/components/account/SearchedAuthAccountList';
import SearchedAuthAccountResultListItem from 'src/components/account/layout/SearchedAuthAccountResultListItem';

const SearchedAuthProfileListItem = (props) => {
    const { searchedProfile } = props;
    return (
        <SearchedAuthAccountResultListItem
          accountType="profiles"
          actionButton={<ProfileActionButtonWrapper searchedProfile={searchedProfile} profile={null} />}
          searchedAccount={searchedProfile}
        />
    );
};

SearchedAuthProfileListItem.propTypes = {
    searchedProfile: customPropTypes.searchedProfile.isRequired
};

const makeMapStateToProps = () => {
    const selectProfileSearchedById = makeSelectProfileSearchedById();
    return (state, ownProps) => {
        const { searchedProfileId } = ownProps;
        const searchedProfile = selectProfileSearchedById(state, searchedProfileId);
        return {
            searchedProfile,
        };
    };
};

const ConnectedSearchedAuthProfileListItem = connect(makeMapStateToProps)(SearchedAuthProfileListItem);

const SearchedAuthProfileList = (props) => {
    const { searchedProfileIds } = props;
    return (
        <SearchedAuthAccountList
          searchedAccountIds={searchedProfileIds}
          renderListItem={(accountId) => <ConnectedSearchedAuthProfileListItem searchedProfileId={accountId} />}
        />
    );
};

SearchedAuthProfileList.propTypes = {
    searchedProfileIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default SearchedAuthProfileList;
