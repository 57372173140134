import * as customPropTypes from 'src/customPropTypes';
import * as youtubeAuthUsersAction from 'src/actions/youtubeAuthUsers';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import YoutubeConnectButtonAndState from 'src/components/authTransactions/YoutubeConnectButtonAndState';
import _memoize from 'memoize-one';
import AuthTransactionRequirementsTable from 'src/components/authTransactions/table/AuthTransactionRequirementsTable';

const YoutubeAuthTransactionRequirementsConnect = (props) => {
    const {
        authTransaction,
        authTransactionRequirementsByProfileId,
        isPublic,
        showOnlyBasicInsightsUseCases
    } = props;

    const handleYoutubeConnectRequest = (identifier, authTransactionId, authTransactionRequirements, profile) => {
        const { youtubeAuthUserConnectRequestAction, youtubeAuthUserExternalConnectRequestAction } = props;
        if (isPublic) {
            youtubeAuthUserExternalConnectRequestAction(authTransaction.hash, identifier, authTransactionId, authTransactionRequirements, profile);
        } else {
            youtubeAuthUserConnectRequestAction(identifier, authTransactionId, authTransactionRequirements, profile);
        }
    };

    const connectButtonPerProfileId = _memoize(() => {
        const buttons = [];
        Object.keys(authTransactionRequirementsByProfileId).forEach((profileId) => {
            buttons[profileId] = (
                <YoutubeConnectButtonAndState
                  authTransaction={authTransaction}
                  profileId={profileId}
                  authTransactionRequirements={authTransactionRequirementsByProfileId[profileId]}
                  connectAction={handleYoutubeConnectRequest}
                />
            );
        });
        return buttons;
    });

    return (
        <AuthTransactionRequirementsTable
          platformType="youtube"
          authTransactionRequirementsByProfileId={authTransactionRequirementsByProfileId}
          connectButtonsByAccountId={connectButtonPerProfileId()}
          showOnlyBasicInsightsUseCases={showOnlyBasicInsightsUseCases}
        />
    );
};

YoutubeAuthTransactionRequirementsConnect.propTypes = {
    youtubeAuthUserConnectRequestAction: PropTypes.func.isRequired,
    youtubeAuthUserExternalConnectRequestAction: PropTypes.func.isRequired,
    authTransaction: customPropTypes.authTransaction.isRequired,
    authTransactionRequirementsByProfileId: PropTypes.objectOf(customPropTypes.profileAuthTransactionRequirements).isRequired,
    isPublic: PropTypes.bool.isRequired,
    showOnlyBasicInsightsUseCases: PropTypes.bool.isRequired
};

export default connect(null, {
    youtubeAuthUserConnectRequestAction: youtubeAuthUsersAction.youtubeAuthUserConnectRequest,
    youtubeAuthUserExternalConnectRequestAction: youtubeAuthUsersAction.youtubeAuthUserExternalConnectRequest,
})(YoutubeAuthTransactionRequirementsConnect);
