import React, { Component } from 'react';
import _range from 'lodash/range';
import PropTypes from 'prop-types';
import Select from 'src/components/forms/inputs/Select';
import styles from 'src/stylesheets/inputs/reportTimeSelector.scss';

const isHour = (hour) => {
    const hourCheck = /^([0-1][0-9]|2[0-3])$/;
    return !!hourCheck.test(hour.trim());
};

const isMinute = (minute) => {
    const minuteCheck = /^(00|15|30|45)$/;
    return !!minuteCheck.test(minute.trim());
};

const getHourAndMinute = (timeValue) => {
    const hourAndMinute = timeValue ? timeValue.trim().split(':') : [];
    const hour = (hourAndMinute[0] && isHour(hourAndMinute[0])) ? hourAndMinute[0] : '00';
    const minute = (hourAndMinute[1] && isMinute(hourAndMinute[1])) ? hourAndMinute[1] : '00';
    return { hour, minute };
};

const renderHours = () => _range(0, 24).map((hour) => {
    const value = hour > 9 ? `${hour}` : `0${hour}`;
    return {
        value,
        name: value
    };
});

class ReportTimeSelector extends Component {
    constructor(props) {
        super(props);
        this.handleOnHourChange = this.handleOnHourChange.bind(this);
        this.handleOnMinuteChange = this.handleOnMinuteChange.bind(this);
    }

    handleOnHourChange(e) {
        const newHour = e.target.value;
        const { value, onChange } = this.props;
        const { minute } = getHourAndMinute(value);
        const newTime = `${newHour}:${minute}`;
        onChange(newTime);
    }

    handleOnMinuteChange(e) {
        const newMinute = e.target.value;
        const { value, onChange } = this.props;
        const { hour } = getHourAndMinute(value);
        const newTime = `${hour}:${newMinute}`;
        onChange(newTime);
    }

    render() {
        const {
            name, value, disabled, error
        } = this.props;
        const { hour, minute } = getHourAndMinute(value);

        return (
            <div className={styles.wrapper}>
                <Select
                  name="hours"
                  value={hour}
                  onChange={this.handleOnHourChange}
                  disabled={disabled}
                  error={error}
                  className={styles.hour}
                >
                    {
                        renderHours().map((elements) => (
                            <option
                              key={elements.value}
                              value={elements.value}
                            >
                                {elements.name}
                            </option>
                        ))
                    }
                </Select>
                <span className={styles.separator}>:</span>
                <Select
                  name="minutes"
                  value={minute}
                  onChange={this.handleOnMinuteChange}
                  disabled={disabled}
                  error={error}
                >
                    <option value="00">00</option>
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="45">45</option>
                </Select>
                <input
                  type="hidden"
                  name={name}
                  value={JSON.stringify(value)}
                />
            </div>
        );
    }
}

ReportTimeSelector.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    error: PropTypes.bool,
    disabled: PropTypes.bool
};

ReportTimeSelector.defaultProps = {
    error: false,
    disabled: false,
    value: '09:00'
};

export default ReportTimeSelector;
