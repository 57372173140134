import React from 'react';
import PlatformProfileInfoButtons from 'src/components/profile/PlatformProfileInfoButtons';
import ProfileNameAndMetaInfo from 'src/components/profile/ProfileNameAndMetaInfo';
import styles from 'src/stylesheets/profile/profileInfoInDetailPage.scss';
import ProfilePicture from 'src/components/profile/ProfilePicture';
import * as customPropTypes from 'src/customPropTypes';
import { getAudienceSizeAndAddedDateInfo } from 'src/utils/profiles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProfileHealthStatusWarning from 'src/components/profile/UnHealthyProfileIndicator';
import { getAccountOptions } from 'src/selectors/loggedInUser';
import adAccountThumbnail from 'src/resources/icons/ad-account-thumbnail.png';

const AdAccountInfoInDetailPage = (props) => {
    const { adAccount, lazyLoadProfileImage, accountOptions } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.info}>
                <ProfilePicture picture={adAccountThumbnail} lazyLoadProfileImage={lazyLoadProfileImage} isLarge />
                <div className={styles.nameAndMetaInfo}>
                    <ProfileNameAndMetaInfo profile={adAccount} isLarge showAppendix={false} />
                </div>
                <div className={styles.likesAndDate}>
                    {getAudienceSizeAndAddedDateInfo(adAccount, accountOptions)}
                </div>
            </div>
            <div className={styles.buttons}>
                <div className={styles.warningIndicator}>
                    <ProfileHealthStatusWarning profile={adAccount} />
                </div>
                <PlatformProfileInfoButtons profile={adAccount} showExternalLink />
            </div>
        </div>
    );
};

AdAccountInfoInDetailPage.propTypes = {
    lazyLoadProfileImage: PropTypes.bool,
    adAccount: customPropTypes.adAccount.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired
};

AdAccountInfoInDetailPage.defaultProps = {
    lazyLoadProfileImage: false
};

const mapStateToProps = (state) => ({
    accountOptions: getAccountOptions(state)
});

export default connect(mapStateToProps)(AdAccountInfoInDetailPage);
