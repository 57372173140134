import { isSubmitting, submit } from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import DataPushTaskEditForm from 'src/components/forms/dataPushTasks/DataPushTaskEditForm';
import { dataPushTaskUpdateRequestFormAction } from 'src/actions/dataPushTasks';
import { makeSelectDataPushTaskById } from 'src/selectors/dataPushTasks';
import { getDataPushTaskInitialValues } from 'src/utils/dataPushTasks';
import memoize from 'memoize-one';
import withResourceCheck from 'src/components/modals/withResourceCheck';

const memoizedGetDataPushTaskInitialValues = memoize(getDataPushTaskInitialValues);

const formName = 'dataPushTaskEditForm';

const EditDataPushTaskModal = (props) => {
    const {
        hideModal,
        enforceFocus,
        submitAction,
        isUpdating,
        dataPushTask
    } = props;

    const initialValues = memoizedGetDataPushTaskInitialValues(dataPushTask);

    return (
        <Modal dataViewName="edit-data-push-task-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Edit data push task
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body centered>
                    <DataPushTaskEditForm
                      form={formName}
                      onSubmit={dataPushTaskUpdateRequestFormAction}
                      initialValues={initialValues}
                      dataPushTaskType={dataPushTask.type}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          label="Cancel"
                          onClick={hideModal}
                          disabled={isUpdating}
                        />
                        <Button
                          label="Update"
                          action
                          onClick={() => { submitAction(formName); }}
                          loading={isUpdating}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

EditDataPushTaskModal.propTypes = {
    dataPushTaskId: PropTypes.string.isRequired,
    dataPushTask: customPropTypes.dataPushTask.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    isUpdating: PropTypes.bool.isRequired,
};

const makeMapStateToProps = () => {
    const selectDataPushTaskById = makeSelectDataPushTaskById();
    return (state, ownProps) => {
        const dataPushTask = selectDataPushTaskById(state, ownProps.dataPushTaskId);
        return {
            isUpdating: isSubmitting(formName)(state),
            dataPushTask
        };
    };
};

const EditDataPushTaskModalWithResourceCheck = withResourceCheck(EditDataPushTaskModal, 'dataPushTask', 'Data push task');

export default connect(makeMapStateToProps, {
    submitAction: submit
})(EditDataPushTaskModalWithResourceCheck);
