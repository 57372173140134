import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import _omit from 'lodash/omit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

const withReactRouterLinkHandling = (WrappedComponent) => {
    const WithReactRouterLinkHandling = (props) => {
        const { routePushAction, to, onClick } = props;
        const cleanedProps = _omit(props, ['routePushAction', 'to', 'onClick']);

        const onClickCombined = (e) => {
            if (onClick) {
                onClick(e);
            }
            routePushAction(to);
        };
        return <WrappedComponent {...cleanedProps} onClick={onClickCombined} />;
    };

    WithReactRouterLinkHandling.propTypes = {
        routePushAction: PropTypes.func.isRequired,
        to: customPropTypes.linkTo.isRequired,
        onClick: PropTypes.func
    };

    return connect(null, {
        routePushAction: routeActions.push
    })(WithReactRouterLinkHandling);
};

export default withReactRouterLinkHandling;
