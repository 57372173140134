import React from 'react';

const GraphAuthUserInAnotherSpace = () => (
    <span>
        We have detected that this facebook account has already been used to authenticate profiles in a different <a href="https://help.facelift-cloud.com/en/articles/8601528-spaces-feature" target="_blank" rel="noopener noreferrer">space</a> within the quintly app.
        <br />
        <br />
        <b>Why is this a concern?</b><br />
        Your facebook account and the quintly app have a singular relationship. This means that all quintly spaces in which you have authenticated will be able to make use of <b>all</b> private statistics data that you have offered. Make sure that you have considered the implications of this sharing of data.
        <br />
        <br />
        <b>What actions can I take?</b><br />
        Here we offer you the choice to proceed with the authentication, allowing both spaces to access all private data from this facebook account, or to cancel this authentication completely and retry with a different facebook account.
    </span>
);

export default GraphAuthUserInAnotherSpace;
