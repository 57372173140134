import ProfileInfoListItemContainer from 'src/components/views/profiles/ProfileInfoListItemContainer';
import AdAccountInfoListItemContainer from 'src/components/views/adAccounts/AdAccountInfoListItemContainer';
import { connect } from 'react-redux';
import { makeSelectCheckedValues } from 'src/selectors/lists';
import PropTypes from 'prop-types';
import React from 'react';
import withRemoveListItemIcon from 'src/components/withRemoveListItemIcon';
import CheckedAccounts from 'src/components/account/layout/CheckedAccounts';
import { isProfile, isAdAccount, getAccountId } from 'src/utils/accountInsights';

const ProfileInfoListItemWithRemoveListItemIcon = withRemoveListItemIcon(ProfileInfoListItemContainer);
const AdAccountInfoListItemWithRemoveListItemIcon = withRemoveListItemIcon(AdAccountInfoListItemContainer);

const AuthTransactionsCheckedAccounts = (props) => {
    const { accountListName, checkedValues } = props;
    return (
        <CheckedAccounts
          listName={accountListName}
          checkedValues={checkedValues}
          label="Selected accounts"
          renderListItem={(checkedValue) => {
              const accountId = getAccountId(checkedValue);
              if (isProfile(checkedValue)) {
                  return (
                      <ProfileInfoListItemWithRemoveListItemIcon
                        listName={accountListName}
                        fieldName={checkedValue}
                        profileId={accountId}
                      />
                  );
              }
              if (isAdAccount(checkedValue)) {
                  return (
                      <AdAccountInfoListItemWithRemoveListItemIcon
                        listName={accountListName}
                        fieldName={checkedValue}
                        adAccountId={accountId}
                        showThumbnail
                      />
                  );
              }
              return null;
          }}
        />
    );
};

const makeMapStateToProps = () => {
    const selectCheckedValues = makeSelectCheckedValues();
    return (state, ownProps) => ({
        checkedValues: selectCheckedValues(state, ownProps.accountListName)
    });
};

AuthTransactionsCheckedAccounts.propTypes = {
    accountListName: PropTypes.string.isRequired,
    checkedValues: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default connect(makeMapStateToProps)(AuthTransactionsCheckedAccounts);
