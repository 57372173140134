import { makeSelectCurrentProfileLimitCountAndType } from 'src/selectors/profileSearch';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { selectIsBulkProfileAdding } from 'src/selectors/profiles';
import AccountAddButton from 'src/components/buttons/AccountAddButton';
import { listName } from 'src/utils/profileSearch';

const SearchedProfileAddButton = (props) => {
    const {
        searchedProfile,
        isBulkProfileAdding,
        currentProfileLimitCountAndType
    } = props;
    return (
        <AccountAddButton
          accountId={searchedProfile.id}
          isAdding={isBulkProfileAdding}
          currentAccountLimitCountAndType={currentProfileLimitCountAndType}
          listName={listName}
        />
    );
};

SearchedProfileAddButton.propTypes = {
    currentProfileLimitCountAndType: customPropTypes.currentAccountLimitCountAndType.isRequired,
    searchedProfile: customPropTypes.searchedProfile.isRequired,
    isBulkProfileAdding: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectCurrentProfileLimitCountAndType = makeSelectCurrentProfileLimitCountAndType();
    return (state) => ({
        currentProfileLimitCountAndType: selectCurrentProfileLimitCountAndType(state),
        isBulkProfileAdding: selectIsBulkProfileAdding(state)
    });
};

export default connect(makeMapStateToProps)(SearchedProfileAddButton);
