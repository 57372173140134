import React from 'react';
import PropTypes from 'prop-types';
import { addHtmlTagsToKeywordsInText, cleanupHtmlTagsFromText } from 'src/utils/string';

const PostTextHighlighter = (props) => {
    const { phrase, values } = props;
    if (values.length > 0) {
        const cleanedPhrase = cleanupHtmlTagsFromText(phrase);
        const taggedKeywords = addHtmlTagsToKeywordsInText(cleanedPhrase, values, 'b');
        return <div dangerouslySetInnerHTML={{ __html: taggedKeywords }} />;
    }
    return <div>{phrase}</div>;
};

PostTextHighlighter.propTypes = {
    phrase: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default PostTextHighlighter;
