import { isSubmitting, submit } from 'redux-form';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { selectUserNotificationSettingsFormInitialValues } from 'src/selectors/userNotification';
import { updateUserNotificationSettingsRequestFormAction } from 'src/actions/userNotification';
import UserNotificationSettingsForm from 'src/components/forms/UserNotificationSettingsForm';

const formName = 'userNotificationSettings';

const UserNotificationSettingsModal = (props) => {
    const {
        hideModal,
        enforceFocus,
        initialValues,
        submitAction,
        submitting
    } = props;
    return (
        <Modal dataViewName="user-notification-settings" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Notification settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UserNotificationSettingsForm
                      form={formName}
                      onSubmit={updateUserNotificationSettingsRequestFormAction}
                      initialValues={initialValues}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button onClick={hideModal} label="Cancel" />
                        <Button action loading={submitting} onClick={() => submitAction(formName)} label="Save" />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

UserNotificationSettingsModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    submitAction: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    initialValues: PropTypes.shape({
        sendUserNotificationsViaEmail: PropTypes.bool.isRequired
    }).isRequired
};

const mapStateToProps = (state) => ({
    initialValues: selectUserNotificationSettingsFormInitialValues(state),
    submitting: isSubmitting(formName)(state)
});

export default connect(mapStateToProps, {
    submitAction: submit
})(UserNotificationSettingsModal);
