import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import { selectFolderTypeById } from 'src/selectors/ownership';

const OwnershipFolderSharedToPrivateActionHandler = ({
    children, toFolderType, fromFolderType, defaultAction, alternativeAction
}) => {
    let action = defaultAction;
    if (toFolderType && (toFolderType !== fromFolderType)) {
        action = alternativeAction;
    }
    return children(action);
};

OwnershipFolderSharedToPrivateActionHandler.propTypes = {
    children: PropTypes.func.isRequired,
    toFolderType: customPropTypes.ownershipType,
    fromFolderType: customPropTypes.ownershipType.isRequired,
    defaultAction: PropTypes.func.isRequired,
    alternativeAction: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    toFolderType: selectFolderTypeById(state, ownProps.toFolderId),
    fromFolderType: selectFolderTypeById(state, ownProps.fromFolderId)
});

const ConnectedOwnershipFolderSharedToPrivateActionHandler = connect(mapStateToProps)(OwnershipFolderSharedToPrivateActionHandler);

ConnectedOwnershipFolderSharedToPrivateActionHandler.propTypes = {
    toFolderId: PropTypes.string,
    fromFolderId: PropTypes.string.isRequired
};

export default ConnectedOwnershipFolderSharedToPrivateActionHandler;
