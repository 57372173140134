import { isSubmitting, submit } from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { dashboardEditRequestFormAction } from 'src/actions/dashboard';
import EditDashboardForm from 'src/components/forms/EditDashboardForm';
import Modal from 'src/components/modals/layout/Modal';
import { modalsShowDashboardAndFolderDelete } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import React from 'react';
import { selectDashboardById } from 'src/selectors/dashboards';
import { selectLoggedInSpace } from 'src/selectors/spaces';
import { hasDashboardCustomLogo } from 'src/utils/string';

const formName = 'editDashboard';

const EditDashboardModal = (props) => {
    const {
        hideModal,
        submitAction,
        submitting,
        dashboardId,
        dashboard,
        dashboardDeleteAction,
        enforceFocus,
        redirectToDashboards,
        loggedInSpace
    } = props;
    const formNameWithId = `${formName}-${dashboardId}`;

    const hasCustomLogo = hasDashboardCustomLogo(loggedInSpace.customLogo, dashboard.customLogo);
    const initialValues = {
        id: dashboard.id,
        name: dashboard.name,
        customLogo: {
            url: hasCustomLogo ? dashboard.customLogo : '',
            meta: {}
        }
    };

    const buttonsLeft = (
        <ButtonGroup>
            <Button
              disabled={submitting}
              label="Delete"
              warning
              icon="delete"
              onClick={() => dashboardDeleteAction([], [dashboard.id], '0', redirectToDashboards)}
            />
        </ButtonGroup>
    );

    return (
        <Modal dataViewName="edit-dashboard" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Edit report: {dashboard.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditDashboardForm
                      form={formNameWithId}
                      onSubmit={dashboardEditRequestFormAction}
                      initialValues={initialValues}
                    />
                </Modal.Body>
                <Modal.Footer buttonsLeft={buttonsLeft}>
                    <ButtonGroup>
                        <Button disabled={submitting} onClick={hideModal} label="Cancel" />
                        <Button action loading={submitting} onClick={() => submitAction(formNameWithId)} label="Save" />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

EditDashboardModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    dashboardId: PropTypes.string.isRequired,
    dashboard: customPropTypes.dashboard.isRequired,
    dashboardDeleteAction: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    redirectToDashboards: PropTypes.bool,
    loggedInSpace: customPropTypes.space.isRequired
};

EditDashboardModal.defaultProps = {
    submitting: false,
    redirectToDashboards: false
};

const mapStateToProps = (state, ownProps) => ({
    submitting: isSubmitting(`${formName}-${ownProps.dashboardId}`)(state),
    dashboard: selectDashboardById(state, ownProps.dashboardId),
    loggedInSpace: selectLoggedInSpace(state)
});

export default connect(mapStateToProps, {
    submitAction: submit,
    dashboardDeleteAction: modalsShowDashboardAndFolderDelete
})(EditDashboardModal);
