import React from 'react';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Button from 'src/components/buttons/Button';
import Feedback from 'src/components/feedback/Feedback';
import Modal from 'src/components/modals/layout/Modal';
import {
    getQQLSelectorValuesFromStore,
    makeSelectCustomMetricRunQQLState,
    makeSelectCustomMetricServerDataLoadingState
} from 'src/selectors/customMetric';
import { getFormSyncErrors, getFormValues, isSubmitting } from 'redux-form';
import { selectMetricBuilderFormButtonClicked } from 'src/selectors/forms';
import { connect } from 'react-redux';
import * as customMetricActions from 'src/actions/customMetric';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import { formName, isVisualizationOfTypeApi } from 'src/utils/metricBuilder';

const errorStyle = {
    paddingBottom: '20px'
};

const CustomMetricSaveAndSaveAsPartialModal = (props) => {
    const {
        valid,
        qqlRunState,
        customMetricServerDataLoadingState,
        setQQLFormValuesAction,
        formValues,
        hideModal,
        selectedProfilesOrGroups,
        selectedDate,
        selectedPostTag,
        selectedPostText,
        selectedPostTextExclude,
        selectedAdCampaign,
        runQQLLabel,
        renderIfNoError
    } = props;

    const { hasData, error, loading } = customMetricServerDataLoadingState;

    let message = null;
    let buttons = null;
    if (!valid) {
        message = <Feedback type="error" content="The metric contains errors that need to be resolved before saving. Please check the error message in the metric builder." responsive={false} />;
        buttons = <Button onClick={hideModal} label="Okay" action />;
    } else if (qqlRunState) {
        message = 'To save a valid metric, click \'Run QQL\' to test for any errors.';
        const formValueWithisMetricTrue = Object.assign({}, formValues);
        formValueWithisMetricTrue.isMetric = true;
        buttons = (
            <ButtonGroup>
                <Button onClick={hideModal} label="Cancel" />
                <Button
                  action
                  onClick={() => {
                      setQQLFormValuesAction(formValueWithisMetricTrue, {
                          selectedProfilesOrGroups, selectedDate, selectedPostTag, selectedPostText, selectedPostTextExclude, selectedAdCampaign
                      });
                  }}
                  label={runQQLLabel}
                />
            </ButtonGroup>
        );
    } else if (error) {
        message = (
            <div>
                <div style={errorStyle}>To save a valid metric, please solve all errors.</div>
                <Feedback type="error" content={error.message} />
            </div>
        );
        buttons = <Button onClick={hideModal} label="Okay" action />;
    } else if (loading) {
        message = 'Please wait, data is loading.';
        buttons = (
            <Button
              loading={loading}
              label="Loading"
            />
        );
    } else if (hasData) {
        if (isVisualizationOfTypeApi(formValues.visualizationType)) {
            message = <Feedback type="error" content="Visualization type API (raw output) is solely for query testing before using a query with our API and thus a metric cannot be saved using this type." responsive={false} />;
        }
    }

    if (message !== null || buttons !== null) {
        return (
            <>
                <Modal.Body>
                    {
                        message
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        buttons
                    }
                </Modal.Footer>
            </>
        );
    }
    return renderIfNoError();
};

const makeMapStateToProps = () => (state) => {
    const submitting = isSubmitting(formName)(state);
    const buttonClicked = selectMetricBuilderFormButtonClicked(state);
    const selectCustomMetricServerDataLoadingState = makeSelectCustomMetricServerDataLoadingState();
    const selectCustomMetricRunQQLState = makeSelectCustomMetricRunQQLState();
    const {
        selectedProfilesOrGroups, selectedDate, selectedPostTag, selectedPostText, selectedPostTextExclude, selectedAdCampaign
    } = getQQLSelectorValuesFromStore(state);
    return {
        submitting,
        buttonClicked,
        selectedProfilesOrGroups,
        selectedDate,
        selectedPostTag,
        selectedPostText,
        selectedPostTextExclude,
        selectedAdCampaign,
        fieldErrors: getFormSyncErrors(formName)(state),
        formValues: getFormValues(formName)(state),
        customMetricServerDataLoadingState: selectCustomMetricServerDataLoadingState(state),
        qqlRunState: selectCustomMetricRunQQLState(state)
    };
};

CustomMetricSaveAndSaveAsPartialModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    formValues: customPropTypes.metricBuilderFormValues.isRequired,
    setQQLFormValuesAction: PropTypes.func.isRequired,
    customMetricServerDataLoadingState: customPropTypes.customMetricQQLRunStatus.isRequired,
    qqlRunState: PropTypes.bool.isRequired,
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    selectedDate: customPropTypes.selectedDate.isRequired,
    selectedPostTag: customPropTypes.postTagFilter.isRequired,
    selectedPostText: customPropTypes.postTextFilter.isRequired,
    selectedPostTextExclude: customPropTypes.postTextExcludeFilter.isRequired,
    selectedAdCampaign: customPropTypes.adCampaignFilter.isRequired,
    runQQLLabel: PropTypes.string.isRequired,
    renderIfNoError: PropTypes.func.isRequired
};

export default connect(makeMapStateToProps, {
    setQQLFormValuesAction: customMetricActions.setQQLFormValues
})(CustomMetricSaveAndSaveAsPartialModal);
