import {
    getFormValues,
    isSubmitting,
    submit
} from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';
import * as overlayActions from 'src/actions/overlays';
import _includes from 'lodash/includes';
import BackLink from 'src/components/BackLink';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { createAuthTransactionRequestFormAction } from 'src/actions/authTransactions';
import CreateAuthTransactionRequirementsForm from 'src/components/forms/CreateAuthTransactionRequirementsForm';
import { makeSelectInitialFormValues } from 'src/selectors/authTransactionRequirements';
import { makeSelectProfilesByIds } from 'src/selectors/profiles';
import { makeSelectAdAccountsByIds } from 'src/selectors/adAccounts';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import SelectAndDeselectProfileUseCasesButtons from 'src/components/buttons/SelectAndDeselectProfileUseCasesButtons';
import styles from 'src/stylesheets/modals/createAuthTransactionRequirementsModal.scss';
import PrivateStatisticUseCasesInfo from 'src/components/modals/authTransactionSteps/PrivateStatisticUseCasesInfo';
import Separator from 'src/components/header/layout/Separator';
import { arrangeAccountsByPlatformType, appendBrokenAccountUseCasesInInitialFormValues } from 'src/utils/accountInsights';
import { makeSelectBrokenProfileUseCasesByPlatformType, makeSelectBrokenAdAccountUseCasesByPlatformType } from 'src/selectors/authUsers';

const authTransactionRequirementsForm = 'authTransactionRequirementsForm';
const checkIfAnyFieldValueIsTrue = (fieldValues = {}) => _includes(Object.values(fieldValues), true);

const CreateAuthTransactionRequirementsModal = (props) => {
    const handleHideModal = () => {
        const { hideModal } = props;
        hideModal();
    };

    const handleBackButton = () => {
        const {
            modalsShowCreateAuthTransactionSelectProfiles,
            hideModal,
            checkedProfileIds,
            checkedAdAccountIds,
            selectBrokenProfileUseCases
        } = props;
        modalsShowCreateAuthTransactionSelectProfiles(checkedProfileIds, checkedAdAccountIds, selectBrokenProfileUseCases);
        hideModal();
    };

    const {
        profilesByPlatformType,
        adAccountsByPlatformType,
        formValues,
        submitAction,
        isFormSubmitting,
        initialFormValues
    } = props;
    const shouldContinue = checkIfAnyFieldValueIsTrue(formValues);
    const backCLick = <BackLink text="Back" disabled={isFormSubmitting} onClick={handleBackButton} />;

    return (
        <Modal dataViewName="create-auth-transaction-detail-step1-modal" onHide={handleHideModal}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={handleHideModal} leftContent={backCLick}>
                    <div className={styles.title}>
                        <Modal.Title>
                            <div className={styles.title}>
                                Select private stats use cases to grant for authentication
                            </div>
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <div className={styles.wrapper}>
                    <div className={styles.body}>
                        <div className={styles.header}>
                            <div className={styles.left} />
                            <div className={styles.right}>
                                <PrivateStatisticUseCasesInfo />
                                <Separator />
                                <SelectAndDeselectProfileUseCasesButtons formName={authTransactionRequirementsForm} />
                            </div>
                        </div>
                        <CreateAuthTransactionRequirementsForm
                          profilesByPlatformType={profilesByPlatformType}
                          adAccountsByPlatformType={adAccountsByPlatformType}
                          form={authTransactionRequirementsForm}
                          onSubmit={createAuthTransactionRequestFormAction}
                          initialValues={initialFormValues}
                        />
                    </div>
                </div>
                <Modal.Footer>
                    <ButtonGroup>
                        <div className={styles.stepDefinition}>
                            Step 2/ 2 - select use cases
                        </div>
                        <Button
                          action
                          label="Begin authentication"
                          disabled={!shouldContinue}
                          loading={isFormSubmitting}
                          onClick={() => { submitAction(authTransactionRequirementsForm); }}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

CreateAuthTransactionRequirementsModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    checkedProfileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    checkedAdAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    adAccountsByPlatformType: customPropTypes.adAccountsByPlatformType.isRequired,
    profilesByPlatformType: customPropTypes.profilesByPlatformType.isRequired,
    modalsShowCreateAuthTransactionSelectProfiles: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    isFormSubmitting: PropTypes.bool.isRequired,
    formValues: PropTypes.object,
    selectBrokenProfileUseCases: PropTypes.bool,
    initialFormValues: PropTypes.object.isRequired
};

CreateAuthTransactionRequirementsModal.defaultProps = {
    selectBrokenProfileUseCases: true,
    formValues: {}
};

const makeMapStateToProps = () => {
    const selectProfilesByIds = makeSelectProfilesByIds();
    const selectAdAccountsByIds = makeSelectAdAccountsByIds();
    const selectInitialFormValues = makeSelectInitialFormValues();
    const selectBrokenProfileUseCasesByPlatformType = makeSelectBrokenProfileUseCasesByPlatformType();
    const selectBrokenAdAccountUseCasesByPlatformType = makeSelectBrokenAdAccountUseCasesByPlatformType();
    return (state, ownProps) => {
        const formValues = getFormValues(authTransactionRequirementsForm)(state);
        const isFormSubmitting = isSubmitting(authTransactionRequirementsForm)(state);
        const { checkedProfileIds, checkedAdAccountIds, selectBrokenProfileUseCases } = ownProps;
        const profiles = selectProfilesByIds(state, checkedProfileIds);
        const adAccounts = selectAdAccountsByIds(state, checkedAdAccountIds);
        const profilesByPlatformType = arrangeAccountsByPlatformType(profiles);
        const adAccountsByPlatformType = arrangeAccountsByPlatformType(adAccounts);
        const initialFormValues = selectInitialFormValues(state, profilesByPlatformType, adAccountsByPlatformType);
        if (selectBrokenProfileUseCases) {
            const brokenProfileUseCasesByPlatformType = selectBrokenProfileUseCasesByPlatformType(state);
            appendBrokenAccountUseCasesInInitialFormValues(initialFormValues, brokenProfileUseCasesByPlatformType, checkedProfileIds);
            const brokenAdAccountUseCasesByPlatformType = selectBrokenAdAccountUseCasesByPlatformType(state);
            appendBrokenAccountUseCasesInInitialFormValues(initialFormValues, brokenAdAccountUseCasesByPlatformType, checkedAdAccountIds, 'adAccounts');
        }
        return {
            initialFormValues,
            formValues,
            isFormSubmitting,
            profilesByPlatformType,
            adAccountsByPlatformType
        };
    };
};

export default connect(makeMapStateToProps, {
    modalsShowCreateAuthTransactionSelectProfiles: overlayActions.modalsShowCreateAuthTransactionSelectProfiles,
    submitAction: submit
})(CreateAuthTransactionRequirementsModal);
