import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { Field } from 'redux-form';
import _startCase from 'lodash/startCase';
import { makeSelectUserById } from 'src/selectors/users';
import styles from 'src/stylesheets/userTransformForm.scss';
import { connect } from 'react-redux';
import Select from './fields/Select';
import FormError from './feedback/FormError';

const getOptGroupOptions = (spaceId, spaceInfos, notDeletedUserInfo) => {
    const optionsOther = [];
    const optionsSpace = [];
    const noUserAvailable = <option disabled key="not-available" value={-2}>not available</option>;
    Object.keys(notDeletedUserInfo).forEach((userId) => {
        const option = (
            <option key={userId} value={userId}>
                {' '}
                {notDeletedUserInfo[userId]}
            </option>
        );
        if (_has(spaceInfos, [spaceId, 'users', userId])) {
            optionsSpace.push(option);
        } else {
            optionsOther.push(option);
        }
    });

    if (optionsSpace.length < 1) {
        optionsSpace.push(noUserAvailable);
    }
    if (optionsOther.length < 1) {
        optionsOther.push(noUserAvailable);
    }

    return [
        <option key="placeholder" disabled value={-1}>Transfer to...</option>,
        (
            <optgroup key="space-user" label="Users already assigned to this space">
                {optionsSpace}
            </optgroup>
        ),
        (
            <optgroup key="client-user" label="Other users not assigned to this space">
                {optionsOther}
            </optgroup>
        )
    ];
};

const validate = (value) => (!value || value < 0 ? 'Please select a user.' : undefined);

const UserTransferForm = (props) => {
    const {
        handleSubmit, error, transferOptions, userToDelete
    } = props;
    const { ownerShipsPerSpace, spaceInfos, notDeletedUserInfo } = transferOptions;

    const count = Object.keys(ownerShipsPerSpace).length;

    const content = Object.keys(ownerShipsPerSpace).map((spaceId) => {
        const ownerShips = ownerShipsPerSpace[spaceId];
        return (
            <div
              className={styles.formGroup}
              key={spaceId}
            >
                <p className={styles.spaceName}>Space: {_get(spaceInfos, [spaceId, 'name'], spaceId)}</p>
                <div className={styles.entitiesList}>

                    <div>
                        {
                        Object.keys(ownerShips).map((type) => (
                            `${_startCase(type)}: ${ownerShips[type]}`
                        )).join(', ')
                    }
                    </div>
                </div>
                <Field
                  autoFocus
                  validate={validate}
                  name={`space-${spaceId}`}
                  component={Select}
                >
                    {getOptGroupOptions(spaceId, spaceInfos, notDeletedUserInfo)}
                </Field>
            </div>

        );
    });

    const name = `${userToDelete.firstName} ${userToDelete.lastName}`;

    let text = 'Please select a user to transfer their items to.';
    if (count > 1) {
        text = 'Please select a user in each space below to transfer their items to.';
    }

    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <p>{name}&apos;s still has items in their account. {text}</p>
            {
                content
            }
        </form>
    );
};

UserTransferForm.propTypes = {
    error: customPropTypes.dataLoadingError,
    handleSubmit: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    transferOptions: customPropTypes.userTransferOptions.isRequired,
    userToDelete: customPropTypes.user.isRequired
};

const makeMapStateToProps = () => {
    const selectUserById = makeSelectUserById();
    return (state, ownProps) => ({
        userToDelete: selectUserById(state, ownProps.userId)
    });
};

export default withReduxForm(connect(makeMapStateToProps)(UserTransferForm));
