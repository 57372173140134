import { Hue, Saturation } from 'react-color/lib/components/common';
import Button from 'src/components/buttons/Button';
import ChromePointer from 'react-color/lib/components/chrome/ChromePointer';
import ChromePointerCircle from 'react-color/lib/components/chrome/ChromePointerCircle';
import { isValidHex, toState } from 'react-color/lib/helpers/color';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from 'src/stylesheets/customColorPicker.scss';

const DEFAULT_HEX_VALUE = '#2f7ed8';

const getInitialColor = (hexValue) => toState({ hex: isValidHex(hexValue) ? hexValue : DEFAULT_HEX_VALUE }, 0);

class CustomColorPicker extends Component {
    constructor(props) {
        super(props);
        this.handleChangeOfSaturation = this.handleChangeOfSaturation.bind(this);
        this.handleChangeOfHue = this.handleChangeOfHue.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        const { hexValue } = props;
        this.state = {
            color: getInitialColor(hexValue)
        };
    }

    handleChangeOfSaturation(value) {
        const { color } = this.state;
        this.setState({
            color: toState(value, color.oldHue)
        });
    }

    handleChangeOfHue(value) {
        const { color } = this.state;
        this.setState({
            color: toState(value, color.oldHue)
        });
    }

    handleButtonClick() {
        const { onButtonClick } = this.props;
        const { color } = this.state;
        onButtonClick(color.hex);
    }

    render() {
        const { color } = this.state;
        return (
            <div className={styles.wrapper}>
                <div className={styles.body}>
                    <div className={styles.saturationWrapper}>
                        <Saturation
                          onChange={this.handleChangeOfSaturation}
                          hsl={color.hsl}
                          hsv={color.hsv}
                          pointer={ChromePointerCircle}
                        />
                    </div>
                    <div className={styles.hueWrapper}>
                        <Hue hsl={color.hsl} onChange={this.handleChangeOfHue} pointer={ChromePointer} />
                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.footerLeft}>
                        <div className={styles.colorIndicator} style={{ backgroundColor: color.hex }} />
                        <div className={styles.hex}>{color.hex}</div>
                    </div>
                    <div className={styles.footerRight}>
                        <Button action label="Pick" onClick={this.handleButtonClick} />
                    </div>
                </div>
            </div>
        );
    }
}

CustomColorPicker.propTypes = {
    hexValue: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired
};

export default CustomColorPicker;
