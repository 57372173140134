import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { makeSelectAdAccountSearchedById } from 'src/selectors/adAccountSearch';
import SearchedAccountResultListItem from 'src/components/account/layout/SearchedAccountResultListItem';
import SearchedAdAccountAddButton from 'src/components/views/adAccounts/search/SearchedAdAccountAddButton';
import { makeSelectAdAccountByTempId } from 'src/selectors/adAccounts';
import AdAccountInfoListItem from 'src/components/views/adAccounts/AdAccountInfoListItem';

const SearchedAdAccountResultListItem = (props) => {
    const {
        searchedAdAccount, adAccount, first, last
    } = props;

    return (
        <SearchedAccountResultListItem
          actionButton={<SearchedAdAccountAddButton searchedAdAccount={searchedAdAccount} adAccount={adAccount} />}
          accountInfo={<AdAccountInfoListItem adAccount={searchedAdAccount} showThumbnail={false} />}
          first={first}
          last={last}
        />
    );
};

SearchedAdAccountResultListItem.propTypes = {
    searchedAdAccount: customPropTypes.searchedAdAccount.isRequired,
    adAccount: customPropTypes.adAccount.isRequired,
    first: PropTypes.bool,
    last: PropTypes.bool
};

SearchedAdAccountResultListItem.defaultProps = {
    first: false,
    last: false,
};

const makeMapStateToProps = () => {
    const selectAdAccountSearchedById = makeSelectAdAccountSearchedById();
    const selectAdAccountByTempId = makeSelectAdAccountByTempId();
    return (state, ownProps) => {
        const { searchedAccountId } = ownProps;
        return {
            searchedAdAccount: selectAdAccountSearchedById(state, searchedAccountId),
            adAccount: selectAdAccountByTempId(state, searchedAccountId)
        };
    };
};

export default connect(makeMapStateToProps)(SearchedAdAccountResultListItem);
