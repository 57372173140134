import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import styles from 'src/stylesheets/modal.scss';

const ModalTitle = ({ children }) => <div className={styles.title}>{children}</div>;

ModalTitle.propTypes = {
    children: customPropTypes.children.isRequired
};

export default ModalTitle;
