import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { dataPushTaskScheduleNowRequest } from 'src/actions/dataPushTasks';

const DataPushTaskScheduleNowModal = (props) => {
    const { hideModal, enforceFocus } = props;

    const handleScheduleNow = () => {
        const { dataPushTaskId, dataPushTaskScheduleNowRequestAction } = props;
        dataPushTaskScheduleNowRequestAction(dataPushTaskId);
        hideModal();
    };

    return (
        <Modal dataViewName="data-push-task-schedule-now-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Schedule task
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        By confirming, your data push task will be scheduled for execution. Please note that it can take up to 15 minutes for it to be processed. You can click the refresh button in the top right to refresh the logs and check whether your data push finished.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          label="Cancel"
                          onClick={hideModal}
                        />
                        <Button
                          label="Schedule task"
                          action
                          onClick={handleScheduleNow}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

DataPushTaskScheduleNowModal.propTypes = {
    dataPushTaskId: PropTypes.string.isRequired,
    dataPushTaskScheduleNowRequestAction: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired
};

export default connect(null, {
    dataPushTaskScheduleNowRequestAction: dataPushTaskScheduleNowRequest
})(DataPushTaskScheduleNowModal);
