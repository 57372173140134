import ProgressBar from 'src/components/ProgressBar';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/progressBarWithLabels.scss';

const ProgressBarWithLabels = (props) => {
    const {
        label,
        usage,
        limit,
        barColor
    } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.labelBar}>
                <div className={styles.label}>{label}</div>
                <div className={styles.usage}>{usage} / {limit}</div>
            </div>
            <div className={styles.progressBar}>
                <ProgressBar
                  now={usage}
                  max={limit}
                  barColor={barColor}
                />
            </div>
        </div>
    );
};

ProgressBarWithLabels.propTypes = {
    label: PropTypes.string,
    usage: PropTypes.number.isRequired,
    limit: PropTypes.number,
    barColor: PropTypes.string
};

ProgressBarWithLabels.defaultProps = {
    limit: 100,
    label: '',
    barColor: 'info'
};

export default ProgressBarWithLabels;
