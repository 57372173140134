import * as customPropTypes from 'src/customPropTypes';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';

const AccountDeletionForm = (props) => {
    const { handleSubmit, error } = props;
    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <div>
                Do you really want to delete your quintly account?
            </div>
        </form>
    );
};

AccountDeletionForm.propTypes = {
    handleSubmit: PropTypes.func,
    error: customPropTypes.dataLoadingError
};

export default withReduxForm(AccountDeletionForm, {
    form: 'accountDeletionForm',
});
