import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/copyToClipboard.scss';
import withTooltip from 'src/components/withToolTip';
import withCopyToClipboard from 'src/components/withCopyToClipboard';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const CopyToClipboard = (props) => {
    const {
        onClick, label, small, tooltip, icon, className
    } = props;
    return (
        <a className={classnames(styles.link, { [styles.small]: small, [styles.big]: !small }, className)} onClick={onClick}>
            {
                    tooltip && icon
                    && <IcomoonIconWithTooltip icon={icon} className={styles.icon} tooltip={tooltip} />
                }
            {
                    !tooltip && icon
                    && <IcomoonIcon icon={icon} className={styles.icon} tooltip={tooltip} />
            }
            <div className={styles.label}>{label}</div>
        </a>
    );
};

CopyToClipboard.propTypes = {
    small: PropTypes.bool,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func
};

CopyToClipboard.defaultProps = {
    label: 'Copy link',
    small: true,
    tooltip: '',
    icon: 'link',
    className: '',
    onClick: () => {}
};

export default withCopyToClipboard(CopyToClipboard);
