import _isEqual from 'lodash/isEqual';
import { customRequired } from 'src/components/forms/validators';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'src/components/forms/fields';
import withReduxForm from 'src/components/forms/withReduxForm';

const errorMessage = 'To confirm force archive, please type the text exactly as displayed';
const requiredForce = (value) => customRequired(value, errorMessage);
const confirmForce = (value) => (
    (!_isEqual(value, 'FORCE ARCHIVE')) ? errorMessage : undefined
);

const ForceArchiveForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <Field
              name="confirmInput"
              label="Type FORCE ARCHIVE to confirm"
              component={Text}
              validate={[requiredForce, confirmForce]}
              autoFocus
            />
        </form>
    );
};

ForceArchiveForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired
};

export default withReduxForm(ForceArchiveForm);
