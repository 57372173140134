import { makeSelectAuthTransactionById, selectClosingState } from 'src/selectors/authTransactions';
import * as authTransactionActions from 'src/actions/authTransactions';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Feedback from 'src/components/feedback/Feedback';
import IcomoonIcon from 'src/components/IcomoonIcon';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/modals/externalAuthTransactionCloseModal.scss';

const ExternalAuthTransactionCloseModal = (props) => {
    const {
        hideModal,
        closingState,
        enforceFocus
    } = props;

    const handleAuthTransactionClose = () => {
        const { authTransaction, closeExternalAuthTransactionRequestAction } = props;
        closeExternalAuthTransactionRequestAction(authTransaction.id, authTransaction.hash);
    };
    const { isPending, error, success } = closingState;

    const headerContent = success
        ? <div className={styles.header}><IcomoonIcon icon="success" className={styles.success} /><span>All done</span></div>
        : <div className={styles.header}>Completely finished authenticating?</div>;

    return (
        <Modal dataViewName="auth-external-transaction-close-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal} leftContent={headerContent} />
                <Modal.Body>
                    {
                        success
                        && (
                            <div>
                                Authentication complete, you can now close the page.
                            </div>
                        )
                    }
                    {
                        !success
                        && (
                            <div>
                                <div>
                                    By closing this request the requester will be notified.
                                    Make sure you have completed as many connections as possible before closing this request.
                                </div>
                                <br />
                                <div>
                                    <b>Be aware: </b>
                                    In case you know this link was shared with multiple people, we recommend to keep this request open.
                                    Closing this request will also disallow others to continue authenticating using this link.
                                </div>
                                <div>
                                    {
                                        (!isPending && error)
                                        && <Feedback content={error} type="error" />
                                    }
                                </div>
                            </div>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.footer}>
                        {
                            !success
                            && (
                                <ButtonGroup>
                                    <Button
                                      label="No, keep this request open"
                                      disabled={isPending}
                                      onClick={hideModal}
                                    />
                                    <Button
                                      action
                                      label="Yes, close this request"
                                      loading={isPending}
                                      onClick={handleAuthTransactionClose}
                                    />
                                </ButtonGroup>
                            )
                        }
                    </div>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

ExternalAuthTransactionCloseModal.propTypes = {
    authTransaction: customPropTypes.authTransaction.isRequired,
    closeExternalAuthTransactionRequestAction: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    closingState: customPropTypes.asyncReducerState,
    enforceFocus: PropTypes.bool.isRequired
};

ExternalAuthTransactionCloseModal.defaultProps = {
    closingState: {}
};

const makeMapStateToProps = () => {
    const selectAuthTransactionById = makeSelectAuthTransactionById();
    return (state, ownProps) => {
        const { id } = ownProps;
        const authTransaction = selectAuthTransactionById(state, id);
        const closingState = selectClosingState(state, id);

        return {
            authTransaction,
            closingState
        };
    };
};

export default connect(makeMapStateToProps, {
    closeExternalAuthTransactionRequestAction: authTransactionActions.closeExternalAuthTransactionRequest
})(ExternalAuthTransactionCloseModal);
