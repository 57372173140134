import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/label.scss';

const StateIndicator = (props) => {
    const { state } = props;

    return <div className={classnames(styles.state, { [styles[state]]: true })} />;
};

StateIndicator.propTypes = {
    state: PropTypes.oneOf(['ok', 'warning', 'error', 'on', 'off', 'disabled', 'neutral']).isRequired
};

export default StateIndicator;
