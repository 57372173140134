import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/authTransactions/authTransactionRequirementState.scss';

const AuthTransactionRequirementState = (props) => {
    const { isPending, isFulfilled, className } = props;
    let icon = 'pending';
    if (!isPending) {
        icon = isFulfilled ? 'success' : 'close';
    }
    return (
        <div className={styles.wrapper}>
            <IcomoonIcon className={classnames(styles[icon], className)} icon={icon} />
        </div>
    );
};

AuthTransactionRequirementState.propTypes = {
    isPending: PropTypes.bool.isRequired,
    isFulfilled: PropTypes.bool.isRequired,
    className: PropTypes.string
};

AuthTransactionRequirementState.defaultProps = {
    className: ''
};

export default AuthTransactionRequirementState;
