import React from 'react';
import SearchedAuthAccountList from 'src/components/account/SearchedAuthAccountList';
import PropTypes from 'prop-types';
import SearchedAdAccountAddButton from 'src/components/views/adAccounts/search/SearchedAdAccountAddButton';
import * as customPropTypes from 'src/customPropTypes';
import { makeSelectAdAccountSearchedById } from 'src/selectors/adAccountSearch';
import { connect } from 'react-redux';
import SearchedAuthAccountResultListItem from 'src/components/account/layout/SearchedAuthAccountResultListItem';

const SearchedAuthAdAccountResultListItem = (props) => {
    const { searchedAdAccount } = props;
    return (
        <SearchedAuthAccountResultListItem
          accountType="adAccounts"
          actionButton={<SearchedAdAccountAddButton searchedAdAccount={searchedAdAccount} />}
          searchedAccount={searchedAdAccount}
        />
    );
};

SearchedAuthAdAccountResultListItem.propTypes = {
    searchedAdAccount: customPropTypes.searchedAdAccount.isRequired
};

const makeMapStateToProps = () => {
    const selectAdAccountSearchedById = makeSelectAdAccountSearchedById();
    return (state, ownProps) => {
        const { searchedAdAccountId } = ownProps;
        return {
            searchedAdAccount: selectAdAccountSearchedById(state, searchedAdAccountId)
        };
    };
};

const ConnectedSearchedAuthAdAccountResultListItem = connect(makeMapStateToProps)(SearchedAuthAdAccountResultListItem);

const renderListItem = (adAccountId) => <ConnectedSearchedAuthAdAccountResultListItem searchedAdAccountId={adAccountId} />;

const SearchedAuthAdAccountList = (props) => {
    const { searchedAdAccountIds } = props;
    return (
        <SearchedAuthAccountList
          searchedAccountIds={searchedAdAccountIds}
          renderListItem={renderListItem}
        />
    );
};

SearchedAuthAdAccountList.propTypes = {
    searchedAdAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default SearchedAuthAdAccountList;
