import { connect } from 'react-redux';
import { makeSelectProfileById } from 'src/selectors/profiles';
import { modalsShowProfileDetails } from 'src/actions/overlays';
import AccountListItem from 'src/components/authTransactions/authTransactionSteps/AccountListItem';
import PropTypes from 'prop-types';

const makeMapStateToProps = () => {
    const selectProfileById = makeSelectProfileById();
    return (state, ownProps) => {
        const {
            profileId,
            listName,
            highlight
        } = ownProps;
        const profile = selectProfileById(state, profileId);
        return {
            account: profile,
            listName,
            highlight,
            accountType: 'profiles'
        };
    };
};

const ProfileListItemContainer = connect(makeMapStateToProps, {
    modalsShowProfileDetailsAction: modalsShowProfileDetails
})(AccountListItem);

ProfileListItemContainer.propTypes = {
    profileId: PropTypes.string.isRequired,
    listName: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired
};

export default ProfileListItemContainer;
