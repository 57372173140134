import React, { useEffect } from 'react';
import { adjustPackageEvent } from 'src/actions/appEvents';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Feedback from 'src/components/feedback/Feedback';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';

const SubscriptionModal = (props) => {
    useEffect(() => {
        const { adjustPackageEventAction, packageStatus } = props;
        adjustPackageEventAction(packageStatus);
    }, []);

    const { hideModal } = props;
    const messageContent = <span> Your current package is not able to be adjusted here, our support team would be happy to assist you with any changes to your subscription. Get in touch with them at <a href="mailto:support@facelift-bbt.com">support@facelift-bbt.com</a>.</span>;
    return (
        <Modal dataViewName="subscription" onHide={hideModal}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Change package</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Feedback type="info" content={messageContent} />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button action onClick={hideModal} label="Okay" />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

SubscriptionModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    packageStatus: PropTypes.string.isRequired,
    adjustPackageEventAction: PropTypes.func.isRequired
};

export default connect(null, {
    adjustPackageEventAction: adjustPackageEvent
})(SubscriptionModal);
