import React from 'react';
import { makeSelectUseCasesByIds } from 'src/selectors/useCases';
import { makeSelectPlatformPermissionsByIds } from 'src/selectors/platformPermissions';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import { getUniquePlatformPermissionIds } from 'src/utils/useCases';
import styles from 'src/stylesheets/modals/socialNetworkLoginUseCaseSelectionModal.scss';

const UseCasePermissionNames = (props) => {
    const { platformPermissions } = props;
    return (
        <div>
            <div className={styles.permissionTitle}>
                Permissions Facebook you will be asked for:
            </div>
            <div>
                {
                    platformPermissions.map(({ id, name }) => (
                        <div key={id} className={styles.permissionName}>
                            {name}
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

UseCasePermissionNames.propTypes = {
    useCaseIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    platformPermissions: customPropTypes.platformPermissions.isRequired
};

const makeMapStateToProps = () => {
    const selectUseCasesByIds = makeSelectUseCasesByIds();
    const selectPlatformPermissionsByIds = makeSelectPlatformPermissionsByIds();
    return (state, ownProps) => {
        const useCases = selectUseCasesByIds(state, ownProps.useCaseIds);
        return {
            platformPermissions: selectPlatformPermissionsByIds(state, getUniquePlatformPermissionIds(useCases))
        };
    };
};

export default connect(makeMapStateToProps)(UseCasePermissionNames);
