import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';

const BreadcrumbPopover = ({ items }) => (
    <Popover id="breadCrumbPopover">
        <ButtonGroup vertical>
            {items}
        </ButtonGroup>
    </Popover>
);

BreadcrumbPopover.propTypes = {
    items: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default BreadcrumbPopover;
