import * as customPropTypes from 'src/customPropTypes';
import DashboardInfo from 'src/components/views/dashboards/DashboardInfo';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/dashboardInModalListItem.scss';

const DashboardInModalListItem = (props) => {
    const {
        dashboard, loggedInUser, labelIcon
    } = props;

    return (
        <div className={styles.dashboardInModalItem}>
            <DashboardInfo
              dashboard={dashboard}
              loggedInUser={loggedInUser}
              showLabels={['type', 'createdByUser', 'metricCount']}
              labelIcon={labelIcon}
            />
        </div>
    );
};

DashboardInModalListItem.propTypes = {
    dashboard: customPropTypes.dashboard.isRequired,
    loggedInUser: customPropTypes.user.isRequired,
    labelIcon: PropTypes.string
};

export default DashboardInModalListItem;
