import { isSubmitting, submit } from 'redux-form';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import GoogleBigQueryDestinationCreateTableForm from 'src/components/forms/dataPushTasks/GoogleBigQueryDestinationCreateTableForm';
import { googleBigQueryDestinationCreateTableRequestFormAction } from 'src/actions/dataPushTasks';
import { makeSelectDataPushTaskDestinationById } from 'src/selectors/dataPushTaskDestination';
import * as customPropTypes from 'src/customPropTypes';

const formName = 'googleBigQueryTableCreationForm';

const CreateGoogleBigQueryDestinationTableModal = (props) => {
    const {
        hideModal,
        enforceFocus,
        submitAction,
        isCreating,
        dataPushTaskId,
        destinationId,
        dataPushTaskDestination
    } = props;

    return (
        <Modal dataViewName="create-google-big-query-destination-table-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Create google BigQuery table
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GoogleBigQueryDestinationCreateTableForm
                      form={formName}
                      dataPushTaskDestination={dataPushTaskDestination}
                      onSubmit={googleBigQueryDestinationCreateTableRequestFormAction}
                      initialValues={{
                          destinationId,
                          dataPushTaskId
                      }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          label="Cancel"
                          onClick={hideModal}
                          disabled={isCreating}
                        />
                        <Button
                          label="Create"
                          action
                          onClick={() => { submitAction(formName); }}
                          loading={isCreating}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

CreateGoogleBigQueryDestinationTableModal.propTypes = {
    dataPushTaskId: PropTypes.string.isRequired,
    destinationId: PropTypes.string.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    dataPushTaskDestination: customPropTypes.googleBigQueryDataPushTaskDestination.isRequired,
    isCreating: PropTypes.bool.isRequired
};

const mapStateToProps = () => {
    const selectDataPushTaskDestinationById = makeSelectDataPushTaskDestinationById();
    return (state, ownProps) => ({
        dataPushTaskDestination: selectDataPushTaskDestinationById(state, ownProps.destinationId),
        isCreating: isSubmitting(formName)(state)
    });
};

export default connect(mapStateToProps, {
    submitAction: submit
})(CreateGoogleBigQueryDestinationTableModal);
