import React from 'react';
import Feedback from 'src/components/feedback/Feedback';
import PropTypes from 'prop-types';

const NonOperationalEntitiesDetail = (props) => {
    const { names, actionName, label } = props;
    const content = names.length === 1
        ? `The ${label} ${names[0]} has bindings to a metric in a dashboard, automated report, link export or tag automation rule and can only be ${actionName}d by an admin.`
        : (
            <div>
                {`The following ${label}s have bindings to metrics in a dashboard, automated report, link export or auto-tag rule and can only be ${actionName}d by an admin.`}
                <ul>
                    {
                        names.map((name) => (
                            <li key={name}>{name}</li>
                        ))
                    }
                </ul>
            </div>
        );
    return (
        <Feedback
          content={content}
          type="error"
        />
    );
};

NonOperationalEntitiesDetail.propTypes = {
    names: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.string.isRequired,
    actionName: PropTypes.string.isRequired
};

export default NonOperationalEntitiesDetail;
