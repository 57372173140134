import * as customPropTypes from 'src/customPropTypes';
import AuthTransactionRequirementState from 'src/components/authTransactions/AuthTransactionRequirementState';
import React from 'react';
import styles from 'src/stylesheets/authTransactions/authTransactionRequirementsTable.scss';
import { MAX_NUMBER_OF_COLUMNS } from 'src/utils/accountInsights';
import PropTypes from 'prop-types';
import ListTableCell from 'src/components/listTable/ListTableCell';
import EmptyCells from 'src/components/listTable/EmptyCells';

const getUserUseCase = (userUseCases, useCaseId) => {
    const finalUseCase = userUseCases.filter((useCase) => useCase.useCaseId === useCaseId);
    return finalUseCase.length === 1 ? finalUseCase[0] : false;
};

const AuthTransactionRequirementsColumns = (props) => {
    const {
        platformUseCases,
        requirements
    } = props;

    const emptyColumnLength = MAX_NUMBER_OF_COLUMNS - platformUseCases.length;
    return (
        <>
            {
                platformUseCases.map((platformUseCase) => {
                    const requirement = getUserUseCase(requirements, platformUseCase.id);
                    return (
                        <ListTableCell key={platformUseCase.id}>
                            <div className={styles.useCase}>
                                {
                                    requirement
                                    && (
                                        <AuthTransactionRequirementState
                                          isPending={requirement.isPending}
                                          isFulfilled={requirement.isFulfilled}
                                          className={styles.requirementState}
                                        />
                                    )
                                }
                                {
                                    !requirement
                                    && <div className={styles.unavailable} />
                                }
                            </div>
                        </ListTableCell>
                    );
                })
            }
            {
                emptyColumnLength > 0 && <EmptyCells emptyColumnLength={emptyColumnLength} />
            }
        </>
    );
};

AuthTransactionRequirementsColumns.propTypes = {
    requirements: PropTypes.oneOfType([customPropTypes.adAccountAuthTransactionRequirements, customPropTypes.profileAuthTransactionRequirements]).isRequired,
    platformUseCases: customPropTypes.useCases.isRequired
};

export default AuthTransactionRequirementsColumns;
