import React from 'react';
import AuthUserInAnotherSpaceWarningModal from 'src/components/modals/AuthUserInAnotherSpaceWarningModal';
import { connect } from 'react-redux';
import { socialNetworkSearchGraphForceConnectRequest } from 'src/actions/profileSearch';
import { adAccountSearchGraphAuthUserForceConnectRequest } from 'src/actions/adAccountSearch';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';

const FacebookAuthUserInAnotherSocialNetworkSearchWarningModal = (props) => {
    const handleCompleteAuthenticationButtonClick = () => {
        const {
            accountType,
            socialNetworkSearchGraphForceConnectRequestAction,
            adAccountSearchGraphAuthUserForceConnectRequestAction,
            accessToken
        } = props;

        if (accountType === 'profiles') {
            socialNetworkSearchGraphForceConnectRequestAction(accessToken);
        }

        if (accountType === 'adAccounts') {
            adAccountSearchGraphAuthUserForceConnectRequestAction(accessToken);
        }
    };

    const { hideModal, enforceFocus } = props;
    return (
        <AuthUserInAnotherSpaceWarningModal
          onCompleteAuthenticationClick={handleCompleteAuthenticationButtonClick}
          networkType="graph"
          hideModal={hideModal}
          enforceFocus={enforceFocus}
        />
    );
};

FacebookAuthUserInAnotherSocialNetworkSearchWarningModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    accessToken: PropTypes.string.isRequired,
    socialNetworkSearchGraphForceConnectRequestAction: PropTypes.func.isRequired,
    adAccountSearchGraphAuthUserForceConnectRequestAction: PropTypes.func.isRequired,
    accountType: customPropTypes.accountTypes.isRequired
};

export default connect(null, {
    socialNetworkSearchGraphForceConnectRequestAction: socialNetworkSearchGraphForceConnectRequest,
    adAccountSearchGraphAuthUserForceConnectRequestAction: adAccountSearchGraphAuthUserForceConnectRequest
})(FacebookAuthUserInAnotherSocialNetworkSearchWarningModal);
