import CustomizeAccount from 'src/components/CustomizeAccount';
import { makeSelectProfileById } from 'src/selectors/profiles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const makeMapStateToProps = () => {
    const selectProfileById = makeSelectProfileById();
    return (state, ownProps) => {
        const profile = selectProfileById(state, ownProps.profileId);
        return {
            accountType: 'profile',
            accountId: profile.id,
            appendix: profile.appendix,
            favorite: profile.favorite || false,
            color: profile.color
        };
    };
};

const ConnectedCustomizeProfile = connect(makeMapStateToProps)(CustomizeAccount);

ConnectedCustomizeProfile.propTypes = {
    profileId: PropTypes.string.isRequired
};

export default ConnectedCustomizeProfile;
