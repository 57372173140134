import AdvancedTimezoneContext from 'src/components/context/AdvancedTimezoneContext';
import AdvancedTimezoneSelect from 'src/components/forms/inputs/AdvancedTimezoneSelect';
import React from 'react';
import TimezoneSelect from 'src/components/forms/inputs/TimezoneSelect';
import PropTypes from 'prop-types';

const TimezoneSelection = ({ onChange, timezone }) => (
    <AdvancedTimezoneContext.Consumer>
        {
            (advancedTimezoneValues) => {
                if (!advancedTimezoneValues) {
                    return (
                        <TimezoneSelect
                          value={timezone}
                          onChange={onChange}
                        />
                    );
                }
                const {
                    defaultTimezone,
                    isDefault,
                    onDefaultChange
                } = advancedTimezoneValues;
                return (
                    <AdvancedTimezoneSelect
                      isDefault={isDefault}
                      onDefaultChange={onDefaultChange}
                      defaultTimezone={defaultTimezone}
                      value={timezone}
                      onChange={onChange}
                    />
                );
            }
        }
    </AdvancedTimezoneContext.Consumer>
);

TimezoneSelection.propTypes = {
    onChange: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired
};

export default TimezoneSelection;
