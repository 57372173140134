import { isSubmitting, submit } from 'redux-form';
import { makeSelectDashboardIdsByMetricId, makeSelectDashboardsByIds } from 'src/selectors/dashboards';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import ConfirmDeleteForm from 'src/components/forms/ConfirmDeleteForm';
import { connect } from 'react-redux';
import { customMetricDeleteFormAction } from 'src/actions/customMetric';
import DashboardList from 'src/components/DashboardList';
import { getMetricsWithDashboardUsageByMetricId } from 'src/selectors/metricDashboardUsages';
import { makeSelectMetricById } from 'src/selectors/metrics';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/customMetricDeleteModal.scss';

const formName = 'customMetricDeleteForm';

const CustomMetricDeleteModal = (props) => {
    const {
        hideModal, metricName, isDeleting, enforceFocus, metricId, submitAction, dashboards, numberOfDashboards
    } = props;

    return (
        <Modal dataViewName="delete-custom-metric" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Delete this metric from your account?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        (dashboards.length > 0 || numberOfDashboards > 0)
                            && (
                            <div className={styles.warning}>
                                <span><b>{metricName}</b> will be permanently deleted from the following dashboards:</span>
                                <div className={styles.dashboardList}>
                                    <DashboardList dashboards={dashboards} numberOfDashboards={numberOfDashboards} />
                                </div>
                            </div>
                            )
                    }
                    <ConfirmDeleteForm
                      form={formName}
                      initialValues={{ id: metricId }}
                      onSubmit={customMetricDeleteFormAction}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button onClick={hideModal} label="Cancel" />
                        <Button
                          action
                          warning
                          icon="delete"
                          loading={isDeleting}
                          onClick={() => { submitAction(formName); }}
                          label="Delete"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

CustomMetricDeleteModal.propTypes = {
    metricId: PropTypes.string.isRequired,
    hideModal: PropTypes.func.isRequired,
    metricName: PropTypes.string.isRequired,
    isDeleting: PropTypes.bool,
    enforceFocus: PropTypes.bool,
    submitAction: PropTypes.func.isRequired,
    dashboards: customPropTypes.dashboards.isRequired,
    numberOfDashboards: PropTypes.number
};

CustomMetricDeleteModal.defaultProps = {
    isDeleting: false,
    numberOfDashboards: 0
};

const makeMapStateToProps = () => {
    const selectDashboardIdsByMetricId = makeSelectDashboardIdsByMetricId();
    const selectDashboardsByIds = makeSelectDashboardsByIds();
    const selectMetricById = makeSelectMetricById();

    return (state, ownProps) => {
        const { metricId } = ownProps;
        const metric = selectMetricById(state, metricId);
        const dashboardIds = selectDashboardIdsByMetricId(state, metricId);
        const dashboards = selectDashboardsByIds(state, dashboardIds);
        const numberOfDashboards = getMetricsWithDashboardUsageByMetricId(state, metricId);
        return {
            metricName: metric ? metric.name : '',
            isDeleting: isSubmitting(formName)(state),
            dashboards,
            numberOfDashboards
        };
    };
};

export default connect(makeMapStateToProps, {
    submitAction: submit
})(CustomMetricDeleteModal);
