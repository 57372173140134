import React from 'react';
import { Text, File, Select } from 'src/components/forms/fields';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { required } from 'src/components/forms/validators';
import _has from 'lodash/has';

const fileRequired = (value) => (!_has(value, 'meta.name', false) ? 'Please upload the auth file.' : undefined);

const GoogleBigQueryDataPushTaskFields = (props) => {
    const { googleBigQueryTimePartitioningType, isAuthFileRequired } = props;
    return (
        <div>
            <Field
              autoFocus
              name="googleBigQueryProjectId"
              component={Text}
              label="Project id"
              validate={required}
            />
            <Field
              autoFocus
              name="googleBigQueryDatasetId"
              component={Text}
              label="Dataset id"
              validate={required}
            />
            <Field
              autoFocus
              name="googleBigQueryTableId"
              component={Text}
              label="Table id"
            />
            <Field
              autoFocus
              name="googleBigQueryTimePartitioningType"
              component={Select}
              label="Default time partitioning type"
            >
                <option value="">None</option>
                <option value="DAY">DAY</option>
                <option value="HOUR">HOUR</option>
                <option value="MONTH">MONTH</option>
                <option value="YEAR">YEAR</option>
            </Field>
            {
                googleBigQueryTimePartitioningType && (
                <Field
                  autoFocus
                  name="googleBigQueryTimePartitioningField"
                  component={Text}
                  label="Time partition field"
                />
                )
            }
            <Field
              autoFocus
              name="googleBigQueryAuth"
              component={File}
              label="Auth credential file"
              validate={isAuthFileRequired ? fileRequired : undefined}
            />
        </div>
    );
};

GoogleBigQueryDataPushTaskFields.propTypes = {
    googleBigQueryTimePartitioningType: PropTypes.string,
    isAuthFileRequired: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector(ownProps.form);
    return {
        googleBigQueryTimePartitioningType: selector(state, 'googleBigQueryTimePartitioningType')
    };
};

export default connect(mapStateToProps)(GoogleBigQueryDataPushTaskFields);
