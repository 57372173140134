import AccountDeleteModal from 'src/components/account/layout/AccountDeleteModal';
import { isSubmitting, submit } from 'redux-form';
import { makeSelectAdAccountsByIds } from 'src/selectors/adAccounts';
import { connect } from 'react-redux';
import { adAccountDeleteFormAction } from 'src/actions/adAccounts';

import PropTypes from 'prop-types';

const formName = 'adAccountDeleteForm';

const makeMapStateToProps = () => {
    const selectAdAccountsByIds = makeSelectAdAccountsByIds();
    return (state, ownProps) => {
        const adAccounts = selectAdAccountsByIds(state, ownProps.adAccountIds);
        const accountNames = adAccounts.map((adAccount) => ({ id: adAccount.id, name: adAccount.defaultName }));
        return {
            isAccountDeleting: isSubmitting(formName)(state),
            formAction: adAccountDeleteFormAction,
            accountNames,
            accountIds: ownProps.adAccountIds,
            singularLabel: 'ad account',
            pluralLabel: 'ad accounts',
            formName
        };
    };
};

const AdAccountModal = connect(makeMapStateToProps, {
    submitAction: submit
})(AccountDeleteModal);

AdAccountModal.propTypes = {
    adAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default AdAccountModal;
