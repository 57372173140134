import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import _omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styles from 'src/stylesheets/inputs/input.scss';

const Password = (props) => {
    const {
        error,
        placeholder,
        className,
        disabled
    } = props;
    const [type, setType] = useState('password');
    const ref = useRef();
    const toggle = () => { setType(type === 'password' ? 'text' : 'password'); ref.current.focus(); };

    const placeholderText = !disabled ? placeholder : '';
    const cleanedProps = _omit(props, 'placeholder', 'className', 'error', 'active');
    return (
        <div className={classnames(styles.inputWithResetWrapper, styles.removeItemActive)}>
            <input
              {...cleanedProps}
              type={type}
              placeholder={placeholderText}
              className={classnames(styles.input, { [styles.error]: error }, className)}
              ref={ref}
            />
            <IcomoonIcon
              icon={type === 'password' ? 'show' : 'hide'}
              onClick={toggle}
              className={classnames(styles.resetFilterIcon, styles.showResetFilterIcon)}
            />
        </div>
    );
};

Password.propTypes = {
    placeholder: PropTypes.string,
    error: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool
};

Password.defaultProps = {
    placeholder: '',
    error: false,
    className: '',
    disabled: false
};

export default Password;
