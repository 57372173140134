import * as customPropTypes from 'src/customPropTypes';
import ace from 'ace-builds';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getColumnsByDataSourceId } from 'src/selectors/dataSources';
import { getDataSourcesRequest } from 'src/actions/dataSources';
import PropTypes from 'prop-types';
import 'ace-builds/src-noconflict/ext-language_tools';

let dataSourcesRegistered = false;

function activateDataSourceAutocomplete(dataSourcesAutocompleteList, dataColumnsAutocompleteList) {
    if (dataSourcesAutocompleteList.length > 0 && dataColumnsAutocompleteList.length > 0) {
        const langTools = ace.require('ace/ext/language_tools');
        const dataSourcesCompleter = {
            getCompletions(editor, session, pos, prefix, callback) {
                callback(null, dataSourcesAutocompleteList.map((dSource) => ({
                    caption: dSource,
                    value: dSource,
                    meta: 'Table'
                })));
            }
        };
        const dataColumnsCompleter = {
            getCompletions(editor, session, pos, prefix, callback) {
                callback(null, dataColumnsAutocompleteList.map((dColumn) => ({
                    caption: dColumn,
                    value: dColumn,
                    meta: 'Column'
                })));
            }
        };

        langTools.addCompleter(dataSourcesCompleter);
        langTools.addCompleter(dataColumnsCompleter);
    }
}

function registerDataSourceAutocomplete(columnsByDataSourceName) {
    if (!dataSourcesRegistered && Object.keys(columnsByDataSourceName).length > 0) {
        dataSourcesRegistered = true;
        const autoCompleteDataSources = [];
        const autoCompleteDataColumns = [];
        Object.keys(columnsByDataSourceName).forEach((name) => {
            const columns = columnsByDataSourceName[name];
            autoCompleteDataSources.push(name);
            columns.forEach((column) => {
                autoCompleteDataColumns.push(`${name}.${column.id}`);
            });
        });
        autoCompleteDataSources.sort();
        autoCompleteDataColumns.sort();
        activateDataSourceAutocomplete(autoCompleteDataSources, autoCompleteDataColumns);
    }
}

const AceEditorAutoCompleteHelper = (props) => {
    const { dataSourceRequestAction, columnsByDataSourceId } = props;
    useEffect(() => {
        if (Object.keys(columnsByDataSourceId).length === 0) {
            dataSourceRequestAction();
        } else {
            registerDataSourceAutocomplete(columnsByDataSourceId);
        }
    }, [columnsByDataSourceId]);
    return null;
};

AceEditorAutoCompleteHelper.propTypes = {
    columnsByDataSourceId: PropTypes.objectOf(customPropTypes.dataSourceColumns).isRequired,
    dataSourceRequestAction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    columnsByDataSourceId: getColumnsByDataSourceId(state)
});

export default connect(mapStateToProps, { dataSourceRequestAction: getDataSourcesRequest })(AceEditorAutoCompleteHelper);
