import PropTypes from 'prop-types';
import React from 'react';
import ResourceNotFoundModal from 'src/components/modals/ResourceNotFoundModal';

const withResourceCheck = (WrappedComponent, mainResourceProp, mainResourceName) => {
    const WithResourceCheck = (props) => {
        const { [mainResourceProp]: resourceToCheck } = props;
        if (!resourceToCheck) {
            const { hideModal, enforceFocus } = props;
            return (
                <ResourceNotFoundModal
                  hideModal={hideModal}
                  enforceFocus={enforceFocus}
                  resourceName={mainResourceName}
                />
            );
        }
        return <WrappedComponent {...props} />;
    };

    WithResourceCheck.propTypes = {
        hideModal: PropTypes.func.isRequired,
        enforceFocus: PropTypes.bool.isRequired
    };

    return WithResourceCheck;
};

export default withResourceCheck;
