import styles from 'src/stylesheets/profileDetailsPage.scss';
import CustomizeProfileForm from 'src/components/forms/CustomizeProfileForm';
import Button from 'src/components/buttons/Button';
import React from 'react';
import { connect } from 'react-redux';
import { isPristine, isSubmitting, submit } from 'redux-form';
import PropTypes from 'prop-types';
import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';

const getFormName = (accountId, accountType) => `customize${accountType}-${accountId}`;

const CustomizeAccount = (props) => {
    const {
        appendix, color, favorite, accountId, submitting, pristine, submitAction, onSubmit, formName
    } = props;
    const initialValues = {
        appendix,
        color,
        favorite,
        accountId
    };

    return (
        <div className={styles.customization}>
            <div className={styles.customizationForm}>
                <CustomizeProfileForm
                  form={formName}
                  onSubmit={onSubmit}
                  initialValues={initialValues}
                />
            </div>
            <Button
              label="Save customization options"
              stretch
              action
              disabled={submitting || pristine}
              loading={submitting}
              onClick={() => { submitAction(formName); }}
            />
        </div>
    );
};

CustomizeAccount.propTypes = {
    submitAction: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    appendix: PropTypes.string,
    color: PropTypes.string,
    favorite: PropTypes.bool,
    accountId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    formName: PropTypes.string.isRequired
};

CustomizeAccount.defaultProps = {
    appendix: null,
    color: null,
    favorite: false
};

const mapStateToProps = (state, ownProps) => {
    const customizeProfileFormName = getFormName(ownProps.accountId, ownProps.accountType);
    return {
        formName: customizeProfileFormName,
        submitting: isSubmitting(customizeProfileFormName)(state),
        pristine: isPristine(customizeProfileFormName)(state)
    };
};

const ConnectedCustomizeAccount = connect(mapStateToProps, {
    submitAction: ensurePermission(submit, writePermissions.customizeProfile),
})(CustomizeAccount);

ConnectedCustomizeAccount.propTypes = {
    accountType: PropTypes.oneOf(['profile', 'adAccount']).isRequired,
    accountId: PropTypes.string.isRequired
};

export default ConnectedCustomizeAccount;
