import ProfileDetailsPage from 'src/components/ProfileDetailsPage';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import { makeSelectProfileById } from 'src/selectors/profiles';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import withResourceCheck from 'src/components/modals/withResourceCheck';
import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import { modalsShowProfileDelete } from 'src/actions/overlays';
import styles from 'src/stylesheets/profileDetailsPage.scss';

const ProfileDetailsModal = (props) => {
    const {
        hideModal,
        profile,
        modalsShowProfileDeleteAction
    } = props;

    const deleteButton = (
        <Button
          warning
          label="Delete profile"
          onClick={() => { modalsShowProfileDeleteAction([profile.id]); }}
          icon="delete"
        />
    );

    return (
        <Modal dataViewName="profile-detail" onHide={hideModal} key={`profile_detail_${profile.id}`}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Profile details</Modal.Title>
                </Modal.Header>
                <Modal.Body padded={false} className={styles.modal}>
                    <ProfileDetailsPage profile={profile} />
                </Modal.Body>
                <Modal.Footer buttonsLeft={deleteButton}>
                    <Button onClick={hideModal} label="Close" />
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

ProfileDetailsModal.propTypes = {
    profile: customPropTypes.profile.isRequired,
    hideModal: PropTypes.func.isRequired,
    modalsShowProfileDeleteAction: PropTypes.func.isRequired
};

const ProfileDetailsModalWithResourceCheck = withResourceCheck(ProfileDetailsModal, 'profile', 'profile');

const makeMapStateToProps = () => {
    const selectProfileById = makeSelectProfileById();
    return (state, ownProps) => {
        const { profileId } = ownProps;
        return {
            profile: selectProfileById(state, profileId)
        };
    };
};

export default connect(makeMapStateToProps, {
    modalsShowProfileDeleteAction: ensurePermission(modalsShowProfileDelete, writePermissions.deleteProfile)
})(ProfileDetailsModalWithResourceCheck);
