import React from 'react';
import PropTypes from 'prop-types';

const MetricPersonalNotes = ({ notes }) => <div dangerouslySetInnerHTML={{ __html: notes }} />;

MetricPersonalNotes.propTypes = {
    notes: PropTypes.string.isRequired
};

export default MetricPersonalNotes;
