import * as customPropTypes from 'src/customPropTypes';
import ProfileSelectorButton from 'src/components/buttons/ProfileSelectorButton';
import ProfileSelectorPopover from 'src/components/popovers/ProfileSelectorPopover';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styles from 'src/stylesheets/profileSelector.scss';
import withPopover from 'src/components/withPopover';

const ProfileSelectorButtonWithPopover = withPopover(ProfileSelectorButton);

const ProfileSelector = forwardRef((props, ref) => {
    const {
        selectedProfilesOrGroups, handleProfileOrGroupClick, placement, small, onShow, onHide, disabled
    } = props;

    const profileSelectorPopover = (
        <ProfileSelectorPopover
          handleProfileOrGroupClick={handleProfileOrGroupClick}
          selectedProfilesOrGroups={selectedProfilesOrGroups}
        />
    );

    return (
        <ProfileSelectorButtonWithPopover
          overlay={profileSelectorPopover}
          selectedProfilesOrGroups={selectedProfilesOrGroups}
          flip={false}
          placement={placement}
          small={small}
          onShow={onShow}
          onHide={onHide}
          className="profileSelector"
          disabled={disabled}
          referenceWrapperClassName={styles.referenceWrapper}
          forwardedRef={ref}
        />
    );
});

ProfileSelector.propTypes = {
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    handleProfileOrGroupClick: PropTypes.func.isRequired,
    placement: PropTypes.string,
    small: PropTypes.bool,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    disabled: PropTypes.bool,
};

ProfileSelector.defaultProps = {
    placement: 'bottom-start',
    small: false,
    disabled: false,
};

export default ProfileSelector;
