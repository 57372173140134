import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { postTagRuleAddFormName } from 'src/utils/postTagRule';
import { submitPostTagRuleForm } from 'src/actions/forms';
import _upperFirst from 'lodash/upperFirst';

const PostTagRuleWarningModal = (props) => {
    const {
        hideModal, enforceFocus, operationType, warningMessage
    } = props;
    const handleOnCreateClick = () => {
        const { submitPostTagRuleFormAction } = props;
        submitPostTagRuleFormAction(postTagRuleAddFormName, true);
        hideModal();
    };

    return (
        <Modal dataViewName="post-tag-rule-historical-posts-warning" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Auto-tag historical posts
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body height={120}>
                    <div>{warningMessage}</div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button label="Edit rule" onClick={() => { hideModal(); }} />
                        <Button
                          action
                          label={`${_upperFirst(operationType)} rule`}
                          onClick={handleOnCreateClick}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

PostTagRuleWarningModal.propTypes = {
    warningMessage: PropTypes.string.isRequired,
    operationType: PropTypes.oneOf(['add', 'update']).isRequired,
    submitPostTagRuleFormAction: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
};

export default connect(null, {
    submitPostTagRuleFormAction: submitPostTagRuleForm
})(PostTagRuleWarningModal);
