import React, { Component } from 'react';
import * as dashboardMetricsActionCreators from 'src/actions/dashboardMetrics';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import { selectIsDeletePending } from 'src/selectors/dashboardMetrics';

export class DashboardMetricDeleteModal extends Component {
    constructor(props) {
        super(props);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
    }

    handleDeleteClick() {
        const {
            dashboardMetricId, dashboardId, metricId, dashboardMetricsDeleteRequestAction
        } = this.props;
        dashboardMetricsDeleteRequestAction(dashboardId, dashboardMetricId, metricId);
    }

    render() {
        const { hideModal, loading, enforceFocus } = this.props;

        return (
            <Modal dataViewName="delete-dashboard-metric" onHide={hideModal} enforceFocus={enforceFocus}>
                <Modal.Dialog>
                    <Modal.Header onHide={hideModal}>
                        <Modal.Title>Delete metric</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this metric?
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button disabled={loading} onClick={hideModal} label="Cancel" />
                            <Button action warning icon="delete" loading={loading} onClick={this.handleDeleteClick} label="Delete" />
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal.Dialog>
            </Modal>
        );
    }
}

DashboardMetricDeleteModal.propTypes = {
    dashboardMetricId: PropTypes.string.isRequired,
    dashboardId: PropTypes.string.isRequired,
    metricId: PropTypes.string.isRequired,
    hideModal: PropTypes.func.isRequired,
    dashboardMetricsDeleteRequestAction: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    enforceFocus: PropTypes.bool
};

DashboardMetricDeleteModal.defaultProps = {
    loading: false
};

function mapStateToProps(state, ownProps) {
    return {
        loading: selectIsDeletePending(state, ownProps.dashboardMetricId)
    };
}

export default connect(mapStateToProps, {
    dashboardMetricsDeleteRequestAction: dashboardMetricsActionCreators.dashboardMetricsDeleteRequest
})(DashboardMetricDeleteModal);
