import React from 'react';
import PropTypes from 'prop-types';
import IcomoonIcon from 'src/components/IcomoonIcon';
import classnames from 'classnames';
import styles from 'src/stylesheets/breadcrumbFoldedIcon.scss';

const BreadcrumbFoldedIcon = ({ onClick, small, active }) => (
    <div onClick={onClick} className={classnames(styles.wrapper, { [styles.small]: small }, { [styles.active]: active })}>
        <IcomoonIcon icon="more" className={styles.icon} />
    </div>
);

BreadcrumbFoldedIcon.propTypes = {
    onClick: PropTypes.func,
    active: PropTypes.bool,
    small: PropTypes.bool
};

BreadcrumbFoldedIcon.defaultProps = {
    active: false,
    small: false
};

export default BreadcrumbFoldedIcon;
