import React from 'react';
import List from 'src/components/List';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/views/searchedAuthAccountList.scss';

const SearchedAuthAccountList = (props) => {
    const { searchedAccountIds, renderListItem } = props;
    return (
        <List border={false}>
            {
                searchedAccountIds.map((searchedAccountId) => (
                    <div className={styles.item}>
                        {
                            renderListItem(searchedAccountId)
                        }
                    </div>
                ))
            }
        </List>
    );
};

SearchedAuthAccountList.propTypes = {
    searchedAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    renderListItem: PropTypes.func.isRequired
};

export default SearchedAuthAccountList;
