import React, { Component } from 'react';
import _omit from 'lodash/omit';
import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import withToolTip from 'src/components/withToolTip';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/input.scss';
import Textarea from 'src/components/forms/inputs/Textarea';

const IcomoonIconWithTooltip = withToolTip(IcomoonIcon);

class TextAreaWithResetIcon extends Component {
    constructor(props) {
        super(props);
        this.handleResetClick = this.handleResetClick.bind(this);
    }

    handleResetClick() {
        const { onResetClick, value } = this.props;
        this.focus();
        onResetClick(value);
    }

    select() {
        this.inputComponent.select();
    }

    focus() {
        this.inputComponent.focus();
    }

    render() {
        const {
            value, onChange, disabled, layout
        } = this.props;
        const cleanedProps = _omit(this.props, ['onResetClick']);
        const showResetFilterIcon = value && value.length > 0;
        return (
            <div
              className={classnames(styles.inputWithResetWrapper, {
                  [styles.profileSearch]: layout === 'profileSearch',
                  [styles.removeItemActive]: showResetFilterIcon,
                  [styles.disabled]: disabled
              })}
            >
                <Textarea
                  ref={(c) => { this.inputComponent = c; }}
                  {...cleanedProps}
                  onMouseOver={this.onMouseOverInput}
                  onMouseOut={this.onMouseOutInput}
                  value={value}
                  onChange={onChange}
                />
                <div
                  className={classnames(styles.resetFilterIcon, {
                      [styles.showResetFilterIcon]: showResetFilterIcon,
                      [styles.resetFilterIconDisabled]: disabled
                  })}
                >
                    <IcomoonIconWithTooltip
                      icon="clear"
                      tooltip="Clear"
                      onClick={this.handleResetClick}
                    />
                </div>
            </div>
        );
    }
}

TextAreaWithResetIcon.propTypes = {
    onResetClick: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    layout: PropTypes.oneOf(['profileSearch'])
};

TextAreaWithResetIcon.defaultProps = {
    disabled: false
};

export default TextAreaWithResetIcon;
