import React from 'react';
import { connect } from 'react-redux';
import * as routeActions from 'react-router-redux';
import {
    getTypeAndId,
    dashboardReportType,
    dashboardShareLinkType
} from 'src/utils/reportOverviews';
import PropTypes from 'prop-types';
import IcomoonIcon from 'src/components/IcomoonIcon';
import withToolTip from 'src/components/withToolTip';
import _get from 'lodash/get';

const infoByType = {
    [dashboardReportType]: { icon: 'schedule', tooltip: 'Scheduled report' },
    [dashboardShareLinkType]: { icon: 'link', tooltip: 'Interactive report' }
};

const IcomoonIconWithTooltip = withToolTip(IcomoonIcon);
const style = { display: 'flex' };

const ReportOverviewIcon = (props) => {
    const { reportOverviewId, className } = props;
    const { type } = getTypeAndId(reportOverviewId);
    const { icon, tooltip } = _get(infoByType, type, { icon: '', label: '' });
    return (
        <div style={style}>
            <IcomoonIconWithTooltip
              icon={icon}
              className={className}
              tooltip={tooltip}
            />
        </div>
    );
};

ReportOverviewIcon.propTypes = {
    reportOverviewId: PropTypes.string.isRequired,
    className: PropTypes.string
};

ReportOverviewIcon.defaultProps = {
    className: ''
};

export default connect(null, {
    routePushAction: routeActions.push,
})(ReportOverviewIcon);
