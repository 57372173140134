import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/modalListItem.scss';

const ModalListItem = (props) => {
    const {
        onClick, disabled, children, fullOpaque
    } = props;
    return (
        <div className={classnames(styles.modalListItem, { [styles.disabled]: disabled, [styles.fullOpaque]: fullOpaque })} onClick={onClick}>
            {children}
        </div>
    );
};

ModalListItem.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    children: customPropTypes.children.isRequired,
    fullOpaque: PropTypes.bool
};

ModalListItem.defaultProps = {
    onClick: () => {},
    disabled: false,
    fullOpaque: false
};

export default ModalListItem;
