import * as customPropTypes from 'src/customPropTypes';
import SingleAbsoluteDateSelectorButton from 'src/components/buttons/SingleAbsoluteDateSelectorButton';
import SingleAbsoluteDateSelectorPopover from 'src/components/popovers/SingleAbsoluteDateSelectorPopover';
import PropTypes from 'prop-types';
import React from 'react';
import withPopover from 'src/components/withPopover';

const SingleAbsoluteDateSelectorButtonWithPopover = withPopover(SingleAbsoluteDateSelectorButton);

const SingleAbsoluteDateSelector = (props) => {
    const {
        selectedDate, placement, small, onDateChange, disabled, onHide, onShow, labelIfNull, stretch
    } = props;
    const dateSelectorPopover = (
        <SingleAbsoluteDateSelectorPopover
          selectedDate={selectedDate}
          onDateChange={onDateChange}
        />
    );

    return (
        <SingleAbsoluteDateSelectorButtonWithPopover
          flip={false}
          overlay={dateSelectorPopover}
          selectedDate={selectedDate}
          placement={placement}
          small={small}
          disabled={disabled}
          onShow={onShow}
          onHide={onHide}
          className="dateSelector"
          stretch={stretch}
          alignLabel="left"
          labelIfNull={labelIfNull}
        />
    );
};

SingleAbsoluteDateSelector.propTypes = {
    selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    defaultDateForDatePicker: customPropTypes.dateRange,
    onDateChange: PropTypes.func.isRequired,
    placement: PropTypes.string,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    labelIfNull: PropTypes.string,
    stretch: PropTypes.bool
};

SingleAbsoluteDateSelector.defaultProps = {
    placement: 'bottom-start',
    small: false,
    disabled: false,
    labelIfNull: '',
    stretch: true
};

export default SingleAbsoluteDateSelector;
