import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import ConfirmDeleteForm from 'src/components/forms/ConfirmDeleteForm';
import { reportOverviewDeleteRequestFormAction } from 'src/actions/reportOverview';
import { isSubmitting, submit, getFormError } from 'redux-form';
import FormError from 'src/components/forms/feedback/FormError';
import { makeSelectReportOverviewsByIds } from 'src/selectors/reportOverView';
import ReportOverviewInfo from 'src/components/reportOverview/ReportOverviewInfo';
import styles from 'src/stylesheets/modals/reportOverviewDeleteModal.scss';
import { getDeleteReportOverviewSentence } from 'src/utils/reportOverviews';

const formName = 'reportOverviewDeleteForm';

export const ReportOverviewDeleteModal = (props) => {
    const {
        reportOverviews,
        hideModal,
        enforceFocus,
        isDeleting,
        submitAction,
        error
    } = props;
    const reportOverviewIds = reportOverviews.map(({ id }) => id);

    return (
        <Modal dataViewName="report-overview-delete-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>Delete report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        error && <FormError message={error.message} />
                    }
                    <div>
                        <div>
                            {getDeleteReportOverviewSentence(reportOverviews)}
                        </div><br />
                        <div className={styles.reportOverviewInfos}>
                            {
                                reportOverviews.map((reportOverview) => (
                                    <div key={reportOverview.id} className={styles.reportOverviewInfo}>
                                        <ReportOverviewInfo reportOverview={reportOverview} />
                                    </div>
                                ))
                            }
                        </div>
                        <ConfirmDeleteForm
                          form={formName}
                          initialValues={{ reportOverviewIds }}
                          onSubmit={reportOverviewDeleteRequestFormAction}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button onClick={hideModal} label="Cancel" />
                        <Button
                          action
                          warning
                          icon="delete"
                          label="Delete"
                          onClick={() => { submitAction(formName); }}
                          loading={isDeleting}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

ReportOverviewDeleteModal.propTypes = {
    reportOverviews: customPropTypes.reportOverviews.isRequired,
    hideModal: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    enforceFocus: PropTypes.bool,
    submitAction: PropTypes.func.isRequired,
    error: customPropTypes.dataLoadingError
};

const makeMapStateToProps = () => {
    const selectReportOverviewsByIds = makeSelectReportOverviewsByIds();
    return (state, ownProps) => ({
        reportOverviews: selectReportOverviewsByIds(state, ownProps.reportOverviewIds),
        isDeleting: isSubmitting(formName)(state),
        error: getFormError(formName)(state)
    });
};

export default connect(makeMapStateToProps, {
    submitAction: submit,
})(ReportOverviewDeleteModal);
