import * as linkedInAuthUserActions from 'src/actions/linkedInAuthUsers';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { makeSelectAuthTransactionById } from 'src/selectors/authTransactions';
import PropTypes from 'prop-types';
import React from 'react';
import AuthUserInAnotherSpaceWarningModal from 'src/components/modals/AuthUserInAnotherSpaceWarningModal';

const LinkedInAuthUserInAnotherSpaceAuthTransactionWarningModal = (props) => {
    const handleCompleteAuthenticationButtonClick = () => {
        const {
            authTransaction,
            identifier,
            authTransactionRequirementIds,
            accessToken,
            refreshToken,
            validUntil,
            refreshTokenValidUntil,
            platformUserId,
            platformUserName,
            linkedInAuthUserExternalForceConnectRequestAction,
            linkedInAuthUserForceConnectRequestAction,
            isPublic,
            profileIds
        } = props;
        if (isPublic) {
            linkedInAuthUserExternalForceConnectRequestAction(
                authTransaction.hash,
                identifier,
                authTransaction.id,
                authTransactionRequirementIds,
                accessToken,
                refreshToken,
                validUntil,
                refreshTokenValidUntil,
                platformUserId,
                platformUserName,
                profileIds
            );
        } else {
            linkedInAuthUserForceConnectRequestAction(
                identifier,
                authTransaction.id,
                authTransactionRequirementIds,
                accessToken,
                refreshToken,
                validUntil,
                refreshTokenValidUntil,
                platformUserId,
                platformUserName,
                profileIds
            );
        }
    };

    const { hideModal, enforceFocus } = props;

    return (
        <AuthUserInAnotherSpaceWarningModal
          onCompleteAuthenticationClick={handleCompleteAuthenticationButtonClick}
          networkType="linkedIn"
          hideModal={hideModal}
          enforceFocus={enforceFocus}
        />
    );
};

LinkedInAuthUserInAnotherSpaceAuthTransactionWarningModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    authTransaction: customPropTypes.authTransaction.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    linkedInAuthUserForceConnectRequestAction: PropTypes.func.isRequired,
    linkedInAuthUserExternalForceConnectRequestAction: PropTypes.func.isRequired,
    identifier: PropTypes.string.isRequired,
    authTransactionId: PropTypes.string.isRequired,
    authTransactionRequirementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    accessToken: PropTypes.string.isRequired,
    refreshToken: PropTypes.string.isRequired,
    validUntil: PropTypes.string.isRequired,
    refreshTokenValidUntil: PropTypes.string.isRequired,
    platformUserId: PropTypes.string.isRequired,
    platformUserName: PropTypes.string.isRequired,
    isPublic: PropTypes.string.isRequired,
    profileIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

const makeMapStateToProps = () => {
    const selectAuthTransactionById = makeSelectAuthTransactionById();
    return (state, ownProps) => ({
        authTransaction: selectAuthTransactionById(state, ownProps.authTransactionId)
    });
};

export default connect(makeMapStateToProps, {
    linkedInAuthUserForceConnectRequestAction: linkedInAuthUserActions.linkedInAuthUserForceConnectRequest,
    linkedInAuthUserExternalForceConnectRequestAction: linkedInAuthUserActions.linkedInAuthUserExternalForceConnectRequest
})(LinkedInAuthUserInAnotherSpaceAuthTransactionWarningModal);
