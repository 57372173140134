import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import AuthUserInAnotherSpaceWarningModal from 'src/components/modals/AuthUserInAnotherSpaceWarningModal';
import { externalSnapchatForceConnectRequest } from 'src/actions/socialNetworkProfileAuthentication';

const SnapchatAuthUserInAnotherSpaceExternalAuthenticationWarningModal = (props) => {
    const handleCompleteAuthenticationButtonClick = () => {
        const {
            accessToken,
            expiresIn,
            refreshAccessToken,
            platformUserId,
            displayName,
            externalSnapchatForceConnectRequestAction
        } = props;
        externalSnapchatForceConnectRequestAction(
            accessToken,
            expiresIn,
            refreshAccessToken,
            platformUserId,
            displayName
        );
    };

    const { hideModal, enforceFocus } = props;
    return (
        <AuthUserInAnotherSpaceWarningModal
          hideModal={hideModal}
          enforceFocus={enforceFocus}
          onCompleteAuthenticationClick={handleCompleteAuthenticationButtonClick}
          networkType="snapchat"
        />
    );
};

SnapchatAuthUserInAnotherSpaceExternalAuthenticationWarningModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    accessToken: PropTypes.string.isRequired,
    refreshAccessToken: PropTypes.string.isRequired,
    expiresIn: PropTypes.number.isRequired,
    platformUserId: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    externalSnapchatForceConnectRequestAction: PropTypes.func.isRequired
};

export default connect(null, {
    externalSnapchatForceConnectRequestAction: externalSnapchatForceConnectRequest
})(SnapchatAuthUserInAnotherSpaceExternalAuthenticationWarningModal);
