import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import InstagramTagAsBusinessProfileWarning from 'src/components/InstagramTagAsBusinessProfileWarning';
import Popover from 'src/components/popovers/layout/Popover';
import styles from 'src/stylesheets/popovers/unHealthyProfilePopover.scss';
import PropTypes from 'prop-types';
import FacebookMigratedProfileLink from 'src/components/profile/FacebookMigratedProfileLink';

const UnHealthyProfileInfoPopover = (props) => {
    const {
        profile, profileHealth, content
    } = props;
    const { unHealthyType, metaData } = profileHealth;
    return (
        <Popover id="Un-healthy-profile-info-popover">
            <div className={styles.wrapper}>
                <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
                {
                    unHealthyType === 'migrated'
                    && <FacebookMigratedProfileLink link={metaData.link} />
                }
                {
                    unHealthyType === 'not_exist_or_personal_account'
                    && (
                        <InstagramTagAsBusinessProfileWarning profile={profile} />
                    )
                }
            </div>
        </Popover>
    );
};

UnHealthyProfileInfoPopover.propTypes = {
    profile: customPropTypes.profile.isRequired,
    profileHealth: customPropTypes.profileHealth.isRequired,
    content: PropTypes.string.isRequired,
};

export default UnHealthyProfileInfoPopover;
