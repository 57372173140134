import ManageGroupsOfAccountsPopover from 'src/components/popovers/ManageGroupsOfAccountsPopover';
import { connect } from 'react-redux';
import { makeSelectGroupsIdsByProfileId } from 'src/selectors/profiles';
import { profileAddToOrRemoveFromGroupRequest } from 'src/actions/profiles';
import PropTypes from 'prop-types';

const makeMapStateToProps = () => {
    const selectGroupsIdsByProfileId = makeSelectGroupsIdsByProfileId();
    return (state, ownProps) => {
        const { profileId } = ownProps;
        return {
            accountId: profileId,
            selectedGroupIds: selectGroupsIdsByProfileId(state, profileId),
        };
    };
};

const ManageGroupsOfProfilePopOver = connect(makeMapStateToProps, {
    accountAddToOrRemoveFromGroupRequestAction: profileAddToOrRemoveFromGroupRequest
})(ManageGroupsOfAccountsPopover);

ManageGroupsOfProfilePopOver.propTypes = {
    profileId: PropTypes.string.isRequired
};

export default ManageGroupsOfProfilePopOver;
