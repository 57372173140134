import { Field } from 'redux-form';
import React from 'react';
import { Image } from 'src/components/forms/fields';
import { makeValidateImage } from 'src/components/forms/validators';
import PropTypes from 'prop-types';
import fallBackImg from 'src/resources/illustrations/no_image.png';

const acceptedMimeTypes = [
    'image/png',
    'image/jpeg'
];

const validateCustomLogo = makeValidateImage(5000000, 400, 100, acceptedMimeTypes);

const CustomLogo = ({ context }) => (
    <Field
      name="customLogo"
      label="Custom logo"
      component={Image}
      removeLabel="Remove logo"
      accept={acceptedMimeTypes}
      validate={validateCustomLogo}
      info={`Add a logo to the reports and exports for this ${context}. Logos will be shown on the cover of automatic reports, the top right corner inside each report/export page, and in report emails. Recommended image ratio is 4:1 (e.g. 400px by 100px) max 5MB.`}
      fallbackSrc={fallBackImg}
      maxHeight={100}
      maxWidth={440}
    />
);

CustomLogo.propTypes = {
    context: PropTypes.oneOf(['dashboard', 'space']).isRequired
};

export default CustomLogo;
