import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/buttonGroup.scss';

const ButtonGroup = ({
    shrinkableButtons, vertical, className, children
}) => (
    <ul className={classnames(styles.buttons, {
        [styles.vertical]: vertical,
        [className]: className
    })}
    >
        {
                React.Children.map(children, (button, index) => {
                    if (button) {
                        const shrink = shrinkableButtons.indexOf(index + 1) !== -1;
                        return <li className={classnames({ [styles.shrink]: shrink })}>{button}</li>;
                    }
                    return null;
                })
            }
    </ul>
);

ButtonGroup.propTypes = {
    children: customPropTypes.children.isRequired,
    vertical: PropTypes.bool,
    className: PropTypes.string,
    shrinkableButtons: PropTypes.arrayOf(PropTypes.number) // pass as array of button indices which are shrinkable, first index starts with 1
};

ButtonGroup.defaultProps = {
    vertical: false,
    shrinkableButtons: []
};

export default ButtonGroup;
