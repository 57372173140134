import NetworkButton from 'src/components/buttons/NetworkButton';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import IcomoonIcon from 'src/components/IcomoonIcon';
import { isAuthTransactionOpen } from 'src/utils/authTransactionUtils';
import { makeSelectProfileById } from 'src/selectors/profiles';
import ProfileAuthTransactionConnectErrorPopover from 'src/components/popovers/ProfileAuthTransactionConnectErrorPopover';
import PropTypes from 'prop-types';
import React from 'react';
import { selectAuthenticateByIdentifier } from 'src/selectors/authUsers';
import styles from 'src/stylesheets/networkAuthUserConnectButton.scss';
import withPopover from 'src/components/withPopover';

const IcomoonIconIconWithPopover = withPopover(IcomoonIcon);

const ThreadsConnectButtonAndState = (props) => {
    const {
        profile,
        authTransaction,
        authTransactionRequirements,
        connectAction,
        authenticateState
    } = props;

    const error = authenticateState ? authenticateState.error : '';
    const isPending = authenticateState ? authenticateState.isPending : false;

    return (
        <div className={styles.wrapper}>
            {
                (error && !isPending)
                && (
                <div className={styles.error}>
                    <IcomoonIconIconWithPopover
                      overlay={<ProfileAuthTransactionConnectErrorPopover errorMessage={error} />}
                      icon="alert"
                      placement="top"
                      trigger="hover"
                      className={styles.errorIcon}
                    />
                </div>
                )
            }
            <div className={styles.button}>
                <NetworkButton
                  platformType="threads"
                  label="Continue with Threads"
                  disabled={!(isAuthTransactionOpen(authTransaction.isOpen, authTransaction.validUntil))}
                  loading={isPending}
                  onClick={() => {
                      connectAction(
                          `threads_${authTransaction.id}_${profile.id}`,
                          authTransaction.id,
                          authTransactionRequirements,
                          profile
                      );
                  }}
                />
            </div>
        </div>
    );
};

ThreadsConnectButtonAndState.propTypes = {
    profile: customPropTypes.profile.isRequired,
    authTransaction: customPropTypes.authTransaction.isRequired,
    authTransactionRequirements: customPropTypes.profileAuthTransactionRequirements.isRequired,
    connectAction: PropTypes.func.isRequired,
    authenticateState: PropTypes.oneOfType([PropTypes.bool, customPropTypes.asyncReducerState]).isRequired,
};

const makeMapStateToProps = () => {
    const selectProfileById = makeSelectProfileById();
    return (state, ownProps) => {
        const { authTransaction, profileId } = ownProps;
        return {
            profile: selectProfileById(state, profileId),
            authenticateState: selectAuthenticateByIdentifier(state, `threads_${authTransaction.id}_${profileId}`)
        };
    };
};

export default connect(makeMapStateToProps)(ThreadsConnectButtonAndState);
