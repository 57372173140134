import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { postTagEditFormName } from 'src/utils/postTag';
import { submitPostTagEditForm } from 'src/actions/forms';

const PostTagEditWarningModal = (props) => {
    const { hideModal, enforceFocus, errorMessage } = props;

    const handleOnSaveClick = () => {
        const { submitPostTagEditFormAction } = props;
        submitPostTagEditFormAction(postTagEditFormName, true);
        hideModal();
    };

    return (
        <Modal dataViewName="post-tag-edit-warning" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Post tag edit
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body height={120}>
                    <div>
                        {errorMessage}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button label="Cancel" onClick={() => { hideModal(); }} />
                        <Button
                          action
                          label="Save"
                          onClick={handleOnSaveClick}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

PostTagEditWarningModal.propTypes = {
    errorMessage: PropTypes.string.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    submitPostTagEditFormAction: PropTypes.func.isRequired,
};

export default connect(null, {
    submitPostTagEditFormAction: submitPostTagEditForm
})(PostTagEditWarningModal);
