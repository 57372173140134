import _capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/selectorLabels.scss';

const DynamicDateSelectorLabel = (props) => {
    const { selectedDate } = props;
    let text = '';
    let labelValue = '';

    if (selectedDate) {
        text = _capitalize(selectedDate);
    } else {
        text = 'No date selected';
        labelValue = 'Click to select';
    }
    return (
        <span>
            <span>
                {text}
            </span>
            {
                labelValue && <span className={styles.label}>({labelValue})</span>
            }
        </span>
    );
};

DynamicDateSelectorLabel.propTypes = {
    selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
};

export default DynamicDateSelectorLabel;
