import React from 'react';
import { getAudienceSizeString } from 'src/utils/profiles';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/profile/profileAudienceSize.scss';
import classnames from 'classnames';

const ProfileAudienceSize = (props) => {
    const { profile, isLarge } = props;
    const { likes, platformType, hiddenAudienceSize } = profile;
    return (
        <div className={classnames(styles.wrapper, { [styles.large]: isLarge })}>
            {getAudienceSizeString(likes, platformType, hiddenAudienceSize)}
        </div>
    );
};

ProfileAudienceSize.propTypes = {
    profile: PropTypes.oneOfType([
        customPropTypes.profile,
        customPropTypes.searchedProfile
    ]).isRequired,
    isLarge: PropTypes.bool
};

ProfileAudienceSize.defaultProps = {
    isLarge: false
};

export default ProfileAudienceSize;
