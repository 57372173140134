import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/views.scss';

class ScrollView extends Component {
    constructor(props) {
        super(props);
        this.handleRefCallback = this.handleRefCallback.bind(this);
    }

    handleRefCallback(node) {
        const { bodyRefForScrollPosition, bodyRefForStickyHeader } = this.props;
        if (bodyRefForScrollPosition) {
            bodyRefForScrollPosition(node);
        }
        if (bodyRefForStickyHeader) {
            bodyRefForStickyHeader(node);
        }
    }

    render() {
        const { children, dataViewName } = this.props;
        const optionalProps = {};
        if (dataViewName) {
            optionalProps['data-view-name'] = `view-${dataViewName}`;
        }
        return (
            <div
              {...optionalProps}
              className={classnames(styles.scrollView)}
              ref={(node) => { this.handleRefCallback(node); }}
            >
                {children}
            </div>
        );
    }
}

ScrollView.propTypes = {
    children: customPropTypes.children,
    bodyRefForScrollPosition: PropTypes.func,
    bodyRefForStickyHeader: PropTypes.func,
    dataViewName: PropTypes.string,
};

export default ScrollView;
