import { Link } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/breadcrumb.scss';

const BreadcrumbItem = ({ last, onClick, title }) => (
    <div className={styles.innerItemWrapper}>
        {
            last === false
                && (
                <Link onClick={onClick} className={styles.link}>
                    {title}
                </Link>
                )
        }
        {
            last === true
                && (
                <div className={styles.lastItem}>
                    {title}
                </div>
                )
        }
    </div>
);

BreadcrumbItem.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    last: PropTypes.bool
};

BreadcrumbItem.defaultProps = {
    last: false
};

export default BreadcrumbItem;
