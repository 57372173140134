import * as customPropTypes from 'src/customPropTypes';
import * as twitterAuthUsersAction from 'src/actions/twitterAuthUsers';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import TwitterConnectButtonAndState from 'src/components/authTransactions/TwitterConnectButtonAndState';
import AuthTransactionRequirementsTable from 'src/components/authTransactions/table/AuthTransactionRequirementsTable';
import _memoize from 'memoize-one';

const TwitterAuthTransactionRequirementConnect = (props) => {
    const {
        authTransaction,
        authTransactionRequirementsByProfileId,
        isPublic,
        showOnlyBasicInsightsUseCases
    } = props;

    const handleTwitterConnectRequest = (identifier, authTransactionId, authTransactionRequirements, profile) => {
        const { twitterAuthUserConnectRequestAction, twitterAuthUserExternalConnectRequestAction } = props;
        if (isPublic) {
            twitterAuthUserExternalConnectRequestAction(authTransaction.hash, identifier, authTransactionId, authTransactionRequirements, profile);
        } else {
            twitterAuthUserConnectRequestAction(identifier, authTransactionId, authTransactionRequirements, profile);
        }
    };

    const connectButtonPerProfileId = _memoize(() => {
        const buttons = [];
        Object.keys(authTransactionRequirementsByProfileId).forEach((profileId) => {
            buttons[profileId] = (
                <TwitterConnectButtonAndState
                  profileId={profileId}
                  authTransaction={authTransaction}
                  connectAction={handleTwitterConnectRequest}
                  authTransactionRequirements={authTransactionRequirementsByProfileId[profileId]}
                />
            );
        });
        return buttons;
    });

    return (
        <AuthTransactionRequirementsTable
          platformType="twitter"
          authTransactionRequirementsByProfileId={authTransactionRequirementsByProfileId}
          connectButtonsByAccountId={connectButtonPerProfileId()}
          showOnlyBasicInsightsUseCases={showOnlyBasicInsightsUseCases}
        />
    );
};

TwitterAuthTransactionRequirementConnect.propTypes = {
    authTransaction: customPropTypes.authTransaction.isRequired,
    twitterAuthUserConnectRequestAction: PropTypes.func.isRequired,
    twitterAuthUserExternalConnectRequestAction: PropTypes.func.isRequired,
    authTransactionRequirementsByProfileId: PropTypes.objectOf(customPropTypes.profileAuthTransactionRequirements).isRequired,
    isPublic: PropTypes.bool.isRequired,
    showOnlyBasicInsightsUseCases: PropTypes.bool.isRequired
};

export default connect(null, {
    twitterAuthUserConnectRequestAction: twitterAuthUsersAction.twitterAuthUserConnectRequest,
    twitterAuthUserExternalConnectRequestAction: twitterAuthUsersAction.twitterAuthUserExternalConnectRequest
})(TwitterAuthTransactionRequirementConnect);
