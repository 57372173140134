import React from 'react';
import CopyToClipboard from 'src/components/CopyToClipboard';
import PropTypes from 'prop-types';
import { getShareLink } from 'src/utils/authTransactionUtils';

const AuthTransactionLink = ({ hash, label }) => <CopyToClipboard link={getShareLink(hash)} label={label} />;

AuthTransactionLink.propTypes = {
    hash: PropTypes.string.isRequired,
    label: PropTypes.string
};

export default AuthTransactionLink;
