import { modalsHide, modalsHideAsRoute } from 'src/actions/overlays';
import React, { Component } from 'react';
import { selectModalFromUrlHash, selectModalsInOrderToShow } from 'src/selectors/modals';
import * as customPropTypes from 'src/customPropTypes';
import * as Modals from 'src/components/modals';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import ModalErrorBoundary from 'src/components/modals/ModalErrorBoundary';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

class ModalHandler extends Component {
    constructor(props) {
        super(props);
        this.state = { render: false };
        this.enableRendering = this.enableRendering.bind(this);
    }

    componentDidMount() {
        // we need to delay the modal rendering on initial load cause it can have race conditions with
        // modal views that get rendered in <Content /> cause this component is as well async on mount
        // because of the dimension provider
        setTimeout(this.enableRendering, 300);
    }

    enableRendering() {
        this.setState({ render: true });
    }

    render() {
        const { render } = this.state;
        if (render) {
            const {
                modals, modalsHideAction, modalFromUrl, modalsHideAsRouteAction
            } = this.props;

            const modalsToRender = [];

            if (modalFromUrl) {
                const ModalFromUrlToRender = _get(Modals, modalFromUrl.modalType, null);
                if (ModalFromUrlToRender) {
                    const hideModal = () => {
                        modalsHideAsRouteAction(modalFromUrl.modalType, modalFromUrl.idProp);
                    };
                    const modalFromRoute = (
                        <ModalErrorBoundary
                          enforceFocus={modals.length === 0}
                          hideModal={hideModal}
                          key={modalFromUrl.modalType}
                        >
                            <ModalFromUrlToRender
                              enforceFocus={modals.length === 0}
                              {...modalFromUrl.modalProps}
                              hideModal={hideModal}
                            />
                        </ModalErrorBoundary>
                    );
                    modalsToRender.push(modalFromRoute);
                } else {
                    throw new Error(`Unsupported modal type ${modalFromUrl}`);
                }
            }
            modals.forEach((modal, idx, allModals) => {
                // Only set enforceFocus to the last launched Modal
                let enforceFocus = false;
                if (idx === allModals.length - 1) {
                    enforceFocus = true;
                }

                const { modalType, modalProps, identifier } = modal;
                const hideModal = () => {
                    modalsHideAction(identifier, modalType);
                };
                const ModalToRender = _get(Modals, modalType, null);
                if (ModalToRender) {
                    const modalToRender = (
                        <ModalErrorBoundary
                          enforceFocus={modals.length === 0}
                          hideModal={hideModal}
                          key={identifier}
                        >
                            <ModalToRender
                              {...modalProps}
                              hideModal={hideModal}
                              enforceFocus={enforceFocus}
                            />
                        </ModalErrorBoundary>
                    );
                    modalsToRender.push(modalToRender);
                } else {
                    throw new Error(`Unsupported modal type ${modalType}`);
                }
            });

            if (modalsToRender.length > 0) {
                return <div>{modalsToRender}</div>;
            }
        }
        return null;
    }
}

ModalHandler.propTypes = {
    modals: customPropTypes.modals.isRequired,
    modalsHideAction: PropTypes.func.isRequired,
    modalsHideAsRouteAction: PropTypes.func.isRequired,
    modalFromUrl: PropTypes.shape({
        modalProps: PropTypes.object.isRequired,
        modalType: PropTypes.string.isRequired,
        idProp: PropTypes.string
    })

};

function mapStateToProps(state, ownProps) {
    return {
        modals: selectModalsInOrderToShow(state),
        modalFromUrl: selectModalFromUrlHash(ownProps.location.hash)
    };
}
export default withRouter(connect(mapStateToProps, {
    modalsHideAction: modalsHide,
    modalsHideAsRouteAction: modalsHideAsRoute
})(ModalHandler));
