import classnames from 'classnames';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import Label from 'src/components/labels/Label';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/labels/authUserUseCasesLabel.scss';

const AuthUserUseCasesLabel = (props) => {
    const { userName, valid } = props;
    const finalState = valid ? 'ok' : 'error';

    return (
        <div className={styles.wrapper}>
            <div className={styles.label}>
                <Label state={finalState} />
            </div>
            <div className={classnames(styles.userName, 'authUserForHover')}>
                <DivWithTooltip tooltip={userName}>
                    {userName}
                </DivWithTooltip>
            </div>
        </div>
    );
};

AuthUserUseCasesLabel.propTypes = {
    userName: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired
};

export default AuthUserUseCasesLabel;
