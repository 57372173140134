import { dashboardCloneRequest } from 'src/actions/dashboard';
import { modalShowAddFolder } from 'src/actions/overlays';
import FolderBrowser from 'src/components/folderBrowser/FolderBrowser';
import FolderBrowserAddFolderButton from 'src/components/folderBrowser/FolderBrowserAddFolderButton';
import FolderBrowserSubmitButton from 'src/components/folderBrowser/FolderBrowserSubmitButton';
import FolderBrowserTitle from 'src/components/folderBrowser/FolderBrowserTitle';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { selectIsDashboardClonePending } from 'src/selectors/dashboards';

const CloneDashboardModal = (props) => {
    const {
        hideModal,
        enforceFocus,
        folderId: initialFolderId,
        dashboardId,
        isLoading,
        cloneRequestAction,
        modalShowAddFolderAction
    } = props;
    //
    const [toFolderId, setToFolderId] = useState(initialFolderId);

    const submitClick = () => {
        cloneRequestAction(dashboardId, toFolderId);
    };

    return (
        <Modal dataViewName="clone-dashboard" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog width={620}>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title><FolderBrowserTitle toFolderId={toFolderId} actionName="Clone" /></Modal.Title>
                </Modal.Header>
                <Modal.Body
                  height={400}
                  stickyFooter={<FolderBrowserAddFolderButton onClick={modalShowAddFolderAction} toFolderId={toFolderId} />}
                >
                    <FolderBrowser
                      toFolderId={toFolderId}
                      onItemClick={setToFolderId}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button disabled={isLoading} onClick={hideModal} label="Cancel" />
                        <FolderBrowserSubmitButton
                          toFolderId={toFolderId}
                          onClick={submitClick}
                          isLoading={isLoading}
                          label="Save here"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

CloneDashboardModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    cloneRequestAction: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    folderId: PropTypes.string.isRequired,
    dashboardId: PropTypes.string.isRequired,
    modalShowAddFolderAction: PropTypes.func.isRequired
};

CloneDashboardModal.defaultProps = {
    isLoading: false
};
const mapStateToProps = (state, { dashboardId }) => ({
    isLoading: selectIsDashboardClonePending(state, dashboardId),
    modalShowAddFolderAction: modalShowAddFolder
});

export default connect(mapStateToProps, {
    cloneRequestAction: dashboardCloneRequest
})(CloneDashboardModal);
