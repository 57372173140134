import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/image.scss';
import ImageWithFallback from 'src/components/ImageWithFallback';
import fallBackImg from 'src/resources/illustrations/no_image.png';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import classnames from 'classnames';

const Image = (props) => {
    const inputRef = useRef();
    const {
        value, accept, maxHeight, maxWidth, fallbackSrc, removeLabel
    } = props;
    const imageStyle = {};
    const imageWrapperStyle = {};
    if (maxHeight) {
        imageStyle.maxHeight = maxHeight;
        imageWrapperStyle.height = maxHeight;
    }
    if (maxWidth) {
        imageWrapperStyle.width = maxWidth;
        imageStyle.maxWidth = maxWidth;
    }
    const handleOnChange = (e) => {
        e.preventDefault();
        const { onChange } = props;
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const objectURL = (window.URL || window.webkitURL).createObjectURL(file);
            const imageObject = new window.Image();
            imageObject.onload = () => {
                const imgWidth = imageObject.naturalWidth;
                const imgHeight = imageObject.naturalHeight;
                onChange({
                    url: objectURL,
                    meta: {
                        width: imgWidth,
                        height: imgHeight,
                        size: file.size,
                        type: file.type
                    }
                });
            };

            imageObject.onerror = () => {
                (window.URL || window.webkitURL).revokeObjectURL(objectURL);
                onChange({
                    url: objectURL,
                    error: 'Something went wrong, please chose an image.'
                });
            };

            imageObject.src = objectURL;
        }
    };

    const handleOnRemove = () => {
        const { onChange } = props;
        onChange({
            url: '',
            meta: {}
        });
        inputRef.current.value = '';
    };

    return (
        <div className={styles.wrapper}>
            <div className={classnames(styles.imageWrapper, { [styles.noImage]: value.url === '' })} style={imageWrapperStyle}>
                {
                    value.url !== ''
                    && (
                        <ImageWithFallback
                          src={value.url}
                          style={imageStyle}
                          className={styles.image}
                          fallbackSrc={fallbackSrc}
                        />
                    )
                }
            </div>
            <div className={styles.control}>
                <Button
                  label={removeLabel}
                  icon="delete"
                  stretch
                  disabled={value.url === ''}
                  className={styles.remove}
                  onClick={handleOnRemove}
                />
                <Button
                  label="Select file"
                  icon="upload"
                  stretch
                  onClick={() => { inputRef.current.click(); }}
                />
            </div>
            <input
              ref={inputRef}
              type="file"
              accept={accept.join(', ')}
              onChange={handleOnChange}
              className={styles.input}
            />
        </div>
    );
};

Image.propTypes = {
    value: customPropTypes.imageInput,
    onChange: PropTypes.func.isRequired,
    accept: PropTypes.array,
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    fallbackSrc: PropTypes.string,
    removeLabel: PropTypes.string.isRequired
};

Image.defaultProps = {
    value: { url: '', file: null, meta: { size: 0, width: 0, height: 0 } },
    accept: ['image/png'],
    fallbackSrc: fallBackImg,
    maxWidth: 300,
    maxHeight: 100
};

export default Image;
