import BackLink from 'src/components/BackLink';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Button from 'src/components/buttons/Button';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/profileSearch.scss';
import AdAccountAddSidebar from 'src/components/views/adAccounts/search/AdAccountAddSidebar';
import withAccountSearchHandling from 'src/components/withAccountSearchHandling';
import { connect } from 'react-redux';
import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import AdAccountSearchStartPage from 'src/components/views/adAccounts/search/AdAccountSearchStartPage';
import AuthenticatedSearchAdAccountResult from 'src/components/views/adAccounts/search/AuthenticatedSearchAdAccountResult';
import { getIsAuthenticatedSearchResultPage } from 'src/selectors/adAccountSearch';
import { listName } from 'src/utils/adAccountSearch';
import { adAccountBulkAddRequest } from 'src/actions/adAccounts';
import { socialNetworkAdAccountSearchReset } from 'src/actions/adAccountSearch';
import { selectIsBulkAdAccountAdding } from 'src/selectors/adAccounts';

const AdAccountAddModal = (props) => {
    const {
        enforceFocus,
        isAuthenticatedSearchResultPage,
        socialNetworkAdAccountSearchResetAction,
        checkedAccountIds,
        isBulkAdAccountAdding,
        handleOnHideModal
    } = props;

    useEffect(() => () => {
        socialNetworkAdAccountSearchResetAction();
    }, []);

    const handleOnAdAccountAddClick = () => {
        const { onAddAccounts, adAccountBulkAddRequestAction } = props;
        adAccountBulkAddRequestAction(checkedAccountIds);
        onAddAccounts();
    };

    const optionalProps = {};
    if (isAuthenticatedSearchResultPage) {
        optionalProps.leftContent = <BackLink text="Connect more ad accounts" onClick={socialNetworkAdAccountSearchResetAction} />;
    }
    const buttonLabel = `${isBulkAdAccountAdding ? 'Adding' : 'Add'} ad account${checkedAccountIds.length > 1 ? 's' : ''}`;

    return (
        <Modal dataViewName="add-new-ad-accounts-modal" onHide={handleOnHideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={handleOnHideModal} {...optionalProps} disabled={isBulkAdAccountAdding}>
                    <Modal.Title>Add new ad accounts</Modal.Title>
                </Modal.Header>
                <div className={styles.wrapper}>
                    <div className={styles.body}>
                        <div className={styles.content}>
                            {
                                !isAuthenticatedSearchResultPage && <AdAccountSearchStartPage />
                            }
                            {
                                isAuthenticatedSearchResultPage && <AuthenticatedSearchAdAccountResult />
                            }
                        </div>
                    </div>
                    <AdAccountAddSidebar />
                </div>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          label="Cancel"
                          onClick={() => { handleOnHideModal(); }}
                        />
                        <Button
                          label={buttonLabel}
                          action
                          disabled={checkedAccountIds.length === 0}
                          loading={isBulkAdAccountAdding}
                          onClick={handleOnAdAccountAddClick}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

AdAccountAddModal.propTypes = {
    checkedAccountIds: customPropTypes.searchedProfiles.isRequired,
    handleOnHideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    isAuthenticatedSearchResultPage: PropTypes.bool.isRequired,
    socialNetworkAdAccountSearchResetAction: PropTypes.func.isRequired,
    onAddAccounts: PropTypes.func.isRequired,
    isBulkAdAccountAdding: PropTypes.bool.isRequired,
    adAccountBulkAddRequestAction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isAuthenticatedSearchResultPage: getIsAuthenticatedSearchResultPage(state),
    isBulkAdAccountAdding: selectIsBulkAdAccountAdding(state)
});

export default connect(mapStateToProps, {
    adAccountBulkAddRequestAction: ensurePermission(adAccountBulkAddRequest, writePermissions.addAdAccount),
    socialNetworkAdAccountSearchResetAction: socialNetworkAdAccountSearchReset
})(withAccountSearchHandling(AdAccountAddModal, listName));
