import AccountDeleteModal from 'src/components/account/layout/AccountDeleteModal';
import { isSubmitting, submit } from 'redux-form';
import { connect } from 'react-redux';
import { makeSelectProfilesByIds } from 'src/selectors/profiles';
import { profileDeleteFormAction } from 'src/actions/profiles';
import PropTypes from 'prop-types';

const formName = 'profileDeleteForm';

const makeMapStateToProps = () => {
    const selectProfilesByIds = makeSelectProfilesByIds();
    const formSelector = isSubmitting(formName);
    return (state, ownProps) => {
        const profiles = selectProfilesByIds(state, ownProps.profileIds);
        const accountNames = profiles.map((profile) => ({ id: profile.id, name: profile.defaultName }));

        return {
            isAccountDeleting: formSelector(state),
            accountNames,
            accountIds: ownProps.profileIds,
            formAction: profileDeleteFormAction,
            singularLabel: 'profile',
            pluralLabel: 'profiles',
            formName
        };
    };
};

const ProfileDeleteModal = connect(makeMapStateToProps, {
    submitAction: submit
})(AccountDeleteModal);

ProfileDeleteModal.propTypes = {
    profileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProfileDeleteModal;
