import { adAccountForceDeleteFormAction } from 'src/actions/adAccounts';
import React from 'react';
import { groupForceDeleteFormAction } from 'src/actions/groups';
import Modal from 'src/components/modals/layout/Modal';
import { profileForceDeleteFormAction } from 'src/actions/profiles';
import {
    postTagsForceArchiveRequestFormAction,
    postTagsForceRemoveRequestFormAction
} from 'src/actions/postTags';
import NonOperationalEntitiesDetail from 'src/components/bindings/NonOperationalEntitiesDetail';
import MultipleEntityBindingList from 'src/components/bindings/MultipleEntityBindingList';
import SingleEntityBindingList from 'src/components/bindings/SingleEntityBindingList';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Button from 'src/components/buttons/Button';
import ConfirmDeleteForm from 'src/components/forms/ConfirmDeleteForm';
import ForceArchiveForm from 'src/components/forms/ForceArchiveForm';
import styles from 'src/stylesheets/modals/postTagsDeleteModal.scss';
import { isSubmitting, submit } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';

const operationalTypes = {
    forceProfileDelete: {
        action: profileForceDeleteFormAction, title: 'Profile deletion options', actionName: 'delete', pendingActionName: 'deleting', label: 'profile'
    },
    forceGroupDelete: {
        action: groupForceDeleteFormAction, title: 'Group deletion options', actionName: 'delete', pendingActionName: 'deleting', label: 'group'
    },
    forceTagDelete: {
        action: postTagsForceRemoveRequestFormAction, title: 'Tag deletion options', actionName: 'delete', pendingActionName: 'deleting', label: 'tag'
    },
    forceTagArchive: {
        action: postTagsForceArchiveRequestFormAction,
        title: 'Tag archive options',
        actionName: 'archive',
        pendingActionName: 'archiving',
        label: 'tag'
    },
    forceAdAccountDelete: {
        action: adAccountForceDeleteFormAction,
        title: 'Ad account deletion options',
        actionName: 'delete',
        pendingActionName: 'deleting',
        label: 'ad account'
    }
};

const getFormName = (type) => `${type}Form`;

const EntityOperationInfoModal = (props) => {
    const {
        operationalType,
        forceOperationalEntityInfos,
        nonOperationalEntityNames,
        hideModal,
        enforceFocus,
        isPending,
        submitAction
    } = props;
    const formName = getFormName(operationalType);

    const operationalTypeValues = operationalTypes[operationalType]; // use safe _get later
    const {
        action, actionName, pendingActionName, title, label
    } = operationalTypeValues;

    const isNonOperationalAvailable = nonOperationalEntityNames.length > 0;
    const isForceOperationalAvailable = forceOperationalEntityInfos.length > 0;
    return (
        <Modal dataViewName="entity-operation-info-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isNonOperationalAvailable
                        && (
                            <div className={styles.row}>
                                <NonOperationalEntitiesDetail names={nonOperationalEntityNames} label={label} actionName={actionName} />
                            </div>
                        )
                    }
                    {
                        isForceOperationalAvailable
                        && (
                            <div>
                                <div className={styles.row}>
                                    {
                                        forceOperationalEntityInfos.length === 1
                                            ? <SingleEntityBindingList label={label} forceOperationalEntityInfo={forceOperationalEntityInfos[0]} />
                                            : <MultipleEntityBindingList label={label} forceOperationalEntityInfos={forceOperationalEntityInfos} />
                                    }
                                </div>
                                {
                                 operationalType !== 'forceTagArchive'
                                    && (
                                    <ConfirmDeleteForm
                                      form={formName}
                                      forceDelete
                                      onSubmit={action}
                                      initialValues={{ ids: forceOperationalEntityInfos.map(({ id }) => id) }}
                                    />
                                    )
                                }
                                {
                                    operationalType === 'forceTagArchive'
                                    && (
                                        <ForceArchiveForm
                                          form={formName}
                                          forceDelete
                                          onSubmit={action}
                                          initialValues={{ ids: forceOperationalEntityInfos.map(({ id }) => id) }}
                                        />
                                    )
                                }
                            </div>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        isForceOperationalAvailable
                        && (
                            <ButtonGroup>
                                <Button label="Cancel" onClick={hideModal} />
                                <Button
                                  label={`Force ${isPending ? pendingActionName : actionName}`}
                                  action
                                  warning
                                  loading={isPending}
                                  onClick={() => { submitAction(formName); }}
                                />
                            </ButtonGroup>
                        )
                    }
                    {
                        !isForceOperationalAvailable && isNonOperationalAvailable
                        && (
                            <Button
                              label="Okay"
                              action
                              onClick={hideModal}
                            />
                        )
                    }
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

const makeMapStateToProps = (state, ownProps) => ({
    isPending: isSubmitting(getFormName(ownProps.operationalType))(state)
});

EntityOperationInfoModal.propTypes = {
    operationalType: PropTypes.oneOf(['forceProfileDelete', 'forceGroupDelete', 'forceTagDelete', 'forceTagArchive', 'forceAdAccountDelete']).isRequired,
    nonOperationalEntityNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    forceOperationalEntityInfos: customPropTypes.forceOperationalEntityInfos.isRequired,
    submitAction: PropTypes.func.isRequired,
    isPending: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool
};

export default connect(makeMapStateToProps, {
    submitAction: submit,
})(EntityOperationInfoModal);
