import React from 'react';
import styles from 'src/stylesheets/listTable/listTable.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ListTable = (props) => {
    const {
        header,
        children,
        className
    } = props;
    return (
        <table className={classnames(styles.table, className)}>
            {header}
            <tbody>
                {children}
            </tbody>
        </table>
    );
};

ListTable.propTypes = {
    children: PropTypes.node.isRequired,
    header: PropTypes.node.isRequired,
    className: PropTypes.string
};

ListTable.defaultProps = {
    className: ''
};

export default ListTable;
