import { adAccountCustomizeFormAction } from 'src/actions/adAccounts';
import CustomizeAdAccount from 'src/components/CustomizeAdAccount';
import AdAccountGroupItemList from 'src/components/views/adAccounts/AdAccountGroupItemList';
import AdAccountInfoInDetailPage from 'src/components/views/adAccounts/AdAccountInfoInDetailPage';
import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import styles from 'src/stylesheets/profileDetailsPage.scss';
import AdAccountUseCases from 'src/components/AdAccountUseCases';
import AccountTypeContext from 'src/components/context/AccountTypeContext';
import { getFormNameByAccountNameAndContext } from 'src/utils/accountInsights';

const contextValues = { accountType: 'adAccounts', isDetailPage: true };

const AdAccountDetailsPage = (props) => {
    const { adAccount } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                <div className={styles.profileSection}>
                    <div className={styles.profileInfo}>
                        <AdAccountInfoInDetailPage adAccount={adAccount} />
                    </div>
                    <div className={styles.groupInfo}>
                        <AdAccountGroupItemList adAccountId={adAccount.id} className={styles.groupListItem} />
                    </div>
                    <CustomizeAdAccount onSubmit={adAccountCustomizeFormAction} adAccountId={adAccount.id} />
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.profileUseCaseEventAndHealths}>
                    <AccountTypeContext.Provider value={contextValues}>
                        <AdAccountUseCases
                          adAccount={adAccount}
                          formName={getFormNameByAccountNameAndContext('ad_account_detail_use_cases', adAccount.id)}
                          className={styles.spaceProfileUseCaseForm}
                        />
                    </AccountTypeContext.Provider>
                </div>
            </div>
        </div>
    );
};

AdAccountDetailsPage.propTypes = {
    adAccount: customPropTypes.adAccount.isRequired,
};

export default AdAccountDetailsPage;
