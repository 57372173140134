import React from 'react';
import DashboardSelectorComponent from 'src/components/DashboardSelector';
import PropTypes from 'prop-types';

const DashboardSelector = (props) => {
    const handleOnDashboardClick = (dashboardId) => {
        const { onChange } = props;
        onChange(dashboardId);
    };

    const { value, name, disabled } = props;

    return (
        <div>
            <DashboardSelectorComponent
              dashboardId={value}
              handleOnDashboardClick={handleOnDashboardClick}
              disabled={disabled}
            />
            <input name={name} value={value} type="hidden" />
        </div>
    );
};

DashboardSelector.propTypes = {
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    dashboardId: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool
};

DashboardSelector.defaultProps = {
    disabled: false,
    error: false
};

export default DashboardSelector;
