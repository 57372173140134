import { change, getFormValues, isSubmitting } from 'redux-form';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import memoizeOne from 'memoize-one';

const areAllFieldsSelected = memoizeOne((profileUseCaseFields, formValues) => profileUseCaseFields.every((field) => formValues[field] === true));

const SelectAndDeselectProfileUseCasesButtons = (props) => {
    const { formValues, isFormSubmitting } = props;
    const profileUseCaseFields = formValues !== undefined ? Object.keys(formValues) : [];

    const selectAllProfileUseCases = () => {
        const { changeAction, formName } = props;
        profileUseCaseFields.forEach((fieldName) => {
            changeAction(formName, fieldName, true);
        });
    };

    const unSelectAllProfileUseCase = () => {
        const { changeAction, formName } = props;
        profileUseCaseFields.forEach((fieldName) => {
            changeAction(formName, fieldName, false);
        });
    };

    if (areAllFieldsSelected(profileUseCaseFields, formValues)) {
        return (
            <Button
              label="Quick de-select all use cases"
              onClick={() => { unSelectAllProfileUseCase(); }}
              disabled={isFormSubmitting}
            />
        );
    }

    return (
        <Button
          label="Quick select all use cases"
          onClick={() => { selectAllProfileUseCases(); }}
          disabled={isFormSubmitting}
        />
    );
};

SelectAndDeselectProfileUseCasesButtons.propTypes = {
    changeAction: PropTypes.func.isRequired,
    formName: PropTypes.string.isRequired,
    isFormSubmitting: PropTypes.bool.isRequired,
    formValues: PropTypes.object
};

const mapStateToProps = (state, ownProps) => ({
    isFormSubmitting: isSubmitting(ownProps.formName)(state),
    formValues: getFormValues(ownProps.formName)(state)
});

export default connect(mapStateToProps, {
    changeAction: change
})(SelectAndDeselectProfileUseCasesButtons);
