import PropTypes from 'prop-types';
import React from 'react';
import { dashboardMetricMoveRequest } from 'src/actions/dashboardMetrics';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import { makeSelectDashboardMetricById } from 'src/selectors/dashboardMetrics';
import DashboardSearchableListModal from 'src/components/modals/DashboardSearchableListModal';
import _omit from 'lodash/omit';

const MoveDashboardMetricModal = (props) => {
    const { dashboardMetric } = props;
    const sourceDashboardId = dashboardMetric.dashboardId;
    const handleOnDashboardClick = (destinationDashboard) => {
        const { dashboardMetricMoveRequestAction } = props;
        const { id } = dashboardMetric;
        dashboardMetricMoveRequestAction(id, sourceDashboardId, destinationDashboard);
    };

    const cleanedProps = _omit(props, ['dashboardMetric', 'dashboardMetricMoveRequestAction']);
    return (
        <DashboardSearchableListModal
          {...cleanedProps}
          onDashboardClick={handleOnDashboardClick}
          disabledDashboardIds={[sourceDashboardId]}
        />
    );
};

MoveDashboardMetricModal.propTypes = {
    dashboardMetric: customPropTypes.dashboardMetric.isRequired,
    dashboardMetricMoveRequestAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectDashboardMetricById = makeSelectDashboardMetricById();
    return (state, ownProps) => {
        const dashboardMetric = selectDashboardMetricById(state, ownProps.dashboardMetricId);
        return {
            dashboardMetric,
        };
    };
};

export default connect(makeMapStateToProps, {
    dashboardMetricMoveRequestAction: dashboardMetricMoveRequest
})(MoveDashboardMetricModal);
