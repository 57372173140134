import * as customPropTypes from 'src/customPropTypes';
import ProfileSelectorLabel from 'src/components/profileSelector/ProfileSelectorLabel';
import PropTypes from 'prop-types';
import React from 'react';
import ProfileSelectorButtonIcon from 'src/components/ProfileSelectorButtonIcon';
import styles from 'src/stylesheets/dashboardShareLinks/profileSelection.scss';

const ProfileSelection = (props) => {
    const { selectedProfilesOrGroups } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.profileOrGroupIcon}>
                <ProfileSelectorButtonIcon selectedProfilesOrGroups={selectedProfilesOrGroups} />
            </div>
            <div className={styles.label}>
                <ProfileSelectorLabel selectedProfilesOrGroups={selectedProfilesOrGroups} />
            </div>
        </div>
    );
};

ProfileSelection.propTypes = {
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    disabled: PropTypes.bool
};

export default ProfileSelection;
