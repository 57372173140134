import Breadcrumb from 'src/components/breadcrumb/Breadcrumb';
import SuperRootHandler from 'src/components/folderBrowser/SuperRootHandler';
import FolderList from 'src/components/views/folders/FolderList';
import React from 'react';
import styles from 'src/stylesheets/folderBrowser/folderBrowser.scss';
import PropTypes from 'prop-types';
import { SUPER_ROOT } from 'src/utils/dashboardFolders';

const FolderBrowser = ({ toFolderId, onItemClick, movingFolderIds }) => (
    <>
        <div className={styles.breadcrumbWrapper}>
            <div className={styles.breadcrumbChild}>
                <Breadcrumb
                  fixedItemBefore={{ id: SUPER_ROOT, name: 'Dashboards' }}
                  small
                  currentFolderId={toFolderId}
                  onItemClick={onItemClick}
                />
            </div>
        </div>
        <div className={styles.body}>
            <SuperRootHandler toFolderId={toFolderId} setToFolderId={onItemClick}>
                <FolderList parentId={toFolderId} movingFolderIds={movingFolderIds} onFolderClick={onItemClick} />
            </SuperRootHandler>
        </div>
    </>
);

FolderBrowser.propTypes = {
    onItemClick: PropTypes.func.isRequired,
    toFolderId: PropTypes.string.isRequired,
    movingFolderIds: PropTypes.arrayOf(PropTypes.string)
};

FolderBrowser.defaultProps = {
    movingFolderIds: []
};

export default FolderBrowser;
