import { required } from 'src/components/forms/validators';
import * as customPropTypes from 'src/customPropTypes';
import { Text } from 'src/components/forms/fields';
import { Field } from 'redux-form';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';

const FolderEditForm = (props) => {
    const { handleSubmit, error } = props;
    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <Field
              autoFocus
              name="folderName"
              label="Folder name"
              component={Text}
              validate={required}
            />
        </form>
    );
};

FolderEditForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: customPropTypes.dataLoadingError
};

export default withReduxForm(FolderEditForm, { destroyOnUnmount: false });
