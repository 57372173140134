import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/userInvitationBox.scss';
import PropTypes from 'prop-types';

const UserInvitationBox = ({ children, cta }) => (
    <div className={styles.wrapper}>
        <div className={styles.header}><div>User Invitation </div>{ cta && <div>{cta}</div> }</div>
        {children}
    </div>
);

UserInvitationBox.propTypes = {
    children: customPropTypes.children.isRequired,
    cta: PropTypes.node
};

export default UserInvitationBox;
