import { put, takeEvery } from 'redux-saga/effects';
import { MODAL_HIDE_AS_ROUTE, MODAL_SHOW_AS_ROUTE } from 'src/actions/overlays';
import * as routerActions from 'react-router-redux';
import { browserHistory } from 'react-router';
import _startsWith from 'lodash/startsWith';

function* modalsHideRedirect(action) {
    const { payload } = action;
    const { pathname, hash: hashFromRoute, query } = browserHistory.getCurrentLocation();
    const { modalType, idProp, ignoreIdProp } = payload;
    if (ignoreIdProp === true) {
        if (_startsWith(hashFromRoute, `#${modalType}`)) {
            yield put(routerActions.push({ pathname, query }));
        }
    } else {
        const hashToRemove = idProp ? `${modalType}-${idProp}` : modalType;
        if (`#${hashToRemove}` === hashFromRoute) {
            yield put(routerActions.push({ pathname, query }));
        }
    }
}

function* modalsShowRedirect(action) {
    const { payload } = action;
    const { pathname, query } = browserHistory.getCurrentLocation();
    const { modalType, idProp } = payload;
    const hashToShow = idProp ? `${modalType}-${idProp}` : modalType;
    yield put(routerActions.push({ pathname, query, hash: `#${hashToShow}` }));
}

export default function* modalsSagas() {
    yield takeEvery(MODAL_HIDE_AS_ROUTE, modalsHideRedirect);
    yield takeEvery(MODAL_SHOW_AS_ROUTE, modalsShowRedirect);
}
