import { Checkbox } from 'src/components/forms/fields';
import { customRequired } from 'src/components/forms/validators';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';

const required = (value) => customRequired(value, 'You must check this field to continue');

const AuthUserInAnotherSpaceConfirmForm = (props) => {
    const { handleSubmit, networkName } = props;
    return (
        <form onSubmit={handleSubmit}>
            <Field
              name="confirm"
              text={`I am fine with my ${networkName} data being used across spaces.`}
              component={Checkbox}
              validate={required}
            />
        </form>
    );
};

AuthUserInAnotherSpaceConfirmForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    networkName: PropTypes.string.isRequired
};

export default withReduxForm(AuthUserInAnotherSpaceConfirmForm);
