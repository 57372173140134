import FilterTokenInput from 'src/components/forms/inputs/layout/FilterTokenInput';
import withPopover from 'src/components/withPopover';
import PropTypes from 'prop-types';
import React from 'react';
import FilterToken from 'src/components/FilterToken';
import TextFilterPopover from 'src/components/popovers/TextFilterPopover';

const FilterTokenWithPopover = withPopover(FilterToken);

const TextFilterTokenInput = ({
    value, onChange, wrapElements, disabled, addActionLabel, editActionLabel, buttonLabel
}) => (
    <FilterTokenInput
      onChange={onChange}
      wrapElements={wrapElements}
      disabled={disabled}
      renderToken={(entry, onEdit, onDelete) => (
          <FilterTokenWithPopover
            placement="bottom-start"
            showTooltip={!wrapElements}
            overlay={(
                <TextFilterPopover
                  actionLabel={editActionLabel}
                  defaultValue={entry}
                  onSubmit={onEdit}
                  selectedValues={value}
                />
            )}
            label={entry}
            onClick={() => {}}
            key={entry}
            onDeleteClick={onDelete}
          />
      )}
      renderAddOverlay={(onAdd) => (
          <TextFilterPopover
            onSubmit={onAdd}
            actionLabel={addActionLabel}
            selectedValues={value}
          />
      )}
      value={value}
      buttonLabel={buttonLabel}
    />
);

TextFilterTokenInput.propTypes = {
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    wrapElements: PropTypes.bool,
    disabled: PropTypes.bool,
    addActionLabel: PropTypes.string,
    editActionLabel: PropTypes.string,
    buttonLabel: PropTypes.string
};

TextFilterTokenInput.defaultProps = {
    wrapElements: false,
    disabled: false,
    addActionLabel: 'Add text filter',
    editActionLabel: 'Edit text filter',
    buttonLabel: ''
};

export default TextFilterTokenInput;
