import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/profileSearch.scss';
import ProfileSearchLoadingListIndicator from 'src/components/loadingIndicators/ProfileSearchLoadingListIndicator';
import Feedback from 'src/components/feedback/Feedback';
import FeedbackLinkPlain from 'src/components/feedback/FeedbackLinkPlain';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import Button from 'src/components/buttons/Button';
import { profileNamesByPlatformType } from 'src/utils/profiles';
import _get from 'lodash/get';

const AuthenticatedSearchAccountResult = (props) => {
    const {
        result,
        tryAgainAction,
        renderResultList,
        onBackClick
    } = props;
    const {
        network, loading, error, data
    } = result;
    if (loading) {
        return <ProfileSearchLoadingListIndicator />;
    } if (error) {
        const link = <FeedbackLinkPlain onClick={() => { tryAgainAction(network); }}>Try again</FeedbackLinkPlain>;
        return (
            <div>
                <div>Something went wrong</div>
                <hr className={styles.spacer} />
                <Feedback
                  type="error"
                  content={error.message}
                  link={link}
                />
            </div>
        );
    } if (data) {
        return (
            <div>
                <div>Showing {data.length} result</div>
                <hr className={styles.spacer} />
                {
                    data.length > 0 && renderResultList(data)
                }
                {
                    data.length === 0 && (
                        <EmptyInfoScreen
                          title={`There are no ${_get(profileNamesByPlatformType, network, '')} pages managed by this user`}
                          buttons={(<Button label="Back to search" onClick={onBackClick} />)}
                        />
                    )
                }
            </div>
        );
    }
    return null;
};

AuthenticatedSearchAccountResult.propTypes = {
    tryAgainAction: PropTypes.func.isRequired,
    onBackClick: PropTypes.func.isRequired,
    renderResultList: PropTypes.func.isRequired,
    result: customPropTypes.accountSearchResult.isRequired
};

export default AuthenticatedSearchAccountResult;
