import _get from 'lodash/get';

const unHealthyProfileTypes = {
    facebook: {
        migrated: {
            content: 'This Facebook Page was migrated. Facebook does not offer data for migrated Facebook Pages. You might want to remove this Page from quintly and add the new Page this one was migrated to instead.',
            tooltip: 'Migrated Facebook Page'
        },
        not_exist: {
            content: 'This Facebook Page does not exist anymore. If you do not need access to historic data of this Page, you might want to remove it from quintly.',
            tooltip: 'Removed Facebook Page'
        }
    },
    instagram: {
        not_exist_or_personal_account: {
            content: 'This is either Instagram Personal Account, or the account does not exist anymore. If this is your own profile and it is Instagram Personal Account, you can <a href="https://www.facebook.com/business/help/502981923235522" target="_blank">convert your personal profile to Instagram Professional Account.</a>',
            tooltip: 'Instagram Personal Account'
        },
        id_mismatch: {
            content: "Instagram's identifier for this profile does not match the one within quintly. Please re-add this profile or remove it from quintly.",
            tooltip: 'Invalid Instagram Professional Account'
        },
        only_general_link_to_facebook_page: {
            content: 'This Instagram Professional Account is not properly linked to the Facebook Page. Facebook requires Instagram Professional Account to be linked to a Facebook Page to access private statistics. Please <a href="https://help.facelift-cloud.com/en/articles/8601569-re-linking-an-instagram-account-with-its-related-facebook-page" target="_blank">relink the Instagram Professional Account with the Facebook Page</a> in the Page’s settings on Facebook.',
            tooltip: 'Broken connection between Instagram Professional Account and Facebook Page'
        },
        no_link_to_facebook_page: {
            content: 'This Instagram Professional Account has no connection to a Facebook Page. Facebook requires Instagram Professional Account to be linked to a Facebook Page to access private statistics. Please <a href="https://help.facelift-cloud.com/en/articles/8601569-re-linking-an-instagram-account-with-its-related-facebook-page" target="_blank">link the Instagram Professional Account with a Facebook Page</a> in the Page’s settings on Facebook.',
            tooltip: 'No connection between Instagram Professional Account and Facebook Page'
        },
        link_to_facebook_page_mismatch: {
            content: 'This Instagram Professional Account is linked to a different Facebook Page than expected.',
            tooltip: 'Connection to an unexpected Facebook Page'
        }
    },
    linkedIn: {
        not_exist: {
            content: 'This LinkedIn Company Page does not exist anymore. If historic data is not required, you might want to remove it from quintly.',
            tooltip: 'Removed LinkedIn Company Page'
        }
    },
    youtube: {
        not_exist: {
            content: 'This YouTube channel does not exist anymore. If historic data is not required, you might want to remove it from quintly.',
            tooltip: 'Removed YouTube channel'
        }
    },
    snapchatShow: {
        not_exist: {
            content: 'This Snapchat Show does not exist anymore. If historic data is not required, you might want to remove it from quintly.',
            tooltip: 'Removed Snapchat Show'
        }
    },
    tiktok: {
        not_exist: {
            content: 'This TikTok account does not exist anymore. If historic data is not required, you might want to remove it from quintly.',
            tooltip: 'Removed TikTok account'
        }
    }
};

export const getUnHealthyProfileInfo = (platformType, unHealthyType) => _get(unHealthyProfileTypes, [platformType, unHealthyType], null);
