import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import AccountUseCasesTableRows from 'src/components/forms/accountUseCasesTable/AccountUseCasesTableRows';
import AccountDetailUseCasesTableHeader from 'src/components/forms/accountUseCasesTable/AccountDetailUseCasesTableHeader';
import { getUseCaseFields } from 'src/utils/accountInsights';
import _memoize from 'memoize-one';
import _uniq from 'lodash/uniq';
import ListTable from 'src/components/listTable/ListTable';
import styles from 'src/stylesheets/profileUseCasesTable.scss';

const getAuthUserIds = _memoize((authUserAccountUseCases, authUsersAuthAccountUseCases) => {
    const authUserIds = [];
    authUserAccountUseCases.forEach(({ userId }) => {
        authUserIds.push(userId);
    });

    authUsersAuthAccountUseCases.forEach(({ userId }) => {
        authUserIds.push(userId);
    });
    return _uniq(authUserIds);
});

const memoizedGetUseCaseFields = _memoize(getUseCaseFields);

const AccountUseCaseForm = (props) => {
    const {
        account,
        handleSubmit,
        submitting,
        platformTypeUseCases
    } = props;

    const { useCases, accountUseCasesAuthUsers, authUsersAuthAccountUseCases } = account;
    const useCaseFormFields = memoizedGetUseCaseFields(platformTypeUseCases, useCases, accountUseCasesAuthUsers, authUsersAuthAccountUseCases);

    return (
        <form onSubmit={handleSubmit}>
            <div className={styles.table}>
                <ListTable header={(
                    <AccountDetailUseCasesTableHeader
                      account={account}
                      useCaseFormFields={useCaseFormFields}
                      submitting={submitting}
                    />
                )}
                >
                    <AccountUseCasesTableRows
                      account={account}
                      useCaseFormFields={useCaseFormFields}
                      authUserIds={getAuthUserIds(accountUseCasesAuthUsers, authUsersAuthAccountUseCases)}
                    />
                </ListTable>
            </div>
        </form>
    );
};

AccountUseCaseForm.propTypes = {
    account: customPropTypes.account.isRequired,
    platformTypeUseCases: customPropTypes.useCases.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export default withReduxForm(AccountUseCaseForm, {
    enableReinitialize: true
});
