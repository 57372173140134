import AccountInfoListItem from 'src/components/account/layout/AccountInfoListItem';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';

const ProfileInfoListItem = (props) => {
    const { profile, highlight } = props;
    const {
        defaultName, platformType, thumbnail, appendix
    } = profile;
    return (
        <AccountInfoListItem
          thumbnail={thumbnail}
          name={defaultName}
          platformType={platformType}
          highlight={highlight}
          appendix={appendix}
        />
    );
};

ProfileInfoListItem.propTypes = {
    profile: PropTypes.oneOfType([customPropTypes.profile, customPropTypes.searchedProfile, customPropTypes.authProfile]).isRequired,
    highlight: PropTypes.string
};

ProfileInfoListItem.defaultProps = {
    highlight: ''
};

export default ProfileInfoListItem;
