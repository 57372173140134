import _get from 'lodash/get';

const infoLookUp = {
    facebook: {
        globalChild: {
            title: 'Market Page',
            content: 'This Facebook Page is a Market Page within a Global Page structure. The public Page Likes shown in our tool will reflect the global Page Likes across the whole Global Page. If you connect this page to Facebook insights the Page Likes will reflect the count of users assigned to this specific Market Page. <br /> <br /> <a href="https://help.facelift-cloud.com/en/articles/8624651-analyzing-facebook-market-pages" target="_blank">Learn more about the Global Page construct</a>',
            icon: 'global',
            tooltip: 'This is a market Page belonging to a Global Page.'
        },
        community: {
            title: 'Community Page',
            content: 'This Facebook Page is a Community Page. Please see Facebook’s documentation for further information about Community Pages. <br/> <br/> <a href="https://www.facebook.com/help/151753258228443" target="_blank">View Facebook documentation</a>',
            icon: 'community',
            tooltip: 'This is a community page.'
        },
    },
    linkedIn: {
        showcase: {
            title: 'Showcase Page',
            content: 'This LinkedIn Page is a Showcase Page. Please see LinkedIn’s documentation for further information about Showcase Pages. <br /> <br /> <a href="https://business.linkedin.com/marketing-solutions/company-pages/showcase-pages" target="_blank">View LinkedIn documentation</a>',
            icon: 'grid',
            tooltip: 'This is a showcase page.'
        }
    }
};

const getPlatformProfileTypeInfo = (platformType, platformProfileType) => _get(infoLookUp, [platformType, platformProfileType]);

export default getPlatformProfileTypeInfo;
