import Button from 'src/components/buttons/Button';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { makeSelectDashboardMetricById, selectIsUpdateNotePending } from 'src/selectors/dashboardMetrics';
import * as customPropTypes from 'src/customPropTypes';
import MetricNameHeaderWithIcons from 'src/components/MetricNameHeaderWithIcons';
import _get from 'lodash/get';
import MetricPersonalNotes from 'src/components/MetricPersonalNotes';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import styles from 'src/stylesheets/modals/dashboardMetricNoteModal.scss';
import { dashboardMetricsUpdateNotesRequest } from 'src/actions/dashboardMetrics';
import { selectIsInEditMode, setEditMode } from 'src/reducers/slices/dashboardMetricNoteEdit';
import RichTextEditor from 'react-rte';

const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
    ]
};

const DashboardMetricNoteModal = (props) => {
    const {
        hideModal, enforceFocus, editMode, dashboardMetric, onSaveClick, setEditModeAction, isLoading, readOnly
    } = props;

    const inEditModeState = useSelector((state) => selectIsInEditMode(state, dashboardMetric.id));
    useEffect(() => {
        setEditModeAction(dashboardMetric.id, editMode);
    }, []);
    const [notesState, setNotesState] = useState(RichTextEditor.createValueFromString(_get(dashboardMetric, 'settings.notes', ''), 'html'));

    return (
        <Modal dataViewName="dashboard-metric-notes" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog width={540}>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        <MetricNameHeaderWithIcons name={dashboardMetric.displayName} platformType={_get(dashboardMetric, 'metric.platformType')} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body height={412}>
                    {inEditModeState && (
                    <RichTextEditor
                      value={notesState}
                      onChange={(value) => { setNotesState(value); }}
                      toolbarConfig={toolbarConfig}
                      placeholder="These notes will be shown alongside the metric, visible to everyone with access to this dashboard."
                      autoFocus
                      editorClassName={styles.innerEditor}
                      className={styles.outerEditor}
                      enableSoftNewLineByDefault
                    />
                    )}
                    {!inEditModeState && <MetricPersonalNotes notes={dashboardMetric.settings.notes} />}
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button label="Close" onClick={hideModal} disabled={isLoading} />
                        {!readOnly && (
                            <>
                                {
                                    inEditModeState && (
                                    <Button
                                      disabled={isLoading}
                                      label="Save"
                                      onClick={() => {
                                          const hasContent = !!notesState.getEditorState().getCurrentContent().getPlainText().trim();
                                          onSaveClick(dashboardMetric.id, dashboardMetric.dashboardId, !hasContent ? '' : notesState.toString('html'));
                                      }}
                                      action
                                    />
                                    )
                            }
                                {
                                !inEditModeState && <Button disabled={isLoading} label="Edit" action onClick={() => { setEditModeAction(dashboardMetric.id, true); }} />
                            }
                            </>
                        )}
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

DashboardMetricNoteModal.propTypes = {
    enforceFocus: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    dashboardMetric: customPropTypes.dashboardMetric.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    setEditModeAction: PropTypes.func,
    isLoading: PropTypes.bool,
    readOnly: PropTypes.bool
};

DashboardMetricNoteModal.defaultProps = {
    editMode: false,
    isLoading: false,
    readOnly: false
};

const makeMapStateToProps = () => {
    const selectDashboardMetricById = makeSelectDashboardMetricById();
    return (state, ownProps) => ({
        dashboardMetric: selectDashboardMetricById(state, ownProps.dashboardMetricId),
        isLoading: selectIsUpdateNotePending(state, ownProps.dashboardMetricId)
    });
};

const ConnectedDashboardMetricNoteModal = connect(makeMapStateToProps, {
    onSaveClick: dashboardMetricsUpdateNotesRequest,
    setEditModeAction: setEditMode
})(DashboardMetricNoteModal);

ConnectedDashboardMetricNoteModal.propTypes = {
    dashboardMetricId: PropTypes.string.isRequired
};

export default ConnectedDashboardMetricNoteModal;
