import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import _isArray from 'lodash/isArray';
import _isFunction from 'lodash/isFunction';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/editableContent.scss';
import Text from 'src/components/forms/inputs/Text';

class EditableContent extends Component {
    constructor(props) {
        super(props);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleEditModeLeft = this.handleEditModeLeft.bind(this);
        this.handleEditModeEntered = this.handleEditModeEntered.bind(this);
        this.handleOnLeave = this.handleOnLeave.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleOnValueChange = this.handleOnValueChange.bind(this);
        this.validate = this.validate.bind(this);
        this.state = { error: undefined };
    }

    componentDidMount() {
        const { editMode } = this.props;
        if (editMode === true) {
            this.handleEditModeEntered();
        }
    }

    componentDidUpdate(prevProps) {
        const { editMode } = this.props;
        if (prevProps.editMode === true && editMode === false) {
            this.handleEditModeLeft();
        }
        if (editMode === true && prevProps.editMode === false) {
            this.handleEditModeEntered();
        }
    }

    componentWillUnmount() {
        const { editMode } = this.props;
        if (editMode === true) {
            this.handleEditModeLeft();
        }
    }

    handleOnChange() {
        const { onChange } = this.props;
        const { editInput } = this;
        const newValue = editInput.getRef().value.trim();
        onChange(newValue);
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.handleOnChangeAndLeave();
        }
    }

    // Don't bind this event as 'onKeyUp' Prop, cause it needs to stop bubbling the event to modals etc
    handleEditModeEntered() {
        this.editInput.getRef().addEventListener('keydown', this.handleKeyDown);
    }

    handleEditModeLeft() {
        if (this.editInput) {
            this.editInput.getRef().removeEventListener('keydown', this.handleKeyDown);
        }
    }

    handleKeyDown(event) {
        event.stopPropagation();
        if (event.keyCode === 27) {
            this.handleOnLeave();
        }
    }

    handleOnLeave() {
        const { onLeave } = this.props;
        onLeave();
    }

    handleOnChangeAndLeave() {
        if (this.isAllowedToLeave()) {
            if (this.hasValueChanged()) {
                this.handleOnChange();
            }
            this.handleOnLeave();
        }
    }

    handleOnBlur() {
        const { changeOnBlur } = this.props;
        if (changeOnBlur) {
            this.handleOnChangeAndLeave();
        } else {
            this.handleOnLeave();
        }
    }

    handleOnValueChange(event) {
        this.validate(event.target.value.trim());
    }

    validate(value) {
        const { validate } = this.props;
        if (validate) {
            const { error: hasError } = this.state;

            let error;
            if (_isFunction(validate)) {
                error = validate(value);
            }

            if (_isArray(validate)) {
                let i = 0;
                while (i < validate.length && !error) {
                    error = validate[i](value);
                    i += 1;
                }
            }

            if (error !== hasError) {
                this.setState({ error });
            }
        }
    }

    hasValueChanged() {
        const { value } = this.props;
        const { editInput } = this;
        const newValue = editInput.getRef().value.trim();
        return (newValue !== value);
    }

    isAllowedToLeave() {
        const { error } = this.state;
        return !error;
    }

    render() {
        const {
            value, editMode, loading, onDoubleClick, showErrorOnTheRight, children
        } = this.props;
        const { error } = this.state;
        const defaultValue = this.editInput ? this.editInput.getRef().value.trim() : value;

        return (
            <div className={styles.wrapper}>
                {
                    !editMode && !loading
                    && (
                        <div onDoubleClick={onDoubleClick}>
                            {children}
                        </div>
                    )
                }
                {
                    (editMode || loading)
                    && (
                        <div className={classnames({ [styles.inputBoxWithErrorOnBottom]: !showErrorOnTheRight, [styles.inputBoxWithErrorOnRight]: showErrorOnTheRight })}>
                            <Text
                              ref={(c) => { this.editInput = c; }}
                              onKeyPress={this.handleKeyPress}
                              onBlur={this.handleOnBlur}
                              defaultValue={defaultValue}
                              autoFocus
                              disabled={loading}
                              loading={loading}
                              error={!!error}
                              onChange={this.handleOnValueChange}
                              className={styles.input}
                            />
                            { error && <div className={styles.error}>{error}</div> }
                        </div>
                    )
                }
            </div>
        );
    }
}

EditableContent.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onLeave: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    children: customPropTypes.children.isRequired,
    changeOnBlur: PropTypes.bool,
    loading: PropTypes.bool,
    onDoubleClick: PropTypes.func,
    validate: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.func)
    ]),
    showErrorOnTheRight: PropTypes.bool
};

EditableContent.defaultProps = {
    editMode: false,
    changeOnBlur: true,
    loading: false,
    validate: [],
    onDoubleClick: () => {},
    showErrorOnTheRight: false
};

export default EditableContent;
