import React from 'react';
import Button from 'src/components/buttons/Button';
import Modal from 'src/components/modals/layout/Modal';
import styles from 'src/stylesheets/modals/postDetailModal.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';
import { getPostLinkLabel } from 'src/utils/postSearch';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';

const PostDetailFooter = (props) => {
    const { post, handleOnModalClose } = props;
    const { link, postTypeName } = post;
    const footerLeft = link
        ? (
            <a href={link} className={styles.link} target="_blank" rel="noopener noreferrer">
                <IcomoonIcon icon="vist-page" className={styles.icon} />{getPostLinkLabel(postTypeName)}
            </a>
        )
        : null;
    return (
        <Modal.Footer buttonsLeft={footerLeft}>
            <Button label="Close" onClick={handleOnModalClose} />
        </Modal.Footer>
    );
};

PostDetailFooter.propTypes = {
    post: customPropTypes.post.isRequired,
    handleOnModalClose: PropTypes.func.isRequired
};

export default PostDetailFooter;
