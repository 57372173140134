import { createSelector } from 'reselect';
import { defaultLoadingState } from 'src/selectors/utils';
import _get from 'lodash/get';

export const getProfileHealthMetricDataFromStore = (state) => state.profileHealth.healthMetricDataById;
export const getProfileStatusFromStore = (state) => state.profileHealth.statusById;
export const getAsyncStates = (state) => state.profileHealth.asyncStates;

export const makeSelectProfileHealthMetricDataByProfileId = () => createSelector(
    [
        getProfileHealthMetricDataFromStore,
        (_, profileId) => profileId
    ],
    (profileHealths, profileId) => profileHealths[profileId] || { yAxisCategories: [], metrics: [] }
);

export const makeSelectHealthDataAsyncStateByProfileId = () => createSelector(
    [
        getAsyncStates,
        (_, profileId) => profileId
    ],
    (asyncStates, profileId) => _get(asyncStates, ['healthData', profileId], defaultLoadingState)
);

export const makeSelectIsProfileHealthy = () => createSelector(
    [
        getProfileStatusFromStore,
        (_, profileId) => profileId
    ],
    (statusById, profileId) => _get(statusById, [profileId, 'isHealthy']) || false
);

export const makeSelectProfileHealthByProfileId = () => createSelector(
    [
        getProfileStatusFromStore,
        (_, profileId) => profileId
    ],
    (statusById, profileId) => _get(statusById, profileId) || null
);

export const selectIsInstagramProfileConverting = createSelector(
    [
        getAsyncStates
    ],
    (asyncActions) => _get(asyncActions, ['convertInstagramProfile', 'isConverting', 'isPending'], false)
);
