import * as customPropTypes from 'src/customPropTypes';
import AuthTransactionRequirementsFormTable from 'src/components/forms/authTransaction/AuthTransactionRequirementsFormTable';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/forms/createAuthTransactionRequirementsForm.scss';
import withReduxForm from 'src/components/forms/withReduxForm';
import { platformTypeOrder } from 'src/utils/authTransactionUtils';

const CreateAuthTransactionRequirementsForm = (props) => {
    const {
        profilesByPlatformType,
        adAccountsByPlatformType,
        handleSubmit,
        submitting
    } = props;
    return (
        <form onSubmit={handleSubmit} className={styles.wrapper}>
            {
                platformTypeOrder.map((platformType) => {
                    const profiles = profilesByPlatformType[platformType] || [];
                    const adAccounts = adAccountsByPlatformType[platformType] || [];
                    if (profiles.length > 0 || adAccounts.length > 0) {
                        return (
                            <div className={classnames(styles.section, styles.bottomBorder)} key={platformType}>
                                <AuthTransactionRequirementsFormTable
                                  profiles={profiles}
                                  adAccounts={adAccounts}
                                  platformType={platformType}
                                  disabled={submitting}
                                />
                            </div>
                        );
                    }
                    return null;
                })
            }
        </form>
    );
};

CreateAuthTransactionRequirementsForm.propTypes = {
    adAccountsByPlatformType: customPropTypes.adAccountsByPlatformType.isRequired,
    profilesByPlatformType: customPropTypes.profilesByPlatformType.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
};

export default withReduxForm(CreateAuthTransactionRequirementsForm);
