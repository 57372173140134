import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { makeSelectPostById } from 'src/selectors/posts';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import { makeSelectProfileById } from 'src/selectors/profiles';
import * as postActions from 'src/actions/posts';
import PostDetailBody from 'src/components/modals/partials/PostDetail';
import PostDetailFooter from 'src/components/modals/partials/PostDetailFooter';
import PostDetailHeader from 'src/components/modals/partials/PostDetailHeader';
import PostTagManagerPostDetailScroller from 'src/components/postDetailModalScroller/PostTagManagerPostDetailScroller';
import PostPostTagList from 'src/components/views/postTags/postPostTagList/PostPostTagList';

const PostDetailInPostTagManagerModal = (props) => {
    const {
        post, postTagId, profile, hideModal, enforceFocus
    } = props;

    const handleOnModalClose = () => {
        hideModal();
    };
    const { defaultName } = profile;

    const renderScroller = () => (
        <PostTagManagerPostDetailScroller postId={post.id} postTagId={postTagId} />
    );

    const renderPostPostTagList = () => <PostPostTagList post={post} />;

    return (
        <Modal dataViewName="post-detail-in-post-tag-manager-modal" onHide={handleOnModalClose} enforceFocus={enforceFocus} onBackdropClick={handleOnModalClose} withBackDropSupport>
            <Modal.Dialog width={800}>
                <PostDetailHeader post={post} profileName={defaultName} handleOnModalClose={handleOnModalClose} renderScroller={renderScroller} />
                <PostDetailBody post={post} profile={profile} renderPostPostTagList={renderPostPostTagList} />
                <PostDetailFooter post={post} handleOnModalClose={handleOnModalClose} />
            </Modal.Dialog>
        </Modal>
    );
};

PostDetailInPostTagManagerModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    postId: PropTypes.string.isRequired,
    postTagId: PropTypes.string.isRequired,
    post: customPropTypes.post.isRequired,
    profile: PropTypes.oneOfType([customPropTypes.profile, PropTypes.bool]).isRequired
};

const makeMapStateToProps = () => {
    const selectPostById = makeSelectPostById();
    const selectProfileById = makeSelectProfileById();
    return (state, ownProps) => {
        const post = selectPostById(state, ownProps.postId);
        const profile = post ? selectProfileById(state, post.profileId) : false;
        return {
            post,
            profile
        };
    };
};

export default connect(makeMapStateToProps, {
    getPostRequestAction: postActions.getPostRequest,
    searchPostsRequestAction: postActions.searchPostsRequest
})(PostDetailInPostTagManagerModal);
