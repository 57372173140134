import * as customPropTypes from 'src/customPropTypes';
import AuthTransactionsDetailTable from 'src/components/authTransactions/AuthTransactionsDetailTable';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import { makeSelectOpenedAndClosedTransactions } from 'src/selectors/authTransactions';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';

const AuthTransactionsDetailModal = (props) => {
    const {
        hideModal,
        enforceFocus,
        openedAuthTransactions,
        closedAuthTransactions
    } = props;

    return (
        <Modal dataViewName="auth-transactions-detail" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog width={1000}>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Authentications</Modal.Title>
                </Modal.Header>
                <Modal.Body padded={false} height={476}>
                    <AuthTransactionsDetailTable
                      openedAuthTransactions={openedAuthTransactions}
                      closedAuthTransactions={closedAuthTransactions}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={hideModal} label="Close" />
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

AuthTransactionsDetailModal.propTypes = {
    openedAuthTransactions: customPropTypes.authTransactions.isRequired,
    closedAuthTransactions: customPropTypes.authTransactions.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectOpenedAndClosedTransactions = makeSelectOpenedAndClosedTransactions();
    return (state) => {
        const { openedAuthTransactions, closedAuthTransactions } = selectOpenedAndClosedTransactions(state);
        return {
            openedAuthTransactions,
            closedAuthTransactions
        };
    };
};

export default connect(makeMapStateToProps)(AuthTransactionsDetailModal);
