import Modal from 'src/components/modals/layout/Modal';
import PostInfo from 'src/components/views/postTags/PostInfo';
import React from 'react';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import ProfileInfoListItem from 'src/components/views/profiles/ProfileInfoListItem';
import styles from 'src/stylesheets/modals/postDetailModal.scss';
import PostKPIs from 'src/components/views/postTags/PostKPIs';
import * as postActions from 'src/actions/posts';
import PostImage from 'src/components/views/postTags/postTagging/PostImage';
import PostContent from 'src/components/PostContent';
import PropTypes from 'prop-types';

const PostDetailBody = (props) => {
    const {
        post, profile, highlightKeywords, renderPostPostTagList
    } = props;
    const { contentItems, image, kpis } = post;
    return (
        <Modal.Body height={420}>
            <div className={styles.wrapper}>
                <div className={styles.imageWrapper}>
                    <PostImage url={image} width={380} height={380} />
                </div>
                <div className={styles.detail}>
                    <div className={styles.profileInfo}>
                        <div className={styles.profileListItemWrapper}>
                            <ProfileInfoListItem profile={profile} />
                        </div>
                        <div className={styles.postInfo}>
                            <PostInfo post={post} />
                        </div>
                    </div>
                    <div className={styles.postContentWrapper}>
                        <PostContent items={contentItems} highlightKeywords={highlightKeywords} />
                    </div>
                    <div className={styles.kpis}>
                        <PostKPIs postKpis={kpis} />
                    </div>
                    <div className={styles.postTags}>
                        {
                            renderPostPostTagList()
                        }
                    </div>
                </div>
            </div>
        </Modal.Body>
    );
};

PostDetailBody.propTypes = {
    post: customPropTypes.post.isRequired,
    profile: customPropTypes.profile.isRequired,
    highlightKeywords: PropTypes.arrayOf(PropTypes.string),
    renderPostPostTagList: PropTypes.func.isRequired
};

PostDetailBody.defaultProps = {
    highlightKeywords: []
};

export default connect(null, {
    getPostRequestAction: postActions.getPostRequest,
    searchPostsRequestAction: postActions.searchPostsRequest
})(PostDetailBody);
