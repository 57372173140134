import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/stickyTableHeader.scss';
import classnames from 'classnames';

const StickyTableHeader = (props) => {
    const { children, offsetFromPageHeader, className } = props;
    return (
        <thead className={classnames(styles.tableHeader, { [styles.offsetFromPageHeader]: offsetFromPageHeader }, className)}>
            {children}
        </thead>
    );
};

StickyTableHeader.propTypes = {
    children: PropTypes.node.isRequired,
    offsetFromPageHeader: PropTypes.bool,
    className: PropTypes.string
};

StickyTableHeader.defaultProps = {
    offsetFromPageHeader: false,
    className: ''
};

export default StickyTableHeader;
