import React from 'react';
import { connect } from 'react-redux';
import { makeSelectProfileSearchedById } from 'src/selectors/profileSearch';
import ProfileInfoListItem from 'src/components/views/profiles/ProfileInfoListItem';
import * as customPropTypes from 'src/customPropTypes';

const CheckedProfileInfoItem = (props) => {
    const { searchedProfile } = props;
    return <ProfileInfoListItem profile={searchedProfile} />;
};

const makeMapStateToProps = () => {
    const selectProfileSearchedById = makeSelectProfileSearchedById();
    return (state, ownProps) => ({
        searchedProfile: selectProfileSearchedById(state, ownProps.id)
    });
};

CheckedProfileInfoItem.propTypes = {
    searchedProfile: customPropTypes.searchedProfile.isRequired
};

export default connect(makeMapStateToProps)(CheckedProfileInfoItem);
