import * as customPropTypes from 'src/customPropTypes';
import _upperFirst from 'lodash/upperFirst';
import { connect } from 'react-redux';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';
import { makeSelectPlatformPermissionsByIds } from 'src/selectors/platformPermissions';
import { selectUseCaseById } from 'src/selectors/useCases';
import styles from 'src/stylesheets/popovers/useCaseDescriptionPopover.scss';

const UseCaseDescriptionPopover = (props) => {
    const { useCase, platformPermissions } = props;
    const { name, platformType, description } = useCase;
    return (
        <Popover id="useCaseDescriptionPopover">
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    {`${_upperFirst(platformType)} > ${name}`}
                </div>
                <div className={styles.description}>
                    {description}
                </div>
                <div className={styles.requiredPermissions}>
                    <b>Required permissions: </b>
                    {
                        platformPermissions.map((platformPermission, i) => (
                            <span key={platformPermission.name}>
                                <a
                                  href={platformPermission.documentationLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={styles.link}
                                >
                                    {platformPermission.name}
                                </a>
                                {
                                    i !== platformPermissions.length - 1
                                    && <span>, </span>
                                }
                            </span>
                        ))
                    }
                </div>
                <a
                  href="https://help.facelift-cloud.com/en/articles/8601551-what-are-private-statistic-use-cases"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                    Learn more
                </a>
            </div>
        </Popover>
    );
};

UseCaseDescriptionPopover.propTypes = {
    useCaseId: PropTypes.string.isRequired,
    useCase: customPropTypes.useCase.isRequired,
    platformPermissions: customPropTypes.platformPermissions.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const { useCaseId } = ownProps;
    const selectPlatformPermissionsByIds = makeSelectPlatformPermissionsByIds();
    const useCase = selectUseCaseById(state, useCaseId);
    return {
        useCase,
        platformPermissions: selectPlatformPermissionsByIds(state, useCase.platformPermissionIds)
    };
};

export default connect(mapStateToProps)(UseCaseDescriptionPopover);
