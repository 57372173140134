import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';

const ErrorModal = (props) => {
    const { error, handleError, enforceFocus } = props;

    return (
        <Modal dataViewName="error" onHide={handleError} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={handleError}>
                    <Modal.Title>
                        {error.title || 'Error'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error.message}
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button action label="Ok" onClick={handleError} />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

ErrorModal.propTypes = {
    error: customPropTypes.error.isRequired,
    handleError: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool
};

export default ErrorModal;
