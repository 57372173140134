import * as customPropTypes from 'src/customPropTypes';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/modal.scss';
import withTooltip from 'src/components/withToolTip';
import classnames from 'classnames';

const IconWithTooltip = withTooltip(IcomoonIcon);

const ModalHeader = (props) => {
    const {
        leftContent,
        children,
        onHide,
        disabled
    } = props;
    return (
        <div className={styles.header}>
            {
                leftContent
                    && (
                    <div className={styles.leftContent}>
                        {leftContent}
                    </div>
                    )
            }
            <div className={styles.content}>
                {children}
            </div>
            {
                onHide
                && (
                <div className={classnames(styles.closeButton, { [styles.disabled]: disabled })} onClick={onHide}>
                    <IconWithTooltip
                      icon="close"
                      className={styles.closeButtonIcon}
                      referenceWrapperClassName={styles.closeButtonIconWrapper}
                      tooltip="Close [esc]"
                      tooltipPlacement="bottom"
                    />
                </div>
                )
            }
        </div>
    );
};

ModalHeader.propTypes = {
    children: customPropTypes.children.isRequired,
    onHide: PropTypes.func,
    leftContent: PropTypes.node,
    disabled: PropTypes.bool
};

export default ModalHeader;
