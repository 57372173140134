import AdditionalFilterButton from 'src/components/buttons/AdditionalFilterButton';
import React, { useState } from 'react';
import AdditionalFilterDrawer from 'src/components/modals/AdditionalFilterDrawer';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import {
    defaultPostTextState,
    defaultPostTagState,
    defaultPostTextExcludeState,
    defaultAdCampaignState
} from 'src/utils/filterSelectors';

const AdditionalFilterSelector = ({
    postText, postTextExclude, onApplyClick, postTag, small, disabled, showPrefixWhenFilterIsSet, adCampaign
}) => {
    const [show, setShow] = useState(false);
    const hideAction = () => {
        setShow(false);
    };
    const showAction = () => {
        setShow(true);
    };

    return (
        <div>
            <AdditionalFilterButton
              active={show}
              small={small}
              disabled={disabled}
              onClick={showAction}
              postText={postText}
              postTextExclude={postTextExclude}
              postTag={postTag}
              adCampaign={adCampaign}
              showPrefixWhenFilterIsSet={showPrefixWhenFilterIsSet}
              error
            />
            {show
            && (
                <AdditionalFilterDrawer
                  postText={postText}
                  postTag={postTag}
                  postTextExclude={postTextExclude}
                  hideModal={hideAction}
                  show={show}
                  adCampaign={adCampaign}
                  onApplyClick={onApplyClick}
                />
            )}
        </div>
    );
};

AdditionalFilterSelector.propTypes = {
    postText: customPropTypes.postTextFilter,
    postTextExclude: customPropTypes.postTextExcludeFilter,
    adCampaign: customPropTypes.adCampaignFilter,
    postTag: customPropTypes.postTagFilter,
    onApplyClick: PropTypes.func.isRequired,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
    showPrefixWhenFilterIsSet: PropTypes.bool
};

AdditionalFilterSelector.defaultProps = {
    small: false,
    disabled: false,
    postTag: defaultPostTagState,
    adCampaign: defaultAdCampaignState,
    postText: defaultPostTextState,
    postTextExclude: defaultPostTextExcludeState,
    showPrefixWhenFilterIsSet: true
};

export default AdditionalFilterSelector;
