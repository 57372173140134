import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import Text from 'src/components/forms/inputs/Text';
import AuthTransactionTimeInfo from 'src/components/authTransactions/AuthTransactionTimeInfo';
import styles from 'src/stylesheets/authTransactions/authTransactionShareInfo.scss';
import AuthTransactionLink from 'src/components/authTransactions/AuthTransactionLink';
import { getShareLink } from 'src/utils/authTransactionUtils';

const AuthTransactionShareInfo = (props) => {
    const { authTransaction } = props;
    const { hash } = authTransaction;
    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Share this link with the admin/editor</div>
            <div className={styles.text}>
                <Text defaultValue={getShareLink(hash)} readOnly />
            </div>
            <div className={styles.info}>
                <div className={styles.link}>
                    <AuthTransactionLink
                      hash={hash}
                      label="Copy link to clipboard"
                    />
                </div>
                <div className={styles.date}>
                    <AuthTransactionTimeInfo authTransaction={authTransaction} showCreatorInfo={false} />
                </div>
            </div>
        </div>
    );
};

AuthTransactionShareInfo.propTypes = {
    authTransaction: customPropTypes.authTransaction.isRequired
};

export default AuthTransactionShareInfo;
