import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import FormError from 'src/components/forms/feedback/FormError';
import styles from 'src/stylesheets/forms/dataPushTaskForm.scss';
import DataPushTaskDestinationFormFields from 'src/components/forms/dataPushTasks/DataPushTaskDestinationFormFields';

const DataPushTaskDestinationEditForm = (props) => {
    const {
        handleSubmit, error, form
    } = props;

    return (
        <form onSubmit={handleSubmit} className={styles.wrapper}>
            {
                error && (
                    <div className={styles.error}>
                        <FormError message={error.message} />
                    </div>
                )
            }
            <DataPushTaskDestinationFormFields form={form} isAuthFileRequired={false} />
        </form>
    );
};

DataPushTaskDestinationEditForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.object,
    form: PropTypes.string.isRequired
};

export default withReduxForm(DataPushTaskDestinationEditForm);
