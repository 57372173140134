import {
    makeSelectDateByContext,
    makeSelectAdditionalFilterByContext,
    makeSelectProfileSelectorSelectionByContext
} from 'src/selectors/filterSelectors';
import { isSubmitting, submit } from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import CreateShareableLinkForm from 'src/components/forms/CreateShareableLinkForm';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { getDashboardContext } from 'src/utils/filterSelectors';
import { createShareableLinkFormAction } from 'src/actions/dashboardShareLinks';
import { getInitialFormValues } from 'src/utils/dashboardShareLink';

const formName = 'createShareableLinkForm';

const CreateShareableLinkModal = (props) => {
    const {
        dashboardId, hideModal, selectedDate, selectedProfilesOrGroups, submitAction, submitting, enforceFocus, additionalFilter
    } = props;

    const initialValues = getInitialFormValues(dashboardId, selectedProfilesOrGroups, selectedDate, additionalFilter);

    return (
        <Modal dataViewName="create-update-shareable-link" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Create interactive report
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateShareableLinkForm
                      form={formName}
                      initialValues={initialValues}
                      onSubmit={createShareableLinkFormAction}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button label="Cancel" onClick={hideModal} />
                        <Button action loading={submitting} label="Save" onClick={() => { submitAction(formName); }} />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

CreateShareableLinkModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    selectedDate: customPropTypes.selectedDate.isRequired,
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    additionalFilter: customPropTypes.additionalFilterValues.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    dashboardId: PropTypes.string.isRequired,
    enforceFocus: PropTypes.bool
};

const makeMapStateToProps = () => {
    const selectAdditionalFilterByContext = makeSelectAdditionalFilterByContext();
    const selectDateByContext = makeSelectDateByContext();
    const selectProfileSelectorSelectionByContext = makeSelectProfileSelectorSelectionByContext();

    return (state, ownProps) => {
        const { dashboardId } = ownProps;
        const filterContext = getDashboardContext(dashboardId);
        return {
            selectedDate: selectDateByContext(state, filterContext),
            selectedProfilesOrGroups: selectProfileSelectorSelectionByContext(state, filterContext),
            additionalFilter: selectAdditionalFilterByContext(state, filterContext),
            submitting: isSubmitting(formName)(state)
        };
    };
};

export default connect(makeMapStateToProps, {
    submitAction: submit,
})(CreateShareableLinkModal);
