import { Text } from 'src/components/forms/fields';
import * as customPropTypes from 'src/customPropTypes';
import { Field } from 'redux-form';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import { required } from 'src/components/forms/validators';
import CustomLogo from 'src/components/forms/partials/CustomLogo';

const CreateOrCloneDashboardForm = (props) => {
    const { handleSubmit, error } = props;
    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <Field
              autoFocus
              type="input"
              name="name"
              label="Dashboard name"
              component={Text}
              validate={required}
            />
            <CustomLogo context="dashboard" />
        </form>
    );
};

CreateOrCloneDashboardForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: customPropTypes.dataLoadingError
};

export default withReduxForm(CreateOrCloneDashboardForm);
