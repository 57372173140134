import {
    getProfileSearchFilter,
    makeSelectProfileSearchOutput,
    getProfileSearchIsPending
} from 'src/selectors/profileSearch';
import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as profileSearchActions from 'src/actions/profileSearch';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import Feedback from 'src/components/feedback/Feedback';
import { forceLazyLoadCheck } from 'src/components/lazyLoad/LazyLoad';
import ProfileSearchSummaryText from 'src/components/views/profiles/search/ProfileSearchSummaryText';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/profileSearch.scss';
import ProfileSearchResultList from 'src/components/views/profiles/search/ProfileSearchResultList';
import withAccountSearchListHandling from 'src/components/bulkList/withAccountSearchListHandling';
import { listName } from 'src/utils/profileSearch';
import ProfileSearchLoadingListIndicator from 'src/components/loadingIndicators/ProfileSearchLoadingListIndicator';
import FailedLinksWarning from 'src/components/views/profiles/search/FailedLinksWarning';
import OutOfProfileLimitLinksWarning from 'src/components/views/profiles/search/OutOfProfileLimitLinksWarning';

const ProfileSearchResultListWithListStateHandling = withAccountSearchListHandling(ProfileSearchResultList, listName);

class InputSearchProfileResults extends Component {
    componentDidUpdate(prevProps) {
        const { profileSearchFilter } = this.props;
        if (profileSearchFilter !== prevProps.profileSearchFilter) {
            forceLazyLoadCheck();
        }
    }

    render() {
        const {
            profileSearchOutput,
            isPending,
            profileSearchResetInputAction
        } = this.props;
        const {
            profileIds,
            totalResultCount,
            failedLinks,
            outOfProfileLimitLinks,
            hints,
            errors
        } = profileSearchOutput;

        const filteredResultCount = profileIds.length;

        return (
            <div>
                {
                    (isPending && totalResultCount === 0) && <ProfileSearchLoadingListIndicator showNetworkFilter />
                }
                {
                    !isPending && errors.length > 0 && (
                        <div className={styles.errorWrapper}>
                            <Feedback
                              type="error"
                              content={errors.join(',\n ')}
                            />
                        </div>
                    )
                }
                {
                    !isPending && hints && hints.map((hint) => (
                        <div className={styles.infoFeedbackWrapper} key={`hint-${hint}`}>
                            <Feedback
                              type="info"
                              content={hint}
                            />
                        </div>
                    ))
                }
                {
                    !isPending && failedLinks.length > 0 && (
                        <div className={styles.failedLinks}>
                            <FailedLinksWarning links={failedLinks} />
                        </div>
                    )
                }
                {
                    !isPending && outOfProfileLimitLinks.length > 0 && (
                        <div className={styles.failedLinks}>
                            <OutOfProfileLimitLinksWarning links={outOfProfileLimitLinks} />
                        </div>
                    )
                }
                {
                    totalResultCount > 0 && (
                        <div className={styles.networkFilterWrapper}>
                            <ProfileSearchSummaryText filteredCount={filteredResultCount} showNetworkFilter={totalResultCount > 0} />
                        </div>
                    )
                }
                {
                    errors.length === 0 && filteredResultCount === 0 && totalResultCount > 0 && (
                        <EmptyInfoScreen
                          title="No results found for this network"
                          message="Try another another network."
                          buttons={(<Button label="Reset search" onClick={profileSearchResetInputAction} />)}
                        />
                    )
                }
                {
                    !isPending && errors.length === 0 && filteredResultCount === 0 && totalResultCount === 0 && (
                        <EmptyInfoScreen
                          title="No results found for the search term."
                          message="Try another search or copy and paste the profile’s URL."
                          buttons={(<Button label="Reset search" onClick={profileSearchResetInputAction} />)}
                        />
                    )
                }
                {
                    filteredResultCount > 0 && (
                        <div>
                            <hr className={styles.spacer} />
                            <ProfileSearchResultListWithListStateHandling accountIds={profileIds} searchedProfileIds={profileIds} isPending={isPending} />
                        </div>
                    )
                }
            </div>
        );
    }
}

InputSearchProfileResults.propTypes = {
    profileSearchOutput: customPropTypes.profileSearchOutput.isRequired,
    isPending: PropTypes.bool.isRequired,
    profileSearchFilter: PropTypes.string.isRequired,
    profileSearchResetInputAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectProfileSearchOutput = makeSelectProfileSearchOutput();
    return (state) => ({
        profileSearchOutput: selectProfileSearchOutput(state),
        isPending: getProfileSearchIsPending(state),
        profileSearchFilter: getProfileSearchFilter(state)
    });
};

export default connect(makeMapStateToProps, {
    profileSearchResetInputAction: profileSearchActions.profileSearchResetInput
})(InputSearchProfileResults);
