import * as listActions from 'src/actions/lists';
import _omit from 'lodash/omit';
import { connect } from 'react-redux';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/withRemoveListItemIcon.scss';
import withTooltip from 'src/components/withToolTip';

const IcomoonIconWithtooltip = withTooltip(IcomoonIcon);

const withRemoveListItemIcon = (WrappedComponent, tooltip = 'Deselect') => {
    const WithRemoveListItemIcon = (props) => {
        const { listName, fieldName } = props;

        const handleRemoveClick = () => {
            const { uncheckListValueAction } = props;
            uncheckListValueAction(listName, fieldName);
        };

        const cleanedProps = _omit(props, 'listName', 'fieldName');
        return (
            <div className={styles.wrapper}>
                <div className={styles.wrappedComponent}>
                    <WrappedComponent {...cleanedProps} />
                </div>
                <div
                  className={styles.iconWrapper}
                  onClick={handleRemoveClick}
                >
                    <IcomoonIconWithtooltip
                      icon="clear"
                      className={styles.removeIcon}
                      tooltip={tooltip}
                    />
                </div>
            </div>
        );
    };

    WithRemoveListItemIcon.propTypes = {
        listName: PropTypes.string.isRequired,
        fieldName: PropTypes.string.isRequired,
        uncheckListValueAction: PropTypes.func.isRequired
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithRemoveListItemIcon.displayName = `withRemoveListItemIcon(${wrappedComponentName})`;

    return connect(null, {
        uncheckListValueAction: listActions.uncheckListValue,
    })(WithRemoveListItemIcon);
};

export default withRemoveListItemIcon;
