import React from 'react';

const SnapchatAuthUserInAnotherSpace = () => (
    <span>
        We have detected that this Snapchat account has already been used to authenticate profiles in a different space within the quintly app.
        <br />
        <br />
        <b>Why is this a concern?</b><br />
        Your Snapchat account and the quintly app have a singular relationship. meaning that all quintly spaces in which you have authenticated will be able to make use of <b>all</b> private statistics data that you have offered. Make sure that you have considered the implications of this sharing of data.
        <br />
        <br />
        <b>What actions can I make?</b><br />
        Here we offer you the choice to proceed with the authentication, allowing both spaces to access all data from this account, or to cancel this authentication completely and retry with a different Snapchat account.
    </span>
);

export default SnapchatAuthUserInAnotherSpace;
