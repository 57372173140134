import React from 'react';
import PropTypes from 'prop-types';
import { modalShowPostDetailInPostTagging, modalHidePostDetailInPostTagging } from 'src/actions/overlays';
import { connect } from 'react-redux';
import { makeSelectPostTaggingPrevAndNextPostIds } from 'src/selectors/posts';
import PostDetailModalScroller from 'src/components/postDetailModalScroller/PostDetailModalScroller';

const PostTaggingPostDetailScroller = (props) => {
    const {
        modalShowPostDetailAction,
        modalHidePostDetailAction,
        hasPostsTagsBeenUpdated
    } = props;
    const { prevPostId, nextPostId } = props;
    const handleModalShowPostDetailAction = (postId) => {
        modalShowPostDetailAction(postId, hasPostsTagsBeenUpdated);
    };
    return (
        <PostDetailModalScroller
          modalHidePostDetailAction={modalHidePostDetailAction}
          modalShowPostDetailAction={handleModalShowPostDetailAction}
          prevPostId={prevPostId}
          nextPostId={nextPostId}
        />
    );
};

PostTaggingPostDetailScroller.propTypes = {
    postId: PropTypes.string.isRequired,
    nextPostId: PropTypes.string,
    prevPostId: PropTypes.string,
    modalShowPostDetailAction: PropTypes.func.isRequired,
    modalHidePostDetailAction: PropTypes.func.isRequired,
    hasPostsTagsBeenUpdated: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectPrevAndNextPostIds = makeSelectPostTaggingPrevAndNextPostIds();
    return (state, ownProps) => {
        const { prevPostId, nextPostId } = selectPrevAndNextPostIds(state, ownProps.postId);
        return {
            prevPostId,
            nextPostId
        };
    };
};

export default connect(makeMapStateToProps, {
    modalShowPostDetailAction: modalShowPostDetailInPostTagging,
    modalHidePostDetailAction: modalHidePostDetailInPostTagging
})(PostTaggingPostDetailScroller);
