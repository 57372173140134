import AdvancedTimezoneContext from 'src/components/context/AdvancedTimezoneContext';
import * as customPropTypes from 'src/customPropTypes';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { selectedDateFromDefaultContext } from 'src/utils/filterSelectors';

const memoizedSelectedDateFromDefaultContext = memoizeOne(selectedDateFromDefaultContext);

const DefaultTimezoneResolverFromContext = ({ children, selectedDate }) => {
    const context = useContext(AdvancedTimezoneContext);
    const selectedDateWithDefault = memoizedSelectedDateFromDefaultContext(selectedDate, context);
    return children(selectedDateWithDefault);
};

DefaultTimezoneResolverFromContext.propTypes = {
    children: PropTypes.func.isRequired,
    selectedDate: PropTypes.oneOfType([customPropTypes.relativeDate, customPropTypes.dateRange, PropTypes.bool]).isRequired,
};

export default DefaultTimezoneResolverFromContext;
