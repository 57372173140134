import { getTimezoneStringWithAbbrev, tryGetTimezoneAbbrevOrUtcOffsetOrNull } from 'src/components/dateSelector/utils';
import moment from 'moment/moment';
import timezones from 'phpConfig/timezones.json';

const now = moment();

export const timezonesObject = timezones.map((option) => {
    const name = getTimezoneStringWithAbbrev(option, now);
    const abbrevOrNull = tryGetTimezoneAbbrevOrUtcOffsetOrNull(option, now);
    return {
        id: option, abbrev: abbrevOrNull || option, name, value: option
    };
});
