import React, { useEffect } from 'react';
import { adAccountSearchByAuthAdAccountRequest } from 'src/actions/adAccountSearch';
import { connect } from 'react-redux';
import { listName } from 'src/utils/adAccountSearch';
import PropTypes from 'prop-types';
import { makeSelectAuthAdAccountSearchState, getSearchedAdAccountIds } from 'src/selectors/adAccountSearch';
import withAccountSearchListHandling from 'src/components/bulkList/withAccountSearchListHandling';
import * as customPropTypes from 'src/customPropTypes';
import SearchedAuthAdAccountList from 'src/components/views/adAccounts/SearchedAuthAdAccountList';
import AvailableConnectedAccounts from 'src/components/account/AvailableConnectedAccounts';

const SearchedAuthAdAccountListWithListStateHandling = withAccountSearchListHandling(SearchedAuthAdAccountList, listName);

const AvailableConnectedAdAccounts = (props) => {
    const { adAccountIds, state } = props;
    const { isPending } = state;

    useEffect(() => {
        const { adAccountSearchByAuthAdAccountRequestAction } = props;
        adAccountSearchByAuthAdAccountRequestAction();
    }, []);

    return (
        <AvailableConnectedAccounts
          accountType="adAccounts"
          accountIds={adAccountIds}
          isLoading={isPending}
          renderAuthAccountList={(accountIds) => <SearchedAuthAdAccountListWithListStateHandling accountIds={accountIds} searchedAdAccountIds={accountIds} />}
        />
    );
};

AvailableConnectedAdAccounts.propTypes = {
    adAccountSearchByAuthAdAccountRequestAction: PropTypes.func.isRequired,
    adAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    state: customPropTypes.asyncDataLoadingState.isRequired
};

const makeMapStateToProps = () => {
    const selectAuthAdAccountSearchState = makeSelectAuthAdAccountSearchState();
    return (state) => ({
        state: selectAuthAdAccountSearchState(state),
        adAccountIds: getSearchedAdAccountIds(state)
    });
};

export default connect(makeMapStateToProps, {
    adAccountSearchByAuthAdAccountRequestAction: adAccountSearchByAuthAdAccountRequest
})(AvailableConnectedAdAccounts);
