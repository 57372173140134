import React from 'react';
import ListTable from 'src/components/listTable/ListTable';
import ListTableHeader from 'src/components/listTable/ListTableHeader';
import DashboardShareLinksTableRow from 'src/components/dashboardShareLinks/DashboardShareLinksTableRow';
import styles from 'src/stylesheets/dashboardShareLinks/dashboardShareLinksTable.scss';

import * as customPropTypes from 'src/customPropTypes';

const columns = [
    {
        id: 'createTime', content: 'Creation date', width: '10%'
    },
    {
        id: 'creator', content: 'Creator', width: '15%'
    },
    {
        id: 'profiles', content: 'Profiles', width: '18%'
    },
    {
        id: 'dateRange', content: 'Date range', width: '27%'
    },
    {
        id: 'expiry', content: 'Expiry', width: '30%'
    }
];

const DashboardShareLinksTable = (props) => {
    const { dashboardShareLinks } = props;
    const tableHeader = <ListTableHeader columns={columns} className={styles.header} />;

    if (dashboardShareLinks.length > 0) {
        return (
            <ListTable header={tableHeader}>
                {
                    dashboardShareLinks.map((dashboardShareLink) => (
                        <DashboardShareLinksTableRow key={dashboardShareLink.id} dashboardShareLink={dashboardShareLink} />
                    ))
                }
            </ListTable>
        );
    }
    return (
        <div>
            No links found
        </div>
    );
};

DashboardShareLinksTable.propTypes = {
    dashboardShareLinks: customPropTypes.dashboardShareLinks.isRequired
};

export default DashboardShareLinksTable;
