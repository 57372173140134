import _get from 'lodash/get';

const audienceBarScoreByCategory = {
    high: [100, 100, 100],
    medium: [100, 100, 0],
    low: [100, 0, 0],
    none: [0, 0, 0]
};

export const getProgressesByCategory = (category) => _get(audienceBarScoreByCategory, category, [0, 0, 0]);

const generateSentence = (category, count, dayMonthYear, since) => {
    const dayMonthYears = count > 1 ? `${dayMonthYear}s` : dayMonthYear;
    const firstPart = category === 'low' ? 'Some' : `At least ${count} ${dayMonthYears} of`;
    return `${firstPart} audience data available (From ${since})`;
};

export const getSentence = (category, numberOfAvailableDays, since) => {
    const availableNumberOfMonths = numberOfAvailableDays / 30;
    const availableNumberOfYears = availableNumberOfMonths / 12;
    if (availableNumberOfYears >= 1) {
        return generateSentence(category, Math.round(availableNumberOfYears), 'year', since);
    }

    if (availableNumberOfMonths >= 1 && availableNumberOfMonths < 12) {
        return generateSentence(category, Math.round(availableNumberOfMonths), 'month', since);
    }

    if (numberOfAvailableDays > 0) {
        return generateSentence(category, numberOfAvailableDays, 'day', since);
    }

    return 'No historical audience data available';
};
