import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';

const handleErrorTypes = (error) => {
    switch (error.errorType) {
        case 'spaceDeletionUsersAssignedError': {
            const users = _get(error, 'payload.users', []);

            const message = (
                <div>
                    <div>
                        Could not delete space because the following users are still assigned to it:
                    </div>
                    <ul>
                        {users.map((user) => (
                            <li key={`user-${user.email}`}>
                                {user.name} ({user.email})
                            </li>
                        ))}
                    </ul>
                    <div>
                        Please remove all the users from this space and try again.
                    </div>
                </div>
            );
            return {
                message
            };
        }
        default:
            return {
                message: error.message
            };
    }
};

const SpaceDeleteForm = (props) => {
    const { handleSubmit, error, spaceName } = props;

    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError {...handleErrorTypes(error)} />
            }
            Do you really want to delete the space named <b>{` "${spaceName}" `}</b>? <br />
        </form>
    );
};

SpaceDeleteForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    spaceName: PropTypes.string.isRequired,
    error: customPropTypes.dataLoadingError
};

export default withReduxForm(SpaceDeleteForm);
