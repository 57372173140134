import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/inputs/fieldLabel.scss';

const FieldLabel = (props) => {
    const { htmlFor, children } = props;
    return (
        <div className={styles.label}>
            <label htmlFor={htmlFor}>{children}</label>
        </div>
    );
};

FieldLabel.propTypes = {
    children: customPropTypes.children.isRequired,
    htmlFor: PropTypes.string.isRequired
};

export default FieldLabel;
