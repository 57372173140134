import { createSelector } from 'reselect';
import { makeStringSorter, defaultLoadingState } from 'src/selectors/utils';
import _get from 'lodash/get';

export const getProfileEventsFromStore = (state) => state.entities.profileEvents.byId;
export const getProfileEventIdsFromStore = (state) => state.entities.profileEvents.allIds;
export const getAsyncStates = (state) => state.entities.profileEvents.asyncStates;

export const makeSelectProfileEvents = () => createSelector(
    [
        getProfileEventIdsFromStore,
        getProfileEventsFromStore
    ],
    (profileEventIds, profileEvents) => profileEventIds.map((id) => profileEvents[id])
);

export const makeSelectProfileEventsByProfileId = () => {
    const selectProfileEvents = makeSelectProfileEvents();
    return createSelector(
        [
            selectProfileEvents,
            (_, profileId) => profileId
        ],
        (profileEvents, profileId) => {
            const filteredProfileEvents = profileEvents.filter((profileEvent) => profileEvent.profileId === profileId);
            return filteredProfileEvents.slice().sort(makeStringSorter('createdTime', 'desc'));
        }
    );
};

export const makeSelectAsyncStateByProfileId = () => createSelector(
    [
        getAsyncStates,
        (_, profileId) => profileId
    ],
    (asyncStates, profileId) => _get(asyncStates, ['get', profileId], defaultLoadingState)
);
