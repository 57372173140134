import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { cronExpressionTranslationRequest } from 'src/actions/dashboardReportSchedule';
import { connect } from 'react-redux';
import { makeSelectCronTranslation } from 'src/selectors/dashboardReportSchedule';

const CronExpressionTranslation = (props) => {
    const { expression, cronExpressionTranslation } = props;

    useEffect(() => {
        const { cronExpressionTranslationRequestAction } = props;
        if (cronExpressionTranslation.requested === false) {
            cronExpressionTranslationRequestAction(expression);
        }
    }, [expression]);

    const { loading, translation } = cronExpressionTranslation;

    if (loading) {
        return <span>Translating ...</span>;
    }
    if (translation) {
        return <span>{translation}</span>;
    }

    return null;
};

CronExpressionTranslation.propTypes = {
    expression: PropTypes.string,
    cronExpressionTranslation: PropTypes.object,
    cronExpressionTranslationRequestAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectCronTranslation = makeSelectCronTranslation();
    return (state, ownProps) => ({
        cronExpressionTranslation: selectCronTranslation(state, ownProps.expression)
    });
};

export default connect(makeMapStateToProps, {
    cronExpressionTranslationRequestAction: cronExpressionTranslationRequest
})(CronExpressionTranslation);
