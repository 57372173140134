import React from 'react';
import PropTypes from 'prop-types';
import SearchedProfileWarnings from 'src/components/views/profiles/search/SearchedProfileWarnings';

const OutOfProfileLimitLinksWarning = (props) => {
    const { links } = props;

    return (
        <SearchedProfileWarnings
          links={links}
          topInfo="It looks like your profile limit is full. The following profiles could not be selected."
          bottomInfo="To upgrade your profile limit, contact us via live chat or support@facelift-bbt.com"
        />
    );
};

OutOfProfileLimitLinksWarning.propTypes = {
    links: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default OutOfProfileLimitLinksWarning;
