import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import { selectUseCasesByPlatformWithBasicInsights, selectUseCasesByPlatform } from 'src/selectors/useCases';
import { socialNetworkProfileShowListRequest } from 'src/actions/profileSearch';
import { externalSocialNetworkConnectRequest } from 'src/actions/socialNetworkProfileAuthentication';
import { submit } from 'redux-form';
import GraphLoginUseCaseSelectionModal from 'src/components/modals/partials/GraphLoginUseCaseSelectionModal';
import TwitterLoginModal from 'src/components/modals/TwitterLoginModal';

const getUseCaseIds = (platformUseCases) => platformUseCases.map((useCase) => useCase.id);

const SocialNetworkLoginUseCaseSelectionModal = (props) => {
    const {
        hideModal, platformUseCases, platformType, enforceFocus, instagramUseCases
    } = props;

    const handleRequestAction = (useCases) => {
        const { isExternalAuthentication, externalSocialNetworkConnectRequestAction, socialNetworkProfileShowListRequestAction } = props;
        if (isExternalAuthentication) {
            externalSocialNetworkConnectRequestAction(platformType, useCases);
        } else {
            socialNetworkProfileShowListRequestAction(platformType, useCases);
        }
        hideModal();
    };

    useEffect(() => {
        if (platformType !== 'facebook' && platformType !== 'twitter') {
            handleRequestAction(getUseCaseIds(platformUseCases));
        }
    }, []);

    if (platformType === 'facebook') {
        return (
            <GraphLoginUseCaseSelectionModal
              platformUseCases={platformUseCases.concat(instagramUseCases)}
              handleRequestAction={handleRequestAction}
              enforceFocus={enforceFocus}
              hideModal={hideModal}
            />
        );
    }

    if (platformType === 'twitter') {
        return (
            <TwitterLoginModal
              hideModal={hideModal}
              enforceFocus={enforceFocus}
              onContinueAction={() => { handleRequestAction(getUseCaseIds(platformUseCases)); }}
            />
        );
    }

    return null;
};

SocialNetworkLoginUseCaseSelectionModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    platformType: customPropTypes.platformTypes.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired,
    instagramUseCases: customPropTypes.useCases.isRequired,
    isExternalAuthentication: PropTypes.bool.isRequired,
    socialNetworkProfileShowListRequestAction: PropTypes.func.isRequired,
    externalSocialNetworkConnectRequestAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => (state, ownProps) => ({
    platformUseCases: selectUseCasesByPlatformWithBasicInsights(state, ownProps.platformType, false),
    instagramUseCases: selectUseCasesByPlatform(state, 'instagram')
});

export default connect(makeMapStateToProps, {
    socialNetworkProfileShowListRequestAction: socialNetworkProfileShowListRequest,
    externalSocialNetworkConnectRequestAction: externalSocialNetworkConnectRequest,
    submitAction: submit
})(SocialNetworkLoginUseCaseSelectionModal);
