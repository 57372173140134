import { isSubmitting, submit } from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';
import AccountDeletionForm from 'src/components/forms/AccountDeletionForm';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { deleteAccountFormAction } from 'src/actions/loggedInUser';
import { deleteAccountEvent } from 'src/actions/appEvents';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { selectLoggedInUser } from 'src/selectors/users';

const formName = 'accountDeletion';

const DeleteAccountModal = (props) => {
    useEffect(() => {
        const { deleteAccountEventAction } = props;
        deleteAccountEventAction();
    }, []);
    const {
        hideModal, enforceFocus, user, submitting, submitAction
    } = props;
    return (
        <Modal dataViewName="delete-account" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Delete account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AccountDeletionForm form={formName} onSubmit={deleteAccountFormAction} initialValues={{ userId: user.id }} />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button disabled={submitting} onClick={hideModal} label="Cancel" />
                        <Button action warning icon="delete" loading={submitting} onClick={() => submitAction(formName)} label="Delete" />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

DeleteAccountModal.propTypes = {
    user: PropTypes.oneOfType([customPropTypes.user, PropTypes.bool]).isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    submitting: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    deleteAccountEventAction: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const user = selectLoggedInUser(state);
    return {
        user,
        submitting: isSubmitting(formName)(state),
    };
}

export default connect(mapStateToProps, {
    submitAction: submit,
    deleteAccountEventAction: deleteAccountEvent
})(DeleteAccountModal);
