import EntityOwnershipNameResolver from 'src/components/onwership/EntityOwnershipNameResolver';
import addDashboardTemplateIllustration from 'src/resources/illustrations/add-dashboard-template.png';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import createDashboardIllustration from 'src/resources/illustrations/create-dashboard.png';
import Modal from 'src/components/modals/layout/Modal';
import { modalsShowCreateDashboard } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/addDashboardChoiceModal.scss';
import withReactRouterLinkHandling from 'src/components/withReactRouterLinkHandling';

const ButtonWithReactRouterLinkHandling = withReactRouterLinkHandling(Button);

const AddDashboardChoiceModal = ({ modalsShowCreateDashboardAction, folderId, hideModal }) => {
    const onCreateYourOwnClick = () => {
        modalsShowCreateDashboardAction(folderId, true);
        hideModal();
    };
    return (
        <Modal dataViewName="add-dashboard-choice" onHide={hideModal}>
            <Modal.Dialog width={false}>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Add a new <EntityOwnershipNameResolver entityId={folderId} entityType="folder" /> dashboard</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.wrapper}>
                        <div className={styles.content}>
                            <img alt="Find a template" src={addDashboardTemplateIllustration} />
                            <div className={styles.text}>
                                Search through our selection of pre-made dashboards.
                            </div>

                            <div className={styles.buttons}>
                                <ButtonWithReactRouterLinkHandling
                                  to="/discover/dashboards"
                                  action
                                  label="Find a template"
                                  className={styles.button}
                                  onClick={hideModal}
                                />
                            </div>
                        </div>

                        <div className={styles.spacer} />

                        <div className={styles.content}>
                            <img alt="Create your own" src={createDashboardIllustration} />

                            <div className={styles.text}>
                                Build your own dashboard from scratch to suit your own needs.
                            </div>

                            <div className={styles.buttons}>
                                <Button label="Create your own" className={styles.button} onClick={onCreateYourOwnClick} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    );
};

AddDashboardChoiceModal.propTypes = {
    folderId: PropTypes.string.isRequired,
    hideModal: PropTypes.func.isRequired,
    modalsShowCreateDashboardAction: PropTypes.func.isRequired,
};

export default connect(null, {
    modalsShowCreateDashboardAction: modalsShowCreateDashboard,
})(AddDashboardChoiceModal);
