import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/profileInfo.scss';
import ProfileNameAndMetaInfo from 'src/components/profile/ProfileNameAndMetaInfo';
import ProfilePicture from 'src/components/profile/ProfilePicture';
import ProfileAudienceSize from 'src/components/profile/ProfileAudienceSize';

const ProfileInfo = (props) => {
    const { profile } = props;
    return (
        <div className={classnames(styles.profileInfo, styles.large)}>
            <div className={styles.profilePictureWrapper}>
                <ProfilePicture picture={profile.picture} lazyLoadProfileImage isLarge={false} />
            </div>
            <div className={styles.textWrapper}>
                <ProfileNameAndMetaInfo profile={profile} isLarge={false} showPlatformProfileInfo />
                <div className={styles.likes}>
                    <ProfileAudienceSize profile={profile} isLarge={false} />
                </div>
                {
                    profile.link !== null && profile.link !== ''
                        && (
                        <div className={styles.profileLink}>
                            <Link to={profile.link} target="_blank" className={styles.profileLink} onClick={(e) => { e.stopPropagation(); }}>
                                Visit profile page
                            </Link>
                        </div>
                        )
                }
            </div>
        </div>
    );
};

ProfileInfo.propTypes = {
    profile: PropTypes.oneOfType([
        customPropTypes.profile,
        customPropTypes.searchedProfile
    ]).isRequired
};

export default ProfileInfo;
