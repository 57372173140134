import { changeModalFolderLocation, foldersAndDashboardsMoveToFolderRequest } from 'src/actions/folders';
import FolderBrowserAddFolderButton from 'src/components/folderBrowser/FolderBrowserAddFolderButton';
import FolderBrowserSubmitButton from 'src/components/folderBrowser/FolderBrowserSubmitButton';
import FolderBrowserTitle from 'src/components/folderBrowser/FolderBrowserTitle';
import React, { Component } from 'react';
import { selectIsFolderAndDashboardMovePending, selectModalFolderLocation } from 'src/selectors/folders';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import { modalShowAddFolder, modalShowSharedToPrivateOwnershipWarningModal } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import FolderBrowser from 'src/components/folderBrowser/FolderBrowser';
import OwnershipFolderSharedToPrivateActionHandler from 'src/components/onwership/OwnershipFolderSharedToPrivateActionHandler';

class MoveToFolderModal extends Component {
    constructor(props) {
        super(props);
        this.onFolderOrBreadcrumbItemClick = this.onFolderOrBreadcrumbItemClick.bind(this);
        this.handleMovingOperation = this.handleMovingOperation.bind(this);
        this.handleMovingOperationViaOverlay = this.handleMovingOperationViaOverlay.bind(this);
    }

    componentDidMount() {
        const { fromFolderId, changeModalFolderLocationAction } = this.props;
        changeModalFolderLocationAction(fromFolderId);
    }

    handleMovingOperation() {
        const {
            folderIds,
            fromFolderId,
            foldersAndDashboardsMoveToFolderRequestAction,
            dashboardIds,
            selectedFolder
        } = this.props;
        const { toFolderId } = selectedFolder;
        foldersAndDashboardsMoveToFolderRequestAction(folderIds, dashboardIds, toFolderId, fromFolderId);
    }

    handleMovingOperationViaOverlay() {
        const {
            folderIds,
            fromFolderId,
            modalShowSharedToPrivateOwnershipWarningModalAction,
            dashboardIds,
            selectedFolder
        } = this.props;
        const { toFolderId } = selectedFolder;
        modalShowSharedToPrivateOwnershipWarningModalAction(folderIds, dashboardIds, toFolderId, fromFolderId);
    }

    onFolderOrBreadcrumbItemClick(toFolderId) {
        const { changeModalFolderLocationAction } = this.props;
        changeModalFolderLocationAction(toFolderId);
    }

    render() {
        const {
            hideModal,
            enforceFocus,
            isMoving,
            folderIds,
            modalShowAddFolderAction,
            selectedFolder
        } = this.props;

        const { fromFolderId } = this.props;
        const { toFolderId } = selectedFolder;

        return (
            <Modal dataViewName="move-to-folder" onHide={hideModal} enforceFocus={enforceFocus}>
                <Modal.Dialog width={620}>
                    <Modal.Header onHide={hideModal}>
                        <Modal.Title><FolderBrowserTitle toFolderId={toFolderId} actionName="Move" /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                      height={400}
                      stickyFooter={<FolderBrowserAddFolderButton onClick={modalShowAddFolderAction} toFolderId={toFolderId} />}
                    >
                        <FolderBrowser
                          toFolderId={toFolderId}
                          movingFolderIds={folderIds}
                          onItemClick={this.onFolderOrBreadcrumbItemClick}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button onClick={hideModal} label="Cancel" />
                            <OwnershipFolderSharedToPrivateActionHandler
                              fromFolderId={fromFolderId}
                              toFolderId={toFolderId}
                              defaultAction={this.handleMovingOperation}
                              alternativeAction={this.handleMovingOperationViaOverlay}
                            >
                                {
                                    (actionToTrigger) => (
                                        <FolderBrowserSubmitButton
                                          toFolderId={toFolderId}
                                          onClick={actionToTrigger}
                                          isLoading={isMoving}
                                          label="Move here"
                                          disabled={toFolderId === fromFolderId}
                                        />
                                    )
                                }
                            </OwnershipFolderSharedToPrivateActionHandler>
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal.Dialog>
            </Modal>
        );
    }
}

MoveToFolderModal.propTypes = {
    fromFolderId: PropTypes.string.isRequired,
    enforceFocus: PropTypes.bool,
    isMoving: PropTypes.bool,
    folderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    dashboardIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    hideModal: PropTypes.func.isRequired,
    foldersAndDashboardsMoveToFolderRequestAction: PropTypes.func.isRequired,
    modalShowAddFolderAction: PropTypes.func.isRequired,
    changeModalFolderLocationAction: PropTypes.func.isRequired,
    selectedFolder: PropTypes.shape({ toFolderId: PropTypes.string.isRequired }).isRequired,
    modalShowSharedToPrivateOwnershipWarningModalAction: PropTypes.func.isRequired
};

MoveToFolderModal.defaultProps = {
    enforceFocus: true,
    isMoving: false,
};

const mapStateToProps = (state, ownProps) => {
    const isMoving = selectIsFolderAndDashboardMovePending(state, ownProps.fromFolderId);
    const selectedFolder = selectModalFolderLocation(state);
    return {
        isMoving,
        selectedFolder
    };
};

export default connect(mapStateToProps, {
    foldersAndDashboardsMoveToFolderRequestAction: foldersAndDashboardsMoveToFolderRequest,
    modalShowAddFolderAction: modalShowAddFolder,
    changeModalFolderLocationAction: changeModalFolderLocation,
    modalShowSharedToPrivateOwnershipWarningModalAction: modalShowSharedToPrivateOwnershipWarningModal
})(MoveToFolderModal);
