import MenuButton from 'src/components/buttons/MenuButton';
import TextWithResetIcon from 'src/components/forms/inputs/TextWithResetIcon';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    makeSelectAdAccountCampaignById,
    makeSelectSortedAdAccountCampaignIds
} from 'src/selectors/adAccountCampaigns';
import styles from 'src/stylesheets/popovers/filterPopover.scss';
import { injectWordBreakOpportunityTags } from 'src/utils/string';

const makeMapStateToPropsForMenuButton = () => {
    const selectAdAccountCampaignById = makeSelectAdAccountCampaignById();
    return (state, ownProps) => {
        let label = 'Invalid ad campaign';

        const selectedPostTag = selectAdAccountCampaignById(state, ownProps.adAccountCampaignId);
        if (selectedPostTag) {
            label = selectedPostTag.name;
        }
        return {
            label: <span dangerouslySetInnerHTML={{ __html: injectWordBreakOpportunityTags(label) }} />
        };
    };
};

const ConnectedMenuButton = connect(makeMapStateToPropsForMenuButton)(MenuButton);

const AdAccountCampaignFilterPopover = (props) => {
    const {
        defaultValue, onSubmit, hidePopover, adAccountCampaignIds, selectedValues, filterQuery, setFilterQuery
    } = props;

    useEffect(() => {
        setFilterQuery('');
    }, []);

    const submit = (clickedValue) => { onSubmit(clickedValue); hidePopover(); };
    const selectedValuesWithoutSelf = defaultValue ? selectedValues.filter((value) => value !== defaultValue) : selectedValues;
    const filteredAdAccountIds = adAccountCampaignIds.filter((el) => selectedValuesWithoutSelf.indexOf(el) < 0);

    const inputError = !!filterQuery && filteredAdAccountIds.length < 1;

    return (
        <Popover width={230} id="adCampaignFilterPopover">
            <div className={styles.container}>
                <div className={styles.header}>
                    <TextWithResetIcon
                      autoFocus
                      placeholder="Search for ad campaigns"
                      value={filterQuery}
                      onChange={(e) => { setFilterQuery(e.target.value); }}
                      onResetClick={() => { setFilterQuery(''); }}
                      error={inputError}
                    />
                </div>
                <div className={styles.items}>
                    {
                        filteredAdAccountIds.length > 0 && filteredAdAccountIds.map((adAccountId) => (
                            <ConnectedMenuButton
                              key={adAccountId}
                              adAccountCampaignId={adAccountId}
                              onClick={() => { submit(adAccountId); }}
                            />
                        ))
                    }
                    {
                        filteredAdAccountIds.length < 1 && <div className={styles.empty}>No ad campaigns available</div>
                    }
                </div>
            </div>
        </Popover>
    );
};

AdAccountCampaignFilterPopover.propTypes = {
    defaultValue: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    hidePopover: PropTypes.func,
    adAccountCampaignIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
    filterQuery: PropTypes.string.isRequired,
    setFilterQuery: PropTypes.func.isRequired
};

AdAccountCampaignFilterPopover.defaultProps = {
    defaultValue: ''
};

const makeMapStateToProps = () => {
    const selectSortedAdAccountCampaignIds = makeSelectSortedAdAccountCampaignIds();
    return (state, ownProps) => ({
        adAccountCampaignIds: selectSortedAdAccountCampaignIds(state, ownProps.filterQuery)
    });
};

export default connect(makeMapStateToProps)(AdAccountCampaignFilterPopover);
