import { isSubmitting, submit } from 'redux-form';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import DataPushTaskAddForm from 'src/components/forms/dataPushTasks/DataPushTaskAddForm';
import { dataPushTaskCreateValidationRequestFormAction } from 'src/actions/dataPushTasks';
import { dataPushTaskAddFormName } from 'src/utils/dataPushTasks';

const AddDataPushTaskModal = (props) => {
    const {
        hideModal,
        enforceFocus,
        submitAction,
        isAdding
    } = props;

    const initialValues = {
        dataSource: '',
        dataSourceColumns: [],
        dynamicDate: 'last 7 days',
        timezone: 'UTC',
        interval: 'daily',
        dataPushTimeColumnName: '_dataPushTime',
        deliveryCronExpression: {
            cronExpression: '0 3 * * *',
            timezone: 'UTC'
        }
    };

    return (
        <Modal dataViewName="add-data-push-task-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Add data push task
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body centered>
                    <DataPushTaskAddForm
                      form={dataPushTaskAddFormName}
                      onSubmit={dataPushTaskCreateValidationRequestFormAction}
                      initialValues={initialValues}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          label="Cancel"
                          onClick={hideModal}
                          disabled={isAdding}
                        />
                        <Button
                          label="Next"
                          action
                          onClick={() => { submitAction(dataPushTaskAddFormName); }}
                          loading={isAdding}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

AddDataPushTaskModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    isAdding: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isAdding: isSubmitting(dataPushTaskAddFormName)(state)
});

export default connect(mapStateToProps, {
    submitAction: submit
})(AddDataPushTaskModal);
