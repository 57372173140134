import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import FormError from 'src/components/forms/feedback/FormError';

const DataPushTaskDeleteForm = (props) => {
    const { handleSubmit, error } = props;
    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <div>
                Are you sure you want to delete this data push ?
            </div>
        </form>
    );
};

DataPushTaskDeleteForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.object
};

export default withReduxForm(DataPushTaskDeleteForm);
