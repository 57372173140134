import React from 'react';
import styles from 'src/stylesheets/responsiveEditableItemList.scss';
import classnames from 'classnames';
import ResponsiveEditableItem from 'src/components/ResponsiveEditableItem';
import withToolTip from 'src/components/withToolTip';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import ResponsiveEditableItems from 'src/components/ResponsiveEditableItems';

const maxNumberOfItems = 3;
const memoizedGetSlicedItems = memoizeOne((items, start, end) => items.slice(start, end));

const ItemWithTooltip = withToolTip(ResponsiveEditableItem);

const ResponsiveEditableItemList = (props) => {
    const {
        items, className, editButton, emptyListLabel
    } = props;
    const itemCount = items.length;
    const wrapperClassName = classnames(styles.wrapper, className);
    const visibleItems = memoizedGetSlicedItems(items, 0, maxNumberOfItems);
    const hiddenItems = memoizedGetSlicedItems(items, maxNumberOfItems, itemCount);

    if (itemCount > 0) {
        if (itemCount > maxNumberOfItems) {
            const remainingItemCount = itemCount - maxNumberOfItems;
            return (
                <div className={wrapperClassName}>
                    <ResponsiveEditableItems items={visibleItems} />
                    <div className={styles.moreItems}>
                        <ItemWithTooltip
                          className={styles.test}
                          tooltip={hiddenItems.map((item) => item.name).join(', ')}
                          name={`+${remainingItemCount}`}
                        />
                    </div>
                    {editButton}
                </div>
            );
        }

        return (
            <div className={wrapperClassName}>
                <ResponsiveEditableItems items={items} />
                {editButton}
            </div>
        );
    }

    return (
        <div className={wrapperClassName}>
            <div className={styles.item}>
                <ResponsiveEditableItem name={emptyListLabel} disabled />
            </div>
            {editButton}
        </div>
    );
};

ResponsiveEditableItemList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    editButton: PropTypes.node.isRequired,
    className: PropTypes.string,
    emptyListLabel: PropTypes.string.isRequired
};

ResponsiveEditableItemList.defaultProps = {
    className: ''
};

export default ResponsiveEditableItemList;
