import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import styles from 'src/stylesheets/listFilters.scss';

const ListFilters = ({ children }) => (
    <ul className={styles.listFilters}>
        {
            React.Children.map(children, (formField) => {
                if (formField) {
                    return <li className={styles.formFieldWrapper}>{formField}</li>;
                }
                return null;
            })
        }
    </ul>
);

ListFilters.propTypes = {
    children: customPropTypes.children.isRequired,
};

export default ListFilters;
