import React from 'react';
import PropTypes from 'prop-types';
import Feedback from 'src/components/feedback/Feedback';

const FailedAccountView = (props) => {
    const {
        links, onRetryClick, onRetryLabel, label
    } = props;
    const content = (
        <div>
            <div>
                {`An error occurred while adding your ${label}. The following ${label} could not be added:`}
            </div>
            <div>
                {
                    links.map((link) => (
                        <div key={link}><a href={link} target="_blank" rel="noopener noreferrer">{link}</a></div>
                    ))
                }
            </div>
            <br />
            <a onClick={onRetryClick}>{onRetryLabel}</a>
        </div>
    );
    return <Feedback content={content} />;
};

FailedAccountView.propTypes = {
    links: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.string.isRequired,
    onRetryClick: PropTypes.func.isRequired,
    onRetryLabel: PropTypes.string
};

FailedAccountView.defaultProps = {
    onRetryLabel: 'Try again'
};

export default FailedAccountView;
