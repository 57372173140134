import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Feedback from 'src/components/feedback/Feedback';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';

const GeneralInfoModal = (props) => {
    const { hideModal, enforceFocus, info } = props;
    return (
        <Modal dataViewName="general-info" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        {info.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Feedback content={info.message} type="info" />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button action label="Ok" onClick={hideModal} />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

GeneralInfoModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    info: customPropTypes.generalInfoForModal.isRequired,
    enforceFocus: PropTypes.bool
};

export default GeneralInfoModal;
