import * as customPropTypes from 'src/customPropTypes';
import AccountUseCasesTableRow from 'src/components/forms/accountUseCasesTable/AccountUseCasesTableRow';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { makeSelectOrderedByNameAuthUsers } from 'src/selectors/authUsers';
import ListTableCell from 'src/components/listTable/ListTableCell';
import NoAuthUserUseCaseLabel from 'src/components/labels/NoAuthUserUseCaseLabel';
import EmptyCells from 'src/components/listTable/EmptyCells';
import { MAX_NUMBER_OF_COLUMNS } from 'src/utils/accountInsights';
import ListTableRow from 'src/components/listTable/ListTableRow';
import classnames from 'classnames';
import styles from 'src/stylesheets/profileUseCasesTable.scss';

const getUseCasesByAuthUserId = (authUsersUseCases, authUserId) => authUsersUseCases.filter((authUsersUseCase) => authUsersUseCase.userId === authUserId);

const AccountUseCasesTableRows = (props) => {
    const {
        account,
        useCaseFormFields,
        authUsers
    } = props;
    const { useCases, accountUseCasesAuthUsers, authUsersAuthAccountUseCases } = account;
    return (
        <>
            {
                authUsers.map((authUser) => (
                    <AccountUseCasesTableRow
                      key={`${authUser.id}_authUserRow`}
                      authUser={authUser}
                      accountUseCases={useCases}
                      accountUseCasesAuthUsers={getUseCasesByAuthUserId(accountUseCasesAuthUsers, authUser.id)}
                      authUsersAuthAccountUseCases={getUseCasesByAuthUserId(authUsersAuthAccountUseCases, authUser.id)}
                      useCaseFormFields={useCaseFormFields}
                    />
                ))
            }
            {
                authUsers.length === 0 && (
                    <ListTableRow>
                        <ListTableCell className={classnames(styles.firstColumn, styles.authUser)}>
                            <NoAuthUserUseCaseLabel />
                        </ListTableCell>
                        <EmptyCells emptyColumnLength={MAX_NUMBER_OF_COLUMNS} />
                    </ListTableRow>
                )
            }
        </>
    );
};

AccountUseCasesTableRows.propTypes = {
    account: customPropTypes.account.isRequired,
    authUsers: customPropTypes.authUsers.isRequired,
    useCaseFormFields: customPropTypes.useCaseFormFields.isRequired,
    authUserIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

const makeMapStateToProps = () => {
    const selectOrderedByNameAuthUserIds = makeSelectOrderedByNameAuthUsers();
    return (state, ownProps) => ({
        authUsers: selectOrderedByNameAuthUserIds(state, ownProps.authUserIds)
    });
};

export default connect(makeMapStateToProps)(AccountUseCasesTableRows);
