import { checkListValues, initializeList, unregisterList } from 'src/actions/lists';
import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import _range from 'lodash/range';
import CheckBox from 'src/components/forms/inputs/Checkbox';
import { connect } from 'react-redux';
import IcomoonIcon from 'src/components/IcomoonIcon';
import NetworkIcon from 'src/components/NetworkIcon';
import PropTypes from 'prop-types';
import { selectUseCasesByPlatform } from 'src/selectors/useCases';
import styles from 'src/stylesheets/updateProfilesUseCasesTable.scss';
import UseCaseDescriptionPopover from 'src/components/popovers/UseCaseDescriptionPopover';
import withBulkCheckboxHandling from 'src/components/bulkList/withBulkCheckboxHandling';
import withPopover from 'src/components/withPopover';
import { MAX_NUMBER_OF_COLUMNS } from 'src/utils/accountInsights';

const IcomoonIconWithPopover = withPopover(IcomoonIcon);
const AccountListBulkCheckbox = withBulkCheckboxHandling(CheckBox, true);

const UpdateAccountsUseCasesRow = (props) => {
    const {
        accounts, platformUseCases, isUpdating, listNamesPerUseCase
    } = props;
    const accountIds = accounts.map((account) => account.id);

    useEffect(() => {
        const {
            initializeListAction, checkListValuesAction, unregisterListAction
        } = props;

        // initialize the lists
        platformUseCases.forEach((useCase) => {
            if (listNamesPerUseCase[useCase.id]) {
                initializeListAction(listNamesPerUseCase[useCase.id], accountIds);
            }
        });

        // check list values for accounts that has use case enabled.
        let accountIdsPerUseCaseId = {};
        accounts.forEach((account) => {
            const useCases = _get(account, 'useCases', []);
            useCases.forEach((useCase) => {
                if (accountIdsPerUseCaseId[useCase.id]) {
                    accountIdsPerUseCaseId[useCase.id].push(account.id);
                } else {
                    accountIdsPerUseCaseId = Object.assign(accountIdsPerUseCaseId, { [useCase.id]: [account.id] });
                }
            });
        });

        Object.keys(accountIdsPerUseCaseId).forEach((useCaseId) => {
            if (listNamesPerUseCase[useCaseId]) {
                checkListValuesAction(listNamesPerUseCase[useCaseId], accountIdsPerUseCaseId[useCaseId]);
            }
        });

        // unregister the lists on unmount
        return () => {
            if (!isUpdating) {
                platformUseCases.forEach((useCase) => {
                    if (listNamesPerUseCase[useCase.id]) {
                        unregisterListAction(listNamesPerUseCase[useCase.id]);
                    }
                });
            }
        };
    }, []);

    const { platformType } = props;
    const accountOrAccounts = accounts.length > 1 ? 'accounts' : 'account';
    const numberOfEmptyColumns = MAX_NUMBER_OF_COLUMNS - platformUseCases.length;

    return (
        <tr>
            <td className={styles.column}>
                <div className={styles.networkType}>
                    <NetworkIcon platformType={platformType} className={styles.networkIcon} />
                    <div className={styles.numberOfProfiles}>
                        {`${accounts.length} ${accountOrAccounts}`}
                    </div>
                </div>
            </td>
            {
                platformUseCases.map((useCase) => (
                    <td key={useCase.id} className={styles.column}>
                        <div className={styles.columnContent}>
                            <AccountListBulkCheckbox
                              listName={listNamesPerUseCase[useCase.id]}
                              listItemIdsToHandle={accountIds}
                              disabled={isUpdating}
                            />
                            <div className={styles.useCase}>
                                <span className={styles.useCaseName}>{useCase.name}</span>
                                <IcomoonIconWithPopover
                                  icon="info"
                                  className={styles.helpIcon}
                                  overlay={<UseCaseDescriptionPopover useCaseId={useCase.id} />}
                                />
                            </div>
                        </div>
                    </td>
                ))
            }
            {
                _range(numberOfEmptyColumns).map((i) => (
                    <td
                      key={`empty_${i}`}
                      className={styles.column}
                    >
                        <div className={styles.unavailable} />
                    </td>
                ))
            }
        </tr>
    );
};

UpdateAccountsUseCasesRow.propTypes = {
    accounts: customPropTypes.accounts.isRequired,
    platformType: PropTypes.string.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired,
    initializeListAction: PropTypes.func.isRequired,
    checkListValuesAction: PropTypes.func.isRequired,
    unregisterListAction: PropTypes.func.isRequired,
    isUpdating: PropTypes.bool,
    listNamesPerUseCase: PropTypes.object.isRequired
};

UpdateAccountsUseCasesRow.defaultProps = {
    isUpdating: false
};

const mapStateToProps = (state, ownProps) => ({
    platformUseCases: selectUseCasesByPlatform(state, ownProps.platformType)
});

export default connect(mapStateToProps, {
    initializeListAction: initializeList,
    unregisterListAction: unregisterList,
    checkListValuesAction: checkListValues
})(UpdateAccountsUseCasesRow);
