import PropTypes from 'prop-types';
import React from 'react';
import SuperRoot from 'src/components/folderBrowser/SuperRoot';
import * as customPropTypes from 'src/customPropTypes';
import { SUPER_ROOT } from 'src/utils/dashboardFolders';

const SuperRootHandler = (
    { toFolderId, setToFolderId, children }
) => {
    if (toFolderId === SUPER_ROOT) {
        return <SuperRoot onItemClick={setToFolderId} />;
    }
    return children;
};

SuperRootHandler.propTypes = {
    children: customPropTypes.children.isRequired,
    setToFolderId: PropTypes.func.isRequired,
    toFolderId: PropTypes.string.isRequired
};

export default SuperRootHandler;
