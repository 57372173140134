import AdAccountCampaignFilterToken from 'src/components/AdAccountCampaignFilterToken';
import FilterTokenInput from 'src/components/forms/inputs/layout/FilterTokenInput';
import AdAccountCampaignFilterPopover from 'src/components/popovers/AdCampaignFilterPopover';
import withPopover from 'src/components/withPopover';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const AdAccountCampaignFilterTokenWithPopover = withPopover(AdAccountCampaignFilterToken);

const AdAccountCampaignFilterTokenInput = ({
    value, onChange, wrapElements, disabled
}) => {
    const [filterQuery, setFilterQuery] = useState('');
    return (
        <FilterTokenInput
          onChange={onChange}
          wrapElements={wrapElements}
          disabled={disabled}
          renderToken={(entry, onEdit, onDelete) => (
              <AdAccountCampaignFilterTokenWithPopover
                placement="bottom-start"
                showTooltip={!wrapElements}
                overlay={(
                    <AdAccountCampaignFilterPopover
                      actionLabel="edit"
                      selectedValues={value}
                      onSubmit={onEdit}
                      defaultValue={entry}
                      filterQuery={filterQuery}
                      setFilterQuery={setFilterQuery}
                    />
                )}
                adAccountCampaignId={entry}
                key={entry}
                onDeleteClick={onDelete}
              />
          )}
          renderAddOverlay={(onAdd) => (
              <AdAccountCampaignFilterPopover
                onSubmit={onAdd}
                actionLabel="Add"
                selectedValues={value}
                filterQuery={filterQuery}
                setFilterQuery={setFilterQuery}
              />
          )}
          value={value}
        />
    );
};

AdAccountCampaignFilterTokenInput.propTypes = {
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    wrapElements: PropTypes.bool,
    disabled: PropTypes.bool
};

AdAccountCampaignFilterTokenInput.defaultProps = {
    wrapElements: false,
    disabled: false
};

export default AdAccountCampaignFilterTokenInput;
