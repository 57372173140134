import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import MetricBuilderName from 'src/components/forms/partials/MetricBuilderName';

const MetricBuilderFormWithNameFieldOnly = ({ handleSubmit }) => (
    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
        <MetricBuilderName autoFocus />
    </form>
);

MetricBuilderFormWithNameFieldOnly.propTypes = {
    handleSubmit: PropTypes.func.isRequired
};

export default withReduxForm(MetricBuilderFormWithNameFieldOnly, {
    destroyOnUnmount: false,
    touchOnBlur: true
});
