import * as customPropTypes from 'src/customPropTypes';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import CustomizeProfile from './partials/CustomizeProfile';

const CustomizeProfileForm = (props) => {
    const { handleSubmit, error } = props;

    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <CustomizeProfile />
        </form>

    );
};

CustomizeProfileForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: customPropTypes.dataLoadingError
};

export default withReduxForm(CustomizeProfileForm, {
    enableReinitialize: true
});
