import React from 'react';
import ConnectedAccountLabel from 'src/components/labels/ConnectedAccountLabel';
import DisconnectedAccountLabel from 'src/components/labels/DisconnectedAccountLabel';
import * as customPropTypes from 'src/customPropTypes';
import { getLabelAndFinalState } from 'src/utils/accountInsights';
import PropTypes from 'prop-types';
import withTooltip from 'src/components/withToolTip';

const ConnectedAccountLabelWithTooltip = withTooltip(ConnectedAccountLabel);

const AccountPrivateStats = (props) => {
    const {
        account, active, onClick, forceActive
    } = props;
    const { useCases: requiredUseCases, accountUseCasesAuthUsers } = account;

    if (requiredUseCases.length > 0) {
        const { finalState, finalTooltip } = getLabelAndFinalState(requiredUseCases, accountUseCasesAuthUsers);
        return (
            <ConnectedAccountLabelWithTooltip
              platformType={account.platformType}
              finalState={finalState}
              tooltip={finalTooltip}
              onClick={onClick}
              active={forceActive || active}
            />
        );
    }

    return (
        <DisconnectedAccountLabel
          active={active}
          onClick={onClick}
        />
    );
};

AccountPrivateStats.propTypes = {
    account: customPropTypes.account.isRequired,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    forceActive: PropTypes.bool
};

AccountPrivateStats.defaultProps = {
    active: false,
    forceActive: false
};

export default AccountPrivateStats;
