import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import { Link } from 'react-router';
import React from 'react';
import styles from 'src/stylesheets/feedback.scss';

const FeedbackLink = (props) => {
    const { type, children } = props;

    return (
        <Link className={classnames(styles.link, styles[type])} {...props}>
            {children}
        </Link>
    );
};

FeedbackLink.propTypes = {
    type: customPropTypes.feedbackTypes,
    children: customPropTypes.children.isRequired
};

export default FeedbackLink;
