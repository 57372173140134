import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import ConfirmDeleteForm from 'src/components/forms/ConfirmDeleteForm';
import { postTagsRemoveRequestFormAction } from 'src/actions/postTags';
import { connect } from 'react-redux';
import { isSubmitting, submit } from 'redux-form';
import { makeSelectPostTagsByIds } from 'src/selectors/postTags';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/modals/postTagsDeleteModal.scss';

const formName = 'postTagRemoveForm';

const PostTagsDeleteModal = (props) => {
    const {
        hideModal, postTags, enforceFocus, submitAction, isDeleting, postTagIds
    } = props;
    const numberOfTags = postTags.length;
    const label = numberOfTags > 1 ? 'tags' : 'tag';
    return (
        <Modal dataViewName="delete-post-tag" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        {`Delete ${label}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.postTags}>
                        {
                            numberOfTags === 1
                            && (
                                <span>
                                    Any posts assigned to this tag will no longer be assigned. Are you sure you want to delete <b>{postTags[0].name} </b>?
                                </span>
                            )
                        }
                        {
                            numberOfTags > 1
                            && (
                                <div>
                                    <div className={styles.postTagWarning}>Any posts assigned to these tags will no longer be assigned. Are you sure you want to delete the following tags?</div>
                                    {
                                        postTags.map((postTag) => (
                                            <div key={postTag.id}>
                                                <b>{postTag.name}</b>
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </div>
                    <ConfirmDeleteForm
                      form={formName}
                      onSubmit={postTagsRemoveRequestFormAction}
                      initialValues={{ postTagIds }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button label="Cancel" onClick={hideModal} />
                        <Button
                          action
                          warning
                          icon="delete"
                          loading={isDeleting}
                          onClick={() => { submitAction(formName); }}
                          label="Delete"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

PostTagsDeleteModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    postTags: customPropTypes.postTags.isRequired,
    postTagIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    submitAction: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectPostTagsByIds = makeSelectPostTagsByIds();
    return (state, ownProps) => ({
        isDeleting: isSubmitting(formName)(state),
        postTags: selectPostTagsByIds(state, ownProps.postTagIds)
    });
};

export default connect(makeMapStateToProps, {
    submitAction: submit,
})(PostTagsDeleteModal);
