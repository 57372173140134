import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { selectIsDeletePending } from 'src/selectors/users';
import UserTransferForm from 'src/components/forms/UserTransferForm';
import { transferAndDeleteUserRequestFormAction } from 'src/actions/users';

import { isSubmitting as isSubmittingSelector, submit } from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';

const getFormName = (userId) => (`transfer-user-${userId}`);

const UserTransferAndDeleteModal = (props) => {
    const {
        hideModal, enforceFocus, userId, isSubmitting, submitAction, transferOptions
    } = props;
    const formName = getFormName(userId);

    const initialValues = {
        transferFromUserId: userId
    };

    Object.keys(transferOptions.ownerShipsPerSpace).forEach((spaceId) => {
        Object.assign(initialValues, { [`space-${spaceId}`]: -1 });
    });

    return (
        <Modal dataViewName="transfer-and-delete-user" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Transfer items before deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UserTransferForm
                      form={formName}
                      onSubmit={transferAndDeleteUserRequestFormAction}
                      transferOptions={transferOptions}
                      initialValues={initialValues}
                      userId={userId}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button disabled={isSubmitting} onClick={hideModal} label="Cancel" />
                        <Button
                          action
                          warning
                          icon="delete"
                          loading={isSubmitting}
                          onClick={() => {
                              submitAction(formName);
                          }}
                          label="Transfer objects and delete user"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

UserTransferAndDeleteModal.propTypes = {
    userId: PropTypes.string.isRequired,
    hideModal: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    transferOptions: customPropTypes.userTransferOptions.isRequired
};

UserTransferAndDeleteModal.defaultProps = {
    isSubmitting: false
};

function mapStateToProps(state, ownProps) {
    return {
        isDeleting: selectIsDeletePending(state, ownProps.userId),
        isSubmitting: isSubmittingSelector(getFormName(ownProps.userId))(state),
    };
}

export default connect(mapStateToProps, {
    submitAction: submit
})(UserTransferAndDeleteModal);
