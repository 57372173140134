import React from 'react';
import PropTypes from 'prop-types';
import SearchedProfileWarnings from 'src/components/views/profiles/search/SearchedProfileWarnings';

const FailedLinksWarning = (props) => {
    const { links } = props;
    return (
        <SearchedProfileWarnings
          links={links}
          topInfo={`We could not find the profiles for the following URL${links.length > 1 ? 's' : ''}:`}
        />
    );
};

FailedLinksWarning.propTypes = {
    links: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default FailedLinksWarning;
