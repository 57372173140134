import ManageGroupsOfAdAccountsPopover from 'src/components/popovers/ManageGroupsOfAdAccountsPopover';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { makeSelectGroupsFromAdAccountId } from 'src/selectors/utils';
import React from 'react';
import { selectGroups } from 'src/selectors/groups';
import withToolTip from 'src/components/withToolTip';
import EditButton from 'src/components/buttons/EditButton';
import PropTypes from 'prop-types';
import ResponsiveEditableItemList from 'src/components/ResponsiveEditableItemList';
import withPopover from 'src/components/withPopover';
import { selectIsReadOnly } from 'src/selectors/loggedInUser';

const ProfileGroupEditButtonWithTooltip = withPopover(withToolTip(EditButton));

const AdAccountGroupItemList = (props) => {
    const {
        groups, adAccountId, className, disabled, isUserReadOnly
    } = props;
    const editButton = (
        <div className="groupEditButton">
            <ProfileGroupEditButtonWithTooltip
              overlay={<ManageGroupsOfAdAccountsPopover adAccountId={adAccountId} />}
              tooltip="Assign groups"
              disabled={disabled || isUserReadOnly}
            />
        </div>
    );

    return (
        <ResponsiveEditableItemList
          items={groups}
          editButton={editButton}
          className={className}
          emptyListLabel="No groups"
        />
    );
};

AdAccountGroupItemList.propTypes = {
    groups: customPropTypes.groups.isRequired,
    adAccountId: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    isUserReadOnly: PropTypes.bool.isRequired
};

AdAccountGroupItemList.defaultProps = {
    className: '',
    disabled: false
};

const makeMapStateToProps = () => {
    const selectGroupsFromAdAccountId = makeSelectGroupsFromAdAccountId();
    return (state, ownProps) => {
        const { adAccountId } = ownProps;
        const allGroups = selectGroups(state);
        const groups = selectGroupsFromAdAccountId(state, adAccountId, allGroups);
        return {
            groups,
            isUserReadOnly: selectIsReadOnly(state)
        };
    };
};

export default connect(makeMapStateToProps)(AdAccountGroupItemList);
