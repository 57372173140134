import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import ConfirmDeleteForm from 'src/components/forms/ConfirmDeleteForm';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/profileDeleteModal.scss';

const AccountDeleteModal = (props) => {
    const {
        hideModal, accountNames, accountIds, isAccountDeleting, enforceFocus, submitAction, singularLabel, pluralLabel, formAction, formName
    } = props;

    const thisTheseProfiles = (accountIds.length === 1) ? `this ${singularLabel}` : `these ${pluralLabel}`;
    return (
        <Modal dataViewName="delete-account" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Delete {singularLabel}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {`Are you sure you want to delete ${thisTheseProfiles} from your account and all groups?`}
                    </span>
                    <div className={styles.profiles}>
                        <ul className={styles.profileList}>
                            {
                                accountNames.map((account) => (
                                    <li key={account.id}>
                                        {account.name}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className={styles.confirmation}>
                        <ConfirmDeleteForm
                          form={formName}
                          initialValues={{ accountIds }}
                          onSubmit={formAction}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          onClick={hideModal}
                          label="Cancel"
                        />
                        <Button
                          action
                          warning
                          icon="delete"
                          onClick={() => { submitAction(formName); }}
                          label={`${isAccountDeleting ? 'Deleting' : 'Delete'}`}
                          loading={isAccountDeleting}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

AccountDeleteModal.propTypes = {
    accountNames: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired, name: PropTypes.string.isRequired })).isRequired,
    accountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    isAccountDeleting: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    singularLabel: PropTypes.string.isRequired,
    pluralLabel: PropTypes.string.isRequired,
    formAction: PropTypes.func.isRequired,
    formName: PropTypes.string.isRequired
};

AccountDeleteModal.defaultProps = {
    enforceFocus: false
};

export default AccountDeleteModal;
