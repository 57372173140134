import Popover from 'src/components/popovers/layout/Popover';
import React from 'react';
import styles from 'src/stylesheets/popovers/cronExpressionHelpPopover.scss';
import ExternalLink from 'src/components/ExternalLink';

const cronParts = [
    { value: '0', desc: 'minute' },
    { value: '9', desc: 'hour' },
    { value: '*', desc: 'day(month)' },
    { value: '*/3', desc: 'month' },
    { value: '1', desc: 'day (week)' }
];

const cronExamples = [
    { title: 'Quarterly', expression: '0 9 1 */3 *', translation: 'Every three months on the 1st at 9:00am.' },
    { title: 'Every x hours', expression: '0 */6 * * *', translation: 'Once every 6 hours.' }
];

const CronExpressionHelpPopover = () => (
    <Popover id="cronExpressionHelpPopover" width={350}>
        <div className={styles.wrapper}>
            <div className={styles.header}>Create custom schedules with cron jobs</div>
            <div className={styles.parts}>
                {
                    cronParts.map((part) => (
                        <div key={`${part.value}_${part.desc}`} className={styles.part}>
                            <div className={styles.value}>{part.value}</div>
                            <div className={styles.desc}>{part.desc}</div>
                        </div>
                    ))
                }
            </div>
            <div className={styles.examples}>
                {
                    cronExamples.map((example) => (
                        <div key={example.title} className={styles.example}>
                            <div>{`${example.title}: ${example.expression}`}</div>
                            <div>{`“${example.translation}”`}</div>
                        </div>
                    ))
                }
            </div>
            <ExternalLink link="https://en.wikipedia.org/wiki/Cron" text="Learn more" icon="vist-page" />
        </div>
    </Popover>
);

export default CronExpressionHelpPopover;
