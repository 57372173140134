import { makeSelectCurrentAdAccountLimitCountAndType } from 'src/selectors/adAccountSearch';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import React from 'react';
import AccountAddButton from 'src/components/buttons/AccountAddButton';
import Button from 'src/components/buttons/Button';
import { listName } from 'src/utils/adAccountSearch';

const SearchedAdAccountAddButton = (props) => {
    const {
        currentAdAccountLimitAndType,
        searchedAdAccount,
        adAccount
    } = props;

    return adAccount ? (
        <Button
          small
          icon="check-mark"
          label="Added"
          action
          disabled
        />
    ) : (
        <AccountAddButton
          accountId={searchedAdAccount.id}
          isAdding={false}
          currentAccountLimitCountAndType={currentAdAccountLimitAndType}
          listName={listName}
        />
    );
};

SearchedAdAccountAddButton.propTypes = {
    adAccount: customPropTypes.adAccount.isRequired,
    currentAdAccountLimitAndType: customPropTypes.currentAccountLimitCountAndType.isRequired,
    searchedAdAccount: customPropTypes.searchedAdAccount.isRequired
};

const makeMapStateToProps = () => {
    const selectCurrentAdAccountLimitCountAndType = makeSelectCurrentAdAccountLimitCountAndType();
    return (state) => ({
        currentAdAccountLimitAndType: selectCurrentAdAccountLimitCountAndType(state),
    });
};

export default connect(makeMapStateToProps)(SearchedAdAccountAddButton);
