import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/tabs/tab.scss';

const TabButton = (props) => {
    const {
        onClick, label, active, first, last, layout
    } = props;
    return (
        <button
          type="button"
          onClick={onClick}
          className={classnames(styles.tab, {
              [styles.activeTab]: active,
              [styles.first]: first,
              [styles.last]: last,
              [styles.discover]: layout === 'discover',
              [styles.qqlMetricTile]: layout === 'qqlMetricTile',

          })}
        >
            {label}
        </button>
    );
};

TabButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
    first: PropTypes.bool,
    last: PropTypes.bool,
    layout: PropTypes.oneOf(['default', 'discover', 'qqlMetricTile']),
};

TabButton.defaultProps = {
    active: false,
    first: false,
    last: false,
    layout: 'default'
};

export default TabButton;
