import Error from 'src/components/errors/Error';
import UserInvitationBox from 'src/components/userInvitations/UserInvitationBox';
import UserInvitationResendButton from 'src/components/userInvitations/UserInvitationResendButton';
import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/userInvitationBox.scss';

const UserInvitationError = ({ error, userId }) => {
    let button;
    if (error.errorType !== 'invitationAlreadyAccepted') {
        button = <UserInvitationResendButton userId={userId} />;
    }
    return (
        <UserInvitationBox cta={button}>
            <div className={styles.errorWrapper}><Error error={error} /></div>
        </UserInvitationBox>
    );
};

UserInvitationError.propTypes = {
    error: customPropTypes.error.isRequired,
    userId: PropTypes.string.isRequired
};

export default UserInvitationError;
