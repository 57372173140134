import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import ConfirmDeleteForm from 'src/components/forms/ConfirmDeleteForm';
import { dashboardShareLinkRemoveRequestFormAction } from 'src/actions/dashboardShareLinks';
import { isSubmitting, submit, getFormError } from 'redux-form';
import FormError from 'src/components/forms/feedback/FormError';
import { makeSelectDashboardShareLinkById } from 'src/selectors/dashboardShareLinks';

const formName = 'dashboardShareLinkDeleteForm';

export const DashboardShareLinkDeleteModal = (props) => {
    const {
        dashboardShareLink,
        hideModal,
        enforceFocus,
        isDeleting,
        submitAction,
        error
    } = props;
    const { id } = dashboardShareLink;
    return (
        <Modal dataViewName="dashboard-share-link-delete" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>Delete interactive report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        error && <FormError message={error.message} />
                    }
                    <div>
                        <div>
                            You are about to delete this report. This will not delete the dashboard of the report.
                        </div><br />
                        <ConfirmDeleteForm
                          form={formName}
                          initialValues={{ id }}
                          onSubmit={dashboardShareLinkRemoveRequestFormAction}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button onClick={hideModal} label="Cancel" />
                        <Button
                          action
                          warning
                          icon="delete"
                          label="Delete"
                          onClick={() => { submitAction(formName); }}
                          loading={isDeleting}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

DashboardShareLinkDeleteModal.propTypes = {
    dashboardShareLinkId: PropTypes.string.isRequired,
    dashboardShareLink: customPropTypes.dashboardShareLink.isRequired,
    hideModal: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    enforceFocus: PropTypes.bool,
    submitAction: PropTypes.func.isRequired,
    error: customPropTypes.dataLoadingError
};

const makeMapStateToProps = () => {
    const selectDashboardShareLinkById = makeSelectDashboardShareLinkById();
    return (state, ownProps) => ({
        dashboardShareLink: selectDashboardShareLinkById(state, ownProps.dashboardShareLinkId),
        isDeleting: isSubmitting(formName)(state),
        error: getFormError(formName)(state)
    });
};

export default connect(makeMapStateToProps, {
    submitAction: submit
})(DashboardShareLinkDeleteModal);
