import * as customPropTypes from 'src/customPropTypes';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import { required } from 'src/components/forms/validators';
import { Text, Checkbox } from 'src/components/forms/fields';
import { Field } from 'redux-form';
import _isEqual from 'lodash/isEqual';

const confirm = (value) => (
    (!_isEqual(value, 'DELETE')) ? 'Type DELETE to confirm.' : undefined
);

const PostTagRulesRemoveForm = (props) => {
    const { handleSubmit, error } = props;
    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <Field
              name="removeTagsOnPosts"
              text="Remove all tags on posts made by this rule. Manually tagged posts will stay untouched."
              component={Checkbox}
            />
            <Field
              name="confirmInput"
              label="Type DELETE to confirm"
              component={Text}
              validate={[required, confirm]}
              autoFocus
            />
        </form>
    );
};

PostTagRulesRemoveForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: customPropTypes.dataLoadingError
};

export default withReduxForm(PostTagRulesRemoveForm);
