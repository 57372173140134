import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as tiktokAuthUsersActions from 'src/actions/tiktokAuthUsers';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TiktokConnectButtonAndState from 'src/components/authTransactions/TiktokConnectButtonAndState';
import AuthTransactionRequirementsTable from 'src/components/authTransactions/table/AuthTransactionRequirementsTable';
import _memoize from 'memoize-one';

const TiktokAuthTransactionRequirementsConnect = (props) => {
    const {
        authTransaction,
        authTransactionRequirementsByProfileId,
        isPublic,
        showOnlyBasicInsightsUseCases
    } = props;

    const handleTiktokConnectRequest = (identifier, authTransactionId, authTransactionRequirements, profile) => {
        const { tiktokAuthUserConnectRequestAction, tiktokAuthUserExternalConnectRequestAction } = props;
        if (isPublic) {
            tiktokAuthUserExternalConnectRequestAction(authTransaction.hash, identifier, authTransactionId, authTransactionRequirements, profile);
        } else {
            tiktokAuthUserConnectRequestAction(identifier, authTransactionId, authTransactionRequirements, profile);
        }
    };

    const connectButtonPerProfileId = _memoize(() => {
        const buttons = [];
        Object.keys(authTransactionRequirementsByProfileId).forEach((profileId) => {
            buttons[profileId] = (
                <TiktokConnectButtonAndState
                  key={profileId}
                  profileId={profileId}
                  authTransaction={authTransaction}
                  connectAction={handleTiktokConnectRequest}
                  authTransactionRequirements={authTransactionRequirementsByProfileId[profileId]}
                />
            );
        });
        return buttons;
    });

    return (
        <AuthTransactionRequirementsTable
          platformType="tiktok"
          authTransactionRequirementsByProfileId={authTransactionRequirementsByProfileId}
          connectButtonsByAccountId={connectButtonPerProfileId()}
          showOnlyBasicInsightsUseCases={showOnlyBasicInsightsUseCases}
        />
    );
};

TiktokAuthTransactionRequirementsConnect.propTypes = {
    tiktokAuthUserConnectRequestAction: PropTypes.func.isRequired,
    tiktokAuthUserExternalConnectRequestAction: PropTypes.func.isRequired,
    authTransaction: customPropTypes.authTransaction.isRequired,
    authTransactionRequirementsByProfileId: PropTypes.objectOf(customPropTypes.profileAuthTransactionRequirements).isRequired,
    isPublic: PropTypes.bool.isRequired,
    showOnlyBasicInsightsUseCases: PropTypes.bool.isRequired
};

export default connect(null, {
    tiktokAuthUserConnectRequestAction: tiktokAuthUsersActions.tiktokAuthUserConnectRequest,
    tiktokAuthUserExternalConnectRequestAction: tiktokAuthUsersActions.tiktokAuthUserExternalConnectRequest,
})(TiktokAuthTransactionRequirementsConnect);
