import React, { PureComponent } from 'react';
import ColorPickerButton from 'src/components/buttons/ColorPickerButton';
import ColorPickerPopover from 'src/components/popovers/ColorPickerPopover';
import { isValidHex } from 'react-color/lib/helpers/color';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/colorPicker.scss';
import Text from 'src/components/forms/inputs/Text';
import withPopover from 'src/components/withPopover';

const ColorPickerButtonWithPopover = withPopover(ColorPickerButton);

class ColorPicker extends PureComponent {
    render() {
        const { onChange, value, error } = this.props;

        const overlay = <ColorPickerPopover onChange={onChange} hexValue={value} />;
        const hasErrors = error || (value !== '' && !isValidHex(value));
        const valueForPickerButton = !hasErrors ? value : '';

        return (
            <div className={styles.wrapper}>
                <Text {...this.props} className={styles.input} error={hasErrors} />
                <ColorPickerButtonWithPopover
                  placement="bottom-end"
                  overlay={overlay}
                  value={valueForPickerButton}
                  error={hasErrors}
                />
            </div>
        );
    }
}

ColorPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    error: PropTypes.bool,
    active: PropTypes.bool
};

ColorPicker.defaultProps = {
    error: false
};

export default ColorPicker;
