import { submit } from 'redux-form';

export const submitMetricBuilderForm = (form, buttonClicked = '') => {
    const originalAction = submit(form);
    originalAction.meta.buttonClicked = buttonClicked;
    return originalAction;
};

export const submitPostTagRuleForm = (form, ignoreCheck = false) => {
    const originalAction = submit(form);
    originalAction.meta.ignoreCheck = ignoreCheck;
    return originalAction;
};

export const submitPostTagEditForm = (form, ignoreHistoricalPostCheck = false) => {
    const originalAction = submit(form);
    originalAction.meta.ignoreCheck = ignoreHistoricalPostCheck;
    return originalAction;
};
