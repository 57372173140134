import React from 'react';
import { connect } from 'react-redux';
import { makeSelectAdAccountSearchedById } from 'src/selectors/adAccountSearch';
import * as customPropTypes from 'src/customPropTypes';
import AdAccountInfoListItem from 'src/components/views/adAccounts/AdAccountInfoListItem';

const CheckedAdAccount = (props) => {
    const { searchedAdAccount } = props;
    return <AdAccountInfoListItem adAccount={searchedAdAccount} />;
};

const makeMapStateToProps = () => {
    const selectAdAccountSearchedById = makeSelectAdAccountSearchedById();
    return (state, ownProps) => ({
        searchedAdAccount: selectAdAccountSearchedById(state, ownProps.id)
    });
};

CheckedAdAccount.propTypes = {
    searchedAdAccount: customPropTypes.searchedAdAccount.isRequired
};

export default connect(makeMapStateToProps)(CheckedAdAccount);
