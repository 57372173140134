import React from 'react';
import CustomPeriodInputBox from 'src/components/dateSelector/CustomPeriodInputBox';
import PropTypes from 'prop-types';
import QuickLinks from 'src/components/dateSelector/QuickLinks';
import * as customPropTypes from 'src/customPropTypes';

const InputBoxAndQuickLinks = (props) => {
    const {
        customPeriodValidator, onValidEnterKeyDown, additionalLinks, selectedDate, customInputActive, setCustomInputActive
    } = props;

    const handleQuickLinksSelection = (newDate) => {
        const { onDateChange } = props;
        setCustomInputActive(false);
        onDateChange(newDate);
    };

    const handleCustomPeriodInput = (newDate) => {
        const { onDateChange } = props;
        onDateChange(newDate);
    };
    return (
        <div>
            <CustomPeriodInputBox
              onInputChanged={handleCustomPeriodInput}
              validator={customPeriodValidator}
              onValidEnterKeyDown={onValidEnterKeyDown}
              selectedDate={selectedDate}
              active={customInputActive}
              setActive={setCustomInputActive}
            />
            <QuickLinks
              onQuickLinkClick={handleQuickLinksSelection}
              additionalLinks={additionalLinks}
              selectedDate={selectedDate}
              customInputActive={customInputActive}
            />
        </div>
    );
};

InputBoxAndQuickLinks.propTypes = {
    onDateChange: PropTypes.func.isRequired,
    customPeriodValidator: PropTypes.func,
    onValidEnterKeyDown: PropTypes.func,
    additionalLinks: PropTypes.arrayOf(PropTypes.node),
    selectedDate: customPropTypes.selectedDate.isRequired,
    customInputActive: PropTypes.bool,
    setCustomInputActive: PropTypes.func
};

InputBoxAndQuickLinks.defaultProps = {
    additionalLinks: [],
    customInputActive: false,
    setCustomInputActive: () => {}
};

export default InputBoxAndQuickLinks;
