import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/emptyInfoScreen.scss';

const EmptyInfoScreen = (props) => {
    const {
        image, title, message, buttons, imageClassName, width
    } = props;

    return (
        <div className={styles.emptyInfoScreen}>
            {
                image
                && (
                <div className={styles.image}>
                    <img src={image} alt="empty" className={imageClassName} width={width} />
                </div>
                )
            }
            {
                title
                && <div className={styles.title}>{title}</div>
            }
            {
                message
                && <div className={styles.message}>{message}</div>
            }
            {
                buttons
                && (
                <div className={styles.buttons}>
                    {buttons}
                </div>
                )
            }
        </div>
    );
};

EmptyInfoScreen.propTypes = {
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.node]),
    buttons: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    imageClassName: PropTypes.string,
    width: PropTypes.number
};

EmptyInfoScreen.defaultProps = {
    image: false,
    title: false,
    message: false,
    buttons: false,
    imageClassName: '',
    width: 'auto'
};

export default EmptyInfoScreen;
