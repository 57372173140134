import { isSubmitting, submit } from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { createSpaceRequestFormAction } from 'src/actions/spaces';
import { getClientLimits } from 'src/selectors/client';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import SpaceForm from 'src/components/forms/SpaceForm';

const formName = 'createSpace';

const initialFieldValues = {
    name: '',
    pageLimit: 0,
    adAccountLimit: 0,
    customLogo: {
        url: '',
        meta: {}
    }
};

const SpaceCreateModal = (props) => {
    const {
        hideModal, submitAction, clientLimits, submitting, enforceFocus
    } = props;
    const { freeLimits } = clientLimits;
    return (
        <Modal dataViewName="create-space" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Create space</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SpaceForm
                      form={formName}
                      onSubmit={createSpaceRequestFormAction}
                      freeProfileLimits={freeLimits.profiles}
                      freeAdAccountLimits={freeLimits.adAccounts}
                      initialValues={initialFieldValues}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button disabled={submitting} onClick={hideModal} label="Cancel" />
                        <Button
                          action
                          onClick={() => submitAction(formName)}
                          label="Create"
                          loading={submitting}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

SpaceCreateModal.propTypes = {
    clientLimits: customPropTypes.clientLimits.isRequired,
    hideModal: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    enforceFocus: PropTypes.bool
};

function mapStateToProps(state) {
    return {
        submitting: isSubmitting(formName)(state),
        clientLimits: getClientLimits(state)
    };
}

export default connect(mapStateToProps, {
    submitAction: submit
})(SpaceCreateModal);
