import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/views/profiles/search/socialNetworkAuthenticatedSearchOptions.scss';
import ProfileSocialNetworkButtons from 'src/components/views/profiles/ProfileSocialNetworkButtons';
import { modalShowSocialNetworkLoginUseCaseSelection } from 'src/actions/overlays';

const SocialNetworkAuthenticatedSearchOptions = (props) => {
    const { modalShowSocialNetworkLoginUseCaseSelectionAction } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.description}>Connect via the social network to add profiles with instant access to private data</div>
            <div className={styles.buttons}>
                <ProfileSocialNetworkButtons action={modalShowSocialNetworkLoginUseCaseSelectionAction} />
            </div>
        </div>
    );
};

SocialNetworkAuthenticatedSearchOptions.propTypes = {
    modalShowSocialNetworkLoginUseCaseSelectionAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalShowSocialNetworkLoginUseCaseSelectionAction: modalShowSocialNetworkLoginUseCaseSelection
})(SocialNetworkAuthenticatedSearchOptions);
