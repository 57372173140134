import SuperRootHandler from 'src/components/folderBrowser/SuperRootHandler';
import { makeSelectAllDashboardIdAndName, selectLastVisitedDashboards } from 'src/selectors/dashboards';
import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import Breadcrumb from 'src/components/breadcrumb/Breadcrumb';
import { connect } from 'react-redux';
import DashboardInModalListItemContainer from 'src/components/views/dashboards/DashboardInModalListItemContainer';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import FolderList from 'src/components/views/folders/FolderList';
import ModalListItem from 'src/components/views/folders/ModalListItem';
import noDashboards from 'src/resources/illustrations/no-dashboards.png';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/dashboardSearchableList.scss';
import TextWithResetIcon from 'src/components/forms/inputs/TextWithResetIcon';
import { SUPER_ROOT } from 'src/utils/dashboardFolders';
import _includes from 'lodash/includes';

class DashboardSearchableList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toFolderId: props.initialFolderId
        };
        this.onSearchQueryChange = this.onSearchQueryChange.bind(this);
        this.onSearchQueryReset = this.onSearchQueryReset.bind(this);
        this.onFolderOrBreadcrumbItemClick = this.onFolderOrBreadcrumbItemClick.bind(this);
    }

    onSearchQueryChange(event) {
        const { setSearchQuery } = this.props;
        setSearchQuery(event.target.value);
    }

    onSearchQueryReset() {
        const { setSearchQuery } = this.props;
        setSearchQuery('');
    }

    onFolderOrBreadcrumbItemClick(toFolderId) {
        const { onFolderClick } = this.props;
        this.setState({
            toFolderId
        });
        if (onFolderClick) {
            onFolderClick(toFolderId);
        }
    }

    render() {
        const {
            allDashboards,
            lastVisitedDashboards,
            onDashboardClick,
            searchQuery,
            disabledDashboardIds
        } = this.props;
        const { toFolderId } = this.state;

        let filteredDashboards = allDashboards;
        let filteredDashboardsToRender = null;
        let body = null;

        if (searchQuery.length === 0) {
            // if there is no search query entered, we just show the most recent and all dashboard together
            body = (
                <div className={styles.section}>
                    {
                        toFolderId !== SUPER_ROOT
                        && (
                        <div className={styles.breadcrumbWrapper}>
                            <div className={styles.breadcrumbChild}>
                                <Breadcrumb
                                  fixedItemBefore={{ id: SUPER_ROOT, name: 'Dashboards' }}
                                  small
                                  currentFolderId={toFolderId}
                                  onItemClick={this.onFolderOrBreadcrumbItemClick}
                                />
                            </div>
                        </div>
                        )
                    }
                    {toFolderId === SUPER_ROOT
                    && (
                        <h2 className={styles.sectionHeadline}>
                            All dashboards and folders
                        </h2>
                    )}
                    <SuperRootHandler toFolderId={toFolderId} setToFolderId={this.onFolderOrBreadcrumbItemClick}>
                        <FolderList
                          parentId={toFolderId}
                          movingFolderIds={[]}
                          onFolderClick={this.onFolderOrBreadcrumbItemClick}
                          onDashboardClick={onDashboardClick}
                          disableDashboards={false}
                          emptyInfoImage={noDashboards}
                          emptyInfoMessage="This folder has no dashboards."
                          disabledDashboardIds={disabledDashboardIds}
                        />
                    </SuperRootHandler>
                    {
                        toFolderId === SUPER_ROOT
                        && (
                            <div>
                                {
                                    lastVisitedDashboards.length > 0
                                    && (
                                        <div>
                                            <h2 className={styles.sectionHeadline}>
                                                Recently viewed
                                            </h2>
                                            { lastVisitedDashboards.map((lastVisitedDashboard) => (

                                                <ModalListItem
                                                  onClick={() => { onDashboardClick(lastVisitedDashboard.id); }}
                                                  key={lastVisitedDashboard.id}
                                                  disabled={_includes(disabledDashboardIds, lastVisitedDashboard.id)}
                                                >
                                                    <DashboardInModalListItemContainer
                                                      dashboardId={lastVisitedDashboard.id}
                                                    />
                                                </ModalListItem>
                                            )) }
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            );
        } else {
            // if the user entered a search query, just show a filtered list of all dashboards (no recent dashboard any more)
            const searchQueryToLower = searchQuery.toLowerCase();
            filteredDashboards = allDashboards.filter((dashboard) => dashboard.label.toLowerCase().indexOf(searchQueryToLower) > -1).sort((a, b) => {
                const alc = a.label.toLowerCase();
                const blc = b.label.toLowerCase();

                if (alc > blc) {
                    return 1;
                }

                return alc < blc ? -1 : 0;
            });

            if (filteredDashboards.length > 0) {
                // if the search query has any result
                filteredDashboardsToRender = filteredDashboards.map((dashboard) => (
                    <ModalListItem
                      onClick={() => { onDashboardClick(dashboard.id); }}
                      key={dashboard.id}
                      disabled={_includes(disabledDashboardIds, dashboard.id)}
                    >
                        <DashboardInModalListItemContainer
                          dashboardId={dashboard.id}
                        />
                    </ModalListItem>
                ));
            } else {
                // else show emptyInfoScreen message
                filteredDashboardsToRender = (
                    <EmptyInfoScreen
                      image={noDashboards}
                      message="No dashboards found."
                    />
                );
            }

            body = (
                <div className={styles.section}>
                    {filteredDashboardsToRender}
                </div>
            );
        }

        return (
            <div>
                <TextWithResetIcon
                  error={!!searchQuery && filteredDashboardsToRender.length === 0}
                  autoFocus
                  placeholder="Search for dashboards"
                  onChange={this.onSearchQueryChange}
                  value={searchQuery}
                  onResetClick={this.onSearchQueryReset}
                />
                <div className={styles.body}>
                    {body}
                </div>
            </div>
        );
    }
}

DashboardSearchableList.propTypes = {
    allDashboards: customPropTypes.selectListOptions.isRequired,
    lastVisitedDashboards: customPropTypes.dashboards.isRequired,
    onDashboardClick: PropTypes.func.isRequired,
    onFolderClick: PropTypes.func.isRequired,
    initialFolderId: PropTypes.string.isRequired,
    searchQuery: PropTypes.string.isRequired,
    setSearchQuery: PropTypes.func.isRequired,
    disabledDashboardIds: PropTypes.arrayOf(PropTypes.string)
};

DashboardSearchableList.defaultProps = {
    disabledDashboardIds: []
};

const makeMapStateToProps = () => {
    const selectAllDashboardIdsAndName = makeSelectAllDashboardIdAndName();
    return (state) => ({
        allDashboards: selectAllDashboardIdsAndName(state),
        lastVisitedDashboards: selectLastVisitedDashboards(state)
    });
};

export default connect(makeMapStateToProps)(DashboardSearchableList);
