import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { selectIsInstagramProfileConverting } from 'src/selectors/profileHealth';
import { tagProfileAsInstagramBusinessProfileRequest } from 'src/actions/profileHealth';
import styles from 'src/stylesheets/instagramTagAsBusinessProfileWarning.scss';

const InstagramTagAsBusinessProfileWarning = (props) => {
    const { profile, tagProfileAsInstagramBusinessProfileRequestAction, isInstagramProfileConverting } = props;
    const { id, platformId, platformUsername } = profile;

    return (
        <div className={styles.wrapper}>
            <div className={styles.text}>
                If you have already converted your personal profile into a business profile and you are still seeing this message, your profile has not been tagged as a business profile in our database.
            </div>
            <Button
              label="Tag as business profile"
              onClick={() => { tagProfileAsInstagramBusinessProfileRequestAction(id, platformId, platformUsername); }}
              feedbackWarning
              disabled={isInstagramProfileConverting}
              stretch
              small
            />
        </div>
    );
};

InstagramTagAsBusinessProfileWarning.propTypes = {
    profile: customPropTypes.profile.isRequired,
    tagProfileAsInstagramBusinessProfileRequestAction: PropTypes.func.isRequired,
    isInstagramProfileConverting: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    const isInstagramProfileConverting = selectIsInstagramProfileConverting(state);
    return {
        isInstagramProfileConverting
    };
};

export default connect(mapStateToProps, {
    tagProfileAsInstagramBusinessProfileRequestAction: tagProfileAsInstagramBusinessProfileRequest
})(InstagramTagAsBusinessProfileWarning);
