import { connect } from 'react-redux';
import { makeSelectGroupsByIds } from 'src/selectors/groups';
import PropTypes from 'prop-types';
import React from 'react';
import EntityOperationInfoModal from 'src/components/modals/EntityOperationInfoModal';
import * as customPropTypes from 'src/customPropTypes';

const ForceDeleteGroupModalContainer = (props) => {
    const {
        forceOperationalGroupInfos,
        nonDeletableGroups,
        hideModal,
        enforceFocus
    } = props;

    const notRemovedGroupNames = nonDeletableGroups.map((group) => group.name);
    return (
        <EntityOperationInfoModal
          nonOperationalEntityNames={notRemovedGroupNames}
          forceOperationalEntityInfos={forceOperationalGroupInfos}
          operationalType="forceGroupDelete"
          hideModal={hideModal}
          enforceFocus={enforceFocus}
        />
    );
};

ForceDeleteGroupModalContainer.propTypes = {
    hideModal: PropTypes.func.isRequired,
    forceOperationalGroupInfos: customPropTypes.forceOperationalEntityInfos.isRequired,
    nonDeletableGroups: customPropTypes.groups.isRequired,
    enforceFocus: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
    const { notRemovedGroupIds } = ownProps;
    const selectGroupsByIds = makeSelectGroupsByIds();
    return {
        nonDeletableGroups: selectGroupsByIds(state, notRemovedGroupIds)
    };
};

export default connect(mapStateToProps)(ForceDeleteGroupModalContainer);
