import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import ErrorModal from 'src/components/modals/ErrorModal';
import PropTypes from 'prop-types';

class ModalErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch() {
        this.setState({ hasError: true });
    }

    render() {
        const { hasError } = this.state;
        if (hasError) {
            const { enforceFocus, hideModal } = this.props;

            // You can render any custom fallback UI
            return (
                <ErrorModal
                  enforceFocus={enforceFocus}
                  handleError={hideModal}
                  error={{
                      title: 'Modal content cannot be rendered',
                      message: 'This happens most likely when a modal is called via route.',
                      id: 'error',
                      handled: false
                  }}
                />
            );
        }
        const { children } = this.props;
        return children;
    }
}

ModalErrorBoundary.propTypes = {
    children: customPropTypes.children.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool
};

export default ModalErrorBoundary;
