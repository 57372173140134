import { createSelector } from 'reselect';
import {
    makeStringSorter, createDeepEqualSelector, makePropertyExtractor, makeGroupFilter, makePlatformTypeFilter
} from 'src/selectors/utils';

export const defaultNameStringOrderWithExcludedPrefix = () => makeStringSorter('defaultName', 'asc', '@');

export const makeOrderBySorter = () => createSelector(
    [
        (items) => items,
        (_, orderBy) => orderBy
    ],
    (items, orderBy = 'alphabetically') => {
        if (orderBy === 'alphabetically') {
            const sortedByAppendix = items.slice().sort(makeStringSorter('appendix', 'asc'));
            return sortedByAppendix.slice().sort(defaultNameStringOrderWithExcludedPrefix());
        }
        if (orderBy === 'recentlyAdded') {
            return items.slice().sort(makeStringSorter('addedTime', 'desc'));
        }
        return items;
    }
);

const defaultFilter = (items, filterQuery) => items.filter((account) => (
    account.defaultName.toLowerCase().indexOf(filterQuery) > -1 || (account.appendix && account.appendix.toLowerCase().indexOf(filterQuery) > -1)
));

export const makeSearchQueryFilter = (filter = defaultFilter) => createSelector(
    [
        (items) => items,
        (_, filterQuery) => filterQuery
    ],
    (items, filterQuery) => {
        if (filterQuery.length < 1) {
            return items;
        }
        const filterQueryToLower = filterQuery.toLowerCase();
        return filter(items, filterQueryToLower);
    }
);

export const makeSelectAccountIdsFromAccountsWithListFiltersApplied = () => {
    const searchQueryFilter = makeSearchQueryFilter();
    const sorter = makeOrderBySorter();
    const idsExtractor = makePropertyExtractor('id');
    const groupFilter = makeGroupFilter();
    const platformTypeFilter = makePlatformTypeFilter();

    return createDeepEqualSelector([(state, adAccounts, filterBy, platformType, group, orderBy) => {
        const queryFiltered = searchQueryFilter(adAccounts, filterBy);
        const groupFiltered = groupFilter(queryFiltered, group);
        const platformTypeFiltered = platformTypeFilter(groupFiltered, platformType);
        const sorted = sorter(platformTypeFiltered, orderBy);
        return idsExtractor(sorted);
    }], (ids) => ids);
};
