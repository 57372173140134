import * as customPropTypes from 'src/customPropTypes';
import ProfileAuthTransactionRequirementsRow from 'src/components/authTransactions/table/ProfileAuthTransactionRequirementsRow';
import AdAccountAuthTransactionRequirementsRow from 'src/components/authTransactions/table/AdAccountAuthTransactionRequirementsRow';
import PlatformUseCasesTableHeader from 'src/components/views/authentications/table/PlatformUseCasesTableHeader';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/authTransactions/authTransactionRequirementsTable.scss';
import { selectUseCasesByPlatformWithBasicInsights } from 'src/selectors/useCases';
import { connect } from 'react-redux';
import classnames from 'classnames';
import ListTable from 'src/components/listTable/ListTable';

const AuthTransactionRequirementsTable = (props) => {
    const {
        platformUseCases,
        authTransactionRequirementsByProfileId,
        authTransactionRequirementsByAdAccountId,
        platformType,
        connectButtonsByAccountId
    } = props;
    return (
        <div className={classnames(styles.bottomBorder, styles.bottomPadded)} key={platformType}>
            <ListTable header={(
                <PlatformUseCasesTableHeader
                  platformUseCases={platformUseCases}
                  showUseCaseDetail
                  platformType={platformType}
                  connectButton={connectButtonsByAccountId.length > 0}
                />
            )}
            >
                {
                    Object.keys(authTransactionRequirementsByProfileId).map((profileId) => (
                        <ProfileAuthTransactionRequirementsRow
                          key={profileId}
                          profileId={profileId}
                          platformUseCases={platformUseCases}
                          requirements={authTransactionRequirementsByProfileId[profileId]}
                          connectButton={connectButtonsByAccountId[profileId] || null}
                        />
                    ))
                }
                {
                    Object.keys(authTransactionRequirementsByAdAccountId).map((adAccountId) => (
                        <AdAccountAuthTransactionRequirementsRow
                          key={adAccountId}
                          adAccountId={adAccountId}
                          platformUseCases={platformUseCases}
                          requirements={authTransactionRequirementsByAdAccountId[adAccountId]}
                          connectButton={connectButtonsByAccountId[adAccountId] || null}
                        />
                    ))
                }
            </ListTable>
        </div>
    );
};

AuthTransactionRequirementsTable.propTypes = {
    authTransactionRequirementsByProfileId: PropTypes.objectOf(customPropTypes.profileAuthTransactionRequirements),
    authTransactionRequirementsByAdAccountId: PropTypes.objectOf(customPropTypes.adAccountAuthTransactionRequirements),
    platformUseCases: customPropTypes.useCases.isRequired,
    platformType: customPropTypes.platformTypes.isRequired,
    connectButtonsByAccountId: PropTypes.arrayOf(PropTypes.node),
    showOnlyBasicInsightsUseCases: PropTypes.bool
};

AuthTransactionRequirementsTable.defaultProps = {
    authTransactionRequirementsByProfileId: {},
    authTransactionRequirementsByAdAccountId: {},
    connectButtonsByAccountId: [],
    showOnlyBasicInsightsUseCases: false
};

const mapStateToProps = (state, ownProps) => ({
    platformUseCases: selectUseCasesByPlatformWithBasicInsights(state, ownProps.platformType, ownProps.showOnlyBasicInsightsUseCases)
});

export default connect(mapStateToProps)(AuthTransactionRequirementsTable);
