export const PREPARE_EXPORT_DATA = 'PREPARE_EXPORT_DATA';

export const prepareExportData = (
    context,
    metrics,
    dashboardMetrics,
    accountOptions,
    visualizations,
    profiles,
    adAccounts,
    groups,
    metricResponses,
    metricRequests,
    clientSetting,
    postTags,
    adAccountCampaigns,
    faceliftTags,
    faceliftCampaigns
) => ({
    type: PREPARE_EXPORT_DATA,
    payload: {
        context,
        metrics,
        dashboardMetrics,
        accountOptions,
        visualizations,
        profiles,
        adAccounts,
        groups,
        metricResponses,
        metricRequests,
        clientSetting,
        postTags,
        adAccountCampaigns,
        faceliftTags,
        faceliftCampaigns
    }
});
