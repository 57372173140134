import React from 'react';
import PropTypes from 'prop-types';
import NetworkButton from 'src/components/buttons/NetworkButton';
import styles from 'src/stylesheets/views/profiles/socialNetworkButtons.scss';

const AdAccountSocialNetworkButtons = (props) => {
    const { action } = props;
    return (
        <div className={styles.wrapper}>
            <NetworkButton platformType="facebook" onClick={() => { action('meta'); }} label="Continue with Facebook" />
        </div>
    );
};

AdAccountSocialNetworkButtons.propTypes = {
    action: PropTypes.func.isRequired
};

export default AdAccountSocialNetworkButtons;
