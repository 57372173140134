import React, { Component } from 'react';
import _has from 'lodash/has';
import AceEditorAutoCompleteHelper from 'src/components/forms/inputs/layout/AceEditorAutoCompleteHelper';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import RawAceEditor from 'react-ace';
import styles from 'src/stylesheets/aceEditor.scss';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/mode-json';
import 'src/components/forms/inputs/aceThemes/quintly';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-searchbox';

import withFlexDimensionProvider from 'src/components/withFlexDimensionProvider';

const handleOnPaste = (event) => {
    try {
        const parsed = JSON.parse(event.text);
        Object.assign(event, { text: JSON.stringify(parsed, null, 4) });
    } catch (e) {
    }
};

class AceEditor extends Component {
    constructor(props) {
        super(props);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.insertValue = this.insertValue.bind(this);
        this.setValue = this.setValue.bind(this);
        this.handleOnFocus = this.handleOnFocus.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.aceEditor = null;
    }

    componentDidMount() {
        // Once the componentDidMount, remove the initial value from the undoManager stack, in order to not
        // revert into empty text box
        setTimeout(() => {
            if (_has(this.aceEditor, 'editor.session')) {
                this.aceEditor.editor.session.getUndoManager().reset();
                this.aceEditor.editor.commands.removeCommand('gotoline');
            }
        }, 300);
    }

    handleOnChange(value) {
        const { onChange } = this.props;
        onChange(value);
    }

    handleOnBlur(e, editor) {
        const { onBlur } = this.props;
        const value = editor.session.getValue();
        onBlur(value);
    }

    handleOnFocus(e, editor) {
        const { onFocus } = this.props;
        const value = editor.session.getValue();
        onFocus(value);
    }

    setValue(value) {
        const { session } = this.aceEditor.editor;
        session.setValue(value, 1);
        this.aceEditor.editor.focus();
    }

    insertValue(value) {
        const { session } = this.aceEditor.editor;
        const cursorPosition = this.aceEditor.editor.getCursorPosition();
        session.insert({
            row: cursorPosition.row,
            column: cursorPosition.column
        }, `${value} `);
        this.aceEditor.editor.focus();
    }

    render() {
        const {
            value, name, width, height, mode, liveAutoComplete, isReadOnly, aceEditorRef, disabled
        } = this.props;

        return (
            <div className={classnames(styles.formFieldWrapper, { [styles.disabled]: disabled })}>
                <RawAceEditor
                  name={name}
                  theme="quintly"
                  mode={mode}
                  width={`${width}px`}
                  height={`${height}px`}
                  value={value}
                  ref={(aceEditor) => {
                      this.aceEditor = aceEditor;
                      aceEditorRef(aceEditor);
                  }}
                  onChange={this.handleOnChange}
                  onFocus={this.handleOnFocus}
                  onBlur={this.handleOnBlur}
                  onPaste={handleOnPaste}
                  tabSize={4}
                  fontSize={13}
                  showGutter
                  enableLiveAutocompletion={liveAutoComplete}
                  enableBasicAutocompletion={liveAutoComplete}
                  enableSnippets={false}
                  showPrintMargin={false}
                  wrapEnabled
                  editorProps={{ $blockScrolling: Infinity }}
                  readOnly={isReadOnly}
                  setOptions={{ useWorker: false }}
                />
                <input name={name} value={value} type="hidden" />
                { mode === 'json' && <AceEditorAutoCompleteHelper /> }
            </div>
        );
    }
}

AceEditor.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    mode: PropTypes.oneOf(['json', 'sql']),
    error: PropTypes.bool,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    liveAutoComplete: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    aceEditorRef: PropTypes.func,
    disabled: PropTypes.bool
};

AceEditor.defaultProps = {
    value: '',
    mode: 'sql',
    liveAutoComplete: false,
    error: false,
    isReadOnly: false,
    disabled: false,
    aceEditorRef: () => {},
    onFocus: () => {},
    onBlur: () => {},
    onChange: () => {}
};

export default withFlexDimensionProvider(AceEditor, true, true);
