import AdAccountDetailsPage from 'src/components/views/adAccounts/AdAccountDetailsPage';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import withResourceCheck from 'src/components/modals/withResourceCheck';
import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import { modalsShowAdAccountDelete } from 'src/actions/overlays';
import styles from 'src/stylesheets/profileDetailsPage.scss';
import { makeSelectAdAccountById } from 'src/selectors/adAccounts';

const AdAccountDetailsModal = (props) => {
    const {
        hideModal,
        adAccount,
        modalsShowAdAccountDeleteAction
    } = props;

    const deleteButton = (
        <Button
          warning
          label="Delete ad account"
          onClick={() => { modalsShowAdAccountDeleteAction([adAccount.id]); }}
          icon="delete"
        />
    );

    return (
        <Modal dataViewName="ad-account-detail" onHide={hideModal} key={`ad_account_detail_${adAccount.id}`}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Ad account details</Modal.Title>
                </Modal.Header>
                <Modal.Body padded={false} className={styles.modal} />
                <AdAccountDetailsPage adAccount={adAccount} />
                <Modal.Footer buttonsLeft={deleteButton}>
                    <Button onClick={hideModal} label="Close" />
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

AdAccountDetailsModal.propTypes = {
    adAccount: customPropTypes.adAccount.isRequired,
    hideModal: PropTypes.func.isRequired,
    modalsShowAdAccountDeleteAction: PropTypes.func.isRequired
};

const ProfileDetailsModalWithResourceCheck = withResourceCheck(AdAccountDetailsModal, 'adAccount', 'adAccount');

const makeMapStateToProps = () => {
    const selectAdAccountById = makeSelectAdAccountById();
    return (state, ownProps) => {
        const { adAccountId } = ownProps;
        return {
            adAccount: selectAdAccountById(state, adAccountId)
        };
    };
};

export default connect(makeMapStateToProps, {
    modalsShowAdAccountDeleteAction: ensurePermission(modalsShowAdAccountDelete, writePermissions.deleteProfile)
})(ProfileDetailsModalWithResourceCheck);
