import * as customPropTypes from 'src/customPropTypes';
import { FieldArray } from 'redux-form';
import FieldLabel from 'src/components/forms/fields/FieldLabel';
import PropTypes from 'prop-types';
import React from 'react';
import SpaceOptionSelector from 'src/components/forms/inputs/SpaceOptionSelector';

const SpaceOptionSelectorFieldArray = (props) => {
    const { label, spaces } = props;
    return (
        <div>
            { label && <FieldLabel htmlFor="spaceList">{label}</FieldLabel> }
            {
                spaces.map((space, index) => {
                    const { groups, id } = space;
                    const groupTags = groups.map((option) => ({ id: option.id, name: option.name, spaceId: id }));
                    return (
                        <FieldArray
                          id={index}
                          key={space.id}
                          name={`space-${space.id}`}
                          space={space}
                          groupTags={groupTags}
                          component={SpaceOptionSelector}
                          rerenderOnEveryChange
                        />
                    );
                })
            }
        </div>
    );
};

SpaceOptionSelectorFieldArray.propTypes = {
    label: PropTypes.string.isRequired,
    spaces: customPropTypes.spacesForManagement.isRequired
};

export default SpaceOptionSelectorFieldArray;
