import * as customPropTypes from 'src/customPropTypes';
import Checkbox from 'src/components/forms/inputs/Checkbox';
import { connect } from 'react-redux';
import { makeSelectFieldValue } from 'src/selectors/lists';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/authTransactions/profileListItem.scss';
import { toggleListValue } from 'src/actions/lists';
import AccountPrivateStats from 'src/components/AccountPrivateStats';
import { getProfileListItemName, getAdAccountListItemName } from 'src/utils/accountInsights';
import AdAccountInfoListItem from 'src/components/views/adAccounts/AdAccountInfoListItem';
import ProfileInfoListItem from 'src/components/views/profiles/ProfileInfoListItem';

const getListNameFromAccountIdByAccountType = (accountId, accountType) => {
    if (accountType === 'profiles') {
        return getProfileListItemName(accountId);
    }
    if (accountType === 'adAccounts') {
        return getAdAccountListItemName(accountId);
    }
    return accountId;
};

export const renderAccountInfo = (account, accountType, highlight = '') => {
    if (accountType === 'profiles') {
        return <ProfileInfoListItem profile={account} highlight={highlight} />;
    }
    if (accountType === 'adAccounts') {
        return <AdAccountInfoListItem adAccount={account} highlight={highlight} />;
    }
    return null;
};

const AccountListItem = (props) => {
    const {
        account,
        listName,
        highlight,
        toggleListValueAction,
        isAccountChecked,
        accountType
    } = props;
    return (
        <div className={styles.wrapper} onClick={() => { toggleListValueAction(listName, getListNameFromAccountIdByAccountType(account.id, accountType)); }}>
            <div className={styles.left}>
                <div className={styles.checkboxWrapper}>
                    <Checkbox
                      checked={isAccountChecked}
                      readOnly
                    />
                </div>
                <div className={styles.profileInfoWrapper}>
                    {
                        renderAccountInfo(account, accountType, highlight)
                    }
                </div>
                <div className={styles.insightsIndicator}>
                    <AccountPrivateStats account={account} />
                </div>
            </div>
            <div className={styles.right} />
        </div>
    );
};

AccountListItem.propTypes = {
    accountType: customPropTypes.accountTypes.isRequired,
    account: customPropTypes.account.isRequired,
    highlight: PropTypes.string.isRequired,
    listName: PropTypes.string.isRequired,
    toggleListValueAction: PropTypes.func.isRequired,
    isAccountChecked: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectFieldValue = makeSelectFieldValue();
    return (state, ownProps) => ({
        isAccountChecked: selectFieldValue(state, getListNameFromAccountIdByAccountType(ownProps.account.id, ownProps.accountType), ownProps.listName)
    });
};

export default connect(makeMapStateToProps, {
    toggleListValueAction: toggleListValue
})(AccountListItem);
