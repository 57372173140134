import { makeSelectIsProfileHealthy } from 'src/selectors/profileHealth';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import styles from 'src/stylesheets/profile/profileHealthStatusBorder.scss';

const ProfileHealthStatusBorder = (props) => {
    const { isHealthy, children } = props;
    return (
        <div className={classnames(styles.wrapper, { [styles.unHealthy]: !isHealthy })}>
            {children}
        </div>
    );
};

ProfileHealthStatusBorder.propTypes = {
    profileId: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    isHealthy: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectIsProfileHealthy = makeSelectIsProfileHealthy();
    return (state, ownProps) => ({
        isHealthy: selectIsProfileHealthy(state, ownProps.profileId)
    });
};

export default connect(makeMapStateToProps)(ProfileHealthStatusBorder);
