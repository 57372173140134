import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import styles from 'src/stylesheets/modal.scss';

const ModalFooter = (props) => {
    const { buttonsLeft, children } = props;
    return (
        <div className={styles.footer}>
            {
                buttonsLeft && <div className={styles.footerLeft}>{buttonsLeft}</div>
            }
            {children}
        </div>
    );
};

ModalFooter.propTypes = {
    children: customPropTypes.children,
    buttonsLeft: customPropTypes.children,
    fullscreen: PropTypes.bool
};

ModalFooter.defaultProps = {
    fullscreen: null
};

export default ModalFooter;
