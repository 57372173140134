import React from 'react';
import { Select, Text } from 'src/components/forms/fields';
import { Field } from 'redux-form';
import { required } from 'src/components/forms/validators';

const GoogleBigQueryDataPushTaskFields = () => (
    <>
        <Field
          autoFocus
          name="awsS3AccessKeyId"
          component={Text}
          label="Access key"
          validate={required}
        />
        <Field
          autoFocus
          name="awsS3SecretAccessKey"
          component={Text}
          label="Secret access key"
          validate={required}
        />
        <Field
          autoFocus
          name="awsS3Region"
          component={Text}
          label="Region"
          validate={required}
        />
        <Field
          autoFocus
          name="awsS3Bucket"
          component={Text}
          label="Bucket"
          validate={required}
        />
        <Field
          autoFocus
          name="awsS3FilePrefix"
          component={Text}
          label="File prefix"
          validate={required}
        />
        <Field
          autoFocus
          name="awsS3FileFormat"
          component={Select}
          label="Format"
        >
            <option value="csv">CSV</option>
            <option value="json">JSON</option>
        </Field>
    </>
);

export default GoogleBigQueryDataPushTaskFields;
