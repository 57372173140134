import * as customPropTypes from 'src/customPropTypes';
import * as snapchatAuthUserActions from 'src/actions/snapchatAuthUsers';
import { connect } from 'react-redux';
import { makeSelectAuthTransactionById } from 'src/selectors/authTransactions';
import PropTypes from 'prop-types';
import React from 'react';
import AuthUserInAnotherSpaceWarningModal from 'src/components/modals/AuthUserInAnotherSpaceWarningModal';

const SnapchatAuthUserInAnotherSpaceAuthTransactionWarningModal = (props) => {
    const handleCompleteAuthenticationButtonClick = () => {
        const {
            authTransaction,
            identifier,
            authTransactionRequirementIds,
            accessToken,
            expiresIn,
            refreshAccessToken,
            platformUserId,
            displayName,
            snapchatAuthUserForceConnectRequestAction,
            snapchatAuthUserExternalForceConnectRequestAction,
            profileIds,
            isPublic
        } = props;
        if (isPublic) {
            snapchatAuthUserExternalForceConnectRequestAction(
                authTransaction.hash,
                identifier,
                authTransaction.id,
                authTransactionRequirementIds,
                accessToken,
                refreshAccessToken,
                expiresIn,
                platformUserId,
                displayName,
                profileIds
            );
        } else {
            snapchatAuthUserForceConnectRequestAction(
                identifier,
                authTransaction.id,
                authTransactionRequirementIds,
                accessToken,
                refreshAccessToken,
                expiresIn,
                platformUserId,
                displayName,
                profileIds
            );
        }
    };

    const { hideModal, enforceFocus } = props;
    return (
        <AuthUserInAnotherSpaceWarningModal
          hideModal={hideModal}
          enforceFocus={enforceFocus}
          onCompleteAuthenticationClick={handleCompleteAuthenticationButtonClick}
          networkType="snapchat"
        />
    );
};

SnapchatAuthUserInAnotherSpaceAuthTransactionWarningModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    authTransaction: customPropTypes.authTransaction.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    hasConfirmed: PropTypes.bool.isRequired,
    snapchatAuthUserForceConnectRequestAction: PropTypes.func.isRequired,
    snapchatAuthUserExternalForceConnectRequestAction: PropTypes.func.isRequired,
    identifier: PropTypes.string.isRequired,
    authTransactionId: PropTypes.string.isRequired,
    authTransactionRequirementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    accessToken: PropTypes.string.isRequired,
    refreshAccessToken: PropTypes.string.isRequired,
    expiresIn: PropTypes.number.isRequired,
    platformUserId: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    isPublic: PropTypes.string.isRequired,
    profileIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

const makeMapStateToProps = () => {
    const selectAuthTransactionById = makeSelectAuthTransactionById();
    return (state, ownProps) => ({
        authTransaction: selectAuthTransactionById(state, ownProps.authTransactionId)
    });
};

export default connect(makeMapStateToProps, {
    snapchatAuthUserForceConnectRequestAction: snapchatAuthUserActions.snapchatAuthUserForceConnectRequest,
    snapchatAuthUserExternalForceConnectRequestAction: snapchatAuthUserActions.snapchatAuthUserExternalForceConnectRequest,
})(SnapchatAuthUserInAnotherSpaceAuthTransactionWarningModal);
