import React from 'react';
import { connect } from 'react-redux';
import { socialNetworkAdAccountShowListRequest } from 'src/actions/adAccountSearch';
import PropTypes from 'prop-types';
import AvailableConnectedAdAccounts from 'src/components/views/adAccounts/AvailableConnectedAdAccounts';
import styles from 'src/stylesheets/modals/adAccountSearchStartPage.scss';
import AdAccountSocialNetworkButtons from 'src/components/views/adAccounts/AdAccountSocialNetworkButtons';

const AdAccountSearchStartPage = (props) => {
    const { socialNetworkAdAccountShowListRequestAction } = props;
    return (
        <div>
            <div className={styles.nonResultPage}>
                <div className={styles.description}>Connect via the social network to add ad accounts with instant access to private data</div>
                <div className={styles.buttons}>
                    <AdAccountSocialNetworkButtons action={socialNetworkAdAccountShowListRequestAction} />
                </div>
            </div>
            <AvailableConnectedAdAccounts />
        </div>
    );
};

AdAccountSearchStartPage.propTypes = {
    socialNetworkAdAccountShowListRequestAction: PropTypes.func.isRequired
};

export default connect(null, {
    socialNetworkAdAccountShowListRequestAction: socialNetworkAdAccountShowListRequest
})(AdAccountSearchStartPage);
