import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import SocialNetworkUseCasesForm from 'src/components/forms/SocialNetworkUseCasesForm';
import { submit, getFormValues } from 'redux-form';
import _split from 'lodash/split';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import NetworkButton from 'src/components/buttons/NetworkButton';
import memoize from 'memoize-one';
import UseCasePermissionNames from 'src/components/views/public/authProfileAuthUserConnectionState/UseCasePermissionNames';
import styles from 'src/stylesheets/modals/socialNetworkLoginUseCaseSelectionModal.scss';

const formName = 'socialNetworkUseCaseSelectionForm';

const getUseCaseIdsFromFormValues = memoize((formValues) => {
    const useCases = [];
    if (formValues) {
        Object.keys(formValues).forEach((key) => {
            const value = formValues[key];
            if (value === true) {
                const splittedValue = _split(key, '_');
                useCases.push(splittedValue[0]);
            }
        });
    }
    return useCases;
});

const GraphLoginUseCaseSelectionModal = (props) => {
    const {
        hideModal, enforceFocus, platformUseCases, submitAction, formValues, handleRequestAction
    } = props;

    const handleOnSubmit = (finalFormValues) => {
        const useCases = getUseCaseIdsFromFormValues(finalFormValues);
        if (useCases.length > 0) {
            handleRequestAction(useCases);
        }
    };

    const selectedUseCaseIds = getUseCaseIdsFromFormValues(formValues);
    return (
        <Modal dataViewName="graph-login-use-case-selection" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog width={580}>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Select the permissions you would like to grant
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body height={450}>
                    <div className={styles.body}>
                        <div className={styles.form}>
                            <SocialNetworkUseCasesForm
                              platformUseCases={platformUseCases}
                              form={formName}
                              onSubmit={handleOnSubmit}
                            />
                        </div>
                        <div className={styles.permissionNames}>
                            <UseCasePermissionNames useCaseIds={selectedUseCaseIds} />
                        </div>
                        <div className={styles.info}>
                            In the next step, Facebook will ask you which pages you want to use with quintly. If you&apos;ve previously linked quintly to Facebook, be sure to click on <b>&apos;Edit settings&apos;</b> to select the pages you want to use.
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <NetworkButton
                          platformType="facebook"
                          label="Continue with Facebook"
                          small={false}
                          disabled={selectedUseCaseIds.length === 0}
                          onClick={() => { submitAction(formName); }}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

GraphLoginUseCaseSelectionModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired,
    submitAction: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    handleRequestAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const formValueSelector = getFormValues(formName);
    return (state) => ({
        formValues: formValueSelector(state)
    });
};

export default connect(makeMapStateToProps, {
    submitAction: submit
})(GraphLoginUseCaseSelectionModal);
