import { getProfileSearchInput } from 'src/selectors/profileSearch';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import AuthenticatedSearchProfileResult from 'src/components/views/profiles/search/AuthenticatedSearchProfileResult';
import InputProfileSearchResults from 'src/components/views/profiles/search/InputSearchProfileResults';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/profileSearch.scss';
import ProfileSearchInputForm from 'src/components/forms/ProfileSearchInputForm';

const ProfileSearch = (props) => {
    const {
        input,
        isAuthenticatedSearchResultPage
    } = props;
    const isInputSearchResultPage = input.keyword.length > 0;

    const initialValues = {
        input: input.keyword
    };

    return (
        <>
            {
                !isAuthenticatedSearchResultPage
                && (
                    <div className={styles.searchWrapper}>
                        <ProfileSearchInputForm initialValues={initialValues} isResultPage={isInputSearchResultPage} />
                    </div>
                )
            }
            {
                isInputSearchResultPage && <InputProfileSearchResults />
            }
            {
                isAuthenticatedSearchResultPage && <AuthenticatedSearchProfileResult />
            }
        </>
    );
};

ProfileSearch.propTypes = {
    input: customPropTypes.profileSearchInput.isRequired,
    isAuthenticatedSearchResultPage: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
    input: getProfileSearchInput(state),
});

export default connect(mapStateToProps)(ProfileSearch);
