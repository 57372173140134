import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import NetworkButton from 'src/components/buttons/NetworkButton';
import faceliftIcon from 'src/resources/icons/facelift_icon.svg';
import styles from 'src/stylesheets/modals/twitterLogin.scss';

const TwitterLoginModal = (props) => {
    const {
        hideModal, enforceFocus, onContinueAction
    } = props;

    const handleOnContinue = () => {
        onContinueAction();
        hideModal();
    };

    return (
        <Modal dataViewName="twitter-login-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        <div className={styles.title}>
                            <img className={styles.img} src={faceliftIcon} /> Connect X via facelift
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Once you continue to X, we will ask you to authenticate your X profiles via the facelift app. As quintly is a part of facelift, we authenticate X via one joined application.</div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <NetworkButton
                          platformType="twitter"
                          label="Continue with X"
                          small
                          onClick={handleOnContinue}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

TwitterLoginModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    onContinueAction: PropTypes.func.isRequired
};

export default TwitterLoginModal;
