import _omit from 'lodash/omit';
import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import React from 'react';
import styles from 'src/stylesheets/labels/useCaseStatus.scss';
import withTooltip from 'src/components/withToolTip';
import PropTypes from 'prop-types';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const UseCaseStatusLabel = (props) => {
    const { valid, invalidReason } = props;
    const cleanedProps = _omit(props, ['accountUseCasesAuthUser', 'valid', 'invalidReason']);
    return (
        <IcomoonIconWithTooltip
          icon={valid ? 'check-mark-in-box' : 'alert'}
          tooltip={valid ? 'Valid' : `Invalid: ${invalidReason}`}
          className={classnames(styles.icon, { [styles.valid]: valid, [styles.invalid]: !valid })}
          {...cleanedProps}
        />
    );
};

UseCaseStatusLabel.propTypes = {
    valid: PropTypes.bool.isRequired,
    invalidReason: PropTypes.string
};

export default UseCaseStatusLabel;
