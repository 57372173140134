import React, { useEffect } from 'react';
import ProfileHealthChartView from 'src/components/profileHealth/ProfileHealthChartView';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/profileHealth.scss';
import * as profileHealthsActions from 'src/actions/profileHealth';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeSelectHealthDataAsyncStateByProfileId, makeSelectProfileHealthMetricDataByProfileId } from 'src/selectors/profileHealth';
import Error from 'src/components/errors/Error';
import ProfileHealthStatusBorder from 'src/components/profile/ProfileHealthStatusBorder';

const ProfileHealth = (props) => {
    const {
        profile, profileHealthMetricDataGetRequestAction, profileHealthMetricData, loadingState
    } = props;

    const { isPending, error } = loadingState;
    useEffect(() => {
        profileHealthMetricDataGetRequestAction(profile.id);
    }, []);

    let height = 250;
    if (profileHealthMetricData.yAxisCategories.length > 0) {
        height = (profileHealthMetricData.yAxisCategories.length * 50) + 40;
    }

    let content = <ProfileHealthChartView className={styles.chartView} profileHealthMetricData={profileHealthMetricData} />;
    if (isPending) {
        content = <div>Loading...</div>;
    } else if (!isPending && error) {
        content = <Error error={error} />;
    }

    return (
        <ProfileHealthStatusBorder profileId={profile.id}>
            <div className={styles.body}>
                <div className={styles.header}>
                    <div className={styles.title}>Profile health</div>
                    <div className={styles.info}>* Data recorded from 01.07.2020 </div>
                </div>
                <div className={styles.lineChart} style={{ height }}>
                    {content}
                </div>
            </div>
        </ProfileHealthStatusBorder>
    );
};

ProfileHealth.propTypes = {
    profile: customPropTypes.profile.isRequired,
    profileHealthMetricDataGetRequestAction: PropTypes.func.isRequired,
    loadingState: customPropTypes.asyncDataLoadingState.isRequired,
    profileHealthMetricData: customPropTypes.profileHealthMetricData.isRequired
};

const makeMapStateToProps = () => {
    const selectHealthDataAsyncStateByProfileId = makeSelectHealthDataAsyncStateByProfileId();
    const selectProfileHealthMetricDataByProfileId = makeSelectProfileHealthMetricDataByProfileId();

    return (state, ownProps) => {
        const { profile } = ownProps;
        return {
            profileHealthMetricData: selectProfileHealthMetricDataByProfileId(state, profile.id),
            loadingState: selectHealthDataAsyncStateByProfileId(state, profile.id)
        };
    };
};

export default connect(makeMapStateToProps, {
    profileHealthMetricDataGetRequestAction: profileHealthsActions.profileHealthMetricDataGetRequest
})(ProfileHealth);
