import CustomSelectListItem from 'src/components/forms/inputs/layout/CustomSelectListItem';
import TimezoneSelectHeader from 'src/components/forms/inputs/layout/TimezoneSelectHeader';
import React from 'react';
import CustomSelect from 'src/components/forms/inputs/CustomSelect';
import { timezonesObject } from 'src/components/forms/inputs/layout/TimezoneOptions';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/timezoneSelect.scss';

const renderValue = (option, shortcutOnly) => {
    if (shortcutOnly) {
        return option.abbrev;
    }
    return option.name;
};

const renderOption = (option, index, active, onChange) => (
    <CustomSelectListItem
      onClick={() => {
          onChange(option.value);
      }}
      active={active}
    >
        <div
          className={styles.item}
          key={option.value}
          value={option.value}
        >{option.name}
        </div>
    </CustomSelectListItem>
);

const TimezoneSelect = (props) => {
    const {
        onChange, value, disabled, shortcutOnly, hideQuickSelect, popoverWidth
    } = props;

    return (
        <div>
            <CustomSelect
              options={timezonesObject}
              renderHeader={(filter, setFilter, error, onValueChange, setActiveIndex, onKeyDownHandler, resetIndex) => (
                  <TimezoneSelectHeader
                    onValueChange={onValueChange}
                    onFilterChange={setFilter}
                    filter={filter}
                    error={error}
                    setActiveIndex={setActiveIndex}
                    hideQuickSelect={hideQuickSelect}
                    onKeyDownHandler={onKeyDownHandler}
                    resetActiveIndex={resetIndex}
                  />
              )}
              renderOption={renderOption}
              renderValue={(option) => renderValue(option, shortcutOnly)}
              disabled={disabled}
              onChange={onChange}
              value={value}
              popoverWidth={popoverWidth}
            />
        </div>
    );
};

TimezoneSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    shortcutOnly: PropTypes.bool,
    hideQuickSelect: PropTypes.bool,
    popoverWidth: PropTypes.number
};

TimezoneSelect.defaultProps = {
    disabled: false,
    shortcutOnly: true,
    hideQuickSelect: false,
    popoverWidth: 400
};

export default TimezoneSelect;
