import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';
import { selectVisualizationsByPos } from 'src/selectors/visualizations';
import styles from 'src/stylesheets/inputs/visualizationPopover.scss';
import VisualizationList from 'src/components/VisualizationList';

const VisualizationListPopover = (props) => {
    const {
        visualizations, selectedOptionId, onSelectionChange, hidePopover, onFocus, onBlur
    } = props;

    const onChange = (value) => {
        onSelectionChange(value);
        hidePopover();
    };

    return (
        <Popover id="visualizationListPopover">
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <VisualizationList
                      options={visualizations}
                      selectedOptionId={selectedOptionId}
                      onChange={onChange}
                      onBlur={onBlur}
                      onFocus={onFocus}
                    />
                </div>
            </div>
        </Popover>
    );
};

VisualizationListPopover.propTypes = {
    visualizations: PropTypes.arrayOf(customPropTypes.visualizationType).isRequired,
    selectedOptionId: PropTypes.string.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    hidePopover: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
};

const mapStateToProps = (state) => ({
    visualizations: selectVisualizationsByPos(state)
});

export default connect(mapStateToProps)(VisualizationListPopover);
