import classnames from 'classnames';
import { getInsightsLabelFrom } from 'src/utils/accountInsights';
import Label from 'src/components/labels/Label';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/labels/connectedProfileLabel.scss';

const ConnectedAccountLabel = (props) => {
    const {
        finalState, platformType, onMouseOver, onMouseOut, onClick, active
    } = props;
    const label = getInsightsLabelFrom(platformType);
    return (
        <div
          className={styles.wrapper}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClick={onClick}
          role="button"
        >
            <div className={classnames(styles.button, { [styles.clickable]: onClick }, { [styles.active]: active })}>
                <Label state={finalState} />
                <div className={classnames(styles.label, styles.active, { [styles[finalState]]: finalState === 'error' })}>{label}</div>
            </div>
        </div>
    );
};

ConnectedAccountLabel.propTypes = {
    platformType: PropTypes.string.isRequired,
    finalState: PropTypes.string.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    onMouseOut: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    active: PropTypes.bool.isRequired
};

export default ConnectedAccountLabel;
