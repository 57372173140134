import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import styles from 'src/stylesheets/popovers/platformProfileTypeInfoPopover.scss';
import Popover from 'src/components/popovers/layout/Popover';

const PlatformProfileTypeInfoPopover = (props) => {
    const { platformProfileTypeInfo } = props;
    const { title, content } = platformProfileTypeInfo;
    return (
        <Popover id="Profile-type-info-popover">
            <div className={styles.wrapper}>
                <div className={styles.typeInfo}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
                </div>
            </div>
        </Popover>
    );
};

PlatformProfileTypeInfoPopover.propTypes = {
    platformProfileTypeInfo: customPropTypes.platformProfileTypeInfo.isRequired
};

export default PlatformProfileTypeInfoPopover;
