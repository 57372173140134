import {
    checkAllListValues, checkListValues, uncheckAllListValues, uncheckListValues
} from 'src/actions/lists';
import React, { Component } from 'react';
import _omit from 'lodash/omit';
import { connect } from 'react-redux';
import { makeSelectBulkCheckedState } from 'src/selectors/lists';
import PropTypes from 'prop-types';
import withTooltip from 'src/components/withToolTip';

const extractStateFromTriState = (checkState, intermediateCheckState) => {
    switch (checkState) {
        case 'checked':
            return { indeterminate: false, checked: true };
        case 'indeterminate':
            return { indeterminate: true, checked: intermediateCheckState };
        case 'unchecked':
        default:
            return { indeterminate: false, checked: false };
    }
};

const withBulkCheckboxHandling = (WrappedComponent, intermediateCheckState = false) => {
    const WrappedComponentWithTooltip = withTooltip(WrappedComponent);

    class WithBulkCheckboxHandling extends Component {
        constructor(props) {
            super(props);
            this.handleOnChange = this.handleOnChange.bind(this);
        }

        handleOnChange() {
            const {
                uncheckListValuesAction,
                uncheckAllListValuesAction,
                checkListValuesAction,
                checkAllListValuesAction,
                checkState,
                listItemIdsToHandle,
                listName
            } = this.props;
            const checkboxState = extractStateFromTriState(checkState, intermediateCheckState);
            if (checkboxState.checked === true) {
                if (listItemIdsToHandle) {
                    uncheckListValuesAction(listName, listItemIdsToHandle);
                } else {
                    uncheckAllListValuesAction(listName);
                }
            } else if (listItemIdsToHandle) {
                checkListValuesAction(listName, listItemIdsToHandle);
            } else {
                checkAllListValuesAction(listName);
            }
        }

        render() {
            const { checkState, disabled, listName } = this.props;
            const checkboxState = extractStateFromTriState(checkState, intermediateCheckState);
            const cleanedProps = _omit(this.props, [
                'checkAllListValuesAction',
                'uncheckAllListValuesAction',
                'checkState',
                'listItemIdsToHandle',
                'uncheckListValuesAction',
                'checkListValuesAction',
                'listName'
            ]);

            let tooltip = checkState === 'checked' ? 'Deselect all' : 'Select all';
            if (checkState === 'indeterminate' && intermediateCheckState) {
                tooltip = 'Deselect all';
            }

            return (
                <WrappedComponentWithTooltip
                  name={`${listName}BulkCheckbox`}
                  indeterminate={checkboxState.indeterminate}
                  checked={checkboxState.checked}
                  onChange={this.handleOnChange}
                  disabled={disabled}
                  tooltip={tooltip}
                  {...cleanedProps}
                />
            );
        }
    }

    WithBulkCheckboxHandling.propTypes = {
        uncheckListValuesAction: PropTypes.func.isRequired,
        uncheckAllListValuesAction: PropTypes.func.isRequired,
        checkAllListValuesAction: PropTypes.func.isRequired,
        checkListValuesAction: PropTypes.func.isRequired,
        checkState: PropTypes.oneOf(['checked', 'unchecked', 'indeterminate']).isRequired,
        disabled: PropTypes.bool,
        listName: PropTypes.string.isRequired,
        listItemIdsToHandle: PropTypes.arrayOf(PropTypes.string)
    };

    WithBulkCheckboxHandling.defaultProps = {
        disabled: false,
    };

    const makeMapStateToProps = () => {
        const selectBulkCheckedState = makeSelectBulkCheckedState();
        return (state, ownProps) => ({
            checkState: selectBulkCheckedState(state, ownProps.listItemIdsToHandle, ownProps.listName)
        });
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithBulkCheckboxHandling.displayName = `withBulkCheckboxHandling(${wrappedComponentName})`;

    return connect(makeMapStateToProps, {
        uncheckListValuesAction: uncheckListValues,
        uncheckAllListValuesAction: uncheckAllListValues,
        checkListValuesAction: checkListValues,
        checkAllListValuesAction: checkAllListValues
    })(WithBulkCheckboxHandling);
};

export default withBulkCheckboxHandling;
