import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectEntityTypeByEntityAndId } from 'src/selectors/ownership';
import { getReadableOwnershipType } from 'src/utils/ownership';

const EntityOwnershipNameResolver = ({ ownershipType }) => getReadableOwnershipType(ownershipType);

EntityOwnershipNameResolver.propTypes = {
    entityId: PropTypes.string.isRequired,
    entityType: customPropTypes.ownershipEntity.isRequired,
    ownershipType: customPropTypes.ownershipType.isRequired
};

const mapStateTopProps = (state, ownProps) => ({
    ownershipType: selectEntityTypeByEntityAndId(state, ownProps.entityType, ownProps.entityId)
});

export default connect(mapStateTopProps)(EntityOwnershipNameResolver);
