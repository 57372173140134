import { createSelector } from 'reselect';
import _get from 'lodash/get';

export const getCronExpressionTranslations = (state) => state.dashboardReportSchedule.translationByExpression;
export const getCronExpressionNextRunDates = (state) => state.dashboardReportSchedule.nextRunDateByExpressionAndTimezone;

const cronTranslationInitialState = {
    loading: true, translation: '', error: null, requested: false
};

const cronNextRunDateInitialState = {
    loading: true, date: '', error: null, requested: false
};

export const makeSelectCronTranslation = () => createSelector(
    [
        getCronExpressionTranslations,
        (_, expression) => expression
    ],
    (cronExpressionTranslations, selection) => _get(cronExpressionTranslations, selection, cronTranslationInitialState)
);

export const makeSelectCronNextRunDate = () => createSelector(
    [
        getCronExpressionNextRunDates,
        (_, expression) => expression
    ],
    (cronExpressionNextRunDates, selection) => _get(cronExpressionNextRunDates, selection, cronNextRunDateInitialState)
);
