import React from 'react';
import styles from 'src/stylesheets/views/profiles/search/addedProfileItem.scss';
import { makeSelectProfileById } from 'src/selectors/profiles';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ProfileAudienceDataView from 'src/components/views/profiles/ProfileAudienceDataView';
import ProfilePicture from 'src/components/profile/ProfilePicture';
import ProfileNameAndMetaInfo from 'src/components/profile/ProfileNameAndMetaInfo';

const AddedProfileItem = (props) => {
    const { profile, first, last } = props;
    return (
        <div className={classnames(styles.item, { [styles.first]: first === true }, { [styles.last]: last === true })}>
            <div className={styles.profilePictureWrapper}>
                <ProfilePicture picture={profile.picture} lazyLoadProfileImage />
            </div>
            <div className={styles.detail}>
                <div className={styles.meta}>
                    <ProfileNameAndMetaInfo profile={profile} showPlatformProfileInfo />
                </div>
                <ProfileAudienceDataView profileId={profile.id} />
            </div>
        </div>
    );
};

AddedProfileItem.propTypes = {
    profileId: PropTypes.string.isRequired,
    profile: customPropTypes.profile.isRequired,
    first: PropTypes.bool,
    last: PropTypes.bool
};

const makeMapStateToProps = () => {
    const selectProfileById = makeSelectProfileById();
    return (state, ownProps) => {
        const { profileId } = ownProps;
        const profile = selectProfileById(state, profileId);
        return {
            profile,
        };
    };
};

export default connect(makeMapStateToProps)(AddedProfileItem);
