import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';

const SessionInvalidModal = (props) => {
    const { enforceFocus, message } = props;
    return (
        <Modal dataViewName="session-invalid-modal" enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>
                        Your browser session just got invalid
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        message
                    }
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          action
                          label="Reload this page"
                          onClick={() => {
                              window.location.reload();
                          }}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

SessionInvalidModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    enforceFocus: PropTypes.bool
};

export default SessionInvalidModal;
