import React from 'react';
import PropTypes from 'prop-types';
import Text from 'src/components/forms/inputs/Text';
import styles from 'src/stylesheets/inputs/advancedReportScheduler.scss';
import CronExpressionTranslation from 'src/components/dashboardReportSchedule/CronExpressionTranslation';
import CronExpressionNextRunDate from 'src/components/dashboardReportSchedule/CronExpressionNextRunDate';
import TimezoneSelect from 'src/components/forms/inputs/TimezoneSelect';
import CronExpressionHelpPopover from 'src/components/popovers/CronExpressionHelpPopover';
import TextWithPopover from 'src/components/TextWithPopover';
import * as customPropTypes from 'src/customPropTypes';

const AdvancedReportScheduler = (props) => {
    const {
        name, value, onFocus, onBlur, error, onChange, timezoneDisabled, title
    } = props;
    const { cronExpression, timezone } = value;

    const handleOnExpressionChange = (e) => {
        onChange(Object.assign({}, value, { cronExpression: e.target.value }));
    };

    const handleOnTimezoneChange = (newTimezone) => {
        onChange(Object.assign({}, value, { timezone: newTimezone }));
    };

    const handleOnBlur = () => {
        onBlur(value);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.top}>
                <div className={styles.expressionWrapper}>
                    <div className={styles.header}>{title}</div>
                    <div className={styles.expression}>
                        <Text
                          value={cronExpression}
                          onChange={handleOnExpressionChange}
                          onBlur={handleOnBlur}
                          onFocus={onFocus}
                          error={error}
                        />
                        <div className={styles.help}>
                            <TextWithPopover label="Cron examples" overlay={<CronExpressionHelpPopover />} placement="top-start" />
                        </div>
                        <div className={styles.translation}>
                            <CronExpressionTranslation expression={cronExpression} />
                        </div>
                    </div>
                </div>
                <div className={styles.timezone}>
                    <div className={styles.header}>Time zone</div>
                    <TimezoneSelect value={timezone} onChange={handleOnTimezoneChange} disabled={timezoneDisabled} />
                </div>
            </div>
            <div>
                <CronExpressionNextRunDate cronExpression={cronExpression} timezone={timezone} />
            </div>
            <input
              type="hidden"
              name={name}
              value={value}
            />
        </div>
    );
};

AdvancedReportScheduler.propTypes = {
    value: customPropTypes.advancedReportSchedule.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    error: PropTypes.bool,
    title: PropTypes.string,
    timezoneDisabled: PropTypes.bool
};

AdvancedReportScheduler.defaultProps = {
    timezoneDisabled: false,
    title: 'Cron expression'
};

export default AdvancedReportScheduler;
