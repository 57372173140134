import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import SelectedOptionListItem from 'src/components/inlineList/SelectedOptionListItem';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inlineList/selectedOptionList.scss';

const SelectedOptionList = (props) => {
    const {
        selectedOptions,
        onCloseClick,
        onIndeterminateClick,
        isBulkSelection
    } = props;
    return (
        <div className={styles.wrapper}>
            {
                selectedOptions.map(({ option, isIndeterminate }) => (
                    <div key={`inline-item-${option.id}`} className={styles.item}>
                        <SelectedOptionListItem
                          option={option}
                          isIndeterminate={isIndeterminate}
                          onCloseClick={onCloseClick}
                          onIndeterminateClick={onIndeterminateClick}
                          isBulkSelection={isBulkSelection}
                        />
                    </div>
                ))
            }
        </div>
    );
};

SelectedOptionList.propTypes = {
    selectedOptions: customPropTypes.inlineListOptions.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onIndeterminateClick: PropTypes.func.isRequired,
    isBulkSelection: PropTypes.bool.isRequired
};

export default SelectedOptionList;
