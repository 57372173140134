import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/inlineList/selectedListItem.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';
import * as customPropTypes from 'src/customPropTypes';
import withTooltip from 'src/components/withToolTip';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const getAddTooltip = (isBulkSelection) => (isBulkSelection ? 'Add to all' : 'Add');
const getRemoveTooltip = (isBulkSelection) => (isBulkSelection ? 'Remove from all' : 'Remove');

const SelectedOptionListItem = (props) => {
    const {
        option,
        isIndeterminate,
        isBulkSelection
    } = props;
    const { label } = option;

    const handleOnCloseClick = () => {
        const { onCloseClick } = props;
        onCloseClick(option);
    };

    const handleOnIndeterminateClick = () => {
        const { onIndeterminateClick } = props;
        onIndeterminateClick(option);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.name}>
                <DivWithTooltip tooltip={label}>
                    {label}
                </DivWithTooltip>
            </div>
            <div>
                {
                    isIndeterminate && (
                        <IcomoonIconWithTooltip
                          icon="add"
                          tooltip={getAddTooltip(isBulkSelection)}
                          className={styles.icon}
                          onClick={handleOnIndeterminateClick}
                        />
                    )
                }
                {
                    !isIndeterminate && (
                        <IcomoonIconWithTooltip
                          icon="remove"
                          tooltip={getRemoveTooltip(isBulkSelection)}
                          className={styles.icon}
                          onClick={handleOnCloseClick}
                        />
                    )
                }
            </div>
        </div>
    );
};

SelectedOptionListItem.propTypes = {
    option: customPropTypes.selectListOption.isRequired,
    isIndeterminate: PropTypes.bool.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onIndeterminateClick: PropTypes.func.isRequired,
    isBulkSelection: PropTypes.bool.isRequired
};

export default SelectedOptionListItem;
