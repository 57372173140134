import { Select, TextWithResetIcon } from 'src/components/forms/fields';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import ListFilters from 'src/components/ListFilters';
import ListOptionsBar from 'src/components/ListOptionsBar';
import PropTypes from 'prop-types';
import React from 'react';
import { selectGroups } from 'src/selectors/groups';
import withReduxForm from 'src/components/forms/withReduxForm';
import { authTransactionNetworkFilter } from 'src/utils/profiles';

const sortingOptions = [
    { value: 'alphabetically', label: 'Name (A-Z)' },
    { value: 'recentlyAdded', label: 'Recently added' }
];

const groupFilterStyle = { maxWidth: 220 };
const searchFilterStyle = { width: 220 };

const AuthTransactionProfileFilterForm = (props) => {
    const { groups, change } = props;
    return (
        <ListOptionsBar
          filters={(
              <ListFilters>
                  <div style={groupFilterStyle}>
                      <Field
                        name="groupFilter"
                        component={Select}
                        layout="listFilterWhite"
                        activeOnDirty
                      >
                          {
                              groups.map(({
                                  id, name, profileIds, adAccountIds
                              }) => (
                                  <option key={`select-option-${id}`} value={id}>
                                      {
                                          id === '0'
                                          && `All accounts (${profileIds.length + adAccountIds.length})`
                                      }
                                      {
                                          id !== '0'
                                          && `${name} (${profileIds.length + adAccountIds.length})`
                                      }
                                  </option>
                              ))
                          }
                      </Field>
                  </div>
                  <Field
                    name="networkFilter"
                    component={Select}
                    layout="listFilterWhite"
                    activeOnDirty
                  >
                      {
                          authTransactionNetworkFilter.map((network) => (
                              <option key={`select-option-${network.value}`} value={network.value}>{network.label}</option>
                          ))
                      }
                  </Field>
                  <div style={searchFilterStyle}>
                      <Field
                        name="freeTextFilter"
                        component={TextWithResetIcon}
                        onResetClick={() => { change('freeTextFilter', ''); }}
                        placeholder="Search my accounts"
                        layout="listFilter"
                        activeOnDirty
                        autoFocus
                      />
                  </div>
              </ListFilters>
          )}
          sorting={(
              <Field
                name="sortBy"
                component={Select}
                layout="listFilterWhite"
                activeOnDirty
              >
                  {
                      sortingOptions.map((option) => (
                          <option key={`select-option-${option.value}`} value={option.value}>{option.label}</option>
                      ))
                  }
              </Field>
          )}
        />
    );
};

const mapStateToProps = (state) => ({
    groups: selectGroups(state)
});

AuthTransactionProfileFilterForm.propTypes = {
    groups: customPropTypes.groups.isRequired,
    change: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(withReduxForm(AuthTransactionProfileFilterForm));
