import _range from 'lodash/range';
import PropTypes from 'prop-types';
import React from 'react';
import ListTable from 'src/components/listTable/ListTable';
import ListTableRow from 'src/components/listTable/ListTableRow';
import ListTableCell from 'src/components/listTable/ListTableCell';
import StickyTableHeader from 'src/components/StickyTableHeader';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';

const Header = ({ columns }) => (
    <StickyTableHeader>
        <tr>
            {
                _range(columns).map((column) => (
                    <ListTableCell key={column} isHeaderCell><EmptyDiv height={20} /></ListTableCell>
                ))
            }
        </tr>
    </StickyTableHeader>
);

Header.propTypes = {
    columns: PropTypes.number.isRequired
};

const ListTableSkeleton = (props) => {
    const { rows, columns } = props;
    return (
        <ListTable header={<Header columns={columns} />}>
            {
                _range(rows).map((item) => (
                    <ListTableRow key={item}>
                        {
                            _range(columns).map((column) => (
                                <ListTableCell key={column}><EmptyDiv height={20} /></ListTableCell>
                            ))
                        }
                    </ListTableRow>
                ))
            }
        </ListTable>
    );
};

ListTableSkeleton.propTypes = {
    rows: PropTypes.number,
    columns: PropTypes.number,
};

ListTableSkeleton.defaultProps = {
    rows: 10,
    columns: 5
};

export default ListTableSkeleton;
