import List from 'src/components/List';
import PropTypes from 'prop-types';
import React from 'react';
import SearchedProfileResultListItem from 'src/components/views/profiles/search/SearchedProfileResultListItem';

const ProfileSearchResultList = (props) => {
    const { searchedProfileIds, isPending } = props;
    return (
        <List disabled={isPending}>
            {
                searchedProfileIds.map((searchedProfileId) => (
                    <SearchedProfileResultListItem
                      key={`searched-profile-${searchedProfileId}`}
                      searchedProfileId={searchedProfileId}
                    />
                ))
            }
        </List>
    );
};

ProfileSearchResultList.propTypes = {
    searchedProfileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    isPending: PropTypes.bool
};

ProfileSearchResultList.defaultProps = {
    isPending: false
};

export default ProfileSearchResultList;
