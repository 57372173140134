import { selectDashboardById } from 'src/selectors/dashboards';
import { connect } from 'react-redux';
import DashboardInModalListItem from 'src/components/views/dashboards/DashboardInModalListItem';
import PropTypes from 'prop-types';
import { selectDashboardTypeById } from 'src/selectors/ownership';
import { selectLoggedInUser } from 'src/selectors/users';
import { getFolderIconByOwnersipType } from 'src/utils/ownership';

const makeMapStateToProps = () => (state, ownProps) => {
    const { dashboardId } = ownProps;
    const dashboard = selectDashboardById(state, dashboardId);
    const dashboardType = selectDashboardTypeById(state, dashboardId);

    return {
        dashboard,
        dashboardType,
        loggedInUser: selectLoggedInUser(state),
        labelIcon: getFolderIconByOwnersipType(dashboardType)
    };
};

const DashboardInModalListItemContainer = connect(makeMapStateToProps)(DashboardInModalListItem);

DashboardInModalListItemContainer.propTypes = {
    dashboardId: PropTypes.string.isRequired
};

export default DashboardInModalListItemContainer;
