import AuthTransactionProfileFilterForm from 'src/components/forms/AuthTransactionProfileFilterForm';
import CheckBox from 'src/components/forms/inputs/Checkbox';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/authTransactions/authTransactionProfileListHeader.scss';
import withBulkCheckboxHandling from 'src/components/bulkList/withBulkCheckboxHandling';

const ProfileListBulkCheckbox = withBulkCheckboxHandling(CheckBox, true);

const AuthTransactionProfileListHeader = (props) => {
    const {
        listName,
        listItemIdsToHandle,
        formName,
        initialValues
    } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.checkOption}>
                <ProfileListBulkCheckbox listName={listName} listItemIdsToHandle={listItemIdsToHandle} />
            </div>
            <div className={styles.form}>
                <AuthTransactionProfileFilterForm form={formName} initialValues={initialValues} />
            </div>
        </div>
    );
};

AuthTransactionProfileListHeader.propTypes = {
    listName: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    listItemIdsToHandle: PropTypes.arrayOf(PropTypes.string).isRequired,
    initialValues: PropTypes.shape({
        groupFilter: PropTypes.string.isRequired,
        networkFilter: PropTypes.string.isRequired,
        freeTextFilter: PropTypes.string.isRequired,
        sortBy: PropTypes.string.isRequired
    }).isRequired
};

export default AuthTransactionProfileListHeader;
