import ActiveSelectionHandler from 'src/components/ActiveSelectionHandler';
import React, { useState } from 'react';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/popovers/customSelectPopover.scss';
import memoizeOne from 'memoize-one';
import _delay from 'lodash/delay';
import _replace from 'lodash/replace';

const filterValues = memoizeOne((options, searchString) => {
    if (searchString === '') {
        return options;
    }
    return options.filter((value) => {
        const convertedString = _replace(value.name, '_', ' ');
        const stringLower = _replace(searchString, '_', ' ');
        return convertedString.toLowerCase().includes(stringLower.toLowerCase());
    });
});

const CustomSelectPopover = (props) => {
    const {
        onChange,
        options,
        hidePopover,
        renderOption,
        renderHeader,
        width,
        activeIndex: initialActiveIndex
    } = props;

    const [filter, setFilter] = useState('');
    const filteredOptions = filterValues(options, filter);

    const hasOptions = filteredOptions.length > 0;

    const onChangeWithHide = (newValue) => {
        onChange(newValue);
        // we need to delay here otherwise the hide popover triggers too fast breaking the event chain
        _delay(hidePopover, 50);
    };

    const commitActiveIndex = (index) => {
        if (index < filteredOptions.length) {
            onChangeWithHide(filteredOptions[index].id);
        }
    };

    return (
        <ActiveSelectionHandler numberOptions={filteredOptions.length} initialActiveIndex={initialActiveIndex} onCommit={commitActiveIndex}>
            {(activeIndex, onKeyHandler, setIndex, resetIndex) => (
                <Popover width={width} id="customSelectPopover">
                    {
                    renderHeader && renderHeader(
                        filter,
                        setFilter,
                        filteredOptions.length < 1,
                        onChangeWithHide,
                        setIndex,
                        onKeyHandler,
                        resetIndex
                    )
                }

                    {hasOptions
                && (
                    <div className={styles.menuWrapper}>
                        <ul className={styles.menuList}>
                            {
                                filteredOptions.map((option, idx) => {
                                    const active = idx === activeIndex;
                                    return (
                                        <li role="option" key={option.id} aria-selected={active}>
                                            {renderOption(option, idx, active, onChangeWithHide)}
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                )}

                    {!hasOptions && filter !== '' && <div className={styles.noMatch}>No matching options</div>}
                </Popover>
            )}
        </ActiveSelectionHandler>
    );
};

CustomSelectPopover.propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.any.isRequired,
    hidePopover: PropTypes.func,
    renderOption: PropTypes.func.isRequired,
    renderHeader: PropTypes.func,
    width: PropTypes.number,
    activeIndex: PropTypes.number.isRequired,
};

CustomSelectPopover.defaultProps = {
    width: 250
};

export default CustomSelectPopover;
