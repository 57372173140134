import React from 'react';
import styles from 'src/stylesheets/divider.scss';
import PropTypes from 'prop-types';

const Divider = ({ sideMargin }) => <hr className={styles.divider} style={{ marginLeft: sideMargin, marginRight: sideMargin }} />;

Divider.propTypes = {
    sideMargin: PropTypes.number
};

Divider.defaultProps = {
    sideMargin: 24
};

export default Divider;
