import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { makeSelectActivePostTags } from 'src/selectors/postTags';
import { getShouldTagAllPosts, makeSelectPostTagIdsByPostIdsWithIndeterminatedPostTags, selectAddRemovePostTagsState } from 'src/selectors/posts';
import { serverDataClearAllCache } from 'src/actions/serverData';
import { getListOption } from 'src/utils/postTag';
import InlineListWithSearchbar from 'src/components/inlineList/InlineListWithSearchbar';
import { postTagAddRequest } from 'src/actions/postTags';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import noTags from 'src/resources/illustrations/tags_empty_small.png';
import {
    addRemovePostTagsFromPostsRequest,
    addRemovePostTagsFromPostByFilterRequest
} from 'src/actions/posts';
import { makeLengthValidator } from 'src/components/inlineList/validators';

const postTagNameLengthValidator = makeLengthValidator('tag', 1, 30);

const style = {
    height: '495px'
};

export const renderEmptyActiveTags = () => <EmptyInfoScreen message="You don't have any active tags yet, start typing to add one." image={noTags} />;

const AddOrRemovePostTagsFromPostsModal = (props) => {
    const {
        postIds,
        selectedPostTagIds,
        indeterminatePostTagIds,
        postTags,
        hideModal,
        enforceFocus,
        addRemovePostTagState
    } = props;
    const { isPending } = addRemovePostTagState;

    const options = getListOption(postTags);

    const handleOnApplyClick = (selectedTagOptions) => {
        const {
            serverDataClearAllCacheAction, shouldTagAllPosts, addRemovePostTagsFromPostsRequestAction, addRemovePostTagsFromPostByFilterRequestAction
        } = props;
        if (shouldTagAllPosts) {
            addRemovePostTagsFromPostByFilterRequestAction(postIds, selectedTagOptions);
        } else {
            addRemovePostTagsFromPostsRequestAction(postIds, selectedTagOptions);
        }
        // clear the cache
        serverDataClearAllCacheAction();
    };

    const handleOnCreateNewTag = (newTag) => {
        const { postTagAddRequestAction } = props;
        postTagAddRequestAction({ name: newTag });
    };

    const handleOnCancelClick = () => {
        hideModal();
    };

    return (
        <Modal dataViewName="add-or-remove-post-tags-from-posts-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog width={520}>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Add or remove tags
                    </Modal.Title>
                </Modal.Header>
                <div style={style}>
                    <InlineListWithSearchbar
                      options={options}
                      optionName="tag"
                      entityIds={postIds}
                      selectedOptionIds={selectedPostTagIds}
                      indeterminateOptionIds={indeterminatePostTagIds}
                      renderEmptyOptions={renderEmptyActiveTags}
                      applyAction={handleOnApplyClick}
                      createNewEntityAction={handleOnCreateNewTag}
                      cancelAction={handleOnCancelClick}
                      isApplyActionPending={isPending}
                      validateCreateNewOption={postTagNameLengthValidator}
                    />
                </div>
            </Modal.Dialog>
        </Modal>
    );
};

AddOrRemovePostTagsFromPostsModal.propTypes = {
    postIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    addRemovePostTagState: customPropTypes.asyncDataLoadingState.isRequired,
    postTagAddRequestAction: PropTypes.func.isRequired,
    postTags: customPropTypes.postTags.isRequired,
    selectedPostTagIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    indeterminatePostTagIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    hideModal: PropTypes.func.isRequired,
    hidePopover: PropTypes.func,
    enforceFocus: PropTypes.bool,
    shouldTagAllPosts: PropTypes.bool.isRequired,
    serverDataClearAllCacheAction: PropTypes.func.isRequired,
    addRemovePostTagsFromPostsRequestAction: PropTypes.func.isRequired,
    addRemovePostTagsFromPostByFilterRequestAction: PropTypes.func.isRequired,
    cleanupAddRemovePostTagsFromPostsStateAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectPostTagIdsByPostIdsWithIndeterminatedPostTags = makeSelectPostTagIdsByPostIdsWithIndeterminatedPostTags();
    const selectActivePostTags = makeSelectActivePostTags();
    return (state, ownProps) => {
        const { postIds } = ownProps;
        const postTags = selectActivePostTags(state);
        const shouldTagAllPosts = getShouldTagAllPosts(state);
        const { selectedPostTagIds, indeterminatePostTagIds } = selectPostTagIdsByPostIdsWithIndeterminatedPostTags(state, postIds, shouldTagAllPosts);
        return {
            postIds,
            selectedPostTagIds,
            indeterminatePostTagIds,
            postTags,
            shouldTagAllPosts,
            addRemovePostTagState: selectAddRemovePostTagsState(state)
        };
    };
};

export default connect(makeMapStateToProps, {
    serverDataClearAllCacheAction: serverDataClearAllCache,
    postTagAddRequestAction: postTagAddRequest,
    addRemovePostTagsFromPostsRequestAction: addRemovePostTagsFromPostsRequest,
    addRemovePostTagsFromPostByFilterRequestAction: addRemovePostTagsFromPostByFilterRequest
})(AddOrRemovePostTagsFromPostsModal);
