import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { getListFromStore } from 'src/selectors/lists';
import { getListNamePerUseCaseId } from 'src/selectors/useCases';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { selectCheckedAndUnCheckedUseCasesPerAccountByListNames } from 'src/selectors/utils';
import { selectIsBulkUseCaseUpdating } from 'src/selectors/accountUseCasesAsyncStates';
import UpdateAccountUseCasesTable from 'src/components/bulkUpdateAccountUseCases/UpdateAccountUseCasesTable';
import PrivateStatisticUseCasesInfo from 'src/components/modals/authTransactionSteps/PrivateStatisticUseCasesInfo';
import { arrangeAccountsByPlatformType } from 'src/utils/accountInsights';

const styles = {
    padding: '20px 20px 40px 20px'
};

const ActivateBulkAccountUseCasesModal = (props) => {
    const {
        hideModal,
        enforceFocus,
        listNamesPerUseCase,
        accounts,
        isUpdating,
        onSave
    } = props;

    const handleOnSaveClick = () => {
        const { checkedAndUncheckedUseCasesPerAccount } = props;
        onSave(checkedAndUncheckedUseCasesPerAccount);
    };
    const accountsByPlatformType = arrangeAccountsByPlatformType(accounts);
    return (
        <Modal dataViewName="activate-bulk-account-use-cases" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog width={720}>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Activate private statistics use cases</Modal.Title>
                </Modal.Header>
                <Modal.Body padded={false}>
                    <div style={styles}>
                        <UpdateAccountUseCasesTable
                          listNamesPerUseCase={listNamesPerUseCase}
                          accountsByPlatformType={accountsByPlatformType}
                          isUpdating={isUpdating}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer buttonsLeft={<PrivateStatisticUseCasesInfo />}>
                    <ButtonGroup>
                        <Button
                          label="Cancel"
                          onClick={hideModal}
                          disabled={isUpdating}
                        />
                        <Button
                          action
                          loading={isUpdating}
                          label="Save and close"
                          onClick={handleOnSaveClick}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

ActivateBulkAccountUseCasesModal.propTypes = {
    onSave: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    accounts: customPropTypes.accounts.isRequired,
    listNamesPerUseCase: PropTypes.object.isRequired,
    checkedAndUncheckedUseCasesPerAccount: PropTypes.object.isRequired,
    isUpdating: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => (state) => {
    const listNamesPerUseCase = getListNamePerUseCaseId(state);
    const allLists = getListFromStore(state);
    return {
        listNamesPerUseCase,
        checkedAndUncheckedUseCasesPerAccount: selectCheckedAndUnCheckedUseCasesPerAccountByListNames(allLists, listNamesPerUseCase),
        isUpdating: selectIsBulkUseCaseUpdating(state)
    };
};

export default connect(makeMapStateToProps)(ActivateBulkAccountUseCasesModal);
