import React, { PureComponent } from 'react';
import _omit from 'lodash/omit';
import PropTypes from 'prop-types';

const withCheckedValue = (WrappedComponent) => {
    class WithCheckedValue extends PureComponent {
        render() {
            const { value } = this.props;
            const transformedProps = _omit(this.props, 'value');
            transformedProps.checked = !!value;
            return (
                <WrappedComponent {...transformedProps} />
            );
        }
    }

    WithCheckedValue.propTypes = {
        value: PropTypes.bool.isRequired
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithCheckedValue.displayName = `withCheckedValue(${wrappedComponentName})`;
    return WithCheckedValue;
};

export default withCheckedValue;
