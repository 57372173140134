import React, { useEffect } from 'react';
import _omit from 'lodash/omit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { registerListItems, unregisterListItems } from 'src/actions/lists';
import { makeSelectUnCheckedValues } from 'src/selectors/lists';

const withAccountSearchListHandling = (WrappedComponent, listName) => {
    const WithProfileSearchListHandling = (props) => {
        const { accountIds } = props;
        useEffect(() => {
            // add the new accountIds, remove the non-selected ones (keep the selected ones)
            const { registerListItemsAction, unregisterListItemsAction, unCheckedValues } = props;
            unregisterListItemsAction(listName, unCheckedValues);
            registerListItemsAction(listName, accountIds, false);
        }, [accountIds]);

        const cleanedProps = _omit(props, [
            'accountIds',
            'unCheckedValues'
        ]);
        return <WrappedComponent {...cleanedProps} />;
    };

    WithProfileSearchListHandling.propTypes = {
        accountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        unCheckedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
        registerListItemsAction: PropTypes.func.isRequired,
        unregisterListItemsAction: PropTypes.func.isRequired
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithProfileSearchListHandling.displayName = `withProfileSearchListHandling(${wrappedComponentName})`;

    const makeMapStateToProps = () => {
        const selectUnCheckedValues = makeSelectUnCheckedValues();
        return (state) => ({
            unCheckedValues: selectUnCheckedValues(state, listName)
        });
    };

    return connect(makeMapStateToProps, {
        registerListItemsAction: registerListItems,
        unregisterListItemsAction: unregisterListItems
    })(WithProfileSearchListHandling);
};

export default withAccountSearchListHandling;
