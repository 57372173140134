import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import * as customPropTypes from 'src/customPropTypes';
import * as overlayActions from 'src/actions/overlays';
import AuthTransactionTimeInfo from 'src/components/authTransactions/AuthTransactionTimeInfo';
import BackLink from 'src/components/BackLink';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import EditableAuthTransactionName from 'src/components/authTransactions/EditableAuthTransactionName';
import { isAuthTransactionExpired } from 'src/utils/authTransactionUtils';
import { makeSelectAuthTransactionById, selectIsReopeningState } from 'src/selectors/authTransactions';
import Modal from 'src/components/modals/layout/Modal';
import NetworksAuthTransactionRequirementsTables from 'src/components/authTransactions/NetworksAuthTransactionsRequirementsTables';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/modals/authTransactionDetailModal.scss';
import * as authTransactionActions from 'src/actions/authTransactions';
import AuthTransactionLink from 'src/components/authTransactions/AuthTransactionLink';

const AuthTransactionDetailModal = (props) => {
    const {
        authTransaction,
        hideModal,
        enforceFocus,
        isReopening
    } = props;

    const {
        id,
        validUntil,
        hash,
        isOpen
    } = authTransaction;

    const handleOnBackClick = () => {
        const { modalsShowAuthTransactionsDetailAction } = props;
        modalsShowAuthTransactionsDetailAction();
        hideModal();
    };

    const handleOnAuthenticateClick = () => {
        const { modalsShowAddUpdateAuthUsersAction } = props;
        modalsShowAddUpdateAuthUsersAction(id);
        hideModal();
    };

    const handleAuthTransactionReopen = () => {
        const { authTransactionReopenRequestAction } = props;
        authTransactionReopenRequestAction(authTransaction.id);
    };

    const isExpired = isAuthTransactionExpired(validUntil);

    return (
        <Modal dataViewName="auth-transaction-detail" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog width={1000}>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        <div className={styles.header}>
                            <div className={styles.name}>
                                <EditableAuthTransactionName authTransaction={authTransaction} />
                            </div>
                            <div className={styles.authTransactionTimeState}>
                                <AuthTransactionTimeInfo authTransaction={authTransaction} />
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body height={476}>
                    <NetworksAuthTransactionRequirementsTables authTransaction={authTransaction} />
                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.footer}>
                        <div className={styles.backButtonWrapper}>
                            <div className={styles.backButton}>
                                <BackLink text="See all" onClick={handleOnBackClick} />
                            </div>
                        </div>
                        {
                            hash && (
                                <div className={styles.externalLink}>
                                    <AuthTransactionLink hash={hash} />
                                </div>
                            )
                        }
                        <ButtonGroup className={styles.actions}>
                            <Button onClick={hideModal} label="Close" />
                            {
                                (!isOpen || isExpired)
                                && (
                                    <Button
                                      action
                                      label="Reopen this authentication"
                                      loading={isReopening}
                                      onClick={handleAuthTransactionReopen}
                                    />
                                )
                            }
                            {
                                (isOpen && !isExpired)
                                && <Button action onClick={handleOnAuthenticateClick} label="Continue authentication" />
                            }
                        </ButtonGroup>
                    </div>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

AuthTransactionDetailModal.propTypes = {
    authTransaction: customPropTypes.authTransaction.isRequired,
    modalsShowAuthTransactionsDetailAction: PropTypes.func.isRequired,
    modalsShowAddUpdateAuthUsersAction: PropTypes.func.isRequired,
    authTransactionReopenRequestAction: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    isReopening: PropTypes.bool.isRequired,
    enforceFocus: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectAuthTransactionById = makeSelectAuthTransactionById();
    return (state, ownProps) => ({
        authTransaction: selectAuthTransactionById(state, ownProps.authTransactionId),
        isReopening: selectIsReopeningState(state, ownProps.authTransactionId)
    });
};

export default connect(makeMapStateToProps, {
    modalsShowAuthTransactionsDetailAction: overlayActions.modalsShowAuthTransactionsDetail,
    modalsShowAddUpdateAuthUsersAction: ensurePermission(overlayActions.modalsShowAddUpdateAuthUsers, writePermissions.addUpdateAuthUsers),
    authTransactionReopenRequestAction: authTransactionActions.authTransactionReopenRequest
})(AuthTransactionDetailModal);
