import * as notificationActions from 'src/actions/notifications';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React from 'react';
import _omit from 'lodash/omit';

const withCopyToClipboard = (WrappedComponent) => {
    const WithCopyToClipboard = (props) => {
        const handleOnCopyLink = () => {
            const { showNotificationAction, link } = props;
            copy(link);
            showNotificationAction('Copied to clipboard.', 'success', 1);
        };
        const cleanedProps = _omit(props, ['link', 'showNotificationAction']);
        return (
            <div onClick={handleOnCopyLink}>
                <WrappedComponent {...cleanedProps} />
            </div>
        );
    };

    WithCopyToClipboard.propTypes = {
        link: PropTypes.string.isRequired,
        showNotificationAction: PropTypes.func.isRequired
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithCopyToClipboard.displayName = `withCopyToClipboard(${wrappedComponentName})`;

    return connect(null, {
        showNotificationAction: notificationActions.showNotification
    })(WithCopyToClipboard);
};

export default withCopyToClipboard;
