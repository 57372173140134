import React from 'react';
import _range from 'lodash/range';
import ListTableCell from 'src/components/listTable/ListTableCell';
import PropTypes from 'prop-types';

const EmptyCells = ({
    emptyColumnLength, className, padded, isHeader, children
}) => _range(emptyColumnLength).map((i) => (
    <ListTableCell
      key={`empty_${i}`}
      className={className}
      padded={padded}
      isHeaderCell={isHeader}
      isHeaderViewLayout={false}
    >
        {children}
    </ListTableCell>
));

EmptyCells.propTypes = {
    emptyColumnLength: PropTypes.number.isRequired,
    className: PropTypes.string,
    padded: PropTypes.bool,
    isHeader: PropTypes.bool,
    children: PropTypes.node
};

EmptyCells.defaultProps = {
    padded: false,
    className: '',
    isHeader: false
};

export default EmptyCells;
