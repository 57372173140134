import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/buttons/editButton.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';
import classnames from 'classnames';

const EditButton = (props) => {
    const {
        onClick,
        onMouseOver,
        onMouseOut,
        className,
        disabled
    } = props;

    return (
        <div
          className={classnames(styles.wrapper, className, { [styles.disabled]: disabled })}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        >
            <IcomoonIcon
              icon="edit"
              className={classnames(styles.icon, { [styles.disabled]: disabled })}
            />
        </div>
    );
};

EditButton.propTypes = {
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    onClick: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool
};

EditButton.defaultProps = {
    onMouseOver: () => {},
    onMouseOut: () => {},
    onClick: () => {},
    className: '',
    disabled: false
};

export default EditButton;
