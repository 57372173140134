import Divider from 'src/components/Divider';
import IcomoonIcon from 'src/components/IcomoonIcon';
import withTooltip from 'src/components/withToolTip';
import React from 'react';
import styles from 'src/stylesheets/modal.scss';
import PropTypes from 'prop-types';

const IconWithTooltip = withTooltip(IcomoonIcon);

const DrawerHeader = ({ title, onHide }) => (
    <div className={styles.drawerHeader}>
        <div className={styles.titleWithClose}>
            <div className={styles.title}>
                {title}
            </div>
            { onHide
        && (
        <div className={styles.close} onClick={onHide}>
            <IconWithTooltip
              icon="close"
              className={styles.closeButtonIcon}
              tooltip="Close [esc]"
              tooltipPlacement="bottom"
              referenceWrapperClassName={styles.referenceWrapper}
            />
        </div>
        )}
        </div>

        <Divider sideMargin={0} />
    </div>
);

DrawerHeader.propTypes = {
    title: PropTypes.string.isRequired,
    onHide: PropTypes.func
};

export default DrawerHeader;
