import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/visualizationList.scss';
import { visualizationImages } from 'src/resources/visualization-images';

const VisualizationList = (props) => {
    const {
        options, selectedOptionId, preferredOptionId, preferredOptionSuffix, onChange, onBlur, onFocus
    } = props;

    return (
        <div>
            <ul className={styles.list}>
                {
                    options.map((option) => {
                        const { id, type, title } = option;
                        const selected = selectedOptionId === id;
                        const preferred = id === preferredOptionId;

                        return (
                            <li key={`image-select-option-${option.id}`} className={styles.listItem}>
                                <button
                                  type="button"
                                  onClick={() => { onChange(id); }}
                                  className={classnames(styles.item, { [styles.selected]: selected })}
                                  onFocus={onFocus}
                                  onBlur={() => { onBlur(id); }}
                                >
                                    <img src={visualizationImages[type]} className={styles.image} alt={title} />
                                    <span className={styles.label}>{title}{ preferred && ` (${preferredOptionSuffix})` }</span>
                                </button>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
};

VisualizationList.propTypes = {
    options: customPropTypes.VisualizationListOptions.isRequired,
    selectedOptionId: PropTypes.string,
    preferredOptionId: PropTypes.string,
    preferredOptionSuffix: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
};

export default VisualizationList;
