import { transformKeyWordOnlyValue } from 'src/utils/string';
import IcomoonIcon from 'src/components/IcomoonIcon';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/filterToken.scss';
import classnames from 'classnames';

const FilterToken = ({
    label, onClick, active, onDeleteClick, error, showTooltip
}) => {
    const { value, wordOnly } = transformKeyWordOnlyValue(label);

    let labelToRender = value;
    if (wordOnly) {
        labelToRender = <><span className={styles.prefix}>=</span>{value}</>;
    }

    return (
        <div className={classnames(styles.token)}>
            <div
              onClick={onClick}
              className={classnames(styles.subToken, styles.left, { [styles.active]: active, [styles.error]: error })}
            >
                {
                    showTooltip
                    && (
                        <DivWithTooltip
                          referenceWrapperClassName={styles.tooltipWrapper}
                          tooltip={labelToRender}
                          className={styles.label}
                        >
                            {labelToRender}
                        </DivWithTooltip>
                    )
                }
                {
                    !showTooltip
                    && (<div className={styles.label}>{labelToRender}</div>)
                }
            </div>
            <div
              className={classnames(styles.subToken, styles.right, { [styles.error]: error })}
              onClick={onDeleteClick}
            >
                <IcomoonIcon icon="clear" />
            </div>
        </div>
    );
};

FilterToken.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
    onDeleteClick: PropTypes.func.isRequired,
    error: PropTypes.bool,
    showTooltip: PropTypes.bool
};

FilterToken.defaultProps = {
    active: false,
    error: false,
    showTooltip: true
};

export default FilterToken;
