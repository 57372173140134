import FieldLabel from 'src/components/forms/fields/FieldLabel';
import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';

const CheckboxGroup = ({ label, children }) => (
    <>
        <FieldLabel htmlFor={label}>{label}</FieldLabel>
        {children}
    </>
);

CheckboxGroup.propTypes = {
    children: customPropTypes.children.isRequired,
    label: PropTypes.string.isRequired
};

export default CheckboxGroup;
