import _omit from 'lodash/omit';
import SingleAbsoluteSelectedDateLabel from 'src/components/dateSelector/SingleAbsoluteSelectedDateLabel';
import Button from 'src/components/buttons/Button';
import PropTypes from 'prop-types';
import React from 'react';

const SingleAbsoluteDateSelectorButton = (props) => {
    const { selectedDate, labelIfNull } = props;
    const cleanedProps = _omit(props, 'selectedDate');
    const label = <SingleAbsoluteSelectedDateLabel selectedDate={selectedDate} labelIfNull={labelIfNull} />;
    return <Button {...cleanedProps} label={label} icon="date-selector" />;
};

SingleAbsoluteDateSelectorButton.propTypes = {
    selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    small: PropTypes.bool,
    labelIfNull: PropTypes.string
};

SingleAbsoluteDateSelectorButton.defaultProps = {
    small: false,
    labelIfNull: ''
};

export default SingleAbsoluteDateSelectorButton;
