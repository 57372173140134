import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/dashboardList.scss';

const DashboardList = (props) => {
    const { dashboards, numberOfDashboards } = props;
    const dashboardLength = dashboards.length;
    return (
        <ul className={styles.wrapper}>
            {
                dashboards.map((dashboard, i) => (
                    <li key={`${dashboard.id}`} className={classnames({ [styles.ownAndSharedDashboards]: (i < dashboardLength - 1 || numberOfDashboards > 0) })}>
                        <IcomoonIcon className={styles.icon} icon="dashboard" />
                        <span className={styles.name}>{dashboard.name}</span>
                    </li>
                ))
            }
            {
                numberOfDashboards > 0
                && (
                <li>
                    <IcomoonIcon className={styles.icon} icon="lock" />
                    <span className={styles.name}>{numberOfDashboards} dashboards</span>
                </li>
                )
            }
        </ul>
    );
};

DashboardList.propTypes = {
    dashboards: customPropTypes.dashboards,
    numberOfDashboards: PropTypes.number
};

DashboardList.defaultProps = {
    dashboards: [],
    numberOfDashboards: 0
};

export default DashboardList;
