import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { standardScheduleNextRunDateRequest } from 'src/actions/dashboardReportSchedule';
import { connect } from 'react-redux';
import { makeSelectCronNextRunDate } from 'src/selectors/dashboardReportSchedule';
import NextRunDate from 'src/components/dashboardReportSchedule/NextRunDate';
import * as customPropTypes from 'src/customPropTypes';

const getHashedIdentifier = (interval, timezone, intervalDetailWeekly, intervalDetailMonthly, reportTime) => JSON.stringify({
    interval, timezone, intervalDetailWeekly, intervalDetailMonthly, reportTime
});

const StandardScheduleNextRunDate = (props) => {
    const {
        intervalType, timezone, intervalDetailWeekly, intervalDetailMonthly, reportTime, nextRunDateState
    } = props;

    useEffect(() => {
        const { standardScheduleNextRunDateRequestAction } = props;
        const identifier = getHashedIdentifier(intervalType, timezone, intervalDetailWeekly, intervalDetailMonthly, reportTime);
        if (nextRunDateState.requested === false) {
            standardScheduleNextRunDateRequestAction(intervalType, timezone, intervalDetailWeekly, intervalDetailMonthly, reportTime, identifier);
        }
    }, [intervalType, timezone, intervalDetailWeekly, intervalDetailMonthly, reportTime]);
    return <NextRunDate timezone={timezone} nextRunDateState={nextRunDateState} />;
};

StandardScheduleNextRunDate.propTypes = {
    intervalType: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    intervalDetailWeekly: PropTypes.string.isRequired,
    intervalDetailMonthly: PropTypes.string.isRequired,
    reportTime: PropTypes.string.isRequired,
    nextRunDateState: customPropTypes.nextRunTimeState.isRequired,
    standardScheduleNextRunDateRequestAction: PropTypes.func.isRequired,
};

const makeMapStateToProps = () => {
    const selectCronNextRunDate = makeSelectCronNextRunDate();
    return (state, ownProps) => {
        const {
            intervalType, timezone, intervalDetailWeekly, intervalDetailMonthly, reportTime
        } = ownProps;
        const identifier = getHashedIdentifier(intervalType, timezone, intervalDetailWeekly, intervalDetailMonthly, reportTime);
        return { nextRunDateState: selectCronNextRunDate(state, identifier) };
    };
};

export default connect(makeMapStateToProps, {
    standardScheduleNextRunDateRequestAction: standardScheduleNextRunDateRequest
})(StandardScheduleNextRunDate);
