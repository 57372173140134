import * as customPropTypes from 'src/customPropTypes';
import AuthTransactionRequirementsFormRow from 'src/components/forms/authTransaction/AuthTransactionRequirementsFormRow';
import { connect } from 'react-redux';
import PlatformUseCasesTableHeader from 'src/components/views/authentications/table/PlatformUseCasesTableHeader';
import PropTypes from 'prop-types';
import React from 'react';
import { selectUseCasesByPlatform } from 'src/selectors/useCases';
import ListTable from 'src/components/listTable/ListTable';

const AuthTransactionRequirementsFormTable = (props) => {
    const {
        platformUseCases,
        profiles,
        adAccounts,
        platformType,
        disabled
    } = props;

    return (
        <ListTable header={(
            <PlatformUseCasesTableHeader
              platformUseCases={platformUseCases}
              showUseCaseDetail
              platformType={platformType}
            />
        )}
        >
            {
                profiles.map((profile) => (
                    <AuthTransactionRequirementsFormRow
                      key={profile.id}
                      account={profile}
                      accountType="profiles"
                      platformUseCases={platformUseCases}
                      disabled={disabled}
                    />
                ))
            }
            {
                adAccounts.map((adAccount) => (
                    <AuthTransactionRequirementsFormRow
                      key={adAccount.id}
                      account={adAccount}
                      accountType="adAccounts"
                      platformUseCases={platformUseCases}
                      disabled={disabled}
                    />
                ))
            }
        </ListTable>
    );
};

AuthTransactionRequirementsFormTable.propTypes = {
    profiles: customPropTypes.profiles.isRequired,
    adAccounts: customPropTypes.adAccounts.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired,
    platformType: customPropTypes.platformTypes.isRequired,
    disabled: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => (state, ownProps) => {
    const { platformType } = ownProps;
    return {
        platformUseCases: selectUseCasesByPlatform(state, platformType)
    };
};

export default connect(makeMapStateToProps)(AuthTransactionRequirementsFormTable);
