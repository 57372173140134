import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getSocialNetworkAuthenticatedSearch } from 'src/selectors/adAccountSearch';
import AdAccountSearchResultList from 'src/components/views/adAccounts/search/AdAccountSearchResultList';
import { listName } from 'src/utils/adAccountSearch';
import withAccountSearchListHandling from 'src/components/bulkList/withAccountSearchListHandling';
import { socialNetworkAdAccountShowListRequest, socialNetworkAdAccountSearchReset } from 'src/actions/adAccountSearch';
import AuthenticatedSearchAccountResult from 'src/components/AuthenticatedSearchAccountResult';

const AdAccountSearchResultListWithProfileSearchListHandling = withAccountSearchListHandling(AdAccountSearchResultList, listName);

const renderResultList = (data) => <AdAccountSearchResultListWithProfileSearchListHandling accountIds={data} searchedAdAccountIds={data} />;

const AuthenticatedSearchAdAccountResult = (props) => {
    const {
        result,
        socialNetworkAdAccountSearchResetAction,
        socialNetworkAdAccountShowListRequestAction
    } = props;
    return (
        <AuthenticatedSearchAccountResult
          result={result}
          tryAgainAction={socialNetworkAdAccountShowListRequestAction}
          renderResultList={renderResultList}
          onBackClick={socialNetworkAdAccountSearchResetAction}
        />
    );
};

AuthenticatedSearchAdAccountResult.propTypes = {
    socialNetworkAdAccountShowListRequestAction: PropTypes.func.isRequired,
    socialNetworkAdAccountSearchResetAction: PropTypes.func.isRequired,
    result: customPropTypes.accountSearchResult.isRequired
};

const mapStateToProps = (state) => ({
    result: getSocialNetworkAuthenticatedSearch(state)
});

export default connect(mapStateToProps, {
    socialNetworkAdAccountShowListRequestAction: socialNetworkAdAccountShowListRequest,
    socialNetworkAdAccountSearchResetAction: socialNetworkAdAccountSearchReset
})(AuthenticatedSearchAdAccountResult);
