import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import ReportOverviewIcon from 'src/components/reportOverview/ReportOverviewIcon';
import styles from 'src/stylesheets/reportOverview/reportOverviewInfo.scss';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';

const ReportOverviewInfo = (props) => {
    const { reportOverview } = props;
    const { id, name } = reportOverview;
    return (
        <div className={styles.wrapper}>
            <ReportOverviewIcon reportOverviewId={id} className={styles.icon} />
            <div className={styles.name}>
                <DivWithTooltip tooltip={name}>
                    {name}
                </DivWithTooltip>
            </div>
        </div>
    );
};

ReportOverviewInfo.propTypes = {
    reportOverview: customPropTypes.reportOverview.isRequired
};

export default ReportOverviewInfo;
