import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { updateTiktokPlatformUsernameRequest } from 'src/actions/profiles';
import { makeSelectIsTiktokPlatformUsernameUpdating } from 'src/selectors/profiles';

const UpdateInstagramUsernameButton = (props) => {
    const {
        profile,
        searchedProfile,
        loading,
        updateTiktokPlatformUsernameRequestAction
    } = props;

    const onClick = () => {
        updateTiktokPlatformUsernameRequestAction(profile.id, searchedProfile.platformUsername);
    };

    return (
        <Button disabled={loading} small label="Update username" onClick={onClick} />
    );
};

UpdateInstagramUsernameButton.propTypes = {
    profile: customPropTypes.profile.isRequired,
    searchedProfile: customPropTypes.searchedProfile.isRequired,
    updateTiktokPlatformUsernameRequestAction: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

const makeMapStateToProps = () => {
    const selectIsTiktokPlatformUsernameUpdating = makeSelectIsTiktokPlatformUsernameUpdating();
    return (state, ownProps) => {
        const { profile } = ownProps;
        const loading = selectIsTiktokPlatformUsernameUpdating(state, profile.id);
        return { loading };
    };
};

export default connect(makeMapStateToProps, {
    updateTiktokPlatformUsernameRequestAction: updateTiktokPlatformUsernameRequest
})(UpdateInstagramUsernameButton);
