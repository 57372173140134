import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import GroupListItemContainer from 'src/components/views/profiles/GroupListItemContainer';
import { makeSelectUserCreatedGroupsIds } from 'src/selectors/groups';
import Modal from 'src/components/modals/layout/Modal';
import ModalListAddButton from 'src/components/ModalListAddButton';
import { modalsShowGroupCreate } from 'src/actions/overlays';
import noGroups from 'src/resources/illustrations/no-groups.png';
import PlainList from 'src/components/PlainList';
import PropTypes from 'prop-types';
import React from 'react';

const GroupEditModal = (props) => {
    const {
        hideModal, userCreatedGroupIds, enforceFocus, modalsShowGroupCreateAction
    } = props;

    return (
        <Modal dataViewName="edit-group" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog width={520}>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Manage groups</Modal.Title>
                </Modal.Header>
                <Modal.Body
                  maxHeight={400}
                  stickyFooter={<ModalListAddButton label="New group" onClick={modalsShowGroupCreateAction} />}
                >
                    {
                        userCreatedGroupIds.length > 0
                        && (
                        <PlainList>
                            {
                                userCreatedGroupIds.map((groupId) => (
                                    <GroupListItemContainer
                                      key={`group-edit-${groupId}`}
                                      groupId={groupId}
                                    />
                                ))
                            }
                        </PlainList>
                        )
                    }
                    {
                        userCreatedGroupIds.length === 0
                        && <EmptyInfoScreen message="You don't have any groups yet." image={noGroups} />
                    }
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button action onClick={hideModal} label="Close" />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

GroupEditModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    userCreatedGroupIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    modalsShowGroupCreateAction: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool
};

const makeMapStateToProps = () => {
    const selectUserCreatedGroupsIds = makeSelectUserCreatedGroupsIds();
    return (state) => ({
        userCreatedGroupIds: selectUserCreatedGroupsIds(state)
    });
};

export default connect(makeMapStateToProps, {
    modalsShowGroupCreateAction: modalsShowGroupCreate
})(GroupEditModal);
