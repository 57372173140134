import { isSubmitting, submit } from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';
import { createUserRequestFormAction } from 'src/actions/users';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { makeSelectAdminSettingsState } from 'src/selectors/adminSettings';
import { selectDefaultTimezoneFromAccountSettings } from 'src/selectors/loggedInUser';
import { selectLoggedInSpace, selectSpaces } from 'src/selectors/spaces';
import { makeSelectUserEditInitialFormValues } from 'src/selectors/users';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import UserInviteForm from 'src/components/forms/UserInviteForm';

const formName = 'inviteUserForm';

const UserCreateModal = (props) => {
    const {
        hideModal, initialValues, isLoading, submitAction, spaces, enforceFocus, loadingState
    } = props;

    const { isPending, error } = loadingState;

    return (
        <Modal dataViewName="create-user" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Invite a new user</Modal.Title>
                </Modal.Header>
                <Modal.Body centered>
                    {!isPending && !error
                        && (
                        <UserInviteForm
                          form={formName}
                          onSubmit={createUserRequestFormAction}
                          initialValues={initialValues}
                          spaces={spaces}
                        />
                        )}
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          disabled={isLoading}
                          onClick={hideModal}
                          type="button"
                          label="Cancel"
                        />
                        <Button
                          action
                          loading={isLoading}
                          onClick={() => submitAction(formName)}
                          disabled={isPending || !!error}
                          label="Send invite"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

UserCreateModal.propTypes = {
    spaces: customPropTypes.spacesForManagement.isRequired,
    submitAction: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    initialValues: PropTypes.object.isRequired,
    enforceFocus: PropTypes.bool,
    loadingState: customPropTypes.asyncDataLoadingState.isRequired
};

const makeMapStateToProps = () => {
    const selectUserCreateInitialFormValues = makeSelectUserEditInitialFormValues();
    const selectAdminSettingsState = makeSelectAdminSettingsState();

    return (state) => {
        const spaces = selectSpaces(state);
        const defaultTimezone = selectDefaultTimezoneFromAccountSettings(state);
        const loggedInSpace = selectLoggedInSpace(state);
        return {
            isLoading: isSubmitting(formName)(state),
            initialValues: selectUserCreateInitialFormValues(false, spaces, defaultTimezone, loggedInSpace),
            loadingState: selectAdminSettingsState(state),
            spaces
        };
    };
};

export default connect(makeMapStateToProps, {
    submitAction: submit,
})(UserCreateModal);
