import React, { useEffect, useState, createRef } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import _orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inlineList/inlinelistWithSearchbar.scss';
import TextWithResetIcon from 'src/components/forms/inputs/TextWithResetIcon';
import memoizeOne from 'memoize-one';
import classnames from 'classnames';
import _includes from 'lodash/includes';
import { getCreatableOptionOrNull } from 'src/utils/string';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import FilteredOptionList from 'src/components/inlineList/FilteredOptionList';
import SelectedOptionList from 'src/components/inlineList/SelectedOptionList';
import _min from 'lodash/min';
import _max from 'lodash/max';
import CreatableOptionContainer from 'src/components/inlineList/CreatableOptionContainer';

const generateOptionsWithIntermediateFlag = memoizeOne((options, indeterminateOptionIds) => options.map((option) => ({ option, isIndeterminate: _includes(indeterminateOptionIds, option.id) })));

const UP = 38;
const DOWN = 40;
const RETURN = 13;

const filterSelectedOptions = memoizeOne((options, indeterminateOptionIds, optionIds) => {
    const optionsWithIntermediateFlag = generateOptionsWithIntermediateFlag(options, indeterminateOptionIds);
    return optionsWithIntermediateFlag.filter(({ option, isIndeterminate }) => _includes(optionIds, option.id) || isIndeterminate === true);
});

const filterNonSelectedOptionsWithSearchQueryApplied = memoizeOne((options, currentSelectedOptions, searchQuery) => {
    const currentOptionIds = currentSelectedOptions.map(({ option }) => option.id);
    const nonSelectedOptions = options.filter((option) => !_includes(currentOptionIds, option.id));

    let filteredOptions = _orderBy(nonSelectedOptions, [(item) => item.label.toLowerCase()], ['asc']);
    if (searchQuery.length > 0) {
        const searchQueryToLower = searchQuery.toLowerCase().trim();
        filteredOptions = filteredOptions.filter((group) => group.label.toLowerCase()
            .includes(searchQueryToLower));
    }

    return filteredOptions;
});

const InlineListWithSearchbar = (props) => {
    const {
        options,
        optionName,
        selectedOptionIds,
        indeterminateOptionIds,
        renderEmptyOptions,
        applyAction,
        createNewEntityAction,
        cancelAction,
        isApplyActionPending,
        validateCreateNewOption,
        entityIds
    } = props;
    const searchInputRef = createRef();
    const headerAndBodyRef = createRef();

    const [currentSelectedOptions, setCurrentSelectedOptions] = useState(filterSelectedOptions(options, indeterminateOptionIds, selectedOptionIds));
    const [activeIndex, setActiveIndex] = useState(-1);
    const [searchQuery, setSearchQuery] = useState('');
    const [optionToCheck, setOptionToCheck] = useState(null);

    const filteredOptions = filterNonSelectedOptionsWithSearchQueryApplied(options, currentSelectedOptions, searchQuery);
    const creatableOption = getCreatableOptionOrNull(searchQuery, options, validateCreateNewOption);

    const resetActiveListIndex = () => {
        setActiveIndex(-1);
        headerAndBodyRef.current.scrollTop = 0;
    };

    const updateSearchKeyword = (keyword) => {
        setSearchQuery(keyword);
        resetActiveListIndex();
    };

    useEffect(() => {
        if (optionToCheck !== null) {
            const option = options.find((group) => (group.label.toLowerCase() === optionToCheck));
            if (option !== undefined) {
                setCurrentSelectedOptions([...currentSelectedOptions, { option, isIndeterminate: false }]);
                setOptionToCheck(null);
                updateSearchKeyword('');
            }
        }
    }, [options, optionToCheck]);

    const focusSearchInput = () => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    };

    const handleOnNewOptionClick = () => {
        const { optionToCreate, validationError } = creatableOption;
        if (validationError === undefined && optionToCreate !== '') {
            setOptionToCheck(optionToCreate);
            createNewEntityAction(optionToCreate);
            focusSearchInput();
        }
    };

    const handleOnOptionClickWithResetBehavior = (option) => {
        setCurrentSelectedOptions([...currentSelectedOptions, { option, isIndeterminate: false }]);
        if (searchQuery.length > 0) {
            updateSearchKeyword('');
        }
        resetActiveListIndex();
        focusSearchInput();
    };

    const handleOnEnter = () => {
        if (filteredOptions[activeIndex]) {
            handleOnOptionClickWithResetBehavior(filteredOptions[activeIndex]);
        } else if (creatableOption !== null && activeIndex === filteredOptions.length) {
            handleOnNewOptionClick();
        }
        resetActiveListIndex();
    };

    const handleKeyDown = (e) => {
        let filteredOptionLengthWithNewOption = filteredOptions.length - 1;
        if (creatableOption !== null) {
            filteredOptionLengthWithNewOption += 1;
        }
        switch (e.keyCode) {
            case DOWN:
                setActiveIndex(_min([activeIndex + 1, filteredOptionLengthWithNewOption]));
                e.preventDefault();
                return true;
            case UP:
                setActiveIndex(_max([activeIndex - 1, 0]));
                e.preventDefault();
                return true;
            case RETURN:
                handleOnEnter();
                e.preventDefault();
                return true;
            default:
                return false;
        }
    };

    const handleOnCloseClick = (currentOption) => {
        setCurrentSelectedOptions(currentSelectedOptions.filter(({ option }) => option.id !== currentOption.id));
        focusSearchInput();
    };

    const handleOnIndeterminateClick = (currentOption) => {
        const tempSelection = currentSelectedOptions.slice(0);
        const index = tempSelection.findIndex(({ option }) => option.id === currentOption.id);
        if (index !== -1) {
            tempSelection[index].isIndeterminate = false;
        }
        setCurrentSelectedOptions(tempSelection);
        focusSearchInput();
    };

    const onSearchQueryChange = (event) => {
        updateSearchKeyword(event.target.value);
    };

    const onSearchQueryReset = () => {
        updateSearchKeyword('');
    };

    const handleOnApplyAction = () => {
        applyAction(currentSelectedOptions);
    };

    const handleOnCancelClick = () => {
        cancelAction();
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.searchInput}>
                <TextWithResetIcon
                  value={searchQuery}
                  autoFocus
                  placeholder={`Search or create new ${optionName}`}
                  onChange={onSearchQueryChange}
                  onResetClick={onSearchQueryReset}
                  onKeyDown={handleKeyDown}
                  layout="profileSearch"
                  ref={searchInputRef}
                  disabled={isApplyActionPending}
                />
            </div>
            <div className={styles.headerAndBody} ref={headerAndBodyRef}>
                <div className={classnames(styles.header, { [styles.disabled]: isApplyActionPending })}>
                    {
                        (options.length > 0 && currentSelectedOptions.length === 0) && (
                            <div className={styles.nonSelectedOptions}>
                                {`No ${optionName}s assigned yet, add one.`}
                            </div>
                        )
                    }
                    <SelectedOptionList
                      selectedOptions={currentSelectedOptions}
                      onCloseClick={handleOnCloseClick}
                      onIndeterminateClick={handleOnIndeterminateClick}
                      isBulkSelection={entityIds.length > 1}
                    />
                </div>
                <div className={classnames(styles.body, { [styles.disabled]: isApplyActionPending })}>
                    {
                        (searchQuery === '' && options.length === 0) && (
                            <div className={styles.empty}>
                                {renderEmptyOptions()}
                            </div>
                        )
                    }
                    <div className={styles.filteredList}>
                        <FilteredOptionList
                          filteredOptions={filteredOptions}
                          onOptionClick={handleOnOptionClickWithResetBehavior}
                          activeIndex={activeIndex}
                        />
                        {
                            creatableOption !== null && (
                                <CreatableOptionContainer
                                  creatableOption={creatableOption}
                                  onNewOptionClick={handleOnNewOptionClick}
                                  optionName={optionName}
                                  activeIndex={activeIndex}
                                  numberOfFilteredOptions={filteredOptions.length}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <ButtonGroup>
                    {
                        cancelAction && (
                            <Button
                              label="Cancel"
                              onClick={handleOnCancelClick}
                              disabled={isApplyActionPending}
                            />
                        )
                    }
                    <Button
                      label="Apply"
                      onClick={handleOnApplyAction}
                      action
                      loading={isApplyActionPending}
                    />
                </ButtonGroup>

            </div>
        </div>
    );
};

InlineListWithSearchbar.propTypes = {
    selectedOptionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    indeterminateOptionIds: PropTypes.arrayOf(PropTypes.string),
    optionName: PropTypes.string.isRequired,
    options: customPropTypes.selectListOptions.isRequired,
    createNewEntityAction: PropTypes.func.isRequired,
    renderEmptyOptions: PropTypes.func.isRequired,
    applyAction: PropTypes.func.isRequired,
    isApplyActionPending: PropTypes.bool.isRequired,
    cancelAction: PropTypes.func,
    validateCreateNewOption: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
    entityIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

InlineListWithSearchbar.defaultProps = {
    indeterminateOptionIds: []
};

export default InlineListWithSearchbar;
