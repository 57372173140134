import React, { useState } from 'react';
import { connect } from 'react-redux';
import DashboardSearchableList from 'src/components/views/dashboards/DashboardSearchableList';
import Modal from 'src/components/modals/layout/Modal';
import ModalListAddButton from 'src/components/ModalListAddButton';
import { modalsShowCreateDashboard } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import { SUPER_ROOT } from 'src/utils/dashboardFolders';

const DashboardSearchableListModal = (props) => {
    const [currentFolderId, setCurrentFolder] = useState(SUPER_ROOT);
    const [searchQuery, setSearchQuery] = useState('');

    const handleOnDashboardClick = (dashboardId) => {
        const { onDashboardClick, hideModal } = props;
        onDashboardClick(dashboardId);
        hideModal();
    };

    const handleOnFolderClick = (folderId) => {
        setCurrentFolder(folderId);
    };

    const onCreateDashboardButtonClick = () => {
        const { modalsShowCreateDashboardAction } = props;
        modalsShowCreateDashboardAction(currentFolderId, false);
    };

    const handleSearchQuery = (query) => {
        setSearchQuery(query);
    };

    const CreateDashboardButton = currentFolderId !== SUPER_ROOT && searchQuery.length < 1 ? (
        <ModalListAddButton
          big
          label="Add new dashboard"
          onClick={onCreateDashboardButtonClick}
        />
    ) : <div style={{ height: 68 }} />;

    const {
        enforceFocus,
        hideModal,
        disabledDashboardIds
    } = props;

    return (
        <Modal dataViewName="add-metric-to-dashboard" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog width={620}>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Select a dashboard</Modal.Title>
                </Modal.Header>
                <Modal.Body
                  height={526}
                  stickyFooter={CreateDashboardButton}
                >
                    <DashboardSearchableList
                      initialFolderId={currentFolderId}
                      onDashboardClick={handleOnDashboardClick}
                      onFolderClick={handleOnFolderClick}
                      searchQuery={searchQuery}
                      setSearchQuery={handleSearchQuery}
                      disabledDashboardIds={disabledDashboardIds}
                    />
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    );
};

DashboardSearchableListModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    modalsShowCreateDashboardAction: PropTypes.func.isRequired,
    onDashboardClick: PropTypes.func.isRequired,
    disabledDashboardIds: PropTypes.arrayOf(PropTypes.string)
};

DashboardSearchableListModal.defaultProps = {
    disabledDashboardIds: []
};

export default connect(null, {
    modalsShowCreateDashboardAction: modalsShowCreateDashboard
})(DashboardSearchableListModal);
