import * as customPropTypes from 'src/customPropTypes';
import UpdateAccountsUseCasesRow from 'src/components/bulkUpdateAccountUseCases/UpdateAccountsUseCasesRow';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styles from 'src/stylesheets/updateProfilesUseCasesTable.scss';
import AccountTypeContext from 'src/components/context/AccountTypeContext';
import _lowercase from 'lodash/lowerCase';
import _upperFirst from 'lodash/upperFirst';

const UpdateAccountUseCasesTable = (props) => {
    const {
        accountsByPlatformType, listNamesPerUseCase, isUpdating
    } = props;
    const { accountType } = useContext(AccountTypeContext);
    const accountName = _lowercase(accountType);
    return (
        <div>
            <div className={styles.title}>
                {`When activating private statistics use cases for multiple ${accountName}, please be aware that any ${accountName} lacking the required permissions will display connection issues in your ${_upperFirst(accountName)} list. You can easily resolve these issues individually in the Authentications section later.`}
            </div>
            <table className={styles.table}>
                <tbody>
                    {
                    Object.keys(accountsByPlatformType).map((platformType) => {
                        const accounts = accountsByPlatformType[platformType];
                        if (accounts.length > 0) {
                            return (
                                <UpdateAccountsUseCasesRow
                                  accounts={accounts}
                                  platformType={platformType}
                                  listNamesPerUseCase={listNamesPerUseCase}
                                  isUpdating={isUpdating}
                                  key={platformType}
                                />
                            );
                        }
                        return null;
                    })
                }
                </tbody>
            </table>
        </div>
    );
};

UpdateAccountUseCasesTable.propTypes = {
    accountsByPlatformType: PropTypes.oneOfType([customPropTypes.profilesByPlatformType, customPropTypes.adAccountsByPlatformType]).isRequired,
    listNamesPerUseCase: PropTypes.object.isRequired,
    isUpdating: PropTypes.bool.isRequired
};

export default UpdateAccountUseCasesTable;
