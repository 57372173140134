import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import IcomoonIcon from 'src/components/IcomoonIcon';
import { isAuthTransactionOpen } from 'src/utils/authTransactionUtils';
import ProfileAuthTransactionConnectErrorPopover from 'src/components/popovers/ProfileAuthTransactionConnectErrorPopover';
import PropTypes from 'prop-types';
import React from 'react';
import { selectAuthenticateByIdentifier } from 'src/selectors/authUsers';
import styles from 'src/stylesheets/networkAuthUserConnectButton.scss';
import withPopover from 'src/components/withPopover';
import NetworkButton from 'src/components/buttons/NetworkButton';

const IcomoonIconIconWithPopover = withPopover(IcomoonIcon);

const SnapchatConnectButtonAndState = (props) => {
    const {
        authTransaction,
        connectAction,
        authenticateState
    } = props;

    const error = authenticateState ? authenticateState.error : '';
    const isPending = authenticateState ? authenticateState.isPending : false;

    return (
        <div className={styles.wrapper}>
            {
                (error && !isPending)
                && (
                <div className={styles.error}>
                    <IcomoonIconIconWithPopover
                      overlay={<ProfileAuthTransactionConnectErrorPopover errorMessage={error} />}
                      icon="alert"
                      placement="top"
                      trigger="hover"
                      className={styles.errorIcon}
                    />
                </div>
                )
            }
            <div className={styles.button}>
                <NetworkButton
                  platformType="snapchatShow"
                  label="Continue with Snapchat"
                  small
                  disabled={!(isAuthTransactionOpen(authTransaction.isOpen, authTransaction.validUntil))}
                  loading={isPending}
                  onClick={() => { connectAction(`sc_${authTransaction.id}`); }}
                />
            </div>
        </div>
    );
};

SnapchatConnectButtonAndState.propTypes = {
    authTransaction: customPropTypes.authTransaction.isRequired,
    connectAction: PropTypes.func.isRequired,
    authenticateState: PropTypes.oneOfType([PropTypes.bool, customPropTypes.asyncReducerState]).isRequired,
};

const makeMapStateToProps = () => (state, ownProps) => {
    const { authTransaction } = ownProps;
    return {
        authenticateState: selectAuthenticateByIdentifier(state, `sc_${authTransaction.id}`),
    };
};

export default connect(makeMapStateToProps)(SnapchatConnectButtonAndState);
