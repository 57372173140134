import React from 'react';
import styles from 'src/stylesheets/postContent.scss';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import classnames from 'classnames';
import PostTextHighlighter from 'src/components/views/postTags/postTagging/PostTextHighlighter';
import PropTypes from 'prop-types';

const renderContent = (content, highlightKeywords) => (highlightKeywords.length > 0 ? <PostTextHighlighter phrase={content} values={highlightKeywords} /> : <div>{content}</div>);

const PostContent = (props) => {
    const { items, highlightKeywords } = props;

    const primaryContent = _get(items, '0', false);
    const additionalContents = items.slice(1);

    const itemCountWithNotEmptyContent = items.filter((item) => item.content !== '').length;

    return (
        <div className={styles.wrapper}>
            {
                itemCountWithNotEmptyContent === 0 && (
                    <div className={styles.noContent}>
                        This post contains no text
                    </div>
                )
            }
            {
                primaryContent && primaryContent.content !== '' && (
                    <div className={classnames(styles.primaryContent, styles.content)}>
                        {renderContent(primaryContent.content, highlightKeywords)}
                    </div>
                )
            }
            {
                additionalContents.length > 0 && additionalContents.filter((contentItem) => contentItem.content !== '').map((contentItem) => (
                    <div className={classnames(styles.additionalContent, styles.content)} key={`post-content-${contentItem.id}`}>
                        {renderContent(contentItem.content, highlightKeywords)}
                    </div>
                ))
            }
        </div>
    );
};

PostContent.propTypes = {
    items: customPropTypes.postContentItems.isRequired,
    highlightKeywords: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default PostContent;
