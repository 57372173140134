import EntityOwnershipNameResolver from 'src/components/onwership/EntityOwnershipNameResolver';
import { isSubmitting, submit } from 'redux-form';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import FolderAddForm from 'src/components/forms/FolderAddForm';
import { folderAddRequestFormAction } from 'src/actions/folders';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import { selectFolderTypeById } from 'src/selectors/ownership';

const formName = 'addFolderForm';

const FolderAddModal = (props) => {
    const {
        hideModal, submitAction, submitting, enforceFocus, folderId, context, folderType
    } = props;
    const initialFolderValues = {
        folderName: '',
        parentId: folderId,
        context,
        parentFolderType: folderType
    };
    return (
        <Modal dataViewName="add-folder" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Create new <EntityOwnershipNameResolver entityId={folderId} entityType="folder" /> folder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FolderAddForm
                      form={formName}
                      initialValues={initialFolderValues}
                      onSubmit={folderAddRequestFormAction}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button disabled={submitting} onClick={hideModal} label="Cancel" />
                        <Button action loading={submitting} onClick={() => submitAction(formName)} label="Add" />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

FolderAddModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    enforceFocus: PropTypes.bool,
    folderId: PropTypes.string.isRequired,
    context: PropTypes.string.isRequired,
    folderType: customPropTypes.ownershipType.isRequired
};

FolderAddModal.defaultProps = {
    submitting: false,
    enforceFocus: true,
};

const mapStateToProps = (state, ownProps) => ({
    submitting: isSubmitting(formName)(state),
    folderType: selectFolderTypeById(state, ownProps.folderId)
});

export default connect(mapStateToProps, {
    submitAction: submit,
})(FolderAddModal);
