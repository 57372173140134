import Date from 'src/components/Date';
import _upperCase from 'lodash/upperCase';
import React from 'react';
import styles from 'src/stylesheets/views/postTags/postInfo.scss';
import * as customPropTypes from 'src/customPropTypes';

const PostInfo = ({ post }) => {
    const { timezone, time, postTypeName } = post;

    return (
        <div className={styles.postInfo}>
            <Date format="datetime" appendTimezone dateInTimezone={timezone} dateToTimezone={timezone} date={time} />
            <div>{_upperCase(postTypeName)}</div>
        </div>
    );
};

PostInfo.propTypes = {
    post: customPropTypes.post.isRequired
};

export default PostInfo;
