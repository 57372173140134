import { makeRangeValidator, required } from 'src/components/forms/validators';
import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import EditableContent from 'src/components/EditableContent';
import IconButton from 'src/components/buttons/IconButton';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/groupListItem.scss';
import withTooltip from 'src/components/withToolTip';

const IconButtonWithTooltip = withTooltip(IconButton);

const rangeValidator = makeRangeValidator(2, 25);

class GroupListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: false
        };
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }

    handleEditClick() {
        this.setState({ editMode: true });
    }

    handleOnChange(newValue) {
        const { groupEditRequestAction, group } = this.props;
        groupEditRequestAction(group.id, newValue);
    }

    handleLeave() {
        this.setState({ editMode: false });
    }

    render() {
        const { modalsShowGroupDeleteAction, group, isGroupEditing } = this.props;
        const { editMode } = this.state;

        return (
            <div className={styles.groupListItem}>
                <div className={styles.groupName}>
                    <EditableContent
                      validate={[required, rangeValidator]}
                      onDoubleClick={this.handleEditClick}
                      value={group.name}
                      onChange={this.handleOnChange}
                      onLeave={this.handleLeave}
                      editMode={editMode}
                      loading={isGroupEditing}
                    >
                        <span className={styles.listStyle}>{group.name}</span>
                    </EditableContent>
                </div>
                {
                    (!editMode && !isGroupEditing) && (
                        <ButtonGroup className={styles.buttons}>

                            <IconButtonWithTooltip
                              icon="edit"
                              onClick={this.handleEditClick}
                              tooltip="Rename"
                            />
                            <IconButtonWithTooltip
                              icon="delete"
                              tooltip="Delete"
                              onClick={() => {
                                  modalsShowGroupDeleteAction(group.id);
                              }}
                              className={styles.delete}
                            />
                        </ButtonGroup>
                    )
                }
            </div>
        );
    }
}

GroupListItem.propTypes = {
    group: customPropTypes.group.isRequired,
    isGroupEditing: PropTypes.bool,
    modalsShowGroupDeleteAction: PropTypes.func.isRequired,
    groupEditRequestAction: PropTypes.func.isRequired
};

GroupListItem.defaultProps = {
    isGroupEditing: true
};

export default GroupListItem;
