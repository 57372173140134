import _includes from 'lodash/includes';
import { connect } from 'react-redux';
import DashboardInModalListItemContainer from 'src/components/views/dashboards/DashboardInModalListItemContainer';
import emptyFolder from 'src/resources/illustrations/empty-folder.png';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import FolderInModalListItemContainer from 'src/components/views/folders/FolderInModalListItemContainer';
import List from 'src/components/List';
import { makeSelectFolderIdsByParentIdAndType, selectFolderTypeById, makeSelectDashboardIdsByFolderIdAndType } from 'src/selectors/ownership';
import ModalListItem from 'src/components/views/folders/ModalListItem';
import PropTypes from 'prop-types';
import React from 'react';

const FolderList = (props) => {
    const {
        childFolderIds,
        childDashboardIds,
        movingFolderIds,
        onFolderClick,
        disableDashboards,
        disabledDashboardIds,
        onDashboardClick,
        emptyInfoImage,
        emptyInfoMessage
    } = props;

    const folders = childFolderIds.map((folderId) => (
        <ModalListItem
          onClick={() => { onFolderClick(folderId); }}
          key={`folder-${folderId}`}
          disabled={_includes(movingFolderIds, folderId)}
        >
            <FolderInModalListItemContainer
              folderId={folderId}
            />
        </ModalListItem>
    ));

    const dashboards = childDashboardIds.map((dashboardId) => (
        <ModalListItem
          onClick={() => { onDashboardClick(dashboardId); }}
          key={`dashboard-${dashboardId}`}
          disabled={disableDashboards || _includes(disabledDashboardIds, dashboardId)}
        >
            <DashboardInModalListItemContainer
              dashboardId={dashboardId}
            />
        </ModalListItem>
    ));

    const foldersAndDashboards = folders.concat(dashboards);

    return (
        <div>
            {
                foldersAndDashboards.length > 0
                && (
                <List border={false}>
                    {foldersAndDashboards}
                </List>
                )
            }
            {
                (foldersAndDashboards.length === 0)
                && (
                <EmptyInfoScreen
                  image={emptyInfoImage}
                  message={emptyInfoMessage}
                />
                )
            }
        </div>
    );
};

FolderList.propTypes = {
    childFolderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    childDashboardIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    movingFolderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    onFolderClick: PropTypes.func.isRequired,
    disableDashboards: PropTypes.bool,
    onDashboardClick: PropTypes.func,
    emptyInfoImage: PropTypes.string,
    emptyInfoMessage: PropTypes.string,
    disabledDashboardIds: PropTypes.arrayOf(PropTypes.string)
};

FolderList.defaultProps = {
    disableDashboards: true,
    disabledDashboardIds: [],
    emptyInfoImage: emptyFolder,
    emptyInfoMessage: 'This folder is empty.',
    onDashboardClick: () => {}
};

const makeMapStateToProps = () => {
    const selectFolderIdsByParentIdAndType = makeSelectFolderIdsByParentIdAndType();
    const selectDashboardIdsByFolderIdAndType = makeSelectDashboardIdsByFolderIdAndType();
    return (state, ownProps) => {
        const { parentId } = ownProps;
        const folderType = selectFolderTypeById(state, parentId);
        return {
            childFolderIds: selectFolderIdsByParentIdAndType(state, folderType, parentId),
            childDashboardIds: selectDashboardIdsByFolderIdAndType(state, parentId, folderType)
        };
    };
};

export default connect(makeMapStateToProps)(FolderList);
