import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import { getLocalString } from 'src/components/Date';
import { selectAccountOptions } from 'src/selectors/loggedInUser';

const NextRunDate = (props) => {
    const { timezone, nextRunDateState, accountOptions } = props;
    const { loading, date } = nextRunDateState;

    if (loading) {
        return <span>Next run date: Generating ...</span>;
    }
    if (date) {
        return <span>Next run date: {getLocalString(date, 'datetime', accountOptions, true, timezone, timezone)}</span>;
    }

    return null;
};

NextRunDate.propTypes = {
    timezone: PropTypes.string.isRequired,
    nextRunDateState: customPropTypes.nextRunTimeState.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired
};

const mapStateToProps = (state) => ({
    accountOptions: selectAccountOptions(state)
});

export default connect(mapStateToProps)(NextRunDate);
