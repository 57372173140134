import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import AuthUserInAnotherSpaceWarningModal from 'src/components/modals/AuthUserInAnotherSpaceWarningModal';
import { externalLinkedInForceConnectRequest } from 'src/actions/socialNetworkProfileAuthentication';

const LinkedInAuthUserInAnotherSpaceExternalAuthenticationWarningModal = (props) => {
    const handleCompleteAuthenticationButtonClick = () => {
        const {
            accessToken,
            refreshToken,
            validUntil,
            refreshTokenValidUntil,
            platformUserId,
            platformUserName,
            externalLinkedInForceConnectRequestAction
        } = props;

        externalLinkedInForceConnectRequestAction(
            accessToken,
            refreshToken,
            validUntil,
            refreshTokenValidUntil,
            platformUserId,
            platformUserName
        );
    };

    const { hideModal, enforceFocus } = props;

    return (
        <AuthUserInAnotherSpaceWarningModal
          onCompleteAuthenticationClick={handleCompleteAuthenticationButtonClick}
          networkType="linkedIn"
          hideModal={hideModal}
          enforceFocus={enforceFocus}
        />
    );
};

LinkedInAuthUserInAnotherSpaceExternalAuthenticationWarningModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    externalLinkedInForceConnectRequestAction: PropTypes.func.isRequired,
    accessToken: PropTypes.string.isRequired,
    refreshToken: PropTypes.string.isRequired,
    validUntil: PropTypes.string.isRequired,
    refreshTokenValidUntil: PropTypes.string.isRequired,
    platformUserId: PropTypes.string.isRequired,
    platformUserName: PropTypes.string.isRequired
};

export default connect(null, {
    externalLinkedInForceConnectRequestAction: externalLinkedInForceConnectRequest
})(LinkedInAuthUserInAnotherSpaceExternalAuthenticationWarningModal);
