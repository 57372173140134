import React from 'react';
import { Text } from 'src/components/forms/fields';
import { Field } from 'redux-form';
import { customRequired, validateMaxLength } from 'src/components/forms/validators';
import PropTypes from 'prop-types';

const nameRequired = (value) => customRequired(value, 'Please provide a metric name');
const validateNameLength = (value) => validateMaxLength(value, 80);

const MetricBuilderName = ({ autoFocus }) => (
    <Field
      name="metricName"
      label="Metric name"
      component={Text}
      autoFocus={autoFocus}
      validate={[nameRequired, validateNameLength]}
    />
);

MetricBuilderName.propTypes = {
    autoFocus: PropTypes.bool,
};

MetricBuilderName.defaultProps = {
    autoFocus: false
};

export default MetricBuilderName;
