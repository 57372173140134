import PropTypes from 'prop-types';
import React from 'react';
import { Router } from 'react-router';
import styles from 'src/stylesheets/root.scss';

const Root = (props) => {
    const { history, routes } = props;
    return (
        <div className={styles.root}>
            <Router history={history} routes={routes} />
        </div>
    );
};

Root.propTypes = {
    history: PropTypes.object.isRequired,
    routes: PropTypes.node.isRequired
};

export default Root;
