import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inlineList/filteredOptionList.scss';
import FilteredOptionListItem from 'src/components/inlineList/FilteredOptionListItem';
import classnames from 'classnames';
import CreatableOption from 'src/components/inlineList/CreatableOption';
import * as customPropTypes from 'src/customPropTypes';

const CreatableOptionContainer = (props) => {
    const {
        creatableOption, optionName, activeIndex, numberOfFilteredOptions
    } = props;
    const { validationError } = creatableOption;

    const handleOnNewOptionClick = () => {
        const { onNewOptionClick } = props;
        onNewOptionClick();
    };

    if (validationError) {
        return (
            <div className={styles.filteredOptionItem}>
                <CreatableOption
                  creatableOption={creatableOption}
                  optionName={optionName}
                  disabled
                />
            </div>
        );
    }

    const active = activeIndex === numberOfFilteredOptions;
    return (
        <FilteredOptionListItem
          onClick={handleOnNewOptionClick}
          active={active}
        >
            <div className={classnames(styles.filteredOptionItem, { [styles.activeItem]: active })}>
                <CreatableOption
                  creatableOption={creatableOption}
                  optionName={optionName}
                />
            </div>
        </FilteredOptionListItem>
    );
};

CreatableOptionContainer.propTypes = {
    creatableOption: customPropTypes.creatableOption.isRequired,
    optionName: PropTypes.string.isRequired,
    onNewOptionClick: PropTypes.func.isRequired,
    activeIndex: PropTypes.number.isRequired,
    numberOfFilteredOptions: PropTypes.number.isRequired
};

export default CreatableOptionContainer;
