import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Modal from 'src/components/modals/layout/Modal';
import OverlayTableView from 'src/components/chartViews/OverlayTableView';
import PropTypes from 'prop-types';
import React from 'react';
import WithDataLoaded from 'src/components/withDataLoaded';
import { selectIsSharedDashboardContext } from 'src/selectors/sharedDashboard';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import { getMetricRequest } from 'src/sagas/utils';

const OverlayTableViewWithDataLoaded = WithDataLoaded(OverlayTableView);
const bodyStyle = { display: 'flex', width: '440px', height: '400px' };

const TwitterUserInteractionModal = (props) => {
    const {
        userId, userName, hideModal, enforceFocus, metricRequest, isSharedDashboardContext
    } = props;

    const params = { userId, metricRequest: JSON.stringify(getMetricRequest(metricRequest)) };
    let serverEndPoint = '/client-widget/load-twitter-user-interactions';

    if (isSharedDashboardContext) {
        serverEndPoint = '/client-widget/load-shared-dashboard-twitter-user-interactions';
    }

    return (
        <Modal dataViewName="twitter-user-interaction" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Interactions for {userName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={bodyStyle}>
                        <OverlayTableViewWithDataLoaded
                          params={params}
                          serverEndPoint={serverEndPoint}
                          type="twitterInteractions"
                          width={440}
                          height={400}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button action label="Done" onClick={hideModal} />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

TwitterUserInteractionModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    enforceFocus: PropTypes.bool,
    isSharedDashboardContext: PropTypes.bool.isRequired,
    metricRequest: customPropTypes.metricRequest.isRequired
};

const mapStateToProps = (state) => ({
    isSharedDashboardContext: selectIsSharedDashboardContext(state)
});

export default connect(mapStateToProps)(TwitterUserInteractionModal);
