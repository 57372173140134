import AuthUserInAnotherSpaceConfirmForm from 'src/components/forms/AuthUserInAnotherSpaceConfirmForm';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { isValid } from 'redux-form';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import GraphAuthUserInAnotherSpace from 'src/components/modals/partials/GraphAuthUserInAnotherSpace';
import LinkedInAuthUserInAnotherSpace from 'src/components/modals/partials/LinkedInAuthUserInAnotherSpace';
import SnapchatAuthUserInAnotherSpace from 'src/components/modals/partials/SnapchatAuthUserInAnotherSpace';
import _get from 'lodash/get';

const formName = 'authUserInSameSpaceForm';

const metaInfoByNetworkTypes = {
    graph: { networkName: 'Facebook', content: <GraphAuthUserInAnotherSpace /> },
    linkedIn: { networkName: 'LinkedIn', content: <LinkedInAuthUserInAnotherSpace /> },
    snapchat: { networkName: 'Snapchat', content: <SnapchatAuthUserInAnotherSpace /> }
};

const AuthUserInAnotherSpaceWarningModal = (props) => {
    const {
        hasConfirmed,
        hideModal,
        enforceFocus,
        networkType
    } = props;

    const handleCompleteAuthenticationButtonClick = () => {
        const { onCompleteAuthenticationClick } = props;
        onCompleteAuthenticationClick();
        hideModal();
    };

    const metaInfoByNetworkType = _get(metaInfoByNetworkTypes, networkType, false);

    if (metaInfoByNetworkType === false) {
        return (
            <Modal dataViewName="auth-user-in-another-space-modal" onHide={hideModal} enforceFocus={enforceFocus}>
                <Modal.Dialog width={560}>
                    <Modal.Header onHide={hideModal}>
                        <Modal.Title>
                            Unknown network type
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Network type unknown
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>
        );
    }

    const { networkName, content } = metaInfoByNetworkType;
    const title = `This ${networkName} account is used in another space`;
    return (
        <Modal dataViewName="auth-user-in-same-space-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog width={560}>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {content}
                    <br />
                    <br />
                    <br />
                    <AuthUserInAnotherSpaceConfirmForm networkName={networkName} form={formName} />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          label="Cancel and retry"
                          onClick={hideModal}
                        />
                        <Button
                          action
                          disabled={!hasConfirmed}
                          label="Complete authentication with this account"
                          onClick={handleCompleteAuthenticationButtonClick}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

AuthUserInAnotherSpaceWarningModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    hasConfirmed: PropTypes.bool.isRequired,
    networkType: PropTypes.oneOf(['graph', 'linkedIn', 'snapchat']).isRequired,
    onCompleteAuthenticationClick: PropTypes.func.isRequired
};

const makeMapStateToProps = () => (state) => ({
    hasConfirmed: isValid(formName)(state)
});

export default connect(makeMapStateToProps)(AuthUserInAnotherSpaceWarningModal);
