import { isSubmitting, submit } from 'redux-form';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import CreateGroupForm from 'src/components/forms/CreateGroupForm';
import { groupAddRequestFormAction } from 'src/actions/groups';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';

const initialValues = {
    name: ''
};

const formName = 'createGroup';

const GroupCreateModal = (props) => {
    const {
        hideModal, submitAction, submitting, enforceFocus
    } = props;
    return (
        <Modal dataViewName="create-group" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Create new group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateGroupForm form={formName} onSubmit={groupAddRequestFormAction} initialValues={initialValues} />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button disabled={submitting} onClick={hideModal} label="Cancel" />
                        <Button action loading={submitting} onClick={() => submitAction(formName)} label="Create" />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

GroupCreateModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    enforceFocus: PropTypes.bool
};

GroupCreateModal.defaultProps = {
    submitting: false
};

const mapStateToProps = (state) => ({
    submitting: isSubmitting(formName)(state)
});

export default connect(mapStateToProps, {
    submitAction: submit,
})(GroupCreateModal);
