import Button from 'src/components/buttons/Button';
import withPopover from 'src/components/withPopover';
import withTooltip from 'src/components/withToolTip';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/inputs/filterTokenInput.scss';
import classnames from 'classnames';

const ButtonWithPopoverAndTooltip = withPopover(withTooltip(Button));

const FilterTokenInput = ({
    value, renderToken, renderAddOverlay, onChange, wrapElements, disabled, buttonLabel
}) => {
    const onAdd = (newValue) => {
        if (newValue) {
            onChange([...value, newValue]);
        }
    };

    return (
        <ul className={classnames(styles.tokenList, { [styles.wrapElements]: wrapElements, [styles.disabled]: disabled })}>
            { value.map((entry, index) => {
                const onEdit = (changedValue) => {
                    const newValue = [...value];
                    if (changedValue) {
                        newValue[index] = changedValue;
                    } else {
                        newValue.splice(index, 1);
                    }
                    onChange(newValue);
                };

                const onDelete = () => {
                    const newValue = [...value];
                    newValue.splice(index, 1);
                    onChange(newValue);
                };

                return (<li key={entry}> { renderToken(entry, onEdit, onDelete)} </li>);
            })}
            <li className={styles.buttonWrapper}>
                <ButtonWithPopoverAndTooltip
                  icon="plus"
                  label={buttonLabel}
                  small
                  stretch
                  tooltip="Add"
                  placement="bottom-start"
                  overlay={
                      renderAddOverlay(onAdd)
                  }
                />
            </li>
        </ul>
    );
};

FilterTokenInput.propTypes = {
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    renderToken: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    renderAddOverlay: PropTypes.func.isRequired,
    wrapElements: PropTypes.bool,
    disabled: PropTypes.bool,
    buttonLabel: PropTypes.string
};

FilterTokenInput.defaultProps = {
    wrapElements: false,
    disabled: false,
    buttonLabel: ''
};

export default FilterTokenInput;
