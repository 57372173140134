import ProfileDetailsPage from 'src/components/ProfileDetailsPage';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import { makeSelectProfileById } from 'src/selectors/profiles';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import withResourceCheck from 'src/components/modals/withResourceCheck';
import styles from 'src/stylesheets/profileDetailsPage.scss';

const OnboardingProfileDetailModal = (props) => {
    const {
        hideModal,
        profile
    } = props;

    return (
        <Modal dataViewName="profile-detail" onHide={hideModal} key={`onboarding_profile_detail_${profile.id}`}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Profile details</Modal.Title>
                </Modal.Header>
                <Modal.Body padded={false} className={styles.modal}>
                    <ProfileDetailsPage profile={profile} hideViewAllAuthenticationButton />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={hideModal} label="Close" />
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

OnboardingProfileDetailModal.propTypes = {
    profile: customPropTypes.profile.isRequired,
    hideModal: PropTypes.func.isRequired
};

const OnboardingProfileDetailModalWithResourceCheck = withResourceCheck(OnboardingProfileDetailModal, 'profile', 'profile');

const makeMapStateToProps = () => {
    const selectProfileById = makeSelectProfileById();
    return (state, ownProps) => {
        const { profileId } = ownProps;
        return {
            profile: selectProfileById(state, profileId)
        };
    };
};

export default connect(makeMapStateToProps)(OnboardingProfileDetailModalWithResourceCheck);
