import React from 'react';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';
import classnames from 'classnames';
import styles from 'src/stylesheets/profileSearchResultListItem.scss';
import ProfileInfoSkeleton from 'src/components/loadingIndicators/ProfileInfoSkeleton';
import List from 'src/components/List';
import _range from 'lodash/range';
import PropTypes from 'prop-types';
import profileSearchStyle from 'src/stylesheets/profileSearch.scss';

const ListItem = ({ first, last }) => (
    <div className={classnames(styles.item, { [styles.first]: first === true }, { [styles.last]: last === true })}>
        <div className={styles.profileInfoWrapper}>
            <ProfileInfoSkeleton />
        </div>
        <div className={styles.buttonWrapper}>
            <EmptyDiv width={100} height={32} />
        </div>
    </div>
);

ListItem.propTypes = {
    first: PropTypes.bool,
    last: PropTypes.bool
};

const ProfileSearchLoadingListIndicator = ({ showNetworkFilter }) => (
    <div>
        <div style={{ display: 'flex' }}>
            <div style={{ flex: '1 1 auto' }}>
                <EmptyDiv width={115} height={24} />
            </div>
            {
                showNetworkFilter && (
                    <div style={{ alignSelf: 'flex-end' }}>
                        <EmptyDiv width={150} height={24} />
                    </div>
                )
            }
        </div>
        <div className={profileSearchStyle.spacer} />
        <List>
            {
                _range(0, 5).map((i) => (
                    <ListItem key={`list${i}`} />
                ))
            }
        </List>
    </div>
);

ProfileSearchLoadingListIndicator.propTypes = {
    showNetworkFilter: PropTypes.bool
};

export default ProfileSearchLoadingListIndicator;
