import CheckboxGroup from 'src/components/forms/CheckboxGroup';
import {
    AdditionalFilterSelector,
    Checkbox,
    CombinedDateSelector,
    ImageSelect,
    ProfileSelector,
    Select,
    SelectList
} from 'src/components/forms/fields';
import { change, Field, formValueSelector } from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/inputs/formFields.scss';
import withReduxForm from 'src/components/forms/withReduxForm';
import withVizualizationTypes from 'src/components/forms/withVisualtizationTypes';
import DashboardMetricAliasField from 'src/components/forms/partials/DashboardMetricAliasField';
import AdvancedTimezoneContext from 'src/components/context/AdvancedTimezoneContext';
import { validateDateSelection, validateProfileSelection } from 'src/components/forms/validators';

const ImageSelectWithVizualizationTypes = withVizualizationTypes(ImageSelect);

function generateLimitSelectOptions() {
    const limits = [];
    limits.push({ value: 0, title: 'Show all' });
    for (let i = 1; i < 26; i += 1) {
        limits.push({ value: i, title: i });
    }
    limits.push({ value: 30, title: 30 });
    limits.push({ value: 40, title: 40 });
    limits.push({ value: 50, title: 50 });
    limits.push({ value: 60, title: 60 });
    limits.push({ value: 70, title: 70 });
    limits.push({ value: 80, title: 80 });
    limits.push({ value: 90, title: 90 });
    limits.push({ value: 100, title: 100 });
    return (
        limits
    );
}

function generateSortDirSelectOptions() {
    return [
        { value: 'ASC', title: 'Ascending' },
        { value: 'DESC', title: 'Descending' }
    ];
}

function validate(data) {
    const errors = {};
    if (data.isCustomDateSelected) {
        if (_isEmpty(data.settingDateSelection)) {
            errors.settingDateSelection = 'Please select a date.';
        }
    }
    if (data.isCustomProfileSelected && !data.settingProfileSelection) {
        errors.settingProfileSelection = 'Please select a profile.';
    }
    return errors;
}

const MetricTileSettingForm = (props) => {
    const {
        handleSubmit,
        error,
        isCustomProfileSelectedValue,
        isCustomDateSelectedValue,
        isCustomAdditionalFilterSelectedValue,
        hasAdditionalSettings,
        columns,
        defaultVisualizationId,
        defaultMetricName,
        settingAdditionalFilterSelectionValue,
        inheritTimezoneValue,
        defaultTimezone,
        changeAction,
        form
    } = props;

    const formChangeCallback = (value) => {
        changeAction(form, 'inheritTimezone', value);
    };

    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <DashboardMetricAliasField placeholder={defaultMetricName} />
            <Field
              name="visualizationId"
              label="Choose a chart type"
              info="You can choose from other visualization types being compatible with the same kind of data. Please note that certain types like tables are not compatible with any other type."
              defaultVisualizationId={defaultVisualizationId}
              component={ImageSelectWithVizualizationTypes}
            />
            {
                hasAdditionalSettings
                    && (
                    <Field
                      name="sortBy"
                      label="Sort table by"
                      component={Select}
                    >
                        {
                            columns.map((column) => (
                                <option key={column.id} value={column.id}>
                                    {column.title}
                                </option>
                            ))
                        }
                    </Field>
                    )
            }
            {
                hasAdditionalSettings
                    && (
                    <Field
                      name="sortDir"
                      label="Sorting direction"
                      component={Select}
                    >
                        {
                            generateSortDirSelectOptions().map((element) => (
                                <option key={element.value} value={element.value}>
                                    {element.title}
                                </option>
                            ))
                        }
                    </Field>
                    )
            }
            {
                hasAdditionalSettings
                    && (
                    <Field
                      name="limit"
                      label="Limit rows in table to a maximum of"
                      component={Select}
                    >
                        {
                            generateLimitSelectOptions().map((elements) => (
                                <option key={elements.value} value={elements.value}>{elements.title}</option>
                            ))
                        }
                    </Field>
                    )
            }
            {
                hasAdditionalSettings
                    && (
                    <Field
                      name="hideColumns"
                      label="Hide the following columns"
                      options={columns.map((column) => ({ id: column.id, label: column.title }))}
                      component={SelectList}
                    />
                    )
            }
            <CheckboxGroup label="Custom selections">
                <Field
                  name="isCustomProfileSelected"
                  text="Select custom profile for this metric"
                  component={Checkbox}
                />
                {
                isCustomProfileSelectedValue
                    && (
                    <div className={styles.innerFormFieldWrapper}>
                        <Field
                          name="settingProfileSelection"
                          componentClass="profileSelector"
                          component={ProfileSelector}
                          validate={validateProfileSelection}
                        />
                    </div>
                    )
            }
                <Field
                  name="isCustomDateSelected"
                  text="Select custom date for this metric"
                  component={Checkbox}
                />
                {
                isCustomDateSelectedValue
                    && (
                    <AdvancedTimezoneContext.Provider value={{ defaultTimezone, isDefault: inheritTimezoneValue, onDefaultChange: formChangeCallback }}>
                        <div className={styles.innerFormFieldWrapper}>
                            <Field
                              name="settingDateSelection"
                              component={CombinedDateSelector}
                              validate={validateDateSelection}
                            />
                        </div>
                    </AdvancedTimezoneContext.Provider>
                    )
            }
                <Field
                  name="isCustomAdditionalFilterSelected"
                  text="Select custom additional filters for this metric"
                  component={Checkbox}
                />
                {
                isCustomAdditionalFilterSelectedValue
                    && (
                    <div className={styles.innerFormFieldWrapper}>
                        <Field
                          name="settingAdditionalFilterSelection"
                          component={AdditionalFilterSelector}
                          info={_isEmpty(settingAdditionalFilterSelectionValue) ? 'If no additional filters are selected here, this metric will still ignore any global additional filters used for the dashboard.' : undefined}
                        />
                    </div>
                    )
            }
            </CheckboxGroup>
        </form>
    );
};

MetricTileSettingForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: customPropTypes.dataLoadingError,
    isCustomProfileSelectedValue: PropTypes.bool.isRequired,
    isCustomDateSelectedValue: PropTypes.bool.isRequired,
    isCustomAdditionalFilterSelectedValue: PropTypes.bool.isRequired,
    hasAdditionalSettings: PropTypes.bool.isRequired,
    columns: PropTypes.array,
    defaultVisualizationId: PropTypes.string.isRequired,
    defaultMetricName: PropTypes.string.isRequired,
    settingAdditionalFilterSelectionValue: customPropTypes.additionalFilterValues,
    inheritTimezoneValue: PropTypes.bool.isRequired,
    defaultTimezone: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
    changeAction: PropTypes.func.isRequired
};

function mapStateToProps(state, props) {
    const selector = formValueSelector(props.form);
    return {
        isCustomProfileSelectedValue: !!selector(state, 'isCustomProfileSelected'),
        isCustomDateSelectedValue: !!selector(state, 'isCustomDateSelected'),
        isCustomAdditionalFilterSelectedValue: !!selector(state, 'isCustomAdditionalFilterSelected'),
        settingAdditionalFilterSelectionValue: selector(state, 'settingAdditionalFilterSelection'),
        inheritTimezoneValue: !!selector(state, 'inheritTimezone')
    };
}

export default connect(mapStateToProps, { changeAction: change })(withReduxForm(MetricTileSettingForm, {
    validate
}));
