import AdAccountInfoListItem from 'src/components/views/adAccounts/AdAccountInfoListItem';
import { makeSelectAdAccountById } from 'src/selectors/adAccounts';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const makeMapStateToProps = () => {
    const selectAdAccountById = makeSelectAdAccountById();
    return (state, ownProps) => {
        const { adAccountId, showThumbnail } = ownProps;
        const adAccount = selectAdAccountById(state, adAccountId);
        return {
            adAccount,
            showThumbnail
        };
    };
};

const AdAccountInfoListItemContainer = connect(makeMapStateToProps)(AdAccountInfoListItem);

AdAccountInfoListItemContainer.propTypes = {
    adAccountId: PropTypes.string.isRequired,
    showThumbnail: PropTypes.bool.isRequired
};

export default AdAccountInfoListItemContainer;
