import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/profileUseCases.scss';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Button from 'src/components/buttons/Button';
import withReactRouterLinkHandling from 'src/components/withReactRouterLinkHandling';
import { isPristine, isSubmitting, submit } from 'redux-form';
import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import classnames from 'classnames';
import PrivateStatisticUseCasesInfo from 'src/components/modals/authTransactionSteps/PrivateStatisticUseCasesInfo';
import AccountUseCaseForm from 'src/components/forms/AccountUseCaseForm';
import { selectUseCasesByPlatform } from 'src/selectors/useCases';
import { getUseCaseInitialFormValues } from 'src/utils/accountInsights';
import _memoize from 'memoize-one';

const memoizedGetUseCaseInitialFormValues = _memoize(getUseCaseInitialFormValues);

const ButtonWithReactRouterLinkHandling = withReactRouterLinkHandling(Button);

const AccountUseCases = (props) => {
    const {
        account,
        submitting,
        submitAction,
        pristine,
        className,
        hideViewAllAuthenticationButton,
        formName,
        formAction,
        platformTypeUseCases
    } = props;

    const initialValues = memoizedGetUseCaseInitialFormValues(account, platformTypeUseCases, hideViewAllAuthenticationButton);
    return (
        <div className={classnames(styles.wrapper, className)}>
            <AccountUseCaseForm
              form={formName}
              onSubmit={formAction}
              account={account}
              initialValues={initialValues}
              submitting={submitting}
              platformTypeUseCases={platformTypeUseCases}
            />
            <div className={styles.footer}>
                <div className={styles.left}>
                    <PrivateStatisticUseCasesInfo />
                </div>
                <div className={styles.right}>
                    <ButtonGroup>
                        {
                            !hideViewAllAuthenticationButton
                            && (
                                <ButtonWithReactRouterLinkHandling
                                  small
                                  label="View all authentications"
                                  to="/authentications"
                                />
                            )
                        }
                        <Button
                          small
                          action
                          label="Save"
                          disabled={pristine}
                          loading={submitting}
                          onClick={() => { submitAction(formName); }}
                        />
                    </ButtonGroup>
                </div>
            </div>
        </div>
    );
};

AccountUseCases.propTypes = {
    account: customPropTypes.account.isRequired,
    platformTypeUseCases: customPropTypes.useCases.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    className: PropTypes.string,
    hideViewAllAuthenticationButton: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    formAction: PropTypes.func.isRequired
};

AccountUseCases.defaultProps = {
    className: '',
    hideViewAllAuthenticationButton: false
};

const mapStateToProps = (state, ownProps) => ({
    platformTypeUseCases: selectUseCasesByPlatform(state, ownProps.account.platformType),
    submitting: isSubmitting(ownProps.formName)(state),
    pristine: isPristine(ownProps.formName)(state)
});

export default connect(mapStateToProps, {
    submitAction: ensurePermission(submit, writePermissions.updateProfileUseCases),
})(AccountUseCases);
