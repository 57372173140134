import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import _includes from 'lodash/includes';
import _slice from 'lodash/slice';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/tileCheckBoxSelectList.scss';
import TileCheckBox from 'src/components/forms/inputs/TileCheckBox';

class TileCheckBoxSelectList extends Component {
    constructor(props) {
        super(props);
        this.handleOnCheckBoxChange = this.handleOnCheckBoxChange.bind(this);
    }

    handleOnCheckBoxChange(e) {
        const { onChange, value } = this.props;
        const { checked, name } = e.target;
        const newSelection = _slice(value);
        if (checked) {
            newSelection.unshift(name);
        } else {
            newSelection.splice(newSelection.indexOf(name), 1);
        }
        onChange(newSelection);
    }

    render() {
        const {
            options, value, onFocus, onBlur, name
        } = this.props;

        return (
            <div className={styles.wrapper}>
                {
                    options.map((option) => (
                        <div className={styles.tileCheckBoxItem} key={option.id}>
                            <TileCheckBox
                              name={option.id}
                              text={option.label}
                              checked={_includes(value, option.id)}
                              onChange={this.handleOnCheckBoxChange}
                            />
                        </div>
                    ))
                }
                <input
                  type="hidden"
                  name={name}
                  value={value}
                  onFocus={onFocus}
                  onBlur={onBlur}
                />
            </div>
        );
    }
}

TileCheckBoxSelectList.propTypes = {
    options: customPropTypes.selectListOptions.isRequired,
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
};

export default TileCheckBoxSelectList;
