import DateTime from 'react-datetime';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';

const style = {
    padding: '16px'
};

const SingleAbsoluteDateSelectorPopover = (props) => {
    const { selectedDate, onDateChange, hidePopover } = props;

    const handleOnDateSelect = (selection) => {
        const value = selection.format('YYYY-MM-DD');
        onDateChange(value);
        hidePopover();
    };

    return (
        <Popover id="singleAbsoluteDateSelector">
            <div style={style}>
                <DateTime
                  open
                  input={false}
                  timeFormat={false}
                  value={selectedDate}
                  dateFormat="YYYY-MM-DD"
                  onChange={handleOnDateSelect}
                />
            </div>
        </Popover>
    );
};

SingleAbsoluteDateSelectorPopover.propTypes = {
    selectedDate: PropTypes.string,
    onDateChange: PropTypes.func.isRequired,
    hidePopover: PropTypes.func
};

export default SingleAbsoluteDateSelectorPopover;
