import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import LinkTextWithActions from 'src/components/LinkTextWithActions';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { getLocalString } from 'src/components/Date';
import { connect } from 'react-redux';
import { selectAccountOptions } from 'src/selectors/loggedInUser';

import * as customPropTypes from 'src/customPropTypes';

const CreatedLinkModal = (props) => {
    const {
        hideModal, exportedLink, validUntil, enforceFocus, accountOptions
    } = props;
    return (
        <Modal dataViewName="interactive-report" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Interactive report created
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LinkTextWithActions
                      link={exportedLink}
                      description={`Here is the link to share the report. It will ${validUntil !== null ? `expire on ${getLocalString(validUntil, 'date', accountOptions)}.` : 'not expire.'}`}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button label="Close" onClick={hideModal} />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

CreatedLinkModal.propTypes = {
    accountOptions: customPropTypes.accountOptions.isRequired,
    hideModal: PropTypes.func.isRequired,
    exportedLink: PropTypes.string.isRequired,
    validUntil: PropTypes.string,
    enforceFocus: PropTypes.bool
};

const mapStateToProps = (state) => ({
    accountOptions: selectAccountOptions(state)
});

export default connect(mapStateToProps)(CreatedLinkModal);
