import _findIndex from 'lodash/findIndex';
import React, { useState, useEffect } from 'react';
import _omit from 'lodash/omit';
import PropTypes from 'prop-types';
import Select from 'src/components/forms/inputs/Select';
import withPopover from 'src/components/withPopover';
import SelectItem from 'src/components/forms/inputs/layout/SelectItem';
import CustomSelectPopover from 'src/components/popovers/CustomSelectPopover';

const SelectWithCustomPopoverWithPopoverHandling = withPopover(SelectItem);

const BaseComponent = (props) => {
    const {
        value,
        options,
        onChange,
        renderOption,
        renderHeader,
        popoverWidth
    } = props;

    const cleanedProps = _omit(props, ['renderOption', 'renderHeader', 'popoverWidth']);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        setActiveIndex(_findIndex(options, (option) => option.id === value) || 0);
    }, [value]);

    const overlay = (
        <CustomSelectPopover
          options={options}
          value={value}
          onChange={onChange}
          renderOption={renderOption}
          renderHeader={renderHeader}
          width={popoverWidth}
          activeIndex={activeIndex}
        />
    );

    return (
        <SelectWithCustomPopoverWithPopoverHandling
          trigger={['click', 'spacebar']}
          {...cleanedProps}
          overlay={overlay}
          placement="bottom-start"
          usePortalToRender={false}
        />
    );
};

BaseComponent.propTypes = {
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    renderOption: PropTypes.func.isRequired,
    renderHeader: PropTypes.func,
    popoverWidth: PropTypes.number
};

const CustomSelect = (props) => <Select {...props} baseComponent={BaseComponent} />;

CustomSelect.propTypes = {
    renderValue: PropTypes.func.isRequired,
    renderOption: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    popoverWidth: PropTypes.number
};

export default CustomSelect;
