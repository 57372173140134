import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/modalListAddButton.scss';

const ModalListAddButton = ({
    big, onClick, label, disabled
}) => (
    <div className={classnames(styles.wrapper, { [styles.big]: big, [styles.disabled]: disabled })}>
        <Link onClick={onClick} className={styles.link}>
            <IcomoonIcon icon="plus" className={styles.icon} />
            <div className={styles.text}>{label}</div>
        </Link>
    </div>
);

ModalListAddButton.propTypes = {
    label: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    big: PropTypes.bool,
    disabled: PropTypes.bool
};

ModalListAddButton.defaultProps = {
    big: false,
    disabled: false
};

export default ModalListAddButton;
