import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { makeSelectCheckedValues } from 'src/selectors/lists';
import { initializeList, unregisterList } from 'src/actions/lists';
import { selectIsBulkProfileAdding } from 'src/selectors/profiles';
import _omit from 'lodash/omit';

const withAccountSearchHandling = (WrappedModalComponent, listName) => {
    const WithProfileSearchHandling = (props) => {
        const [clickedAddAccounts, changeClickedAddAccounts] = useState(false);
        useEffect(() => {
            const { initializeListAction, unregisterListAction } = props;
            initializeListAction(listName);
            return () => {
                unregisterListAction(listName);
            };
        }, []);

        const handleOnAddAccounts = () => {
            changeClickedAddAccounts(true);
        };

        const handleOnHideModal = () => {
            const { hideModal, isBulkProfileAdding, checkedAccountIds } = props;
            if (!isBulkProfileAdding) {
                if (!clickedAddAccounts && checkedAccountIds.length > 0) {
                    if (window.confirm('Are you sure you want to leave the page? The selected accounts have not been added.')) {
                        hideModal();
                    }
                } else {
                    hideModal();
                }
            }
        };

        const cleanedProps = _omit(props, ['initializeListAction', 'unregisterListAction']);

        return (
            <WrappedModalComponent {...cleanedProps} onAddAccounts={handleOnAddAccounts} handleOnHideModal={handleOnHideModal} />
        );
    };

    WithProfileSearchHandling.propTypes = {
        checkedAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        initializeListAction: PropTypes.func.isRequired,
        unregisterListAction: PropTypes.func.isRequired,
        isBulkProfileAdding: PropTypes.bool.isRequired,
        hideModal: PropTypes.func
    };

    const mapStateToProps = () => {
        const selectCheckedValues = makeSelectCheckedValues();
        return (state) => {
            const checkedAccountIds = selectCheckedValues(state, listName);
            return {
                checkedAccountIds,
                isBulkProfileAdding: selectIsBulkProfileAdding(state)
            };
        };
    };

    return connect(mapStateToProps, {
        initializeListAction: initializeList,
        unregisterListAction: unregisterList
    })(WithProfileSearchHandling);
};

export default withAccountSearchHandling;
