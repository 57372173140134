import React from 'react';
import PropTypes from 'prop-types';
import TimezoneSelect from 'src/components/forms/inputs/TimezoneSelect';
import styles from 'src/stylesheets/inputs/advancedTimezoneSelect.scss';
import Button from 'src/components/buttons/Button';
import withTooltip from 'src/components/withToolTip';

const ButtonWithTooltip = withTooltip(Button);

const AdvancedTimezoneSelect = (props) => {
    const {
        value, onChange, defaultTimezone, isDefault, onDefaultChange
    } = props;
    const tooltipButton = isDefault ? <span>The timezone gets taken from the dashboard{'\''}s selection. <br /> Click to select a specific timezone.</span> : <span>A specific timezone is selected. <br />Click to bind timezone to dashboard{'\''}s selection</span>;

    return (
        <div className={styles.wrapper}>
            <div className={styles.button}>
                <ButtonWithTooltip onClick={() => { onDefaultChange(!isDefault); }} icon={isDefault ? 'lock' : 'unlock'} tooltip={tooltipButton} />
            </div>
            <div className={styles.select}>
                <TimezoneSelect
                  disabled={isDefault}
                  value={isDefault ? defaultTimezone : value}
                  onChange={(newValue) => { onChange(newValue); }}
                />
            </div>
        </div>
    );
};

AdvancedTimezoneSelect.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultTimezone: PropTypes.string.isRequired,
    isDefault: PropTypes.bool.isRequired,
    onDefaultChange: PropTypes.func.isRequired
};

export default AdvancedTimezoneSelect;
