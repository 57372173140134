import FolderInModalListItemContainer from 'src/components/views/folders/FolderInModalListItemContainer';
import ModalListItem from 'src/components/views/folders/ModalListItem';
import React from 'react';
import PropTypes from 'prop-types';
import List from 'src/components/List';

const SuperRoot = ({ onItemClick }) => (
    <List border={false}>
        <ModalListItem onClick={() => { onItemClick('user'); }}>
            <FolderInModalListItemContainer
              folderId="user"
              showFolderCount
              icon="lock"
              showLabel={false}
            />
        </ModalListItem>
        <ModalListItem onClick={() => { onItemClick('shared'); }}>
            <FolderInModalListItemContainer
              folderId="shared"
              showLabel={false}
              showFolderCount
              icon="share"
            />
        </ModalListItem>
    </List>
);

SuperRoot.propTypes = {
    onItemClick: PropTypes.func.isRequired
};

export default SuperRoot;
