import * as customPropTypes from 'src/customPropTypes';
import { getLabelAndFinalState } from 'src/utils/accountInsights';
import Label from 'src/components/labels/Label';
import React from 'react';
import withTooltip from 'src/components/withToolTip';

const LabelWithTooltip = withTooltip(Label);

const ProfileSelectorLinkConnectionStatus = (props) => {
    const { requiredUseCases, accountUseCasesAuthUsers } = props;
    const { finalState, finalTooltip } = getLabelAndFinalState(requiredUseCases, accountUseCasesAuthUsers);
    return <LabelWithTooltip state={finalState} tooltip={finalTooltip} />;
};

ProfileSelectorLinkConnectionStatus.propTypes = {
    requiredUseCases: customPropTypes.useCases.isRequired,
    accountUseCasesAuthUsers: customPropTypes.accountUseCasesAuthUsers.isRequired
};

export default ProfileSelectorLinkConnectionStatus;
