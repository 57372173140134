import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/modal.scss';

const ModalBody = ({
    padded, centered, children, height, maxHeight, stickyFooter, className
}) => {
    const body = classnames(styles.body, { [styles.padded]: padded }, className);

    if (centered) {
        return (
            <div className={body}>
                <div className={styles.centered}>
                    {children}
                </div>
            </div>
        );
    }

    const elementStyle = {};
    if (height) {
        elementStyle.height = height;
    }
    if (maxHeight) {
        elementStyle.maxHeight = maxHeight;
    }

    if (stickyFooter) {
        return (
            <div className={styles.bodyWrapper}>
                <div className={body} style={elementStyle}>{children}</div>
                <div className={styles.stickyFooterWrapper}>
                    {stickyFooter}
                </div>
            </div>
        );
    }

    return <div className={body} style={elementStyle}>{children}</div>;
};

ModalBody.propTypes = {
    children: customPropTypes.children.isRequired,
    centered: PropTypes.bool,
    height: PropTypes.number, // used to guarantee fixed height of a modal, e.g. to avoid height jumping effects with new elements added within the modal
    maxHeight: PropTypes.number, // less strict than setting "height", usually used in context where a height change of the modal is acceptable
    stickyFooter: PropTypes.node,
    padded: PropTypes.bool,
    className: PropTypes.string
};

ModalBody.defaultProps = {
    centered: false,
    padded: true,
    className: ''
};

export default ModalBody;
