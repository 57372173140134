import CombinedDateSelectorButton from 'src/components/buttons/CombinedDateSelectorButton';
import IntervalButton from 'src/components/buttons/IntervalButton';
import CombinedDateSelectorPopover from 'src/components/popovers/CombinedDateSelectorPopover';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React, { useState, forwardRef } from 'react';
import withPopover from 'src/components/withPopover';
import styles from 'src/stylesheets/combinedDateSelector.scss';
import classnames from 'classnames';
import { getDefaultDate } from 'src/utils/filterSelectors';
import DefaultTimezoneResolverFromContext from 'src/components/dateSelector/DefaultTimezoneResolverFromContext';

const CombinedDateSelectorButtonWithPopover = withPopover(CombinedDateSelectorButton);

const CombinedDateSelector = forwardRef((props, ref) => {
    const {
        selectedDate, placement, small, onDateChange, disabled, defaultDateForDatePicker, onHide, onShow, showInterval
    } = props;
    const dateSelectorPopover = (
        <CombinedDateSelectorPopover
          selectedDate={selectedDate || defaultDateForDatePicker}
          onDateChange={onDateChange}
        />
    );

    const [lastHover, setLastHover] = useState('left');

    return (
        <DefaultTimezoneResolverFromContext selectedDate={selectedDate}>
            {
                (selectedDateWithDefault) => (
                    <div className={styles.wrapper}>
                        <div onMouseEnter={() => { setLastHover('left'); }} className={classnames({ [styles.active]: lastHover === 'left' })}>
                            <CombinedDateSelectorButtonWithPopover
                              flip={false}
                              overlay={dateSelectorPopover}
                              selectedDate={selectedDateWithDefault}
                              placement={placement}
                              small={small}
                              disabled={disabled}
                              onShow={onShow}
                              onHide={onHide}
                              className="dateSelector"
                              leftCurved={showInterval}
                              forwardedRef={ref}
                            />
                        </div>
                        {
                showInterval
                && (
                    <div onMouseEnter={() => { setLastHover('right'); }} className={classnames({ [styles.active]: lastHover === 'right' }, styles.right)}>
                        <IntervalButton
                          rightCurved
                          selectedDate={selectedDateWithDefault}
                          onIntervalChange={onDateChange}
                          small={small}
                          disabled={disabled}
                          onShow={onShow}
                          onHide={onHide}
                        />
                    </div>
                )
            }
                    </div>
                )
            }
        </DefaultTimezoneResolverFromContext>
    );
});

CombinedDateSelector.propTypes = {
    selectedDate: PropTypes.oneOfType([customPropTypes.dateRange, customPropTypes.relativeDate, PropTypes.bool]).isRequired,
    defaultDateForDatePicker: customPropTypes.dateRange,
    onDateChange: PropTypes.func.isRequired,
    placement: PropTypes.string,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    showInterval: PropTypes.bool
};

CombinedDateSelector.defaultProps = {
    placement: 'bottom-start',
    small: false,
    disabled: false,
    defaultDateForDatePicker: getDefaultDate(),
    showInterval: true,
};

export default CombinedDateSelector;
