import React from 'react';
import styles from 'src/stylesheets/modal.scss';
import * as customPropTypes from 'src/customPropTypes';

const DrawerDialog = ({ children }) => (
    <div className={styles.drawerDialog}>
        {children}
    </div>
);

DrawerDialog.propTypes = {
    children: customPropTypes.children.isRequired
};

export default DrawerDialog;
