import Button from 'src/components/buttons/Button';
import Text from 'src/components/forms/inputs/Text';
import Checkbox from 'src/components/forms/inputs/Checkbox';
import Popover from 'src/components/popovers/layout/Popover';
import _trim from 'lodash/trim';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from 'src/stylesheets/popovers/filterPopover.scss';
import { parseKeyWordOnlyValue, transformKeyWordOnlyValue } from 'src/utils/string';

const TextFilterPopover = ({
    defaultValue, onSubmit, hidePopover, actionLabel, selectedValues
}) => {
    const [value, changeValue] = useState(defaultValue);
    const [error, changeError] = useState(false);

    const selectedValuesWithoutSelf = defaultValue ? selectedValues.filter((selectedValue) => selectedValue !== defaultValue) : selectedValues;

    const submit = () => {
        if (error) {
            return;
        }
        const { value: transformedValue } = transformKeyWordOnlyValue(value);
        if (_trim(transformedValue).length === 0) {
            onSubmit('');
        } else {
            onSubmit(value);
        }
        hidePopover();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            submit();
        }
    };

    const {
        value: valueToRender, wordOnly
    } = transformKeyWordOnlyValue(value);

    const updateValue = (valueWithoutPrefix) => {
        changeValue(parseKeyWordOnlyValue(valueWithoutPrefix, wordOnly));
    };

    const updateCheckBox = (newWordOnly) => {
        changeValue(parseKeyWordOnlyValue(valueToRender, newWordOnly));
    };

    return (
        <Popover width={230} id="textFilterPopover">
            <div className={styles.wrapper}>
                <div className={styles.description}>Filter posts by keywords or hashtags</div>
                <Text
                  value={valueToRender}
                  autoFocus
                  className={styles.textInput}
                  onChange={(event) => {
                      const currentValue = event.target.value;
                      const fullValue = parseKeyWordOnlyValue(currentValue, wordOnly);
                      if (selectedValuesWithoutSelf.some((otherValue) => otherValue === fullValue)) {
                          changeError(true);
                      } else {
                          changeError(false);
                      }
                      updateValue(currentValue);
                  }}
                  onKeyPress={handleKeyPress}
                  error={error}
                />

                <div className={styles.checkbox}>
                    <Checkbox
                      onChange={() => {
                          const fullValue = parseKeyWordOnlyValue(valueToRender, !wordOnly);
                          if (selectedValuesWithoutSelf.some((otherValue) => otherValue === fullValue)) {
                              changeError(true);
                          } else {
                              changeError(false);
                          }
                          updateCheckBox(!wordOnly);
                      }}
                      checked={wordOnly}
                      text="Match exact word only"
                    />
                </div>
                <Button small stretch action onClick={submit} label={actionLabel} />
            </div>
        </Popover>
    );
};

TextFilterPopover.propTypes = {
    defaultValue: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    hidePopover: PropTypes.func,
    actionLabel: PropTypes.string.isRequired,
    selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired
};

TextFilterPopover.defaultProps = {
    defaultValue: ''
};

export default TextFilterPopover;
