import FilterTokenInput from 'src/components/forms/inputs/layout/FilterTokenInput';
import TagFilterPopover from 'src/components/popovers/TagFilterPopover';
import withPopover from 'src/components/withPopover';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TagFilterToken from 'src/components/TagFilterToken';

const TagFilterTokenWithPopover = withPopover(TagFilterToken);

const TagFilterTokenInput = ({
    value, onChange, wrapElements, disabled
}) => {
    const [filterQuery, setFilterQuery] = useState('');

    return (
        <FilterTokenInput
          onChange={onChange}
          wrapElements={wrapElements}
          disabled={disabled}
          renderToken={(entry, onEdit, onDelete) => (
              <TagFilterTokenWithPopover
                placement="bottom-start"
                showTooltip={!wrapElements}
                overlay={(
                    <TagFilterPopover
                      actionLabel="edit"
                      selectedValues={value}
                      onSubmit={onEdit}
                      defaultValue={entry}
                      filterQuery={filterQuery}
                      setFilterQuery={setFilterQuery}
                    />
                )}
                postTagId={entry}
                key={entry}
                onDeleteClick={onDelete}
              />
          )}
          renderAddOverlay={(onAdd) => (
              <TagFilterPopover
                onSubmit={onAdd}
                actionLabel="Add"
                selectedValues={value}
                filterQuery={filterQuery}
                setFilterQuery={setFilterQuery}
              />
          )}
          value={value}
        />
    );
};

TagFilterTokenInput.propTypes = {
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    wrapElements: PropTypes.bool,
    disabled: PropTypes.bool
};

TagFilterTokenInput.defaultProps = {
    wrapElements: false,
    disabled: false
};

export default TagFilterTokenInput;
