import * as customPropTypes from 'src/customPropTypes';
import _omit from 'lodash/omit';
import Button from 'src/components/buttons/Button';
import classnames from 'classnames';
import ProfileSelectorLabel from 'src/components/profileSelector/ProfileSelectorLabel';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styles from 'src/stylesheets/selectorButton.scss';
import ProfileSelectorButtonIcon from 'src/components/ProfileSelectorButtonIcon';

const ProfileSelectorButton = forwardRef((props, ref) => {
    const cleanedProps = _omit(props, 'selectedProfilesOrGroups', 'className');
    const { selectedProfilesOrGroups, disabled, className } = props;
    const mergedClassname = className ? classnames(className, styles.minWidth) : styles.minWidth;
    const label = <ProfileSelectorLabel selectedProfilesOrGroups={selectedProfilesOrGroups} disabled={disabled} />;
    const icon = <ProfileSelectorButtonIcon selectedProfilesOrGroups={selectedProfilesOrGroups} disabled={disabled} />;
    return (
        <Button
          {...cleanedProps}
          className={mergedClassname}
          label={label}
          icon={icon}
          responsive
          ref={ref}
        />
    );
});

ProfileSelectorButton.propTypes = {
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string
};

ProfileSelectorButton.defaultProps = {
    small: false,
    disabled: false
};

export default ProfileSelectorButton;
