import React, { Component } from 'react';
import _omit from 'lodash/omit';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/checkbox.scss';

class Checkbox extends Component {
    componentDidMount() {
        const { indeterminate, defaultIndeterminate } = this.props;
        if (indeterminate !== undefined && defaultIndeterminate !== undefined) {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error('indeterminate (controlled) and defaultIndeterminate (uncontrolled) only work mutually exclusive');
            }
        }
        this.input.indeterminate = defaultIndeterminate === true || indeterminate === true;
    }

    componentDidUpdate() {
        const { indeterminate, defaultIndeterminate } = this.props;

        if (defaultIndeterminate === undefined && indeterminate !== undefined) {
            this.input.indeterminate = indeterminate;
        }
    }

    render() {
        const {
            disabled, error, text, onMouseOver, onMouseOut, warning
        } = this.props;
        const cleanedProps = _omit(this.props, ['error', 'text', 'indeterminate', 'defaultIndeterminate', 'active', 'onMouseOut', 'onMouseOver', 'warning']);

        const optionalProps = {};
        if (onMouseOver) {
            optionalProps.onMouseOver = onMouseOver;
        }
        if (onMouseOut) {
            optionalProps.onMouseOut = onMouseOut;
        }

        return (
            <label
              className={classnames(
                  styles.wrapper,
                  { [styles.wrapperDisabled]: disabled, [styles.error]: error, [styles.hasText]: !!text }
              )}
              {...optionalProps}
            >
                <input
                  ref={(c) => { this.input = c; }}
                  {...cleanedProps}
                  type="checkbox"
                  className={styles.input}
                />
                <span className={classnames(styles.checkbox, { [styles.warning]: warning })} />
                { text && <span className={styles.text}>{text}</span> }
            </label>
        );
    }
}

Checkbox.propTypes = {
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    indeterminate: PropTypes.bool,
    defaultIndeterminate: PropTypes.bool,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    warning: PropTypes.bool
};

Checkbox.defaultProps = {
    error: false,
    disabled: false,
    text: '',
    warning: false,
    onMouseOver: () => {},
    onMouseOut: () => {}
};

export default Checkbox;
