export const listName = 'adAccountSearch';

export const getNumberOfAdAccountsToSelect = (spaceLimits, selectedAdAccountsCount = 0) => {
    const { usage, limits } = spaceLimits;
    const adAccountUsed = usage.adAccounts;
    const adAccountsTotal = limits.adAccounts;
    const adAccountLift = adAccountsTotal - adAccountUsed;

    if (adAccountLift - selectedAdAccountsCount <= 0) {
        return {
            count: 0,
            type: 'ad account'
        };
    }

    return {
        count: adAccountLift - selectedAdAccountsCount,
        type: 'none'
    };
};
