import React from 'react';
import styles from 'src/stylesheets/views/postTags/bindingList.scss';
import * as customPropTypes from 'src/customPropTypes';

const renderEntityNames = (entityNames) => (
    <ul className={styles.entities}>
        {
            entityNames.map((name) => (
                <li key={name}>{name}</li>
            ))
        }
    </ul>
);

const BindingList = (props) => {
    const { forceOperationalEntityInfo } = props;
    const {
        dashboardNames, postTagNames, hasBindingsToOtherUserDashboards, hasBindingsToDashboardShareLinks
    } = forceOperationalEntityInfo;
    return (
        <ul className={styles.wrapper}>
            {
                dashboardNames.length > 0
                && (
                    <li>
                        {`Dashboard${dashboardNames.length > 1 ? 's' : ''}:`}
                        {renderEntityNames(dashboardNames)}
                    </li>
                )
            }
            {
                postTagNames.length > 0
                && (
                    <li>
                        {`Tag${postTagNames.length > 1 ? 's' : ''} with automation:`}
                        {renderEntityNames(postTagNames)}
                    </li>
                )
            }
            {
                hasBindingsToOtherUserDashboards
                && (
                    <li>Private dashboards</li>
                )
            }
            {
                hasBindingsToDashboardShareLinks
                && (
                    <li>Export links</li>
                )
            }
        </ul>
    );
};

BindingList.propTypes = {
    forceOperationalEntityInfo: customPropTypes.forceOperationalEntityInfo.isRequired
};

export default BindingList;
