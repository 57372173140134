import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';

const GroupDeleteForm = (props) => {
    const { handleSubmit, group } = props;
    return (
        <form onSubmit={handleSubmit}>
            {`Are you sure you want to delete group "${group.name}"?`}
        </form>
    );
};

GroupDeleteForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    group: customPropTypes.group.isRequired
};

export default withReduxForm(GroupDeleteForm);
