import React from 'react';
import _omit from 'lodash/omit';
import FieldLabel from 'src/components/forms/fields/FieldLabel';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/formFields.scss';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import classnames from 'classnames';

const withFormField = (InputComponent) => {
    const WithFormField = (props) => {
        const {
            label, meta, info, bellowRightInfo, input, activeOnDirty, showErrorIfDirty, topRightInfo, disabled
        } = props;
        const { touched, error, dirty } = meta;
        const cleanedProps = _omit(props, 'label', 'meta', 'info', 'topRightInfo', 'input', 'activeOnDirty', 'showErrorIfDirty', 'bellowRightInfo');
        const showError = (touched || (showErrorIfDirty && dirty)) && error;
        return (
            <div className={styles.defaultFormField}>
                <div className={classnames(styles.formGroup, { [styles.disabled]: disabled })}>
                    <div className={styles.fieldLabelWrapper}>
                        { label && <div className={styles.fieldLabel}><FieldLabel htmlFor={input.name}>{label}</FieldLabel></div>}
                        {
                            topRightInfo && (
                                <div className={styles.fieldInfo}>
                                    {
                                        (typeof topRightInfo) === 'string' && <DivWithTooltip tooltip={topRightInfo}>{topRightInfo}</DivWithTooltip>
                                    }
                                    {
                                        (typeof topRightInfo) === 'object' && (topRightInfo)
                                    }
                                </div>
                            )
                        }
                    </div>
                    <InputComponent
                      active={activeOnDirty && dirty}
                      error={!!error && (!!touched || (showErrorIfDirty && dirty))}
                      {...cleanedProps}
                      {...input}
                    />
                    {
                        (showError || bellowRightInfo || info) && (
                            <div className={styles.errorAndInfoWrapper}>
                                <div className={styles.errorWrapper}>
                                    {
                                        showError && (
                                            <div className={classnames(styles.error, 'form-error-global')}>{error}</div>
                                        )
                                    }
                                    {
                                        info && (
                                            <div className={styles.info}>
                                                {info}
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    bellowRightInfo && <div className={styles.bellowRightInfo}>{bellowRightInfo}</div>
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        );
    };

    WithFormField.propTypes = {
        label: PropTypes.string,
        meta: PropTypes.shape({
            touched: PropTypes.bool,
            error: PropTypes.string,
            dirty: PropTypes.bool
        }).isRequired,
        info: PropTypes.string,
        topRightInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        input: PropTypes.shape({
            value: PropTypes.any,
            name: PropTypes.string.isRequired,
        }).isRequired,
        activeOnDirty: PropTypes.bool,
        showErrorIfDirty: PropTypes.bool,
        disabled: PropTypes.bool,
        bellowRightInfo: PropTypes.string
    };

    WithFormField.defaultProps = {
        label: '',
        info: '',
        topRightInfo: '',
        activeOnDirty: false,
        showErrorIfDirty: false,
        bellowRightInfo: ''
    };

    return WithFormField;
};

export default withFormField;
