import Button from 'src/components/buttons/Button';
import { getTimezoneStringWithAbbrev } from 'src/components/dateSelector/utils';
import TextWithResetIcon from 'src/components/forms/inputs/TextWithResetIcon';
import moment from 'moment-timezone';
import React from 'react';
import PropTypes from 'prop-types';
import { getAccountOptions } from 'src/selectors/loggedInUser';
import styles from 'src/stylesheets/inputs/timezoneSelect.scss';
import withTooltip from 'src/components/withToolTip';
import { connect } from 'react-redux';

const ButtonWithTooltip = withTooltip(Button);

const TimezoneSelectHeader = (props) => {
    const {
        filter,
        onFilterChange,
        error,
        accountSettingsTimezone,
        onValueChange,
        setActiveIndex,
        hideQuickSelect,
        onKeyDownHandler,
        resetActiveIndex
    } = props;

    const defaultTimezoneWithAbbrev = getTimezoneStringWithAbbrev(accountSettingsTimezone, moment());

    return (
        <div className={styles.header}>
            <div className={styles.search}>
                <TextWithResetIcon
                  error={error}
                  autoFocus
                  placeholder="Search for timezone"
                  onChange={(e) => {
                      onFilterChange(e.target.value);
                      if (e.target.value === '') {
                          resetActiveIndex();
                      } else {
                          setActiveIndex(0);
                      }
                  }}
                  value={filter}
                  onKeyDown={onKeyDownHandler}
                  onResetClick={() => {
                      onFilterChange('');
                      setActiveIndex(0);
                  }}
                />
            </div>
            {
                !hideQuickSelect
                && (
                <div className={styles.default}>
                    <ButtonWithTooltip
                      icon="timezone"
                      tooltip={<span>Quick select user{'\''}s default: <br />{defaultTimezoneWithAbbrev}.</span>}
                      onClick={() => {
                          onValueChange(accountSettingsTimezone);
                      }}
                    />
                </div>
                )
            }
        </div>
    );
};

TimezoneSelectHeader.propTypes = {
    filter: PropTypes.string.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
    accountSettingsTimezone: PropTypes.string.isRequired,
    setActiveIndex: PropTypes.func.isRequired,
    resetActiveIndex: PropTypes.func.isRequired,
    hideQuickSelect: PropTypes.bool,
    onKeyDownHandler: PropTypes.func.isRequired
};

TimezoneSelectHeader.defaultProps = {
    error: false,
    hideQuickSelect: false
};

const mapStateToProps = (state) => {
    const { timezone } = getAccountOptions(state);
    return {
        accountSettingsTimezone: timezone
    };
};

export default connect(mapStateToProps)(TimezoneSelectHeader);
