import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { toggleListValue } from 'src/actions/lists';
import { makeSelectFieldValue } from 'src/selectors/lists';
import styles from 'src/stylesheets/views/profiles/search/searchProfileAddButton.scss';
import * as notificationActions from 'src/actions/notifications';

const AccountAddButton = (props) => {
    const [isHovering, setIsHovering] = useState(false);
    const {
        isSelected,
        accountId,
        toggleListValueAction,
        isAdding,
        listName
    } = props;

    const handleOnClick = () => {
        const { showNotificationAction, currentAccountLimitCountAndType } = props;
        if (isSelected === false && currentAccountLimitCountAndType.count === 0) {
            showNotificationAction(`It looks like you have reached your ${currentAccountLimitCountAndType.type} limit. To upgrade your ${currentAccountLimitCountAndType.type} limit, please contact support@facelift-bbt.com.`, 'warning');
        } else {
            toggleListValueAction(listName, accountId);
        }
    };

    let icon = isSelected ? 'check-mark' : null;
    let label = isSelected ? 'Selected' : 'Select';
    if (isHovering && isSelected) {
        label = 'Remove';
        icon = null;
    }

    return (
        <Button
          small
          className={styles.button}
          icon={icon}
          label={label}
          disabled={isAdding}
          onMouseOver={() => setIsHovering(true)}
          onMouseOut={() => setIsHovering(false)}
          onClick={handleOnClick}
        />
    );
};

AccountAddButton.propTypes = {
    isSelected: PropTypes.bool.isRequired,
    currentAccountLimitCountAndType: customPropTypes.currentAccountLimitCountAndType.isRequired,
    accountId: customPropTypes.searchedProfile.isRequired,
    toggleListValueAction: PropTypes.func.isRequired,
    isAdding: PropTypes.bool.isRequired,
    showNotificationAction: PropTypes.func.isRequired,
    listName: PropTypes.string.isRequired
};

const makeMapStateToProps = () => {
    const selectFieldValue = makeSelectFieldValue();
    return (state, ownProps) => {
        const { accountId, listName } = ownProps;
        return {
            isSelected: selectFieldValue(state, accountId, listName),
        };
    };
};

export default connect(makeMapStateToProps, {
    toggleListValueAction: toggleListValue,
    showNotificationAction: notificationActions.showNotification
})(AccountAddButton);
