import React from 'react';
import _omit from 'lodash/omit';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/toggleSwitch.scss';

const ToggleSwitch = (props) => {
    const { disabled, error, text } = props;
    const cleanedProps = _omit(props, ['error', 'text', 'indeterminate', 'defaultIndeterminate', 'active']);
    return (
        <label
          className={classnames(
              styles.wrapper,
              { [styles.wrapperDisabled]: disabled, [styles.error]: error, [styles.hasText]: !!text }
          )}
        >
            <input
              {...cleanedProps}
              type="checkbox"
              className={styles.input}
            />
            <span className={styles.checkbox} />
            { text && <span className={styles.text}>{text}</span> }
        </label>
    );
};

ToggleSwitch.propTypes = {
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

ToggleSwitch.defaultProps = {
    error: false,
    disabled: false,
    text: ''
};

export default ToggleSwitch;
