import CopyToClipboard from 'src/components/CopyToClipboard';
import ExternalLink from 'src/components/ExternalLink';
import { Text } from 'src/components/forms/inputs';
import React from 'react';
import styles from 'src/stylesheets/linkTextWithActions.scss';
import PropTypes from 'prop-types';

const LinkTextWithActions = ({ description, link, showVisitLink }) => (
    <div>
        {description
            && (
            <div className={styles.description}>
                { description }
            </div>
            )}
        <div className={styles.linkWrapper}>
            <div className={styles.textInput}>
                <Text defaultValue={link} readOnly />
            </div>
            <div className={styles.links}>
                { showVisitLink && <ExternalLink link={link} text="Visit link" icon="vist-page" className={styles.visitLink} /> }
                <CopyToClipboard link={link} label="Copy link" />
            </div>
        </div>
    </div>
);

LinkTextWithActions.propTypes = {
    description: PropTypes.node,
    link: PropTypes.string.isRequired,
    showVisitLink: PropTypes.bool
};

LinkTextWithActions.defaultProps = {
    showVisitLink: true
};

export default LinkTextWithActions;
