import React, { forwardRef, useState } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/profileSelectorLink.scss';
import Checkbox from 'src/components/forms/inputs/Checkbox';
import { Highlighter } from 'react-bootstrap-typeahead';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import Button from 'src/components/buttons/Button';
import withTooltip from 'src/components/withToolTip';

const ButtonWithTooltip = withTooltip(Button);

const ProfileSelectorGroupLink = forwardRef((props, ref) => {
    const {
        label, active, onClick, group, selected, highlight, onExpandClick, onOnlyClick
    } = props;

    const [hover, setHover] = useState(false);
    const itemCount = group.profileIds.length + group.adAccountIds.length;
    return (
        <a
          href="#"
          onClick={(event) => { onClick(event); event.preventDefault(); }}
          className={classnames(styles.link, { [styles.active]: active })}
          ref={ref}
          onMouseEnter={() => { setHover(true); }}
          onMouseLeave={() => { setHover(false); }}
        >
            <div className={styles.checkbox}>
                <Checkbox readOnly checked={selected} />
            </div>
            <IcomoonIcon icon="folder" className={classnames(styles.icon, styles.group)} />

            <div className={styles.ellipsisWrapper}>
                <DivWithTooltip
                  tooltip={label}
                  className={styles.name}
                >
                    <span dir="auto">
                        <Highlighter search={highlight}>{label}</Highlighter>
                    </span>
                </DivWithTooltip>
            </div>
            {!hover
                    && (
                    <div
                      className={styles.itemCount}
                    >
                        {`(${itemCount})`}
                    </div>
                    )}
            {
                hover
                && (
                    <div className={styles.hoverMenu}>
                        <ButtonWithTooltip
                          tooltip="Select accounts in this group"
                          disabled={itemCount < 1}
                          icon="expand"
                          small
                          onClick={(event) => { onExpandClick(group.profileIds, group.adAccountIds); event.stopPropagation(); event.preventDefault(); }}
                        />
                        <Button small label="Only" onClick={() => { onOnlyClick('group', group.id); }} />
                    </div>
                )
            }
        </a>
    );
});

ProfileSelectorGroupLink.propTypes = {
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
    label: PropTypes.string.isRequired,
    group: customPropTypes.group.isRequired,
    selected: PropTypes.bool,
    onExpandClick: PropTypes.func.isRequired,
    onOnlyClick: PropTypes.func.isRequired,
    highlight: PropTypes.string
};

ProfileSelectorGroupLink.defaultProps = {
    active: false,
    selected: false,
    highlight: ''
};

export default ProfileSelectorGroupLink;
