import React, { Component } from 'react';
import _isEqual from 'lodash/isEqual';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/list.scss';

const batchSize = 12;

const hasMoreAccounts = (accountIdsInState, accountIdsInProps) => accountIdsInState.length + 1 < accountIdsInProps.length;

class AccountList extends Component {
    constructor(props) {
        super(props);
        this.updateAccountIds = this.updateAccountIds.bind(this);
        const { accountIds } = this.props;
        this.state = {
            accountIds: accountIds.slice(0, batchSize),
            hasMore: hasMoreAccounts([], accountIds)
        };
    }

    componentDidMount() {
        this.updateAccountIds();
    }

    componentDidUpdate() {
        const { hasMore } = this.state;
        if (hasMore) {
            this.updateAccountIds();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { accountIds: accountIdsFromState } = prevState;
        const { accountIds: accountIdsFromProps } = nextProps;
        if (prevState.hasMore === false) {
            if (!_isEqual(accountIdsFromState, accountIdsFromProps)) {
                return {
                    accountIds: accountIdsFromProps.slice(0, batchSize),
                    hasMore: hasMoreAccounts([], accountIdsFromProps)
                };
            }
            return null;
        }
        return null;
    }

    updateAccountIds() {
        setTimeout(() => {
            const { accountIds: accountIdsFromState } = this.state;
            const { accountIds: accountIdsFromProps } = this.props;
            this.setState((prev, props) => ({
                accountIds: props.accountIds.slice(0, prev.accountIds.length + batchSize),
                hasMore: hasMoreAccounts(accountIdsFromState, accountIdsFromProps)
            }));
        }, 0);
    }

    render() {
        const {
            border,
            accountIds: accountIdsProps,
            renderAccountItem,
            hideLastItemBorder
        } = this.props;
        const itemCount = accountIdsProps.length;
        const { accountIds: accountIdsState } = this.state;
        const height = (itemCount * 53) - 1;

        return (
            <ul style={{ height }} className={classnames(styles.list, styles.profile, { [styles.border]: border })}>
                {
                    accountIdsState.map((accountId, i) => {
                        const isLastItem = (i + 1) === itemCount;
                        return (
                            <li
                              key={`profileListItem-${accountId}`}
                              className={classnames(styles.listItemWrapper, { [styles.last]: isLastItem && hideLastItemBorder })}
                            >
                                {
                                    renderAccountItem(accountId)
                                }
                            </li>
                        );
                    })
                }
            </ul>
        );
    }
}

AccountList.propTypes = {
    border: PropTypes.bool,
    accountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    renderAccountItem: PropTypes.func.isRequired,
    hideLastItemBorder: PropTypes.bool
};

AccountList.defaultProps = {
    border: true,
    hideLastItemBorder: true
};

export default AccountList;
