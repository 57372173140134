import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import classnames from 'classnames';
import NetworkIcon from 'src/components/NetworkIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/popovers/graphAuthUserConnectionWarningPopover.scss';

const GraphAuthUserConnectionWarningPopoverContent = (props) => {
    const {
        title, description, facebookProfiles, instagramProfiles
    } = props;

    return (
        <div className={styles.content}>
            <div className={styles.description}>
                <div className={styles.title}>
                    {title}
                </div>
                <div className={styles.text}>
                    {description}
                </div>
                <div className={styles.button}>
                    <a href="https://www.facebook.com/settings?tab=business_tools" target="_blank" rel="noopener noreferrer">
                        <Button label="Visit Facebook app settings" small stretch icon="vist-page" />
                    </a>
                </div>
            </div>
            <div className={styles.profiles}>
                <div className={styles.network}>
                    <NetworkIcon platformType="facebook" className={styles.icon} />
                    <div className={styles.profileList}>
                        {
                            facebookProfiles.map((facebookProfile) => (
                                <div key={facebookProfile.id}>{facebookProfile.defaultName}</div>
                            ))
                        }
                        {
                            facebookProfiles.length === 0
                            && <div className={styles.none}>None</div>
                        }
                    </div>
                </div>
                <div className={classnames(styles.network, styles.instagram)}>
                    <NetworkIcon platformType="instagram" className={styles.icon} />
                    <div className={styles.profileList}>
                        {
                            instagramProfiles.map((instagramProfile) => (
                                <div key={instagramProfile.id}>{instagramProfile.defaultName}</div>
                            ))
                        }
                        {
                            instagramProfiles.length === 0
                            && <div className={styles.none}>None</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

GraphAuthUserConnectionWarningPopoverContent.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    facebookProfiles: customPropTypes.missingAndAdditionalProfiles,
    instagramProfiles: customPropTypes.missingAndAdditionalProfiles,
};

GraphAuthUserConnectionWarningPopoverContent.defaultProps = {
    facebookProfiles: [],
    instagramProfiles: []
};

export default GraphAuthUserConnectionWarningPopoverContent;
