import PropTypes from 'prop-types';
import React from 'react';
import { RadioList } from 'src/components/forms/fields';
import { Field, formValueSelector } from 'redux-form';
import { googleBigQueryType, awsS3Type } from 'src/utils/dataPushTasks';
import GoogleBigQueryDataPushTaskFields from 'src/components/forms/dataPushTasks/GoogleBigQueryDataPushTaskFields';
import AWSS3DataPushTaskFields from 'src/components/forms/dataPushTasks/AWSS3DataPushTaskFields';
import { connect } from 'react-redux';
import googleBigQueryImage from 'src/resources/illustrations/google_bigquery.png';
import awsS3Image from 'src/resources/illustrations/aws_s3.png';
import styles from 'src/stylesheets/forms/dataPushTaskForm.scss';

const destinationTypeOptions = [
    { id: googleBigQueryType, label: 'Google BigQuery', image: googleBigQueryImage },
    { id: awsS3Type, label: 'AWS S3', image: awsS3Image }
];

const DataPushTaskDestinationFormFields = (props) => {
    const { destinationType, form, isAuthFileRequired } = props;
    return (
        <>
            <div className={styles.info}>
                <div className={styles.title}>
                    Where would you like to push the data?
                </div>
                <div className={styles.description}>
                    The required fields differ slightly depending on which service you use.
                </div>
            </div>
            <div className={styles.destinationOptions}>
                <Field
                  name="destinationType"
                  component={RadioList}
                  defaultChecked={googleBigQueryType}
                  options={destinationTypeOptions}
                />
            </div>
            {
                destinationType === googleBigQueryType && <GoogleBigQueryDataPushTaskFields form={form} isAuthFileRequired={isAuthFileRequired} />
            }
            {
                destinationType === awsS3Type && <AWSS3DataPushTaskFields />
            }
        </>
    );
};

DataPushTaskDestinationFormFields.propTypes = {
    form: PropTypes.string.isRequired,
    destinationType: PropTypes.string.isRequired,
    isAuthFileRequired: PropTypes.bool
};

DataPushTaskDestinationFormFields.defaultProps = {
    isAuthFileRequired: true
};

const makeMapStateToProps = () => (state, ownProps) => {
    const selector = formValueSelector(ownProps.form);
    return {
        destinationType: selector(state, 'destinationType')
    };
};

export default connect(makeMapStateToProps)(DataPushTaskDestinationFormFields);
