import React, { useEffect } from 'react';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/postDetailModalScroller.scss';
import withTooltip from 'src/components/withToolTip';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const PostDetailModalScroller = (props) => {
    const {
        modalShowPostDetailAction,
        modalHidePostDetailAction
    } = props;

    const handleClickAction = (postId) => {
        modalHidePostDetailAction();
        modalShowPostDetailAction(postId);
    };

    const handleKeyDown = (event) => {
        const left = 37;
        const right = 39;
        const { prevPostId, nextPostId } = props;

        if (prevPostId && event.keyCode === left) {
            handleClickAction(prevPostId);
        }
        if (nextPostId && event.keyCode === right) {
            handleClickAction(nextPostId);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false);
        return () => {
            document.removeEventListener('keydown', handleKeyDown, false);
        };
    });

    const { prevPostId, nextPostId } = props;

    return (
        <div className={styles.wrapper}>
            {
                prevPostId
                && (
                    <IcomoonIconWithTooltip
                      tooltip={<>Previous post [ &larr; left arrow]</>}
                      icon="chevron-big-left"
                      onClick={() => { handleClickAction(prevPostId); }}
                      className={styles.iconEnabled}
                    />
                )
            }
            {
                !prevPostId && (
                    <IcomoonIconWithTooltip
                      icon="chevron-big-left"
                      className={styles.iconDisabled}
                      tooltip="No previous post"
                    />
                )
            }
            {
                nextPostId
                && (
                    <IcomoonIconWithTooltip
                      tooltip={<>Next post [ &rarr; right arrow]</>}
                      icon="chevron-big-right"
                      onClick={() => { handleClickAction(nextPostId); }}
                      className={styles.iconEnabled}
                    />
                )
            }
            {
                !nextPostId && (
                    <IcomoonIconWithTooltip
                      icon="chevron-big-right"
                      className={styles.iconDisabled}
                      tooltip="No next post"
                    />
                )
            }
        </div>
    );
};

PostDetailModalScroller.propTypes = {
    nextPostId: PropTypes.string,
    prevPostId: PropTypes.string,
    modalShowPostDetailAction: PropTypes.func.isRequired,
    modalHidePostDetailAction: PropTypes.func.isRequired
};

export default PostDetailModalScroller;
