import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { makeSelectOnlyProfileById } from 'src/selectors/profiles';
import ProfileInfoListItem from 'src/components/views/profiles/ProfileInfoListItem';
import PropTypes from 'prop-types';
import React from 'react';
import AuthTransactionRequirementsRow from 'src/components/authTransactions/table/AuthTransactionRequirementsRow';

const ProfileAuthTransactionRequirementsRow = (props) => {
    const {
        requirements,
        profile,
        platformUseCases,
        connectButton
    } = props;
    return (
        <AuthTransactionRequirementsRow
          accountInfo={<ProfileInfoListItem profile={profile} />}
          requirements={requirements}
          platformUseCases={platformUseCases}
          connectButton={connectButton}
        />
    );
};

ProfileAuthTransactionRequirementsRow.propTypes = {
    profileId: PropTypes.string.isRequired,
    profile: customPropTypes.profile.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired,
    connectButton: PropTypes.node,
    requirements: customPropTypes.profileAuthTransactionRequirements.isRequired
};

ProfileAuthTransactionRequirementsRow.defaultProps = {
    connectButton: null
};

const makeMapStateToProps = () => {
    const selectOnlyProfileById = makeSelectOnlyProfileById();
    return (state, ownProps) => ({
        profile: selectOnlyProfileById(state, ownProps.profileId)
    });
};

export default connect(makeMapStateToProps)(ProfileAuthTransactionRequirementsRow);
