import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/toggleableDescription.scss';

const ToggleableDescription = (props) => {
    const { children } = props;
    return (
        <div className={styles.toggleableDescriptionWrapper}>
            {children}
        </div>
    );
};

ToggleableDescription.propTypes = {
    children: PropTypes.node.isRequired
};

export default ToggleableDescription;
