import React from 'react';
import Feedback from 'src/components/feedback/Feedback';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/views/profiles/search/failedLinksWarning.scss';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import ExpandableContent from 'src/components/ExpandableContent';
import CopyToClipboard from 'src/components/CopyToClipboard';
import { getValidAndInvalidLinks } from 'src/utils/profileSearch';

const renderContent = (links, topInfo, bottomInfo) => {
    const { validLinks, inValidLinks } = getValidAndInvalidLinks(links);
    return (
        <div className={styles.wrapper}>
            <div className={styles.infoWrapper}>
                <div className={styles.info}>{topInfo}</div>
                <div className={styles.copy}>
                    <CopyToClipboard link={links.join('\n')} label="Copy URLs" icon="" className={styles.link} />
                </div>
            </div>
            {
                validLinks.length > 0 && (
                    <div className={styles.links}>
                        <b>{`URL${validLinks.length > 1 ? 's' : ''}:`}</b>
                        {
                            validLinks.map((link) => (
                                <a key={link} className={styles.link} href={link} target="_blank" rel="noopener noreferrer">
                                    <DivWithTooltip tooltip={link}>{link}</DivWithTooltip>
                                </a>
                            ))
                        }
                    </div>
                )
            }
            {
                inValidLinks.length > 0 && (
                    <div className={styles.links}>
                        <b>{`Invalid URL${inValidLinks.length > 1 ? 's' : ''}:`}</b>
                        {
                            inValidLinks.map((link) => (
                                <DivWithTooltip key={link} tooltip={link}>{link}</DivWithTooltip>
                            ))
                        }
                    </div>
                )
            }
            {
                bottomInfo && (
                    <div>To upgrade your profile limit, contact us via live chat or support@facelift-bbt.com</div>
                )
            }
        </div>
    );
};

const SearchedProfileWarnings = (props) => {
    const { links, topInfo, bottomInfo } = props;

    const content = (
        <ExpandableContent
          renderContent={() => renderContent(links, topInfo, bottomInfo)}
          minHeight={150}
          expandButtonClassName={styles.expandButton}
        />
    );
    return (
        <Feedback type="warning" content={content} />
    );
};

SearchedProfileWarnings.propTypes = {
    links: PropTypes.arrayOf(PropTypes.string).isRequired,
    topInfo: PropTypes.string.isRequired,
    bottomInfo: PropTypes.string
};

export default SearchedProfileWarnings;
