import { getAuthTransactionIconFromState, getAuthTransactionLabelFromStateNew, isAuthTransactionOpen } from 'src/utils/authTransactionUtils';
import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/authTransactions/authTransactionState.scss';
import withToolTip from 'src/components/withToolTip';

const IcomoonIconWithToolTip = withToolTip(IcomoonIcon);

const AuthTransactionState = (props) => {
    const {
        isOpen,
        validUntil,
        isInProgress,
        isFulfilled,
        className,
        showLabel
    } = props;

    const isAuthOpen = isAuthTransactionOpen(isOpen, validUntil);
    const icon = getAuthTransactionIconFromState(isAuthOpen, isInProgress, isFulfilled);

    const classNames = isAuthOpen
        ? {
            [styles.isPending]: !isInProgress,
            [styles.isFulfilled]: (isInProgress && isFulfilled),
            [styles.isPendingUnfulfilled]: (isInProgress && !isFulfilled)
        }
        : {
            [styles.isFulfilled]: isFulfilled,
            [styles.isUnfulfilled]: !isFulfilled
        };

    const label = getAuthTransactionLabelFromStateNew(isOpen, validUntil, isInProgress, isFulfilled);
    return (
        <div className={styles.wrapper}>
            <IcomoonIconWithToolTip
              icon={icon}
              tooltip={label}
              className={classnames(classNames, className)}
            />
            {
                showLabel
                && <div className={styles.label}>{label}</div>
            }
        </div>
    );
};

AuthTransactionState.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isInProgress: PropTypes.bool.isRequired,
    isFulfilled: PropTypes.bool.isRequired,
    validUntil: PropTypes.string.isRequired,
    showLabel: PropTypes.bool,
    className: PropTypes.string
};

AuthTransactionState.defaultProps = {
    className: '',
    showLabel: false
};

export default AuthTransactionState;
