import { getFormSyncErrors, isSubmitting, submit } from 'redux-form';
import { selectDashboardById } from 'src/selectors/dashboards';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import CreateReportForm from 'src/components/forms/CreateReportForm';
import { dashboardReportAddRequestFormAction } from 'src/actions/dashboardReports';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import withResourceCheck from 'src/components/modals/withResourceCheck';
import { getDashboardContext } from 'src/utils/filterSelectors';
import { dashboardReportScheduleCleanup } from 'src/actions/dashboardReportSchedule';
import { modalShowDashboardReportDelete } from 'src/actions/overlays';
import { createInitialFormValuesForNewReport } from 'src/utils/dashboardReport';
import memoizone from 'memoize-one';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import { makeSelectAdditionalFilterByContext, makeSelectProfileByContext } from 'src/selectors/filterSelectors';
import PreviewReportButton from 'src/components/buttons/PreviewReportButton';

const memoizedCreateInitialFormValuesForNewReport = memoizone(createInitialFormValuesForNewReport);

const formName = 'createReport';

const CreateReportModal = (props) => {
    const {
        submitAction,
        dashboard,
        submitting,
        accountOptions,
        additionalFilter,
        profileSelection,
        enforceFocus
    } = props;
    const initialValues = memoizedCreateInitialFormValuesForNewReport(dashboard, accountOptions, profileSelection, additionalFilter);

    const handleOnHideModal = () => {
        const { dashboardReportScheduleCleanupAction, hideModal } = props;
        dashboardReportScheduleCleanupAction();
        hideModal();
    };

    const previewButton = <PreviewReportButton formName={formName} dashboardId={dashboard.id} />;
    return (
        <Modal dataViewName="create-report-modal" onHide={handleOnHideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={handleOnHideModal}>
                    <Modal.Title>Create report: {dashboard.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body centered>
                    <CreateReportForm
                      onSubmit={dashboardReportAddRequestFormAction}
                      form={formName}
                      initialValues={initialValues}
                    />
                </Modal.Body>
                <Modal.Footer buttonsLeft={previewButton}>
                    <ButtonGroup>
                        <Button
                          disabled={submitting}
                          onClick={handleOnHideModal}
                          label="Cancel"
                        />
                        <Button
                          action
                          loading={submitting}
                          onClick={() => submitAction(formName)}
                          label="Create"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

CreateReportModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    dashboard: customPropTypes.dashboard.isRequired,
    additionalFilter: customPropTypes.additionalFilterValues.isRequired,
    profileSelection: customPropTypes.profileFilter.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired,
    submitting: PropTypes.bool,
    enforceFocus: PropTypes.bool,
    syncErrors: PropTypes.objectOf(PropTypes.string),
    dashboardReportScheduleCleanupAction: PropTypes.func.isRequired
};

CreateReportModal.defaultProps = {
    submitting: false,
    syncErrors: {}
};

const CreateReportModalWithResourceCheck = withResourceCheck(CreateReportModal, 'dashboard', 'dashboard');

const makeMapStateToProps = () => {
    const selectAdditionalFilterByContext = makeSelectAdditionalFilterByContext();
    const selectProfileByContext = makeSelectProfileByContext();

    return (state, ownProps) => {
        const { dashboardId } = ownProps;
        const filterContext = getDashboardContext(dashboardId);
        return {
            submitting: isSubmitting(formName)(state),
            dashboard: selectDashboardById(state, dashboardId),
            profileSelection: selectProfileByContext(state, filterContext),
            additionalFilter: selectAdditionalFilterByContext(state, filterContext),
            accountOptions: selectAccountOptions(state),
            syncErrors: getFormSyncErrors(formName)(state),
        };
    };
};

export default connect(makeMapStateToProps, {
    submitAction: submit,
    dashboardReportScheduleCleanupAction: dashboardReportScheduleCleanup,
    modalShowDashboardReportDeleteAction: modalShowDashboardReportDelete
})(CreateReportModalWithResourceCheck);
