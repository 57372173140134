import React from 'react';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import styles from 'src/stylesheets/views/searchedAuthAccountList.scss';
import Label from 'src/components/labels/Label';
import AdAccountInfoListItem from 'src/components/views/adAccounts/AdAccountInfoListItem';
import ProfileInfoListItem from 'src/components/views/profiles/ProfileInfoListItem';
import ButtonGroup from 'src/components/buttons/ButtonGroup';

const SearchedAuthAccountResultListItem = (props) => {
    const { searchedAccount, accountType, actionButton } = props;
    const { authUserName } = searchedAccount;
    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                {
                    accountType === 'profiles' && <ProfileInfoListItem profile={searchedAccount} />
                }
                {
                    accountType === 'adAccounts' && <AdAccountInfoListItem adAccount={searchedAccount} showThumbnail={false} />
                }
            </div>
            <div className={styles.right}>
                <div className={styles.authUserState}>
                    <div className={styles.state}><Label state="ok" /></div>
                    <div className={styles.authUserName}>
                        <DivWithTooltip tooltip={authUserName}>{authUserName}</DivWithTooltip>
                    </div>
                </div>
                <div className={styles.buttonWrapper}>
                    <ButtonGroup>
                        {
                            actionButton
                        }
                    </ButtonGroup>
                </div>
            </div>
        </div>
    );
};

SearchedAuthAccountResultListItem.propTypes = {
    accountType: customPropTypes.accountTypes.isRequired,
    actionButton: PropTypes.node.isRequired,
    searchedAccount: PropTypes.oneOfType([customPropTypes.searchedAdAccount, customPropTypes.searchedProfile]).isRequired
};

export default SearchedAuthAccountResultListItem;
