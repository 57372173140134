import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import { makeSelectActivePostTagsByIds } from 'src/selectors/postTags';
import EntityOperationInfoModal from 'src/components/modals/EntityOperationInfoModal';

const PostTagsForceDeleteModalContainer = (props) => {
    const {
        nonDeletablePostTags,
        forceDeletableTagInfos,
        hideModal,
        enforceFocus,
    } = props;
    const nonDeletablePostTagNames = nonDeletablePostTags.map((nonDeletablePostTag) => nonDeletablePostTag.name);
    return (
        <EntityOperationInfoModal
          nonOperationalEntityNames={nonDeletablePostTagNames}
          forceOperationalEntityInfos={forceDeletableTagInfos}
          operationalType="forceTagDelete"
          hideModal={hideModal}
          enforceFocus={enforceFocus}
        />
    );
};

PostTagsForceDeleteModalContainer.propTypes = {
    forceDeletableTagInfos: customPropTypes.forceOperationalEntityInfos.isRequired,
    nonDeletablePostTags: customPropTypes.postTags.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool
};

const makeMapStateToProps = () => {
    const selectActivePostTagsByIds = makeSelectActivePostTagsByIds();
    return (state, ownProps) => ({
        nonDeletablePostTags: selectActivePostTagsByIds(state, ownProps.nonDeletableTagIds),
    });
};

export default connect(makeMapStateToProps)(PostTagsForceDeleteModalContainer);
