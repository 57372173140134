import React from 'react';
import AuthUserInAnotherSpaceWarningModal from 'src/components/modals/AuthUserInAnotherSpaceWarningModal';
import { connect } from 'react-redux';
import { externalGraphForceConnectRequest } from 'src/actions/socialNetworkProfileAuthentication';
import { metaAdAccountAuthenticationForceConnectRequest } from 'src/actions/socialNetworkAdAccountAuthentication';
import * as customPropTypes from 'src/customPropTypes';

import PropTypes from 'prop-types';

const FacebookAuthUserInAnotherSpaceExternalAuthenticationWarning = (props) => {
    const handleCompleteAuthenticationButtonClick = () => {
        const {
            externalGraphForceConnectRequestAction, metaAdAccountAuthenticationForceConnectRequestAction, accessToken, accountType
        } = props;
        if (accountType === 'profiles') {
            externalGraphForceConnectRequestAction(accessToken);
        }
        if (accountType === 'adAccounts') {
            metaAdAccountAuthenticationForceConnectRequestAction(accessToken);
        }
    };

    const { hideModal, enforceFocus } = props;
    return (
        <AuthUserInAnotherSpaceWarningModal
          onCompleteAuthenticationClick={handleCompleteAuthenticationButtonClick}
          networkType="graph"
          hideModal={hideModal}
          enforceFocus={enforceFocus}
        />
    );
};

FacebookAuthUserInAnotherSpaceExternalAuthenticationWarning.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    accessToken: PropTypes.string.isRequired,
    externalGraphForceConnectRequestAction: PropTypes.func.isRequired,
    metaAdAccountAuthenticationForceConnectRequestAction: PropTypes.func.isRequired,
    accountType: customPropTypes.accountTypes.isRequired
};

export default connect(null, {
    externalGraphForceConnectRequestAction: externalGraphForceConnectRequest,
    metaAdAccountAuthenticationForceConnectRequestAction: metaAdAccountAuthenticationForceConnectRequest
})(FacebookAuthUserInAnotherSpaceExternalAuthenticationWarning);
