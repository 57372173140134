import { isSubmitting, submit } from 'redux-form';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import DataPushTaskDestinationEditForm from 'src/components/forms/dataPushTasks/DataPushTaskDestinationEditForm';
import { dataPushTaskDestinationUpdateRequestFormAction } from 'src/actions/dataPushTasks';
import { getDataPushTaskDestinationInitialValues } from 'src/utils/dataPushTasks';
import { makeSelectDataPushTaskDestinationById } from 'src/selectors/dataPushTaskDestination';
import * as customPropTypes from 'src/customPropTypes';
import memoize from 'memoize-one';

const memoizedGetDataPushTaskDestinationInitialValues = memoize(getDataPushTaskDestinationInitialValues);

const formName = 'dataPushTaskDestinationEditForm';

const EditDataPushTaskDestinationModal = (props) => {
    const {
        hideModal,
        enforceFocus,
        submitAction,
        dataPushTaskDestination,
        isUpdating
    } = props;

    const initialValues = memoizedGetDataPushTaskDestinationInitialValues(dataPushTaskDestination);
    return (
        <Modal dataViewName="add-data-push-task-destination-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Edit data push destination
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body centered>
                    <DataPushTaskDestinationEditForm
                      form={formName}
                      onSubmit={dataPushTaskDestinationUpdateRequestFormAction}
                      initialValues={initialValues}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          label="Cancel"
                          onClick={hideModal}
                          disabled={isUpdating}
                        />
                        <Button
                          label="Update"
                          action
                          onClick={() => { submitAction(formName); }}
                          loading={isUpdating}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

EditDataPushTaskDestinationModal.propTypes = {
    destinationId: PropTypes.string.isRequired,
    dataPushTaskDestination: customPropTypes.dataPushTaskDestination.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    isUpdating: PropTypes.bool.isRequired,
};

const mapStateToProps = () => {
    const selectDataPushTaskDestinationById = makeSelectDataPushTaskDestinationById();
    return (state, ownProps) => ({
        dataPushTaskDestination: selectDataPushTaskDestinationById(state, ownProps.destinationId),
        isUpdating: isSubmitting(formName)(state)
    });
};

export default connect(mapStateToProps, {
    submitAction: submit
})(EditDataPushTaskDestinationModal);
