import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import { getLocalString } from 'src/components/Date';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import { connect } from 'react-redux';
import styles from 'src/stylesheets/popovers/postPostTagsPopover.scss';

const renderInfo = (item, accountOptions) => {
    const userFormattedTime = getLocalString(item.tagTime, 'datetime', accountOptions, true);
    let content = '';
    if (item.tagUserName) {
        content = `${item.postTagName} was tagged by ${item.tagUserName} on ${userFormattedTime}.`;
    } else if (item.postTagRule) {
        content = `${item.postTagName} was automatically tagged on ${userFormattedTime}.`;
    } else if (item.postTagRuleId) {
        content = `${item.postTagName} was automatically tagged on ${userFormattedTime}`;
    }
    return content;
};

const PostPostTagsTooltip = (props) => {
    const { postPostTags, accountOptions } = props;
    return (
        <div className={styles.wrapper}>
            {
                postPostTags.map((item) => (
                    <div key={item.postTagId}>
                        {renderInfo(item, accountOptions)}
                    </div>
                ))
            }
        </div>
    );
};

PostPostTagsTooltip.propTypes = {
    postPostTags: customPropTypes.derivedPostPostTags.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired
};

const mapStateToProps = (state) => ({
    accountOptions: selectAccountOptions(state)
});

export default connect(mapStateToProps)(PostPostTagsTooltip);
