import ButtonGroup from 'src/components/buttons/ButtonGroup';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/profileSearchResultListItem.scss';

const SearchedProfileResultListItem = (props) => {
    const {
        accountInfo, actionButton, first, last
    } = props;
    return (
        <div className={classnames(styles.item, { [styles.first]: first === true }, { [styles.last]: last === true })}>
            <div className={styles.profileInfoWrapper}>
                {
                    accountInfo
                }
            </div>
            <div className={styles.buttonWrapper}>
                <ButtonGroup>
                    {actionButton}
                </ButtonGroup>
            </div>
        </div>
    );
};

SearchedProfileResultListItem.propTypes = {
    accountInfo: PropTypes.node.isRequired,
    actionButton: PropTypes.node.isRequired,
    first: PropTypes.bool,
    last: PropTypes.bool
};

SearchedProfileResultListItem.defaultProps = {
    first: false,
    last: false,
};

export default SearchedProfileResultListItem;
