import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/quickLinks.scss';
import * as customPropTypes from 'src/customPropTypes';
import QuickLink from './QuickLink';
import { getDateConfigByString, getPresets, isDynamicDateInPresets } from './utils';

const QuickLinks = (props) => {
    const handleQuickLinkClick = (inputString) => {
        const { onQuickLinkClick } = props;
        const newDate = getDateConfigByString(inputString);
        onQuickLinkClick(newDate);
    };

    const { additionalLinks, selectedDate, customInputActive } = props;

    const [lastKnownCustomDynamicDate, setLastKnownCustomDynamicDate] = useState(null);

    useEffect(() => {
        if (selectedDate.dynamicDate && selectedDate.dynamicDate !== lastKnownCustomDynamicDate && !isDynamicDateInPresets(selectedDate.dynamicDate)) {
            setLastKnownCustomDynamicDate(selectedDate.dynamicDate);
        }
    }, [selectedDate.dynamicDate]);

    const quickLinkList = getPresets();

    const quickLinks = quickLinkList.map((quickLink) => (
        <li key={quickLink.value}>
            <QuickLink
              label={quickLink.displayName}
              onClick={() => handleQuickLinkClick(quickLink.value)}
              active={!customInputActive && quickLink.value === selectedDate.dynamicDate}
            />
        </li>
    ));

    return (
        <div className={styles.quickLinks}>
            <ul className={styles.ul}>
                {
                    additionalLinks.map((additionalLink) => <li>{additionalLink}</li>)
                }
                {quickLinks}
            </ul>
        </div>
    );
};

QuickLinks.propTypes = {
    onQuickLinkClick: PropTypes.func.isRequired,
    additionalLinks: PropTypes.arrayOf(PropTypes.node),
    selectedDate: customPropTypes.selectedDate.isRequired,
    customInputActive: PropTypes.bool
};

QuickLinks.defaultProps = {
    additionalLinks: [],
    customInputActive: false
};

export default QuickLinks;
