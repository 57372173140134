import {
    MODAL_HIDE,
    MODAL_HIDE_ALL,
    MODAL_SHOW
} from 'src/actions/overlays';
import _find from 'lodash/find';
import { combineReducers } from 'redux';
import { LOCATION_CHANGE } from 'react-router-redux';

function modals(state = [], action) {
    const { payload, type } = action;
    switch (type) {
        case MODAL_SHOW: {
            const { identifier, modalType, modalProps } = payload;
            const alreadyExists = _find(state, (modal) => modal.identifier === identifier);
            if (!alreadyExists) {
                return [...state, { modalType, modalProps, identifier }];
            }
            return state;
        }

        // close all modals on location Change via browser history (action === POP)
        case LOCATION_CHANGE: {
            if (payload.action !== 'REPLACE' && state.length > 0) {
                return [];
            }
            return state;
        }
        case MODAL_HIDE: {
            const { identifier } = payload;
            return state.filter((value) => value.identifier !== identifier);
        }

        case MODAL_HIDE_ALL: {
            return [];
        }

        default: {
            return state;
        }
    }
}

export const overlays = combineReducers({
    modals
});
