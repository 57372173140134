import { takeEvery } from 'redux-saga/effects';
import {
    PREPARE_GOOGLE_TAG_MANAGER
} from 'src/actions/appEvents';

/* eslint-disable */
function* addGoogleTagManager(action) {
    const { payload } = action;
    const { isAgent, } = payload;
    if (!isAgent) {
        // add codeSnippet in a script tag inside head
        const script = document.createElement('script');
        const codeSnippet = `(${function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        }})(window,document,'script','dataLayer','GTM-K9FMK3R');`;

        script.appendChild(document.createTextNode(codeSnippet));
        document.head.appendChild(script);
    } else {
        window.dataLayer = [];
    }

    yield;
}

/* eslint-enable */

export default function* googleTagManagerSagas() {
    yield takeEvery(PREPARE_GOOGLE_TAG_MANAGER, addGoogleTagManager);
}
