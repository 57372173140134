import { makeSelectFoldersByFolderIds } from 'src/selectors/folders';
import * as customPropTypes from 'src/customPropTypes';
import BreadcrumbFoldedIcon from 'src/components/breadcrumb/BreadcrumbFoldedIcon';
import BreadcrumbItem from 'src/components/breadcrumb/BreadcrumbItem';
import BreadcrumbPopover from 'src/components/popovers/BreadcrumbPopover';
import classnames from 'classnames';
import { connect } from 'react-redux';
import MenuButton from 'src/components/buttons/MenuButton';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/breadcrumb.scss';
import withPopover from 'src/components/withPopover';

import { makeSelectParentFolderIdsOfFolderId, selectFolderTypeById } from 'src/selectors/ownership';

const BreadcrumbFoldedIconWithPopover = withPopover(BreadcrumbFoldedIcon);

const Breadcrumb = (props) => {
    // build items in the breadcrumb based on the current folder
    const {
        parentFolders, onItemClick, small, fixedItemBefore
    } = props;
    const items = (fixedItemBefore ? [fixedItemBefore, ...parentFolders] : parentFolders).map((item) => ({
        id: item.id,
        title: item.name,
        onClick: () => { onItemClick(item.id, item.name); }
    }));

    // if there is too many items, we want to fold the "inner" items (2nd to n-1) into a popover for responsive reasons
    const itemCount = items.length;
    const slicedItems = items.slice(1, itemCount - 1);
    const menuButtons = slicedItems.map((item) => <MenuButton key={item.id} onClick={item.onClick} label={item.title} />);
    const innerItemsPopover = <BreadcrumbPopover items={menuButtons} />;

    return (
        <div className={classnames(styles.wrapper, { [styles.small]: small })}>
            {
                (itemCount <= 3)
                && (
                <ul className={styles.list}>
                    {
                        items.map((item, i) => {
                            const isLastItem = (i + 1) === itemCount;
                            return (
                                <li className={styles.itemWrapper} key={item.id}>
                                    <BreadcrumbItem title={item.title} onClick={item.onClick} last={isLastItem} />
                                </li>
                            );
                        })
                    }
                </ul>
                )
            }
            {
                (itemCount > 3)
                    && (
                    <ul className={styles.list}>
                        <li className={styles.itemWrapper}>
                            <BreadcrumbItem title={items[0].title} onClick={items[0].onClick} />
                        </li>
                        <li className={styles.itemWrapper}>
                            <BreadcrumbFoldedIconWithPopover
                              overlay={innerItemsPopover}
                              placement="bottom-start"
                              className={styles.foldedLink}
                              small={small}
                            />
                        </li>
                        <li className={styles.itemWrapper}>
                            <BreadcrumbItem title={items[itemCount - 1].title} onClick={items[itemCount - 1].onClick} last />
                        </li>
                    </ul>
                    )
            }
        </div>
    );
};

Breadcrumb.propTypes = {
    parentFolders: customPropTypes.folders.isRequired,
    onItemClick: PropTypes.func.isRequired,
    small: PropTypes.bool,
    fixedItemBefore: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    })
};

Breadcrumb.defaultProps = {
    small: false
};

const makeMapStateToProps = () => {
    const selectFoldersByFolderIds = makeSelectFoldersByFolderIds();
    const selectParentFolderIdsOfFolderId = makeSelectParentFolderIdsOfFolderId();

    return (state, ownProps) => {
        const { currentFolderId } = ownProps;
        const type = selectFolderTypeById(state, currentFolderId);
        const parentFolderIds = selectParentFolderIdsOfFolderId(state, currentFolderId, type);

        return {
            parentFolders: selectFoldersByFolderIds(state, parentFolderIds)
        };
    };
};

const ConnectedBreadcrumb = connect(makeMapStateToProps)(Breadcrumb);

ConnectedBreadcrumb.propTypes = {
    currentFolderId: PropTypes.string.isRequired,
};

export default ConnectedBreadcrumb;
