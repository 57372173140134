import React from 'react';
import ThreadsConnectButtonAndState from 'src/components/authTransactions/ThreadsConnectButtonAndState';
import * as customPropTypes from 'src/customPropTypes';
import * as threadsUserActions from 'src/actions/threadsAuthUsers';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AuthTransactionRequirementsTable from 'src/components/authTransactions/table/AuthTransactionRequirementsTable';
import _memoize from 'memoize-one';

const ThreadsAuthTransactionRequirementsConnect = (props) => {
    const {
        authTransaction,
        authTransactionRequirementsByProfileId,
        isPublic,
        showOnlyBasicInsightsUseCases
    } = props;

    const handleTiktokConnectRequest = (identifier, authTransactionId, authTransactionRequirements, profile) => {
        const { threadsAuthUserConnectRequestAction, threadsAuthUserExternalConnectRequestAction } = props;
        if (isPublic) {
            threadsAuthUserExternalConnectRequestAction(authTransaction.hash, identifier, authTransactionId, authTransactionRequirements, profile);
        } else {
            threadsAuthUserConnectRequestAction(identifier, authTransactionId, authTransactionRequirements, profile);
        }
    };

    const connectButtonPerProfileId = _memoize(() => {
        const buttons = [];
        Object.keys(authTransactionRequirementsByProfileId).forEach((profileId) => {
            buttons[profileId] = (
                <ThreadsConnectButtonAndState
                  key={profileId}
                  profileId={profileId}
                  authTransaction={authTransaction}
                  connectAction={handleTiktokConnectRequest}
                  authTransactionRequirements={authTransactionRequirementsByProfileId[profileId]}
                />
            );
        });
        return buttons;
    });

    return (
        <AuthTransactionRequirementsTable
          platformType="threads"
          authTransactionRequirementsByProfileId={authTransactionRequirementsByProfileId}
          connectButtonsByAccountId={connectButtonPerProfileId()}
          showOnlyBasicInsightsUseCases={showOnlyBasicInsightsUseCases}
        />
    );
};

ThreadsAuthTransactionRequirementsConnect.propTypes = {
    threadsAuthUserConnectRequestAction: PropTypes.func.isRequired,
    threadsAuthUserExternalConnectRequestAction: PropTypes.func.isRequired,
    authTransaction: customPropTypes.authTransaction.isRequired,
    authTransactionRequirementsByProfileId: PropTypes.objectOf(customPropTypes.profileAuthTransactionRequirements).isRequired,
    isPublic: PropTypes.bool.isRequired,
    showOnlyBasicInsightsUseCases: PropTypes.bool.isRequired
};

export default connect(null, {
    threadsAuthUserConnectRequestAction: threadsUserActions.threadsAuthUserConnectRequest,
    threadsAuthUserExternalConnectRequestAction: threadsUserActions.threadsAuthUserExternalConnectRequest,
})(ThreadsAuthTransactionRequirementsConnect);
