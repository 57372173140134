import { checkAndScrollIntoView } from 'src/components/withScrollIntoViewOfScrollParent';
import { reduxForm } from 'redux-form';
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

const defaultParams = {
    touchOnBlur: false,
};

export default (WrappedComponent, params = {}) => {
    class WithReduxForm extends Component {
        constructor(props) {
            super(props);
            this.ref = createRef();
        }

        componentDidUpdate(prevProps) {
            const { submitFailed } = this.props;
            // This only triggers once on transition to prevent jump effects if already jumped and something else causes the form to re-render
            if (prevProps.submitFailed === false && submitFailed === true) {
                // this is not the best way but in this case okay cause otherwise we would need to find a way to register all refs of form fields
                const elements = document.getElementsByClassName('form-error-global');
                if (elements.length > 0) {
                    checkAndScrollIntoView(elements[0]);
                }
            }
        }

        render() {
            return (
                <WrappedComponent {...this.props} />
            );
        }
    }

    WithReduxForm.propTypes = {
        submitFailed: PropTypes.bool.isRequired
    };

    return reduxForm(Object.assign({}, defaultParams, params))(WithReduxForm);
};
