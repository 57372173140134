import React from 'react';
import ListTableCell from 'src/components/listTable/ListTableCell';
import ListTableRow from 'src/components/listTable/ListTableRow';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import dashboardShareLinkStyle from 'src/stylesheets/dashboardShareLinks/dashboardShareLinksTable.scss';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import Date from 'src/components/Date';
import PropTypes from 'prop-types';
import { modalShowEditShareableLink, modalShowDashboardShareLinkDelete } from 'src/actions/overlays';
import IconButton from 'src/components/buttons/IconButton';
import withToolTip from 'src/components/withToolTip';
import withCopyToClipboard from 'src/components/withCopyToClipboard';
import AbsoluteSelectedDateLabel from 'src/components/dateSelector/AbsoluteSelectedDateLabel';
import ProfileSelection from 'src/components/dashboardShareLinks/ProfileSelection';
import classnames from 'classnames';
import { isDashboardShareLinkExpired } from 'src/utils/dashboardShareLink';
import DashboardShareLinkStatusLabel from 'src/components/labels/DashboardShareLinkStatusLabel';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import RelativeSelectedDateLabel from 'src/components/dateSelector/RelativeSelectedDateLabel';

const IconButtonWithTooltip = withToolTip(IconButton);
const IconButtonWithTooltipWithCopyToClipboard = withToolTip(withCopyToClipboard(IconButton));

const DashboardShareLinksTableRow = (props) => {
    const { dashboardShareLink, modalShowDashboardShareLinkDeleteAction } = props;
    const {
        id, createTime, createdByUserName, link, dateSelection, profileSelection, hasExpiry, validUntil
    } = dashboardShareLink;
    const isExpired = isDashboardShareLinkExpired(dashboardShareLink);

    const handleEditAction = () => {
        const { modalShowEditShareableLinkAction } = props;
        modalShowEditShareableLinkAction(id);
    };

    return (
        <ListTableRow className={dashboardShareLinkStyle.row}>
            <ListTableCell disabled={isExpired} onClick={handleEditAction}><Date date={createTime} /></ListTableCell>
            <ListTableCell disabled={isExpired} onClick={handleEditAction}>
                <DivWithTooltip tooltip={createdByUserName}>
                    {createdByUserName}
                </DivWithTooltip>
            </ListTableCell>
            <ListTableCell disabled={isExpired} onClick={handleEditAction}>
                <ProfileSelection selectedProfilesOrGroups={profileSelection} />
            </ListTableCell>
            <ListTableCell disabled={isExpired} onClick={handleEditAction}>
                {
                    dateSelection.dynamicDate
                        ? <RelativeSelectedDateLabel selectedDate={dateSelection} />
                        : <AbsoluteSelectedDateLabel selectedDate={dateSelection} hideInterval />
                }
            </ListTableCell>
            <ListTableCell rightContent={(
                <div className={dashboardShareLinkStyle.actions}>
                    <div className={dashboardShareLinkStyle.copy}>
                        <IconButtonWithTooltipWithCopyToClipboard
                          tooltip="Copy"
                          icon="link"
                          link={link}
                          iconClassName={dashboardShareLinkStyle.icon}
                        />
                    </div>
                    <div className={dashboardShareLinkStyle.edit}>
                        <IconButtonWithTooltip
                          tooltip="Edit"
                          icon="edit"
                          onClick={handleEditAction}
                          iconClassName={dashboardShareLinkStyle.icon}
                        />
                    </div>
                    <IconButtonWithTooltip
                      tooltip="Delete"
                      icon="delete"
                      onClick={() => { modalShowDashboardShareLinkDeleteAction(id); }}
                      iconClassName={classnames(dashboardShareLinkStyle.icon, dashboardShareLinkStyle.delete)}
                    />
                </div>
            )}
            >
                <div className={dashboardShareLinkStyle.expiryAndActions}>
                    <div className={classnames(dashboardShareLinkStyle.expiry, { [dashboardShareLinkStyle.disabled]: isExpired })}>
                        <DashboardShareLinkStatusLabel validUntil={validUntil} hasExpiry={hasExpiry} />
                    </div>
                </div>
            </ListTableCell>
        </ListTableRow>
    );
};

DashboardShareLinksTableRow.propTypes = {
    dashboardShareLink: customPropTypes.dashboardShareLink.isRequired,
    modalShowDashboardShareLinkDeleteAction: PropTypes.func.isRequired,
    modalShowEditShareableLinkAction: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    accountOptions: customPropTypes.accountOptions.isRequired
};

const makeMapStateToProps = () => (state) => ({
    accountOptions: selectAccountOptions(state)
});

export default connect(makeMapStateToProps, {
    modalShowEditShareableLinkAction: modalShowEditShareableLink,
    modalShowDashboardShareLinkDeleteAction: modalShowDashboardShareLinkDelete
})(DashboardShareLinksTableRow);
