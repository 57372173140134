import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/modal.scss';

const ModalDialog = (props) => {
    const {
        fullscreen, width, className, children
    } = props;

    const elementStyle = {};
    if (!fullscreen) {
        elementStyle.minWidth = 480;
        if (width) {
            elementStyle.width = width;
        }
    }

    return (
        <div
          role="document"
          tabIndex="-1"
          className={classnames(styles.dialog, { [styles.fullscreen]: fullscreen }, className)}
          style={elementStyle}
        >
            {children}
        </div>
    );
};

ModalDialog.propTypes = {
    children: customPropTypes.children.isRequired,
    fullscreen: PropTypes.bool,
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]) // just set to false in case you want the width to be set according to the inner content width
};

ModalDialog.defaultProps = {
    fullscreen: false,
    className: '',
    width: 480 // this is the default we use for the majority of modals
};

export default ModalDialog;
