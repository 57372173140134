import React from 'react';
import DynamicDateSelectorView from 'src/components/dateSelector/DynamicDateSelectorView';
import PropTypes from 'prop-types';

const DynamicDateSelector = (props) => {
    const {
        onBlur, onFocus, name, value, onChange, small, labelIfNull, disabled, stretch, leftCurved, rightCurved
    } = props;
    return (
        <div>
            <DynamicDateSelectorView
              onHide={onBlur}
              onDateChange={onChange}
              onShow={onFocus}
              selectedDate={value}
              small={small}
              labelIfNull={labelIfNull}
              disabled={disabled}
              stretch={stretch}
              leftCurved={leftCurved}
              rightCurved={rightCurved}
            />
            <input type="hidden" name={name} value={value} />
        </div>
    );
};

DynamicDateSelector.propTypes = {
    small: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    labelIfNull: PropTypes.string,
    stretch: PropTypes.bool,
    leftCurved: PropTypes.bool,
    rightCurved: PropTypes.bool
};

DynamicDateSelector.defaultProps = {
    small: false,
    disabled: false,
    error: false,
    labelIfNull: '',
    stretch: true,
    leftCurved: false,
    rightCurved: false
};

export default DynamicDateSelector;
