import Button from 'src/components/buttons/Button';
import ChangeEmailForm from 'src/components/forms/ChangeEmailForm';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { selectLoggedInUser } from 'src/selectors/users';
import * as customPropTypes from 'src/customPropTypes';
import { submit, isSubmitting } from 'redux-form';
import { changeEmailFormAction } from 'src/actions/loggedInUser';

const EmailChangeModal = (props) => {
    const {
        hideModal, enforceFocus, loggedInUser, isLoading, submitForm, connection, userId
    } = props;

    const initialValues = {
        currentEmail: loggedInUser.email,
        connection,
        userId,
        newEmail: ''
    };

    return (
        <Modal dataViewName="email-change-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Change Email
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ChangeEmailForm form="changeEmail" onSubmit={changeEmailFormAction} initialValues={initialValues} />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button onClick={hideModal} label="Close" />
                        <Button onClick={() => submitForm('changeEmail')} label="Save" action loading={isLoading} />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

EmailChangeModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    loggedInUser: customPropTypes.loggedInUser.isRequired,
    isLoading: PropTypes.bool,
    submitForm: PropTypes.bool,
    connection: PropTypes.string,
    userId: PropTypes.string
};

const mapStateToProps = (state) => ({
    loggedInUser: selectLoggedInUser(state),
    isLoading: isSubmitting('changeEmail')(state)
});

export default connect(mapStateToProps, { submitForm: submit })(EmailChangeModal);
