import { usePopper } from 'react-popper';
import React, { Component, useRef } from 'react';
import _omit from 'lodash/omit';
import contains from 'dom-helpers/contains';
import modalManager from 'src/components/modals/layout/modalManager';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import useRootClose from 'react-overlays/useRootClose';
import styles from 'src/stylesheets/popper.scss';
import _uniqueId from 'lodash/uniqueId';
import _get from 'lodash/get';
import * as customPropTypes from 'src/customPropTypes';

const triggerType = PropTypes.oneOf(['click', 'hover', 'focus', 'clickShow', 'spacebar']);

const mergeRefs = (refs) => (value) => {
    refs.forEach((ref) => {
        if (typeof ref === 'function') {
            ref(value);
        } else if (ref != null) {
            Object.assign(ref, { current: value });
        }
    });
};

function isOneOf(one, of) {
    if (Array.isArray(of)) {
        return of.indexOf(one) >= 0;
    }
    return one === of;
}
const ENTER = 13;
const SPACEBAR = 32;
const TAB = 9;

export const createChainedFunction = (...funcs) => (
    funcs
        .filter((f) => f != null)
        .reduce((acc, f) => {
            if (typeof f !== 'function') {
                throw new Error('Invalid argument type, must only provide functions, undefined, or null.');
            }

            if (acc === null) {
                return f;
            }

            return function chainedFunction(...args) {
                acc.apply(this, args);
                f.apply(this, args);
            };
        }, null)
);

// Simple implementation of mouseEnter and mouseLeave.
// React's built version is broken: https://github.com/facebook/react/issues/4251
// for cases when the trigger is disabled and mouseOut/Over can cause flicker
// moving from one child element to another.
const handleMouseOverOut = (handler, e) => {
    const target = e.currentTarget;
    const related = e.relatedTarget || e.nativeEvent.toElement;

    if (!related || (related !== target && !contains(target, related))) {
        handler(e);
    }
};

const getOptions = (placement, flip) => {
    const options = {
        placement,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 10],
                }
            },
            {
                name: 'preventOverflow',
                options: {
                    altBoundary: true,
                    padding: 10,
                    altAxis: true,
                    rootBoundary: 'document',
                }
            }
        ]
    };
    if (!flip) {
        options.modifiers.push({
            name: 'flip',
            options: {
                fallbackPlacements: []
            }
        });
    }
    return options;
};

const RootCloseComponent = ({
    attributes,
    popperStyles,
    overlay,
    handleHide,
    rootClose,
    setPopperElement,
    rootCloseHide,
    usePortalToRender
}) => {
    const rootCloseRef = useRef();
    useRootClose(rootCloseRef, rootCloseHide, {
        disabled: !rootClose,
    });

    const popOver = (
        <div
          {...attributes.popper}
          style={popperStyles.popper}
          className={styles.popper}
          ref={mergeRefs([setPopperElement, rootCloseRef])}
        > {React.cloneElement(overlay, { hidePopover: handleHide })}
        </div>
    );

    if (usePortalToRender) {
        return ReactDOM.createPortal(popOver,
            document.body);
    }
    return popOver;
};

const PopperComponent = (props) => {
    const {
        show, renderFunction, referenceWrapperClassName, placement, overlay, rootClose, handleHide, flip, identifier, usePortalToRender
    } = props;

    const [referenceElement, setReferenceElement] = React.useState(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, getOptions(placement, flip));

    const handleHideIfTop = (event) => {
        // on esc key press only hide the top one
        if (_get(event, 'keyCode') === 27) {
            const isTop = modalManager.isPopoverOnTop(identifier);
            if (isTop) {
                handleHide();
            }
        } else {
            // on outside click handle all hide
            handleHide();
        }
    };

    return (
        <>
            <div ref={setReferenceElement} className={referenceWrapperClassName}>
                { renderFunction() }
            </div>
            {
                show
                && (
                    <RootCloseComponent
                      popperStyles={popperStyles}
                      attributes={attributes}
                      setPopperElement={setPopperElement}
                      overlay={overlay}
                      rootClose={rootClose}
                      handleHide={handleHide}
                      rootCloseHide={handleHideIfTop}
                      usePortalToRender={usePortalToRender}
                    />
                )
            }
        </>
    );
};

PopperComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    renderFunction: PropTypes.func.isRequired,
    referenceWrapperClassName: PropTypes.string,
    placement: PropTypes.string.isRequired,
    overlay: PropTypes.node.isRequired,
    rootClose: PropTypes.bool.isRequired,
    handleHide: PropTypes.func.isRequired,
    flip: PropTypes.bool,
    identifier: PropTypes.string.isRequired,
    usePortalToRender: PropTypes.bool.isRequired
};

const withPopover = (WrappedComponent) => {
    class WithPopover extends Component {
        constructor(props) {
            super(props);

            // Eventhandling copied from react-bootstraps overlay trigger
            // https://github.com/react-bootstrap/react-bootstrap/blob/master/src/OverlayTrigger.js
            this.handleToggle = this.handleToggle.bind(this);
            this.handleDelayedShow = this.handleDelayedShow.bind(this);
            this.handleDelayedHide = this.handleDelayedHide.bind(this);
            this.handleHide = this.handleHide.bind(this);
            this.onHide = this.onHide.bind(this);
            this.onShow = this.onShow.bind(this);
            this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
            this.handleMouseOver = (e) => (
                handleMouseOverOut(this.handleDelayedShow, e)
            );
            this.handleMouseOut = (e) => (
                handleMouseOverOut(this.handleDelayedHide, e)
            );

            this.uniqueId = _uniqueId();

            this.modifiers = {
                preventOverflow: {
                    enabled: true,
                    padding: 10
                },
                hide: {
                    enabled: false
                },
                flip: {
                    boundariesElement: 'scrollParent',
                    enabled: props.flip,
                },
                offset: {
                    enabled: true,
                    offset: '0, 10px'
                }
            };

            this.state = {
                show: props.defaultOverlayShown,
            };
        }

        componentWillUnmount() {
            const { show } = this.state;
            if (show === true) {
                this.onHide();
            }
        }

        handleOnKeyDown(e) {
            const { show } = this.state;
            if (!show && e.keyCode === SPACEBAR) {
                this.show();
            }

            if (show && e.keyCode === TAB) {
                e.preventDefault();
            }

            if (show && e.keyCode === ENTER) {
                this.hide();
            }
        }

        handleDelayedShow() {
            if (this.hoverHideDelayTimer != null) {
                clearTimeout(this.hoverHideDelayTimer);
                this.hoverHideDelayTimer = null;
                return;
            }

            const { show } = this.state;
            const { delayShow, delay } = this.props;

            if (show || this.hoverShowDelayTimer != null) {
                return;
            }

            const delayIfActive = delayShow != null
                ? delayShow : delay;

            if (!delayIfActive) {
                this.show();
                return;
            }

            this.hoverShowDelayTimer = setTimeout(() => {
                this.hoverShowDelayTimer = null;
                this.show();
            }, delayIfActive);
        }

        handleToggle() {
            const { show } = this.state;
            if (show) {
                this.hide();
            } else {
                this.show();
            }
        }

        handleDelayedHide() {
            if (this.hoverShowDelayTimer != null) {
                clearTimeout(this.hoverShowDelayTimer);
                this.hoverShowDelayTimer = null;
                return;
            }
            const { show } = this.state;
            if (!show || this.hoverHideDelayTimer != null) {
                return;
            }

            const { delayHide, delay } = this.props;

            const delayIfActive = delayHide != null
                ? delayHide : delay;

            if (!delayIfActive) {
                this.hide();
                return;
            }

            this.hoverHideDelayTimer = setTimeout(() => {
                this.hoverHideDelayTimer = null;
                this.hide();
            }, delayIfActive);
        }

        handleHide() {
            this.hide();
        }

        onShow() {
            const { onShow } = this.props;
            modalManager.addPopover(this.uniqueId);
            if (onShow) {
                onShow();
            }
        }

        onHide() {
            const { onHide } = this.props;
            modalManager.removePopOver(this.uniqueId);
            if (onHide) {
                onHide();
            }
        }

        show() {
            this.onShow();
            this.setState({ show: true });
        }

        hide() {
            this.onHide();
            this.setState({ show: false });
        }

        render() {
            const {
                trigger,
                placement,
                overlay,
                rootClose,
                activeStateInjection,
                referenceWrapperClassName,
                flip,
                usePortalToRender,
                forwardedRef
            } = this.props;
            const targetProps = _omit(this.props, [
                'placement',
                'overlay',
                'rootClose',
                'trigger',
                'defaultOverlayShown',
                'delay',
                'delayShow',
                'delayHide',
                'flip',
                'onShow',
                'onHide',
                'activeStateInjection',
                'referenceWrapperClassName',
                'usePortalToRender',
                'forwardedRef'
            ]);

            const { show } = this.state;

            if (activeStateInjection) {
                targetProps.active = show;
            }

            if (isOneOf('click', trigger)) {
                targetProps.onClick = createChainedFunction(targetProps.onClick, this.handleToggle);
            }

            if (isOneOf('hover', trigger)) {
                targetProps.onMouseOver = createChainedFunction(targetProps.onMouseOver, this.handleMouseOver);
                targetProps.onMouseOut = createChainedFunction(targetProps.onMouseOut, this.handleMouseOut);
            }

            if (isOneOf('focus', trigger)) {
                targetProps.onFocus = createChainedFunction(targetProps.onFocus, this.handleDelayedShow);
                targetProps.onBlur = createChainedFunction(targetProps.onBlur, this.handleDelayedHide);
            }

            // ClickShow does not toggle like click
            if (isOneOf('clickShow', trigger)) {
                targetProps.onClick = createChainedFunction(targetProps.onClick, this.handleDelayedShow);
            }

            if (isOneOf('spacebar', trigger)) {
                // Inject state into keydown
                const chainedFunction = createChainedFunction(targetProps.onKeyDown, this.handleOnKeyDown);
                targetProps.onKeyDown = (e) => { chainedFunction(e, show); };
            }

            return (
                <PopperComponent
                  flip={flip}
                  rootClose={rootClose}
                  referenceWrapperClassName={referenceWrapperClassName}
                  show={show}
                  placement={placement}
                  overlay={overlay}
                  renderFunction={() => <WrappedComponent ref={forwardedRef} {...targetProps} />}
                  handleHide={this.handleHide}
                  identifier={this.uniqueId}
                  usePortalToRender={usePortalToRender}
                />
            );
        }
    }

    WithPopover.propTypes = {
        placement: PropTypes.string,
        rootClose: PropTypes.bool,
        trigger: PropTypes.oneOfType([
            triggerType, PropTypes.arrayOf(triggerType),
        ]),
        overlay: PropTypes.node.isRequired,
        defaultOverlayShown: PropTypes.bool,
        delay: PropTypes.number,
        delayShow: PropTypes.number,
        delayHide: PropTypes.number,
        flip: PropTypes.bool,
        onShow: PropTypes.func,
        onHide: PropTypes.func,
        activeStateInjection: PropTypes.bool,
        referenceWrapperClassName: PropTypes.string,
        // portal renders a popover into the document's dom directly
        // There might be cases where you want to render it inline into a hierarchy. This is important for nested Popovers
        usePortalToRender: PropTypes.bool,
        forwardedRef: customPropTypes.forwardRef
    };

    WithPopover.defaultProps = {
        placement: 'bottom',
        rootClose: true,
        defaultOverlayShown: false,
        trigger: 'click',
        flip: true,
        activeStateInjection: true,
        referenceWrapperClassName: '',
        usePortalToRender: true
    };
    return WithPopover;
};

export default withPopover;
