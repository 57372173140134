import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Divider from 'src/components/Divider';
import { Checkbox } from 'src/components/forms/inputs';
import AdAccountCampaignFilterTokenInput from 'src/components/forms/inputs/AdCampaignFilterTokenInput';
import ConjunctionInput from 'src/components/forms/inputs/ConjunctionInput';
import IcomoonIcon from 'src/components/IcomoonIcon';
import React, { useState } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import TextFilterTokenInput from 'src/components/forms/inputs/TextFilterTokenInput';
import TagFilterTokenInput from 'src/components/forms/inputs/TagFilterTokenInput';
import styles from 'src/stylesheets/modals/additionalFilterDrawer.scss';
import {
    isPostTextSelected,
    isPostTagSelected,
    defaultPostTagState,
    defaultPostTextState,
    isPostTextExcludeSelected,
    defaultPostTextExcludeState,
    isAdCampaignSelected,
    defaultAdCampaignState
} from 'src/utils/filterSelectors';
import _isEqual from 'lodash/isEqual';
import Drawer from './layout/Drawer';

const FilterGroup = ({ children }) => (<div className={styles.filterGroup}>{ children }</div>);

FilterGroup.propTypes = {
    children: customPropTypes.children.isRequired
};

const FilterConjunctionHeader = ({ children, label }) => (
    <div className={styles.filterConjunctionHeader}>
        <p className={styles.header}>{ label }</p>
        {children
            && (
            <div className={styles.radioGroup}>
                {children}
            </div>
            )}
    </div>
);

FilterConjunctionHeader.propTypes = {
    children: customPropTypes.children,
    label: PropTypes.string.isRequired,
};

const GroupHeader = ({ label, icon }) => (
    <div className={styles.filterGroupHeader}>
        <IcomoonIcon className={styles.icon} icon={icon} />
        <p className={styles.header}>{ label }</p>
    </div>
);

GroupHeader.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
};

const FilterWrapper = ({ children }) => (
    <div className={styles.filterWrapper}> { children }</div>
);

FilterWrapper.propTypes = {
    children: customPropTypes.children.isRequired
};

const AdditionalFilterDrawer = ({
    show, hideModal, postText, onApplyClick, postTag, postTextExclude, adCampaign
}) => {
    const [postTextState, changePostTextState] = useState(postText);
    const [postTagState, changePostTagState] = useState(postTag);
    const [postTextExcludeState, changePostTextExcludeState] = useState(postTextExclude);
    const [adCampaignState, changeAdCampaignState] = useState(adCampaign);

    const handleApplyClick = () => {
        const trulyAppliedFilters = {};
        if (isPostTagSelected(postTagState)) {
            Object.assign(trulyAppliedFilters, { postTag: postTagState });
        }
        if (isPostTextSelected(postTextState)) {
            Object.assign(trulyAppliedFilters, { postText: postTextState });
        }
        if (isPostTextExcludeSelected(postTextExcludeState)) {
            Object.assign(trulyAppliedFilters, { postTextExclude: postTextExcludeState });
        }
        if (isAdCampaignSelected(adCampaignState)) {
            Object.assign(trulyAppliedFilters, { adCampaign: adCampaignState });
        }
        onApplyClick(trulyAppliedFilters);
        hideModal();
    };

    const onInputChange = (values, state, callback) => {
        const newState = Object.assign({}, state, { values });
        callback(newState);
    };

    const onRadioChange = (value, state, callback) => {
        const newState = Object.assign({}, state, { conjunction: value });
        callback(newState);
    };

    const onShowUntaggedChange = (value, state, callback) => {
        const newState = Object.assign({}, state, { untagged: value });
        callback(newState);
    };

    const reset = () => {
        changePostTextState(defaultPostTextState);
        changePostTagState(defaultPostTagState);
        changePostTextExcludeState(defaultPostTextExcludeState);
        changeAdCampaignState(defaultAdCampaignState);
    };

    const isPristine = (_isEqual(postTextState, postText) && _isEqual(postTagState, postTag) && _isEqual(postTextExcludeState, postTextExclude) && _isEqual(adCampaignState, adCampaign));

    return (
        <Drawer show={show} onHide={hideModal} onBackdropClick={hideModal}>
            <Drawer.Dialog>
                <Drawer.Header title="Additional filters" onHide={hideModal} />
                <Drawer.Body>
                    <FilterGroup>
                        <GroupHeader icon="tag" label="Post tags" />
                        <FilterWrapper>
                            <FilterConjunctionHeader label="Include" icon="tag">
                                <ConjunctionInput
                                  value={postTagState.conjunction}
                                  disabled={postTagState.values.length < 2}
                                  onChange={(value) => {
                                      onRadioChange(value, postTagState, changePostTagState);
                                  }}
                                />
                            </FilterConjunctionHeader>
                            <TagFilterTokenInput
                              onChange={(newValue) => onInputChange(newValue, postTagState, changePostTagState)}
                              value={postTagState.values}
                            />
                        </FilterWrapper>
                        <div className={styles.untagged}>
                            <Checkbox
                              text="Show untagged only"
                              checked={postTagState.untagged === true}
                              onChange={(event) => {
                                  onShowUntaggedChange(event.target.checked, postTagState, changePostTagState);
                              }}
                            />
                        </div>
                    </FilterGroup>
                    <Divider sideMargin={0} />
                    <FilterGroup>
                        <GroupHeader icon="search" label="Keywords" />
                        <FilterWrapper>
                            <FilterConjunctionHeader label="Include">
                                <ConjunctionInput
                                  value={postTextState.conjunction}
                                  disabled={postTextState.values.length < 2}
                                  onChange={(value) => {
                                      onRadioChange(value, postTextState, changePostTextState);
                                  }}
                                />
                            </FilterConjunctionHeader>
                            <TextFilterTokenInput
                              onChange={(newValue) => onInputChange(newValue, postTextState, changePostTextState)}
                              value={postTextState.values}
                            />
                        </FilterWrapper>
                        <FilterWrapper>
                            <FilterConjunctionHeader label="Exclude">
                                <ConjunctionInput
                                  value={postTextExcludeState.conjunction}
                                  disabled={postTextExcludeState.values.length < 2}
                                  onChange={(value) => {
                                      onRadioChange(value, postTextExcludeState, changePostTextExcludeState);
                                  }}
                                />
                            </FilterConjunctionHeader>
                            <TextFilterTokenInput
                              onChange={(newValue) => onInputChange(newValue, postTextExcludeState, changePostTextExcludeState)}
                              value={postTextExcludeState.values}
                            />
                        </FilterWrapper>
                    </FilterGroup>
                    <Divider sideMargin={0} />
                    <FilterGroup>
                        <GroupHeader icon="ads" label="Ad campaigns" />
                        <FilterWrapper>
                            <FilterConjunctionHeader label="Include" icon="ads" />
                            <AdAccountCampaignFilterTokenInput
                              onChange={(newValue) => onInputChange(newValue, adCampaignState, changeAdCampaignState)}
                              value={adCampaignState.values}
                            />
                        </FilterWrapper>
                    </FilterGroup>
                </Drawer.Body>
                <Drawer.Footer>
                    <ButtonGroup vertical>
                        <Button
                          className={styles.button}
                          stretch
                          action
                          label="Apply filters"
                          onClick={handleApplyClick}
                          disabled={isPristine}
                        />
                        <Button
                          className={styles.button}
                          stretch
                          label="Clear all filters"
                          onClick={reset}
                          disabled={!(isPostTagSelected(postTagState) || isPostTextSelected(postTextState) || isPostTextExcludeSelected(postTextExcludeState) || isAdCampaignSelected(adCampaignState))}
                        />
                    </ButtonGroup>
                </Drawer.Footer>
            </Drawer.Dialog>
        </Drawer>
    );
};

AdditionalFilterDrawer.propTypes = {
    postText: customPropTypes.postTextFilter.isRequired,
    postTextExclude: customPropTypes.postTextExcludeFilter.isRequired,
    adCampaign: customPropTypes.adCampaignFilter.isRequired,
    postTag: customPropTypes.postTagFilter.isRequired,
    onApplyClick: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};

export default AdditionalFilterDrawer;
