import {
    getProfileSearchFilter,
    makeSelectProfileSearchOutput
} from 'src/selectors/profileSearch';
import { connect } from 'react-redux';
import NetworkFilter from 'src/components/header/NetworkFilter';
import { profileSearchFilterChange } from 'src/actions/profileSearch';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/profiles/search/profileSearchSummaryText.scss';

const ProfileSearchSummaryText = (props) => {
    const {
        keyword,
        filteredCount,
        showNetworkFilter,
        profileSearchFilterChangeAction,
        filter
    } = props;

    return (
        <div>
            <div className={styles.wrapper}>
                <div className={styles.searchSummaryTextWrapper}>
                    {
                        keyword !== null
                            && <div>Showing {filteredCount} result{ filteredCount !== 1 ? 's' : '' }</div>
                    }
                </div>
                {
                    showNetworkFilter && (
                        <div className={styles.searchSummaryNetworkFilterWrapper}>
                            <NetworkFilter onNetworkFilterClick={profileSearchFilterChangeAction} networkFilter={filter} />
                        </div>
                    )
                }
            </div>
        </div>
    );
};

ProfileSearchSummaryText.propTypes = {
    filteredCount: PropTypes.number.isRequired,
    keyword: PropTypes.string,
    profileSearchFilterChangeAction: PropTypes.func.isRequired,
    showNetworkFilter: PropTypes.bool,
    filter: PropTypes.string.isRequired
};

ProfileSearchSummaryText.defaultProps = {
    showNetworkFilter: false
};

const makeMapStateToProps = () => {
    const selectProfileSearchOutput = makeSelectProfileSearchOutput();

    return (state) => {
        const profileSearchOutput = selectProfileSearchOutput(state);

        return {
            keyword: profileSearchOutput.keyword,
            filter: getProfileSearchFilter(state)
        };
    };
};

export default connect(makeMapStateToProps, {
    profileSearchFilterChangeAction: profileSearchFilterChange
})(ProfileSearchSummaryText);
