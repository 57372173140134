import React, { PureComponent } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import VisualizationList from 'src/components/VisualizationList';

class ImageSelect extends PureComponent {
    render() {
        const {
            options, value, name, onChange, onBlur, onFocus, preferredOption, preferredOptionSuffix
        } = this.props;
        return (
            <div>
                <VisualizationList
                  options={options}
                  selectedOptionId={value}
                  preferredOptionId={preferredOption}
                  preferredOptionSuffix={preferredOptionSuffix}
                  onChange={onChange}
                  onBlur={onBlur}
                  onFocus={onFocus}
                />
                <input type="hidden" name={name} value={value} />
            </div>
        );
    }
}

ImageSelect.propTypes = {
    options: customPropTypes.VisualizationListOptions.isRequired,
    value: PropTypes.string,
    preferredOption: PropTypes.string,
    preferredOptionSuffix: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired
};

ImageSelect.defaultProps = {
    preferredOptionSuffix: 'Preferred'
};

export default ImageSelect;
