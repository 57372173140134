import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import UseCasesTableHeader from 'src/components/forms/accountUseCasesTable/UseCasesTableHeader';
import memoize from 'memoize-one';
import styles from 'src/stylesheets/modals/socialNetworkLoginUseCaseSelectionModal.scss';

const getUseCaseFormField = memoize((platformUseCases) => platformUseCases.map((platformUseCase) => Object.assign({}, platformUseCase, { name: `${platformUseCase.id}_${platformUseCase.name}`, label: platformUseCase.name, hasWarning: false })));

const SocialNetworkUseCasesForm = (props) => {
    const { handleSubmit, platformUseCases } = props;
    return (
        <form onSubmit={handleSubmit}>
            <table>
                <UseCasesTableHeader
                  className={styles.useCaseHeader}
                  useCaseFormFields={getUseCaseFormField(platformUseCases)}
                />
            </table>
        </form>
    );
};

SocialNetworkUseCasesForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired
};

export default withReduxForm(SocialNetworkUseCasesForm);
