import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import { modalShowAdAccountAdd } from 'src/actions/overlays';
import { makeSelectAdAccountsByIds } from 'src/selectors/adAccounts';
import * as customPropTypes from 'src/customPropTypes';
import AdAccountInfoListItem from 'src/components/views/adAccounts/AdAccountInfoListItem';
import styles from 'src/stylesheets/modals/profilesAddedFailedStatusModal.scss';

import NewlyAddedAccountView from 'src/components/account/NewlyAddedAccountView';
import FailedAccountView from 'src/components/account/FailedAccountView';

const AdAccountAddResultModal = (props) => {
    const {
        hideModal,
        enforceFocus,
        adAccounts,
        failedSearchedAdAccountLinks
    } = props;

    const title = failedSearchedAdAccountLinks.length > 0 ? 'Error occurred while adding ad accounts' : 'Successfully added';

    const handleOnDoneButton = () => {
        hideModal();
    };

    const handleOnBackButton = () => {
        const { modalShowAdAccountAddAction } = props;
        hideModal();
        modalShowAdAccountAddAction();
    };
    return (
        <Modal dataViewName="ad-account-add-result-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body centered>
                    <div>
                        {
                            failedSearchedAdAccountLinks.length > 0 && (
                                <div className={styles.failedAccounts}>
                                    <FailedAccountView
                                      links={failedSearchedAdAccountLinks}
                                      onRetryClick={handleOnBackButton}
                                      label="ad accounts"
                                      onRetryLabel="Back to ad account selection"
                                    />
                                </div>
                            )
                        }
                        {
                            adAccounts.length > 0 && (
                                <NewlyAddedAccountView
                                  description={`The following ad account${adAccounts.length > 1 ? 's have' : ' has'} been successfully added. You now have access to the campaign level analytics in your ad account.`}
                                >
                                    {
                                        adAccounts.map((adAccount) => (
                                            <div key={adAccount.id} className={styles.item}>
                                                <AdAccountInfoListItem adAccount={adAccount} showThumbnail={false} />
                                            </div>
                                        ))
                                    }
                                </NewlyAddedAccountView>
                            )
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button label="Done" onClick={handleOnDoneButton} />
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

AdAccountAddResultModal.propTypes = {
    modalShowAdAccountAddAction: PropTypes.func.isRequired,
    addedAdAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    failedSearchedAdAccountLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    adAccounts: customPropTypes.adAccounts.isRequired
};

const makeMapStateToProps = () => {
    const selectAdAccountsByIds = makeSelectAdAccountsByIds();
    return (state, ownProps) => ({
        adAccounts: selectAdAccountsByIds(state, ownProps.addedAdAccountIds)
    });
};

export default connect(makeMapStateToProps, {
    modalShowAdAccountAddAction: modalShowAdAccountAdd
})(AdAccountAddResultModal);
