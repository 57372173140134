import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { modalShowCreateShareableLink } from 'src/actions/overlays';
import { makeSelectDashboardShareLinkByDashboardId, selectIsGettingDashboardShareLinks } from 'src/selectors/dashboardShareLinks';
import DashboardShareLinksTable from 'src/components/dashboardShareLinks/DashboardShareLinksTable';
import { dashboardShareLinksGetRequest } from 'src/actions/dashboardShareLinks';
import ListTableSkeleton from 'src/components/loadingIndicators/ListTableSkeleton';
import Error from 'src/components/errors/Error';
import styles from 'src/stylesheets/modals/dashboardShareLinksModal.scss';
import { selectDashboardById } from 'src/selectors/dashboards';

export const DashboardShareLinksModal = (props) => {
    const {
        dashboard,
        dashboardShareLinks,
        hideModal,
        enforceFocus,
        dashboardShareLinksGetRequestAction,
        loadingState
    } = props;

    useEffect(() => {
        dashboardShareLinksGetRequestAction(dashboard.id);
    }, []);

    const { isPending, error } = loadingState;

    const handleOnCreateClick = () => {
        const { modalShowCreateShareableLinkAction } = props;
        modalShowCreateShareableLinkAction(dashboard.id);
    };

    return (
        <Modal dataViewName="dashboard-share-links-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog width={1000}>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Interactive reports: {dashboard.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body height={476} className={styles.body}>
                    {
                        (dashboardShareLinks.length === 0 && !isPending && error)
                        && <Error error={error} />
                    }
                    {
                        dashboardShareLinks.length === 0 && isPending
                        && <ListTableSkeleton columns={5} rows={5} />
                    }
                    {
                        dashboardShareLinks.length === 0 && !isPending && !error
                        && <div className={styles.empty}>No reports yet.</div>
                    }
                    {
                        dashboardShareLinks.length > 0
                        && <DashboardShareLinksTable dashboardShareLinks={dashboardShareLinks} />
                    }
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button onClick={hideModal} label="Close" />
                        <Button
                          action
                          label="New interactive report"
                          icon="plus"
                          onClick={handleOnCreateClick}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

DashboardShareLinksModal.propTypes = {
    dashboardId: PropTypes.string.isRequired,
    modalShowCreateShareableLinkAction: PropTypes.func.isRequired,
    dashboardShareLinks: customPropTypes.dashboardShareLinks.isRequired,
    dashboardShareLinksGetRequestAction: PropTypes.func.isRequired,
    dashboard: customPropTypes.dashboard.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    loadingState: customPropTypes.asyncDataLoadingState.isRequired
};

const makeMapStateToProps = () => {
    const selectDashboardShareLinkByDashboardId = makeSelectDashboardShareLinkByDashboardId();
    return (state, ownProps) => ({
        dashboardShareLinks: selectDashboardShareLinkByDashboardId(state, ownProps.dashboardId),
        loadingState: selectIsGettingDashboardShareLinks(state, ownProps.dashboardId),
        dashboard: selectDashboardById(state, ownProps.dashboardId)
    });
};

export default connect(makeMapStateToProps, {
    modalShowCreateShareableLinkAction: modalShowCreateShareableLink,
    dashboardShareLinksGetRequestAction: dashboardShareLinksGetRequest
})(DashboardShareLinksModal);
