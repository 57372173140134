import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/tabs/tabGroup.scss';

const TabGroup = ({
    className, smallDistance, largeDistance, noDistance, children
}) => (
    <ul className={classnames(styles.tabGroup, {
        [className]: !!(className),
        [styles.smallDistance]: smallDistance,
        [styles.largeDistance]: largeDistance,
        [styles.noDistance]: noDistance
    })}
    >
        {
            React.Children.map(children, (tab) => {
                if (tab) {
                    return <li>{tab}</li>;
                }
                return null;
            })
        }
    </ul>
);

TabGroup.propTypes = {
    children: customPropTypes.children.isRequired,
    className: PropTypes.string,
    smallDistance: PropTypes.bool,
    noDistance: PropTypes.bool,
    largeDistance: PropTypes.bool
};

TabGroup.defaultProps = {
    smallDistance: false,
    noDistance: false
};

export default TabGroup;
