import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/profileEvents/profileEventsTable.scss';
import ProfileEventState from 'src/components/profileEvents/ProfileEventState';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import classnames from 'classnames';
import RelativeTime from 'src/components/RelativeTime';

const ProfileEventsTable = (props) => {
    const { profileEvents } = props;

    const header = (
        <thead>
            <tr>
                <th className={styles.eventTypeHeader}>Event type</th>
                <th className={styles.timeHeader}>Time</th>
            </tr>
        </thead>
    );

    return (
        <div>
            <table className={classnames(styles.table, styles.firstTable)}>
                {header}
            </table>
            <div className={styles.secondTableDiv}>
                <table className={classnames(styles.table, styles.secondTable)}>
                    {header}
                    <tbody>
                        {
                        profileEvents.map(({
                            id, state, content, createdTime
                        }) => (
                            <tr key={id}>
                                <td>
                                    <div className={styles.contentWrapper}>
                                        <div className={styles.stateIndicator}>
                                            <ProfileEventState state={state} />
                                        </div>
                                        <div className={styles.message}>
                                            <DivWithTooltip tooltip={content}>
                                                {content}
                                            </DivWithTooltip>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.timeColumn}>
                                        <RelativeTime date={createdTime} format="datetime" thresholdTimestamp={30 * 86400} />
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

ProfileEventsTable.propTypes = {
    profileEvents: customPropTypes.profileEvents.isRequired
};

export default ProfileEventsTable;
