import React from 'react';
import withPopover from 'src/components/withPopover';
import PropTypes from 'prop-types';

const TextWithPopover = (props) => {
    const {
        label, onClick, onMouseOver, onMouseOut
    } = props;

    return (
        <a
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        >
            {label}
        </a>
    );
};

TextWithPopover.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func
};

export default withPopover(TextWithPopover);
