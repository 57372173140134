import { createSelector } from 'reselect';
import _get from 'lodash/get';

const getAccountUseCasesAsyncStates = (state) => state.accountUseCasesAsyncStates;

export const selectIsBulkUseCaseUpdating = createSelector(
    [
        (state) => getAccountUseCasesAsyncStates(state).bulkUseCasesUpdate
    ],
    (asyncStates) => _get(asyncStates, ['bulkUpdate', 'isPending'], false)
);
