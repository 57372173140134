import ListInfoCreatedBy from 'src/components/ListInfoCreatedBy';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/folderInfo.scss';

const getSingularOrPlural = (count, word, suffix) => {
    if (count === 1) {
        return word;
    }
    return `${word}${suffix}`;
};

const FolderInfo = (props) => {
    const {
        folder, loggedInUser, folderCount, dashboardCount, label, showFolderCount, icon, labelIcon, showLabel
    } = props;

    return (
        <div className={styles.wrapper}>
            <div className={styles.iconWrapper}>
                <IcomoonIcon icon={icon} className={styles.icon} />
            </div>
            <div className={styles.textWrapper}>
                <div className={classnames('folderName', styles.name)}>
                    {folder.name}
                </div>
                <ul className={styles.labels}>
                    {
                        showLabel && label && <li className={styles.label}>{labelIcon && <IcomoonIcon className={styles.labelIcon} icon={labelIcon} />}{label}</li>
                    }

                    {(folder.createdByUserId || folder.createTime)
                    && (
                    <li className={styles.label}>
                        <ListInfoCreatedBy
                          loggedInUserId={loggedInUser.id}
                          cratedByUserId={folder.createdByUserId}
                          createdByUserName={folder.createdByUserName}
                          createdTime={folder.createTime}
                        />
                    </li>
                    )}
                    {
                        showFolderCount && folderCount !== undefined
                        && <li className={styles.label}>{folderCount > 0 ? `${folderCount} ${getSingularOrPlural(folderCount, 'folder', 's')}` : 'No folders yet'}</li>
                    }
                    {
                        dashboardCount !== undefined
                        && <li className={styles.label}>{dashboardCount > 0 ? `${dashboardCount} ${getSingularOrPlural(dashboardCount, 'dashboard', 's')}` : 'No dashboards yet'}</li>
                    }
                </ul>
            </div>
        </div>
    );
};

FolderInfo.propTypes = {
    folder: customPropTypes.folder.isRequired,
    loggedInUser: customPropTypes.user.isRequired,
    dashboardCount: PropTypes.number,
    folderCount: PropTypes.number,
    showFolderCount: PropTypes.bool,
    label: PropTypes.node,
    labelIcon: PropTypes.string,
    showLabel: PropTypes.bool,
    icon: PropTypes.string
};

FolderInfo.defaultProps = {
    label: 'Folder',
    showFolderCount: false,
    icon: 'folder-filled',
    showLabel: true
};

export default FolderInfo;
