import _startsWith from 'lodash/startsWith';
import Feedback from 'src/components/feedback/Feedback';
import FeedbackLink from 'src/components/feedback/FeedbackLink';
import FeedbackLinkPlain from 'src/components/feedback/FeedbackLinkPlain';
import PropTypes from 'prop-types';
import React from 'react';

const style = { marginBottom: 24 };

const withFormFeedback = (type) => {
    const FormFeedback = (props) => {
        const { message, link } = props;
        const feedbackProps = {};
        if (link) {
            if (_startsWith(link.to, 'mailto:')) {
                feedbackProps.link = <FeedbackLinkPlain href={link.to}>{link.text}</FeedbackLinkPlain>;
            } else {
                feedbackProps.link = <FeedbackLink to={link.to}>{link.text}</FeedbackLink>;
            }
        }
        return (
            <div style={style} className="form-error-global">
                <Feedback type={type} content={message} {...feedbackProps} />
            </div>
        );
    };
    FormFeedback.propTypes = {
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        link: PropTypes.shape({
            to: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired
        })
    };
    return FormFeedback;
};

export default withFormFeedback;
