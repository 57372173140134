import DynamicDateSelectorButton from 'src/components/buttons/DynamicDateSelectorButton';
import DynamicDateSelectorPopover from 'src/components/popovers/DynamicDateSelectorPopover';
import PropTypes from 'prop-types';
import React from 'react';
import withPopover from 'src/components/withPopover';

const DynamicDateSelectorWithPopover = withPopover(DynamicDateSelectorButton);

const DynamicDateSelectorView = (props) => {
    const {
        selectedDate, placement, small, onDateChange, disabled, onHide, onShow, labelIfNull, stretch, leftCurved, rightCurved
    } = props;

    const dateSelectorPopover = (
        <DynamicDateSelectorPopover
          selectedDate={selectedDate}
          onDateChange={onDateChange}
        />
    );

    return (
        <DynamicDateSelectorWithPopover
          flip={false}
          overlay={dateSelectorPopover}
          selectedDate={selectedDate}
          placement={placement}
          small={small}
          disabled={disabled}
          onShow={onShow}
          onHide={onHide}
          className="dateSelector"
          stretch={stretch}
          alignLabel="left"
          labelIfNull={labelIfNull}
          leftCurved={leftCurved}
          rightCurved={rightCurved}
        />
    );
};

DynamicDateSelectorView.propTypes = {
    selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    onDateChange: PropTypes.func.isRequired,
    placement: PropTypes.string,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    labelIfNull: PropTypes.string,
    stretch: PropTypes.bool,
    leftCurved: PropTypes.bool,
    rightCurved: PropTypes.bool
};

DynamicDateSelectorView.defaultProps = {
    placement: 'bottom-start',
    small: false,
    disabled: false,
    labelIfNull: '',
    stretch: true,
    leftCurved: false,
    rightCurved: false
};

export default DynamicDateSelectorView;
