import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { postTagAutomationActivateRequest } from 'src/actions/postTags';
import { selectPostTagAutomationActivatingState } from 'src/selectors/postTags';
import * as customPropTypes from 'src/customPropTypes';

const PostTagAutomationWarningModal = (props) => {
    const { hideModal, enforceFocus, state } = props;
    const { isPending, error, success } = state;
    const handleOnSaveClick = () => {
        const { postTagId, postTagAutomationActivateRequestAction } = props;
        postTagAutomationActivateRequestAction(postTagId, true);
    };

    return (
        <Modal dataViewName="post-tag-edit-warning" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Auto-tag historical posts
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body height={120}>
                    <div>
                        {
                            error
                            && <div>{error.message}</div>
                        }
                        {
                            isPending
                            && (
                                <div>Activating ....</div>
                            )
                        }
                        {
                            success && <div>The automation for this tag has been activated.</div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        success
                        && <Button label="Okay" onClick={() => { hideModal(); }} />
                    }
                    {
                        (isPending || error)
                        && (
                            <ButtonGroup>
                                <Button label="Cancel" onClick={() => { hideModal(); }} />
                                <Button
                                  action
                                  label="Activate"
                                  onClick={handleOnSaveClick}
                                  loading={isPending}
                                />
                            </ButtonGroup>
                        )
                    }
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

PostTagAutomationWarningModal.propTypes = {
    postTagId: PropTypes.string.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    postTagAutomationActivateRequestAction: PropTypes.func.isRequired,
    state: customPropTypes.asyncDataLoadingState.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    state: selectPostTagAutomationActivatingState(state, ownProps.postTagId)
});

export default connect(mapStateToProps, {
    postTagAutomationActivateRequestAction: postTagAutomationActivateRequest,
})(PostTagAutomationWarningModal);
