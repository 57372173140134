import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import AccountUseCases from 'src/components/AccountUseCases';
import { profileUseCasesSetFormAction } from 'src/actions/profileUseCases';

const ProfileUseCases = (props) => {
    const {
        profile, className, hideViewAllAuthenticationButton, formName
    } = props;

    return (
        <AccountUseCases
          account={profile}
          formAction={profileUseCasesSetFormAction}
          hideViewAllAuthenticationButton={hideViewAllAuthenticationButton}
          className={className}
          formName={formName}
        />
    );
};

ProfileUseCases.propTypes = {
    profile: customPropTypes.profile.isRequired,
    className: PropTypes.string,
    hideViewAllAuthenticationButton: PropTypes.bool,
    formName: PropTypes.string.isRequired,
};

ProfileUseCases.defaultProps = {
    className: '',
    hideViewAllAuthenticationButton: false
};

export default ProfileUseCases;
