import * as customPropTypes from 'src/customPropTypes';
import AuthTransactionRequirementsTable from 'src/components/authTransactions/table/AuthTransactionRequirementsTable';
import emptyImage from 'src/resources/illustrations/discover-no-results.png';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import React from 'react';
import withAuthTransactionRequirementsSortedByNetworkType from 'src/components/authTransactions/withAuthTransactionRequirementsSortedByNetworkType';
import { platformTypeOrder } from 'src/utils/authTransactionUtils';

const NetworksAuthTransactionRequirementsTables = (props) => {
    const { profileAuthTransactionReqByNetworkType, adAccountAuthTransactionReqByNetworkType } = props;
    return (
        <div>
            {
                platformTypeOrder.map((platformType) => {
                    const authTransactionRequirementsByProfileId = profileAuthTransactionReqByNetworkType[platformType] || {};
                    const authTransactionRequirementsByAdAccountId = adAccountAuthTransactionReqByNetworkType[platformType] || {};
                    if (Object.keys(authTransactionRequirementsByProfileId).length > 0 || Object.keys(authTransactionRequirementsByAdAccountId).length > 0) {
                        return (
                            <AuthTransactionRequirementsTable
                              key={platformType}
                              authTransactionRequirementsByProfileId={authTransactionRequirementsByProfileId}
                              authTransactionRequirementsByAdAccountId={authTransactionRequirementsByAdAccountId}
                              platformType={platformType}
                            />
                        );
                    }
                    return null;
                })
            }
            {
                Object.keys(profileAuthTransactionReqByNetworkType).length === 0 && Object.keys(adAccountAuthTransactionReqByNetworkType).length === 0
                && (
                <EmptyInfoScreen
                  image={emptyImage}
                  message="The account in this authentication were removed from your quintly account."
                />
                )
            }
        </div>
    );
};

NetworksAuthTransactionRequirementsTables.propTypes = {
    profileAuthTransactionReqByNetworkType: customPropTypes.authTransactionRequirementsByPlatformType.isRequired,
    adAccountAuthTransactionReqByNetworkType: customPropTypes.adAccountAuthTransactionRequirementsByPlatformType.isRequired
};

export default withAuthTransactionRequirementsSortedByNetworkType(NetworksAuthTransactionRequirementsTables);
