import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import { makeSelectAuthAdAccountByPlatformType } from 'src/selectors/authAdAccount';
import { authAdAccountSelectionConfirm } from 'src/actions/socialNetworkAdAccountAuthentication';
import AuthAdAccountListForm from 'src/components/forms/AuthAdAccountListForm';
import { makeSelectAllChosenAuthAdAccountIdsByPlatformType } from 'src/selectors/externalNetworkAuthentication';
import { getFormValues } from 'redux-form';
import _get from 'lodash/get';

const formName = 'authAdAccountList';

const AuthAdAccountSelectionModal = (props) => {
    const {
        hideModal, enforceFocus, authAdAccounts, chosenAuthAdAccountIds, formValues
    } = props;
    const currentSelectedAuthAdAccounts = _get(formValues, 'chosenAuthAdAccountIds', []);

    const onConfirmSelectionClickHandle = () => {
        const { authAdAccountSelectionConfirmAction, platformType } = props;
        authAdAccountSelectionConfirmAction(platformType, currentSelectedAuthAdAccounts);
        hideModal();
    };

    return (
        <Modal dataViewName="select-auth-ad-account-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Select ad accounts to connect
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AuthAdAccountListForm
                      authAdAccounts={authAdAccounts}
                      initialValues={{ chosenAuthAdAccountIds }}
                      form={formName}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          label="Cancel"
                          onClick={hideModal}
                        />
                        <Button
                          action
                          label="Confirm selection"
                          onClick={onConfirmSelectionClickHandle}
                          disabled={currentSelectedAuthAdAccounts.length === 0}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

AuthAdAccountSelectionModal.propTypes = {
    platformType: customPropTypes.platformTypes.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    authAdAccountSelectionConfirmAction: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    authAdAccounts: customPropTypes.authAdAccounts.isRequired,
    chosenAuthAdAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

const makeMapStateToProps = () => {
    const selectAuthAdAccountByPlatformTypeAndAuthUserId = makeSelectAuthAdAccountByPlatformType();
    const selectAllChosenAuthAdAccountIdsByPlatformType = makeSelectAllChosenAuthAdAccountIdsByPlatformType();
    const formValueSelector = getFormValues(formName);
    return (state, { platformType }) => ({
        authAdAccounts: selectAuthAdAccountByPlatformTypeAndAuthUserId(state, platformType),
        chosenAuthAdAccountIds: selectAllChosenAuthAdAccountIdsByPlatformType(state, platformType),
        formValues: formValueSelector(state)
    });
};

export default connect(makeMapStateToProps, {
    authAdAccountSelectionConfirmAction: authAdAccountSelectionConfirm
})(AuthAdAccountSelectionModal);
