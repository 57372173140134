import * as customPropTypes from 'src/customPropTypes';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import DashboardMetricAliasField from 'src/components/forms/partials/DashboardMetricAliasField';
import { DashboardSelector } from 'src/components/forms/fields';
import { Field } from 'redux-form';

const CloneDashboardMetricForm = (props) => {
    const {
        handleSubmit, error, defaultMetricName, submitting
    } = props;

    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <DashboardMetricAliasField
              placeholder={defaultMetricName}
              disabled={submitting}
            />
            <Field
              name="destinationDashboardId"
              label="Destination:"
              component={DashboardSelector}
              disabled={submitting}
            />
        </form>
    );
};

CloneDashboardMetricForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dashboardId: PropTypes.string,
    error: customPropTypes.dataLoadingError,
    defaultMetricName: PropTypes.string.isRequired,
    submitting: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired
};

export default withReduxForm(CloneDashboardMetricForm);
