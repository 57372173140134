import React from 'react';
import styles from 'src/stylesheets/views/selectedEntityCountWithClearOption.scss';
import { connect } from 'react-redux';
import { uncheckAllListValues } from 'src/actions/lists';
import PropTypes from 'prop-types';
import withTooltip from 'src/components/withToolTip';
import IcomoonIcon from 'src/components/IcomoonIcon';
import classnames from 'classnames';

const IcomoonIconWithToolTip = withTooltip(IcomoonIcon);

const SelectedEntityCountWithClearOption = ({
    label, count, uncheckAllListValuesAction, listName, disabled
}) => (
    <div className={styles.wrapper}>
        <span>
            {label} { count > 0 && <>{`(${count})`}</> }
        </span>
        {
            count > 0
                && (
                    <IcomoonIconWithToolTip
                      onClick={() => { uncheckAllListValuesAction(listName); }}
                      tooltipPlacement="top"
                      tooltip="Deselect all"
                      className={classnames(styles.icon, { [styles.disabled]: disabled })}
                      icon="clear"
                    />
                )
            }
    </div>
);

SelectedEntityCountWithClearOption.propTypes = {
    label: PropTypes.string.isRequired,
    listName: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    uncheckAllListValuesAction: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

SelectedEntityCountWithClearOption.defaultProps = {
    disabled: false
};

export default connect(null, {
    uncheckAllListValuesAction: uncheckAllListValues
})(SelectedEntityCountWithClearOption);
