import React, { useState } from 'react';
import { listName as listNameOfProfileSearch } from 'src/utils/profileSearch';
import { listName as listNameOfAdAccount } from 'src/utils/adAccountSearch';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProgressBarWithLabels from 'src/components/ProgressBarWithLabels';
import { selectSpaceLimits } from 'src/selectors/spaceLimits';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import _capitalize from 'lodash/capitalize';
import { renderAccountType } from 'src/utils/accountType';
import { getLimitFromSpaceLimit, getUsageFromSpaceLimit } from 'src/utils/accounts';
import { makeSelectCheckedValues } from 'src/selectors/lists';

const listNameByAccountType = {
    adAccounts: listNameOfAdAccount,
    profiles: listNameOfProfileSearch
};

const getListNameByAccountType = (accountType) => _get(listNameByAccountType, accountType, '');

const AccountsTobeAddedInfo = (props) => {
    const { spaceLimits, accountIds, accountType } = props;
    const [accountLimit] = useState(getLimitFromSpaceLimit(spaceLimits, accountType));
    const [accountsUsed] = useState(getUsageFromSpaceLimit(spaceLimits, accountType));
    const newUsage = accountsUsed + accountIds.length;
    return (
        <ProgressBarWithLabels
          limit={accountLimit}
          usage={newUsage}
          label={_capitalize(renderAccountType(accountType, true))}
        />
    );
};

AccountsTobeAddedInfo.propTypes = {
    spaceLimits: customPropTypes.spaceLimits.isRequired,
    accountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    accountType: customPropTypes.accountTypes.isRequired
};

const makeMapStateToProps = () => {
    const selectCheckedValues = makeSelectCheckedValues();
    return (state, ownProps) => ({
        accountIds: selectCheckedValues(state, getListNameByAccountType(ownProps.accountType)),
        spaceLimits: selectSpaceLimits(state)
    });
};

export default connect(makeMapStateToProps)(AccountsTobeAddedInfo);
