import { makeSelectAuthTransactionById, selectClosingState } from 'src/selectors/authTransactions';
import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as overlayAction from 'src/actions/overlays';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Feedback from 'src/components/feedback/Feedback';
import { isAuthTransactionOpen } from 'src/utils/authTransactionUtils';
import Modal from 'src/components/modals/layout/Modal';
import NetworksAuthTransactionRequirementsConnect from 'src/components/authTransactions/NetworksAuthTransactionRequirementsConnect';
import PropTypes from 'prop-types';
import { selectIsReadOnly } from 'src/selectors/loggedInUser';
import styles from 'src/stylesheets/modals/addUpdateAuthUsersModal.scss';
import withResourceCheck from 'src/components/modals/withResourceCheck';
import AuthTransactionShareInfo from 'src/components/views/authentications/AuthTransactionShareInfo';

const AddUpdateAuthUsersModal = (props) => {
    useEffect(() => {
        const { isUserReadOnly, modalShowUserPermissionErrorAction, hideModal } = props;
        if (isUserReadOnly) {
            hideModal();
            modalShowUserPermissionErrorAction('You are a read only user and cannot authenticate users.');
        }
    }, []);

    const handleHideModal = () => {
        const { hideModal } = props;
        hideModal();
    };

    const {
        modalsShowAuthTransactionCloseAction,
        closingState,
        authTransaction,
        hideModal
    } = props;

    const { isOpen, validUntil, hash } = authTransaction;

    return (
        <Modal dataViewName="add-update-auth-users-modal" onHide={handleHideModal}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={handleHideModal}>
                    <Modal.Title>Connect to social network to authenticate</Modal.Title>
                </Modal.Header>
                <div className={styles.wrapper}>
                    <div className={styles.body}>
                        <div className={styles.header}>
                            <div className={styles.left}>
                                <Feedback
                                  content="To authenticate, continue to the social network's connection page by clicking on the buttons below. We will automatically detect the pages you have access to when you connect. Facebook and Instagram profiles can be connected with one Facebook account. The other networks require individual connections per profile."
                                  type="info"
                                />
                            </div>
                            <div className={styles.right}>
                                {
                                    hash !== null && <AuthTransactionShareInfo authTransaction={authTransaction} />
                                }
                            </div>
                        </div>
                        <NetworksAuthTransactionRequirementsConnect authTransaction={authTransaction} />
                    </div>
                </div>
                <Modal.Footer>
                    <ButtonGroup>
                        <div className={styles.stepDefinition}>
                            Step 3/3 - Authentication
                        </div>
                        <Button
                          label="Close window"
                          onClick={hideModal}
                        />
                        <Button
                          action
                          label="End authentication"
                          disabled={!isAuthTransactionOpen(isOpen, validUntil)}
                          loading={closingState.isPending}
                          onClick={() => { modalsShowAuthTransactionCloseAction(authTransaction.id); }}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

AddUpdateAuthUsersModal.propTypes = {
    authTransaction: customPropTypes.authTransaction.isRequired,
    modalsShowAuthTransactionCloseAction: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    isUserReadOnly: PropTypes.bool.isRequired,
    closingState: customPropTypes.asyncReducerState,
    modalShowUserPermissionErrorAction: PropTypes.func.isRequired
};

AddUpdateAuthUsersModal.defaultProps = {
    closingState: {}
};

const AddUpdateAuthUsersModalWithResourceCheck = withResourceCheck(AddUpdateAuthUsersModal, 'authTransaction', 'authentication');

const makeMapStateToProps = () => {
    const selectAuthTransactionById = makeSelectAuthTransactionById();
    return (state, ownProps) => ({
        isUserReadOnly: selectIsReadOnly(state),
        authTransaction: selectAuthTransactionById(state, ownProps.authTransactionId),
        closingState: selectClosingState(state, ownProps.authTransactionId)
    });
};

export default connect(makeMapStateToProps, {
    modalsShowAuthTransactionCloseAction: overlayAction.modalsShowAuthTransactionClose,
    modalShowUserPermissionErrorAction: overlayAction.modalShowUserPermissionError
})(AddUpdateAuthUsersModalWithResourceCheck);
