import React from 'react';
import { makeSelectDashboardById } from 'src/selectors/dashboards';
import { connect } from 'react-redux';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import styles from 'src/stylesheets/buttons/dashboardNameButton.scss';
import Button from 'src/components/buttons/Button';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';

const DashboardNameButton = (props) => {
    const { dashboard, onClick, disabled } = props;
    const name = dashboard ? dashboard.name : 'Unknown dashboard';
    const label = (
        <div className={styles.labelWrapper}>
            <DivWithTooltip
              tooltip={name}
              referenceWrapperClassName={styles.divWithTooltip}
              className={styles.divWithEllipsis}
            >
                {name}
            </DivWithTooltip>
        </div>
    );
    return (
        <Button
          onClick={() => { onClick(); }}
          icon="dashboard"
          label={label}
          disabled={disabled}
          responsive
        />
    );
};

DashboardNameButton.propTypes = {
    dashboard: PropTypes.oneOfType([customPropTypes.dashboard, PropTypes.bool]).isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

DashboardNameButton.defaultProps = {
    disabled: false
};

const makeMapStateToProps = () => {
    const selectDashboardById = makeSelectDashboardById();
    return (state, ownProps) => ({
        dashboard: selectDashboardById(state, ownProps.dashboardId)
    });
};

export default connect(makeMapStateToProps)(DashboardNameButton);
