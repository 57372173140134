import React from 'react';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import { externalNetworkAuthenticationLinkGetRequest } from 'src/actions/profiles';
import PropTypes from 'prop-types';
import { externalNetworkAdAccountAuthenticationLinkGetRequest } from 'src/actions/adAccounts';
import { renderAccountType } from 'src/utils/accountType';
import * as customPropTypes from 'src/customPropTypes';

const ExternalSocialNetworkAuthenticationLink = (props) => {
    const { accountType } = props;
    const handleOnCopyClick = () => {
        const {
            externalNetworkAuthenticationLinkGetRequestAction,
            externalNetworkAdAccountAuthenticationLinkGetRequestAction
        } = props;

        if (accountType === 'profiles') {
            externalNetworkAuthenticationLinkGetRequestAction();
        }

        if (accountType === 'adAccounts') {
            externalNetworkAdAccountAuthenticationLinkGetRequestAction();
        }
    };

    return (
        <>
            <span>
                {`Not an admin of the ${renderAccountType(accountType)}? Share this link with an admin.`}
            </span>
            <br />
            <Button
              small
              label="Copy authentication link"
              icon="link"
              onClick={handleOnCopyClick}
            />
        </>
    );
};

ExternalSocialNetworkAuthenticationLink.propTypes = {
    accountType: customPropTypes.accountTypes.isRequired,
    externalNetworkAuthenticationLinkGetRequestAction: PropTypes.func.isRequired,
    externalNetworkAdAccountAuthenticationLinkGetRequestAction: PropTypes.func.isRequired,
};

export default connect(null, {
    externalNetworkAuthenticationLinkGetRequestAction: externalNetworkAuthenticationLinkGetRequest,
    externalNetworkAdAccountAuthenticationLinkGetRequestAction: externalNetworkAdAccountAuthenticationLinkGetRequest
})(ExternalSocialNetworkAuthenticationLink);
