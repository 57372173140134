import * as customPropTypes from 'src/customPropTypes';
import { Field } from 'redux-form';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'src/components/forms/fields';
import withReduxForm from 'src/components/forms/withReduxForm';
import CustomLogo from 'src/components/forms/partials/CustomLogo';

function validate(data) {
    const errors = {};

    if (!data.name || data.name.trim().length < 1) {
        errors.name = 'The space must be given a name';
    }

    const isPageLimitNumeric = /^\d+$/.test(data.pageLimit);
    if (!isPageLimitNumeric) {
        errors.pageLimit = 'The value of this field must be a whole number';
    }

    const isAdAccountLimitNumeric = /^\d+$/.test(data.adAccountLimit);
    if (!isAdAccountLimitNumeric) {
        errors.adAccountLimit = 'The value of this field must be a whole number';
    }

    return errors;
}

const SpaceForm = (props) => {
    const {
        handleSubmit,
        error,
        freeProfileLimits,
        freeAdAccountLimits
    } = props;

    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <div>
                <Field
                  autoFocus
                  name="name"
                  label="Name"
                  component={Text}
                />
                <Field
                  name="pageLimit"
                  label="Profile limit"
                  component={Text}
                  topRightInfo={`${freeProfileLimits} more profiles available.`}
                />
                <Field
                  name="adAccountLimit"
                  label="Ad account limit"
                  component={Text}
                  topRightInfo={`${freeAdAccountLimits} more ad accounts available.`}
                />
                <CustomLogo context="space" />
            </div>
        </form>
    );
};

SpaceForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: customPropTypes.dataLoadingError,
    freeProfileLimits: PropTypes.number.isRequired,
    freeAdAccountLimits: PropTypes.number.isRequired
};

export default withReduxForm(SpaceForm, {
    validate
});
