import * as customPropTypes from 'src/customPropTypes';
import * as profileUseCaseActions from 'src/actions/profileUseCases';
import { connect } from 'react-redux';
import { makeSelectProfilesByIds } from 'src/selectors/profiles';
import PropTypes from 'prop-types';
import React from 'react';
import ActivateBulkAccountUseCasesModal from 'src/components/modals/partials/ActivateBulkAccountUseCasesModal';
import AccountTypeContext from 'src/components/context/AccountTypeContext';

const ActivateBulkProfileUseCasesModal = (props) => {
    const {
        hideModal,
        enforceFocus,
        profiles,
    } = props;

    const handleOnSaveClick = (checkedAndUncheckedUseCasesPerAccount) => {
        const { bulkProfilesUseCasesUpdateRequestAction } = props;
        bulkProfilesUseCasesUpdateRequestAction(checkedAndUncheckedUseCasesPerAccount);
    };

    return (
        <AccountTypeContext.Provider value={{ accountType: 'profiles' }}>
            <ActivateBulkAccountUseCasesModal
              accounts={profiles}
              onSave={handleOnSaveClick}
              hideModal={hideModal}
              enforceFocus={enforceFocus}
            />
        </AccountTypeContext.Provider>
    );
};

ActivateBulkProfileUseCasesModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    profileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    profiles: customPropTypes.profiles.isRequired,
    bulkProfilesUseCasesUpdateRequestAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectProfilesByIds = makeSelectProfilesByIds();
    return (state, ownProps) => ({
        profiles: selectProfilesByIds(state, ownProps.profileIds)
    });
};

export default connect(makeMapStateToProps, {
    bulkProfilesUseCasesUpdateRequestAction: profileUseCaseActions.bulkProfilesUseCasesUpdateRequest
})(ActivateBulkProfileUseCasesModal);
