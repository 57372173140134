import classnames from 'classnames';
import _find from 'lodash/find';
import _omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class SelectItem extends Component {
    componentDidUpdate(prevProps) {
        const { active, handleOpen } = this.props;
        if (active !== prevProps.active) {
            handleOpen(active);
        }
    }

    render() {
        const {
            renderValue,
            value,
            options,
            className,
        } = this.props;

        const optionToRender = _find(options, { id: value });
        const cleanedProps = _omit(this.props, ['renderValue', 'value', 'options', 'active', 'handleOpen']);
        return (
            <div
              role="listbox"
              tabIndex="0"
              {...cleanedProps}
              className={classnames(className)}
            >
                {
                    optionToRender && renderValue(optionToRender)
                }
                { !optionToRender && 'Select...' }
            </div>
        );
    }
}

SelectItem.propTypes = {
    renderValue: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    active: PropTypes.bool.isRequired,
    className: PropTypes.string,
    handleOpen: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func
};

export default SelectItem;
