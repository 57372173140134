import { getFormSyncErrors, submit } from 'redux-form';
import _pick from 'lodash/pick';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import { getIsReportPreviewPending } from 'src/selectors/exports';
import PropTypes from 'prop-types';
import React from 'react';
import { triggerReportPreview } from 'src/actions/exports';

const PreviewReportButton = (props) => {
    const {
        dashboardId,
        formName,
        triggerReportPreviewAction,
        isReportPreviewPending,
        syncErrors
    } = props;
    const isValid = Object.keys(_pick(syncErrors, ['profileSelection', 'dateSelection'])).length === 0;
    return (
        <Button
          disabled={!isValid}
          label="Preview report"
          onClick={() => { triggerReportPreviewAction(dashboardId, formName); }}
          loading={isReportPreviewPending}
        />
    );
};

PreviewReportButton.propTypes = {
    dashboardId: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    triggerReportPreviewAction: PropTypes.func.isRequired,
    isReportPreviewPending: PropTypes.bool.isRequired,
    syncErrors: PropTypes.objectOf(PropTypes.string),
};

PreviewReportButton.defaultProps = {
    syncErrors: {}
};

const makeMapStateToProps = () => (state, { formName }) => ({
    isReportPreviewPending: getIsReportPreviewPending(state),
    syncErrors: getFormSyncErrors(formName)(state),
});

export default connect(makeMapStateToProps, {
    submitAction: submit,
    triggerReportPreviewAction: triggerReportPreview,
})(PreviewReportButton);
