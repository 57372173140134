import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/file.scss';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import classnames from 'classnames';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import _get from 'lodash/get';

const File = (props) => {
    const inputRef = useRef();
    const {
        value, accept
    } = props;
    const handleOnChange = (e) => {
        e.preventDefault();
        const { onChange } = props;
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const objectURL = (window.URL || window.webkitURL).createObjectURL(file);
            onChange({
                url: objectURL,
                meta: {
                    name: file.name,
                    size: file.size,
                    type: file.type
                }
            });
        }
    };

    const textToShow = _get(value, 'meta.name', 'No file selected');
    return (
        <div className={styles.wrapper}>
            <div className={classnames(styles.fileInfo)}>
                <div className={styles.text}>
                    <DivWithTooltip tooltip={textToShow}>
                        {textToShow}
                    </DivWithTooltip>
                </div>
            </div>
            <div className={styles.control}>
                <Button
                  label="Select file"
                  icon="upload"
                  stretch
                  onClick={() => { inputRef.current.click(); }}
                />
            </div>
            <input
              ref={inputRef}
              type="file"
              accept={accept.join(', ')}
              onChange={handleOnChange}
              className={styles.input}
            />
        </div>
    );
};

File.propTypes = {
    value: customPropTypes.fileInput,
    onChange: PropTypes.func.isRequired,
    accept: PropTypes.arrayOf([PropTypes.string])
};

File.defaultProps = {
    accept: ['application/json']
};

export default File;
