import {
    isSubmitting, isValid, getFormSyncErrors
} from 'redux-form';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { selectMetricBuilderFormButtonClicked } from 'src/selectors/forms';
import { submitMetricBuilderForm } from 'src/actions/forms';
import { formName, isValidWithoutMetricNameValidation } from 'src/utils/metricBuilder';
import MetricBuilderFormWithNameFieldOnly from 'src/components/forms/MetricBuilderFormWithNameFieldOnly';
import CustomMetricSaveAndSaveAsPartialModal from 'src/components/modals/partials/CustomMetricSaveAndSaveAsPartialModal';

const CustomMetricSaveAsModal = (props) => {
    const {
        hideModal,
        enforceFocus,
        valid,
        submitAction,
        submitting,
        buttonClicked,
        fieldErrors
    } = props;

    const computedValid = isValidWithoutMetricNameValidation(fieldErrors, valid);
    const handleOmSubmit = () => {
        submitAction(formName, 'create');
    };
    return (
        <Modal dataViewName="save-as-custom-metric" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Save as a new metric</Modal.Title>
                </Modal.Header>
                <CustomMetricSaveAndSaveAsPartialModal
                  valid={computedValid}
                  hideModal={hideModal}
                  runQQLLabel="Run QQL"
                  renderIfNoError={() => (
                      <>
                          <Modal.Body>
                              <MetricBuilderFormWithNameFieldOnly
                                handleSubmit={() => { handleOmSubmit(); }}
                                form={formName}
                              />
                          </Modal.Body>
                          <Modal.Footer>
                              <ButtonGroup>
                                  <Button
                                    label="Cancel"
                                    loading={submitting && buttonClicked === 'create'}
                                    onClick={() => { hideModal(); }}
                                  />
                                  <Button
                                    action
                                    onClick={handleOmSubmit}
                                    label="Save"
                                    loading={submitting && buttonClicked === 'create'}
                                  />
                              </ButtonGroup>
                          </Modal.Footer>
                      </>
                  )}
                />
            </Modal.Dialog>
        </Modal>
    );
};

CustomMetricSaveAsModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    buttonClicked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    valid: PropTypes.bool.isRequired,
    enforceFocus: PropTypes.bool,
    fieldErrors: PropTypes.object.isRequired,
};

CustomMetricSaveAsModal.defaultProps = {
    enforceFocus: false
};

const makeMapStateToProps = () => (state) => {
    const valid = isValid(formName)(state);
    const submitting = isSubmitting(formName)(state);
    const buttonClicked = selectMetricBuilderFormButtonClicked(state);
    return {
        valid,
        submitting,
        buttonClicked,
        fieldErrors: getFormSyncErrors(formName)(state)
    };
};

export default connect(makeMapStateToProps, {
    submitAction: submitMetricBuilderForm
})(CustomMetricSaveAsModal);
