import classnames from 'classnames';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';
import React from 'react';
import styles from 'src/stylesheets/views/profiles/profileInfoListItem.scss';

const EmptyProfileItem = () => (
    <div className={styles.wrapper}>
        <div className={styles.left}>
            <EmptyDiv width={32} height={32} />
            <div className={classnames(styles.name, styles.none)}>
                None
            </div>
        </div>
    </div>
);

export default EmptyProfileItem;
