import React, { Component } from 'react';
import _has from 'lodash/has';
import PropTypes from 'prop-types';

const hideIntercomIfAvailable = (manager) => {
    if (_has(window, 'Intercom')) {
        if (manager.modals.length === 1) {
            window.Intercom('update', {
                hide_default_launcher: true
            });
            window.Intercom('hide');
        }
    }
};

const showIntercomIfAvailable = (manager) => {
    if (_has(window, 'Intercom')) {
        if (manager.modals.length < 1) {
            window.Intercom('update', {
                hide_default_launcher: false
            });
        }
    }
};

let timer = null;

const withIntercomBubbleToggle = (WrappedComponent) => {
    class WithIntercomBubbleToggle extends Component {
        constructor(props) {
            super(props);
            this.onShow = this.onShow.bind(this);
        }

        componentWillUnmount() {
            const { manager } = this.props;

            clearTimeout(timer);
            timer = setTimeout(showIntercomIfAvailable, 250, manager);
        }

        onShow() {
            const { onShow, manager } = this.props;

            if (onShow) {
                onShow();
            }
            clearTimeout(timer);
            timer = setTimeout(hideIntercomIfAvailable, 25, manager);
        }

        render() {
            return <WrappedComponent {...this.props} onShow={this.onShow} />;
        }
    }

    WithIntercomBubbleToggle.propTypes = {
        onShow: PropTypes.func,
        manager: PropTypes.shape({
            modals: PropTypes.array.isRequired
        }).isRequired
    };
    return WithIntercomBubbleToggle;
};

export default withIntercomBubbleToggle;
