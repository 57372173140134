import * as customPropTypes from 'src/customPropTypes';
import { bulkAdAccountsUseCasesUpdateRequest } from 'src/actions/adAccountUseCases';
import { connect } from 'react-redux';
import { makeSelectAdAccountsByIds } from 'src/selectors/adAccounts';
import PropTypes from 'prop-types';
import React from 'react';
import ActivateBulkAccountUseCasesModal from 'src/components/modals/partials/ActivateBulkAccountUseCasesModal';
import AccountTypeContext from 'src/components/context/AccountTypeContext';

const ActivateBulkAdAccountUseCasesModal = (props) => {
    const {
        hideModal,
        enforceFocus,
        adAccounts,
    } = props;

    const handleOnSaveClick = (checkedAndUncheckedUseCasesPerAccount) => {
        const { bulkAdAccountsUseCasesUpdateRequestAction } = props;
        bulkAdAccountsUseCasesUpdateRequestAction(checkedAndUncheckedUseCasesPerAccount);
    };

    return (
        <AccountTypeContext.Provider value={{ accountType: 'adAccounts' }}>
            <ActivateBulkAccountUseCasesModal
              accounts={adAccounts}
              onSave={handleOnSaveClick}
              hideModal={hideModal}
              enforceFocus={enforceFocus}
            />
        </AccountTypeContext.Provider>
    );
};

ActivateBulkAdAccountUseCasesModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    adAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    adAccounts: customPropTypes.adAccounts.isRequired,
    bulkAdAccountsUseCasesUpdateRequestAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectAdAccountsByIds = makeSelectAdAccountsByIds();
    return (state, ownProps) => ({
        adAccounts: selectAdAccountsByIds(state, ownProps.adAccountIds)
    });
};

export default connect(makeMapStateToProps, {
    bulkAdAccountsUseCasesUpdateRequestAction: bulkAdAccountsUseCasesUpdateRequest
})(ActivateBulkAdAccountUseCasesModal);
