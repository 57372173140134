import BackLink from 'src/components/BackLink';
import Modal from 'src/components/modals/layout/Modal';
import ProfileSearch from 'src/components/views/profiles/search/ProfileSearch';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Button from 'src/components/buttons/Button';
import styles from 'src/stylesheets/profileSearch.scss';
import ProfileAddSidebar from 'src/components/views/profiles/search/sidebar/ProfileAddSidebar';
import withAccountSearchHandling from 'src/components/withAccountSearchHandling';
import { connect } from 'react-redux';
import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import { bulkProfileAddRequest } from 'src/actions/profiles';
import { selectIsAuthenticatedSearchResultPage } from 'src/selectors/profileSearch';
import { listName } from 'src/utils/profileSearch';
import {
    profileSearchReset,
    socialNetworkProfileShowListByStateRequest,
    socialNetworkSearchReset
} from 'src/actions/profileSearch';

const ProfileSearchModal = (props) => {
    const {
        enforceFocus,
        isAuthenticatedSearchResultPage,
        socialNetworkSearchResetAction,
        checkedAccountIds,
        isBulkProfileAdding,
        handleOnHideModal,
        profilesAddRequestAction,
        state,
        socialNetworkProfileShowListByStateRequestAction
    } = props;

    useEffect(() => {
        const { profileSearchResetAction } = props;
        if (state) {
            socialNetworkProfileShowListByStateRequestAction(state);
        }
        return () => {
            profileSearchResetAction();
            socialNetworkSearchResetAction();
        };
    }, []);

    const handleOnProfileAdd = () => {
        const { onAddAccounts } = props;
        profilesAddRequestAction(checkedAccountIds);
        onAddAccounts();
    };

    const optionalProps = {};
    if (isAuthenticatedSearchResultPage) {
        optionalProps.leftContent = <BackLink text="Back" onClick={socialNetworkSearchResetAction} />;
    }
    const buttonLabel = `${isBulkProfileAdding ? 'Adding' : 'Add'} profile${checkedAccountIds.length > 1 ? 's' : ''}`;

    return (
        <Modal dataViewName="profile-search-modal" onHide={handleOnHideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={handleOnHideModal} {...optionalProps} disabled={isBulkProfileAdding}>
                    <Modal.Title>Search for profiles</Modal.Title>
                </Modal.Header>
                <div className={styles.wrapper}>
                    <div className={styles.body}>
                        <div className={styles.content}>
                            <ProfileSearch isAuthenticatedSearchResultPage={isAuthenticatedSearchResultPage} />
                        </div>
                    </div>
                    <ProfileAddSidebar />
                </div>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          label="Cancel"
                          onClick={() => { handleOnHideModal(); }}
                        />
                        <Button
                          label={buttonLabel}
                          action
                          disabled={checkedAccountIds.length === 0}
                          loading={isBulkProfileAdding}
                          onClick={handleOnProfileAdd}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

ProfileSearchModal.propTypes = {
    checkedAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleOnHideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    isAuthenticatedSearchResultPage: PropTypes.bool.isRequired,
    socialNetworkSearchResetAction: PropTypes.func.isRequired,
    onAddAccounts: PropTypes.func.isRequired,
    isBulkProfileAdding: PropTypes.bool.isRequired,
    profilesAddRequestAction: PropTypes.func.isRequired,
    profileSearchResetAction: PropTypes.func.isRequired,
    state: PropTypes.string,
    socialNetworkProfileShowListByStateRequestAction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isAuthenticatedSearchResultPage: selectIsAuthenticatedSearchResultPage(state),
});

export default connect(mapStateToProps, {
    profilesAddRequestAction: ensurePermission(bulkProfileAddRequest, writePermissions.addProfile),
    socialNetworkProfileShowListByStateRequestAction: socialNetworkProfileShowListByStateRequest,
    profileSearchResetAction: profileSearchReset,
    socialNetworkSearchResetAction: socialNetworkSearchReset
})(withAccountSearchHandling(ProfileSearchModal, listName));
