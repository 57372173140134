import Popover from 'src/components/popovers/layout/Popover';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import InlineListWithSearchbar from 'src/components/inlineList/InlineListWithSearchbar';
import { cleanupProfileAddToOrRemoveFromGroup } from 'src/actions/profiles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { selectIsAddRemoveGroupsState, selectUserCreatedGroups } from 'src/selectors/groups';
import { groupAddRequest } from 'src/actions/groups';
import styles from 'src/stylesheets/popovers/addGroupsToProfilePopover.scss';
import { getListOption } from 'src/utils/groups';
import { renderEmptyGroup } from 'src/components/modals/GroupSelectorModal';
import { makeLengthValidator } from 'src/components/inlineList/validators';

const minMaxValidator = makeLengthValidator('group', 2, 25);

const ManageGroupsOfAccountsPopover = (props) => {
    const {
        accountId,
        selectedGroupIds,
        groups,
        addRemoveGroupsState
    } = props;
    const { isPending } = addRemoveGroupsState;
    useEffect(() => {
        const { isPending: pending, success, requested } = addRemoveGroupsState;
        const { hidePopover, cleanupProfileAddToOrRemoveFromGroupAction } = props;
        if (requested && !pending && success) {
            cleanupProfileAddToOrRemoveFromGroupAction();
            hidePopover();
        }
    }, [addRemoveGroupsState]);

    const options = getListOption(groups);

    const handleOnApplyClick = (currentSelectedOptions) => {
        const { accountAddToOrRemoveFromGroupRequestAction } = props;
        accountAddToOrRemoveFromGroupRequestAction([accountId], currentSelectedOptions);
    };

    const handleOnCreateNewGroup = (newGroup) => {
        const { groupAddRequestAction } = props;
        groupAddRequestAction({ name: newGroup });
    };

    return (
        <Popover id="addGroupToProfilePopover" width={360} height={500}>
            <div style={styles} className={styles.wrapper}>
                <div className={styles.body}>
                    <InlineListWithSearchbar
                      options={options}
                      optionName="group"
                      entityIds={[accountId]}
                      selectedOptionIds={selectedGroupIds}
                      renderEmptyOptions={renderEmptyGroup}
                      applyAction={handleOnApplyClick}
                      createNewEntityAction={handleOnCreateNewGroup}
                      isApplyActionPending={isPending}
                      validateCreateNewOption={minMaxValidator}
                    />
                </div>
            </div>
        </Popover>
    );
};

ManageGroupsOfAccountsPopover.propTypes = {
    accountId: PropTypes.string.isRequired,
    selectedGroupIds: PropTypes.arrayOf(PropTypes.string).isRequired,

    groups: customPropTypes.groups.isRequired,
    groupAddRequestAction: PropTypes.func.isRequired,
    accountAddToOrRemoveFromGroupRequestAction: PropTypes.func.isRequired,
    addRemoveGroupsState: customPropTypes.asyncDataLoadingState.isRequired,
    hidePopover: PropTypes.func.isRequired,
    cleanupProfileAddToOrRemoveFromGroupAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => (state) => ({
    groups: selectUserCreatedGroups(state),
    addRemoveGroupsState: selectIsAddRemoveGroupsState(state)
});

export default connect(makeMapStateToProps, {
    groupAddRequestAction: groupAddRequest,
    cleanupProfileAddToOrRemoveFromGroupAction: cleanupProfileAddToOrRemoveFromGroup
})(ManageGroupsOfAccountsPopover);
