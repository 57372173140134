import { adAccountAddToOrRemoveFromGroupRequest } from 'src/actions/adAccounts';
import GroupSelectorModal from 'src/components/modals/GroupSelectorModal';
import { connect } from 'react-redux';
import {
    makeSelectGroupsIdsByAdAccountIdsWithIndeterminatedGroups,
} from 'src/selectors/profiles';

const makeMapStateToProps = () => {
    const selectGroupsIdsByProfileIdsWithIndeterminatedGroups = makeSelectGroupsIdsByAdAccountIdsWithIndeterminatedGroups();
    return (state, ownProps) => {
        const { adAccountIds } = ownProps;
        const selectedGroupIds = selectGroupsIdsByProfileIdsWithIndeterminatedGroups(state, adAccountIds);
        return {
            accountIds: adAccountIds,
            selectedGroupIds: selectedGroupIds.selectedGroupIds,
            indeterminateGroupIds: selectedGroupIds.indeterminateGroupIds
        };
    };
};

const GroupSelectorProfileModal = connect(makeMapStateToProps, {
    accountAddToOrRemoveFromGroupRequestAction: adAccountAddToOrRemoveFromGroupRequest
})(GroupSelectorModal);

export default GroupSelectorProfileModal;
