import React from 'react';
import AccountAddSidebar from 'src/components/account/layout/AccountAddSidebar';
import { makeSelectAdAccountTempIdsSortedAlphabitically } from 'src/selectors/adAccountSearch';
import { connect } from 'react-redux';
import withAdAccountAddingState from 'src/components/views/adAccounts/withAdAccountAddingState';
import withRemoveListItemIcon from 'src/components/withRemoveListItemIcon';
import CheckedAdAccount from 'src/components/views/adAccounts/search/CheckedAdAccount';
import PropTypes from 'prop-types';
import { makeSelectCheckedValues } from 'src/selectors/lists';
import { listName } from 'src/utils/adAccountSearch';

const ProfileInfoListItemWithRemoveListItemIcon = withAdAccountAddingState(withRemoveListItemIcon(CheckedAdAccount));

const renderSelectedAccounts = (id) => <ProfileInfoListItemWithRemoveListItemIcon fieldName={id} id={id} listName={listName} />;

const AdAccountAddSidebar = (props) => {
    const { sortedAdAccountIds } = props;
    return (
        <AccountAddSidebar
          accountType="adAccounts"
          accountIds={sortedAdAccountIds}
          renderSelectedAccounts={renderSelectedAccounts}
          listName={listName}
        />
    );
};

AdAccountAddSidebar.propTypes = {
    sortedAdAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

const makeMapStateToProps = () => {
    const selectAdAccountTempIdsSortedAlphabitically = makeSelectAdAccountTempIdsSortedAlphabitically();
    const selectCheckedValues = makeSelectCheckedValues();
    return (state) => {
        const checkedAdAccountIds = selectCheckedValues(state, listName);
        return {
            sortedAdAccountIds: selectAdAccountTempIdsSortedAlphabitically(state, checkedAdAccountIds)
        };
    };
};

export default connect(makeMapStateToProps)(AdAccountAddSidebar);
