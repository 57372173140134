import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import _includes from 'lodash/includes';
import _isNumber from 'lodash/isNumber';
import _slice from 'lodash/slice';
import Checkbox from 'src/components/forms/inputs/Checkbox';
import classnames from 'classnames';
import Feedback from 'src/components/feedback/Feedback';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import Radio from 'src/components/forms/inputs/Radio';
import styles from 'src/stylesheets/inputs/selectList.scss';
import ToggleableDescription from 'src/components/ToggleableDescription';
import ToggleSwitch from 'src/components/forms/inputs/ToggleSwitch';
import withTooltip from 'src/components/withToolTip';

const CheckboxWithTooltip = withTooltip(Checkbox);

class SelectList extends Component {
    constructor(props) {
        super(props);
        this.handleOnCheckBoxChange = this.handleOnCheckBoxChange.bind(this);
        this.handleOnRadioChange = this.handleOnRadioChange.bind(this);
        this.toggleShowDescription = this.toggleShowDescription.bind(this);
        const { options } = this.props;
        this.state = {
            descriptionsVisibility: options.map((option) => ({ [option.id]: false }))
        };
    }

    handleOnCheckBoxChange(e) {
        const { onChange, value, options } = this.props;
        const { checked, name } = e.target;
        const newSelection = _slice(value);
        if (checked) {
            newSelection.unshift(name);
        } else {
            newSelection.splice(newSelection.indexOf(name), 1);
        }
        // always sort by the index of the option in order to perform dirty checks
        const optionKeys = options.map((option) => option.id);
        newSelection.sort((a, b) => optionKeys.indexOf(a) - optionKeys.indexOf(b));
        onChange(newSelection);
    }

    handleOnRadioChange(e) {
        const { onChange } = this.props;
        const selectedOption = [e.target.name];
        onChange(selectedOption.pop());
    }

    toggleShowDescription(id) {
        const { descriptionsVisibility } = this.state;
        const newState = Object.assign({}, descriptionsVisibility, { [id]: !descriptionsVisibility[id] });
        this.setState({ descriptionsVisibility: newState });
    }

    render() {
        const {
            options, value, onFocus, onBlur, name, multiSelect, disabledItems, maxHeight, indeterminateItems, checkboxTooltip
        } = this.props;
        const optionalProps = {};
        if (_isNumber(maxHeight)) {
            optionalProps.style = { maxHeight, overflowY: 'scroll' };
        }

        const { descriptionsVisibility } = this.state;
        return (
            <div className={styles.selectList} {...optionalProps}>
                {
                    options.map((option, idx, allOptions) => {
                        const isLastItem = idx === allOptions.length - 1;
                        const isPotentialScrollContext = _isNumber(maxHeight);
                        const showToggleSwitch = !!option.toggleSwitch;
                        return (
                            <div className={classnames(styles.selectListItemWrapper, { [styles.lastItemInNoScrollContext]: isLastItem && !isPotentialScrollContext })} key={option.id}>
                                <div className={styles.selectWithInfoIcon}>
                                    {
                                        (multiSelect && checkboxTooltip === '' && showToggleSwitch)
                                        && (
                                        <ToggleSwitch
                                          name={option.id}
                                          text={option.label}
                                          disabled={_includes(disabledItems, option.id)}
                                          checked={_includes(value, option.id)}
                                          onChange={this.handleOnCheckBoxChange}
                                        />
                                        )
                                    }
                                    {
                                        (multiSelect && checkboxTooltip === '' && !showToggleSwitch)
                                        && (
                                        <Checkbox
                                          name={option.id}
                                          text={option.label}
                                          disabled={_includes(disabledItems, option.id)}
                                          checked={_includes(value, option.id)}
                                          onChange={this.handleOnCheckBoxChange}
                                          defaultIndeterminate={_includes(indeterminateItems, option.id)}
                                        />
                                        )
                                    }
                                    {
                                        multiSelect && checkboxTooltip !== ''
                                        && (
                                        <div>
                                            {
                                                _includes(indeterminateItems, option.id)
                                                && (
                                                <CheckboxWithTooltip
                                                  name={option.id}
                                                  text={option.label}
                                                  disabled={_includes(disabledItems, option.id)}
                                                  checked={_includes(value, option.id)}
                                                  onChange={this.handleOnCheckBoxChange}
                                                  defaultIndeterminate={_includes(indeterminateItems, option.id)}
                                                  tooltip={checkboxTooltip}
                                                />
                                                )
                                            }
                                            {
                                                !_includes(indeterminateItems, option.id)
                                                && (
                                                <Checkbox
                                                  name={option.id}
                                                  text={option.label}
                                                  disabled={_includes(disabledItems, option.id)}
                                                  checked={_includes(value, option.id)}
                                                  onChange={this.handleOnCheckBoxChange}
                                                  defaultIndeterminate={_includes(indeterminateItems, option.id)}
                                                />
                                                )
                                            }
                                        </div>
                                        )
                                    }
                                    {
                                        !multiSelect
                                        && (
                                        <Radio
                                          name={option.id}
                                          text={option.label}
                                          disabled={_includes(disabledItems, option.id)}
                                          checked={_includes(value, option.id)}
                                          onChange={this.handleOnRadioChange}
                                        />
                                        )
                                    }
                                    {
                                        option.description
                                        && (
                                        <div className={styles.iconWrapper}>
                                            <IcomoonIcon
                                              icon="info"
                                              className={styles.icon}
                                              onClick={() => { this.toggleShowDescription(option.id); }}
                                            />
                                        </div>
                                        )
                                    }
                                </div>
                                {
                                    (descriptionsVisibility[option.id] && option.description)
                                    && (
                                        <div className={styles.optionInnerContent}>
                                            <ToggleableDescription>
                                                {option.description}
                                            </ToggleableDescription>
                                        </div>
                                    )
                                }
                                {
                                    (option.warning && _includes(value, option.id))
                                    && (
                                        <div className={styles.optionInnerContent}>
                                            <div className={styles.warning}>
                                                <Feedback allowHTML type="info" content={option.warning} responsive={false} />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        );
                    })
                }
                <input
                  type="hidden"
                  name={name}
                  value={value}
                  onFocus={onFocus}
                  onBlur={onBlur}
                />
            </div>
        );
    }
}

SelectList.propTypes = {
    options: customPropTypes.selectListOptions.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    multiSelect: PropTypes.bool,
    disabledItems: PropTypes.array,
    indeterminateItems: PropTypes.array,
    maxHeight: PropTypes.number,
    checkboxTooltip: PropTypes.string,
    toggleSwitch: PropTypes.bool
};

SelectList.defaultProps = {
    multiSelect: true,
    disabledItems: [],
    checkboxTooltip: '',
    toggleSwitch: false
};

export default SelectList;
