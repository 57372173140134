import { useState } from 'react';
import PropTypes from 'prop-types';

const ActiveSelectionHandler = ({
    children, initialActiveIndex, numberOptions, onCommit, onIndexChange
}) => {
    const [defaultActiveIndex, setDefaultActiveIndex] = useState(initialActiveIndex);

    const setIndex = (number) => {
        onIndexChange(defaultActiveIndex, number);
        setDefaultActiveIndex(number);
    };

    const resetDefaultIndex = () => {
        onIndexChange(defaultActiveIndex, initialActiveIndex);
        setDefaultActiveIndex(initialActiveIndex);
    };

    const incrementActiveIndex = () => {
        const newIndex = Math.min(numberOptions - 1, defaultActiveIndex + 1);
        onIndexChange(defaultActiveIndex, newIndex);
        setDefaultActiveIndex(newIndex);
    };

    const decrementActiveIndex = () => {
        const newIndex = Math.max(0, defaultActiveIndex - 1);
        onIndexChange(defaultActiveIndex, newIndex);
        setDefaultActiveIndex(newIndex);
    };

    const commitActiveIndex = () => {
        if (defaultActiveIndex <= numberOptions) {
            onCommit(defaultActiveIndex);
        }
    };

    const keyHandler = (e) => {
        if (e.keyCode === 38) {
            decrementActiveIndex();
            e.preventDefault();
            return;
        }
        if (e.keyCode === 40) {
            incrementActiveIndex();
            e.preventDefault();
            return;
        }
        if (e.keyCode === 13) {
            commitActiveIndex();
            e.preventDefault();
        }
    };

    return children(defaultActiveIndex, keyHandler, setIndex, resetDefaultIndex, onIndexChange);
};

ActiveSelectionHandler.propTypes = {
    children: PropTypes.func.isRequired,
    numberOptions: PropTypes.number.isRequired,
    initialActiveIndex: PropTypes.number.isRequired,
    onCommit: PropTypes.func.isRequired,
    onIndexChange: PropTypes.func
};

ActiveSelectionHandler.defaultProps = {
    onIndexChange: () => {}
};

export default ActiveSelectionHandler;
