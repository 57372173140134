import React from 'react';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/inlineList/filteredOptionList.scss';
import FilteredOptionListItem from 'src/components/inlineList/FilteredOptionListItem';
import classnames from 'classnames';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';

const FilteredOptionList = (props) => {
    const {
        filteredOptions, onOptionClick, activeIndex
    } = props;

    return (
        <div>
            {
                filteredOptions.map((option, i) => (
                    <FilteredOptionListItem
                      key={`list-${option.id}`}
                      active={activeIndex === i}
                      onClick={() => { onOptionClick(option); }}
                    >
                        <div className={classnames(styles.filteredOptionItem)}>
                            <DivWithTooltip tooltip={option.label} className={styles.filteredListOptionName}>
                                {option.label}
                            </DivWithTooltip>
                        </div>
                    </FilteredOptionListItem>
                ))
            }
        </div>
    );
};

FilteredOptionList.propTypes = {
    filteredOptions: customPropTypes.listOptions.isRequired,
    onOptionClick: PropTypes.func.isRequired,
    activeIndex: PropTypes.number.isRequired
};

export default FilteredOptionList;
