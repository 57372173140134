import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/menuButton.scss';
import { isExternalLink } from 'src/utils/routes';

const MenuButton = ({
    to, onClick, active, activeSpace, icon, label, multi, disabled
}) => {
    if (isExternalLink(to)) {
        return (
            <a href={to} target="_blank" rel="noopener noreferrer" className={styles.menuButton} onClick={onClick}>
                {label}
            </a>
        );
    }

    const linkProps = {
        to,
        onClick,
        className: classnames(styles.menuButton, { [styles.active]: active }, { [styles.activeSpace]: activeSpace }, { [styles.disabled]: disabled })
    };

    return (
        <Link {...linkProps}>
            <div className={styles.labelIconAndIndicator}>
                {icon && <IcomoonIcon className={styles.icon} icon={icon} />}
                {label && <span>{label}</span>}
                {activeSpace && <div className={styles.indicator} />}
            </div>
            {multi && <IcomoonIcon className={styles.multiIcon} icon="chevron-big-right" /> }
        </Link>
    );
};

MenuButton.propTypes = {
    icon: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    to: customPropTypes.linkTo,
    onClick: PropTypes.func,
    active: PropTypes.bool,
    activeSpace: PropTypes.bool,
    multi: PropTypes.bool,
    disabled: PropTypes.bool
};

MenuButton.defaultProps = {
    active: false,
    activeSpace: false,
    onClick: () => {},
    multi: false,
    disabled: false
};

export default MenuButton;
