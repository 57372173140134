import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import AuthUserInAnotherSpaceWarningModal from 'src/components/modals/AuthUserInAnotherSpaceWarningModal';
import { socialNetworkSearchSnapchatForceConnectRequest } from 'src/actions/profileSearch';

const SnapchatAuthUserInAnotherSpaceSocialNetworkSearchWarningModal = (props) => {
    const handleCompleteAuthenticationButtonClick = () => {
        const {
            accessToken,
            expiresIn,
            refreshAccessToken,
            platformUserId,
            displayName,
            socialNetworkSearchSnapchatForceConnectRequestAction
        } = props;
        socialNetworkSearchSnapchatForceConnectRequestAction(
            accessToken,
            expiresIn,
            refreshAccessToken,
            platformUserId,
            displayName
        );
    };

    const { hideModal, enforceFocus } = props;

    return (
        <AuthUserInAnotherSpaceWarningModal
          hideModal={hideModal}
          enforceFocus={enforceFocus}
          onCompleteAuthenticationClick={handleCompleteAuthenticationButtonClick}
          networkType="snapchat"
        />
    );
};

SnapchatAuthUserInAnotherSpaceSocialNetworkSearchWarningModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    accessToken: PropTypes.string.isRequired,
    refreshAccessToken: PropTypes.string.isRequired,
    expiresIn: PropTypes.number.isRequired,
    platformUserId: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    socialNetworkSearchSnapchatForceConnectRequestAction: PropTypes.func.isRequired
};

export default connect(null, {
    socialNetworkSearchSnapchatForceConnectRequestAction: socialNetworkSearchSnapchatForceConnectRequest
})(SnapchatAuthUserInAnotherSpaceSocialNetworkSearchWarningModal);
