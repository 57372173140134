import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { cronExpressionNextRunDateRequest } from 'src/actions/dashboardReportSchedule';
import { connect } from 'react-redux';
import { makeSelectCronNextRunDate } from 'src/selectors/dashboardReportSchedule';
import NextRunDate from 'src/components/dashboardReportSchedule/NextRunDate';
import * as customPropTypes from 'src/customPropTypes';

const getHashedIdentifier = (expression, timezone) => JSON.stringify({ expression, timezone });

const CronExpressionNextRunDate = (props) => {
    const {
        cronExpression, timezone, nextRunDateState
    } = props;

    useEffect(() => {
        const { cronExpressionNextRunDateGetRequestAction } = props;
        const identifier = getHashedIdentifier(cronExpression, timezone);
        if (nextRunDateState.requested === false) {
            cronExpressionNextRunDateGetRequestAction(cronExpression, timezone, identifier);
        }
    }, [cronExpression, timezone]);

    return <NextRunDate timezone={timezone} nextRunDateState={nextRunDateState} />;
};

CronExpressionNextRunDate.propTypes = {
    cronExpression: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    nextRunDateState: customPropTypes.nextRunTimeState.isRequired,
    cronExpressionNextRunDateGetRequestAction: PropTypes.func.isRequired,
};

const makeMapStateToProps = () => {
    const selectCronNextRunDate = makeSelectCronNextRunDate();
    return (state, ownProps) => ({
        nextRunDateState: selectCronNextRunDate(state, getHashedIdentifier(ownProps.cronExpression, ownProps.timezone)),
    });
};

export default connect(makeMapStateToProps, {
    cronExpressionNextRunDateGetRequestAction: cronExpressionNextRunDateRequest
})(CronExpressionNextRunDate);
