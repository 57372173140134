import React, { useState } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import Popover from 'src/components/popovers/layout/Popover';
import ProfileSelectorContainer from 'src/components/profileSelector/ProfileSelectorContainer';
import PropTypes from 'prop-types';

const ProfileSelectorPopover = (props) => {
    const {
        handleProfileOrGroupClick,
        selectedProfilesOrGroups,
        hidePopover,
    } = props;

    const [filterQuery, setFilterQuery] = useState('');

    return (
        <Popover id="localProfileSelectorPopover">
            <ProfileSelectorContainer
              handleFilterQueryChange={setFilterQuery}
              handleProfileOrGroupClick={(clickedProfileOrGroup) => {
                  handleProfileOrGroupClick(clickedProfileOrGroup);
                  hidePopover();
              }}
              selectedProfilesOrGroups={selectedProfilesOrGroups}
              filterQuery={filterQuery}
            />
        </Popover>
    );
};

ProfileSelectorPopover.propTypes = {
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    handleProfileOrGroupClick: PropTypes.func.isRequired,
    hidePopover: PropTypes.func
};

export default ProfileSelectorPopover;
