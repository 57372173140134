import { updateSpaceRequestFormAction } from 'src/actions/spaces';
import { isSubmitting, submit } from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { getSpaceById } from 'src/selectors/spaces';
import { getClientLimits } from 'src/selectors/client';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import SpaceForm from 'src/components/forms/SpaceForm';

const SpaceEditModal = (props) => {
    const {
        hideModal, submitAction, space, clientLimits, submitting, enforceFocus, formName
    } = props;
    const initialFieldValues = {
        id: space.id,
        name: space.name,
        pageLimit: space.pageLimit,
        adAccountLimit: space.adAccountLimit,
        customLogo: {
            url: space.customLogo,
            meta: {}
        }
    };
    const { freeLimits } = clientLimits;
    return (
        <Modal dataViewName="edit-space" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Edit space</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SpaceForm
                      form={formName}
                      onSubmit={updateSpaceRequestFormAction}
                      initialValues={initialFieldValues}
                      freeProfileLimits={freeLimits.profiles}
                      freeAdAccountLimits={freeLimits.adAccounts}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button disabled={submitting} onClick={hideModal} label="Cancel" />
                        <Button
                          action
                          onClick={() => submitAction(formName)}
                          label="Update"
                          loading={submitting}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

SpaceEditModal.propTypes = {
    space: customPropTypes.spaceForManagement.isRequired,
    clientLimits: customPropTypes.clientLimits.isRequired,
    hideModal: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    formName: PropTypes.string.isRequired,
    enforceFocus: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
    const { spaceId } = ownProps;
    const formName = `editSpace-${spaceId}`;

    return {
        formName,
        space: getSpaceById(state, spaceId),
        submitting: isSubmitting(formName)(state),
        clientLimits: getClientLimits(state)
    };
}

export default connect(mapStateToProps, {
    submitAction: submit
})(SpaceEditModal);
