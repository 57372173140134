import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/iconButton.scss';

const IconButton = (props) => {
    const {
        onMouseOut, onMouseOver, onClick, onBlur, onFocus, icon, active, iconClassName
    } = props;
    return (
        <button
          className={classnames(styles.iconButton, { [styles.active]: active })}
          onClick={onClick}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
          onFocus={onFocus}
          onBlur={onBlur}
          type="button"
        >
            <IcomoonIcon icon={icon} className={classnames(styles.icon, iconClassName)} />
        </button>
    );
};

IconButton.propTypes = {
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
    active: PropTypes.bool,
    icon: PropTypes.string.isRequired,
    iconClassName: PropTypes.string
};

IconButton.defaultProps = {
    active: false,
    onClick: null,
    onMouseOver: null,
    onMouseOut: null,
    onBlur: null,
    onFocus: null,
    iconClassName: ''
};

export default IconButton;
