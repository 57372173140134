import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styles from 'src/stylesheets/profileUseCasesTable.scss';
import AccountPrivateStats from 'src/components/AccountPrivateStats';
import UseCasesTableHeader from 'src/components/forms/accountUseCasesTable/UseCasesTableHeader';
import AccountTypeContext from 'src/components/context/AccountTypeContext';
import AccountInfoListItemByAccountType from 'src/components/AccountInfoListItemByAccountType';
import ListTableRow from 'src/components/listTable/ListTableRow';
import ListTableCell from 'src/components/listTable/ListTableCell';
import StickyTableHeader from 'src/components/StickyTableHeader';
import classnames from 'classnames';

const AccountDetailUseCasesTableHeader = (props) => {
    const {
        useCaseFormFields,
        disabled,
        submitting,
        account,
    } = props;

    const { accountType, isDetailPage } = useContext(AccountTypeContext);
    return (
        <StickyTableHeader>
            <ListTableRow className={styles.column}>
                <ListTableCell isHeaderCell isHeaderViewLayout={false} className={classnames(styles.firstColumn, styles.column)}>
                    {
                        isDetailPage && <AccountPrivateStats account={account} forceActive />
                    }
                    {
                        !isDetailPage && <AccountInfoListItemByAccountType account={account} accountType={accountType} />
                    }
                </ListTableCell>
                <UseCasesTableHeader
                  useCaseFormFields={useCaseFormFields}
                  disabled={disabled}
                  submitting={submitting}
                />
            </ListTableRow>
        </StickyTableHeader>
    );
};

AccountDetailUseCasesTableHeader.propTypes = {
    useCaseFormFields: customPropTypes.useCaseFormFields.isRequired,
    account: customPropTypes.account.isRequired,
    disabled: PropTypes.bool,
    submitting: PropTypes.bool
};

AccountDetailUseCasesTableHeader.defaultProps = {
    disabled: false,
    submitting: false
};

export default AccountDetailUseCasesTableHeader;
