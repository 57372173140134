import React, { useState } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import GraphAuthUserConnectionWarningPopoverContent from 'src/components/authTransactions/GraphAuthUserConnectionWarningPopoverContent';
import Popover from 'src/components/popovers/layout/Popover';
import styles from 'src/stylesheets/popovers/graphAuthUserConnectionWarningPopover.scss';
import TabButton from 'src/components/buttons/TabButton';
import TabGroup from 'src/components/buttons/TabGroup';

const GraphAuthUserConnectionWarningPopover = (props) => {
    const {
        missingProfiles,
        additionalProfiles
    } = props;

    const missingCount = missingProfiles.length;
    const additionalCount = additionalProfiles.length;

    let tabIndex = 0;
    if (missingCount === 0 && additionalCount > 0) {
        tabIndex = 1;
    }
    const [tab, changeTab] = useState(tabIndex);
    const missingFacebookProfiles = missingProfiles.filter((profile) => profile.platformType === 'facebook');
    const missingInstagramProfiles = missingProfiles.filter((profile) => profile.platformType === 'instagram');
    const additionalFacebookProfiles = additionalProfiles.filter((profile) => profile.platformType === 'facebook');
    const additionalInstagramProfiles = additionalProfiles.filter((profile) => profile.platformType === 'instagram');

    return (
        <Popover id="profileAuthTransactionConnectError">
            <div className={styles.wrapper}>
                <div className={styles.tabs}>
                    <TabGroup>
                        <TabButton
                          active={tab === 0}
                          onClick={() => { changeTab(0); }}
                          label={`Unfulfilled profiles (${missingCount})`}
                        />
                        <TabButton
                          active={tab === 1}
                          onClick={() => { changeTab(1); }}
                          label={`Additional profiles (${additionalCount})`}
                        />
                    </TabGroup>
                </div>
                {
                    tab === 0
                        && (
                        <GraphAuthUserConnectionWarningPopoverContent
                          title="Unfulfilled profiles:"
                          description="Requested use-cases may not have been fulfilled for some profiles. If you wish to retry, but are no longer able to trigger the facebook authentication window, you will need to access your permission settings within the facebook app."
                          facebookProfiles={missingFacebookProfiles}
                          instagramProfiles={missingInstagramProfiles}
                        />
                        )
                }
                {
                    tab === 1
                    && (
                    <GraphAuthUserConnectionWarningPopoverContent
                      title="Additional profiles:"
                      description="During the authentication you may have granted permissions for profiles not included in this request, if these profiles exist within your quintly account then it will be possible to make use of this connection. If this was unintentional we recommend that you revisit your allowed permissions within the facebook app."
                      facebookProfiles={additionalFacebookProfiles}
                      instagramProfiles={additionalInstagramProfiles}
                    />
                    )
                }
            </div>
        </Popover>
    );
};

GraphAuthUserConnectionWarningPopover.propTypes = {
    missingProfiles: customPropTypes.missingAndAdditionalProfiles,
    additionalProfiles: customPropTypes.missingAndAdditionalProfiles,
};

GraphAuthUserConnectionWarningPopover.defaultProps = {
    missingProfiles: [],
    additionalProfiles: []
};

export default GraphAuthUserConnectionWarningPopover;
