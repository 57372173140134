import { makeSelectGroupById, makeSelectIsGroupEditing } from 'src/selectors/groups';
import { connect } from 'react-redux';
import { groupEditRequest } from 'src/actions/groups';
import GroupListItem from 'src/components/views/profiles/GroupListItem';
import { modalsShowGroupDelete } from 'src/actions/overlays';
import PropTypes from 'prop-types';

const makeMapStateToProps = () => {
    const selectGroupById = makeSelectGroupById();
    const selectIsGroupEditing = makeSelectIsGroupEditing();
    return (state, ownProps) => ({
        group: selectGroupById(state, ownProps.groupId),
        isGroupEditing: selectIsGroupEditing(state, ownProps.groupId)
    });
};

const GroupListItemContainer = connect(makeMapStateToProps, {
    modalsShowGroupDeleteAction: modalsShowGroupDelete,
    groupEditRequestAction: groupEditRequest
})(GroupListItem);

GroupListItemContainer.propTypes = {
    groupId: PropTypes.string.isRequired
};

export default GroupListItemContainer;
