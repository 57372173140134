import React from 'react';
import ProfileInfoListItem from 'src/components/views/profiles/ProfileInfoListItem';
import AdAccountInfoListItem from 'src/components/views/adAccounts/AdAccountInfoListItem';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';

const AccountInfoListItemByAccountType = ({
    account, accountType, highlight, adAccountProps
}) => {
    if (accountType === 'profiles') {
        return <ProfileInfoListItem profile={account} highlight={highlight} />;
    }
    if (accountType === 'adAccounts') {
        return <AdAccountInfoListItem adAccount={account} highlight={highlight} {...adAccountProps} />;
    }
    return null;
};

AccountInfoListItemByAccountType.propTypes = {
    account: customPropTypes.account.isRequired,
    accountType: customPropTypes.accountTypes.isRequired,
    highlight: PropTypes.string,
    adAccountProps: PropTypes.object
};

AccountInfoListItemByAccountType.defaultProps = {
    highlight: '',
    adAccountProps: {}
};

export default AccountInfoListItemByAccountType;
