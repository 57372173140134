import React from 'react';
import PropTypes from 'prop-types';
import StateIndicator from 'src/components/labels/StateIndicator';

const ProfileEventState = (props) => {
    const { state } = props;
    let finalState = 'disabled';
    if (state === 'positive') {
        finalState = 'ok';
    }

    if (state === 'negative') {
        finalState = 'error';
    }
    return (
        <StateIndicator state={finalState} />
    );
};

ProfileEventState.propTypes = {
    state: PropTypes.oneOf(['positive', 'negative', 'neutral'])
};

export default ProfileEventState;
