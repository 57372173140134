import _get from 'lodash/get';
import _isString from 'lodash/isString';
import _startsWith from 'lodash/startsWith';
import { createSelector } from 'reselect';

const getModalsFromStore = (state) => state.overlays.modals;

const modalPropMapper = {
    ProfileSearchModal: '?state',
    CreateReportModal: 'dashboardId',
    EditReportModal: 'dashboardReportId',
    MetricSettingsTileModal: 'dashboardMetricId',
    ProfileDetailsModal: 'profileId',
    UserCreateModal: null,
    UserEditModal: 'userId',
    UserNotificationSettingsModal: null,
    AddUpdateAuthUsersModal: 'authTransactionId',
    AdAccountDetailsModal: 'adAccountId',
    AdAccountAddModal: null
};

export const selectModalsInOrderToShow = createSelector(
    [
        getModalsFromStore
    ],
    (modals) => modals
);

export const selectModalFromUrlHash = createSelector(
    [
        (hash) => hash
    ],
    (hash) => {
        if (hash) {
            const hashWithoutHashChar = hash.substring(1);
            const splittedHash = hashWithoutHashChar.split('-');
            const modalType = splittedHash[0];
            const idProp = splittedHash[1];
            const modalPropName = _get(modalPropMapper, modalType);
            if (modalPropName === null) {
                return { modalType, modalProps: {}, identifier: modalType };
            }
            if (_isString(modalPropName)) {
                const isNullable = _startsWith(modalPropName, '?');
                if (!isNullable && idProp) {
                    return {
                        modalType,
                        modalProps: {
                            [modalPropName]: idProp
                        },
                        idProp
                    };
                }
                if (isNullable) {
                    return {
                        modalType,
                        modalProps: {
                            [modalPropName.substring(1)]: idProp
                        },
                        idProp
                    };
                }
            }
        }
        return undefined;
    }
);
