import { makeSelectFoldersSplitIntoChildrenOrNot } from 'src/selectors/folders';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { makeSelectDashboardsByIds } from 'src/selectors/dashboards';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import ModalListItem from 'src/components/views/folders/ModalListItem';
import FolderInModalListItemContainer from 'src/components/views/folders/FolderInModalListItemContainer';
import DashboardInModalListItemContainer from 'src/components/views/dashboards/DashboardInModalListItemContainer';
import List from 'src/components/List';
import { makeSort } from 'src/utils/dashboardFolders';
import ConfirmDeleteForm from 'src/components/forms/ConfirmDeleteForm';
import { foldersAndDashboardsDeleteRequestFormAction } from 'src/actions/folders';
import { isSubmitting, submit, getFormError } from 'redux-form';
import FormError from 'src/components/forms/feedback/FormError';

const getTitle = (dashboards, folders) => {
    const dashboardLabel = `dashboard${dashboards.length > 1 ? 's' : ''}`;
    const folderLabel = `folder${folders.length > 1 ? 's' : ''}`;
    if (dashboards.length > 0 && folders.length === 0) {
        return dashboardLabel;
    }
    if (folders.length > 0 && dashboards.length === 0) {
        return folderLabel;
    }
    return 'folders and dashboards';
};

const getWarningText = (foldersWithChildren, foldersWithoutChildren, title) => {
    const warning = `Are you sure you want to delete the selected ${title}?`;
    if (foldersWithChildren.length > 0) {
        return `${warning} ${foldersWithChildren.length + foldersWithoutChildren.length > 1 ? 'Some folders contain items.' : 'The folder contains items'}`;
    }
    return warning;
};

const formName = 'dashboardAndFolderDeleteForm';

export const DashboardAndFolderDeleteModal = (props) => {
    const {
        dashboardsToBeDeleted,
        foldersWithChildren,
        foldersWithoutChildren,
        hideModal,
        enforceFocus,
        fromFolderId,
        isDeleting,
        redirectToDashboards,
        submitAction,
        error
    } = props;
    const foldersTobeDeletedSorted = foldersWithoutChildren.concat(foldersWithChildren).sort(makeSort('asc'));
    const dashboardTobeDeletedSorted = dashboardsToBeDeleted.sort(makeSort('asc'));
    const title = getTitle(dashboardTobeDeletedSorted, foldersTobeDeletedSorted);
    const forceDeleteFolder = foldersWithChildren.length > 0;
    const initialValues = {
        folderIds: foldersTobeDeletedSorted.map((folder) => folder.id),
        dashboardIds: dashboardTobeDeletedSorted.map((dashboard) => dashboard.id),
        fromFolderId,
        redirectToDashboards,
        forceDeleteFolder
    };

    return (
        <Modal dataViewName="delete-dashboard-and-folder" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog width={620}>
                <Modal.Header>
                    <Modal.Title>Delete {title}</Modal.Title>
                </Modal.Header>
                <Modal.Body maxHeight={526}>
                    {
                        error && <FormError message={error.message} />
                    }
                    <div>
                        <div>
                            {getWarningText(foldersWithChildren, foldersWithoutChildren, title)}
                        </div>
                        <List border={false}>
                            {
                                foldersTobeDeletedSorted.map(({ id }) => (
                                    <ModalListItem key={`folder-${id}`} disabled fullOpaque>
                                        <FolderInModalListItemContainer folderId={id} />
                                    </ModalListItem>
                                ))
                            }
                            {
                                dashboardTobeDeletedSorted.map(({ id }) => (
                                    <ModalListItem key={`dashboard-${id}`} disabled fullOpaque>
                                        <DashboardInModalListItemContainer dashboardId={id} />
                                    </ModalListItem>
                                ))
                            }
                        </List>
                        <ConfirmDeleteForm
                          form={formName}
                          initialValues={initialValues}
                          forceDelete={forceDeleteFolder}
                          onSubmit={foldersAndDashboardsDeleteRequestFormAction}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button onClick={hideModal} label="Cancel" />
                        <Button
                          action
                          warning
                          icon="delete"
                          onClick={() => { submitAction(formName); }}
                          loading={isDeleting}
                          label={foldersWithChildren.length > 0 ? 'Force delete' : 'Delete'}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

DashboardAndFolderDeleteModal.propTypes = {
    foldersWithoutChildren: customPropTypes.folders.isRequired,
    foldersWithChildren: customPropTypes.folders.isRequired,
    dashboardsToBeDeleted: customPropTypes.dashboards.isRequired,
    folderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    dashboardIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    hideModal: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    enforceFocus: PropTypes.bool,
    fromFolderId: PropTypes.string.isRequired,
    redirectToDashboards: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    error: customPropTypes.dataLoadingError
};

const makeMapStateToProps = () => {
    const foldersSplitIntoChildrenOrNot = makeSelectFoldersSplitIntoChildrenOrNot();
    const selectDashboardsByIds = makeSelectDashboardsByIds();

    return (state, ownProps) => {
        const { folderIds, dashboardIds } = ownProps;
        const { foldersWithChildren, foldersWithoutChildren } = foldersSplitIntoChildrenOrNot(state, folderIds);
        const dashboardsToBeDeleted = selectDashboardsByIds(state, dashboardIds);
        return {
            foldersWithChildren,
            foldersWithoutChildren,
            dashboardsToBeDeleted,
            isDeleting: isSubmitting(formName)(state),
            error: getFormError(formName)(state)
        };
    };
};

export default connect(makeMapStateToProps, {
    submitAction: submit
})(DashboardAndFolderDeleteModal);
