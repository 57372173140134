import { isSubmitting, submit, change } from 'redux-form';
import { makeSelectDashboardMetricById, makeSelectInitialDashboardMetricSettings } from 'src/selectors/dashboardMetrics';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { metricSettingsTileUpdateRequestFormAction } from 'src/actions/dashboardMetrics';
import MetricTileSettingForm from 'src/components/forms/MetricTileSettingForm';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import withReactRouterLinkHandling from 'src/components/withReactRouterLinkHandling';
import withResourceCheck from 'src/components/modals/withResourceCheck';
import { getDashboardContext } from 'src/utils/filterSelectors';
import { makeSelectDateByContext } from 'src/selectors/filterSelectors';

const ButtonWithReactRouterLinkHandling = withReactRouterLinkHandling(Button);
const formName = 'metricSettingsTile';

const MetricSettingsTileModal = (props) => {
    const {
        hideModal,
        submitting,
        submitAction,
        initialValues,
        enforceFocus,
        dashboardMetric,
        isTable,
        defaultTimezone
    } = props;

    const buttonsLeft = (
        <ButtonGroup>
            <ButtonWithReactRouterLinkHandling
              to={{ pathname: `/metric-builder/${dashboardMetric.metricId}` }}
              label="Edit in metric builder"
              type="button"
              onClick={hideModal}
            />
        </ButtonGroup>
    );

    const tableRelatedProps = {
        hasAdditionalSettings: isTable,
    };

    if (isTable) {
        tableRelatedProps.columns = _get(dashboardMetric, 'metric.config.metaData.columns');
    }

    const metricName = dashboardMetric.displayName;
    const defaultMetricName = dashboardMetric.metric.name; // if no alias is set, we need to know the original name explicitly to set it as a placeholder
    const defaultVisualizationId = dashboardMetric.metric.visualizationId;

    return (
        <Modal dataViewName="metric-settings-tile" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Edit metric settings: {metricName}</Modal.Title>
                </Modal.Header>
                <Modal.Body centered>
                    <MetricTileSettingForm
                      form={formName}
                      defaultVisualizationId={defaultVisualizationId}
                      defaultMetricName={defaultMetricName}
                      initialValues={initialValues}
                      {...tableRelatedProps}
                      onSubmit={(data, dispatch) => metricSettingsTileUpdateRequestFormAction({ data, metricName, defaultVisualizationId }, dispatch)}
                      defaultTimezone={defaultTimezone}
                    />
                </Modal.Body>
                <Modal.Footer buttonsLeft={buttonsLeft}>
                    <ButtonGroup>
                        <Button disabled={submitting} onClick={hideModal} label="Cancel" />
                        <Button loading={submitting} action onClick={() => submitAction(formName)} label="Apply" />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

MetricSettingsTileModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    enforceFocus: PropTypes.bool,
    dashboardMetric: customPropTypes.dashboardMetric.isRequired,
    isTable: PropTypes.bool,
    defaultTimezone: PropTypes.string.isRequired
};

const MetricSettingsTileModalWithResourceCheck = withResourceCheck(MetricSettingsTileModal, 'dashboardMetric', 'metric settings');

const makeMapStateToProps = () => {
    const selectDashboardMetricById = makeSelectDashboardMetricById();
    const selectInitialDashboardMetricSettings = makeSelectInitialDashboardMetricSettings();

    const selectDateByContext = makeSelectDateByContext();

    return (state, ownProps) => {
        const { dashboardMetricId } = ownProps;
        const dashboardMetric = selectDashboardMetricById(state, dashboardMetricId);
        const filterContext = getDashboardContext(dashboardMetric.dashboardId);
        const isTable = _get(dashboardMetric, 'metric.visualization.type') === 'table';
        const initialValues = selectInitialDashboardMetricSettings(state, filterContext, dashboardMetric, isTable);

        const { timezone: defaultTimezone } = selectDateByContext(state, filterContext);

        return {
            initialValues,
            dashboardMetric,
            submitting: isSubmitting(formName)(state),
            isTable,
            defaultTimezone
        };
    };
};

export default connect(makeMapStateToProps, {
    submitAction: submit,
    changeAction: change
})(MetricSettingsTileModalWithResourceCheck);
