import PropTypes from 'prop-types';
import React from 'react';
import IcomoonIcon from 'src/components/IcomoonIcon';
import classnames from 'classnames';
import { isValidHex } from 'react-color/lib/helpers/color';
import _omit from 'lodash/omit';
import styles from 'src/stylesheets/colorPickerButton.scss';

const ColorPickerButton = (props) => {
    const { value, active, error } = props;

    const showPickerIcon = !value || error;
    const cleanedProps = _omit(props, ['active', 'error']);

    return (
        <button
          {...cleanedProps}
          type="button"
          className={classnames(styles.button, { [styles.active]: active }, { [styles.error]: error })}
        >
            {
                !showPickerIcon
                && <div className={styles.colorIndicator} style={{ backgroundColor: value }} />
            }
            {
                showPickerIcon
                && <IcomoonIcon icon="edit" className={styles.pickerIcon} />
            }
        </button>
    );
};

ColorPickerButton.propTypes = {
    value(props, propName) {
        const { [propName]: value } = props;
        const valueGiven = value !== null && value !== '';
        if (valueGiven && !isValidHex(value)) {
            return new Error('"value" given but not a valid hex code');
        }
        return null;
    },
    error: PropTypes.bool,
    active: PropTypes.bool
};

ColorPickerButton.defaultProps = {
    error: false
};

export default ColorPickerButton;
