import React from 'react';
import PropTypes from 'prop-types';
import { modalShowPostDetailInPostTagManager, modalHidePostDetailInPostTagManager } from 'src/actions/overlays';
import { connect } from 'react-redux';
import { makeSelectPostTagManagerPrevAndNextPostIdsByPostTagId } from 'src/selectors/posts';
import PostDetailModalScroller from 'src/components/postDetailModalScroller/PostDetailModalScroller';

const PostTagManagerPostDetailScroller = (props) => {
    const {
        modalShowPostDetailAction,
        modalHidePostDetailAction,
        postTagId
    } = props;
    const { prevPostId, nextPostId } = props;

    const handleModalShowPostDetailAction = (postId) => {
        modalShowPostDetailAction(postId, postTagId);
    };

    return (
        <PostDetailModalScroller
          modalHidePostDetailAction={modalHidePostDetailAction}
          modalShowPostDetailAction={handleModalShowPostDetailAction}
          prevPostId={prevPostId}
          nextPostId={nextPostId}
        />
    );
};

PostTagManagerPostDetailScroller.propTypes = {
    postId: PropTypes.string.isRequired,
    postTagId: PropTypes.string.isRequired,
    nextPostId: PropTypes.string,
    prevPostId: PropTypes.string,
    modalShowPostDetailAction: PropTypes.func.isRequired,
    modalHidePostDetailAction: PropTypes.func.isRequired,
    hasPostsTagsBeenUpdated: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectPrevAndNextPostIds = makeSelectPostTagManagerPrevAndNextPostIdsByPostTagId();
    return (state, ownProps) => {
        const { postTagId, postId } = ownProps;
        const { prevPostId, nextPostId } = selectPrevAndNextPostIds(state, postTagId, postId);
        return {
            prevPostId,
            nextPostId
        };
    };
};

export default connect(makeMapStateToProps, {
    modalShowPostDetailAction: modalShowPostDetailInPostTagManager,
    modalHidePostDetailAction: modalHidePostDetailInPostTagManager
})(PostTagManagerPostDetailScroller);
