import React, { Component } from 'react';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import withToolTip from 'src/components/withToolTip';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/input.scss';
import Text from 'src/components/forms/inputs/Text';

const IcomoonIconWithTooltip = withToolTip(IcomoonIcon);

class TextWithResetIcon extends Component {
    constructor(props) {
        super(props);
        this.handleResetClick = this.handleResetClick.bind(this);
    }

    handleResetClick() {
        const { onResetClick, value } = this.props;
        this.focus();
        onResetClick(value);
    }

    select() {
        this.inputComponent.select();
    }

    focus() {
        this.inputComponent.focus();
    }

    render() {
        const {
            value, onChange, onKeyDown, disabled, error
        } = this.props;
        const cleanedProps = _omit(this.props, ['onResetClick']);
        const showResetFilterIcon = value && value.length > 0;
        const layout = _get(this.props, 'layout', null);

        return (
            <div
              className={classnames(styles.inputWithResetWrapper, {
                  [styles.profileSearch]: layout === 'profileSearch',
                  [styles.listFilter]: layout === 'listFilter',
                  [styles.discover]: layout === 'discover',
                  [styles.dateSelector]: layout === 'dateSelector',
                  [styles.removeItemActive]: showResetFilterIcon,
                  [styles.disabled]: disabled
              })}
            >
                <Text
                  ref={(c) => { this.inputComponent = c; }}
                  {...cleanedProps}
                  onMouseOver={this.onMouseOverInput}
                  onMouseOut={this.onMouseOutInput}
                  value={value}
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                />
                <div
                  className={classnames(styles.resetFilterIcon, {
                      [styles.showResetFilterIcon]: showResetFilterIcon,
                      [styles.resetFilterIconDisabled]: disabled,
                      [styles.error]: error
                  })}
                >
                    <IcomoonIconWithTooltip
                      icon="clear"
                      tooltip="Clear"
                      onClick={this.handleResetClick}
                    />
                </div>
                { layout === 'dateSelector'
                    && (
                    <div className={classnames(styles.learnMoreIcon, { [styles.error]: error })}>
                        <IcomoonIconWithTooltip tooltip="Learn more" icon="info" onClick={() => { window.open('https://help.facelift-cloud.com/en/articles/8601298-using-dynamic-dates', '_blank'); }} />
                    </div>
                    )}
            </div>
        );
    }
}

TextWithResetIcon.propTypes = {
    onResetClick: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.bool
};

TextWithResetIcon.defaultProps = {
    disabled: false,
    error: false,
    onKeyDown: () => {}
};

export default TextWithResetIcon;
