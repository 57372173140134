import React from 'react';
import styles from 'src/stylesheets/views/profiles/profileAudienceDataView.scss';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';

const ProfileAudienceDataLoadingSkeleton = () => (
    <div className={styles.wrapper}>
        <div className={styles.sentence}><EmptyDiv width={500} height={18} /></div>
        <div className={styles.bars}>
            <div className={styles.bar}>
                <EmptyDiv height={16} />
            </div>
            <div className={styles.bar}>
                <EmptyDiv height={16} />
            </div>
            <div className={styles.bar}>
                <EmptyDiv height={16} />
            </div>
        </div>
    </div>
);

export default ProfileAudienceDataLoadingSkeleton;
