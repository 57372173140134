export const makeRequired = (entityName) => (value) => (value ? undefined : `${entityName} can not be empty.`);

export const makeLengthValidator = (entityName, min, max) => (value) => {
    if (value) {
        if (value.length < min) {
            return `The ${entityName} has to be at least ${min} characters long.`;
        }
        if (value.length > max) {
            return `This ${entityName} has to be maximum ${max} characters long.`;
        }
        return undefined;
    }
    return undefined;
};
