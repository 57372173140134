import { tryGetTimezoneAbbrevOrUtcOffsetOrNull } from 'src/components/dateSelector/utils';
import * as customPropTypes from 'src/customPropTypes';
import _capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/selectorLabels.scss';
import moment from 'moment-timezone';

const RelativeSelectedDateLabel = (props) => {
    const { selectedDate } = props;
    let text = '';
    let labelValue = '';

    if (selectedDate) {
        const { dynamicDate, timezone } = selectedDate;
        text = _capitalize(dynamicDate);
        const abbrev = tryGetTimezoneAbbrevOrUtcOffsetOrNull(timezone, moment());
        if (abbrev) {
            text = `${text} ${abbrev}`;
        }
    } else {
        text = 'No date selected';
        labelValue = 'Click to select';
    }
    return (
        <span>
            <span>
                {text}
            </span>
            {
                labelValue && <span className={styles.label}>({labelValue})</span>

            }
        </span>
    );
};

RelativeSelectedDateLabel.propTypes = {
    selectedDate: PropTypes.oneOfType([customPropTypes.relativeDate, PropTypes.bool]).isRequired
};

export default RelativeSelectedDateLabel;
