import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import ConfirmDeleteForm from 'src/components/forms/ConfirmDeleteForm';
import { isSubmitting, submit, getFormError } from 'redux-form';
import FormError from 'src/components/forms/feedback/FormError';
import { makeSelectDashboardReportById } from 'src/selectors/dashboardReports';
import { dashboardReportDeleteRequestFormAction } from 'src/actions/dashboardReports';

const formName = 'dashboardReportDeleteForm';

export const DashboardReportDeleteModal = (props) => {
    const {
        dashboardReport,
        hideModal,
        enforceFocus,
        isDeleting,
        submitAction,
        error
    } = props;
    const { id } = dashboardReport;
    return (
        <Modal dataViewName="dashboard-report-delete" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>Delete schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        error && <FormError message={error.message} />
                    }
                    <div>
                        <div>
                            The report settings and email delivery details will be deleted but the dashboard of the report will remain.
                        </div><br />
                        <ConfirmDeleteForm
                          form={formName}
                          initialValues={{ dashboardReportId: id }}
                          onSubmit={dashboardReportDeleteRequestFormAction}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button onClick={hideModal} label="Cancel" />
                        <Button
                          action
                          warning
                          icon="delete"
                          label="Delete"
                          onClick={() => { submitAction(formName); }}
                          loading={isDeleting}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

DashboardReportDeleteModal.propTypes = {
    dashboardReportId: PropTypes.string.isRequired,
    dashboardReport: customPropTypes.dashboardReport.isRequired,
    hideModal: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    enforceFocus: PropTypes.bool,
    submitAction: PropTypes.func.isRequired,
    error: customPropTypes.dataLoadingError
};

const makeMapStateToProps = () => {
    const selectDashboardReportById = makeSelectDashboardReportById();
    return (state, ownProps) => ({
        dashboardReport: selectDashboardReportById(state, ownProps.dashboardReportId),
        isDeleting: isSubmitting(formName)(state),
        error: getFormError(formName)(state)
    });
};

export default connect(makeMapStateToProps, {
    submitAction: submit,
})(DashboardReportDeleteModal);
