import _omit from 'lodash/omit';
import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/backLink.scss';
import withTooltip from 'src/components/withToolTip';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const BackLink = (props) => {
    const {
        text, icon, disabled, tooltip
    } = props;
    const cleanedProps = _omit(props, 'text', 'disabled', 'tooltip');
    return (
        <Link {...cleanedProps} className={classnames(styles.link, { [styles.disabled]: disabled })}>
            {
                tooltip && <IcomoonIconWithTooltip className={styles.arrow} icon={icon} tooltip={tooltip} />
            }
            {
                !tooltip && <IcomoonIcon className={styles.arrow} icon={icon} />
            }
            {
                text && <span className={styles.text}>{text}</span>
            }
        </Link>
    );
};

BackLink.propTypes = {
    text: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    tooltip: PropTypes.string
};

BackLink.defaultProps = {
    disabled: false,
    text: '',
    icon: 'chevron-big-left',
    tooltip: ''
};

export default BackLink;
