import React from 'react';
import styles from 'src/stylesheets/listTable/listTableHeader.scss';
import StickyTableHeader from 'src/components/StickyTableHeader';
import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import withPopover from 'src/components/withPopover';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import ListTableCell from 'src/components/listTable/ListTableCell';

const IcomoonIconWithPopover = withPopover(IcomoonIcon);

const ListTableHeader = (props) => {
    const {
        columns, sortByAction, sortBy, sortDir, offsetFromPageHeader, className
    } = props;
    return (
        <StickyTableHeader offsetFromPageHeader={offsetFromPageHeader} className={className}>
            <tr>
                {
                    columns.map(({
                        id, content, infoPopover, align, isSortable, width, className: ColumnClassName
                    }) => {
                        const contentToShow = (
                            <span
                              className={classnames({ [styles.sortable]: isSortable })}
                              onClick={isSortable ? () => { sortByAction(id); } : () => {}}
                            >
                                {content}
                            </span>
                        );
                        return (
                            <ListTableCell
                              isHeaderCell
                              align={align}
                              key={id}
                              width={width}
                              className={classnames({
                                  [styles.headerSortDown]: sortBy === id && sortDir === 'desc',
                                  [styles.headerSortUp]: sortBy === id && sortDir === 'asc'
                              }, ColumnClassName)}
                            >
                                {
                                    infoPopover
                                        ? (
                                            <div className={styles.info}>
                                                {contentToShow}
                                                <IcomoonIconWithPopover
                                                  icon="help"
                                                  overlay={infoPopover}
                                                  className={styles.helpIcon}
                                                />
                                            </div>
                                        )
                                        : contentToShow
                                }
                            </ListTableCell>
                        );
                    })
                }
            </tr>
        </StickyTableHeader>
    );
};

ListTableHeader.propTypes = {
    columns: customPropTypes.listTableHeaderColumns.isRequired,
    sortBy: PropTypes.string,
    sortDir: PropTypes.string,
    offsetFromPageHeader: PropTypes.bool,
    sortByAction: PropTypes.func,
    className: PropTypes.string
};

ListTableHeader.defaultProps = {
    sortByAction: () => {},
    sortBy: '',
    sortDir: '',
    offsetFromPageHeader: false,
    className: ''
};

export default ListTableHeader;
