import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import EntityOperationInfoModal from 'src/components/modals/EntityOperationInfoModal';
import { makeSelectActivePostTagsByIds } from 'src/selectors/postTags';

const PostTagsForceArchiveModalContainer = (props) => {
    const {
        nonArchivablePostTags,
        forceArchivableTagInfos,
        hideModal,
        enforceFocus
    } = props;
    const nonArchivablePostTagNames = nonArchivablePostTags.map((nonDeletablePostTag) => nonDeletablePostTag.name);
    return (
        <EntityOperationInfoModal
          forceOperationalEntityInfos={forceArchivableTagInfos}
          nonOperationalEntityNames={nonArchivablePostTagNames}
          operationalType="forceTagArchive"
          hideModal={hideModal}
          enforceFocus={enforceFocus}
        />
    );
};

PostTagsForceArchiveModalContainer.propTypes = {
    forceArchivableTagInfos: customPropTypes.forceOperationalEntityInfos.isRequired,
    nonArchivablePostTags: customPropTypes.postTags.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
};

const makeMapStateToProps = () => {
    const selectActivePostTagsByIds = makeSelectActivePostTagsByIds();
    return (state, ownProps) => ({
        nonArchivablePostTags: selectActivePostTagsByIds(state, ownProps.nonArchivableTagIds)
    });
};

export default connect(makeMapStateToProps)(PostTagsForceArchiveModalContainer);
