import { connect } from 'react-redux';
import { makeSelectAdAccountById } from 'src/selectors/adAccounts';
import { modalsShowProfileDetails } from 'src/actions/overlays';
import AccountListItem from 'src/components/authTransactions/authTransactionSteps/AccountListItem';
import PropTypes from 'prop-types';

const makeMapStateToProps = () => {
    const selectAdAccountById = makeSelectAdAccountById();
    return (state, ownProps) => {
        const {
            adAccountId,
            listName
        } = ownProps;
        const adAccount = selectAdAccountById(state, adAccountId);
        return {
            account: adAccount,
            listName,
            accountType: 'adAccounts'
        };
    };
};

const AdAccountListItemContainer = connect(makeMapStateToProps, {
    modalsShowProfileDetailsAction: modalsShowProfileDetails
})(AccountListItem);

AdAccountListItemContainer.propTypes = {
    adAccountId: PropTypes.string.isRequired,
    listName: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired
};

export default AdAccountListItemContainer;
