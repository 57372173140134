import IcomoonIcon from 'src/components/IcomoonIcon';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/modals/externalAuthTransactionCloseModal.scss';

const AuthUserShowPagesConfirmedModal = (props) => {
    const { enforceFocus } = props;

    const headerContent = (
        <div className={styles.header}>
            <IcomoonIcon icon="success" className={styles.success} />
            <span>All done</span>
        </div>
    );

    const handleOnClose = () => {

    };

    return (
        <Modal
          dataViewName="auth-user-granted-permission-confirmation-modal"
          onHide={handleOnClose}
          enforceFocus={enforceFocus}
        >
            <Modal.Dialog>
                <Modal.Header disabled leftContent={headerContent} />
                <Modal.Body>
                    <div>
                        Profiles have been connected. You can now close the page.
                    </div>
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    );
};

AuthUserShowPagesConfirmedModal.propTypes = {
    hideModal: PropTypes.func,
    enforceFocus: PropTypes.func
};

export default AuthUserShowPagesConfirmedModal;
