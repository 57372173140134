import RelativeSelectedDateLabel from 'src/components/dateSelector/RelativeSelectedDateLabel';
import * as customPropTypes from 'src/customPropTypes';
import _omit from 'lodash/omit';
import AbsoluteSelectedDateLabel from 'src/components/dateSelector/AbsoluteSelectedDateLabel';
import Button from 'src/components/buttons/Button';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const CombinedDateSelectorButton = forwardRef((props, ref) => {
    const { selectedDate, leftCurved } = props;
    const cleanedProps = _omit(props, 'selectedDate');
    let label = null;
    if (selectedDate.dynamicDate) {
        label = (
            <RelativeSelectedDateLabel selectedDate={selectedDate} />
        );
    } else {
        label = (
            <AbsoluteSelectedDateLabel hideInterval selectedDate={selectedDate} />
        );
    }

    return <Button ref={ref} leftCurved={leftCurved} {...cleanedProps} label={label} icon="date-selector" />;
});

CombinedDateSelectorButton.propTypes = {
    selectedDate: PropTypes.oneOfType([customPropTypes.dateRange, customPropTypes.relativeDate, PropTypes.bool]).isRequired,
    small: PropTypes.bool,
    leftCurved: PropTypes.bool
};

CombinedDateSelectorButton.defaultProps = {
    small: false,
    leftCurved: false
};

export default CombinedDateSelectorButton;
