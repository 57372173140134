import _uniq from 'lodash/uniq';
import { createSelector } from 'reselect';
import { createShallowEqualSelector } from 'src/selectors/utils';
import {
    makeSelectOnlyProfileById,
    makeSelectProfilesByIds
} from 'src/selectors/profiles';
import { getAllAccountsFieldNames } from 'src/utils/authTransactionUtils';
import { makeSelectAllPlatformUseCases, basicUseCaseIdsByProfileType } from 'src/selectors/useCases';
import { makeSelectOnlyAdAccountById } from 'src/selectors/adAccounts';

export const getProfileAuthTransactionRequirements = (state) => state.entities.authTransactionRequirements.profileAuthTransactionRequirements;
export const getAdAccountAuthTransactionRequirements = (state) => state.entities.authTransactionRequirements.adAccountAuthTransactionRequirements;

export const makeSelectProfileAuthTransactionRequirementsByIds = () => createSelector(
    [
        getProfileAuthTransactionRequirements,
        (_, ids) => ids
    ],
    (authTransactionRequirements, ids) => ids.map((id) => authTransactionRequirements[id])
);

export const makeSelectProfileIdsFromAuthTransactionRequirements = () => {
    const selectAuthTransactionRequirementsByIds = makeSelectProfileAuthTransactionRequirementsByIds();
    return createSelector(
        [
            selectAuthTransactionRequirementsByIds
        ],
        (authTransactionRequirements) => _uniq(authTransactionRequirements.map((authTransactionRequirement) => authTransactionRequirement.profileId))
    );
};

const getAuthTransactionRequirementsById = (requirements, ids) => {
    const authTransactionRequirements = [];
    ids.forEach((id) => {
        if (requirements[id]) {
            authTransactionRequirements.push(requirements[id]);
        }
    });
    return authTransactionRequirements;
};

export const makeSelectProfileAuthTransactionRequirementByIds = () => createSelector(
    [
        getProfileAuthTransactionRequirements,
        (_, ids) => ids
    ],
    (requirements, ids) => getAuthTransactionRequirementsById(requirements, ids)
);

export const makeSelectAdAccountAuthTransactionRequirementByIds = () => createSelector(
    [
        getAdAccountAuthTransactionRequirements,
        (_, ids) => ids
    ],
    (requirements, ids) => getAuthTransactionRequirementsById(requirements, ids)
);

const orderAuthTransactionRequirementsByAccountId = (accountAuthTransactionRequirementByIds, accountIdFieldName) => {
    const authTransactionRequirementsByAccountId = {};
    accountAuthTransactionRequirementByIds.forEach((authTransactionRequirement) => {
        const accountId = authTransactionRequirement[accountIdFieldName];
        if (authTransactionRequirementsByAccountId[accountId]) {
            authTransactionRequirementsByAccountId[accountId].push(authTransactionRequirement);
        } else {
            Object.assign(authTransactionRequirementsByAccountId, { [accountId]: [authTransactionRequirement] });
        }
    });
    return authTransactionRequirementsByAccountId;
};

export const makeSelectTransactionRequirementByIdsOrderedByProfileId = () => {
    const selectTransactionRequirementByIds = makeSelectProfileAuthTransactionRequirementByIds();
    return createSelector(
        [
            selectTransactionRequirementByIds
        ],
        (authTransactionRequirements) => orderAuthTransactionRequirementsByAccountId(authTransactionRequirements, 'profileId')
    );
};

export const makeSelectAdAccountAuthTransactionRequirementByIdsOrderedByAdAccountId = () => {
    const selectAdAccountAuthTransactionRequirementByIds = makeSelectAdAccountAuthTransactionRequirementByIds();
    return createSelector(
        [
            selectAdAccountAuthTransactionRequirementByIds
        ],
        (adAccountAuthTransactionRequirementByIds) => orderAuthTransactionRequirementsByAccountId(adAccountAuthTransactionRequirementByIds, 'adAccountId')
    );
};

const getAuthTransactionRequirementsPerAccountType = (accounts, authTransactionReqsByAccountId) => {
    const accountsByPlatformType = {};
    accounts.forEach((account) => {
        const { id, platformType } = account;
        if (accountsByPlatformType[platformType]) {
            Object.assign(accountsByPlatformType[platformType], { [id]: authTransactionReqsByAccountId[id] });
        } else {
            Object.assign(accountsByPlatformType, { [platformType]: { [id]: authTransactionReqsByAccountId[id] } });
        }
    });
    return accountsByPlatformType;
};

export const makeGetPlatformSpecificAuthTransactionReqByProfilesById = () => {
    const selectOnlyProfileById = makeSelectOnlyProfileById();
    return createShallowEqualSelector(
        [
            (state, authTransactionReqsByProfileId) => {
                const profiles = [];
                Object.keys(authTransactionReqsByProfileId).forEach((profileId) => {
                    const profile = selectOnlyProfileById(state, profileId);
                    if (profiles !== null) {
                        profiles.push(profile);
                    }
                });
                return profiles;
            },
            (_, authTransactionReqsByProfileId) => authTransactionReqsByProfileId
        ],
        (profiles, authTransactionReqsByProfileId) => getAuthTransactionRequirementsPerAccountType(profiles, authTransactionReqsByProfileId)
    );
};

export const makeSelectPlatformSpecificAuthTransactionReqByAdAccountsById = () => {
    const selectOnlyAdAccountById = makeSelectOnlyAdAccountById();
    return createShallowEqualSelector(
        [
            (state, authTransactionReqsByAdAccountId) => {
                const adAccounts = [];
                Object.keys(authTransactionReqsByAdAccountId).forEach((adAccountId) => {
                    const addAccount = selectOnlyAdAccountById(state, adAccountId);
                    if (addAccount !== null) {
                        adAccounts.push(addAccount);
                    }
                });
                return adAccounts;
            },
            (_, authTransactionReqsByAdAccountId) => authTransactionReqsByAdAccountId
        ],
        (adAccounts, authTransactionReqsByAdAccountId) => getAuthTransactionRequirementsPerAccountType(adAccounts, authTransactionReqsByAdAccountId)
    );
};

export const makeSelectInitialFormValues = () => {
    const selectAllPlatformUseCases = makeSelectAllPlatformUseCases();
    return createSelector(
        [
            (_, profilesByPlatformType) => profilesByPlatformType,
            (_, __, adAccountsByPlatformType) => adAccountsByPlatformType,
            selectAllPlatformUseCases
        ],
        (profilesByPlatformType, adAccountsByPlatformType, allPlatformUseCases) => {
            const fieldNames = [];
            Object.keys(profilesByPlatformType).forEach((platformType) => {
                const fields = getAllAccountsFieldNames(profilesByPlatformType[platformType], allPlatformUseCases[platformType], 'profiles');
                fields.forEach((field) => {
                    fieldNames.push(field);
                });
            });
            Object.keys(adAccountsByPlatformType).forEach((platformType) => {
                const fields = getAllAccountsFieldNames(adAccountsByPlatformType[platformType], allPlatformUseCases[platformType], 'adAccounts');
                fields.forEach((field) => {
                    fieldNames.push(field);
                });
            });
            const initialFormValues = {};
            fieldNames.forEach((fieldName) => {
                initialFormValues[fieldName] = false;
            });
            return initialFormValues;
        }
    );
};

export const makeSelectBasicRequirementsPerProfile = () => {
    const selectProfilesByIds = makeSelectProfilesByIds();
    return createSelector(
        [
            selectProfilesByIds
        ],
        (profiles) => {
            const authTransactionRequirements = {};
            profiles.forEach(({ id, platformType, accountUseCasesAuthUsers }) => {
                if (basicUseCaseIdsByProfileType[platformType] && accountUseCasesAuthUsers === 0) {
                    Object.assign(authTransactionRequirements, { [id]: [basicUseCaseIdsByProfileType[platformType]] });
                }
            });
            return authTransactionRequirements;
        }
    );
};
