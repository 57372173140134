import React from 'react';
import { connect } from 'react-redux';
import { dashboardMetricsAddRequest } from 'src/actions/dashboardMetrics';
import PropTypes from 'prop-types';
import DashboardSearchableListModal from 'src/components/modals/DashboardSearchableListModal';
import _omit from 'lodash/omit';

const AddMetricToDashboardModal = (props) => {
    const handleOnDashboardClick = (dashboardId) => {
        const { metricId, dashboardMetricsAddRequestAction, isTable } = props;
        dashboardMetricsAddRequestAction(dashboardId, metricId, isTable);
    };
    const cleanedProps = _omit(props, ['isTable', 'metricId', 'dashboardMetricsAddRequestAction']);
    return (
        <DashboardSearchableListModal onDashboardClick={handleOnDashboardClick} {...cleanedProps} />
    );
};

AddMetricToDashboardModal.propTypes = {
    metricId: PropTypes.string.isRequired,
    isTable: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    dashboardMetricsAddRequestAction: PropTypes.func.isRequired
};

export default connect(null, {
    dashboardMetricsAddRequestAction: dashboardMetricsAddRequest
})(AddMetricToDashboardModal);
