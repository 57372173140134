import React from 'react';
import PropTypes from 'prop-types';
import EditButton from 'src/components/buttons/EditButton';
import PostPostTagList from 'src/components/views/postTags/postPostTagList/PostPostTagList';
import withToolTip from 'src/components/withToolTip';
import * as customPropTypes from 'src/customPropTypes';
import ManageTagsOfPostPopover from 'src/components/popovers/ManageTagsOfPostPopover';
import withPopover from 'src/components/withPopover';
import { selectIsReadOnly } from 'src/selectors/loggedInUser';
import { connect } from 'react-redux';

const EditButtonWithPopover = withToolTip(withPopover(EditButton));

const PostPostTagListInPostDetail = (props) => {
    const { post, isUserReadOnly } = props;

    const handOnApplyClick = () => {
        const { onUpdateButtonClick } = props;
        onUpdateButtonClick();
    };

    return (
        <PostPostTagList
          post={post}
          editButton={(
              <EditButtonWithPopover
                tooltip="Assign tags"
                overlay={(
                    <ManageTagsOfPostPopover
                      postId={post.id}
                      performPostSearch={false}
                      onApplyClick={handOnApplyClick}
                    />
                )}
                disabled={isUserReadOnly}
              />
          )}
        />
    );
};

PostPostTagListInPostDetail.propTypes = {
    post: customPropTypes.post.isRequired,
    onUpdateButtonClick: PropTypes.func,
    isUserReadOnly: PropTypes.bool.isRequired
};

PostPostTagListInPostDetail.defaultProps = {
    onUpdateButtonClick: () => {}
};

const mapStateToProps = (state) => ({
    isUserReadOnly: selectIsReadOnly(state)
});

export default connect(mapStateToProps)(PostPostTagListInPostDetail);
