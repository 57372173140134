import _omit from 'lodash/omit';
import Button from 'src/components/buttons/Button';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/button.scss';
import networkButtonStyles from 'src/stylesheets/networkButton.scss';
import NetworkIcon from 'src/components/NetworkIcon';

const NetworkButton = (props) => {
    const {
        platformType, loading, disabled, small
    } = props;
    const cleanedProps = _omit(props, ['platformType', 'action']);
    return (
        <Button
          className={classnames(styles[platformType], { [networkButtonStyles.button]: small })}
          icon={!loading ? <NetworkIcon platformType={platformType} className={networkButtonStyles.icon} /> : null}
          {...cleanedProps}
          small={small}
          disabled={disabled}
        />
    );
};

NetworkButton.propTypes = {
    platformType: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    small: PropTypes.bool
};

NetworkButton.defaultProps = {
    loading: false,
    disabled: false,
    small: true
};

export default NetworkButton;
