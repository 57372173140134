import Button from 'src/components/buttons/Button';
import React from 'react';
import PropTypes from 'prop-types';
import { SUPER_ROOT } from 'src/utils/dashboardFolders';

const FolderBrowserSubmitButton = ({
    onClick, isLoading, label, toFolderId, disabled
}) => (
    <Button
      action
      loading={isLoading}
      onClick={onClick}
      label={label}
      disabled={toFolderId === SUPER_ROOT || disabled}
    />
);

FolderBrowserSubmitButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    toFolderId: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

FolderBrowserSubmitButton.defaultProps = {
    disabled: false
};

export default FolderBrowserSubmitButton;
