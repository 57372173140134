import { isSubmitting, submit } from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import FolderEditForm from 'src/components/forms/FolderEditForm';
import { folderEditRequestFormAction } from 'src/actions/folders';
import { makeSelectFolderById } from 'src/selectors/folders';
import Modal from 'src/components/modals/layout/Modal';
import { modalsShowDashboardAndFolderDelete } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import React from 'react';

const formName = 'editFolder';

const FolderEditModal = (props) => {
    const {
        hideModal, parentId, submitAction, submitting, folder, folderDeleteAction, enforceFocus
    } = props;
    const { name, id } = folder;
    const folderEditInitialValues = {
        folderName: name,
        id
    };

    const formNameWithId = `${formName}-${id}`;

    const buttonsLeft = (
        <ButtonGroup>
            <Button
              disabled={submitting}
              label="Delete"
              warning
              icon="delete"
              onClick={() => { folderDeleteAction([id], [], parentId, false); }}
            />
        </ButtonGroup>
    );

    return (
        <Modal dataViewName="edit-folder" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Edit folder: {folder.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FolderEditForm
                      form={formNameWithId}
                      initialValues={folderEditInitialValues}
                      onSubmit={folderEditRequestFormAction}
                    />
                </Modal.Body>
                <Modal.Footer buttonsLeft={buttonsLeft}>
                    <ButtonGroup>
                        <Button disabled={submitting} onClick={hideModal} label="Cancel" />
                        <Button action loading={submitting} onClick={() => submitAction(formNameWithId)} label="Update" />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

FolderEditModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    folderId: PropTypes.string.isRequired,
    folder: customPropTypes.folder.isRequired,
    folderDeleteAction: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    parentId: PropTypes.string
};

FolderEditModal.defaultProps = {
    submitting: false
};

const makeMapStateToProps = () => {
    const selectFolderById = makeSelectFolderById();
    return (state, ownProps) => {
        const { folderId } = ownProps;
        const folder = selectFolderById(state, folderId);
        return {
            folder,
            submitting: isSubmitting(`${formName}-${folderId}`)(state)
        };
    };
};

export default connect(makeMapStateToProps, {
    submitAction: submit,
    folderDeleteAction: modalsShowDashboardAndFolderDelete
})(FolderEditModal);
