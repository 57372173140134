import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import { isUntilDateExpired } from 'src/utils/dashboardShareLink';
import { getLocalString } from 'src/components/Date';
import Label from 'src/components/labels/Label';
import moment from 'moment';
import _floor from 'lodash/floor';

function getTimeRemaining(validUntil) {
    const eventDate = moment.utc(validUntil);
    const todaysDate = moment.utc();
    const diff = eventDate.diff(todaysDate);
    const diffDuration = moment.duration(diff);
    return {
        days: _floor(diffDuration.asDays(), 0),
        hours: diffDuration.hours(),
        minutes: diffDuration.minutes()
    };
}

const DashboardShareLinkStatusLabel = (props) => {
    const { validUntil, hasExpiry, accountOptions } = props;
    let state = 'disabled';
    let text = '';
    if (hasExpiry) {
        const isExpired = isUntilDateExpired(validUntil);
        const dateString = getLocalString(validUntil, 'dateTime', accountOptions, false, 'UTC', 'UTC');
        if (isExpired) {
            text = `Expired ${dateString}`;
            state = 'error';
        } else {
            text = `Expires ${dateString}`;
            const timeRemaining = getTimeRemaining(validUntil);
            if (timeRemaining.days <= 3) {
                state = 'warning';
            }
            if (timeRemaining.days < 1) {
                state = 'error';
            }
            if (timeRemaining.days < 1 && timeRemaining.hours < 1) {
                state = 'error';
            }
        }
    } else {
        text = 'No expiry';
    }
    return <Label state={state} text={text} />;
};

DashboardShareLinkStatusLabel.propTypes = {
    validUntil: PropTypes.string,
    hasExpiry: PropTypes.bool.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired
};

const mapStateToProps = (state) => ({
    accountOptions: selectAccountOptions(state)
});

export default connect(mapStateToProps)(DashboardShareLinkStatusLabel);
