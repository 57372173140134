import _isEqual from 'lodash/isEqual';
import { customRequired } from 'src/components/forms/validators';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'src/components/forms/fields';
import withReduxForm from 'src/components/forms/withReduxForm';

const errorMessage = 'To confirm delete, please type the text exactly as displayed';
const errorMessageForce = 'To confirm force delete, please type the text exactly as displayed';

const required = (value) => customRequired(value, errorMessage);
const confirm = (value) => (
    (!_isEqual(value, 'DELETE')) ? errorMessage : undefined
);

const requiredForce = (value) => customRequired(value, errorMessageForce);
const confirmForce = (value) => (
    (!_isEqual(value, 'FORCE DELETE')) ? errorMessage : undefined
);

const ConfirmDeleteForm = (props) => {
    const { handleSubmit, forceDelete } = props;
    return (
        <form onSubmit={handleSubmit}>
            {
                !forceDelete
                && (
                <Field
                  name="confirmInput"
                  label="Type DELETE to confirm"
                  component={Text}
                  validate={[required, confirm]}
                  autoFocus
                />
                )
            }
            {
                forceDelete
                && (
                <Field
                  name="confirmInput"
                  label="Type FORCE DELETE to confirm"
                  component={Text}
                  validate={[requiredForce, confirmForce]}
                  autoFocus
                />
                )
            }
        </form>
    );
};

ConfirmDeleteForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    forceDelete: PropTypes.bool
};

ConfirmDeleteForm.defaultProps = {
    forceDelete: false
};

export default withReduxForm(ConfirmDeleteForm);
