import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import AccountUseCases from 'src/components/AccountUseCases';
import { adAccountUseCaseSetRequestFormAction } from 'src/actions/adAccountUseCases';

const AdAccountUseCases = (props) => {
    const {
        adAccount, className, hideViewAllAuthenticationButton, formName, context
    } = props;

    return (
        <AccountUseCases
          account={adAccount}
          formAction={adAccountUseCaseSetRequestFormAction}
          hideViewAllAuthenticationButton={hideViewAllAuthenticationButton}
          className={className}
          formName={formName}
          context={context}
        />
    );
};

AdAccountUseCases.propTypes = {
    adAccount: customPropTypes.adAccount.isRequired,
    className: PropTypes.string,
    hideViewAllAuthenticationButton: PropTypes.bool,
    context: PropTypes.oneOf(['popover', 'profileDetail']),
    formName: PropTypes.string.isRequired,
};

AdAccountUseCases.defaultProps = {
    className: '',
    context: 'popover',
    hideViewAllAuthenticationButton: false
};

export default AdAccountUseCases;
