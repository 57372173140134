import Button from 'src/components/buttons/Button';
import IntervalSelectionPopover from 'src/components/popovers/IntervalSelectionPopover';
import withPopover from 'src/components/withPopover';
import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import PropTypes from 'prop-types';
import _startCase from 'lodash/startCase';

const ButtonWithPopover = withPopover(Button);

const IntervalButton = ({
    rightCurved, onIntervalChange, selectedDate, small, disabled, onShow, onHide
}) => {
    const popover = (
        <IntervalSelectionPopover
          value={selectedDate.interval}
          onChange={(interval) => {
              onIntervalChange(Object.assign({}, selectedDate, { interval }));
          }}
        />
    );
    return (
        <ButtonWithPopover
          small={small}
          disabled={disabled}
          rightCurved={rightCurved}
          label={_startCase(selectedDate.interval)}
          overlay={popover}
          placement="bottom-end"
          onShow={onShow}
          onHide={onHide}
        />
    );
};

IntervalButton.propTypes = {
    rightCurved: PropTypes.bool,
    onIntervalChange: PropTypes.func.isRequired,
    selectedDate: PropTypes.oneOfType([customPropTypes.dateRange, customPropTypes.relativeDate, PropTypes.bool]).isRequired,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
    onShow: PropTypes.func,
    onHide: PropTypes.func
};

IntervalButton.defaultProps = {
    rightCurved: false,
    small: false,
    disabled: false
};

export default IntervalButton;
