import { isSubmitting, submit } from 'redux-form';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import DataPushTaskDestinationAddForm from 'src/components/forms/dataPushTasks/DataPushTaskDestinationAddForm';
import { dataPushTaskCreateRequestFormAction } from 'src/actions/dataPushTasks';
import { googleBigQueryType, dataPushTaskDestinationAddFormName } from 'src/utils/dataPushTasks';
import BackLink from 'src/components/BackLink';
import { modalShowDataPushTaskAdd } from 'src/actions/overlays';

const AddDataPushTaskDestinationModal = (props) => {
    const {
        hideModal,
        enforceFocus,
        submitAction,
        isAdding
    } = props;

    const initialValues = {
        destinationType: googleBigQueryType
    };

    const handleOnBackClick = () => {
        const { modalShowDataPushTaskAddAction } = props;
        modalShowDataPushTaskAddAction();
        hideModal();
    };

    const backButton = <BackLink text="Back" onClick={handleOnBackClick} />;

    return (
        <Modal dataViewName="add-data-push-task-destination-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={hideModal} leftContent={backButton}>
                    <Modal.Title>
                        Add data push destination
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body centered>
                    <DataPushTaskDestinationAddForm
                      form={dataPushTaskDestinationAddFormName}
                      onSubmit={dataPushTaskCreateRequestFormAction}
                      initialValues={initialValues}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          label="Cancel"
                          onClick={hideModal}
                          disabled={isAdding}
                        />
                        <Button
                          label="Create"
                          action
                          onClick={() => { submitAction(dataPushTaskDestinationAddFormName); }}
                          loading={isAdding}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

AddDataPushTaskDestinationModal.propTypes = {
    dataPushTaskId: PropTypes.string.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    isAdding: PropTypes.bool.isRequired,
    modalShowDataPushTaskAddAction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isAdding: isSubmitting(dataPushTaskDestinationAddFormName)(state)
});

export default connect(mapStateToProps, {
    submitAction: submit,
    modalShowDataPushTaskAddAction: modalShowDataPushTaskAdd
})(AddDataPushTaskDestinationModal);
