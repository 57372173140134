import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Modal from 'src/components/modals/layout/Modal';
import OverlayTableView from 'src/components/chartViews/OverlayTableView';
import PropTypes from 'prop-types';
import React from 'react';
import WithDataLoaded from 'src/components/withDataLoaded';
import { selectIsSharedDashboardContext } from 'src/selectors/sharedDashboard';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import { getMetricRequest } from 'src/sagas/utils';

const OverlayTableViewWithDataLoaded = WithDataLoaded(OverlayTableView);
const bodyStyle = { display: 'flex', width: '440px', height: '400px' };

const TwitterRetweetersModal = (props) => {
    const {
        tweetId, profileId, author, hideModal, enforceFocus, metricRequest, isSharedDashboardContext
    } = props;

    const params = { tweetId, profileId, metricRequest: JSON.stringify(getMetricRequest(metricRequest)) };
    let serverEndPoint = '/client-widget/load-twitter-retweeters';

    if (isSharedDashboardContext) {
        serverEndPoint = '/client-widget/load-shared-dashboard-twitter-retweeters';
    }

    return (
        <Modal dataViewName="twitter-retweeters" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Retweeters for the tweet of {author}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={bodyStyle}>
                        <OverlayTableViewWithDataLoaded
                          params={params}
                          serverEndPoint={serverEndPoint}
                          type="twitterRetweeters"
                          width={440}
                          height={400}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button action label="Done" onClick={hideModal} />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

TwitterRetweetersModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    tweetId: PropTypes.string.isRequired,
    profileId: PropTypes.number.isRequired,
    author: PropTypes.string.isRequired,
    enforceFocus: PropTypes.bool,
    isSharedDashboardContext: PropTypes.bool.isRequired,
    metricRequest: customPropTypes.metricRequest.isRequired
};

const mapStateToProps = (state) => ({
    isSharedDashboardContext: selectIsSharedDashboardContext(state)
});

export default connect(mapStateToProps)(TwitterRetweetersModal);
