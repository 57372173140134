import withAuthenticator from 'src/components/withAuthenticator';
import React, { Component } from 'react';
import _omit from 'lodash/omit';
import createServerRequest from 'src/requestHandling/createServerRequest';
import ErrorBody from 'src/components/metricTiles/layout/ErrorBody';
import PropTypes from 'prop-types';
import VerticallyHorizontallyCentered from 'src/components/loadingIndicators/VerticallyHorizontallyCentered';
import withAsyncStateHandling from 'src/components/withAsyncStateHandling';
import * as customPropTypes from 'src/customPropTypes';

const withDataLoaded = (WrappedComponent) => {
    const WrappedComponentWithAsyncStateHanding = withAsyncStateHandling(WrappedComponent, VerticallyHorizontallyCentered, ErrorBody);
    class WithDataLoaded extends Component {
        constructor(props) {
            super(props);
            this.state = {
                data: [],
                loading: true,
                error: null
            };
        }

        componentDidMount() {
            const { serverEndPoint, params, authenticator } = this.props;
            const serverParams = Object.assign({}, params);
            const serverRequest = createServerRequest(serverParams, 'json');
            authenticator.handleAuthorizedServerRequest(serverRequest, serverEndPoint)
                .then((response) => {
                    this.setState({
                        data: response.jsonData,
                        loading: false,
                        error: null
                    });
                })
                .catch((error) => {
                    this.setState({
                        data: [],
                        loading: false,
                        error
                    });
                });
        }

        render() {
            const { data, loading, error } = this.state;
            const { type } = this.props;
            const cleanedProps = _omit(this.props, ['serverEndPoint', 'params', 'type']);
            return (
                <WrappedComponentWithAsyncStateHanding
                  data={data}
                  loading={loading}
                  error={error}
                  type={type}
                  {...cleanedProps}
                />
            );
        }
    }

    WithDataLoaded.propTypes = {
        serverEndPoint: PropTypes.string.isRequired,
        params: PropTypes.object.isRequired,
        type: PropTypes.string.isRequired,
        authenticator: customPropTypes.authenticator.isRequired
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithDataLoaded.displayName = `withDataLoaded(${wrappedComponentName})`;
    return withAuthenticator(WithDataLoaded);
};

export default withDataLoaded;
