import React from 'react';
import styles from 'src/stylesheets/views/postTags/bindingList.scss';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import Feedback from 'src/components/feedback/Feedback';
import BindingList from 'src/components/bindings/BindingList';

const SingleEntityBindingList = (props) => {
    const { forceOperationalEntityInfo, label } = props;
    const { name } = forceOperationalEntityInfo;
    const content = (
        <div className={styles.wrapper}>
            <div className={styles.description}>{`The ${label} '${name}' has bindings to a metric in a dashboard, automated report, link export or auto-tag rule.`}</div>
            <BindingList forceOperationalEntityInfo={forceOperationalEntityInfo} />
        </div>
    );
    return <Feedback content={content} type="warning" />;
};

SingleEntityBindingList.propTypes = {
    label: PropTypes.string.isRequired,
    forceOperationalEntityInfo: customPropTypes.forceOperationalEntityInfo.isRequired
};

export default SingleEntityBindingList;
