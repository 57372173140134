import React from 'react';
import { connect } from 'react-redux';
import { modalShowDashboardSearchableList } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import DashboardNameButton from 'src/components/buttons/DashboardNameButton';
import Button from 'src/components/buttons/Button';
import styles from 'src/stylesheets/dashboardSelector.scss';

const DashboardSelector = (props) => {
    const {
        modalShowDashboardSearchableListAction,
        dashboardId,
        handleOnDashboardClick,
        disabled
    } = props;
    let button = <Button label="Select a dashboard" onClick={() => { modalShowDashboardSearchableListAction(handleOnDashboardClick); }} />;

    if (dashboardId) {
        button = (
            <DashboardNameButton
              dashboardId={dashboardId}
              onClick={() => { modalShowDashboardSearchableListAction(handleOnDashboardClick); }}
              disabled={disabled}
            />
        );
    }

    return (
        <div className={styles.buttonWrapper}>
            {button}
        </div>
    );
};

DashboardSelector.propTypes = {
    dashboardId: PropTypes.string,
    modalShowDashboardSearchableListAction: PropTypes.func.isRequired,
    handleOnDashboardClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

DashboardSelector.defaultProps = {
    disabled: false
};

export default connect(null, {
    modalShowDashboardSearchableListAction: modalShowDashboardSearchableList
})(DashboardSelector);
