import { isSubmitting, submit } from 'redux-form';
import { deleteSpaceFormRequest } from 'src/actions/spaces';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import SpaceDeleteForm from 'src/components/forms/SpaceDeleteForm';

const formName = 'spaceDeleteForm';

export const SpaceDeleteModal = (props) => {
    const {
        hideModal, spaceName, isDeleting, enforceFocus, spaceId, submitAction
    } = props;

    const initialValues = {
        spaceId
    };

    return (
        <Modal dataViewName="delete-space" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Delete space</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SpaceDeleteForm
                      initialValues={initialValues}
                      spaceName={spaceName}
                      form={formName}
                      onSubmit={deleteSpaceFormRequest}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button disabled={isDeleting} onClick={hideModal} label="Cancel" />
                        <Button
                          action
                          warning
                          icon="delete"
                          loading={isDeleting}
                          onClick={() => { submitAction(formName); }}
                          label="Delete"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

SpaceDeleteModal.propTypes = {
    spaceId: PropTypes.string.isRequired,
    hideModal: PropTypes.func.isRequired,
    spaceName: PropTypes.string.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool
};

function mapStateToProps(state) {
    return {
        isDeleting: isSubmitting(formName)(state)
    };
}

export default connect(mapStateToProps, {
    submitAction: submit
})(SpaceDeleteModal);
