import { Field } from 'redux-form';
import React from 'react';
import { Text } from 'src/components/forms/fields';
import PropTypes from 'prop-types';

const DashboardMetricAliasField = (props) => {
    const { placeholder, disabled } = props;
    return (
        <Field
          autoFocus
          name="alias"
          label="Metric name"
          component={Text}
          disabled={disabled}
          placeholder={placeholder}
        />
    );
};

DashboardMetricAliasField.propTypes = {
    placeholder: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

DashboardMetricAliasField.defaultProps = {
    disabled: false
};

export default DashboardMetricAliasField;
