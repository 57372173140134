// CAUTION: THIS FILE IS BUILT AND USED BY THE BACKEND TOO with 'yarn run build:dateUtils'

import _trim from 'lodash/trim';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import _isString from 'lodash/isString';

const presets = [
    {
        displayName: 'Last 7 days',
        value: 'last 7 days'
    },
    {
        displayName: 'Last 30 days',
        value: 'last 30 days'
    },
    {
        displayName: 'Previous week (Mon to Sun)',
        value: 'previous week'
    },
    {
        displayName: `Previous month (${moment()
            .subtract(1, 'months')
            .format('MMM YYYY')})`,
        value: 'previous month'
    },
    {
        displayName: `This month (${moment()
            .format('MMM YYYY')})`,
        value: 'this month'
    }
];

export const getPresets = () => presets;

export const isDynamicDateInPresets = (dynamicDate) => presets.some((object) => object.value === dynamicDate);

function convertIntegerNamesToIntegers(str) {
    const helper = {
        numbers: ['twenty\\s+one|twenty\\s+two|twenty\\s+three|twenty\\s+four|twenty\\s+five|twenty\\s+six|twenty\\s+seven|twenty\\s+eight|twenty\\s+nine|thirty',
            'one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve|thirteen|fourteen|fifteen|sixteen|seventeen|eighteen|nineteen|twenty']
    };
    // replace dashes with a whitespace
    const dashesRegExp = /([a-z]+)-/i;
    let inputString = str.replace(dashesRegExp, '$1 ');

    for (let i = 0; i < helper.numbers.length; i++) {
        const literalNumbersRegExp = new RegExp(`(.*)(${helper.numbers[i]})(.*)`, 'i');
        while (literalNumbersRegExp.test(inputString) === true) {
            const numbersDictionary = helper.numbers[i].split('|');
            const match = literalNumbersRegExp.exec(inputString);
            let numberName = match[2].toLowerCase();
            numberName = numberName.replace(/(.*\S)(\s+)(\S.*)/i, '$1\\s+$3');
            if (numbersDictionary.indexOf(numberName) >= 0) {
                const number = numbersDictionary.indexOf(numberName) + (i > 0 ? 1 : 21);
                const replacementRegExp = new RegExp(`(.*)(${numberName})(.*)`, 'i');
                inputString = inputString.replace(replacementRegExp, `$1${number}$3`);
            }
        }
    }
    return inputString;
}

function replaceMultipleSpacesWithSingleSpace(str) {
    return str.replace(/\s\s+/g, ' ');
}

function getMonthByStr(helper, str) {
    const months = helper.months.split('|');
    for (let i = 0, iLen = months.length; i < iLen; i++) {
        if (str === months[i]) {
            return Math.ceil((i + 1) / 2);
        }
    }
    return null;
}

export const tryGetTimezoneAbbrevOrUtcOffsetOrNull = (timezone, time) => {
    if (_isString(timezone)) {
        const momentTimezone = moment.tz.zone(timezone);
        if (momentTimezone) {
            const abbrev = momentTimezone.abbr(time);
            if (abbrev) {
                const regEx = /^([+|-]\d{2,4})$/;
                return abbrev.replace(regEx, 'UTC$1');
            }
        }
    }
    return null;
};

export const getTimezoneStringWithAbbrev = (timezone, time) => {
    const abbrevOrNull = tryGetTimezoneAbbrevOrUtcOffsetOrNull(timezone, time);
    if (abbrevOrNull) {
        return `${timezone} (${abbrevOrNull})`;
    }
    return timezone;
};

export const getDateConfigByString = (str) => {
    const dict = {};
    const helper = {
        months: 'jan|january|feb|february|mar|march|apr|april|may|may|jun|june|jul|july|aug|august|sep|september|oct|october|nov|november|dec|december'
    };

    // making sure number names are replaced with integers (1-30):
    let inputString = convertIntegerNamesToIntegers(str);
    inputString = replaceMultipleSpacesWithSingleSpace(inputString);
    inputString = _trim((inputString).toLowerCase());
    let matches = null;
    const now = moment.utc();

    if ((new RegExp('^this (year|quarter|month|week|day)?$', 'g').exec(inputString)) !== null) {
        matches = new RegExp('^this (year|quarter|month|week|day)?$', 'g').exec(inputString);
        const unit = matches[1];

        dict.from = moment.utc().startOf(unit === 'week' ? 'isoWeek' : unit);
        dict.to = moment.utc();
        // shift to one day back if its not already the same day as "from"
        if (dict.to.diff(dict.from, 'hours') >= 24) {
            dict.to = dict.to.subtract(1, 'day');
        }
        dict.dynamicDate = inputString;
    } else if (inputString === 'this half-year') {
        const month = moment.utc().month() + 1;
        dict.from = moment.utc().month(month <= 6 ? 0 : 5).date(1);
        dict.to = moment.utc();
        // shift to one day back if its not already the same day as "from"
        if (dict.to.diff(dict.from, 'hours') >= 24) {
            dict.to = dict.to.subtract(1, 'day');
        }
        dict.dynamicDate = inputString;
    } else if ((new RegExp('^previous ([1-9]{1}[0-9]*)?( )?(year|quarter|month|week|day)(s)?$', 'g').exec(inputString)) !== null) {
        matches = new RegExp('^previous ([1-9]{1}[0-9]*)?( )?(year|quarter|month|week|day)(s)?$', 'g').exec(inputString);
        const numberOfUnits = (_get(matches, ['1'], '') === '') ? 1 : matches[1];
        const unit = matches[3];

        dict.from = moment.utc().subtract(numberOfUnits, `${unit}s`).startOf(unit === 'week' ? 'isoWeek' : unit);
        dict.to = moment.utc().subtract(1, unit).endOf(unit === 'week' ? 'isoWeek' : unit);
        dict.dynamicDate = inputString;
    } else if ((new RegExp('^previous ([1-9]{1}[0-9]*)?( )?half-year(s)?$', 'g').exec(inputString)) !== null) {
        matches = new RegExp('^previous ([1-9]{1}[0-9]*)?( )?half-year(s)?$', 'g').exec(inputString);
        const numberOfHalfYears = (_get(matches, ['1'], '') === '') ? 1 : matches[1];

        const agoFrom = moment.utc().subtract(numberOfHalfYears * 6, 'months');
        const agoFromMonth = agoFrom.month() + 1;
        dict.from = agoFrom.month(agoFromMonth <= 6 ? 0 : 5).date(1);

        const agoTo = moment.utc().subtract(6, 'months');
        const agoToMonth = agoTo.month() + 1;
        dict.to = agoTo.month(agoToMonth <= 6 ? 5 : 11).endOf('month');

        dict.dynamicDate = inputString;
    } else if (inputString === 'yesterday') {
        dict.from = moment.utc().subtract(1, 'day');
        dict.to = moment.utc().subtract(1, 'day');
        dict.dynamicDate = inputString;
    } else if (inputString === 'today' || inputString === 'now') {
        dict.from = moment.utc();
        dict.to = moment.utc();
        dict.dynamicDate = inputString;
    } else if ((new RegExp('^last ([1-9]{1}[0-9]*)?( )?(year|month|week|day)(s)?$', 'g').exec(inputString)) !== null) {
        /**
         * last day|days|week|month|year
         * last 3 days
         * last 20 months
         */
        matches = new RegExp('^last ([1-9]{1}[0-9]*)?( )?(year|month|week|day)(s)?$', 'g').exec(inputString);
        // if no integer for the period is given, take 1 (e.g. "last month", "last day")
        if (_get(matches, ['1'], '') === '') matches[1] = 1;
        dict.from = moment().utc().subtract(matches[1], `${matches[3]}s`);
        dict.to = moment().utc();
        dict.dynamicDate = inputString;

        // shift by one day back
        dict.to = dict.to.subtract(1, 'day');
    } else if ((new RegExp(`^(last )?(${helper.months})( ([1-2][0-9]{3}))?( to (${helper.months}|now|today|yesterday)( ([1-2][0-9]{3}))?)?$`, 'g').exec(inputString)) !== null) {
        /**
         * jan|feb|...|dec
         * jan to dec
         * jan 2010
         * jan 2011 to feb
         * jan 2011 to feb 2012
         * jan to feb 2012
         * nov to jan 2012
         * jan to now
         * jan to today
         * jan 2015 to yesterday
         */
        matches = new RegExp(`^(last )?(${helper.months})( ([1-2][0-9]{3}))?( to (${helper.months}|now|today|yesterday)( ([1-2][0-9]{3}))?)?$`, 'g').exec(inputString);
        // lets extract the from-date
        const month1 = getMonthByStr(helper, matches[2]) - 1;
        dict.from = moment().utc().month(month1);

        // is a year explicitly given?
        if (typeof matches[4] !== 'undefined') {
            // year given
            dict.from.year(matches[4]);
        } else if (now.month() <= month1) {
            // year not given, to take the current year, but if month is in future, take the one a year before
            dict.from.year(dict.from.year() - 1);
        }

        // finally we take the start of the month
        dict.from.startOf('month');

        // is a "to" part given?
        if (typeof matches[5] === 'undefined') {
            // NO "to" part given, set the to-date to the end of the given month
            dict.to = dict.from.clone().endOf('month');
        } else {
            // there is a "to" part, so let's get the second month
            const toTerm = matches[6];
            if (toTerm === 'today' || toTerm === 'now') {
                dict.to = moment.utc();
            } else if (toTerm === 'yesterday') {
                dict.to = moment.utc().subtract(1, 'day');
            } else {
                const month2 = getMonthByStr(helper, toTerm) - 1;
                dict.to = moment().utc().month(month2);

                if (typeof matches[8] !== 'undefined') {
                    // year given
                    dict.to.year(matches[8]);
                    // further check if there was no year given for the from-date
                    // if that's the case, take the year of the to-date
                    if (typeof matches[4] === 'undefined') {
                        dict.from.year(dict.to.year());
                        // but if the month of the from-date is after the month of the to-date, lets assume it's one year before
                        if (dict.from.month() >= dict.to.month()) {
                            dict.from.year(dict.from.year() - 1);
                        }
                    }
                } else {
                    // year not given, to lets take the year of the from-date as a basis
                    // but take one year after if the month of the to-date is below the month of the from-date
                    dict.to.year(dict.from.year());
                    if (month2 <= dict.from.month()) {
                        dict.to.year(dict.from.year() + 1);
                    }
                }
                // ensure we are still at the end of the month
                dict.to = dict.to.endOf('month');
            }
        }
        dict.dynamicDate = inputString;
    } else if ((new RegExp('^([1-2][0-9]{3})-([0-3][0-9])-([0-3][0-9])( to (([1-2][0-9]{3})-([0-3][0-9])-([0-3][0-9])))?$', 'g').exec(inputString)) !== null) {
        /**
         * 2013-04-01
         * 2013-04-01 to 2013-05-01
         * TODO: 2012, 2012 to 2013
         */
        matches = new RegExp('^([1-2][0-9]{3})-([0-3][0-9])-([0-3][0-9])( to (([1-2][0-9]{3})-([0-3][0-9])-([0-3][0-9])))?$', 'g').exec(inputString);
        // lets extract the from-date
        dict.from = moment().utc().year(matches[1])
            .month(parseInt(matches[2], 10) - 1)
            .date(matches[3]);

        // is a "to" part given?
        if (typeof matches[4] === 'undefined') {
            // NO "to" part given, set the to-date to the from-date (one day analysis)
            dict.to = dict.from.clone();
        } else {
            // YES
            dict.to = moment().utc().year(matches[6])
                .month(parseInt(matches[7], 10) - 1)
                .date(matches[8]);
        }
        dict.dynamicDate = null;
    } else if ((new RegExp('^-([0-9]+)( )?(year|month|week|day)(s)?,( )?\\+([0-9]+)( )?(year|month|week|day)(s)?$', 'g').exec(inputString)) !== null) {
        /**
         * -10 days, +2 days
         * -5 weeks, +1 day
         */
        matches = new RegExp('^-([0-9]+)( )?(year|month|week|day)(s)?,( )?\\+([0-9]+)( )?(year|month|week|day)(s)?$', 'g').exec(inputString);
        dict.from = moment().utc().subtract(matches[1], `${matches[3]}s`);
        dict.to = moment().utc().subtract(matches[1], `${matches[3]}s`)
            .add(matches[6], `${matches[8]}s`);
        dict.dynamicDate = inputString;
    } else {
        return false;
    }

    // round dates
    dict.from = dict.from.startOf('day');
    dict.to = dict.to.endOf('day');

    return {
        from: dict.from,
        to: dict.to,
        dynamicDate: dict.dynamicDate
    };
};
