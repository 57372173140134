import React, { Component } from 'react';
import PropTypes from 'prop-types';
import scrollParent from 'src/utils/scrollParent';
import _omit from 'lodash/omit';

export const checkAndScrollIntoView = (node) => {
    const scrollParentOfElement = scrollParent(node);
    const rect = node.getBoundingClientRect();
    const parentRect = scrollParentOfElement.getBoundingClientRect();

    const parentComputedStyle = window.getComputedStyle(scrollParentOfElement, null);
    const parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue('border-top-width'), 10);

    if (rect.top < parentRect.top || rect.bottom > parentRect.bottom) {
        scrollParentOfElement.scrollTop = node.offsetTop
            - scrollParentOfElement.offsetTop
            - scrollParentOfElement.clientHeight / 2
            - parentBorderTopWidth
            + node.clientHeight / 2;
    }
};

const withScrollIntoViewOfScrollParent = (WrappedComponent) => {
    class WithScrollIntoViewOfScrollParent extends Component {
        constructor(props) {
            super(props);
            this.ref = React.createRef();
        }

        componentDidMount() {
            const { active, scrollOnMount } = this.props;
            if (active || scrollOnMount) {
                checkAndScrollIntoView(this.ref.current);
            }
        }

        componentDidUpdate(prevProps) {
            const { active } = this.props;
            if (prevProps.active !== active && active) {
                checkAndScrollIntoView(this.ref.current);
            }
        }

        render() {
            const cleanedProps = _omit(this.props, ['scrollOnMount']);
            return <WrappedComponent ref={this.ref} {...cleanedProps} />;
        }
    }

    WithScrollIntoViewOfScrollParent.propTypes = {
        active: PropTypes.bool.isRequired,
        scrollOnMount: PropTypes.bool
    };

    WithScrollIntoViewOfScrollParent.defaultProps = {
        scrollOnMount: false
    };

    return WithScrollIntoViewOfScrollParent;
};

export default withScrollIntoViewOfScrollParent;
