import UserInvitationBox from 'src/components/userInvitations/UserInvitationLoader';
import { isSubmitting, submit } from 'redux-form';
import { selectDefaultTimezoneFromAccountSettings } from 'src/selectors/loggedInUser';
import { makeSelectUserById, makeSelectUserEditInitialFormValues } from 'src/selectors/users';
import * as customPropTypes from 'src/customPropTypes';
import { updateUserRequestFormAction } from 'src/actions/users';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { selectLoggedInSpace, selectSpaces } from 'src/selectors/spaces';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import UserForm from 'src/components/forms/UserInviteForm';
import withResourceCheck from 'src/components/modals/withResourceCheck';

const formName = 'editUserForm';

const UserEditModal = (props) => {
    const {
        initialValues, isLoading, user, spaces, hideModal, submitAction, enforceFocus
    } = props;
    const { displayName, invitationCode, id } = user;
    return (
        <Modal dataViewName="edit-user" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Edit user {displayName}</Modal.Title>
                </Modal.Header>
                <Modal.Body centered>

                    {invitationCode
                        && <UserInvitationBox userId={id} invitationCode={invitationCode} />}

                    <UserForm
                      form={formName}
                      onSubmit={updateUserRequestFormAction}
                      initialValues={initialValues}
                      spaces={spaces}
                      disableEmail
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          disabled={isLoading}
                          onClick={hideModal}
                          type="button"
                          label="Cancel"
                        />
                        <Button
                          action
                          loading={isLoading}
                          onClick={() => submitAction(formName)}
                          label="Update"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

UserEditModal.propTypes = {
    userId: PropTypes.string.isRequired,
    user: customPropTypes.manageUser.isRequired,
    spaces: customPropTypes.spacesForManagement.isRequired,
    submitAction: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    initialValues: PropTypes.object.isRequired,
    enforceFocus: PropTypes.bool
};

const UserEditModalWithResourceCheck = withResourceCheck(UserEditModal, 'user', 'user');

const makeMapStateToProps = () => {
    const selectUserEditInitialFormValues = makeSelectUserEditInitialFormValues();
    const selectUserById = makeSelectUserById();
    return (state, ownProps) => {
        const { userId } = ownProps;
        const user = selectUserById(state, userId);
        const spaces = selectSpaces(state);
        const defaultTimezone = selectDefaultTimezoneFromAccountSettings(state);
        const loggedInSpace = selectLoggedInSpace(state);
        return {
            user,
            initialValues: selectUserEditInitialFormValues(user, spaces, defaultTimezone, loggedInSpace),
            isLoading: isSubmitting(formName)(state),
            spaces
        };
    };
};

export default connect(makeMapStateToProps, {
    submitAction: submit,
})(UserEditModalWithResourceCheck);
