import {
    getFormSyncErrors, isSubmitting, isValid
} from 'redux-form';
import * as customMetricActions from 'src/actions/customMetric';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { submitMetricBuilderForm } from 'src/actions/forms';
import { formName, isFormActuallyValid } from 'src/utils/metricBuilder';
import CustomMetricSaveAndSaveAsPartialModal from 'src/components/modals/partials/CustomMetricSaveAndSaveAsPartialModal';

const CustomMetricSaveModal = (props) => {
    const {
        hideModal,
        enforceFocus,
        valid,
        submitAction,
        fieldErrors,
        submitting
    } = props;
    const computedValid = isFormActuallyValid(fieldErrors, valid);

    return (
        <Modal dataViewName="save-custom-metric" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Save metric</Modal.Title>
                </Modal.Header>
                <CustomMetricSaveAndSaveAsPartialModal
                  valid={computedValid}
                  hideModal={hideModal}
                  runQQLLabel="Run QQL and Save"
                  renderIfNoError={() => {
                      if (!submitting) {
                          hideModal();
                          submitAction(formName, 'update');
                      }
                      return null;
                  }}
                />
            </Modal.Dialog>
        </Modal>
    );
};

CustomMetricSaveModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    enforceFocus: PropTypes.bool,
    fieldErrors: PropTypes.object.isRequired
};

CustomMetricSaveModal.defaultProps = {
    enforceFocus: false
};

const makeMapStateToProps = () => (state) => {
    const valid = isValid(formName)(state);
    const submitting = isSubmitting(formName)(state);
    return {
        valid,
        submitting,
        fieldErrors: getFormSyncErrors(formName)(state),
    };
};

export default connect(makeMapStateToProps, {
    submitAction: submitMetricBuilderForm,
    setQQLFormValuesAction: customMetricActions.setQQLFormValues
})(CustomMetricSaveModal);
