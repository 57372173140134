import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/modals/layout/Modal';
import NewlyAddedProfilesView from 'src/components/views/profiles/search/NewlyAddedProfilesView';
import FailedSearchedProfilesView from 'src/components/views/profiles/search/FailedSearchedProfilesView';
import styles from 'src/stylesheets/modals/profilesAddedFailedStatusModal.scss';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { createBasicAuthTransactionRequest } from 'src/actions/authTransactions';
import { connect } from 'react-redux';
import { makeSelectIsBasicAuthTransactionCreating } from 'src/selectors/authTransactions';
import { makeSelectBasicRequirementsPerProfile } from 'src/selectors/authTransactionRequirements';
import * as overlayActions from 'src/actions/overlays';

const ProfilesAddedFailedStatusModal = (props) => {
    const {
        addedProfileIds, failedSearchedProfileLinks, hideModal, authTransactionCreatePending, basicAuthTransactionRequirements
    } = props;

    const handleOnAuthenticateClick = () => {
        const { createBasicAuthTransactionRequestAction } = props;
        createBasicAuthTransactionRequestAction(basicAuthTransactionRequirements);
    };

    const handleOnRetryClick = () => {
        const { modalsShowProfileSearchAction } = props;
        modalsShowProfileSearchAction();
        hideModal();
    };

    return (
        <Modal dataViewName="profilesAddedAndFailedStatus" onHide={hideModal}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Profiles added</Modal.Title>
                </Modal.Header>
                <Modal.Body centered>
                    <div>
                        {
                            failedSearchedProfileLinks.length > 0 && (
                                <div className={styles.failedAccounts}>
                                    <FailedSearchedProfilesView links={failedSearchedProfileLinks} onRetryClick={handleOnRetryClick} />
                                </div>
                            )
                        }
                        {
                            addedProfileIds.length > 0 && <NewlyAddedProfilesView addedProfileIds={addedProfileIds} />
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        {
                            Object.keys(basicAuthTransactionRequirements).length > 0
                            && (
                                <Button
                                  label="Authenticate profiles"
                                  action
                                  loading={authTransactionCreatePending}
                                  onClick={handleOnAuthenticateClick}
                                />
                            )
                        }
                        <Button label="Done" onClick={hideModal} />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

ProfilesAddedFailedStatusModal.propTypes = {
    addedProfileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    failedSearchedProfileLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
    modalsShowProfileSearchAction: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    createBasicAuthTransactionRequestAction: PropTypes.func.isRequired,
    authTransactionCreatePending: PropTypes.bool.isRequired,
    basicAuthTransactionRequirements: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired
};

const makeMapStateToProps = () => {
    const selectIsBasicAuthTransactionCreating = makeSelectIsBasicAuthTransactionCreating();
    const selectBasicRequirementsPerProfile = makeSelectBasicRequirementsPerProfile();
    return (state, ownProps) => ({
        authTransactionCreatePending: selectIsBasicAuthTransactionCreating(state),
        basicAuthTransactionRequirements: selectBasicRequirementsPerProfile(state, ownProps.addedProfileIds)
    });
};

export default connect(makeMapStateToProps, {
    createBasicAuthTransactionRequestAction: createBasicAuthTransactionRequest,
    modalsShowProfileSearchAction: overlayActions.modalsShowProfileSearch
})(ProfilesAddedFailedStatusModal);
