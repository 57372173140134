import React from 'react';
import styles from 'src/stylesheets/modal.scss';
import * as customPropTypes from 'src/customPropTypes';

const DrawerBody = ({ children }) => (
    <div className={styles.drawerBody}>
        {children}
    </div>
);

DrawerBody.propTypes = {
    children: customPropTypes.children.isRequired
};

export default DrawerBody;
