import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import * as profileEventActions from 'src/actions/profileEvents';
import * as profileHealthActions from 'src/actions/profileHealth';
import { connect } from 'react-redux';
import { makeSelectProfileEventsByProfileId, makeSelectAsyncStateByProfileId } from 'src/selectors/profileEvents';
import ProfileEventsTable from 'src/components/profileEvents/ProfileEventsTable';
import styles from 'src/stylesheets/profileEvents/profileEvents.scss';
import Error from 'src/components/errors/Error';
import ProfileHealthStatusBorder from 'src/components/profile/ProfileHealthStatusBorder';

const ProfileEvents = (props) => {
    const { profileEvents, loadingState, profileId } = props;
    useEffect(() => {
        const { profileEventsGetRequestAction, profileHealthStatusGetRequestAction } = props;
        profileEventsGetRequestAction(profileId);
        profileHealthStatusGetRequestAction(profileId);
    }, []);

    const { isPending, error } = loadingState;

    let content = <div>There are no events for this profile.</div>;
    if (profileEvents.length > 0) {
        content = <ProfileEventsTable profileEvents={profileEvents} />;
    } else if (isPending) {
        content = <div className={styles.loading}>loading ...</div>;
    } else if (error) {
        content = <Error error={error} />;
    }
    return (
        <ProfileHealthStatusBorder profileId={profileId}>
            <div className={styles.header}>
                <div className={styles.headerTitle}>Recent events</div>
                <div className={styles.info}>* Events recorded from 01.07.2020 </div>
            </div>
            <div className={styles.body}>
                {content}
            </div>
        </ProfileHealthStatusBorder>
    );
};

ProfileEvents.propTypes = {
    profileId: PropTypes.string.isRequired,
    profileEvents: customPropTypes.profileEvents.isRequired,
    profileEventsGetRequestAction: PropTypes.func.isRequired,
    loadingState: customPropTypes.asyncDataLoadingState.isRequired,
    profileHealthStatusGetRequestAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectProfileEventsByProfileId = makeSelectProfileEventsByProfileId();
    const selectAsyncStateByProfileId = makeSelectAsyncStateByProfileId();
    return (state, ownProps) => ({
        profileEvents: selectProfileEventsByProfileId(state, ownProps.profileId),
        loadingState: selectAsyncStateByProfileId(state, ownProps.profileId)
    });
};

export default connect(makeMapStateToProps, {
    profileEventsGetRequestAction: profileEventActions.profileEventsGetRequest,
    profileHealthStatusGetRequestAction: profileHealthActions.profileHealthStatusGetRequest
})(ProfileEvents);
