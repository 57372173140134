import React from 'react';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';
import classnames from 'classnames';
import styles from 'src/stylesheets/inputs/multiSelect.scss';

const MultiSelect = (props) => {
    const {
        value, error, disabled
    } = props;
    const cleanedProps = _omit(props, ['error', 'layout', 'active', 'disabled']);
    return (
        <select
          multiple
          native
          value={value}
          label="Native"
          disabled={disabled}
          className={classnames(
              styles.wrapper,
              styles.select, {
                  [styles.error]: error, [styles.disabled]: disabled
              }
          )}
          {...cleanedProps}
        />
    );
};

MultiSelect.defaultProps = {
    error: false,
    disabled: false
};

MultiSelect.propTypes = {
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    error: PropTypes.bool,
    disabled: PropTypes.bool
};

export default MultiSelect;
