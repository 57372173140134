import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import {
    makeSelectProfileAudienceDataByProfileIdOrNull,
    makeSelectAudienceDataAsyncStateByProfileId,
    makeSelectProfileWarningByProfileIdOrNull
} from 'src/selectors/profileAudienceData';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProgressBar from 'src/components/ProgressBar';
import { getProgressesByCategory, getSentence } from 'src/utils/audienceData';
import styles from 'src/stylesheets/views/profiles/profileAudienceDataView.scss';
import ProfileAudienceDataLoadingSkeleton from 'src/components/loadingIndicators/ProfileAudienceDataLoadingSkeleton';
import * as profileAudienceDataActions from 'src/actions/profileAudienceData';
import DivWithMultiLineEllipsis from 'src/components/DivWithMultiLineEllipsis';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import { getLocalString } from 'src/components/Date';

const ProfileAudienceDataView = (props) => {
    const {
        profileGetAudienceDataOverviewRequestAction, audienceData, loadingState, profileId, accountOptions, warning
    } = props;
    const { isPending, error } = loadingState;

    useEffect(() => {
        profileGetAudienceDataOverviewRequestAction(profileId);
    }, []);

    if (isPending) {
        return <ProfileAudienceDataLoadingSkeleton />;
    }
    if (error) {
        return (
            <DivWithMultiLineEllipsis lineClamps={2} tooltip={error.message}>
                <div className={styles.error}>{error.message}</div>
            </DivWithMultiLineEllipsis>
        );
    }

    if (warning) {
        return (
            <DivWithMultiLineEllipsis lineClamps={2} tooltip={warning}>
                {warning}
            </DivWithMultiLineEllipsis>
        );
    }

    if (audienceData) {
        const { category, since, numberOfAvailableDays } = audienceData;
        const progresses = getProgressesByCategory(category);
        const formattedSince = getLocalString(since, 'date', accountOptions);
        const sentence = getSentence(category, numberOfAvailableDays, formattedSince);
        return (
            <div className={styles.wrapper}>
                <div className={styles.sentence}>
                    <DivWithMultiLineEllipsis tooltip={sentence}>
                        {sentence}
                    </DivWithMultiLineEllipsis>
                </div>
                <div className={styles.bars}>
                    {
                        progresses.map((value, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={`${profileId}_progress_${i}_${value}`} className={styles.bar}>
                                <ProgressBar now={value} barColor="green" />
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }

    return null;
};

ProfileAudienceDataView.propTypes = {
    profileGetAudienceDataOverviewRequestAction: PropTypes.func.isRequired,
    profileId: PropTypes.string.isRequired,
    audienceData: customPropTypes.profileAudienceData,
    warning: PropTypes.string,
    accountOptions: customPropTypes.accountOptions.isRequired,
    loadingState: customPropTypes.asyncDataLoadingState.isRequired
};

const makeMapStateToProps = () => {
    const selectProfileAudienceDataByProfileIdOrNull = makeSelectProfileAudienceDataByProfileIdOrNull();
    const selectAudienceDataAsyncStateByProfileId = makeSelectAudienceDataAsyncStateByProfileId();
    const selectProfileWarningByProfileIdOrNull = makeSelectProfileWarningByProfileIdOrNull();
    return (state, ownProps) => ({
        audienceData: selectProfileAudienceDataByProfileIdOrNull(state, ownProps.profileId),
        loadingState: selectAudienceDataAsyncStateByProfileId(state, ownProps.profileId),
        warning: selectProfileWarningByProfileIdOrNull(state, ownProps.profileId),
        accountOptions: selectAccountOptions(state)
    });
};

export default connect(makeMapStateToProps, {
    profileGetAudienceDataOverviewRequestAction: profileAudienceDataActions.profileGetAudienceDataOverviewRequest
})(ProfileAudienceDataView);
