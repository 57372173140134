import { customRequired, makeRangeValidator } from 'src/components/forms/validators';
import React, { useState } from 'react';
import * as authTransactionActions from 'src/actions/authTransactions';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import { connect } from 'react-redux';
import EditableContent from 'src/components/EditableContent';
import IconButton from 'src/components/buttons/IconButton';
import { makeSelectIsAuthTransactionNameUpdating } from 'src/selectors/authTransactions';
import PropTypes from 'prop-types';
import { selectIsReadOnly } from 'src/selectors/loggedInUser';
import styles from 'src/stylesheets/authTransactions/editableAuthTransactionName.scss';
import withTooltip from 'src/components/withToolTip';

const rangeValidator = makeRangeValidator(0, 40, 'Max 40 chars');
const required = (value) => customRequired(value, 'Required');

const EditableAuthTransactionName = (props) => {
    const [isNameEditable, toggleIsNameEditable] = useState(false);

    const {
        authTransaction,
        isUserReadOnly,
        isAuthTransactionNameUpdating
    } = props;

    const { id, name } = authTransaction;

    const handleOnChange = (newName) => {
        const { updateAuthTransactionNameRequestAction } = props;
        updateAuthTransactionNameRequestAction(id, newName);
    };

    const IconButtonWithTooltip = withTooltip(IconButton);

    return (
        <div className={classnames(styles.wrapper, { [styles.disabled]: isUserReadOnly })}>
            <EditableContent
              validate={[required, rangeValidator]}
              value={name}
              editMode={isNameEditable}
              onDoubleClick={() => { toggleIsNameEditable(true); }}
              onLeave={() => { toggleIsNameEditable(false); }}
              onChange={handleOnChange}
              loading={isAuthTransactionNameUpdating}
              showErrorOnTheRight
            >
                <div className={styles.name}>{name}</div>
            </EditableContent>
            {
                (!isNameEditable && !isAuthTransactionNameUpdating)
                && (
                <div className={classnames(styles.editButton, { [styles.disabledIcon]: isUserReadOnly })}>
                    <IconButtonWithTooltip
                      icon="edit"
                      tooltip="Rename"
                      onClick={() => { toggleIsNameEditable(true); }}
                    />
                </div>
                )
            }
        </div>
    );
};

EditableAuthTransactionName.propTypes = {
    authTransaction: customPropTypes.authTransaction.isRequired,
    updateAuthTransactionNameRequestAction: PropTypes.func.isRequired,
    isUserReadOnly: PropTypes.bool.isRequired,
    isAuthTransactionNameUpdating: PropTypes.bool.isRequired
};

const makeMapStateToProps = (state, ownProps) => {
    const { authTransaction } = ownProps;
    const selectIsAuthTransactionNameUpdating = makeSelectIsAuthTransactionNameUpdating();
    return {
        isUserReadOnly: selectIsReadOnly(state),
        isAuthTransactionNameUpdating: selectIsAuthTransactionNameUpdating(state, authTransaction.id)
    };
};

export default connect(makeMapStateToProps, {
    updateAuthTransactionNameRequestAction: authTransactionActions.updateAuthTransactionNameRequest
})(EditableAuthTransactionName);
