import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import _orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import SelectList from 'src/components/forms/inputs/SelectList';
import styles from 'src/stylesheets/inputs/selectListWithSearchBar.scss';
import TextWithResetIcon from 'src/components/forms/inputs/TextWithResetIcon';
import memoizeOne from 'memoize-one';

const getDisabledOptionIds = memoizeOne((options, disabled) => (disabled ? options.map((option) => option.id) : []));

class SelectListWithSearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: ''
        };
        this.onSearchQueryChange = this.onSearchQueryChange.bind(this);
        this.onSearchQueryReset = this.onSearchQueryReset.bind(this);
        this.handleOnChangeWithResetBehavior = this.handleOnChangeWithResetBehavior.bind(this);
        this.memoizedGetDisabledOptionIds = memoizeOne(getDisabledOptionIds);
    }

    handleOnChangeWithResetBehavior(value) {
        const { onChange } = this.props;
        const { searchQuery } = this.state;
        onChange(value);
        if (searchQuery.length > 0) {
            this.setState({ searchQuery: '' });
        }
    }

    onSearchQueryChange(event) {
        this.setState({
            searchQuery: event.target.value
        });
    }

    onSearchQueryReset() {
        this.setState({
            searchQuery: ''
        });
    }

    render() {
        const {
            optionName, maxHeight, name, onBlur, onFocus, options, value, indeterminateItems, checkboxTooltip, searchPlaceholder, disabled
        } = this.props;
        const { searchQuery } = this.state;
        let filteredOptions = _orderBy(options, [(item) => item.label.toLowerCase()], ['asc']);
        if (searchQuery.length > 0) {
            const searchQueryToLower = searchQuery.toLowerCase();
            filteredOptions = filteredOptions.filter((group) => group.label.toLowerCase().includes(searchQueryToLower));
        }

        const body = (
            <div className={styles.body}>
                <div className={styles.section}>
                    <ul className={styles.searchList}>
                        <SelectList
                          options={filteredOptions}
                          onChange={this.handleOnChangeWithResetBehavior}
                          onFocus={onFocus}
                          onBlur={onBlur}
                          name={name}
                          maxHeight={maxHeight}
                          value={value}
                          indeterminateItems={indeterminateItems}
                          checkboxTooltip={checkboxTooltip}
                          disabledItems={this.memoizedGetDisabledOptionIds(filteredOptions, disabled)}
                        />
                    </ul>
                </div>
            </div>
        );

        return (
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <TextWithResetIcon
                      error={!!searchQuery && filteredOptions.length === 0}
                      autoFocus
                      placeholder={searchPlaceholder || `Search for ${optionName}`}
                      onChange={this.onSearchQueryChange}
                      value={searchQuery}
                      onResetClick={this.onSearchQueryReset}
                    />
                </div>
                {body}
            </div>
        );
    }
}

SelectListWithSearchBar.propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    optionName: PropTypes.string.isRequired,
    options: customPropTypes.selectListOptions.isRequired,
    maxHeight: PropTypes.number,
    value: PropTypes.array.isRequired,
    indeterminateItems: PropTypes.array,
    checkboxTooltip: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    disabled: PropTypes.bool
};

SelectListWithSearchBar.defaultProps = {
    checkboxTooltip: '',
    disabled: false
};

export default SelectListWithSearchBar;
