import React from 'react';
import { connect } from 'react-redux';
import { selectProfileAddingState } from 'src/selectors/profiles';
import _omit from 'lodash/omit';
import * as customPropTypes from 'src/customPropTypes';
import AccountAddingState from 'src/components/account/layout/AccountAddingState';

const withProfileAddingState = (WrappedComponent) => {
    const WithProfileAddingState = (props) => {
        const { addingState } = props;
        const cleanedProps = _omit(props, 'addingState');
        return (
            <AccountAddingState addingState={addingState}>
                <WrappedComponent {...cleanedProps} />
            </AccountAddingState>
        );
    };

    WithProfileAddingState.propTypes = {
        addingState: customPropTypes.asyncDataLoadingState.isRequired
    };

    const mapStateToProps = (state, ownProps) => ({
        addingState: selectProfileAddingState(state, ownProps.fieldName)
    });

    return connect(mapStateToProps)(WithProfileAddingState);
};

export default withProfileAddingState;
