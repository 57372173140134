import Backdrop from 'src/components/modals/layout/Backdrop';
import withIntercomBubbleToggle from 'src/components/modals/layout/withIntercomBubbleToggle';
import { Modal as ModalEngine } from 'react-overlays';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/modal.scss';
import modalManager from 'src/components/modals/layout/modalManager';
import DrawerDialog from 'src/components/modals/layout/DrawerDialog';
import DrawerHeader from 'src/components/modals/layout/DrawerHeader';
import DrawerFooter from 'src/components/modals/layout/DrawerFooter';
import DrawerBody from 'src/components/modals/layout/DrawerBody';

const renderBackdrop = (props) => <Backdrop {...props} />;

const ModalEngineWithIntercomBubbleToggle = withIntercomBubbleToggle(ModalEngine);

const Drawer = (props) => (
    <ModalEngineWithIntercomBubbleToggle
      manager={modalManager}
      className={styles.drawer}
      renderBackdrop={renderBackdrop}
      {...props}
    />
);

Drawer.propTypes = {
    backdrop: PropTypes.oneOf(['static', true, false]),
    show: PropTypes.bool,
    autoFocus: PropTypes.bool,
};

Drawer.defaultProps = {
    backdrop: 'static',
    show: true,
    autoFocus: true,
};

Drawer.Dialog = DrawerDialog;
Drawer.Header = DrawerHeader;
Drawer.Footer = DrawerFooter;
Drawer.Body = DrawerBody;

export default Drawer;
