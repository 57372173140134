import React from 'react';
import withLazyLoad from 'src/components/lazyLoad/withLazyLoad';
import styles from 'src/stylesheets/profile/profilePicture.scss';
import classnames from 'classnames';
import noImage from 'src/resources/illustrations/no_image.png';
import PropTypes from 'prop-types';

const ImageWithLazyLoad = withLazyLoad(({ imageUrl }) => <div className={styles.profilePicture} style={{ backgroundImage: `url(${imageUrl}), url(${noImage})` }} />);

const ProfilePicture = ({ picture, lazyLoadProfileImage, isLarge }) => (
    <div className={classnames(styles.wrapper, { [styles.large]: isLarge })}>
        <div className={styles.profilePictureWrapper}>
            {
                picture
                    ? <ImageWithLazyLoad height={80} lazyLoad={lazyLoadProfileImage} imageUrl={picture} isLarge={isLarge} />
                    : <ImageWithLazyLoad height={80} lazyLoad={lazyLoadProfileImage} imageUrl={noImage} isLarge={isLarge} />
            }
        </div>
    </div>
);

ProfilePicture.propTypes = {
    picture: PropTypes.string,
    lazyLoadProfileImage: PropTypes.bool.isRequired,
    isLarge: PropTypes.bool
};

ProfilePicture.defaultProps = {
    isLarge: false
};

export default ProfilePicture;
