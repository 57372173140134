import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import InlineListWithSearchbar from 'src/components/inlineList/InlineListWithSearchbar';
import { profileAddToOrRemoveFromGroupRequest } from 'src/actions/profiles';
import PropTypes from 'prop-types';
import React from 'react';
import { selectIsAddRemoveGroupsState, selectUserCreatedGroups } from 'src/selectors/groups';
import { groupAddRequest } from 'src/actions/groups';
import noGroups from 'src/resources/illustrations/no-groups.png';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import { getListOption } from 'src/utils/groups';
import { makeLengthValidator } from 'src/components/inlineList/validators';

const style = {
    height: '495px'
};

export const renderEmptyGroup = () => <EmptyInfoScreen message="You don’t have any groups yet, start typing to add one" image={noGroups} />;
const minMaxValidator = makeLengthValidator('group', 2, 25);

const GroupSelectorModal = (props) => {
    const {
        hideModal,
        accountIds,
        selectedGroupIds,
        accountAddToOrRemoveFromGroupRequestAction,
        enforceFocus,
        indeterminateGroupIds,
        groups,
        addRemoveGroupsState
    } = props;

    const { isPending } = addRemoveGroupsState;

    const options = getListOption(groups);

    const handleOnApplyClick = (selectedOptions) => {
        accountAddToOrRemoveFromGroupRequestAction(accountIds, selectedOptions);
    };

    const handleOnCreateNewGroup = (newGroup) => {
        const { groupAddRequestAction } = props;
        groupAddRequestAction({ name: newGroup });
    };

    const handleOnCancelClick = () => {
        hideModal();
    };

    return (
        <Modal dataViewName="group-selector" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog width={520}>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Add or remove groups
                    </Modal.Title>
                </Modal.Header>
                <div style={style}>
                    <InlineListWithSearchbar
                      options={options}
                      optionName="group"
                      entityIds={accountIds}
                      selectedOptionIds={selectedGroupIds}
                      indeterminateOptionIds={indeterminateGroupIds}
                      renderEmptyOptions={renderEmptyGroup}
                      applyAction={handleOnApplyClick}
                      createNewEntityAction={handleOnCreateNewGroup}
                      cancelAction={handleOnCancelClick}
                      validateCreateNewOption={minMaxValidator}
                      isApplyActionPending={isPending}
                    />
                </div>
            </Modal.Dialog>
        </Modal>
    );
};

GroupSelectorModal.propTypes = {
    accountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    groupAddRequestAction: PropTypes.func.isRequired,
    addRemoveGroupsState: customPropTypes.asyncDataLoadingState.isRequired,
    selectedGroupIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    indeterminateGroupIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    groups: customPropTypes.groups.isRequired,
    accountAddToOrRemoveFromGroupRequestAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => (state) => ({
    groups: selectUserCreatedGroups(state),
    addRemoveGroupsState: selectIsAddRemoveGroupsState(state)
});

export default connect(makeMapStateToProps, {
    groupAddRequestAction: groupAddRequest,
    profileAddToOrRemoveFromGroupRequestAction: profileAddToOrRemoveFromGroupRequest
})(GroupSelectorModal);
