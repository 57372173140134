import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import FormError from 'src/components/forms/feedback/FormError';
import styles from 'src/stylesheets/forms/dataPushTaskForm.scss';
import DataPushTaskFields from 'src/components/forms/dataPushTasks/DataPushTaskFields';
import { dataPushTaskDataSourceType } from 'src/utils/dataPushTasks';

const DataPushTaskAddForm = (props) => {
    const { handleSubmit, error, form } = props;

    return (
        <form onSubmit={handleSubmit} className={styles.wrapper}>
            {
                error && (
                    <div className={styles.error}>
                        <FormError message={error.message} />
                    </div>
                )
            }
            <DataPushTaskFields form={form} dataPushTaskType={dataPushTaskDataSourceType} />
        </form>
    );
};

DataPushTaskAddForm.propTypes = {
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.object
};

export default withReduxForm(DataPushTaskAddForm, {
    destroyOnUnmount: false
});
