import { required } from 'src/components/forms/validators';
import { Checkbox } from 'src/components/forms/fields';
import * as customPropTypes from 'src/customPropTypes';
import { Link } from 'react-router';
import { Field } from 'redux-form';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import { connect } from 'react-redux';

const generateTextWithLink = () => (
    <span>
        I accept the <Link to="https://www.quintly.com/terms/" target="_blank">Terms and Conditions</Link> and the <Link to="https://www.quintly.com/privacy/" target="_blank">Privacy statement</Link>.
    </span>
);

const AcceptTermsAndConditionsForm = (props) => {
    const { handleSubmit, error, submitting } = props;
    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <Field
              name="acceptedTermsAndConditions"
              text={generateTextWithLink()}
              component={Checkbox}
              disabled={submitting}
              validate={required}
            />
        </form>
    );
};

AcceptTermsAndConditionsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: customPropTypes.dataLoadingError
};

const mapStateToProps = () => ({
    initialValues: {
        acceptedTermsAndConditions: false
    }
});

export default connect(mapStateToProps)(withReduxForm(AcceptTermsAndConditionsForm));
