import ListInfoCreatedBy from 'src/components/ListInfoCreatedBy';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import DashboardMetricTypeOverview from 'src/components/views/dashboards/DashboardMetricTypeOverview';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import ReportStatusLabel from 'src/components/labels/ReportStatusLabel';
import styles from 'src/stylesheets/dashboardInfo.scss';
import { connect } from 'react-redux';
import { makeSelectDashboardReportsByDashboardId } from 'src/selectors/dashboardReports';

const DashboardInfo = (props) => {
    const {
        dashboard, dashboardReports, loggedInUser, showLabels, labelIcon
    } = props;
    const metricCount = dashboard.dashboardMetricIds.length;
    let dashboardReportId = null;
    if (dashboardReports.length > 0) {
        dashboardReportId = dashboardReports[0].id;
    }
    return (
        <div className={styles.wrapper}>
            <div className={styles.iconWrapper}>
                <IcomoonIcon icon="dashboard" className={styles.icon} />
            </div>
            <div className={styles.textWrapper}>
                <div className={styles.infoWrapper}>
                    <div className={classnames('dashboardName', styles.name)}>
                        {dashboard.name}
                    </div>
                    {
                        metricCount > 0
                            && (
                            <div className={styles.metricOverview}>
                                <DashboardMetricTypeOverview dashboardMetricIds={dashboard.dashboardMetricIds} />
                            </div>
                            )
                    }
                    {
                        showLabels.indexOf('report') !== -1 && dashboardReportId !== null
                        && (
                        <div className={styles.label}>
                            <ReportStatusLabel dashboardReportId={dashboardReportId} />
                        </div>
                        )
                    }
                </div>
                <ul className={styles.labels}>
                    {
                        showLabels.indexOf('type') !== -1
                        && <li className={styles.label}>{ labelIcon && <IcomoonIcon className={styles.labelIcon} icon={labelIcon} /> } Dashboard</li>
                    }
                    {
                        showLabels.indexOf('createdByUser') !== -1 && dashboard.createdByUserId
                        && (
                        <li className={styles.label}>
                            <ListInfoCreatedBy
                              loggedInUserId={loggedInUser.id}
                              cratedByUserId={dashboard.createdByUserId}
                              createdByUserName={dashboard.createdByUserName}
                              createdTime={dashboard.createTime}
                            />
                        </li>
                        )
                    }
                    {
                        showLabels.indexOf('metricCount') !== -1
                        && <li className={styles.label}>{metricCount > 0 ? `${metricCount} metrics` : 'No metrics yet'}</li>
                    }
                </ul>
            </div>
        </div>
    );
};

DashboardInfo.propTypes = {
    dashboard: customPropTypes.dashboard.isRequired,
    dashboardReports: customPropTypes.dashboardReports.isRequired,
    hasReport: PropTypes.bool.isRequired,
    loggedInUser: customPropTypes.user.isRequired,
    showLabels: PropTypes.arrayOf(PropTypes.string),
    labelIcon: PropTypes.string
};

DashboardInfo.defaultProps = {
    showLabels: ['type', 'createdByUser', 'metricCount', 'report']
};

const makeMapStateToProps = () => {
    const selectDashboardReportsByDashboardId = makeSelectDashboardReportsByDashboardId();
    return (state, { dashboard }) => ({
        dashboardReports: selectDashboardReportsByDashboardId(state, dashboard.id)
    });
};

export default connect(makeMapStateToProps)(DashboardInfo);
