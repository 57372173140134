import classnames from 'classnames';
import { connect } from 'react-redux';
import IcomoonIcon from 'src/components/IcomoonIcon';
import { makeSelectDashboardsMetricsByType } from 'src/selectors/dashboards';
import NetworkIcon from 'src/components/NetworkIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/dashboardMetricTypeOverview.scss';
import withTooltip from 'src/components/withToolTip';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const DashboardMetricTypeOverview = (props) => {
    const { platformTypesOfMetrics, hasCustomMetric } = props;

    return (
        <div className={styles.wrapper}>
            {
                platformTypesOfMetrics.map((platformType) => (
                    <NetworkIcon
                      key={platformType}
                      platformType={platformType}
                      className={classnames(styles.icon, { [styles.youtube]: platformType === 'youtube' })}
                    />
                ))
            }
            {
                hasCustomMetric
                && (
                <IcomoonIconWithTooltip
                  tooltip="Dashboard contains custom metrics"
                  icon="metric-editor"
                  className={classnames(styles.icon, styles.customMetric)}
                />
                )
            }
        </div>
    );
};

DashboardMetricTypeOverview.propTypes = {
    dashboardMetricIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    platformTypesOfMetrics: PropTypes.arrayOf(PropTypes.string).isRequired,
    hasCustomMetric: PropTypes.bool
};

DashboardMetricTypeOverview.defaultProps = {
    hasCustomMetric: false
};

const makeMapStateToProps = () => {
    const selectDashboardsMetricsByType = makeSelectDashboardsMetricsByType();
    return (state, ownProps) => {
        const { hasCustomMetric, platformTypesOfMetrics } = selectDashboardsMetricsByType(state, ownProps.dashboardMetricIds);
        return {
            platformTypesOfMetrics,
            hasCustomMetric
        };
    };
};

export default connect(makeMapStateToProps)(DashboardMetricTypeOverview);
