import { getDateOptionsByIntervalType, getStartOfWeek } from 'src/components/chartViews/highcharts/configs/utils';
import Highcharts from 'src/components/chartViews/highcharts/Highcharts';

const ProfileHealthChart = (props) => {
    const {
        accountOptions,
        profileHealthMetricData,
        width,
        height
    } = props;
    const { weekDefinition, dateFormat } = accountOptions;
    const dateOptions = getDateOptionsByIntervalType('daily', dateFormat, weekDefinition, 'highChartXRange');

    const { yAxisCategories, data } = profileHealthMetricData;
    return {
        chart: { type: 'xrange', width, height },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: dateOptions.dateFormat,
            minTickInterval: dateOptions.minTickInterval,
            startOfWeek: getStartOfWeek(weekDefinition),
            startOnTick: true,
            endOnTick: false
        },
        yAxis: [{
            title: { enabled: false },
            categories: yAxisCategories,
            min: 0,
            max: yAxisCategories.length - 1,
        }],
        plotOptions: {
            xrange: {
                pointWidth: 20,
                animation: false
            }
        },
        legend: {
            enabled: false
        },
        tooltip: {
            formatter() {
                const renderPoint = ({ point }) => {
                    const {
                        x, x2, yCategory, content
                    } = point;
                    return `<b>${yCategory}</b><br />${Highcharts.dateFormat(dateOptions.dateFormatToolTip, x)} - ${Highcharts.dateFormat(dateOptions.dateFormatToolTip, x2)}<br/>${content}`;
                };
                return renderPoint(this);
            }
        },
        series: [{ data }]
    };
};

export default ProfileHealthChart;
