import React from 'react';
import { makeSelectProfileHealthByProfileId } from 'src/selectors/profileHealth';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import IcomoonIcon from 'src/components/IcomoonIcon';
import withPopover from 'src/components/withPopover';
import withTooltip from 'src/components/withToolTip';
import UnHealthyProfileInfoPopover from 'src/components/popovers/UnHealthyProfileInfoPopover';
import { getUnHealthyProfileInfo } from 'src/utils/getUnHealthyProfileInfo';
import styles from 'src/stylesheets/profile/unHealthyProfileIndicator.scss';
import classnames from 'classnames';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);
const IcomoonIconWithPopoverAndTooltip = withPopover(IcomoonIconWithTooltip);

const UnHealthyProfileIndicator = (props) => {
    const { profileHealth, profile } = props;
    if (profileHealth) {
        const { isHealthy, unHealthyType } = profileHealth;
        if (!isHealthy && unHealthyType) {
            const profileUnHealthyInfo = getUnHealthyProfileInfo(profile.platformType, profileHealth.unHealthyType);
            if (profileUnHealthyInfo) {
                const { tooltip, content } = profileUnHealthyInfo;
                const overlay = <UnHealthyProfileInfoPopover profileHealth={profileHealth} profile={profile} content={content} />;
                return (
                    <IcomoonIconWithPopoverAndTooltip
                      icon="alert"
                      overlay={overlay}
                      tooltip={tooltip}
                      className={classnames(styles.icon, styles.withPopover)}
                    />
                );
            }
            return (
                <IcomoonIconWithTooltip
                  icon="alert"
                  tooltip="Unknown issue"
                  className={styles.icon}
                />
            );
        }
    }
    return null;
};

UnHealthyProfileIndicator.propTypes = {
    profile: customPropTypes.profile.isRequired,
    profileHealth: customPropTypes.profileHealth
};

const makeMapStateToProps = () => {
    const selectProfileHealthByProfileId = makeSelectProfileHealthByProfileId();
    return (state, ownProps) => ({
        profileHealth: selectProfileHealthByProfileId(state, ownProps.profile.id)
    });
};

export default connect(makeMapStateToProps)(UnHealthyProfileIndicator);
