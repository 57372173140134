import React, { PureComponent } from 'react';
import _omit from 'lodash/omit';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/radio.scss';

class Radio extends PureComponent {
    render() {
        const {
            disabled, error, text, textClassName
        } = this.props;
        const cleanedProps = _omit(this.props, ['error', 'text', 'active', 'textClassName']);
        return (
            <label className={classnames(styles.wrapper, { [styles.wrapperDisabled]: disabled, [styles.error]: error })}>
                <input
                  {...cleanedProps}
                  type="radio"
                  className={styles.input}
                />
                <span className={styles.radio}><span className={styles.innerRadio} /></span>
                { text && <span className={classnames(styles.text, { [textClassName]: textClassName })}>{text}</span> }
            </label>
        );
    }
}

Radio.propTypes = {
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    textClassName: PropTypes.string
};

Radio.defaultProps = {
    error: false,
    disabled: false,
    text: '',
    textClassName: ''
};

export default Radio;
