import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import * as socialNetworkSearchActions from 'src/actions/profileSearch';
import PropTypes from 'prop-types';
import { getSocialNetworkAuthenticatedSearch } from 'src/selectors/profileSearch';
import ProfileSearchResultList from 'src/components/views/profiles/search/ProfileSearchResultList';
import { listName } from 'src/utils/profileSearch';
import withAccountSearchListHandling from 'src/components/bulkList/withAccountSearchListHandling';
import { modalShowSocialNetworkLoginUseCaseSelection } from 'src/actions/overlays';
import AuthenticatedSearchAccountResult from 'src/components/AuthenticatedSearchAccountResult';

const ProfileSearchResultListWithProfileSearchListHandling = withAccountSearchListHandling(ProfileSearchResultList, listName);

const renderResultList = (data) => <ProfileSearchResultListWithProfileSearchListHandling accountIds={data} searchedProfileIds={data} />;

const AuthenticatedSearchProfileResults = (props) => {
    const {
        result,
        socialNetworkSearchResetAction,
        modalShowSocialNetworkLoginUseCaseSelectionAction
    } = props;
    return (
        <AuthenticatedSearchAccountResult
          result={result}
          tryAgainAction={modalShowSocialNetworkLoginUseCaseSelectionAction}
          renderResultList={renderResultList}
          onBackClick={socialNetworkSearchResetAction}
        />
    );
};

AuthenticatedSearchProfileResults.propTypes = {
    modalShowSocialNetworkLoginUseCaseSelectionAction: PropTypes.func.isRequired,
    socialNetworkSearchResetAction: PropTypes.func.isRequired,
    result: customPropTypes.accountSearchResult.isRequired
};

const mapStateToProps = (state) => ({
    result: getSocialNetworkAuthenticatedSearch(state)
});

export default connect(mapStateToProps, {
    modalShowSocialNetworkLoginUseCaseSelectionAction: modalShowSocialNetworkLoginUseCaseSelection,
    socialNetworkSearchResetAction: socialNetworkSearchActions.socialNetworkSearchReset
})(AuthenticatedSearchProfileResults);
