import {
    AdditionalFilterSelector, CombinedDateSelector, ProfileSelector, SingleAbsoluteDateSelector, Checkbox
} from 'src/components/forms/fields';
import Password from 'src/components/forms/fields/Password';
import * as customPropTypes from 'src/customPropTypes';
import { Field, formValueSelector } from 'redux-form';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import {
    required, makeLengthValidator, validateDateSelection, validateProfileSelection
} from 'src/components/forms/validators';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import styles from 'src/stylesheets/forms/createSharedDashboardLink.scss';
import classnames from 'classnames';

const passwordLength = makeLengthValidator(6, 50);

const allowOnlyFutureDates = (value) => (moment.tz(value, 'UTC').unix() < moment.utc().unix() ? 'Only future dates are allowed.' : undefined);

const CreateShareableLinkForm = (props) => {
    const {
        error, handleSubmit, isPasswordProtected, initialValues, hasExpiry
    } = props;

    const handleOnExpiryToggle = () => {
        const { change, validUntil } = props;
        if (validUntil === null) {
            change('validUntil', moment().add(30, 'days').format('YYYY-MM-DD'));
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <Field
              name="profileSelection"
              label="Profile"
              component={ProfileSelector}
              validate={validateProfileSelection}
            />
            <Field
              name="dateSelection"
              label="Date range"
              component={CombinedDateSelector}
              validate={validateDateSelection}
            />
            <Field
              name="additionalFilterSelection"
              label="Filter"
              component={AdditionalFilterSelector}
            />
            <div className={classnames(styles.combinedFiled, styles.password)}>
                <Field
                  name="isPasswordProtected"
                  component={Checkbox}
                  text="Password protect report"
                />
                {
                    isPasswordProtected && (
                        <div className={styles.subField}>
                            <Field autoComplete="off" autoFocus component={Password} validate={initialValues.isPasswordProtected === false ? [required, passwordLength] : []} name="password" info="Enter a password" />
                        </div>
                    )
                }
            </div>
            <div className={styles.combinedFiled}>
                <Field
                  name="hasExpiry"
                  component={Checkbox}
                  text="Access expires"
                  onChange={handleOnExpiryToggle}
                />
                {
                    hasExpiry && (
                        <div className={styles.subField}>
                            <Field
                              name="validUntil"
                              component={SingleAbsoluteDateSelector}
                              stretch={false}
                              validate={[allowOnlyFutureDates]}
                            />
                        </div>
                    )
                }
            </div>
        </form>
    );
};

CreateShareableLinkForm.propTypes = {
    error: customPropTypes.dataLoadingError,
    handleSubmit: PropTypes.func.isRequired,
    isPasswordProtected: PropTypes.bool,
    initialValues: PropTypes.object.isRequired,
    hasExpiry: PropTypes.bool.isRequired,
    validUntil: PropTypes.string,
    change: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const selectFormValue = formValueSelector(ownProps.form);
    return {
        isPasswordProtected: selectFormValue(state, 'isPasswordProtected'),
        hasExpiry: !!selectFormValue(state, 'hasExpiry'),
        validUntil: selectFormValue(state, 'validUntil')
    };
};

export default connect(mapStateToProps)(withReduxForm(CreateShareableLinkForm));
