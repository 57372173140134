import FolderBrowserAddFolderButton from 'src/components/folderBrowser/FolderBrowserAddFolderButton';
import FolderBrowserSubmitButton from 'src/components/folderBrowser/FolderBrowserSubmitButton';
import FolderBrowserTitle from 'src/components/folderBrowser/FolderBrowserTitle';
import React, { Component } from 'react';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { dashboardCloneDashboardTemplateRequest } from 'src/actions/dashboard';
import Modal from 'src/components/modals/layout/Modal';
import { modalShowAddFolder } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import { selectIsDashboardSavePending } from 'src/selectors/folders';
import FolderBrowser from 'src/components/folderBrowser/FolderBrowser';
import { SUPER_ROOT } from 'src/utils/dashboardFolders';

class SaveDashboardModal extends Component {
    constructor(props) {
        super(props);
        this.onFolderOrBreadcrumbItemClick = this.onFolderOrBreadcrumbItemClick.bind(this);
        this.handleSaveDashboard = this.handleSaveDashboard.bind(this);
        this.state = {
            toFolderId: SUPER_ROOT,
        };
    }

    handleSaveDashboard() {
        const { dashboardTemplateId, dashboardCloneDashboardTemplateRequestAction } = this.props;
        const { toFolderId } = this.state;
        dashboardCloneDashboardTemplateRequestAction(dashboardTemplateId, toFolderId);
    }

    onFolderOrBreadcrumbItemClick(toFolderId) {
        this.setState({
            toFolderId,
        });
    }

    render() {
        const {
            hideModal,
            enforceFocus,
            isSaving,
            modalShowAddFolderAction
        } = this.props;

        const { toFolderId } = this.state;
        return (
            <Modal dataViewName="save-dashboard" onHide={hideModal} enforceFocus={enforceFocus}>
                <Modal.Dialog width={620}>
                    <Modal.Header onHide={hideModal}>
                        <Modal.Title><FolderBrowserTitle toFolderId={toFolderId} actionName="Save" /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                      height={400}
                      stickyFooter={<FolderBrowserAddFolderButton onClick={modalShowAddFolderAction} toFolderId={toFolderId} />}
                    >
                        <FolderBrowser toFolderId={toFolderId} onItemClick={this.onFolderOrBreadcrumbItemClick} />
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button onClick={hideModal} label="Cancel" />
                            <FolderBrowserSubmitButton
                              toFolderId={toFolderId}
                              onClick={this.handleSaveDashboard}
                              isLoading={isSaving}
                              label="Save here"
                            />
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal.Dialog>
            </Modal>
        );
    }
}

SaveDashboardModal.propTypes = {
    enforceFocus: PropTypes.bool,
    isSaving: PropTypes.bool,
    dashboardTemplateId: PropTypes.string.isRequired,
    hideModal: PropTypes.func.isRequired,
    modalShowAddFolderAction: PropTypes.func.isRequired,
    dashboardCloneDashboardTemplateRequestAction: PropTypes.func.isRequired
};

SaveDashboardModal.defaultProps = {
    isSaving: false,
};

const mapStateToProps = (state, ownProps) => {
    const isSaving = selectIsDashboardSavePending(state, ownProps.dashboardTemplateId);
    return {
        isSaving
    };
};

export default connect(mapStateToProps, {
    dashboardCloneDashboardTemplateRequestAction: dashboardCloneDashboardTemplateRequest,
    modalShowAddFolderAction: modalShowAddFolder
})(SaveDashboardModal);
