import * as customPropTypes from 'src/customPropTypes';
import AuthTransactionsDetailRow from 'src/components/authTransactions/AuthTransactionsDetailRow';
import React from 'react';
import styles from 'src/stylesheets/authTransactions/authTransactionsDetailTable.scss';
import ListTable from 'src/components/listTable/ListTable';
import ListTableCell from 'src/components/listTable/ListTableCell';
import ListTableRow from 'src/components/listTable/ListTableRow';
import ListTableHeader from 'src/components/listTable/ListTableHeader';
import _range from 'lodash/range';

const columns = [
    {
        id: 'name', content: 'Name', width: '20%'
    },
    {
        id: 'createTime', content: 'Creation date', width: '20%'
    },
    {
        id: 'networks', content: 'Networks', width: '12%'
    },
    {
        id: 'expiry', content: 'Expiry', width: '17%'
    },
    {
        id: 'status', content: 'Status', width: '16%'
    },
    {
        id: 'link', content: 'Link', width: '15%'
    }
];

const AuthTransactionsDetailTable = (props) => {
    const { openedAuthTransactions, closedAuthTransactions } = props;
    return (
        <div className={styles.wrapper}>
            <ListTable header={<ListTableHeader columns={columns} />}>
                {
                    openedAuthTransactions.map((authTransaction) => (
                        <AuthTransactionsDetailRow
                          key={authTransaction.id}
                          authTransaction={authTransaction}
                        />
                    ))
                }
                {
                    openedAuthTransactions.length === 0
                    && (
                        <ListTableRow className={styles.emptyRow} hasBorder={false}>
                            <ListTableCell>No open requests</ListTableCell>
                        </ListTableRow>
                    )
                }
                {
                    closedAuthTransactions.length > 0 && (
                        <ListTableRow hasBorder={false}>
                            {
                                _range(columns.length).map((i) => <ListTableCell key={i} padded={false}><div className={styles.divider} /></ListTableCell>)
                            }
                        </ListTableRow>
                    )
                }
                {
                    closedAuthTransactions.length > 0
                    && closedAuthTransactions.map((authTransaction) => (
                        <AuthTransactionsDetailRow
                          key={authTransaction.id}
                          authTransaction={authTransaction}
                        />
                    ))
                }
            </ListTable>
        </div>
    );
};

AuthTransactionsDetailTable.propTypes = {
    openedAuthTransactions: customPropTypes.authTransactions.isRequired,
    closedAuthTransactions: customPropTypes.authTransactions.isRequired
};

export default AuthTransactionsDetailTable;
