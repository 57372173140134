import React from 'react';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import * as profileSearchActions from 'src/actions/profileSearch';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/profile/facebookMigratedProfileLink.scss';
import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import { modalsShowProfileSearch } from 'src/actions/overlays';

const FacebookMigratedProfileLink = (props) => {
    const { link, modalsShowProfileSearchAction, profileSearchByKeywordRequestAction } = props;
    return (
        <div className={styles.wrapper}>
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
                {link}
            </a>
            <Button
              label="Open in profile search"
              small
              stretch
              icon="vist-page"
              onClick={() => {
                  modalsShowProfileSearchAction();
                  profileSearchByKeywordRequestAction(link);
              }}
            />
        </div>
    );
};

FacebookMigratedProfileLink.propTypes = {
    link: PropTypes.string.isRequired,
    modalsShowProfileSearchAction: PropTypes.func.isRequired,
    profileSearchByKeywordRequestAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalsShowProfileSearchAction: ensurePermission(modalsShowProfileSearch, writePermissions.addProfile),
    profileSearchByKeywordRequestAction: profileSearchActions.profileSearchByKeywordRequest
})(FacebookMigratedProfileLink);
