import { Checkbox, TagSelectList } from 'src/components/forms/fields';
import * as customPropTypes from 'src/customPropTypes';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/spaceOptionSelector.scss';
import _get from 'lodash/get';

const SpaceOptionSelector = (props) => {
    const { space, groupTags, fields } = props;
    const fieldsValue = fields.getAll();

    const {
        readOnlySpace,
        spaceSelected
    } = _get(fieldsValue, 0, { readOnlySpace: false, spaceSelected: false });

    return (
        <div>
            { fields.map((spaceSelector) => (
                <div key={space.id} className={styles.spaceWrapper}>
                    <div className={styles.checkBoxFields}>
                        <div className={styles.checkboxField}>
                            <Field
                              name={`${spaceSelector}.spaceSelected`}
                              component={Checkbox}
                              text={space.name}
                            />
                        </div>
                        <div className={styles.checkboxField}>
                            <Field
                              name={`${spaceSelector}.readOnlySpace`}
                              component={Checkbox}
                              text="Read-only/Allowed groups"
                              disabled={!spaceSelected}
                            />
                        </div>
                    </div>
                    {
                        (readOnlySpace && spaceSelected)
                            && (
                            <div className={styles.groupSearchBar}>
                                <Field
                                  name={`${spaceSelector}.defaultSelectedTags`}
                                  label="Select groups"
                                  options={groupTags}
                                  labelKey="name"
                                  component={TagSelectList}
                                />
                            </div>
                            )
                    }
                </div>
            ))}
        </div>
    );
};

SpaceOptionSelector.propTypes = {
    fields: PropTypes.object.isRequired,
    space: customPropTypes.spaceForManagement.isRequired,
    groupTags: customPropTypes.tags.isRequired
};

export default SpaceOptionSelector;
