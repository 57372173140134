import React from 'react';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/inlineList/filteredOptionList.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';
import classnames from 'classnames';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import withTooltip from 'src/components/withToolTip';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const generateSentenceForNewOption = (optionName, creatableOption) => `create new ${optionName} '${creatableOption}'`;

const CreatableOption = (props) => {
    const {
        creatableOption, optionName
    } = props;
    const { optionToCreate, validationError } = creatableOption;
    const isInvalid = validationError !== undefined;
    return (
        <div className={classnames(styles.creatableOption, { [styles.disabled]: isInvalid })}>
            {
                isInvalid && <IcomoonIconWithTooltip tooltip={validationError} icon="alert" className={styles.createIcon} />
            }
            {
                !isInvalid && <IcomoonIcon icon="plus" className={styles.createIcon} />
            }
            <div className={styles.name}>
                <DivWithTooltip tooltip={generateSentenceForNewOption(optionName, optionToCreate)}>
                    {generateSentenceForNewOption(optionName, optionToCreate)}
                </DivWithTooltip>
            </div>
        </div>
    );
};

CreatableOption.propTypes = {
    creatableOption: customPropTypes.creatableOption.isRequired,
    optionName: PropTypes.string.isRequired
};

export default CreatableOption;
