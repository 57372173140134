import AccountInfoListItem from 'src/components/account/layout/AccountInfoListItem';
import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import adAccountThumbnail from 'src/resources/icons/ad-account-thumbnail.png';

const AdAccountInfoListItem = (props) => {
    const {
        adAccount, highlight, showThumbnail
    } = props;
    return (
        <AccountInfoListItem
          showThumbnail={showThumbnail}
          thumbnail={adAccountThumbnail}
          name={adAccount.defaultName}
          platformType={adAccount.platformType}
          highlight={highlight}
          appendix={adAccount.appendix}
        />
    );
};

AdAccountInfoListItem.propTypes = {
    adAccount: customPropTypes.adAccount.isRequired,
    highlight: PropTypes.string,
    showThumbnail: PropTypes.bool
};

AdAccountInfoListItem.defaultProps = {
    highlight: '',
    showThumbnail: true
};

export default AdAccountInfoListItem;
