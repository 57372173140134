import React from 'react';
import Select from 'src/components/forms/inputs/Select';
import TimezoneSelect from 'src/components/forms/inputs/TimezoneSelect';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/forms/standardReportScheduler.scss';
import _range from 'lodash/range';
import * as customPropTypes from 'src/customPropTypes';
import StandardScheduleNextRunDate from 'src/components/dashboardReportSchedule/StandardScheduleNextRunDate';

const intervalTypeOptions = [
    { value: 'quarterly', name: 'Quarterly' },
    { value: 'monthly', name: 'Monthly' },
    { value: 'weekly', name: 'Weekly' },
    { value: 'daily', name: 'Daily' }
];

const intervalDetailMonthlyOptions = () => _range(1, 32).map((value) => ({ value: `${value}`, name: value }));

const intervalDetailWeeklyOptions = [
    { value: '0', name: 'Monday' },
    { value: '1', name: 'Tuesday' },
    { value: '2', name: 'Wednesday' },
    { value: '3', name: 'Thursday' },
    { value: '4', name: 'Friday' },
    { value: '5', name: 'Saturday' },
    { value: '6', name: 'Sunday' }
];

const isHour = (hour) => {
    const hourCheck = /^([0-1][0-9]|2[0-3])$/;
    return !!hourCheck.test(hour.trim());
};

const isMinute = (minute) => {
    const minuteCheck = /^(00|15|30|45)$/;
    return !!minuteCheck.test(minute.trim());
};

const getHourAndMinute = (timeValue) => {
    const hourAndMinute = timeValue ? timeValue.trim().split(':') : [];
    const hour = (hourAndMinute[0] && isHour(hourAndMinute[0])) ? hourAndMinute[0] : '00';
    const minute = (hourAndMinute[1] && isMinute(hourAndMinute[1])) ? hourAndMinute[1] : '00';
    return { hour, minute };
};

const renderHours = () => _range(0, 24).map((hour) => {
    const value = hour > 9 ? `${hour}` : `0${hour}`;
    return {
        value,
        name: value
    };
});

const StandardReportScheduler = (props) => {
    const {
        value, name, onBlur, onFocus, onChange, disabled, error
    } = props;
    const {
        intervalType,
        intervalDetailWeekly,
        intervalDetailMonthly,
        reportTime,
        timezone
    } = value;

    const { hour, minute } = getHourAndMinute(reportTime);
    const handleOnIntervalChange = (e) => {
        const interval = e.target.value;
        onChange(Object.assign({}, props.value, { intervalType: interval }));
    };

    const handleOnIntervalMonthlyChange = (e) => {
        const intervalDetail = e.target.value;
        onChange(Object.assign({}, props.value, { intervalDetailMonthly: intervalDetail }));
    };

    const handleOnIntervalWeeklyChange = (e) => {
        const intervalDetail = e.target.value;
        onChange(Object.assign({}, props.value, { intervalDetailWeekly: intervalDetail }));
    };

    const handleOnTimezoneChange = (newTimezone) => {
        onChange(Object.assign({}, props.value, { timezone: newTimezone }));
    };

    const handleOnHourChange = (e) => {
        const newHour = e.target.value;
        const { minute: existingMin } = getHourAndMinute(props.value.reportTime);
        const newTime = `${newHour}:${existingMin}`;
        onChange(Object.assign({}, props.value, { reportTime: newTime }));
    };

    const handleOnMinuteChange = (e) => {
        const newMinute = e.target.value;
        const { hour: existingHour } = getHourAndMinute(props.value.reportTime);
        const newTime = `${existingHour}:${newMinute}`;
        onChange(Object.assign({}, props.value, { reportTime: newTime }));
    };

    const handleOnBlur = () => {
        onBlur(value);
    };

    return (
        <div className={styles.wrapper} onFocus={onFocus} onBlur={handleOnBlur}>
            <div className={styles.schedule}>
                <div className={styles.scheduleItem}>
                    <div className={styles.header}>Frequency</div>
                    <Select onChange={handleOnIntervalChange} name="intervalType" value={intervalType}>
                        {
                            intervalTypeOptions.map((element) => (
                                <option key={`interval_${element.value}`} value={element.value}>{element.name}</option>
                            ))
                        }
                    </Select>
                </div>
                {
                    (intervalType === 'monthly' || intervalType === 'quarterly')
                    && (
                        <div className={styles.scheduleItem}>
                            <div className={styles.header}>Day of month</div>
                            <Select onChange={handleOnIntervalMonthlyChange} name="intervalMonthly" value={intervalDetailMonthly}>
                                {
                                    intervalDetailMonthlyOptions().map((elements) => (
                                        <option key={elements.value} value={elements.value}>
                                            {elements.name}
                                        </option>
                                    ))
                                }
                            </Select>
                        </div>

                    )
                }
                {
                    (intervalType === 'weekly')
                    && (
                        <div className={styles.scheduleItem}>
                            <div className={styles.header}>Day of week</div>
                            <Select onChange={handleOnIntervalWeeklyChange} name="intervalWeekly" value={intervalDetailWeekly}>
                                {
                                    intervalDetailWeeklyOptions.map((elements) => (
                                        <option key={elements.value} value={elements.value}>
                                            {elements.name}
                                        </option>
                                    ))
                                }
                            </Select>
                        </div>
                    )
                }
                <div className={styles.timeWrapper}>
                    <div className={styles.header}>Delivery time (HH:MM)</div>
                    <div className={styles.time}>
                        <Select
                          name="hours"
                          value={hour}
                          onChange={handleOnHourChange}
                          disabled={disabled}
                          error={error}
                          className={styles.hour}
                        >
                            {
                                renderHours().map((elements) => (
                                    <option
                                      key={elements.value}
                                      value={elements.value}
                                    >
                                        {elements.name}
                                    </option>
                                ))
                            }
                        </Select>
                        <span className={styles.separator}>:</span>
                        <Select
                          name="minutes"
                          value={minute}
                          onChange={handleOnMinuteChange}
                          disabled={disabled}
                          error={error}
                        >
                            <option value="00">00</option>
                            <option value="15">15</option>
                            <option value="30">30</option>
                            <option value="45">45</option>
                        </Select>
                    </div>
                </div>
                <div>
                    <div className={styles.header}>Timezone</div>
                    <TimezoneSelect onChange={handleOnTimezoneChange} value={timezone} />
                </div>
            </div>
            <div>
                <StandardScheduleNextRunDate
                  intervalType={intervalType}
                  intervalDetailWeekly={intervalDetailWeekly}
                  intervalDetailMonthly={intervalDetailMonthly}
                  reportTime={reportTime}
                  timezone={timezone}
                />
            </div>
            <input
              type="hidden"
              name={name}
              value={JSON.stringify(value)}
            />
        </div>
    );
};

StandardReportScheduler.propTypes = {
    name: PropTypes.string.isRequired,
    value: customPropTypes.standardReportSchedule.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
};

export default StandardReportScheduler;
