import { connect } from 'react-redux';
import { makeSelectProfilesByIds } from 'src/selectors/profiles';
import PropTypes from 'prop-types';
import React from 'react';
import EntityOperationInfoModal from 'src/components/modals/EntityOperationInfoModal';
import * as customPropTypes from 'src/customPropTypes';

const ForceDeleteProfileOrGroupModalContainer = (props) => {
    const {
        forceOperationalProfileInfos,
        nonDeletableProfiles,
        hideModal,
        enforceFocus
    } = props;

    const nonDeletableProfileNames = nonDeletableProfiles.map((profile) => profile.defaultName);
    return (
        <EntityOperationInfoModal
          forceOperationalEntityInfos={forceOperationalProfileInfos}
          nonOperationalEntityNames={nonDeletableProfileNames}
          operationalType="forceProfileDelete"
          hideModal={hideModal}
          enforceFocus={enforceFocus}
        />
    );
};

ForceDeleteProfileOrGroupModalContainer.propTypes = {
    forceOperationalProfileInfos: customPropTypes.forceOperationalEntityInfos.isRequired,
    nonDeletableProfiles: customPropTypes.profiles.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
    const { notRemovedProfileIds } = ownProps;
    const selectProfilesByIds = makeSelectProfilesByIds();
    return {
        nonDeletableProfiles: selectProfilesByIds(state, notRemovedProfileIds),
    };
};

export default connect(mapStateToProps)(ForceDeleteProfileOrGroupModalContainer);
