import { makeSelectProfileById } from 'src/selectors/profiles';
import ProfileInfoListItem from 'src/components/views/profiles/ProfileInfoListItem';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const makeMapStateToProps = () => {
    const selectProfileById = makeSelectProfileById();
    return (state, ownProps) => {
        const { profileId } = ownProps;
        const profile = selectProfileById(state, profileId);
        return {
            profile,
        };
    };
};

const ProfileInfoListItemContainer = connect(makeMapStateToProps)(ProfileInfoListItem);

ProfileInfoListItemContainer.propTypes = {
    profileId: PropTypes.string.isRequired
};

export default ProfileInfoListItemContainer;
