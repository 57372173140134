import React from 'react';
import styles from 'src/stylesheets/responsiveEditableItemList.scss';
import ResponsiveEditableItem from 'src/components/ResponsiveEditableItem';

const ResponsiveEditableItems = ({ items }) => (
    items.map(({ id, name }) => (
        <div className={styles.item} key={id}>
            <ResponsiveEditableItem name={name} />
        </div>
    ))
);

export default ResponsiveEditableItems;
