import QuickLink from 'src/components/dateSelector/QuickLink';
import TimezoneSelection from 'src/components/dateSelector/TimezoneSelection';
import { getDateConfigByString } from 'src/components/dateSelector/utils';
import React, { useState } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import DateTime from 'react-datetime';
import InputBoxAndQuickLinks from 'src/components/dateSelector/InputBoxAndQuickLinks';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/combinedDateSelectorView.scss';
import _omit from 'lodash/omit';
import { getDefaultDate } from 'src/utils/filterSelectors';

const resolveToAbsolute = (selectedDate) => {
    if (selectedDate.dynamicDate) {
        const { from, to } = getDateConfigByString(selectedDate.dynamicDate);
        if (from && to) {
            return {
                from: from.format('YYYY-MM-DD'),
                to: to.format('YYYY-MM-DD'),
                interval: selectedDate.interval,
                timezone: selectedDate.timezone
            };
        }
        return Object.assign({},
            getDefaultDate(), {
                interval: selectedDate.interval,
                timezone: selectedDate.timezone
            });
    }
    return selectedDate;
};

const CombinedDateSelectorView = ({ selectedDate: initialSelectedDate, onCancelClick, onApplyClick }) => {
    const [selectedDate, setSelectedDate] = useState(initialSelectedDate);

    const [customInputActive, setCustomInputActive] = useState(false);

    const handleQuickLinks = (newDate) => {
        if (newDate) {
            if (newDate.dynamicDate) {
                // at this point I want to know if valid or not.
                setSelectedDate(Object.assign({}, selectedDate, {
                    from: undefined,
                    to: undefined,
                    dynamicDate: newDate.dynamicDate
                }));
            } else {
                const from = newDate.from.format('YYYY-MM-DD');
                const to = newDate.to.format('YYYY-MM-DD');
                setSelectedDate(Object.assign({}, selectedDate, {
                    from,
                    to,
                    dynamicDate: undefined
                }));
            }
        }
    };
    const handleFromSelectionClick = (selection, to) => {
        const from = selection.format('YYYY-MM-DD');
        setCustomInputActive(false);
        setSelectedDate(Object.assign({}, selectedDate, {
            from,
            to,
            dynamicDate: undefined
        }));
    };

    const handleToSelectionClick = (selection, from) => {
        const to = selection.format('YYYY-MM-DD');
        setCustomInputActive(false);
        setSelectedDate(Object.assign({}, selectedDate, {
            to,
            from,
            dynamicDate: undefined
        }));
    };

    const handleFixedClick = (from, to) => {
        setCustomInputActive(false);
        setSelectedDate(Object.assign({}, selectedDate, {
            to,
            from,
            dynamicDate: undefined
        }));
    };

    const handleTimezoneSelection = (timezone) => {
        setSelectedDate(Object.assign({}, selectedDate, {
            timezone
        }));
    };

    const handleOnApplyClick = () => {
        if (selectedDate.dynamicDate) {
            onApplyClick(_omit(selectedDate, ['to', 'from']));
        } else {
            onApplyClick(_omit(selectedDate, ['dynamicDate']));
        }
    };

    const resolvedDate = resolveToAbsolute(selectedDate);

    const isAbsolute = !selectedDate.dynamicDate;
    return (
        <div className={styles.dateSelector}>
            <div className={styles.body}>
                <div className={styles.left}>
                    <div className={styles.calendarFrom}>
                        <DateTime
                          open
                          className={styles.datePicker}
                          input={false}
                          timeFormat={false}
                          value={resolvedDate.from}
                          dateFormat="YYYY-MM-DD"
                          onChange={(from) => { handleFromSelectionClick(from, resolvedDate.to); }}
                        />
                    </div>
                    <div className={styles.calendarTo}>
                        <DateTime
                          open
                          className={styles.datePicker}
                          input={false}
                          timeFormat={false}
                          value={resolvedDate.to}
                          dateFormat="YYYY-MM-DD"
                          onChange={(to) => {
                              handleToSelectionClick(to, resolvedDate.from);
                          }}
                        />
                    </div>
                </div>
                <div className={styles.right}>
                    <InputBoxAndQuickLinks
                      selectedDate={selectedDate}
                      onDateChange={handleQuickLinks}
                      onValidEnterKeyDown={handleOnApplyClick}
                      additionalLinks={[<QuickLink label="Fixed" onClick={() => { handleFixedClick(resolvedDate.from, resolvedDate.to); }} active={!customInputActive && isAbsolute} />]}
                      customInputActive={customInputActive}
                      setCustomInputActive={setCustomInputActive}
                    />
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerLeft}>
                    <TimezoneSelection timezone={selectedDate.timezone} onChange={handleTimezoneSelection} />
                </div>
                <div className={styles.footerRight}>
                    <ButtonGroup>
                        <Button label="Cancel" onClick={onCancelClick} />
                        <Button label="Apply" action onClick={handleOnApplyClick} />
                    </ButtonGroup>
                </div>
            </div>
        </div>
    );
};

CombinedDateSelectorView.propTypes = {
    selectedDate: customPropTypes.selectedDate.isRequired,
    onApplyClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired
};

export default CombinedDateSelectorView;
