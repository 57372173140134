import { isSubmitting, submit } from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import DataPushTaskDeleteForm from 'src/components/forms/DataPushTaskDeleteForm';
import { makeSelectDataPushTaskById } from 'src/selectors/dataPushTasks';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { dataPushTaskDeleteRequestFormAction } from 'src/actions/dataPushTasks';

const formName = 'dataPushTaskDeleteForm';

const DataPushTaskDeleteModal = (props) => {
    const {
        isDeleting, hideModal, dataPushTask, submitAction, enforceFocus
    } = props;

    return (
        <Modal dataViewName="delete-data-push-task" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Delete Data push task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DataPushTaskDeleteForm
                      onSubmit={dataPushTaskDeleteRequestFormAction}
                      initialValues={{ dataPushTaskId: dataPushTask.id }}
                      form={formName}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          disabled={isDeleting}
                          onClick={hideModal}
                          label="Cancel"
                        />
                        <Button
                          action
                          warning
                          icon="delete"
                          loading={isDeleting}
                          onClick={() => { submitAction(formName); }}
                          label="Delete"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

DataPushTaskDeleteModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    dataPushTask: customPropTypes.dataPushTask.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool
};

const makeMapStateToProps = () => {
    const selectDataPushTaskById = makeSelectDataPushTaskById();
    return (state, ownProps) => ({
        dataPushTask: selectDataPushTaskById(state, ownProps.dataPushTaskId),
        isDeleting: isSubmitting(formName)(state)
    });
};

export default connect(makeMapStateToProps, {
    submitAction: submit
})(DataPushTaskDeleteModal);
