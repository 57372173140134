import React, { PureComponent } from 'react';
import _omit from 'lodash/omit';
import classnames from 'classnames';
import greyLoadingIndicator24px from 'src/resources/loading-indicators/circle-grey-24px.svg';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/input.scss';

const getDefaultIconsPerLayout = (layout, icon) => {
    if (icon.length > 0) {
        return icon;
    }
    switch (layout) {
        case 'listFilter':
        case 'profileSearch':
        case 'discover':
            return 'search';
        case 'dateSelector':
            return 'edit';
        default:
            return icon;
    }
};

class Text extends PureComponent {
    getRef() {
        return this.inputComponent;
    }

    focus() {
        this.inputComponent.focus();
    }

    select() {
        this.inputComponent.select();
    }

    render() {
        const {
            layout, error, placeholder, className, disabled, icon, loading, active, autoComplete
        } = this.props;
        const cleanedProps = _omit(this.props, 'placeholder', 'className', 'error', 'layout', 'loading', 'active');

        const iconOrDefault = getDefaultIconsPerLayout(layout, icon);

        const input = (
            <input
              ref={(c) => { this.inputComponent = c; }}
              {...cleanedProps}
              type="text"
              placeholder={placeholder}
              autoComplete={autoComplete}
              className={classnames(
                  styles.input,
                  { [styles.error]: error },
                  { [styles.inputWithIcon]: !!iconOrDefault },
                  { [styles.loading]: loading },
                  className
              )}
            />
        );

        if (iconOrDefault || loading) {
            return (
                <div
                  className={classnames(
                      styles.wrapper,
                      { [styles.listFilter]: layout === 'listFilter' },
                      { [styles.profileSearch]: layout === 'profileSearch' },
                      { [styles.discover]: layout === 'discover' },
                      { [styles.dateSelector]: layout === 'dateSelector' },
                      { [styles.active]: active },
                      { [styles.error]: error }
                  )}
                >
                    {input}
                    { iconOrDefault && <IcomoonIcon icon={iconOrDefault} className={classnames(styles.icon, { [styles.iconDisabled]: disabled })} />}
                    { loading && <img alt="Loading..." src={greyLoadingIndicator24px} className={styles.loadingIndicator} /> }
                </div>
            );
        }

        return input;
    }
}

Text.propTypes = {
    layout: PropTypes.oneOf(['default', 'listFilter', 'profileSearch', 'discover', 'dateSelector']),
    placeholder: PropTypes.string,
    error: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    loading: PropTypes.bool,
    active: PropTypes.bool,
    autoComplete: PropTypes.oneOf(['on', 'off'])
};

Text.defaultProps = {
    layout: 'default',
    placeholder: '',
    error: false,
    className: '',
    disabled: false,
    loading: false,
    icon: '',
    active: false,
    autoComplete: 'off'
};

export default Text;
