import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { makeSelectPostById, makeSelectPostDetailGetState } from 'src/selectors/posts';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import { makeSelectProfileById } from 'src/selectors/profiles';
import * as postActions from 'src/actions/posts';
import PostDetailBody from 'src/components/modals/partials/PostDetail';
import PostDetailFooter from 'src/components/modals/partials/PostDetailFooter';
import PostDetailHeader from 'src/components/modals/partials/PostDetailHeader';
import PostPostTagListInPostDetail from 'src/components/views/postTags/postPostTagList/PostPostTagListInPostDetail';
import LoadingScreen from 'src/components/LoadingScreen';
import Error from 'src/components/errors/Error';
import Button from 'src/components/buttons/Button';
import { serverDataClearAllCache } from 'src/actions/serverData';
import { selectIsSharedDashboardContext } from 'src/selectors/sharedDashboard';

const PostDetailInAnalysisModal = (props) => {
    const {
        post, postId, profile, enforceFocus, postLoadingState, highlightKeywords, metricRequest
    } = props;
    const [currentHasPostsTagsBeenUpdated, setHasPostsTagsBeenUpdated] = useState(false);

    useEffect(() => {
        const { getPostRequestAction, sharedDashboardPostGetRequestAction, isSharedDashboardContext } = props;
        if (isSharedDashboardContext) {
            sharedDashboardPostGetRequestAction(postId, metricRequest);
        } else {
            getPostRequestAction(postId);
        }
    }, [postId]);

    const handleOnModalClose = () => {
        const { serverDataClearAllCacheAction, invalidatePostSearchHashAction, hideModal } = props;
        if (currentHasPostsTagsBeenUpdated) {
            serverDataClearAllCacheAction();
            invalidatePostSearchHashAction();
        }
        hideModal();
    };

    const handleOnEditIconClick = () => {
        setHasPostsTagsBeenUpdated(true);
    };

    const { isPending, error } = postLoadingState;

    let dialogContent = <></>;

    if (error) {
        dialogContent = (
            <>
                <Modal.Header onHide={handleOnModalClose}>
                    Post detail page
                </Modal.Header>
                <Modal.Body height={420}>
                    <Error error={error} />
                </Modal.Body>
                <Modal.Footer>
                    <Button label="Close" onClick={handleOnModalClose} />
                </Modal.Footer>
            </>
        );
    }
    if (!post && isPending) {
        dialogContent = (
            <>
                <Modal.Header>
                    loading ...
                </Modal.Header>
                <Modal.Body height={420}>
                    <LoadingScreen />
                </Modal.Body>
                <Modal.Footer>
                    <Button label="Close" onClick={handleOnModalClose} />
                </Modal.Footer>
            </>
        );
    }

    if (post) {
        const renderPostPostTagList = () => <PostPostTagListInPostDetail post={post} onUpdateButtonClick={handleOnEditIconClick} />;
        const { defaultName } = profile;
        dialogContent = (
            <>
                <PostDetailHeader post={post} profileName={defaultName} handleOnModalClose={handleOnModalClose} />
                <PostDetailBody post={post} profile={profile} renderPostPostTagList={renderPostPostTagList} highlightKeywords={highlightKeywords} />
                <PostDetailFooter post={post} handleOnModalClose={handleOnModalClose} />
            </>
        );
    }

    return (
        <Modal dataViewName="post-detail-modal" onHide={handleOnModalClose} enforceFocus={enforceFocus} onBackdropClick={handleOnModalClose} withBackDropSupport>
            <Modal.Dialog width={800}>
                {dialogContent}
            </Modal.Dialog>
        </Modal>
    );
};

PostDetailInAnalysisModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    postId: PropTypes.string.isRequired,
    post: PropTypes.oneOfType([customPropTypes.post, PropTypes.bool]).isRequired,
    profile: PropTypes.oneOfType([customPropTypes.profile, PropTypes.bool]).isRequired,
    getPostRequestAction: PropTypes.func.isRequired,
    postLoadingState: customPropTypes.asyncDataLoadingState.isRequired,
    highlightKeywords: PropTypes.arrayOf(PropTypes.string).isRequired,
    serverDataClearAllCacheAction: PropTypes.func.isRequired,
    invalidatePostSearchHashAction: PropTypes.func.isRequired,
    sharedDashboardPostGetRequestAction: PropTypes.func.isRequired,
    metricRequest: customPropTypes.metricRequest.isRequired,
    isSharedDashboardContext: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectPostById = makeSelectPostById();
    const selectProfileById = makeSelectProfileById();
    const selectPostDetailGetState = makeSelectPostDetailGetState();
    return (state, ownProps) => {
        const post = selectPostById(state, ownProps.postId);
        const profile = post ? selectProfileById(state, post.profileId) : false;
        const postLoadingState = selectPostDetailGetState(state, ownProps.postId);
        return {
            post,
            profile,
            postLoadingState,
            isSharedDashboardContext: selectIsSharedDashboardContext(state)
        };
    };
};

export default connect(makeMapStateToProps, {
    getPostRequestAction: postActions.getPostRequest,
    sharedDashboardPostGetRequestAction: postActions.sharedDashboardPostGetRequest,
    invalidatePostSearchHashAction: postActions.invalidatePostSearchHash,
    serverDataClearAllCacheAction: serverDataClearAllCache
})(PostDetailInAnalysisModal);
