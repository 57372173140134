import { SelectListWithSearchBar } from 'src/components/forms/fields';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import memoize from 'memoize-one';
import * as customPropTypes from 'src/customPropTypes';

const getOptions = memoize((authAdAccounts) => authAdAccounts.map(({ id, name, appendix }) => ({ id, label: `${name} (${appendix})` })));

const AuthAdAccountListForm = (props) => {
    const { authAdAccounts, handleSubmit } = props;
    const options = getOptions(authAdAccounts);
    return (
        <form onSubmit={handleSubmit}>
            <Field
              name="chosenAuthAdAccountIds"
              component={SelectListWithSearchBar}
              options={options}
              optionName="ad accounts"
            />
        </form>
    );
};

AuthAdAccountListForm.propTypes = {
    authAdAccounts: customPropTypes.authAdAccounts.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

export default withReduxForm(AuthAdAccountListForm);
