import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/list.scss';

const List = (props) => {
    const { children, border, disabled } = props;
    const itemCount = React.Children.count(children);
    return (
        <ul className={classnames(styles.list, { [styles.border]: border })}>
            {
                React.Children.map(children, (listItem, i) => {
                    if (listItem) {
                        const isFirstItem = (i === 0);
                        const isLastItem = (i + 1) === itemCount;
                        let item = listItem;
                        if (isFirstItem || isLastItem) {
                            item = React.cloneElement(listItem, { first: isFirstItem, last: isLastItem });
                        }
                        return <li className={classnames(styles.listItemWrapper, { [styles.last]: isLastItem, [styles.disabled]: disabled })}>{item}</li>;
                    }
                    return null;
                })
            }
        </ul>
    );
};

List.propTypes = {
    children: customPropTypes.children.isRequired,
    border: PropTypes.bool,
    disabled: PropTypes.bool
};

List.defaultProps = {
    border: true,
    disabled: false
};

export default List;
