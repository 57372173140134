import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import PostTagAddForm from 'src/components/forms/PostTagAddForm';
import { postTagAddRequestFormAction } from 'src/actions/postTags';
import { connect } from 'react-redux';
import { isSubmitting, submit } from 'redux-form';

const formName = 'postTagAddForm';

const PostTagAddModal = (props) => {
    const {
        hideModal, enforceFocus, submitAction, isAdding
    } = props;
    return (
        <Modal dataViewName="add-post-tag" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Add a new tag
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PostTagAddForm
                      form={formName}
                      onSubmit={postTagAddRequestFormAction}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button label="Cancel" onClick={hideModal} />
                        <Button
                          action
                          loading={isAdding}
                          onClick={() => { submitAction(formName); }}
                          label="Add"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

PostTagAddModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    submitAction: PropTypes.func.isRequired,
    isAdding: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => (state) => ({
    isAdding: isSubmitting(formName)(state)
});

export default connect(makeMapStateToProps, {
    submitAction: submit,
})(PostTagAddModal);
