import { connect } from 'react-redux';
import { makeSelectGroupsFilteredByQuery } from 'src/selectors/groups';
import { makeSelectProfilesFilteredByQuery } from 'src/selectors/profiles';
import ProfileSelectorView from 'src/components/profileSelector/ProfileSelectorView';
import { makeSelectAdAccountsFilteredByQuery } from 'src/selectors/adAccounts';

const makeMapStateToProps = () => {
    const selectFilteredProfiles = makeSelectProfilesFilteredByQuery();
    const selectFilteredGroups = makeSelectGroupsFilteredByQuery();
    const selectFilteredAdAccounts = makeSelectAdAccountsFilteredByQuery();
    return (state, ownProps) => {
        const { filterQuery } = ownProps;
        return {
            profiles: selectFilteredProfiles(state, filterQuery),
            groups: selectFilteredGroups(state, filterQuery),
            adAccounts: selectFilteredAdAccounts(state, filterQuery)
        };
    };
};

export default connect(makeMapStateToProps)(ProfileSelectorView);
