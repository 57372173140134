import ThreadsAuthTransactionRequirementsConnect
    from 'src/components/authTransactions/ThreadsAuthTransactionRequirementsConnect';
import * as customPropTypes from 'src/customPropTypes';
import emptyImage from 'src/resources/illustrations/discover-no-results.png';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import GraphAuthTransactionRequirementsConnect from 'src/components/authTransactions/GraphAuthTransactionRequirementsConnect';
import LinkedInAuthTransactionRequirementsConnect from 'src/components/authTransactions/LinkedInAuthTransactionRequirementsConnect';
import PropTypes from 'prop-types';
import React from 'react';
import SnapchatAuthTransactionRequirementConnect from 'src/components/authTransactions/SnapchatAuthTransactionRequirementConnect';
import TwitterAuthTransactionRequirementConnect from 'src/components/authTransactions/TwitterAuthTransactionRequirementConnect';
import withAuthTransactionRequirementsSortedByNetworkType from 'src/components/authTransactions/withAuthTransactionRequirementsSortedByNetworkType';
import YoutubeAuthTransactionRequirementsConnect from 'src/components/authTransactions/YoutubeAuthTransactionRequirementsConnect';
import TiktokAuthTransactionRequirementsConnect from 'src/components/authTransactions/TiktokAuthTransactionRequirementsConnect';
import _get from 'lodash/get';

const NetworksAuthTransactionRequirementsConnect = (props) => {
    const {
        isPublic, authTransaction, profileAuthTransactionReqByNetworkType, adAccountAuthTransactionReqByNetworkType, showOnlyBasicInsightsUseCases
    } = props;
    const facebook = _get(profileAuthTransactionReqByNetworkType, 'facebook', {});
    const instagram = _get(profileAuthTransactionReqByNetworkType, 'instagram', {});
    const twitter = _get(profileAuthTransactionReqByNetworkType, 'twitter', {});
    const youtube = _get(profileAuthTransactionReqByNetworkType, 'youtube', {});
    const linkedIn = _get(profileAuthTransactionReqByNetworkType, 'linkedIn', {});
    const snapchatShow = _get(profileAuthTransactionReqByNetworkType, 'snapchatShow', {});
    const tiktok = _get(profileAuthTransactionReqByNetworkType, 'tiktok', {});
    const meta = _get(adAccountAuthTransactionReqByNetworkType, 'meta', {});
    const threads = _get(profileAuthTransactionReqByNetworkType, 'threads', {});

    return (
        <div>
            {
                (Object.keys(facebook).length > 0 || Object.keys(instagram).length > 0 || Object.keys(meta).length > 0)
                && (
                    <GraphAuthTransactionRequirementsConnect
                      authTransaction={authTransaction}
                      facebookAuthTransactionRequirementsByProfileId={facebook}
                      instagramAuthTransactionRequirementsByProfileId={instagram}
                      metaAuthTransactionRequirementsByAdAccountId={meta}
                      isPublic={isPublic}
                      showOnlyBasicInsightsUseCases={showOnlyBasicInsightsUseCases}
                    />
                )
            }
            {
                Object.keys(twitter).length > 0
                && (
                    <TwitterAuthTransactionRequirementConnect
                      authTransaction={authTransaction}
                      authTransactionRequirementsByProfileId={twitter}
                      isPublic={isPublic}
                      showOnlyBasicInsightsUseCases={showOnlyBasicInsightsUseCases}
                    />
                )
            }
            {
                Object.keys(youtube).length > 0
                && (
                    <YoutubeAuthTransactionRequirementsConnect
                      authTransaction={authTransaction}
                      authTransactionRequirementsByProfileId={youtube}
                      isPublic={isPublic}
                      showOnlyBasicInsightsUseCases={showOnlyBasicInsightsUseCases}
                    />
                )
            }
            {
                Object.keys(linkedIn).length > 0
                && (
                    <LinkedInAuthTransactionRequirementsConnect
                      authTransaction={authTransaction}
                      authTransactionRequirementsByProfileId={linkedIn}
                      isPublic={isPublic}
                      showOnlyBasicInsightsUseCases={showOnlyBasicInsightsUseCases}
                    />
                )
            }
            {
                Object.keys(snapchatShow).length > 0
                && (
                    <SnapchatAuthTransactionRequirementConnect
                      authTransaction={authTransaction}
                      authTransactionRequirementsByProfileId={snapchatShow}
                      isPublic={isPublic}
                      showOnlyBasicInsightsUseCases={showOnlyBasicInsightsUseCases}
                    />
                )
            }
            {
                Object.keys(tiktok).length > 0
                && (
                    <TiktokAuthTransactionRequirementsConnect
                      authTransaction={authTransaction}
                      authTransactionRequirementsByProfileId={tiktok}
                      isPublic={isPublic}
                      showOnlyBasicInsightsUseCases={showOnlyBasicInsightsUseCases}
                    />
                )
            }
            {
                Object.keys(threads).length > 0
                && (
                    <ThreadsAuthTransactionRequirementsConnect
                      authTransaction={authTransaction}
                      authTransactionRequirementsByProfileId={threads}
                      isPublic={isPublic}
                      showOnlyBasicInsightsUseCases={showOnlyBasicInsightsUseCases}
                    />
                )
            }
            {
                (
                    Object.keys(facebook).length === 0
                    && Object.keys(instagram).length === 0
                    && Object.keys(meta).length === 0
                    && Object.keys(twitter).length === 0
                    && Object.keys(youtube).length === 0
                    && Object.keys(linkedIn).length === 0
                    && Object.keys(snapchatShow).length === 0
                    && Object.keys(tiktok).length === 0
                    && Object.keys(threads).length === 0
                )
                && (
                <EmptyInfoScreen
                  image={emptyImage}
                  message="The accounts in this authentication were removed from your quintly account"
                />
                )
            }
        </div>
    );
};

NetworksAuthTransactionRequirementsConnect.propTypes = {
    authTransaction: customPropTypes.authTransaction.isRequired,
    profileAuthTransactionReqByNetworkType: customPropTypes.authTransactionRequirementsByPlatformType.isRequired,
    adAccountAuthTransactionReqByNetworkType: customPropTypes.adAccountAuthTransactionRequirementsByPlatformType.isRequired,
    isPublic: PropTypes.bool,
    showOnlyBasicInsightsUseCases: PropTypes.bool
};

NetworksAuthTransactionRequirementsConnect.defaultProps = {
    isPublic: false,
    showOnlyBasicInsightsUseCases: false
};

export default withAuthTransactionRequirementsSortedByNetworkType(NetworksAuthTransactionRequirementsConnect);
