import React from 'react';
import IcomoonIcon from 'src/components/IcomoonIcon';
import withPopover from 'src/components/withPopover';
import PrivateStatisticsUseCaseInfoPopover from 'src/components/popovers/PrivateStatisticsUseCaseInfoPopover';
import styles from 'src/stylesheets/privateStatisticUseCaseInfo.scss';

const IcomoonIconWithPopover = withPopover(IcomoonIcon);

const PrivateStatisticUseCasesInfo = () => (
    <div className={styles.wrapper}>
        <div>What are private stats use cases?</div>
        <IcomoonIconWithPopover
          className={styles.icon}
          icon="info"
          overlay={<PrivateStatisticsUseCaseInfoPopover />}
          usePortalToRender={false}
        />
    </div>
);

export default PrivateStatisticUseCasesInfo;
