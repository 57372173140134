import * as customPropTypes from 'src/customPropTypes';
import { Checkbox } from 'src/components/forms/fields';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/authTransactions/authTransactionRequirementsTable.scss';
import { MAX_NUMBER_OF_COLUMNS } from 'src/utils/accountInsights';
import { getFieldName } from 'src/utils/authTransactionUtils';
import { renderAccountInfo } from 'src/components/authTransactions/authTransactionSteps/AccountListItem';
import ListTableRow from 'src/components/listTable/ListTableRow';
import ListTableCell from 'src/components/listTable/ListTableCell';
import EmptyCells from 'src/components/listTable/EmptyCells';

const AuthTransactionRequirementsFormRow = (props) => {
    const {
        account,
        platformUseCases,
        disabled,
        accountType
    } = props;

    const emptyColumnLength = MAX_NUMBER_OF_COLUMNS - platformUseCases.length;

    return (
        <ListTableRow>
            <ListTableCell padded={false}>{renderAccountInfo(account, accountType)}</ListTableCell>
            {
                platformUseCases.map((platformUseCase) => (
                    <ListTableCell key={platformUseCase.id}>
                        <div className={styles.useCase}>
                            <div className={styles.useCaseWithIndicator}>
                                <div>
                                    <Field
                                      name={getFieldName(accountType, account.id, platformUseCase.id)}
                                      component={Checkbox}
                                      disabled={disabled}
                                    />
                                </div>
                            </div>
                        </div>
                    </ListTableCell>
                ))
            }
            {
                emptyColumnLength > 0 && <EmptyCells emptyColumnLength={emptyColumnLength} />
            }
        </ListTableRow>
    );
};

AuthTransactionRequirementsFormRow.propTypes = {
    account: customPropTypes.account.isRequired,
    accountType: customPropTypes.accountTypes.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired,
    disabled: PropTypes.bool.isRequired
};

export default AuthTransactionRequirementsFormRow;
