import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import GraphAuthUserConnectionWarningPopover from 'src/components/popovers/GraphAuthUserConnectionWarningPopover';
import IcomoonIcon from 'src/components/IcomoonIcon';
import { isAuthTransactionOpen } from 'src/utils/authTransactionUtils';
import NetworkButton from 'src/components/buttons/NetworkButton';
import ProfileAuthTransactionConnectErrorPopover from 'src/components/popovers/ProfileAuthTransactionConnectErrorPopover';
import PropTypes from 'prop-types';
import React from 'react';
import { selectAuthenticateByIdentifier } from 'src/selectors/authUsers';
import styles from 'src/stylesheets/networkAuthUserConnectButton.scss';
import withPopover from 'src/components/withPopover';
import withTooltip from 'src/components/withToolTip';

const IcomoonIconIconWithTooltipWithPopover = withTooltip(withPopover(IcomoonIcon));
const IcomoonIconIconWithPopover = withPopover(IcomoonIcon);

const GraphConnectButtonAndState = (props) => {
    const {
        authTransaction,
        connectAction,
        authenticateState
    } = props;

    const error = authenticateState ? authenticateState.error : '';
    const isPending = authenticateState ? authenticateState.isPending : false;
    const success = authenticateState ? authenticateState.success : false;
    const data = authenticateState ? authenticateState.data : { };
    const additionalProfiles = _get(data, 'additionalProfiles', []);
    const missingProfiles = _get(data, 'missingProfiles', []);
    const showWarning = additionalProfiles.length > 0 || missingProfiles.length > 0;

    return (
        <div className={styles.wrapper}>
            {
                (error && !isPending)
                && (
                <div className={styles.error}>
                    <IcomoonIconIconWithPopover
                      overlay={<ProfileAuthTransactionConnectErrorPopover errorMessage={error} />}
                      icon="alert"
                      placement="top"
                      trigger="hover"
                      className={styles.errorIcon}
                    />
                </div>
                )
            }
            {
                (success && !error && !isPending && showWarning)
                && (
                <div className={styles.error}>
                    <IcomoonIconIconWithTooltipWithPopover
                      overlay={(
                          <GraphAuthUserConnectionWarningPopover
                            missingProfiles={missingProfiles}
                            additionalProfiles={additionalProfiles}
                          />
                      )}
                      icon="alert"
                      placement="top"
                      className={styles.errorIcon}
                      tooltip="Click to see warnings."
                    />
                </div>
                )
            }
            <div className={styles.button}>
                <NetworkButton
                  platformType="facebook"
                  label="Continue with Facebook"
                  disabled={!(isAuthTransactionOpen(authTransaction.isOpen, authTransaction.validUntil))}
                  loading={isPending}
                  onClick={() => {
                      connectAction(
                          `fb_${authTransaction.id}`,
                          authTransaction.id
                      );
                  }}
                />
            </div>
        </div>
    );
};

GraphConnectButtonAndState.propTypes = {
    authTransaction: customPropTypes.authTransaction.isRequired,
    connectAction: PropTypes.func.isRequired,
    authenticateState: PropTypes.oneOfType([PropTypes.bool, customPropTypes.asyncReducerState]).isRequired,
};

const makeMapStateToProps = (state, ownProps) => {
    const { authTransaction } = ownProps;
    return {
        authenticateState: selectAuthenticateByIdentifier(state, `fb_${authTransaction.id}`),
    };
};

export default connect(makeMapStateToProps)(GraphConnectButtonAndState);
