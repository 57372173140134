import * as customPropTypes from 'src/customPropTypes';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import ProfileSearchAddButton from 'src/components/views/profiles/search/SearchedProfileAddButton';
import React from 'react';
import UpdateTiktokUsernameButton from 'src/components/views/profiles/search/UpdateTiktokUsernameButton';
import UpdateInstagramUsernameButton from 'src/components/views/profiles/search/UpdateInstagramUsernameButton';
import { checkIfProfileUsernameChanged } from 'src/utils/profileSearch';
import Button from 'src/components/buttons/Button';
import memoizeOne from 'memoize-one';

const memoizeCheckIfProfileUsernameChanged = memoizeOne(checkIfProfileUsernameChanged);

const ProfileActionButtonWrapper = (props) => {
    const {
        searchedProfile, profile
    } = props;
    const hasProfileUsernameChanged = profile !== null ? memoizeCheckIfProfileUsernameChanged(profile, searchedProfile) : false;
    return (
        <ButtonGroup>
            {
                (profile !== null && hasProfileUsernameChanged === false) && (
                    <Button small icon="check-mark" label="Added" action disabled />
                )
            }
            {
                (profile !== null && hasProfileUsernameChanged && searchedProfile.platformType === 'instagram') && (
                    <UpdateInstagramUsernameButton searchedProfile={searchedProfile} profile={profile} />
                )
            }
            {
                (profile !== null && hasProfileUsernameChanged && searchedProfile.platformType === 'tiktok') && (
                    <UpdateTiktokUsernameButton searchedProfile={searchedProfile} profile={profile} />
                )
            }
            {
                profile === null && <ProfileSearchAddButton searchedProfile={searchedProfile} />
            }
        </ButtonGroup>
    );
};

ProfileActionButtonWrapper.propTypes = {
    searchedProfile: customPropTypes.searchedProfile.isRequired,
    profile: customPropTypes.profile
};

ProfileActionButtonWrapper.defaultProps = {
    profile: null
};

export default ProfileActionButtonWrapper;
