import React, { PureComponent } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/textarea.scss';

class Textarea extends PureComponent {
    getRef() {
        return this.inputComponent;
    }

    focus() {
        this.inputComponent.focus();
    }

    select() {
        this.inputComponent.select();
    }

    render() {
        const {
            error, placeholder, className, disabled, height, verticalResize, maxHeight
        } = this.props;
        const placeholderText = !disabled ? placeholder : '';
        const cleanedProps = _omit(this.props, 'placeholder', 'className', 'error', 'active', 'verticalResize', 'maxHeight', 'height');

        const style = {};
        if (height) {
            style.height = height;
        }

        if (maxHeight) {
            style.maxHeight = maxHeight;
        }

        if (!_isEmpty(style)) {
            cleanedProps.style = style;
        }

        return (
            <textarea
              {...cleanedProps}
              ref={(c) => { this.inputComponent = c; }}
              placeholder={placeholderText}
              className={classnames(styles.textarea, { [styles.error]: error }, { [styles.verticalResize]: verticalResize }, className)}
            />
        );
    }
}

Textarea.propTypes = {
    placeholder: PropTypes.string,
    error: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    height: PropTypes.number,
    verticalResize: PropTypes.bool,
    maxHeight: PropTypes.number
};

Textarea.defaultProps = {
    placeholder: '',
    error: false,
    className: '',
    disabled: false,
    verticalResize: false
};

export default Textarea;
