import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/body.scss';

const Body = (props) => {
    const { className, children } = props;

    return (
        <div className={classnames(styles.body, className, 'body')}>
            {children}
        </div>
    );
};

Body.propTypes = {
    children: customPropTypes.children,
    className: PropTypes.string
};

Body.defaultProps = {
    className: ''
};

export default Body;
