import { isSubmitting, submit } from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';
import { authUserRemoveRequestFormAction } from 'src/actions/authUsers';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import ConfirmDeleteForm from 'src/components/forms/ConfirmDeleteForm';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';

const formName = 'authUserDeleteForm';

const AuthUserDeleteModal = (props) => {
    const {
        submitAction,
        hideModal,
        isDeleting,
        authUser,
        enforceFocus
    } = props;

    const { id, environment } = authUser;

    return (
        <Modal dataViewName="auth-user-delete-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Delete authenticated user
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        Are you sure you want to remove the user <b>{authUser.name}</b>? All permissions will be fully revoked.
                        <br />
                        <br />
                        Please note that once removed, any profiles previously authenticated by this user will be highlighted as disconnected (unless redundancy connections are in place). If you change your mind in the future, you can reconnect the user at any time.
                        <br />
                        <br />
                    </span>
                    <ConfirmDeleteForm
                      form={formName}
                      onSubmit={authUserRemoveRequestFormAction}
                      initialValues={{ id, environment }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          label="Cancel"
                          disabled={isDeleting}
                          onClick={hideModal}
                        />
                        <Button
                          icon="delete"
                          label="Delete"
                          loading={isDeleting}
                          warning
                          onClick={() => { submitAction(formName); }}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

AuthUserDeleteModal.propTypes = {
    authUser: customPropTypes.authUser.isRequired,
    submitAction: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    enforceFocus: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
    isDeleting: isSubmitting(formName)(state),
});

export default connect(mapStateToProps, {
    submitAction: submit
})(AuthUserDeleteModal);
