import CombinedDateSelectorComponent from 'src/components/dateSelector/CombinedDateSelector';
import React, { forwardRef } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';

const CombinedDateSelector = forwardRef((props, ref) => {
    const {
        onBlur, onFocus, name, value, onChange, disabled, defaultDateForDatePicker
    } = props;

    return (
        <div>
            <CombinedDateSelectorComponent
              onHide={onBlur}
              onDateChange={onChange}
              onShow={onFocus}
              selectedDate={value}
              disabled={disabled}
              defaultDateForDatePicker={defaultDateForDatePicker}
              ref={ref}
            />
            <input type="hidden" name={name} value={JSON.stringify(value)} />
        </div>
    );
});

CombinedDateSelector.propTypes = {
    value: PropTypes.oneOfType([customPropTypes.relativeDate, customPropTypes.dateRange]).isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultDateForDatePicker: customPropTypes.selectedDate.isRequired,
};

CombinedDateSelector.defaultProps = {
    error: false,
    disabled: false
};

export default CombinedDateSelector;
