import React from 'react';
import styles from 'src/stylesheets/views/profiles/search/newlyAddedProfiles.scss';
import PropTypes from 'prop-types';
import List from 'src/components/List';
import IcomoonIcon from 'src/components/IcomoonIcon';

const NewlyAddedAccountView = (props) => {
    const { children, description, info } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.description}>
                <IcomoonIcon icon="check-mark" className={styles.checkMark} />
                <b>{description}</b>
                <br />
            </div>
            {
                info && (
                    <div className={styles.audienceData}>
                        {info}
                    </div>
                )
            }
            <List>
                {children}
            </List>
        </div>
    );
};

NewlyAddedAccountView.propTypes = {
    children: PropTypes.node.isRequired,
    info: PropTypes.node,
    description: PropTypes.string.isRequired
};

export default NewlyAddedAccountView;
