import EmptyProfileItem from 'src/components/views/profiles/EmptyProfileItem';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/profiles/selectedAccounts.scss';
import SelectedEntityCountWithClearOption from 'src/components/views/SelectedEntityCountWithClearOption';

const CheckedAccounts = ({
    checkedValues, listName, label, renderListItem
}) => {
    const isSelected = checkedValues.length > 0;
    return (
        <div className={styles.wrapper}>
            <div className={styles.selectedProfileCount}>
                <SelectedEntityCountWithClearOption label={label} count={checkedValues.length} listName={listName} />
            </div>
            <div className={styles.profileList}>
                {
                    checkedValues.map((checkedValue) => (
                        <div
                          className={styles.profileItem}
                          key={`account-${checkedValue}`}
                        >
                            {
                                renderListItem(checkedValue)
                            }
                        </div>
                    ))
                }
                {
                    !isSelected
                    && <EmptyProfileItem />
                }
            </div>
        </div>
    );
};

CheckedAccounts.propTypes = {
    listName: PropTypes.string.isRequired,
    checkedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
    renderListItem: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
};

export default CheckedAccounts;
