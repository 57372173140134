import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import FormError from 'src/components/forms/feedback/FormError';
import styles from 'src/stylesheets/forms/dataPushTaskForm.scss';
import DataPushTaskFields from 'src/components/forms/dataPushTasks/DataPushTaskFields';
import * as customPropTypes from 'src/customPropTypes';
import { makeSelectExistingDataSourceNamesFromDataPushTasks } from 'src/selectors/dataPushTasks';
import { connect } from 'react-redux';

const DataPushTaskEditForm = (props) => {
    const {
        handleSubmit, error, form, dataPushTaskType, existingDataSourceNames
    } = props;

    return (
        <form onSubmit={handleSubmit} className={styles.wrapper}>
            {
                error && (
                    <div className={styles.error}>
                        <FormError message={error.message} />
                    </div>
                )
            }
            <DataPushTaskFields
              form={form}
              dataPushTaskType={dataPushTaskType}
              existingDataSourceNames={existingDataSourceNames}
            />
        </form>
    );
};

DataPushTaskEditForm.propTypes = {
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.object,
    dataPushTaskType: customPropTypes.dataPushTaskType.isRequired,
    existingDataSourceNames: PropTypes.arrayOf(PropTypes.string).isRequired
};

const makeMapStateToProps = () => {
    const selectExistingDataSourceNamesFromDataPushTasks = makeSelectExistingDataSourceNamesFromDataPushTasks();
    return (state) => ({
        existingDataSourceNames: selectExistingDataSourceNamesFromDataPushTasks(state)
    });
};

export default withReduxForm(connect(makeMapStateToProps)(DataPushTaskEditForm));
