import * as customPropTypes from 'src/customPropTypes';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import { isAuthTransactionExpired } from 'src/utils/authTransactionUtils';
import moment from 'moment-timezone';
import React from 'react';
import styles from 'src/stylesheets/authTransactions/authTransactionTimeState.scss';
import PropTypes from 'prop-types';
import _upperFirst from 'lodash/upperFirst';
import _lowerFirst from 'lodash/lowerFirst';

const AuthTransactionTimeInfo = (props) => {
    const { authTransaction, showCreatorInfo, prefix } = props;
    const {
        createTime,
        closeTime,
        validUntil,
        isOpen,
        createdByUserName
    } = authTransaction;

    const isExpired = isAuthTransactionExpired(validUntil);

    const validUntilDate = moment.tz(validUntil, 'UTC').fromNow();
    const closeTimeDate = moment.tz(closeTime, 'UTC').fromNow();
    const createTimeDate = moment.tz(createTime, 'UTC').fromNow();

    let state = '';
    if (isOpen) {
        if (isExpired) {
            state = `expired ${validUntilDate}`;
        } else {
            state = `expires ${validUntilDate}`;
        }
    } else {
        state = `closed ${closeTimeDate}`;
    }

    let detail = showCreatorInfo
        ? `Created ${createTimeDate} by ${createdByUserName} (${state})`
        : _upperFirst(state);

    if (prefix) {
        detail = `${prefix} ${_lowerFirst(detail)}`;
    }
    return (
        <DivWithTooltip tooltip={detail} className={styles.details}>
            {detail}
        </DivWithTooltip>
    );
};

AuthTransactionTimeInfo.propTypes = {
    authTransaction: customPropTypes.authTransaction.isRequired,
    showCreatorInfo: PropTypes.bool,
    prefix: PropTypes.string
};

AuthTransactionTimeInfo.defaultProps = {
    showCreatorInfo: true,
    prefix: ''
};

export default AuthTransactionTimeInfo;
