import CheckboxGroup from 'src/components/forms/CheckboxGroup';
import {
    AdditionalFilterSelector,
    Checkbox, CombinedDateSelector, ProfileSelector, Select, Textarea, RadioList, AdvancedReportScheduler, StandardReportScheduler
} from 'src/components/forms/fields';
import {
    validateMultipleEmails, makeLengthValidator, validateDateSelection, validateProfileSelection
} from 'src/components/forms/validators';
import { Field, formValueSelector } from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';
import { standardScheduleType, advancedScheduleType } from 'src/utils/dashboardReport';

const reportMessageLengthValidator = makeLengthValidator(0, 500);

const isReportEnabledAndHasRecipients = (_, allValues) => (
    (allValues.isActive) && (allValues.recipients.length < 1)
        ? 'Please enter at least one email address. Multiple email addresses should be separated by comma.'
        : undefined
);

const validateNumberOfRecipient = (emails) => {
    if (emails && emails.length > 0) {
        const emailList = emails.split(',');
        return (emailList.length > 100) ? 'A report may only be sent to a maximum of 100 recipients.' : undefined;
    }
    return undefined;
};

const useCronInitialValue = [
    { id: standardScheduleType, label: 'Standard' },
    { id: advancedScheduleType, label: 'Advanced rule' }
];

const CreateReportForm = (props) => {
    const {
        handleSubmit, error, isAbsoluteDateSelected, reportScheduleType
    } = props;
    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <Field
              autoFocus
              name="isActive"
              text="Turn on schedule for this report."
              component={Checkbox}
            />
            <Field
              name="recipients"
              label="Email recipients"
              info="Enter the email addresses you would like to send this report to, multiple emails should be separated with commas."
              component={Textarea}
              validate={[isReportEnabledAndHasRecipients, validateMultipleEmails, validateNumberOfRecipient]}
            />
            <Field
              name="emailMessage"
              label="Email message"
              placeholder="Hello, your scheduled report is ready!"
              info="This message will only appear in the email."
              component={Textarea}
              validate={reportMessageLengthValidator}
            />
            <Field
              name="profileSelection"
              label="Profiles"
              formName="dashboardReportsForm"
              component={ProfileSelector}
              validate={validateProfileSelection}
            />
            <Field
              name="dateSelection"
              label="Date range"
              component={CombinedDateSelector}
              validate={validateDateSelection}
              info={isAbsoluteDateSelected ? 'Using a specific date period will result in receiving the exact same report repeatedly.' : ''}
            />
            <Field
              name="additionalFilterSelection"
              label="Additional filters"
              component={AdditionalFilterSelector}
            />
            <Field
              label="Report format"
              name="format"
              component={Select}
            >
                <option value="pdf">PDF</option>
                <option value="xlsx">XLSX</option>
                <option value="csv">CSV</option>
            </Field>
            <Field
              name="reportScheduleType"
              label="Scheduling"
              component={RadioList}
              options={useCronInitialValue}
              defaultChecked={standardScheduleType}
            />
            {
                reportScheduleType === advancedScheduleType && <Field name="advancedReportSchedule" component={AdvancedReportScheduler} />
            }
            {
                reportScheduleType === standardScheduleType && <Field name="standardReportSchedule" component={StandardReportScheduler} />
            }
            <Field
              name="notes"
              label="Report notes"
              info="If set and the format is PDF, the notes will be shown at the end of the PDF on a separate page."
              component={Textarea}
            />
            <CheckboxGroup label="Additional settings">
                <Field
                  name="attachShareLink"
                  text="Attach an interactive version of this report as a shareable link in the email."
                  component={Checkbox}
                />
                <Field
                  name="showMetricDesc"
                  text="Show a short description (summary) below each metric within the PDF report."
                  component={Checkbox}
                />
            </CheckboxGroup>
        </form>
    );
};

CreateReportForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    intervalType: PropTypes.string,
    error: customPropTypes.dataLoadingError,
    isAbsoluteDateSelected: PropTypes.bool.isRequired,
    reportScheduleType: PropTypes.string
};

const CreateReportFormForm = withReduxForm(CreateReportForm, {
    touchOnChange: true
});

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector(ownProps.form);
    const reportDateSelection = selector(state, 'dateSelection');
    return {
        isAbsoluteDateSelected: !_get(reportDateSelection, 'dynamicDate', false),
        reportScheduleType: selector(state, 'reportScheduleType')
    };
};

export default connect(mapStateToProps)(CreateReportFormForm);
