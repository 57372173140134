import React from 'react';
import { getProfileAddingStateIconAndLabel } from 'src/utils/profileSearch';
import styles from 'src/stylesheets/views/profiles/search/withProfileAddingState.scss';
import * as customPropTypes from 'src/customPropTypes';
import withTooltip from 'src/components/withToolTip';
import IcomoonIcon from 'src/components/IcomoonIcon';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const AccountAddingState = (props) => {
    const { addingState, children } = props;
    const stateAndLabel = getProfileAddingStateIconAndLabel(addingState);
    if (stateAndLabel === null) {
        return children;
    }
    const { icon, label } = stateAndLabel;

    return (
        <div className={styles.wrapper}>
            <div className={styles.wrappedComponent}>
                {children}
            </div>
            <div className={classnames(styles.stateIcon, { [styles[icon]]: true })}>
                <IcomoonIconWithTooltip
                  icon={icon}
                  className={styles.removeIcon}
                  tooltip={label}
                />
            </div>
        </div>
    );
};

AccountAddingState.propTypes = {
    addingState: customPropTypes.asyncDataLoadingState.isRequired,
    children: PropTypes.node.isRequired
};

export default AccountAddingState;
