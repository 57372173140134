import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/sidebar/sidebar.scss';

const Sidebar = (props) => {
    const { children, width } = props;
    return (
        <div className={styles.sidebar} style={{ width }}>
            {children}
        </div>
    );
};

Sidebar.propTypes = {
    children: PropTypes.node.isRequired,
    width: PropTypes.number
};

Sidebar.defaultProps = {
    width: 280
};

export default Sidebar;
