import EntityOwnershipNameResolver from 'src/components/onwership/EntityOwnershipNameResolver';
import React from 'react';
import ModalListAddButton from 'src/components/ModalListAddButton';
import PropTypes from 'prop-types';
import { SUPER_ROOT } from 'src/utils/dashboardFolders';

const FolderBrowserAddFolderButton = ({ onClick, toFolderId }) => {
    if (toFolderId !== SUPER_ROOT) {
        return (
            <ModalListAddButton
              label={<>Create new <EntityOwnershipNameResolver entityId={toFolderId} entityType="folder" /> folder</>}
              disabled={toFolderId === SUPER_ROOT}
              onClick={() => {
                  onClick(toFolderId, 'modal');
              }}
            />
        );
    }
    return <div style={{ height: 52 }} />;
};

FolderBrowserAddFolderButton.propTypes = {
    toFolderId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default FolderBrowserAddFolderButton;
