import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import InputBoxAndQuickLinks from 'src/components/dateSelector/InputBoxAndQuickLinks';
import Button from 'src/components/buttons/Button';
import styles from 'src/stylesheets/popovers/dynamicDateSelectorPopover.scss';
import _upperFirst from 'lodash/upperFirst';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';

const DynamicDateSelectorPopover = (props) => {
    const { selectedDate, onDateChange, hidePopover } = props;
    const [currentSelectedDate, changeCurrentSelectedDate] = useState(selectedDate);

    const handleOnDateChange = (date) => {
        if (date && date.dynamicDate) {
            changeCurrentSelectedDate(date.dynamicDate);
        }
    };

    const handleOnApplyClick = () => {
        onDateChange(currentSelectedDate);
        hidePopover();
    };

    const compatibleDateSelection = { dynamicDate: currentSelectedDate };
    const dynamicDateToShow = _upperFirst(currentSelectedDate);
    return (
        <Popover id="dynamicDateSelectorPopover" width={400}>
            <div className={styles.wrapper}>
                <InputBoxAndQuickLinks
                  selectedDate={compatibleDateSelection}
                  onDateChange={handleOnDateChange}
                />
            </div>
            <div className={styles.footer}>
                <div className={styles.selectedDate}>
                    <DivWithTooltip tooltip={dynamicDateToShow}>
                        {dynamicDateToShow}
                    </DivWithTooltip>
                </div>
                <div className={styles.button}>
                    <Button label="Apply" onClick={handleOnApplyClick} action />
                </div>
            </div>
        </Popover>
    );
};

DynamicDateSelectorPopover.propTypes = {
    selectedDate: PropTypes.string,
    onDateChange: PropTypes.func.isRequired,
    hidePopover: PropTypes.func
};

export default DynamicDateSelectorPopover;
