import CustomColorPicker from 'src/components/CustomColorPicker';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';

const ColorPickerPopover = ({ onChange, hidePopover, hexValue }) => {
    const onChangeAndHidePopover = (value) => {
        onChange(value);
        hidePopover();
    };
    return (
        <Popover id="colorPickerPopover">
            <CustomColorPicker onButtonClick={onChangeAndHidePopover} hexValue={hexValue} />
        </Popover>
    );
};

ColorPickerPopover.propTypes = {
    hexValue: PropTypes.string.isRequired,
    hidePopover: PropTypes.func,
    onChange: PropTypes.func.isRequired
};

export default ColorPickerPopover;
