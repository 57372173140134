import api from './api.png';
import areaChart from './area-chart.png';
import barChart from './bar-chart.png';
import bubbleChart from './bubble-chart.png';
import bubbleMapChart from './bubble-map-chart.png';
import columnChart from './column-chart.png';
import continuousBubbleChart from './continuous-bubble-chart.png';
import funnelChart from './funnel-chart.png';
import gaugeChart from './gauge-chart.png';
import lineChart from './line-chart.png';
import mapChart from './map-chart.png';
import pieChart from './pie-chart.png';
import pyramidChart from './pyramid-chart.png';
import radarChart from './radar-chart.png';
import scatterChart from './scatter-chart.png';
import stackedAreaChart from './stacked-area-chart.png';
import stackedAreaPercentChart from './stacked-area-percent-chart.png';
import stackedBarChart from './stacked-bar-chart.png';
import stackedBarPercentChart from './stacked-bar-percent-chart.png';
import stackedColumnChart from './stacked-column-chart.png';
import stackedColumnPercentChart from './stacked-column-percent-chart.png';
import table from './table.png';
import waterfallChart from './waterfall-chart.png';
import bigNumber from './big-number.png';
import topContent from './top-content.png';

export const visualizationImages = {
    api,
    areaChart,
    barChart,
    bubbleChart,
    bubbleMapChart,
    columnChart,
    continuousBubbleChart,
    funnelChart,
    gaugeChart,
    lineChart,
    mapChart,
    pieChart,
    pyramidChart,
    radarChart,
    scatterChart,
    stackedAreaChart,
    stackedAreaPercentChart,
    stackedBarChart,
    stackedBarPercentChart,
    stackedColumnChart,
    stackedColumnPercentChart,
    table,
    waterfallChart,
    bigNumber,
    topContent
};
