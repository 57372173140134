import ActiveSelectionHandler from 'src/components/ActiveSelectionHandler';
import React, { useRef, useState } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/profileSelector.scss';
import TextWithResetIcon from 'src/components/forms/inputs/TextWithResetIcon';
import withScrollIntoViewOfScrollParent from 'src/components/withScrollIntoViewOfScrollParent';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import { makeFilterProfilesAndGroupsAvailable } from 'src/selectors/filterSelectors';
import ProfileSelectorAdAccountLink from './ProfileSelectorAdAccountLink';
import ProfileSelectorProfileLink from './ProfileSelectorProfileLink';
import ProfileSelectorGroupLink from './ProfileSelectorGroupLink';

const ProfileSelectorProfileLinkWithActiveStateScroll = withScrollIntoViewOfScrollParent(ProfileSelectorProfileLink);
const ProfileSelectorGroupLinkWithActiveStateScroll = withScrollIntoViewOfScrollParent(ProfileSelectorGroupLink);
const ProfileSelectorAdAccountLinkWithActiveStateScroll = withScrollIntoViewOfScrollParent(ProfileSelectorAdAccountLink);

const getSelectedItemOrNull = (groups, profiles, adAccounts, index) => {
    if (index < groups.length) {
        const item = groups[index];
        return { item, id: item.id, type: 'group' };
    }
    if (index < groups.length + adAccounts.length) {
        const item = adAccounts[index - groups.length];
        return { item, id: item.id, type: 'adAccount' };
    }

    if (index < groups.length + profiles.length + adAccounts.length) {
        const item = profiles[index - groups.length - adAccounts.length];
        return { item, id: item.id, type: 'profile' };
    }

    return null;
};

const ProfileSelector = (props) => {
    const {
        groups, profiles, selectedProfilesOrGroups, filterQuery, handleProfileOrGroupClick, adAccounts
    } = props;

    const onFilterQueryChange = (event) => {
        const { handleFilterQueryChange } = props;
        handleFilterQueryChange(event.target.value);
    };

    const refInnerList = useRef();
    const refInputField = useRef();

    const onResetFilterClick = () => {
        const { handleFilterQueryChange } = props;
        handleFilterQueryChange('');
    };

    // { groupIds: [], profileIds: [], adAccountIds: [] }
    const [currentlySelectedProfilesAndGroups, setCurrentlySelectedProfilesAndGroups] = useState(selectedProfilesOrGroups);
    const onExpandClick = (profileIdsFromGroup, adAccountIdsFromGroup) => {
        const newState = {
            groupIds: [], profileIds: profileIdsFromGroup, adAccountIds: adAccountIdsFromGroup
        };
        setCurrentlySelectedProfilesAndGroups(newState);
    };

    const onOnlyClick = (type, id) => {
        const newState = {
            profileIds: [],
            groupIds: [],
            adAccountIds: []
        };
        if (type === 'profile') {
            newState.profileIds = [id];
        } else if (type === 'group') {
            newState.groupIds = [id];
        } else if (type === 'adAccount') {
            newState.adAccountIds = [id];
        }
        handleProfileOrGroupClick(newState);
    };

    const toggleSelectedItem = (type, id) => {
        const { groupIds, profileIds, adAccountIds } = currentlySelectedProfilesAndGroups;
        const newState = {
            groupIds, profileIds, adAccountIds
        };
        if (type === 'group') {
            if (groupIds.includes(id)) {
                newState.groupIds = groupIds.filter((item) => item !== id);
            } else {
                newState.groupIds = [...groupIds];
                newState.groupIds.push(id);
            }
        }
        if (type === 'profile') {
            if (profileIds.includes(id)) {
                newState.profileIds = profileIds.filter((item) => item !== id);
            } else {
                newState.profileIds = [...profileIds];
                newState.profileIds.push(id);
            }
        }
        if (type === 'adAccount') {
            if (adAccountIds.includes(id)) {
                newState.adAccountIds = adAccountIds.filter((item) => item !== id);
            } else {
                newState.adAccountIds = [...adAccountIds];
                newState.adAccountIds.push(id);
            }
        }

        setCurrentlySelectedProfilesAndGroups(newState);
    };

    const isItemSelected = (type, id) => {
        if (type === 'profile') {
            return currentlySelectedProfilesAndGroups.profileIds.includes(id);
        }
        if (type === 'group') {
            return currentlySelectedProfilesAndGroups.groupIds.includes(id);
        }

        if (type === 'adAccount') {
            return currentlySelectedProfilesAndGroups.adAccountIds.includes(id);
        }
        return false;
    };

    const areItemsSelected = (currentlySelectedProfilesAndGroups.profileIds.length + currentlySelectedProfilesAndGroups.groupIds.length + currentlySelectedProfilesAndGroups.adAccountIds.length) > 0;

    const onIndexChange = (old, newIndex) => {
        if (newIndex > 0) {
            refInnerList.current.focus();
        }
        if (newIndex === 0) {
            refInputField.current.focus();
        }
    };

    const showProfiles = profiles.length > 0;
    const showGroups = groups.length > 0;
    const showAdAccounts = adAccounts.length > 0;

    const inputError = !!filterQuery && (!showGroups && !showProfiles && !showAdAccounts);
    return (
        <ActiveSelectionHandler
          initialActiveIndex={0}
          onCommit={() => {
              handleProfileOrGroupClick(currentlySelectedProfilesAndGroups);
          }}
          numberOptions={groups.length + profiles.length + adAccounts.length + 1}
          onIndexChange={onIndexChange}
        >
            { (activeIndex, onKeyDownHandler, setIndex, resetIndex) => (
                <div className={styles.profileSelector}>
                    <div className={styles.header}>
                        <TextWithResetIcon
                          error={inputError}
                          autoFocus
                          placeholder="Search for groups, profiles or ad accounts"
                          onChange={(event) => {
                              onFilterQueryChange(event);
                              if (event.target.value === '') {
                                  resetIndex();
                              } else {
                                  setIndex(0);
                              }
                          }}
                          ref={refInputField}
                          value={filterQuery}
                          onResetClick={() => { onResetFilterClick(); resetIndex(); }}
                          onKeyDown={onKeyDownHandler}
                        />
                    </div>

                    <div
                      ref={refInnerList}
                      tabIndex="-1"
                      onKeyDown={(e) => {
                          if (e.keyCode === 32) {
                              const item = getSelectedItemOrNull(groups, profiles, adAccounts, activeIndex - 1);
                              toggleSelectedItem(item.type, item.id);
                              e.preventDefault();
                              return;
                          } if (e.keyCode === 39) {
                              const { type, id } = getSelectedItemOrNull(groups, profiles, adAccounts, activeIndex - 1);
                              onOnlyClick(type, id);
                              e.preventDefault();
                              return;
                          }
                          onKeyDownHandler(e);
                          e.preventDefault();
                      }}
                      role="listbox"
                      className={styles.inner}
                    >
                        {
                            (!showGroups && !showProfiles && !showAdAccounts)
                                ? (
                                    <ul className={styles.ulOnly}>
                                        <li className={styles.listText}>No {filterQuery && 'matching '}groups or accounts</li>
                                    </ul>
                                )
                                : ''
                        }

                        {
                            showGroups || showProfiles || showAdAccounts
                                ? <p className={classnames(styles.groupsHeadline, { [styles.inactiveHeadline]: !showGroups })}>Groups</p>
                                : ''
                        }
                        {
                            showGroups ? (
                                <ul className={styles.ul}>{
                                    groups.map((group, index) => {
                                        const type = 'group';
                                        const active = index + 1 === activeIndex;
                                        return (
                                            <li key={`group-${group.id}`}>
                                                <ProfileSelectorGroupLinkWithActiveStateScroll
                                                  group={group}
                                                  onExpandClick={onExpandClick}
                                                  onOnlyClick={onOnlyClick}
                                                  active={active}
                                                  highlight={filterQuery}
                                                  label={group.name}
                                                  scrollOnMount={isItemSelected(type, group.id)}
                                                  selected={isItemSelected(type, group.id)}
                                                  onClick={() => {
                                                      toggleSelectedItem(type, group.id);
                                                  }}
                                                />
                                            </li>
                                        );
                                    })
                                }
                                </ul>
                            ) : ''
                        }

                        {
                            showGroups || showProfiles || adAccounts
                                ? <p className={classnames(styles.profilesHeadline, { [styles.inactiveHeadline]: !adAccounts })}>Ad accounts</p>
                                : ''
                        }

                        {
                            showAdAccounts ? (
                                <ul className={styles.ul}> {
                                    adAccounts.map((adAccount, index) => {
                                        const type = 'adAccount';
                                        const active = groups.length + index + 1 === activeIndex;
                                        return (
                                            <li key={`adAccount-${adAccount.id}`}>
                                                <ProfileSelectorAdAccountLinkWithActiveStateScroll
                                                  selected={isItemSelected(type, adAccount.id)}
                                                  scrollOnMount={isItemSelected(type, adAccount.id)}
                                                  active={active}
                                                  adAccount={adAccount}
                                                  onOnlyClick={onOnlyClick}
                                                  onClick={() => {
                                                      toggleSelectedItem(type, adAccount.id);
                                                  }}
                                                  label={adAccount.defaultName}
                                                  appendix={adAccount.appendix}
                                                />
                                            </li>
                                        );
                                    })
                                }
                                </ul>
                            ) : ''
                        }

                        {
                            showGroups || showProfiles || adAccounts
                                ? <p className={classnames(styles.profilesHeadline, { [styles.inactiveHeadline]: !showProfiles })}>Profiles</p>
                                : ''
                        }

                        {
                            showProfiles ? (
                                <ul className={styles.ul}>{
                                    profiles.map((profile, index) => {
                                        const type = 'profile';
                                        const active = groups.length + adAccounts.length + index + 1 === activeIndex;
                                        return (
                                            <li key={`profile-${profile.id}`}>
                                                <ProfileSelectorProfileLinkWithActiveStateScroll
                                                  profile={profile}
                                                  active={active}
                                                  label={profile.defaultName}
                                                  highlight={filterQuery}
                                                  appendix={profile.appendix}
                                                  selected={isItemSelected(type, profile.id)}
                                                  scrollOnMount={isItemSelected(type, profile.id)}
                                                  onClick={() => {
                                                      toggleSelectedItem(type, profile.id);
                                                  }}
                                                  onOnlyClick={onOnlyClick}
                                                />
                                            </li>
                                        );
                                    })
                                }
                                </ul>
                            ) : ''
                        }
                    </div>

                    <div className={styles.footer}>
                        <div className={styles.selection}>
                            Groups: {currentlySelectedProfilesAndGroups.groupIds.length}
                            <br /> Ad accounts: {currentlySelectedProfilesAndGroups.adAccountIds.length}
                            <br /> Profiles: {currentlySelectedProfilesAndGroups.profileIds.length}
                        </div>
                        <Button
                          label="Clear"
                          onClick={() => {
                              setCurrentlySelectedProfilesAndGroups({ profileIds: [], groupIds: [], adAccountIds: [] });
                          }}
                          disabled={!areItemsSelected}
                          tabindex="-1"
                        />
                        <Button
                          label="Apply"
                          action
                          disabled={!areItemsSelected}
                          onClick={() => {
                              handleProfileOrGroupClick(currentlySelectedProfilesAndGroups);
                          }}
                          tabindex="-1"
                        />
                    </div>
                </div>
            )}
        </ActiveSelectionHandler>
    );
};

ProfileSelector.propTypes = {
    profiles: customPropTypes.profiles.isRequired,
    groups: customPropTypes.groups.isRequired,
    adAccounts: customPropTypes.adAccounts.isRequired,
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    filterQuery: PropTypes.string.isRequired,
    handleProfileOrGroupClick: PropTypes.func.isRequired,
    handleFilterQueryChange: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const filterProfilesAndGroupsAvailable = makeFilterProfilesAndGroupsAvailable();
    return (state, ownProps) => ({
        selectedProfilesOrGroups: filterProfilesAndGroupsAvailable(state, ownProps.selectedProfilesOrGroups)
    });
};

export default connect(makeMapStateToProps)(ProfileSelector);
