import { checkListValues, initializeList, unregisterList } from 'src/actions/lists';
import { makeSelectProfiles } from 'src/selectors/profiles';
import { makeSelectAccountIdsFromAccountsWithListFiltersApplied } from 'src/selectors/accounts';
import React, { useEffect } from 'react';
import * as overlayActions from 'src/actions/overlays';
import AuthTransactionProfileListHeader from 'src/components/views/authentications/AuthTransactionProfileListHeader';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import { getFormValues } from 'redux-form';
import { makeSelectCheckedValues } from 'src/selectors/lists';
import { makeSelectGroupById } from 'src/selectors/groups';
import Modal from 'src/components/modals/layout/Modal';
import noProfileMatch from 'src/resources/illustrations/profiles-filters.png';
import AccountList from 'src/components/AccountList';
import ProfileListItemContainer from 'src/components/authTransactions/authTransactionSteps/ProfileListItemContainer';
import AdAccountListItemContainer from 'src/components/authTransactions/authTransactionSteps/AdAccountListItemContainer';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/modals/createAuthTransactionSelectProfilesModal.scss';
import Sidebar from 'src/components/sidebar/Sidebar';
import * as customPropTypes from 'src/customPropTypes';
import AuthTransactionsCheckedAccounts from 'src/components/AuthTransactionsCheckedAccounts';
import { getProfileAndAdAccountListNames, getAccountIdsFromListNames } from 'src/utils/accountInsights';
import { makeSelectAdAccounts } from 'src/selectors/adAccounts';

const accountListName = 'authenticateAccounts';
const formName = 'authTransactionAccountListForm';

const initialValues = {
    groupFilter: '0',
    networkFilter: 'all',
    freeTextFilter: '',
    sortBy: 'alphabetically'
};

const CreateAuthTransactionSelectProfilesModal = (props) => {
    const {
        filteredProfileIds,
        checkedValues,
        freeTextFilter,
        profiles,
        adAccounts,
        filteredAdAccountIds
    } = props;

    const profileAndAdAccountListNames = getProfileAndAdAccountListNames(filteredProfileIds, filteredAdAccountIds);

    useEffect(() => {
        const {
            initializeListAction,
            unregisterListAction,
            initialProfileIds,
            initialAdAccountIds,
            checkListValuesAction
        } = props;
        initializeListAction(accountListName, profileAndAdAccountListNames);
        checkListValuesAction(accountListName, getProfileAndAdAccountListNames(initialProfileIds, initialAdAccountIds));
        return () => {
            unregisterListAction(accountListName);
        };
    }, []);

    const handleHideModal = () => {
        const { hideModal } = props;
        hideModal();
    };

    const handleContinueButton = () => {
        const {
            modalsShowCreateAuthTransactionRequirementsAction,
            hideModal,
            selectBrokenProfileUseCases
        } = props;
        hideModal();

        const accountIds = getAccountIdsFromListNames(checkedValues);
        modalsShowCreateAuthTransactionRequirementsAction(accountIds.profileIds, accountIds.adAccountIds, selectBrokenProfileUseCases);
    };

    return (
        <Modal dataViewName="create-auth-transaction-detail-step1-modal" onHide={handleHideModal}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={handleHideModal}>
                    <Modal.Title>Select accounts to authenticate</Modal.Title>
                </Modal.Header>
                <div className={styles.wrapper}>
                    <div className={styles.body}>
                        <div className={styles.header}>
                            <AuthTransactionProfileListHeader
                              listName={accountListName}
                              formName={formName}
                              initialValues={initialValues}
                              listItemIdsToHandle={profileAndAdAccountListNames}
                            />
                        </div>
                        {
                            profiles.length === 0 && adAccounts.length === 0
                            && <EmptyInfoScreen image={noProfileMatch} title="You have no accounts yet" />
                        }
                        {
                            (profiles.length > 0 || adAccounts.length > 0)
                            && (
                                <div className={styles.profileList}>
                                    {
                                        filteredAdAccountIds.length > 0 && (
                                            <div className={styles.adAccount}>
                                                <AccountList
                                                  accountIds={filteredAdAccountIds}
                                                  hideLastItemBorder={false}
                                                  renderAccountItem={(adAccountId) => (
                                                      <AdAccountListItemContainer
                                                        adAccountId={adAccountId}
                                                        listName={accountListName}
                                                        highlight={freeTextFilter}
                                                      />
                                                  )}
                                                />
                                            </div>
                                        )
                                    }
                                    {
                                        filteredProfileIds.length > 0 && (
                                            <AccountList
                                              accountIds={filteredProfileIds}
                                              renderAccountItem={(profileId) => (
                                                  <ProfileListItemContainer
                                                    profileId={profileId}
                                                    listName={accountListName}
                                                    highlight={freeTextFilter}
                                                  />
                                              )}
                                            />
                                        )
                                    }
                                    {
                                        filteredProfileIds.length === 0 && filteredAdAccountIds.length === 0
                                        && <EmptyInfoScreen image={noProfileMatch} title="No accounts match the applied filters" />
                                    }
                                </div>
                            )
                        }
                    </div>
                    <Sidebar>
                        <div className={styles.checkedProfiles}>
                            <AuthTransactionsCheckedAccounts accountListName={accountListName} />
                        </div>
                    </Sidebar>
                </div>
                <Modal.Footer>
                    <ButtonGroup>
                        <div className={styles.stepDefinition}>
                            Step 1/ 2 - select accounts
                        </div>
                        <Button
                          action
                          label="Continue"
                          disabled={checkedValues.length === 0}
                          onClick={handleContinueButton}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

const makeMapStateToProps = () => {
    const formValueSelector = getFormValues(formName);
    const selectAccountIdsFromAccountsWithListFiltersApplied = makeSelectAccountIdsFromAccountsWithListFiltersApplied();
    const selectCheckedValues = makeSelectCheckedValues();
    const selectGroupById = makeSelectGroupById();
    const selectProfiles = makeSelectProfiles();
    const selectAdAccounts = makeSelectAdAccounts();
    return (state) => {
        const {
            freeTextFilter, networkFilter, groupFilter, sortBy
        } = formValueSelector(state) || initialValues;
        const checkedValues = selectCheckedValues(state, accountListName);
        const profiles = selectProfiles(state);
        const group = selectGroupById(state, groupFilter);
        const filteredProfileIds = selectAccountIdsFromAccountsWithListFiltersApplied(state, profiles, freeTextFilter, networkFilter, group, sortBy);
        const adAccounts = selectAdAccounts(state);
        const filteredAdAccountIds = selectAccountIdsFromAccountsWithListFiltersApplied(state, adAccounts, freeTextFilter, networkFilter, group, sortBy);
        return {
            profiles,
            adAccounts,
            checkedValues,
            freeTextFilter,
            filteredProfileIds,
            filteredAdAccountIds
        };
    };
};

CreateAuthTransactionSelectProfilesModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    checkedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
    initialProfileIds: PropTypes.arrayOf(PropTypes.string),
    initialAdAccountIds: PropTypes.arrayOf(PropTypes.string),
    modalsShowCreateAuthTransactionRequirementsAction: PropTypes.func.isRequired,
    freeTextFilter: PropTypes.string.isRequired,
    selectBrokenProfileUseCases: PropTypes.bool,
    filteredProfileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    initializeListAction: PropTypes.func.isRequired,
    checkListValuesAction: PropTypes.func.isRequired,
    unregisterListAction: PropTypes.func.isRequired,
    profiles: customPropTypes.profiles.isRequired,
    adAccounts: customPropTypes.adAccounts.isRequired,
    filteredAdAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

CreateAuthTransactionSelectProfilesModal.defaultProps = {
    initialProfileIds: [],
    initialAdAccountIds: [],
    selectBrokenProfileUseCases: false
};

export default connect(makeMapStateToProps, {
    modalsShowCreateAuthTransactionRequirementsAction: overlayActions.modalsShowCreateAuthTransactionRequirements,
    initializeListAction: initializeList,
    unregisterListAction: unregisterList,
    checkListValuesAction: checkListValues
})(CreateAuthTransactionSelectProfilesModal);
