import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import CloneDashboardMetricForm from 'src/components/forms/CloneDashboardMetricForm';
import { dashboardMetricCloneRequestFormAction } from 'src/actions/dashboardMetrics';
import { connect } from 'react-redux';
import { isSubmitting, submit } from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';
import { makeSelectDashboardMetricById } from 'src/selectors/dashboardMetrics';
import _get from 'lodash/get';

const formName = 'dashboardMetricCloneForm';

const CloneDashboardMetricModal = (props) => {
    const {
        hideModal, enforceFocus, submitAction, isCloning, dashboardMetric
    } = props;
    const defaultMetricName = dashboardMetric.metric.name;

    const initialValues = {
        alias: _get(dashboardMetric, 'settings.alias', ''),
        sourceDashboardId: dashboardMetric.dashboardId,
        destinationDashboardId: dashboardMetric.dashboardId,
        dashboardMetricId: dashboardMetric.id
    };

    return (
        <Modal dataViewName="clone-dashboard-metric" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Duplicate metric
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CloneDashboardMetricForm
                      form={formName}
                      onSubmit={dashboardMetricCloneRequestFormAction}
                      initialValues={initialValues}
                      defaultMetricName={defaultMetricName}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button label="Cancel" onClick={hideModal} />
                        <Button
                          action
                          loading={isCloning}
                          onClick={() => { submitAction(formName); }}
                          label="Duplicate"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

CloneDashboardMetricModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    submitAction: PropTypes.func.isRequired,
    isCloning: PropTypes.bool.isRequired,
    dashboardMetric: customPropTypes.dashboardMetric.isRequired
};

const makeMapStateToProps = () => {
    const selectDashboardMetricById = makeSelectDashboardMetricById();
    return (state, ownProps) => {
        const dashboardMetric = selectDashboardMetricById(state, ownProps.dashboardMetricId);
        return {
            dashboardMetric,
            isCloning: isSubmitting(formName)(state)
        };
    };
};

export default connect(makeMapStateToProps, {
    submitAction: submit
})(CloneDashboardMetricModal);
