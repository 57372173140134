import List from 'src/components/List';
import PropTypes from 'prop-types';
import React from 'react';
import SearchedAdAccountResultListItem from 'src/components/views/adAccounts/search/SearchedAdAccountResultListItem';

const AdAccountSearchResultList = (props) => {
    const { searchedAdAccountIds, isPending } = props;
    return (
        <List disabled={isPending}>
            {
                searchedAdAccountIds.map((searchedAdAccountId) => (
                    <SearchedAdAccountResultListItem
                      key={`searched-ad-account-${searchedAdAccountId}`}
                      searchedAccountId={searchedAdAccountId}
                    />
                ))
            }
        </List>
    );
};

AdAccountSearchResultList.propTypes = {
    searchedAdAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    isPending: PropTypes.bool
};

AdAccountSearchResultList.defaultProps = {
    isPending: false
};

export default AdAccountSearchResultList;
