import React from 'react';
import * as customPropType from 'src/customPropTypes';
import ProfileSelectorComponent from 'src/components/profileSelector/ProfileSelector';
import PropTypes from 'prop-types';

function ProfileSelector({
    value, name, onBlur, onFocus, disabled, onChange
}) {
    return (
        <div>
            <ProfileSelectorComponent
              onShow={onFocus}
              onHide={onBlur}
              selectedProfilesOrGroups={value}
              handleProfileOrGroupClick={onChange}
              disabled={disabled}
            />
            <input name={name} value={JSON.stringify(value)} type="hidden" />
        </div>
    );
}

ProfileSelector.propTypes = {
    value: customPropType.profileFilter.isRequired,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.bool
};

ProfileSelector.defaultProps = {
    disabled: false,
    error: false
};

export default ProfileSelector;
