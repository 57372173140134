import LinkTextWithActions from 'src/components/LinkTextWithActions';
import React from 'react';
import styles from 'src/stylesheets/userInvitationBox.scss';
import PropTypes from 'prop-types';

const UserInvitationLinkBox = ({ url }) => (
    <div>
        <div className={styles.headerWrapper}>
            <span className={styles.linkBoxHeader}>Invite Link</span>
        </div>
        <LinkTextWithActions showVisitLink={false} link={url} />
    </div>
);

UserInvitationLinkBox.propTypes = {
    url: PropTypes.string.isRequired
};

export default UserInvitationLinkBox;
