import React from 'react';
import PropTypes from 'prop-types';
import NetworkButton from 'src/components/buttons/NetworkButton';
import styles from 'src/stylesheets/views/profiles/socialNetworkButtons.scss';
import GoogleConnectButton from 'src/components/buttons/GoogleConnectButton';

const ProfileSocialNetworkButtons = (props) => {
    const { action } = props;
    return (
        <div className={styles.wrapper}>
            <NetworkButton platformType="facebook" onClick={() => { action('facebook'); }} label="Continue with Facebook" />
            <GoogleConnectButton onClick={() => { action('youtube'); }} label="Continue with YouTube" />
            <NetworkButton platformType="snapchatShow" onClick={() => { action('snapchatShow'); }} label="Continue with Snapchat" />
            <NetworkButton platformType="twitter" onClick={() => { action('twitter'); }} label="Continue with X" />
            <NetworkButton platformType="linkedIn" onClick={() => { action('linkedIn'); }} label="Continue with LinkedIn" />
            <NetworkButton platformType="tiktok" onClick={() => { action('tiktok'); }} label="Continue with TikTok" />
            <NetworkButton platformType="threads" onClick={() => { action('threads'); }} label="Continue with Threads" />
        </div>
    );
};

ProfileSocialNetworkButtons.propTypes = {
    action: PropTypes.func.isRequired
};

export default ProfileSocialNetworkButtons;
