import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { makeSelectProfilesByIds } from 'src/selectors/profiles';
import ProfileInfoListItem from 'src/components/views/profiles/ProfileInfoListItem';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/modals/profileListModal.scss';

const ProfileListModal = (props) => {
    const { profiles, hideModal, enforceFocus } = props;
    return (
        <Modal dataViewName="profile-list-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog className={styles.dialog} width={400}>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        {`Profiles (${profiles.length})`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        profiles.map((profile) => (
                            <div key={profile.id} className={styles.listItem}>
                                <ProfileInfoListItem profile={profile} />
                            </div>
                        ))
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button label="Close" onClick={hideModal} />
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

const makeMapStateToProps = () => {
    const selectProfilesByIds = makeSelectProfilesByIds();
    return (state, ownProps) => {
        const { profileIds } = ownProps;
        return {
            profiles: selectProfilesByIds(state, profileIds)
        };
    };
};

ProfileListModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    profileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    profiles: customPropTypes.profiles.isRequired
};

export default connect(makeMapStateToProps)(ProfileListModal);
