import React from 'react';
import _omit from 'lodash/omit';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/tileCheckBox.scss';

const TileCheckBox = (props) => {
    const { disabled, text, checked } = props;
    const cleanedProps = _omit(props, ['text']);
    return (
        <label
          className={classnames(
              styles.wrapper,
              {
                  [styles.wrapperDisabled]: disabled,
                  [styles.labelChecked]: checked
              }
          )}
        >
            <input
              {...cleanedProps}
              type="checkbox"
              className={styles.input}
            />
            {text && <span className={styles.text}>{text}</span>}
            <span className={styles.checkbox} />
        </label>
    );
};

TileCheckBox.propTypes = {
    disabled: PropTypes.bool,
    text: PropTypes.string,
    checked: PropTypes.bool.isRequired
};

TileCheckBox.defaultProps = {
    disabled: false,
    text: ''
};

export default TileCheckBox;
