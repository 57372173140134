import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { makeSelectPostById, getPostsSearchInput } from 'src/selectors/posts';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import { makeSelectProfileById } from 'src/selectors/profiles';
import * as postActions from 'src/actions/posts';
import PostDetailBody from 'src/components/modals/partials/PostDetail';
import PostDetailFooter from 'src/components/modals/partials/PostDetailFooter';
import PostDetailHeader from 'src/components/modals/partials/PostDetailHeader';
import PostTaggingPostDetailScroller from 'src/components/postDetailModalScroller/PostTaggingPostDetailScroller';
import PostPostTagListInPostDetail from 'src/components/views/postTags/postPostTagList/PostPostTagListInPostDetail';
import _get from 'lodash/get';
import { makeSelectPostTextByContext } from 'src/selectors/filterSelectors';
import { POST_TAGGING } from 'src/utils/filterSelectors';

const PostDetailInPostTaggingModal = (props) => {
    const {
        post, postId, hasPostsTagsBeenUpdated, profile, enforceFocus, postTextFilter
    } = props;
    const [currentHasPostsTagsBeenUpdated, setHasPostsTagsBeenUpdated] = useState(hasPostsTagsBeenUpdated);

    useEffect(() => {
        const { getPostRequestAction } = props;
        getPostRequestAction(postId);
    }, [postId]);

    const handleOnModalClose = () => {
        const { searchPostsRequestAction, hideModal, postsSearchInput } = props;
        if (currentHasPostsTagsBeenUpdated) {
            searchPostsRequestAction(postsSearchInput);
        }
        hideModal();
    };

    const onUpdateButtonClick = () => {
        setHasPostsTagsBeenUpdated(true);
    };

    const renderPostPostTagList = () => <PostPostTagListInPostDetail post={post} onUpdateButtonClick={onUpdateButtonClick} />;

    const renderScroller = () => (
        <PostTaggingPostDetailScroller postId={post.id} hasPostsTagsBeenUpdated={currentHasPostsTagsBeenUpdated} />
    );

    const { defaultName } = profile;
    const highlightKeywords = _get(postTextFilter, 'values', []);

    return (
        <Modal dataViewName="post-detail-modal" onHide={handleOnModalClose} enforceFocus={enforceFocus} onBackdropClick={handleOnModalClose} withBackDropSupport>
            <Modal.Dialog width={800}>
                <PostDetailHeader post={post} profileName={defaultName} renderScroller={renderScroller} handleOnModalClose={handleOnModalClose} />
                <PostDetailBody post={post} profile={profile} renderPostPostTagList={renderPostPostTagList} highlightKeywords={highlightKeywords} />
                <PostDetailFooter post={post} handleOnModalClose={handleOnModalClose} />
            </Modal.Dialog>
        </Modal>
    );
};

PostDetailInPostTaggingModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    postId: PropTypes.string.isRequired,
    post: PropTypes.oneOfType([customPropTypes.post, PropTypes.bool]).isRequired,
    profile: PropTypes.oneOfType([customPropTypes.profile, PropTypes.bool]).isRequired,
    getPostRequestAction: PropTypes.func.isRequired,
    searchPostsRequestAction: PropTypes.func.isRequired,
    postsSearchInput: customPropTypes.postSearchRequestInput.isRequired,
    hasPostsTagsBeenUpdated: PropTypes.bool.isRequired,
    postTextFilter: customPropTypes.postTextFilter.isRequired
};

const makeMapStateToProps = () => {
    const selectPostById = makeSelectPostById();
    const selectProfileById = makeSelectProfileById();
    const selectPostTextByContext = makeSelectPostTextByContext();
    return (state, ownProps) => {
        const post = selectPostById(state, ownProps.postId);
        const profile = post ? selectProfileById(state, post.profileId) : false;
        return {
            post,
            profile,
            postsSearchInput: getPostsSearchInput(state),
            postTextFilter: selectPostTextByContext(state, POST_TAGGING)
        };
    };
};

export default connect(makeMapStateToProps, {
    getPostRequestAction: postActions.getPostRequest,
    searchPostsRequestAction: postActions.searchPostsRequest
})(PostDetailInPostTaggingModal);
