import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { makeSelectProfileSearchedById } from 'src/selectors/profileSearch';
import { makeSelectProfileByTempId } from 'src/selectors/profiles';
import SearchedAccountResultListItem from 'src/components/account/layout/SearchedAccountResultListItem';
import ProfileActionButtonWrapper from 'src/components/views/profiles/search/ProfileActionButtonWrapper';
import ProfileInfo from 'src/components/views/profiles/search/ProfileInfo';

const SearchedProfileResultListItem = (props) => {
    const {
        searchedProfile, profile, first, last
    } = props;
    return (
        <SearchedAccountResultListItem
          actionButton={<ProfileActionButtonWrapper searchedProfile={searchedProfile} profile={profile} />}
          accountInfo={<ProfileInfo profile={profile || searchedProfile} showAppendix />}
          searchedAccount={searchedProfile}
          account={profile}
          first={first}
          last={last}
        />
    );
};

SearchedProfileResultListItem.propTypes = {
    searchedProfile: customPropTypes.searchedProfile.isRequired,
    profile: customPropTypes.profile,
    first: PropTypes.bool,
    last: PropTypes.bool
};

SearchedProfileResultListItem.defaultProps = {
    first: false,
    last: false,
};

const makeMapStateToProps = () => {
    const selectProfileSearchedById = makeSelectProfileSearchedById();
    const selectProfileByTempId = makeSelectProfileByTempId();
    return (state, ownProps) => {
        const { searchedProfileId } = ownProps;
        const searchedProfile = selectProfileSearchedById(state, searchedProfileId);
        const profile = selectProfileByTempId(state, searchedProfile.id);
        return {
            searchedProfile,
            profile
        };
    };
};

export default connect(makeMapStateToProps)(SearchedProfileResultListItem);
