import React, { PureComponent } from 'react';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import { makeSelectVisualizationById } from 'src/selectors/visualizations';
import PropTypes from 'prop-types';
import VisualizationListPopover from 'src/components/popovers/VisualizationListPopover';
import withPopover from 'src/components/withPopover';

const ButtonWithPopover = withPopover(Button);

class VisualizationSelector extends PureComponent {
    render() {
        const {
            onChange, placement, name, value, selectedVisualization, onBlur, onFocus
        } = this.props;

        const visualizationListPopover = (
            <VisualizationListPopover
              onSelectionChange={onChange}
              selectedOptionId={value}
              onBlur={onBlur}
              onFocus={onFocus}
            />
        );

        return (
            <div>
                <ButtonWithPopover
                  overlay={visualizationListPopover}
                  multiAction
                  placement={placement}
                  label={selectedVisualization}
                />
                <input type="hidden" name={name} value={value} />
            </div>
        );
    }
}

VisualizationSelector.propTypes = {
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedVisualization: PropTypes.string.isRequired,
    placement: PropTypes.string,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired
};

VisualizationSelector.defaultProps = {
    placement: 'bottom-start'
};

const makeMapStateToProps = () => {
    const selectVisualizationById = makeSelectVisualizationById();
    return (state, ownProps) => {
        const selectedVisualization = selectVisualizationById(state, ownProps.value);
        return {
            selectedVisualization: selectedVisualization ? selectedVisualization.title : 'Visualization types'
        };
    };
};

export default connect(makeMapStateToProps)(VisualizationSelector);
