import * as customPropTypes from 'src/customPropTypes';
import _omit from 'lodash/omit';
import { connect } from 'react-redux';
import { makeSelectCompatibleVisualizationsById } from 'src/selectors/visualizations';
import PropTypes from 'prop-types';
import React from 'react';

const withVisualizationTypes = (WrappedComponent) => {
    const WithVisualizationTypes = (props) => {
        const { compatibleVisualizations, defaultVisualizationId } = props;
        const cleanedProps = _omit(props, 'compatibleVisualizations', 'dispatch', 'children', 'defaultVisualizationId');

        return <WrappedComponent preferredOption={defaultVisualizationId} {...cleanedProps} options={compatibleVisualizations} />;
    };

    WithVisualizationTypes.propTypes = {
        compatibleVisualizations: customPropTypes.visualizationTypeList.isRequired,
        defaultVisualizationId: PropTypes.string.isRequired
    };

    const makeMapStateToProps = () => {
        const selectCompatibleVisualizationsById = makeSelectCompatibleVisualizationsById();
        return (state, ownProps) => ({
            compatibleVisualizations: selectCompatibleVisualizationsById(state, ownProps.defaultVisualizationId)
        });
    };
    return connect(makeMapStateToProps)(WithVisualizationTypes);
};

export default withVisualizationTypes;
