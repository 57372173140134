import React from 'react';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import IcomoonIcon from 'src/components/IcomoonIcon';
import withPopover from 'src/components/withPopover';
import AvailableConnectedAuthAccountInfoPopover from 'src/components/popovers/AvailableConnectedAuthAccountInfoPopover';
import AuthAccountSearchLoadingSkeleton from 'src/components/loadingIndicators/AuthAccountSearchLoadingSkeleton';
import styles from 'src/stylesheets/views/profiles/search/availableConnectedAccounts.scss';
import ExternalSocialNetworkAuthenticationLink from 'src/components/account/ExternalSocialNetworkAuthenticationLink';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';
import { renderAccountType } from 'src/utils/accountType';
import _capitalize from 'lodash/capitalize';

const IcomoonIconWithPopover = withPopover(IcomoonIcon);

const AvailableConnectedAccounts = (props) => {
    const {
        accountIds, isLoading, accountType, renderAuthAccountList
    } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.externalLink}>
                <ExternalSocialNetworkAuthenticationLink accountType={accountType} />
            </div>
            <div className={styles.header}>
                <div className={styles.title}>
                    {
                        `${_capitalize(renderAccountType(accountType, true))} connected with authentication link:`
                    }
                </div>
                <div>
                    <IcomoonIconWithPopover
                      icon="info"
                      className={styles.infoIcon}
                      placement="top"
                      overlay={<AvailableConnectedAuthAccountInfoPopover accountType={accountType} />}
                    />
                </div>
            </div>
            <div className={styles.body}>
                {
                    (isLoading && accountIds.length === 0) && <AuthAccountSearchLoadingSkeleton />
                }
                {
                    (!isLoading && accountIds.length === 0) && (
                        <div className={styles.emptyWrapper}>
                            <EmptyDiv width={32} height={32} />
                            <div className={styles.emptyItem}>
                                {`No connected ${renderAccountType(accountType, true)} yet`}
                            </div>
                        </div>
                    )
                }
                {
                    accountIds.length > 0 && renderAuthAccountList(accountIds)
                }
            </div>
        </div>
    );
};

AvailableConnectedAccounts.propTypes = {
    accountType: customPropTypes.accountTypes.isRequired,
    accountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    isLoading: PropTypes.bool.isRequired,
    renderAuthAccountList: PropTypes.func.isRequired
};

export default AvailableConnectedAccounts;
