import { resendUserInvitationRequest } from 'src/actions/users';
import Button from 'src/components/buttons/Button';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectIsResendUserInvitationPending } from 'src/selectors/users';

const UserInvitationResendButton = ({ resendUserInvitationAction, userId, isPending }) => (
    <Button
      loading={isPending}
      label="Resend invitation"
      action
      onClick={() => { resendUserInvitationAction(userId); }}
    />
);

UserInvitationResendButton.propTypes = {
    resendUserInvitationAction: PropTypes.func.isRequired,
    isPending: PropTypes.bool.isRequired,
    userId: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    isPending: selectIsResendUserInvitationPending(state, ownProps.userId)
});

export default connect(mapStateToProps, {
    resendUserInvitationAction: resendUserInvitationRequest
})(UserInvitationResendButton);
