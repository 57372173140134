import React from 'react';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';
import classnames from 'classnames';
import styles from 'src/stylesheets/profileInfo.scss';

const ProfileInfoSkeleton = () => (
    <div className={classnames(styles.profileInfo, styles.large)}>
        <div className={styles.profilePictureWrapper}>
            <EmptyDiv width={80} height={80} />
        </div>
        <div className={styles.textWrapper}>
            <EmptyDiv height={28} width={150} />
            <div className={styles.likes}>
                <EmptyDiv height={18} width={80} />
            </div>
            <div className={styles.profileLink}>
                <EmptyDiv height={18} width={100} />
            </div>
        </div>
    </div>
);

export default ProfileInfoSkeleton;
