import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Feedback from 'src/components/feedback/Feedback';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';

const UserPermissionErrorModal = (props) => {
    const { hideModal, enforceFocus, message } = props;
    return (
        <Modal dataViewName="user-permission-error" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Insufficient privileges
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Feedback content={message} type="warning" allowHTML />
                    <div style={{ marginTop: 30 }}>
                        To make changes, please ask your administrator for access rights.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button action label="Okay" onClick={hideModal} />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

UserPermissionErrorModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    enforceFocus: PropTypes.bool
};

export default UserPermissionErrorModal;
