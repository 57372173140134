import React, { useState } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as snapchatAuthUsersActions from 'src/actions/snapchatAuthUsers';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SnapchatConnectButtonAndState from 'src/components/authTransactions/SnapchatConnectButtonAndState';
import styles from 'src/stylesheets/authTransactions/authTransactionRequirementsTable.scss';
import AuthTransactionRequirementsTable from 'src/components/authTransactions/table/AuthTransactionRequirementsTable';
import _memoize from 'memoize-one';

const SnapchatAuthTransactionRequirementConnect = (props) => {
    const [hovered, changeHoverState] = useState(false);

    const handleOnMountEnter = () => {
        changeHoverState(true);
    };

    const handleOnMountLeave = () => {
        changeHoverState(false);
    };

    const {
        authTransaction,
        authTransactionRequirementsByProfileId,
        isPublic,
        showOnlyBasicInsightsUseCases
    } = props;

    const handleSnapchatConnectRequest = (identifier) => {
        const { snapchatAuthUserConnectRequestAction, snapchatAuthUserExternalConnectRequestAction } = props;
        if (isPublic) {
            snapchatAuthUserExternalConnectRequestAction(authTransaction.hash, identifier, authTransaction.id, authTransactionRequirementsByProfileId);
        } else {
            snapchatAuthUserConnectRequestAction(identifier, authTransaction.id, authTransactionRequirementsByProfileId);
        }
    };

    const getConnectButton = _memoize(() => {
        const buttonsByProfileId = [];
        const profileIds = Object.keys(authTransactionRequirementsByProfileId);
        if (profileIds.length > 0) {
            buttonsByProfileId[profileIds[0]] = (
                <div onMouseEnter={handleOnMountEnter} onMouseLeave={handleOnMountLeave}>
                    <SnapchatConnectButtonAndState
                      authTransaction={authTransaction}
                      connectAction={handleSnapchatConnectRequest}
                    />
                </div>
            );
        }
    });

    return (
        <div className={classnames({ [styles.highlightRows]: hovered })}>
            <AuthTransactionRequirementsTable
              platformType="snapchatShow"
              authTransactionRequirementsByProfileId={authTransactionRequirementsByProfileId}
              showOnlyBasicInsightsUseCases={showOnlyBasicInsightsUseCases}
              connectButtonsByAccountId={getConnectButton()}
            />
        </div>
    );
};

SnapchatAuthTransactionRequirementConnect.propTypes = {
    snapchatAuthUserConnectRequestAction: PropTypes.func.isRequired,
    snapchatAuthUserExternalConnectRequestAction: PropTypes.func.isRequired,
    authTransaction: customPropTypes.authTransaction.isRequired,
    authTransactionRequirementsByProfileId: PropTypes.objectOf(customPropTypes.profileAuthTransactionRequirements).isRequired,
    isPublic: PropTypes.bool.isRequired,
    showOnlyBasicInsightsUseCases: PropTypes.bool.isRequired
};

export default connect(null, {
    snapchatAuthUserConnectRequestAction: snapchatAuthUsersActions.snapchatAuthUserConnectRequest,
    snapchatAuthUserExternalConnectRequestAction: snapchatAuthUsersActions.snapchatAuthUserExternalConnectRequest,
})(SnapchatAuthTransactionRequirementConnect);
