import { Checkbox, Text } from 'src/components/forms/fields';
import { emailValidator, required } from 'src/components/forms/validators';
import * as customPropTypes from 'src/customPropTypes';
import { Field } from 'redux-form';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import SpaceOptionSelectorFieldArray from 'src/components/forms/fieldArray/SpaceOptionSelectorFieldArray';
import withReduxForm from 'src/components/forms/withReduxForm';

const UserInviteForm = (props) => {
    const {
        handleSubmit, error, spaces, disableEmail
    } = props;
    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <Field
              autoFocus
              name="email"
              label="Email"
              component={Text}
              validate={[required, emailValidator]}
              disabled={disableEmail}
            />
            <Field
              name="isAdmin"
              text="Admin"
              component={Checkbox}
            />
            <SpaceOptionSelectorFieldArray
              label="List of spaces"
              spaces={spaces}
            />
        </form>
    );
};

UserInviteForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    spaces: customPropTypes.spacesForManagement.isRequired,
    error: customPropTypes.dataLoadingError,
    disableEmail: PropTypes.bool
};

UserInviteForm.defaultProps = {
    disableEmail: false
};

export default withReduxForm(UserInviteForm, {
    enableReinitialize: true
});
