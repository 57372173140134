import React from 'react';
import { connect } from 'react-redux';
import { selectAdAccountAddingState } from 'src/selectors/adAccounts';
import _omit from 'lodash/omit';
import * as customPropTypes from 'src/customPropTypes';
import AccountAddingState from 'src/components/account/layout/AccountAddingState';

const withAdAccountAddingState = (WrappedComponent) => {
    const WithAdAccountAddingState = (props) => {
        const { addingState } = props;
        const cleanedProps = _omit(props, 'addingState');
        return (
            <AccountAddingState addingState={addingState}>
                <WrappedComponent {...cleanedProps} />
            </AccountAddingState>
        );
    };

    WithAdAccountAddingState.propTypes = {
        addingState: customPropTypes.asyncDataLoadingState.isRequired
    };

    const mapStateToProps = (state, ownProps) => ({
        addingState: selectAdAccountAddingState(state, ownProps.fieldName)
    });

    return connect(mapStateToProps)(WithAdAccountAddingState);
};

export default withAdAccountAddingState;
