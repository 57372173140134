import React from 'react';
import AuthenticatorContext from 'src/components/context/AuthenticatorContext';

const withAuthenticator = (Component) => (props) => (
    <AuthenticatorContext.Consumer>{
        (authenticator) => <Component {...props} authenticator={authenticator} />
    }
    </AuthenticatorContext.Consumer>
);

export default withAuthenticator;
