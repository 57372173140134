import ButtonGroup from 'src/components/buttons/ButtonGroup';
import MenuButton from 'src/components/buttons/MenuButton';
import Popover from 'src/components/popovers/layout/Popover';
import React from 'react';
import PropTypes from 'prop-types';
import _startCase from 'lodash/startCase';

const intervals = [
    'daily',
    'weekly',
    'monthly'
];

const IntervalSelectionPopover = ({ value, onChange, hidePopover }) => (
    <Popover width={180} id="intervalSelection">
        <ButtonGroup vertical>
            {
                intervals.map((name) => <MenuButton key={name} active={value === name} label={_startCase(name)} onClick={() => { onChange(name); hidePopover(); }} />)
            }
        </ButtonGroup>
    </Popover>
);

IntervalSelectionPopover.propTypes = {
    value: PropTypes.oneOf(intervals).isRequired,
    onChange: PropTypes.func.isRequired,
    hidePopover: PropTypes.func
};

export default IntervalSelectionPopover;
