import { isSubmitting, submit } from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';
import * as groupActionCreators from 'src/actions/groups';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import GroupDeleteForm from 'src/components/forms/GroupDeleteForm';
import { makeSelectGroupById } from 'src/selectors/groups';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';

const formName = 'groupDeleteForm';

const GroupDeleteModal = (props) => {
    const {
        isDeleting, hideModal, group, submitAction, enforceFocus
    } = props;
    return (
        <Modal dataViewName="delete-group" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>Delete group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GroupDeleteForm
                      group={group}
                      onSubmit={groupActionCreators.groupDeleteFormRequest}
                      initialValues={{ groupIds: [group.id] }}
                      form={formName}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          disabled={isDeleting}
                          onClick={hideModal}
                          label="Cancel"
                        />
                        <Button
                          action
                          warning
                          icon="delete"
                          loading={isDeleting}
                          onClick={() => { submitAction(formName); }}
                          label="Delete"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

GroupDeleteModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    group: customPropTypes.group.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool
};

const makeMapStateToProps = () => {
    const selectGroupById = makeSelectGroupById();
    return (state, ownProps) => ({
        group: selectGroupById(state, ownProps.groupId),
        isDeleting: isSubmitting(formName)(state)
    });
};

export default connect(makeMapStateToProps, {
    submitAction: submit
})(GroupDeleteModal);
