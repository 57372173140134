import CombinedDateSelectorView from 'src/components/dateSelector/CombinedDateSelectorView';
import * as customPropTypes from 'src/customPropTypes';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';

const CombinedDateSelectorPopover = (props) => {
    const {
        selectedDate, onDateChange, hidePopover
    } = props;

    const onApplyClick = (value) => {
        onDateChange(value);
        hidePopover();
    };

    return (
        <Popover id="combinedDateSelector">
            <CombinedDateSelectorView selectedDate={selectedDate} onApplyClick={onApplyClick} onCancelClick={hidePopover} />
        </Popover>
    );
};

CombinedDateSelectorPopover.propTypes = {
    selectedDate: customPropTypes.selectedDate.isRequired,
    onDateChange: PropTypes.func.isRequired,
    hidePopover: PropTypes.func,
};

export default CombinedDateSelectorPopover;
