import * as customPropTypes from 'src/customPropTypes';
import * as overlayAction from 'src/actions/overlays';
import AuthTransactionState from 'src/components/authTransactions/AuthTransactionState';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Date from 'src/components/Date';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import { makeSelectOnlyProfilesByIds } from 'src/selectors/profiles';
import { makeSelectOnlyAdAccountByIds } from 'src/selectors/adAccounts';
import { makeSelectProfileAuthTransactionRequirementsByIds, makeSelectAdAccountAuthTransactionRequirementByIds } from 'src/selectors/authTransactionRequirements';
import { getAccountIdFromAuthTransactionRequirements } from 'src/utils/authTransactionUtils';
import NetworkIcon from 'src/components/NetworkIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/authTransactions/authTransactionsDetailTable.scss';
import AuthTransactionTimeInfo from 'src/components/authTransactions/AuthTransactionTimeInfo';
import AuthTransactionLink from 'src/components/authTransactions/AuthTransactionLink';
import { getPlatformTypesFromAccounts } from 'src/utils/accounts';
import ListTableRow from 'src/components/listTable/ListTableRow';
import ListTableCell from 'src/components/listTable/ListTableCell';

const AuthTransactionsDetailRow = (props) => {
    const { authTransaction, platformTypes } = props;
    const {
        id,
        name,
        createTime,
        validUntil,
        isOpen,
        isFulfilled,
        isInProgress,
        hash
    } = authTransaction;

    const handleOnAuthTransactionClick = () => {
        const { modalsHideAuthTransactionsDetailAction, modalsShowAuthTransactionDetailAction } = props;
        modalsShowAuthTransactionDetailAction(id);
        modalsHideAuthTransactionsDetailAction();
    };

    const sharedProps = {
        onClick: handleOnAuthTransactionClick
    };

    return (
        <ListTableRow hasBorder={false}>
            <ListTableCell {...sharedProps}>
                <DivWithTooltip
                  tooltip={name}
                  className={styles.authTransactionName}
                >
                    {name}
                </DivWithTooltip>
            </ListTableCell>
            <ListTableCell {...sharedProps}>
                <Date date={createTime} format="datetime" />
            </ListTableCell>
            <ListTableCell {...sharedProps}>
                <div className={styles.networkIcons}>
                    {
                        platformTypes.map((platformType) => (
                            <NetworkIcon
                              key={platformType}
                              platformType={platformType}
                              className={classnames(styles.networkIcon, { [styles.youtube]: platformType === 'youtube', [styles.meta]: platformType === 'meta' })}
                            />
                        ))
                    }
                </div>
            </ListTableCell>
            <ListTableCell {...sharedProps}><AuthTransactionTimeInfo authTransaction={authTransaction} showCreatorInfo={false} /></ListTableCell>
            <ListTableCell {...sharedProps}>
                <AuthTransactionState
                  isOpen={isOpen}
                  isFulfilled={isFulfilled}
                  isInProgress={isInProgress}
                  validUntil={validUntil}
                  className={styles.icon}
                  showLabel
                />
            </ListTableCell>
            <ListTableCell padded={false}>
                {
                    hash && <AuthTransactionLink hash={hash} />
                }
                {
                    hash === null && <div>n/a</div>
                }
            </ListTableCell>
        </ListTableRow>
    );
};

AuthTransactionsDetailRow.propTypes = {
    authTransaction: customPropTypes.authTransaction.isRequired,
    platformTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    modalsShowAuthTransactionDetailAction: PropTypes.func.isRequired,
    modalsHideAuthTransactionsDetailAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectProfileAuthTransactionRequirementsByIds = makeSelectProfileAuthTransactionRequirementsByIds();
    const selectAdAccountAuthTransactionRequirementByIds = makeSelectAdAccountAuthTransactionRequirementByIds();
    const selectOnlyProfilesByIds = makeSelectOnlyProfilesByIds();
    const selectOnlyAdAccountByIds = makeSelectOnlyAdAccountByIds();
    return (state, ownProps) => {
        const { authTransaction } = ownProps;
        const profileIds = getAccountIdFromAuthTransactionRequirements(selectProfileAuthTransactionRequirementsByIds(state, authTransaction.profileAuthTransactionRequirementIds), 'profiles');
        const profiles = selectOnlyProfilesByIds(state, profileIds);
        const adAccountIds = getAccountIdFromAuthTransactionRequirements(selectAdAccountAuthTransactionRequirementByIds(state, authTransaction.adAccountAuthTransactionRequirementIds), 'adAccounts');
        const adAccounts = selectOnlyAdAccountByIds(state, adAccountIds);
        return {
            platformTypes: getPlatformTypesFromAccounts(profiles.concat(adAccounts))
        };
    };
};

export default connect(makeMapStateToProps, {
    modalsShowAuthTransactionDetailAction: overlayAction.modalsShowAuthTransactionDetail,
    modalsHideAuthTransactionsDetailAction: overlayAction.modalsHideAuthTransactionsDetail
})(AuthTransactionsDetailRow);
