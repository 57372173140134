import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/selectorLabels.scss';

const inputFormat = 'YYYY-MM-DD';
const outputMonthDay = 'MMMM DD, YYYY';

const SingleAbsoluteSelectedDateLabel = (props) => {
    const { selectedDate, labelIfNull } = props;
    let label = 'No date selected (Click to select)';
    if (selectedDate) {
        label = moment(selectedDate, inputFormat).format(outputMonthDay);
    } else if (labelIfNull) {
        label = labelIfNull;
    }

    return (
        <span className={styles.label}>
            {label}
        </span>
    );
};

SingleAbsoluteSelectedDateLabel.propTypes = {
    selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    labelIfNull: PropTypes.string
};

SingleAbsoluteSelectedDateLabel.defaultProps = {
    labelIfNull: ''
};

export default SingleAbsoluteSelectedDateLabel;
