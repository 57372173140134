import _isEqual from 'lodash/isEqual';
import { customRequired } from 'src/components/forms/validators';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'src/components/forms/fields';
import withReduxForm from 'src/components/forms/withReduxForm';
import FormError from 'src/components/forms/feedback/FormError';
import * as customPropTypes from 'src/customPropTypes';

const errorMessage = 'To confirm creation, please type the text exactly as displayed';

const required = (value) => customRequired(value, errorMessage);
const confirm = (value) => (
    (!_isEqual(value, 'CREATE')) ? errorMessage : undefined
);

const GoogleBigQueryDestinationCreateTableForm = (props) => {
    const { handleSubmit, error, dataPushTaskDestination } = props;
    const {
        googleBigQueryProjectId, googleBigQueryDatasetId, googleBigQueryTableId
    } = dataPushTaskDestination;

    return (
        <form onSubmit={handleSubmit}>
            {
                error && (
                    <FormError message={error.message} />
                )
            }
            <div>
                This operation will create a new table named <b>{googleBigQueryTableId}</b> in Google BigQuery within project <b>{googleBigQueryProjectId}</b> and data set <b>{googleBigQueryDatasetId}</b> using the provided authentication. This will only succeed if appropriate permissions for creating tables are present.<br /><br />
            </div>
            <Field
              name="confirmInput"
              label="Type CREATE to confirm"
              component={Text}
              validate={[required, confirm]}
              autoFocus
            />
        </form>
    );
};

GoogleBigQueryDestinationCreateTableForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.object,
    dataPushTaskDestination: customPropTypes.googleBigQueryDataPushTaskDestination.isRequired
};

export default withReduxForm(GoogleBigQueryDestinationCreateTableForm);
