import React from 'react';
import withTooltip from 'src/components/withToolTip';
import withPopover from 'src/components/withPopover';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import classnames from 'classnames';
import getPlatformProfileTypeInfo from 'src/utils/getPlatformProfileTypeInfo';
import PlatformProfileTypeInfoPopover from 'src/components/popovers/PlatformProfileTypeInfoPopover';
import styles from 'src/stylesheets/buttons/profileInofButtons.scss';
import PublicProfileDataAvailabilityPopover from 'src/components/popovers/PublicProfileDataAvailabilityPopover';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);
const IcomoonIconWithPopoverAndTooltip = withPopover(IcomoonIconWithTooltip);

const PlatformProfileInfoButtons = (props) => {
    const { profile, showDataAvailability, showExternalLink } = props;
    const { link } = profile;
    const platformProfileType = _get(profile, 'platformProfileType', null);
    const platformProfileTypeInfo = getPlatformProfileTypeInfo(profile.platformType, platformProfileType);

    return (
        <ButtonGroup>
            {
                platformProfileTypeInfo
                && (
                    <IcomoonIconWithPopoverAndTooltip
                      overlay={<PlatformProfileTypeInfoPopover profile={profile} platformProfileTypeInfo={platformProfileTypeInfo} />}
                      icon={platformProfileTypeInfo.icon}
                      tooltip={platformProfileTypeInfo.tooltip}
                      className={classnames(styles.icon, { [styles.alertIcon]: platformProfileTypeInfo.icon === 'alert' }, 'platformProfileInfoIcon')}
                      activeClassName={styles.active}
                    />
                )
            }
            {
                showDataAvailability
                && (
                    <IcomoonIconWithPopoverAndTooltip
                      overlay={<PublicProfileDataAvailabilityPopover profile={profile} />}
                      tooltip="Public data availability"
                      icon="history"
                      className={styles.icon}
                      activeClassName={styles.active}
                    />
                )
            }
            {
                showExternalLink
                && (
                <a className={styles.externalLink} href={link} target="_blank" rel="noopener noreferrer">
                    <IcomoonIconWithTooltip icon="vist-page" className={styles.icon} tooltip="Visit account page" />
                </a>
                )
            }
        </ButtonGroup>
    );
};

PlatformProfileInfoButtons.propTypes = {
    profile: PropTypes.oneOfType([
        customPropTypes.profile,
        customPropTypes.searchedProfile
    ]).isRequired,
    showExternalLink: PropTypes.bool,
    showDataAvailability: PropTypes.bool,
};

PlatformProfileInfoButtons.defaultProps = {
    showExternalLink: false,
    showDataAvailability: false
};

export default PlatformProfileInfoButtons;
