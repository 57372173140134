import Button from 'src/components/buttons/Button';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import AcceptTermsAndConditionsForm from 'src/components/forms/onboarding/AcceptTermsAndConditionsForm';
import { userAcceptsTermsAndConditionRequestFormAction } from 'src/actions/onboarding';
import { submit, isSubmitting, isValid } from 'redux-form';
import { connect, useSelector } from 'react-redux';

const formName = 'acceptTermsAndConditionsForm';

const AcceptTermsAndConditionsModal = (props) => {
    const { enforceFocus, submitAction } = props;
    const isFormSubmitting = useSelector((state) => isSubmitting(formName)(state));
    const isFormValid = useSelector((state) => isValid(formName)(state));
    return (
        <Modal dataViewName="accept-terms-and-conditions-modal" enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>
                        Review the terms to continue
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AcceptTermsAndConditionsForm
                      onSubmit={userAcceptsTermsAndConditionRequestFormAction}
                      form={formName}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                      label="Continue"
                      onClick={() => { submitAction(formName); }}
                      loading={isFormSubmitting}
                      action
                      disabled={!isFormValid}
                    />
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

AcceptTermsAndConditionsModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired
};

export default connect(null, {
    submitAction: submit
})(AcceptTermsAndConditionsModal);
