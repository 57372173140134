import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/listTable/listTableRow.scss';
import classnames from 'classnames';

const ListTableRow = (props) => {
    const {
        children, className, onClick, rowRef, tabIndex, disabled, hasBorder
    } = props;

    const optionalProps = {};
    if (tabIndex) {
        optionalProps.tabIndex = tabIndex;
    }
    if (rowRef) {
        optionalProps.ref = rowRef;
    }

    return (
        <tr
          className={classnames(styles.wrapper,
              {
                  [styles.disabled]: disabled,
                  [styles.clickable]: onClick,
                  [styles.border]: hasBorder
              }, className)}
          onClick={onClick}
          {...optionalProps}
        >
            {children}
        </tr>
    );
};

ListTableRow.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    rowRef: PropTypes.object,
    tabIndex: PropTypes.number,
    disabled: PropTypes.bool,
    hasBorder: PropTypes.bool
};

ListTableRow.defaultProps = {
    className: '',
    disabled: false,
    hasBorder: true
};

export default ListTableRow;
