import classnames from 'classnames';
import Label from 'src/components/labels/Label';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/labels/connectedProfileLabel.scss';

const DisconnectedAccountLabel = (props) => {
    const { active, onClick } = props;
    return (
        <div
          className={classnames(styles.wrapper)}
          role="button"
          onClick={onClick}
        >
            <div className={classnames(styles.button, { [styles.active]: active, [styles.clickable]: onClick })}>
                <Label state="disabled" />
                <div className={classnames(styles.label, styles.nonActive)}>No private stats</div>
            </div>
        </div>
    );
};

DisconnectedAccountLabel.propTypes = {
    onClick: PropTypes.func,
    active: PropTypes.bool.isRequired
};

export default DisconnectedAccountLabel;
