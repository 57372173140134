import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import Feedback from 'src/components/feedback/Feedback';
import _startCase from 'lodash/startCase';

const ResourceNotFoundModal = (props) => {
    const {
        hideModal, enforceFocus, resourceName, fullscreen
    } = props;

    return (
        <Modal dataViewName="resource-not-found" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog fullscreen={fullscreen}>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>{_startCase(resourceName)} not found</Modal.Title>
                </Modal.Header>
                <Modal.Body centered={fullscreen}>
                    <Feedback content={`The ${resourceName} you tried to access does not exist.`} type="error" />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          action
                          onClick={hideModal}
                          label="Close"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

ResourceNotFoundModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    resourceName: PropTypes.string.isRequired,
    fullscreen: PropTypes.bool
};

ResourceNotFoundModal.defaultProps = {
    fullscreen: true
};

export default ResourceNotFoundModal;
