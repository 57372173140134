import { networkFilterOptions } from 'src/utils/profiles';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'src/components/forms/inputs/Select';

const NetworkFilter = (props) => {
    const { networkFilter, onNetworkFilterClick } = props;

    const onSelectChange = (e) => {
        onNetworkFilterClick(e.target.value);
    };

    return (
        <Select layout="light" onChange={onSelectChange} defaultValue={networkFilter}>
            {networkFilterOptions.map(({ value, label }) => (
                <option
                  value={value}
                  key={`network-filter-${value}`}
                >
                    {label}
                </option>
            ))}
        </Select>
    );
};

NetworkFilter.propTypes = {
    networkFilter: PropTypes.string.isRequired,
    onNetworkFilterClick: PropTypes.func.isRequired
};

export default NetworkFilter;
