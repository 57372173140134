import React from 'react';
import AccountAddSidebar from 'src/components/account/layout/AccountAddSidebar';
import { makeSelectProfileTempIdsSortedAlphabitically } from 'src/selectors/profileSearch';
import { connect } from 'react-redux';
import withProfileAddingState from 'src/components/views/profiles/search/withProfileAddingState';
import withRemoveListItemIcon from 'src/components/withRemoveListItemIcon';
import CheckedProfileInfoItem from 'src/components/views/profiles/search/sidebar/CheckedProfileInfoItem';
import PropTypes from 'prop-types';
import { makeSelectCheckedValues } from 'src/selectors/lists';
import { listName } from 'src/utils/profileSearch';

const ProfileInfoListItemWithRemoveListItemIcon = withProfileAddingState(withRemoveListItemIcon(CheckedProfileInfoItem));

const renderSelectedAccounts = (id) => <ProfileInfoListItemWithRemoveListItemIcon fieldName={id} id={id} listName={listName} />;

const ProfileAddSidebar = (props) => {
    const { sortedProfileTempIds } = props;
    return (
        <AccountAddSidebar
          accountType="profiles"
          accountIds={sortedProfileTempIds}
          renderSelectedAccounts={renderSelectedAccounts}
          listName={listName}
        />
    );
};

ProfileAddSidebar.propTypes = {
    sortedProfileTempIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

const makeMapStateToProps = () => {
    const selectProfileTempIdsSortedAlphabitically = makeSelectProfileTempIdsSortedAlphabitically();
    const selectCheckedValues = makeSelectCheckedValues();
    return (state) => {
        const profileTempIds = selectCheckedValues(state, listName);
        return {
            sortedProfileTempIds: selectProfileTempIdsSortedAlphabitically(state, profileTempIds)
        };
    };
};

export default connect(makeMapStateToProps)(ProfileAddSidebar);
