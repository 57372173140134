import { isSubmitting, submit } from 'redux-form';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import CreateShareableLinkForm from 'src/components/forms/CreateShareableLinkForm';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { dashboardShareLinkUpdateRequestFormAction } from 'src/actions/dashboardShareLinks';
import { makeSelectDashboardShareLinkById } from 'src/selectors/dashboardShareLinks';
import { createInitialFormValuesFromDashboardShareLink } from 'src/utils/dashboardShareLink';

const formName = 'updateShareableLinkForm';

const EditShareableLinkModal = (props) => {
    const {
        dashboardShareLink, hideModal, submitAction, submitting, enforceFocus
    } = props;
    const initialValues = createInitialFormValuesFromDashboardShareLink(dashboardShareLink);
    return (
        <Modal dataViewName="create-update-shareable-link" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Update interactive report
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateShareableLinkForm
                      form={formName}
                      initialValues={initialValues}
                      onSubmit={dashboardShareLinkUpdateRequestFormAction}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button label="Cancel" onClick={hideModal} />
                        <Button action loading={submitting} label="Update" onClick={() => { submitAction(formName); }} />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

EditShareableLinkModal.propTypes = {
    dashboardShareLink: customPropTypes.dashboardShareLink.isRequired,
    hideModal: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool
};

const makeMapStateToProps = () => {
    const selectDashboardShareLinkById = makeSelectDashboardShareLinkById();
    return (state, ownProps) => {
        const { dashboardShareLinkId } = ownProps;
        return {
            dashboardShareLink: selectDashboardShareLinkById(state, dashboardShareLinkId),
            submitting: isSubmitting(formName)(state)
        };
    };
};

export default connect(makeMapStateToProps, {
    submitAction: submit,
})(EditShareableLinkModal);
