import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import Radio from 'src/components/forms/inputs/Radio';
import styles from 'src/stylesheets/inputs/radioList.scss';
import ImageWithFallback from 'src/components/ImageWithFallback';

const RadioList = (props) => {
    const { onChange, value } = props;

    useEffect(() => {
        const { defaultChecked } = props;
        if (defaultChecked && (value === '' || value === undefined)) {
            onChange(defaultChecked);
        }
    }, []);

    const handleOnCheckBoxChange = (id) => {
        onChange(id);
    };

    const {
        options, onFocus, onBlur, name
    } = props;
    return (
        <div className={styles.wrapper}>
            {
                options.map(({ id, label, image }) => (
                    <div className={styles.item} key={id}>
                        {
                            image && (
                                <ImageWithFallback src={image} className={styles.image} />
                            )
                        }
                        <Radio
                          name={id}
                          text={label}
                          checked={value === id}
                          onChange={() => { handleOnCheckBoxChange(id); }}
                        />
                    </div>
                ))
            }
            <input
              type="hidden"
              name={name}
              value={value}
              onFocus={onFocus}
              onBlur={onBlur}
            />
        </div>
    );
};

RadioList.propTypes = {
    options: customPropTypes.radioListOptions.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    defaultChecked: PropTypes.any
};

export default RadioList;
