import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/popover.scss';
import classnames from 'classnames';

const PopoverOwn = ({
    id, children, width, height, reminder
}) => (
    <div role="tooltip" id={id} className={classnames(styles.popover, { [styles.reminder]: reminder })} style={{ width, height }}>{children}</div>
);

PopoverOwn.propTypes = {
    id: PropTypes.string.isRequired,
    children: customPropTypes.children.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    reminder: PropTypes.bool
};

PopoverOwn.defaultProps = {
    width: 'auto',
    height: 'auto',
    reminder: false
};

export default PopoverOwn;
