import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/externalLink.scss';

const ExternalLink = (props) => {
    const {
        link, text, className, icon
    } = props;
    return (
        <a className={classnames(styles.wrapper, className)} href={link} target="_blank" rel="noopener noreferrer">
            {
                icon && <IcomoonIcon icon={icon} className={styles.icon} />
            }
            {
                text && <span>{text}</span>
            }
        </a>
    );
};

ExternalLink.propTypes = {
    link: PropTypes.string.isRequired,
    text: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string
};

ExternalLink.defaultProps = {
    className: '',
    icon: 'info',
    text: ''
};

export default ExternalLink;
