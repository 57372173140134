import { createSelector } from 'reselect';
import { defaultLoadingState } from 'src/selectors/utils';
import _get from 'lodash/get';

export const getProfileAudienceDataFromStore = (state) => state.profileAudienceData.audienceById;
export const getAsyncStates = (state) => state.profileAudienceData.asyncStates;
export const getWarningsFromStore = (state) => state.profileAudienceData.warningByProfileId;

export const makeSelectProfileAudienceDataByProfileIdOrNull = () => createSelector(
    [
        getProfileAudienceDataFromStore,
        (_, profileId) => profileId
    ],
    (audienceData, profileId) => audienceData[profileId] || null
);

export const makeSelectAudienceDataAsyncStateByProfileId = () => createSelector(
    [
        getAsyncStates,
        (_, profileId) => profileId
    ],
    (asyncStates, profileId) => _get(asyncStates, ['getAudienceData', profileId], defaultLoadingState)
);

export const makeSelectProfileWarningByProfileIdOrNull = () => createSelector(
    [
        getWarningsFromStore,
        (_, profileId) => profileId
    ],
    (warning, profileId) => warning[profileId] || null
);
