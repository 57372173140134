import CustomizeAccount from 'src/components/CustomizeAccount';
import { makeSelectAdAccountById } from 'src/selectors/adAccounts';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const makeMapStateToProps = () => {
    const selectAdAccountById = makeSelectAdAccountById();
    return (state, ownProps) => {
        const adAccount = selectAdAccountById(state, ownProps.adAccountId);
        return {
            accountType: 'adAccount',
            accountId: adAccount.id,
            appendix: adAccount.appendix,
            favorite: adAccount.favorite || false,
            color: adAccount.color
        };
    };
};

const CustomizeAdAccount = connect(makeMapStateToProps)(CustomizeAccount);

CustomizeAdAccount.propTypes = {
    adAccountId: PropTypes.string.isRequired
};

export default CustomizeAdAccount;
