import GroupSelectorModal from 'src/components/modals/GroupSelectorModal';
import { connect } from 'react-redux';
import { makeSelectGroupsIdsByProfileIdsWithIndeterminatedGroups } from 'src/selectors/profiles';
import { profileAddToOrRemoveFromGroupRequest } from 'src/actions/profiles';

const makeMapStateToProps = () => {
    const selectGroupsIdsByProfileIdsWithIndeterminatedGroups = makeSelectGroupsIdsByProfileIdsWithIndeterminatedGroups();
    return (state, ownProps) => {
        const { profileIds } = ownProps;
        const selectedGroupIds = selectGroupsIdsByProfileIdsWithIndeterminatedGroups(state, profileIds);
        return {
            accountIds: profileIds,
            selectedGroupIds: selectedGroupIds.selectedGroupIds,
            indeterminateGroupIds: selectedGroupIds.indeterminateGroupIds
        };
    };
};

const GroupSelectorProfileModal = connect(makeMapStateToProps, {
    accountAddToOrRemoveFromGroupRequestAction: profileAddToOrRemoveFromGroupRequest
})(GroupSelectorModal);

export default GroupSelectorProfileModal;
