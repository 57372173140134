import { emailValidator, required } from 'src/components/forms/validators';
import * as customPropTypes from 'src/customPropTypes';
import { Text } from 'src/components/forms/fields';
import { Field } from 'redux-form';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';

const ChangeEmailForm = (props) => {
    const { handleSubmit, error } = props;
    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <Field
              name="currentEmail"
              label="Current Email"
              component={Text}
              readOnly
            />
            <Field
              autoFocus
              name="newEmail"
              label="New Email"
              component={Text}
              validate={[required, emailValidator]}
              info="Enter the new email address you would like to use. After submission you will be logged out and we need you to verify your new email address before you can continue using your account."
            />
        </form>
    );
};

ChangeEmailForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: customPropTypes.dataLoadingError
};

export default withReduxForm(ChangeEmailForm, {
    destroyOnUnmount: true,
    validate: (values) => (values.newEmail === values.currentEmail ? { newEmail: 'New email must be different from current email' } : undefined)
});
