import Radio from 'src/components/forms/inputs/Radio';
import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/conjunctionInput.scss';

const ConjunctionInput = ({ value, onChange, disabled }) => (
    <div className={styles.wrapper}>
        <div className={styles.any}>
            <Radio disabled={disabled} checked={value === 'any'} text="Any" onChange={() => onChange('any')} />
        </div>
        <div>
            <Radio disabled={disabled} checked={value === 'all'} text="All" onChange={() => onChange('all')} />
        </div>
    </div>
);

ConjunctionInput.propTypes = {
    value: PropTypes.oneOf(['any', 'all']).isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export default ConjunctionInput;
