import React, { Component } from 'react';
import ClientSideTable from 'src/components/chartViews/fixedDataTable/ClientSideTable';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/tableView.scss';
import TableCellSettingContextProvider from 'src/components/metricTiles/TableCellSettingContextProvider';

const parseAllResults = (data, type) => {
    const { user } = data;
    const allResults = { columns: [], results: [] };
    switch (type) {
        case 'twitterInteractions':
            allResults.results = data.interactions;
            // insert UserName field since it does not contain, better to get from the server response directly
            allResults.results.forEach((row) => {
                Object.assign(row, { userName: user.name });
            });
            allResults.columns = [
                {
                    id: 'userName', title: 'Tweets', type: 'tweeterOfPages', width: 0.8
                },
                {
                    id: 'isRetweet', title: 'Retweeted tweet?', type: 'boolean', width: 0.2
                }
            ];
            break;
        case 'twitterRetweeters':
            allResults.results = data.retweets;
            allResults.columns = [
                {
                    id: 'user', title: 'Retweeters', type: 'twitterRetweeters', width: 0.7
                },
                {
                    id: 'followers', title: 'Followers', type: 'integer', width: 0.3
                }
            ];
            break;
        default:
    }
    return allResults;
};

class OverlayTableView extends Component {
    constructor(props) {
        super(props);
        const { data, type } = this.props;
        const allResults = parseAllResults(data, type);
        const { results, columns } = allResults;
        this.state = {
            results,
            columns
        };
    }

    render() {
        const { className, width, height } = this.props;
        const { columns, results } = this.state;
        const tableConfig = {
            sortBy: columns.length > 0 ? columns[0].id : '',
            sortDir: 'DESC',
            columns
        };
        const tableProps = {
            width,
            height,
            headerHeight: 30,
            tableConfig,
            results,
            settings: {},
            paginate: true
        };
        return (
            <div className={className}>
                <div className={styles.tableWrapper}>
                    <TableCellSettingContextProvider>
                        <ClientSideTable {...tableProps} />
                    </TableCellSettingContextProvider>
                </div>
            </div>
        );
    }
}

OverlayTableView.propTypes = {
    data: PropTypes.object.isRequired,
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
};

export default OverlayTableView;
