import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/quickLink.scss';

const QuickLink = (props) => {
    const { onClick, label, active } = props;
    return (
        <a
          href="#"
          onClick={(event) => { onClick(event); event.preventDefault(); }}
          className={classnames(styles.quickLink, { [styles.active]: active })}
        >
            <div title={label} className={styles.label}>
                { active && <IcomoonIcon icon="check-mark-in-box" className={styles.icon} /> } {label}
            </div>
        </a>
    );
};

QuickLink.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired
};

export default QuickLink;
