import * as customPropTypes from 'src/customPropTypes';
import AuthTransactionRequirementsColumns from 'src/components/authTransactions/table/AuthTransactionRequirementsColumns';
import PropTypes from 'prop-types';
import React from 'react';
import ListTableRow from 'src/components/listTable/ListTableRow';
import ListTableCell from 'src/components/listTable/ListTableCell';

const AuthTransactionRequirementsRow = (props) => {
    const {
        requirements,
        accountInfo,
        platformUseCases,
        connectButton
    } = props;
    return (
        <ListTableRow>
            <ListTableCell padded={false}>
                {accountInfo}
            </ListTableCell>
            <AuthTransactionRequirementsColumns
              requirements={requirements}
              platformUseCases={platformUseCases}
            />
            {
                connectButton && <ListTableCell>{connectButton}</ListTableCell>
            }
        </ListTableRow>
    );
};

AuthTransactionRequirementsRow.propTypes = {
    accountInfo: PropTypes.node.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired,
    connectButton: PropTypes.node,
    requirements: PropTypes.oneOfType([customPropTypes.adAccountAuthTransactionRequirements, customPropTypes.profileAuthTransactionRequirements]).isRequired
};

AuthTransactionRequirementsRow.defaultProps = {
    connectButton: null
};

export default AuthTransactionRequirementsRow;
