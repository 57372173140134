import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/label.scss';
import StateIndicator from 'src/components/labels/StateIndicator';

const Label = (props) => {
    const {
        text, state, onMouseOver, onMouseOut
    } = props;

    return (
        <div
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          className={classnames(styles.label, { [styles.icon]: !text })}
        >
            <StateIndicator state={state} />
            {
                text && (
                    <div className={styles.text}>
                        {text}
                    </div>
                )
            }
        </div>
    );
};

Label.propTypes = {
    state: PropTypes.oneOf(['ok', 'warning', 'error', 'on', 'off', 'disabled']).isRequired,
    text: PropTypes.string,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func
};

export default Label;
