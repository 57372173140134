import MenuButton from 'src/components/buttons/MenuButton';
import TextWithResetIcon from 'src/components/forms/inputs/TextWithResetIcon';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeSelectSortedFaceliftCampaignIds } from 'src/reducers/slices/faceliftCampaigns';
import { makeSelectSortedFaceliftTagIds } from 'src/reducers/slices/faceliftTags';
import { makeSelectActiveCombinedPostTagsById } from 'src/selectors/combindedPostTags';
import { makeSelectSortedActivePostTagIds } from 'src/selectors/postTags';
import styles from 'src/stylesheets/popovers/filterPopover.scss';
import _concat from 'lodash/concat';

const makeMapStateToPropsForMenuButton = () => {
    const selectActiveCombinedPostTagsById = makeSelectActiveCombinedPostTagsById();
    return (state, ownProps) => {
        let label = 'Invalid tag';

        const selectedItem = selectActiveCombinedPostTagsById(state, ownProps.postTagId);
        if (selectedItem) {
            label = selectedItem.name;
        }
        return {
            label
        };
    };
};

const ConnectedMenuButton = connect(makeMapStateToPropsForMenuButton)(MenuButton);

const TagFilterPopover = ({
    defaultValue, onSubmit, hidePopover, activePostTagIds, selectedValues, filterQuery, setFilterQuery, faceliftTagIds, faceliftCampaignIds
}) => {
    const allTagIds = _concat(activePostTagIds, faceliftTagIds, faceliftCampaignIds);

    const submit = (clickedValue) => { onSubmit(clickedValue); hidePopover(); };
    const selectedValuesWithoutSelf = defaultValue ? selectedValues.filter((value) => value !== defaultValue) : selectedValues;
    const filteredPostTagIds = allTagIds.filter((el) => selectedValuesWithoutSelf.indexOf(el) < 0);
    const inputError = !!filterQuery && filteredPostTagIds.length < 1;

    useEffect(() => {
        setFilterQuery('');
    }, []);
    return (
        <Popover width={230} id="tagFilterPopover">
            <div className={styles.container}>
                <div className={styles.header}>
                    <TextWithResetIcon
                      value={filterQuery}
                      onChange={(e) => { setFilterQuery(e.target.value); }}
                      onResetClick={() => { setFilterQuery(''); }}
                      placeholder="Search for tags"
                      autoFocus
                      error={inputError}
                    />
                </div>
                <div className={styles.items}>
                    {
                        filteredPostTagIds.length > 0 && filteredPostTagIds.map((postTagId) => (
                            <ConnectedMenuButton
                              key={postTagId}
                              postTagId={postTagId}
                              onClick={() => { submit(postTagId); }}
                            />
                        ))
                    }
                    {
                        filteredPostTagIds.length < 1 && (<div className={styles.empty}>No active tags available</div>)
                    }
                </div>
            </div>
        </Popover>
    );
};

TagFilterPopover.propTypes = {
    defaultValue: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    hidePopover: PropTypes.func,
    activePostTagIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    faceliftTagIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    faceliftCampaignIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
    filterQuery: PropTypes.string.isRequired,
    setFilterQuery: PropTypes.func.isRequired
};

TagFilterPopover.defaultProps = {
    defaultValue: ''
};

const makeMapStateToProps = () => {
    const selectSortedActivePostTagIds = makeSelectSortedActivePostTagIds();
    const selectSortedFaceliftTagIds = makeSelectSortedFaceliftTagIds();
    const selectSortedFaceliftCampaignIds = makeSelectSortedFaceliftCampaignIds();
    return (state, ownProps) => ({
        activePostTagIds: selectSortedActivePostTagIds(state, ownProps.filterQuery),
        faceliftTagIds: selectSortedFaceliftTagIds(state, ownProps.filterQuery),
        faceliftCampaignIds: selectSortedFaceliftCampaignIds(state, ownProps.filterQuery)
    });
};

export default connect(makeMapStateToProps)(TagFilterPopover);
