import React from 'react';
import styles from 'src/stylesheets/listTable/listTableCell.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ListTableCell = (props) => {
    const {
        isHeaderCell, align, children, className, width, markUnhealthy, onClick, disabled, rightContent, padded, isHeaderViewLayout
    } = props;
    const classNames = classnames({
        [styles.right]: align === 'right',
        [styles.left]: align === 'left',
        [styles.center]: align === 'center',
        [styles.clickable]: onClick,
        [styles.disabled]: disabled,
        [styles.padded]: padded
    }, className);
    const style = {};
    if (isHeaderCell && width) {
        style.width = width;
    }

    const handleOnClick = () => {
        if (onClick) {
            onClick();
        }
    };

    const content = rightContent ? (
        <div className={styles.childrenWithRightContent}>
            <div className={styles.children}>
                {children}
            </div>
            <div>
                {rightContent}
            </div>
        </div>
    ) : children;

    return isHeaderCell
        ? <th className={classnames(styles.th, classNames, { [styles.viewLayout]: isHeaderViewLayout })} style={style} onClick={handleOnClick}>{content}</th>
        : <td className={classnames(styles.td, classNames, { [styles.unHealthy]: markUnhealthy })} onClick={handleOnClick}>{content}</td>;
};

ListTableCell.propTypes = {
    children: PropTypes.node,
    isHeaderCell: PropTypes.bool,
    align: PropTypes.oneOf(['left', 'right', 'center']),
    className: PropTypes.string,
    width: PropTypes.string,
    markUnhealthy: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    rightContent: PropTypes.node,
    padded: PropTypes.bool,
    isHeaderViewLayout: PropTypes.bool
};

ListTableCell.defaultProps = {
    isHeaderCell: false,
    align: 'left',
    className: '',
    width: '',
    markUnhealthy: false,
    disabled: false,
    padded: true,
    isHeaderViewLayout: true
};

export default ListTableCell;
