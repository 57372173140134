import {
    makeGetPlatformSpecificAuthTransactionReqByProfilesById,
    makeSelectTransactionRequirementByIdsOrderedByProfileId,
    makeSelectAdAccountAuthTransactionRequirementByIdsOrderedByAdAccountId,
    makeSelectPlatformSpecificAuthTransactionReqByAdAccountsById
} from 'src/selectors/authTransactionRequirements';
import { connect } from 'react-redux';
import React from 'react';

const withAuthTransactionRequirementsSortedByNetworkType = (WrappedComponent) => {
    const WithAuthTransactionRequirementsSortedByNetworkType = (props) => (
        <WrappedComponent {...props} />
    );

    const makeMapStateToProps = () => {
        const selectTransactionRequirementByIdsOrderedByProfileId = makeSelectTransactionRequirementByIdsOrderedByProfileId();
        const getPlatformSpecificAuthTransactionReqByProfilesById = makeGetPlatformSpecificAuthTransactionReqByProfilesById();
        const selectAdAccountAuthTransactionRequirementByIdsOrderedByAdAccountId = makeSelectAdAccountAuthTransactionRequirementByIdsOrderedByAdAccountId();
        const selectPlatformSpecificAuthTransactionReqByAdAccountsById = makeSelectPlatformSpecificAuthTransactionReqByAdAccountsById();
        return (state, ownProps) => {
            const { authTransaction } = ownProps;
            const authTransactionRequirementsByProfileIds = selectTransactionRequirementByIdsOrderedByProfileId(state, authTransaction.profileAuthTransactionRequirementIds);
            const authTransactionRequirementsByAdAccountIds = selectAdAccountAuthTransactionRequirementByIdsOrderedByAdAccountId(state, authTransaction.adAccountAuthTransactionRequirementIds);
            return {
                authTransaction,
                profileAuthTransactionReqByNetworkType: getPlatformSpecificAuthTransactionReqByProfilesById(state, authTransactionRequirementsByProfileIds),
                adAccountAuthTransactionReqByNetworkType: selectPlatformSpecificAuthTransactionReqByAdAccountsById(state, authTransactionRequirementsByAdAccountIds)
            };
        };
    };
    return connect(makeMapStateToProps)(WithAuthTransactionRequirementsSortedByNetworkType);
};

export default withAuthTransactionRequirementsSortedByNetworkType;
