import { isSubmitting, submit } from 'redux-form';
import { selectDashboardById } from 'src/selectors/dashboards';
import { makeSelectDashboardReportById } from 'src/selectors/dashboardReports';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import CreateReportForm from 'src/components/forms/CreateReportForm';
import { dashboardReportUpdateRequestFormAction } from 'src/actions/dashboardReports';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import withResourceCheck from 'src/components/modals/withResourceCheck';
import { dashboardReportScheduleCleanup } from 'src/actions/dashboardReportSchedule';
import { modalShowDashboardReportDelete } from 'src/actions/overlays';
import { createInitialFormValuesFromDashboardReport } from 'src/utils/dashboardReport';
import memoizone from 'memoize-one';
import PreviewReportButton from 'src/components/buttons/PreviewReportButton';

const memoizedCreateInitialFormValuesFromDashboardReport = memoizone(createInitialFormValuesFromDashboardReport);

const formName = 'editReportModal';

const EditReportModal = (props) => {
    const {
        dashboardReport,
        dashboard,
        submitAction,
        submitting,
        enforceFocus,
        modalShowDashboardReportDeleteAction
    } = props;
    const initialValues = memoizedCreateInitialFormValuesFromDashboardReport(dashboardReport);
    const footerButtons = (
        <ButtonGroup>
            <PreviewReportButton formName={formName} dashboardId={dashboard.id} />
            <Button
              label="Delete report"
              onClick={() => { modalShowDashboardReportDeleteAction(dashboardReport.id); }}
              warning
            />
        </ButtonGroup>
    );

    const handleOnHideModal = () => {
        const { dashboardReportScheduleCleanupAction, hideModal } = props;
        dashboardReportScheduleCleanupAction();
        hideModal();
    };

    return (
        <Modal dataViewName="edit-report-modal" onHide={handleOnHideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={handleOnHideModal}>
                    <Modal.Title>Edit report: {dashboard.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body centered>
                    <CreateReportForm
                      onSubmit={dashboardReportUpdateRequestFormAction}
                      form={formName}
                      initialValues={initialValues}
                    />
                </Modal.Body>
                <Modal.Footer buttonsLeft={footerButtons}>
                    <ButtonGroup>
                        <Button
                          disabled={submitting}
                          onClick={handleOnHideModal}
                          label="Cancel"
                        />
                        <Button
                          action
                          loading={submitting}
                          onClick={() => submitAction(formName)}
                          label="Save"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

EditReportModal.propTypes = {
    dashboardReport: customPropTypes.dashboardReport.isRequired,
    dashboard: customPropTypes.dashboard.isRequired,
    hideModal: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    additionalFilter: customPropTypes.additionalFilterValues.isRequired,
    submitting: PropTypes.bool,
    enforceFocus: PropTypes.bool,
    dashboardReportScheduleCleanupAction: PropTypes.func.isRequired,
    modalShowDashboardReportDeleteAction: PropTypes.func.isRequired
};

EditReportModal.defaultProps = {
    submitting: false
};

const CreateReportModalWithResourceCheck = withResourceCheck(EditReportModal, 'dashboardReport', 'Report');

const makeMapStateToProps = () => {
    const selectDashboardReportById = makeSelectDashboardReportById();
    return (state, ownProps) => {
        const { dashboardReportId } = ownProps;
        const dashboardReport = selectDashboardReportById(state, dashboardReportId);
        const { dashboardId } = dashboardReport;
        return {
            dashboardReport,
            dashboard: selectDashboardById(state, dashboardId),
            submitting: isSubmitting(formName)(state)
        };
    };
};

export default connect(makeMapStateToProps, {
    submitAction: submit,
    dashboardReportScheduleCleanupAction: dashboardReportScheduleCleanup,
    modalShowDashboardReportDeleteAction: modalShowDashboardReportDelete
})(CreateReportModalWithResourceCheck);
