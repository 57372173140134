import React, { useEffect } from 'react';
import { profileSearchByAuthProfilesRequest } from 'src/actions/profileSearch';
import { connect } from 'react-redux';
import { listName } from 'src/utils/profileSearch';
import PropTypes from 'prop-types';
import { getAuthProfileSearchOutput, makeSelectAuthProfileSearchState } from 'src/selectors/profileSearch';
import withAccountSearchListHandling from 'src/components/bulkList/withAccountSearchListHandling';
import SearchedAuthProfileList from 'src/components/views/profiles/search/SearchedAuthProfileList';
import * as customPropTypes from 'src/customPropTypes';
import AvailableConnectedAccounts from 'src/components/account/AvailableConnectedAccounts';

const SearchedAuthProfileListWithListStateHandling = withAccountSearchListHandling(SearchedAuthProfileList, listName);

const AvailableConnectedProfiles = (props) => {
    useEffect(() => {
        const { profileSearchByAuthProfilesRequestAction } = props;
        profileSearchByAuthProfilesRequestAction();
    }, []);
    const { output, state } = props;
    const { isPending } = state;

    const { profileIds } = output;

    return (
        <AvailableConnectedAccounts
          accountIds={profileIds}
          isLoading={isPending}
          accountType="profiles"
          renderAuthAccountList={(accountIds) => <SearchedAuthProfileListWithListStateHandling accountIds={accountIds} searchedProfileIds={accountIds} />}
        />
    );
};

AvailableConnectedProfiles.propTypes = {
    profileSearchByAuthProfilesRequestAction: PropTypes.func.isRequired,
    output: PropTypes.shape({
        profileIds: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired,
    state: customPropTypes.asyncDataLoadingState.isRequired
};

const makeMapStateToProps = () => {
    const selectAuthProfileSearchState = makeSelectAuthProfileSearchState();
    return (state) => ({
        state: selectAuthProfileSearchState(state),
        output: getAuthProfileSearchOutput(state)
    });
};

export default connect(makeMapStateToProps, {
    profileSearchByAuthProfilesRequestAction: profileSearchByAuthProfilesRequest
})(AvailableConnectedProfiles);
