import {
    Highlighter, Menu, MenuItem, Token, Typeahead
} from 'react-bootstrap-typeahead';
import React, { PureComponent } from 'react';
import _groupBy from 'lodash/groupBy';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import SearchBarTag from 'src/components/tokenSearch/SearchBarTag';
import styles from 'src/stylesheets/inputs/tagSelectList.scss';
import 'src/stylesheets/tokenSearch/tokenBasedSearchBar.scss';

const renderToken = (tag, { onRemove }, index) => {
    const { id, name } = tag;
    const key = id || name;
    return (
        <Token
          key={key}
          className={classnames(styles.tag, { 'token-selected': index })}
          onRemove={onRemove}
        >
            <SearchBarTag
              tag={tag}
              onRemove={onRemove}
            />
        </Token>
    );
};

const renderMenu = (results, menuProps) => {
    let idx = 0;
    const grouped = _groupBy(results, (r) => r.tagType || 'Search for');
    const items = Object.keys(grouped).map((type) => [
        grouped[type].map((result) => {
            const { name } = result;
            const item = (
                <MenuItem
                  key={result.id}
                  option={result}
                  position={idx}
                  className="menuItem"
                >
                    <Highlighter search={menuProps.text}>
                        {name}
                    </Highlighter>
                </MenuItem>
            );
            idx += 1;
            return item;
        })
    ]);
    return <Menu {...menuProps}>{items}</Menu>;
};

class TagSelectList extends PureComponent {
    render() {
        const {
            options, onChange, placeholder, name, value, allowNew, labelKey
        } = this.props;
        return (
            <div>
                <Typeahead
                  defaultSelected={value}
                  onChange={onChange}
                  options={options}
                  allowNew={allowNew}
                  labelKey={labelKey}
                  placeholder={placeholder}
                  renderToken={renderToken}
                  renderMenu={renderMenu}
                  clearButton
                  multiple
                  flip
                />
                <input type="hidden" name={name} value={value} />
            </div>
        );
    }
}

TagSelectList.propTypes = {
    value: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    allowNew: PropTypes.bool,
    placeholder: PropTypes.string,
    defaultSelected: PropTypes.array,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    labelKey: PropTypes.string.isRequired
};

TagSelectList.defaultProps = {
    allowNew: false,
    placeholder: '',
    defaultSelected: []
};

export default TagSelectList;
