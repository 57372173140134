import { foldersAndDashboardsMoveToFolderRequest } from 'src/actions/folders';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { selectFolderTypeById } from 'src/selectors/ownership';
import * as customPropTypes from 'src/customPropTypes';
import { getReadableOwnershipType } from 'src/utils/ownership';

const SharedToPrivateOwnershipWarningModal = (props) => {
    const {
        hideModal, enforceFocus, folderIds, fromFolderId, toFolderId, dashboardIds, foldersAndDashboardsMoveToFolderRequestAction, toFolderType
    } = props;
    const confirm = () => {
        foldersAndDashboardsMoveToFolderRequestAction(folderIds, dashboardIds, toFolderId, fromFolderId);
        hideModal();
    };

    const itemCount = dashboardIds.length + folderIds.length;
    const wording = itemCount === 1 ? 'This item' : 'These items';

    const headline = `Move to ${getReadableOwnershipType(toFolderType)} dashboards?`;
    const cta = `Move to ${getReadableOwnershipType(toFolderType)}`;
    return (
        <Modal dataViewName="sharedToPrivateOwnership" enforceFocus={enforceFocus} onHide={hideModal}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>{headline}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { toFolderType === 'user' && <>{wording} will only be available for you to access.</>}
                    { toFolderType === 'shared' && <>{wording} will be available for all users of the account.</>}
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button onClick={hideModal} label="Cancel" />
                        <Button action onClick={confirm} label={cta} />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

SharedToPrivateOwnershipWarningModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    toFolderId: PropTypes.string.isRequired,
    fromFolderId: PropTypes.string.isRequired,
    folderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    dashboardIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    foldersAndDashboardsMoveToFolderRequestAction: PropTypes.func.isRequired,
    toFolderType: customPropTypes.ownershipType.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    toFolderType: selectFolderTypeById(state, ownProps.toFolderId)
});

export default connect(mapStateToProps, {
    foldersAndDashboardsMoveToFolderRequestAction: foldersAndDashboardsMoveToFolderRequest
})(SharedToPrivateOwnershipWarningModal);
