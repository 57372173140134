import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/loadingIndicators/emptyDiv.scss';

const EmptyDiv = (props) => {
    const { width, height, classNames } = props;
    const inheritStyle = { width, height };

    return (
        <div
          className={classnames(styles.wrapper, classNames)}
          style={inheritStyle}
        />
    );
};

EmptyDiv.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    classNames: PropTypes.string
};

EmptyDiv.defaultProps = {
    classNames: '',
    width: '100%',
    height: '100%'
};

export default EmptyDiv;
