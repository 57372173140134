import { adAccountAddToOrRemoveFromGroupRequest } from 'src/actions/adAccounts';
import ManageGroupsOfAccountsPopover from 'src/components/popovers/ManageGroupsOfAccountsPopover';
import { connect } from 'react-redux';
import {
    makeSelectGroupIdsByAdAccountId,
} from 'src/selectors/profiles';
import PropTypes from 'prop-types';

const makeMapStateToProps = () => {
    const selectGroupIdsByAdAccountId = makeSelectGroupIdsByAdAccountId();
    return (state, ownProps) => {
        const { adAccountId } = ownProps;
        return {
            accountId: adAccountId,
            selectedGroupIds: selectGroupIdsByAdAccountId(state, adAccountId),
        };
    };
};

const ManageGroupsOfAdAccountsPopover = connect(makeMapStateToProps, {
    accountAddToOrRemoveFromGroupRequestAction: adAccountAddToOrRemoveFromGroupRequest,
})(ManageGroupsOfAccountsPopover);

ManageGroupsOfAdAccountsPopover.propTypes = {
    adAccountId: PropTypes.string.isRequired
};

export default ManageGroupsOfAdAccountsPopover;
