import Date from 'src/components/Date';
import React from 'react';
import Modal from 'src/components/modals/layout/Modal';
import styles from 'src/stylesheets/modals/postDetailModal.scss';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';

const PostDetailHeader = (props) => {
    const {
        post, profileName, handleOnModalClose, renderScroller
    } = props;
    const { time } = post;
    return (
        <Modal.Header onHide={handleOnModalClose}>
            <Modal.Title>
                <div className={styles.header}>
                    {
                        renderScroller
                        && (
                            <div className={styles.scroller}>
                                {
                                    renderScroller()
                                }
                            </div>
                        )
                    }
                    <DivWithTooltip tooltip={profileName} className={styles.name}>{profileName}</DivWithTooltip>
                    <div className={styles.separator}>-</div>
                    <Date dateInTimezone={post.timezone} dateToTimezone={post.timezone} date={time} format="datetime" appendTimezone />
                </div>
            </Modal.Title>
        </Modal.Header>
    );
};

PostDetailHeader.propTypes = {
    post: customPropTypes.post.isRequired,
    profileName: PropTypes.string.isRequired,
    handleOnModalClose: PropTypes.func.isRequired,
    renderScroller: PropTypes.func,
};

export default PostDetailHeader;
