import React from 'react';
import ListItemWithActiveStateHandling from 'src/components/ListItemWithActiveStateHandling';
import PropTypes from 'prop-types';

const FilteredOptionListItem = (props) => {
    const {
        onClick, active, children
    } = props;
    return (
        <ListItemWithActiveStateHandling onClick={onClick} active={active}>
            {children}
        </ListItemWithActiveStateHandling>
    );
};

export default FilteredOptionListItem;

FilteredOptionListItem.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired
};
