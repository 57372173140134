import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';
import styles from 'src/stylesheets/userInvitationBox.scss';
import React from 'react';

const EmptyInvitationBox = () => (
    <div className={styles.wrapper}>
        <div className={styles.header}>User Invitation</div>
        <EmptyDiv height={200} />
    </div>
);

export default EmptyInvitationBox;
