import Button from 'src/components/buttons/Button';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/modals/aceEditorModal.scss';
import AceEditor from 'src/components/forms/inputs/AceEditor';

const AceEditorModal = (props) => {
    const {
        title, value, mode, hideModal, enforceFocus
    } = props;
    return (
        <Modal dataViewName="ace-editor-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog width={800} className={styles.dialog}>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body height={412}>
                    <AceEditor
                      mode={mode}
                      value={value}
                      isReadOnly
                      className={styles.aceEditor}
                      name="ace-editor"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button label="Close" onClick={hideModal} />
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

AceEditorModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    mode: PropTypes.string
};

AceEditorModal.defaultProps = {
    mode: 'sql'
};

export default AceEditorModal;
