import React from 'react';
import ProfileNameAndMetaInfo from 'src/components/profile/ProfileNameAndMetaInfo';
import styles from 'src/stylesheets/profile/profileInfoInDetailPage.scss';
import ProfilePicture from 'src/components/profile/ProfilePicture';
import PlatformProfileInfoButtons from 'src/components/profile/PlatformProfileInfoButtons';
import * as customPropTypes from 'src/customPropTypes';
import { getAudienceSizeAndAddedDateInfo } from 'src/utils/profiles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProfileHealthStatusWarning from 'src/components/profile/UnHealthyProfileIndicator';
import { getAccountOptions } from 'src/selectors/loggedInUser';

const ProfileInfoInDetailPage = (props) => {
    const { profile, lazyLoadProfileImage, accountOptions } = props;
    const { picture } = profile;

    return (
        <div className={styles.wrapper}>
            <div className={styles.info}>
                <ProfilePicture picture={picture} lazyLoadProfileImage={lazyLoadProfileImage} isLarge />
                <div className={styles.nameAndMetaInfo}>
                    <ProfileNameAndMetaInfo profile={profile} isLarge showAppendix={false} />
                </div>
                <div className={styles.likesAndDate}>
                    {getAudienceSizeAndAddedDateInfo(profile, accountOptions)}
                </div>
            </div>
            <div className={styles.buttons}>
                <div className={styles.warningIndicator}>
                    <ProfileHealthStatusWarning profile={profile} />
                </div>
                <PlatformProfileInfoButtons profile={profile} showExternalLink showDataAvailability />
            </div>
        </div>
    );
};

ProfileInfoInDetailPage.propTypes = {
    lazyLoadProfileImage: PropTypes.bool,
    profile: customPropTypes.profile.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired
};

ProfileInfoInDetailPage.defaultProps = {
    lazyLoadProfileImage: false
};

const mapStateToProps = (state) => ({
    accountOptions: getAccountOptions(state)
});

export default connect(mapStateToProps)(ProfileInfoInDetailPage);
