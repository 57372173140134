import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { postTagRuleDeleteRequestFormRequest } from 'src/actions/postTagRule';
import { connect } from 'react-redux';
import { isSubmitting, submit } from 'redux-form';
import { makeSelectPostTagRuleById } from 'src/selectors/postTagRules';
import * as customPropTypes from 'src/customPropTypes';
import PostTagRulesRemoveForm from 'src/components/forms/PostTagRulesRemoveForm';

const formName = 'postTagRulesRemoveForm';

const postListStyle = {
    paddingBottom: '20px',
    maxHeight: '500px',
    overflow: 'auto'
};

const PostTagRulesDeleteModal = (props) => {
    const {
        hideModal, postTagRule, enforceFocus, submitAction, isDeleting
    } = props;
    const { id, postTagId } = postTagRule;
    const initialValues = {
        postTagRuleId: id,
        postTagId,
        removeTagsOnPosts: false
    };
    return (
        <Modal dataViewName="delete-post-tag-rules" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Delete auto-tag rule
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={postListStyle}>
                        <span>
                            Are you sure you want to delete <b>{postTagRule.name} </b>?
                        </span>
                    </div>
                    <PostTagRulesRemoveForm
                      form={formName}
                      onSubmit={postTagRuleDeleteRequestFormRequest}
                      initialValues={initialValues}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button label="Cancel" onClick={hideModal} />
                        <Button
                          action
                          warning
                          icon="delete"
                          loading={isDeleting}
                          onClick={() => { submitAction(formName); }}
                          label="Delete"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

PostTagRulesDeleteModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool,
    postTagRule: customPropTypes.postTagRule.isRequired,
    postTagRuleId: PropTypes.string.isRequired,
    submitAction: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectPostTagRuleById = makeSelectPostTagRuleById();
    return (state, ownProps) => ({
        isDeleting: isSubmitting(formName)(state),
        postTagRule: selectPostTagRuleById(state, ownProps.postTagRuleId)
    });
};

export default connect(makeMapStateToProps, {
    submitAction: submit,
})(PostTagRulesDeleteModal);
