import classnames from 'classnames';
import Label from 'src/components/labels/Label';
import React from 'react';
import styles from 'src/stylesheets/labels/authUserUseCasesLabel.scss';

const NoAuthUserUseCaseLabel = () => (
    <div className={styles.wrapper}>
        <div className={styles.label}>
            <Label state="disabled" />
        </div>
        <div className={classnames(styles.userName, styles.disabled)}>No users</div>
    </div>
);

export default NoAuthUserUseCaseLabel;
