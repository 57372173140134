import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/tokenSearch/tag.scss';

const SearchBarTag = (props) => {
    const { tag, onRemove } = props;
    return (
        <button
          className={styles.tag}
          type="button"
        >
            {tag.name}
            <span
              className={styles.closeButton}
              role="button"
              onClick={() => { onRemove(); }}
            >
                &times;
            </span>
        </button>
    );
};

SearchBarTag.propTypes = {
    tag: customPropTypes.tag.isRequired,
    onRemove: PropTypes.func.isRequired
};

export default SearchBarTag;
