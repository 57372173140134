import Backdrop from 'src/components/modals/layout/Backdrop';
import withIntercomBubbleToggle from 'src/components/modals/layout/withIntercomBubbleToggle';
import _omit from 'lodash/omit';
import { Modal as ModalEngine } from 'react-overlays';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/modal.scss';
import classnames from 'classnames';
import Body from './ModalBody';
import Dialog from './ModalDialog';
import Footer from './ModalFooter';
import Header from './ModalHeader';
import modalManager from './modalManager';
import Title from './ModalTitle';

const renderBackdrop = (props) => <Backdrop {...props} />;

const ModalEngineWithIntercomBubbleToggle = withIntercomBubbleToggle(ModalEngine);

const Modal = (props) => {
    const { dataViewName, withBackDropSupport } = props;
    const optionalProps = {};
    if (dataViewName) {
        optionalProps['data-view-name'] = `modal-${dataViewName}`;
    }
    const cleanedProps = _omit(props, 'dataViewName', 'withBackDropSupport');

    return (
        <ModalEngineWithIntercomBubbleToggle
          manager={modalManager}
          className={classnames({ [styles.modal]: !withBackDropSupport, [styles.modalWithBackDropSupport]: withBackDropSupport })}
          renderBackdrop={renderBackdrop}
          {...optionalProps}
          {...cleanedProps}
        />
    );
};

Modal.propTypes = {
    backdrop: PropTypes.oneOf(['static', true, false]),
    show: PropTypes.bool,
    autoFocus: PropTypes.bool,
    dataViewName: PropTypes.string,
    withBackDropSupport: PropTypes.bool
};

Modal.defaultProps = {
    backdrop: 'static',
    show: true,
    autoFocus: true,
    withBackDropSupport: false
};

Modal.Body = Body;
Modal.Header = Header;
Modal.Title = Title;
Modal.Footer = Footer;
Modal.Dialog = Dialog;

export default Modal;
