import React from 'react';
import _range from 'lodash/range';
import styles from 'src/stylesheets/views/searchedAuthAccountList.scss';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';

const getRandom = (min, max) => Math.round((Math.random() * Math.abs(max - min)) + min);

const Item = () => (
    <div className={styles.wrapper}>
        <div className={styles.left}>
            <EmptyDiv width={getRandom(80, 300)} height={32} />
        </div>
        <div className={styles.right}>
            <div className={styles.authUserState}>
                <EmptyDiv width={300} height={32} />
            </div>
            <div className={styles.buttonWrapper}>
                <ButtonGroup>
                    <EmptyDiv width={120} height={32} />
                </ButtonGroup>
            </div>
        </div>
    </div>
);

const AuthAccountSearchLoadingSkeleton = () => (
    <div>
        {
            _range(0, 10).map((i) => (
                <div className={styles.item} key={`item${i}`}>
                    <Item />
                </div>
            ))
        }
    </div>
);

export default AuthAccountSearchLoadingSkeleton;
