import { connect } from 'react-redux';
import { makeSelectFolderById } from 'src/selectors/folders';
import PropTypes from 'prop-types';
import React from 'react';
import { SUPER_ROOT } from 'src/utils/dashboardFolders';

const FolderName = (props) => {
    const { folderName } = props;
    return <span>{folderName}</span>;
};

FolderName.propTypes = {
    folderName: PropTypes.string.isRequired,
    folderId: PropTypes.string.isRequired
};

const makeMapStateToProps = () => {
    const selectFolderById = makeSelectFolderById();
    return (state, ownProps) => {
        if (ownProps.folderId === SUPER_ROOT) {
            return {
                folderName: 'Dashboards'
            };
        }
        const folder = selectFolderById(state, ownProps.folderId);

        if (folder) {
            return {
                folderName: folder.name
            };
        }
        return {
            folderName: 'undefinded'
        };
    };
};

export default connect(makeMapStateToProps)(FolderName);
