import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { dataPushTaskDryRunRequest } from 'src/actions/dataPushTasks';
import { dryRunInfo } from 'src/utils/dataPushTasks';

const DataPushTaskDryRunModal = (props) => {
    const {
        hideModal,
        enforceFocus
    } = props;

    const handleOnRun = () => {
        const { dataPushTaskId, dataPushTaskDryRunRequestAction } = props;
        dataPushTaskDryRunRequestAction(dataPushTaskId);
        hideModal();
    };

    return (
        <Modal dataViewName="data-push-task-dry-run-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        Dry run
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {dryRunInfo}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          label="Cancel"
                          onClick={hideModal}
                        />
                        <Button
                          label="Perform dry run"
                          action
                          onClick={handleOnRun}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

DataPushTaskDryRunModal.propTypes = {
    dataPushTaskId: PropTypes.string.isRequired,
    dataPushTaskDryRunRequestAction: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    enforceFocus: PropTypes.bool.isRequired
};

export default connect(null, {
    dataPushTaskDryRunRequestAction: dataPushTaskDryRunRequest
})(DataPushTaskDryRunModal);
