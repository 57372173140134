import { connect } from 'react-redux';
import { makeSelectAdAccountsByIds } from 'src/selectors/adAccounts';
import PropTypes from 'prop-types';
import React from 'react';
import EntityOperationInfoModal from 'src/components/modals/EntityOperationInfoModal';
import * as customPropTypes from 'src/customPropTypes';

const ForceDeleteAdAccountModalContainer = (props) => {
    const {
        forceOperationalAdAccountInfos,
        nonDeletableAdAccounts,
        hideModal,
        enforceFocus
    } = props;

    const notRemovedGroupNames = nonDeletableAdAccounts.map((adAccount) => adAccount.defaultName);
    return (
        <EntityOperationInfoModal
          nonOperationalEntityNames={notRemovedGroupNames}
          forceOperationalEntityInfos={forceOperationalAdAccountInfos}
          operationalType="forceAdAccountDelete"
          hideModal={hideModal}
          enforceFocus={enforceFocus}
        />
    );
};

ForceDeleteAdAccountModalContainer.propTypes = {
    hideModal: PropTypes.func.isRequired,
    forceOperationalAdAccountInfos: customPropTypes.forceOperationalEntityInfos.isRequired,
    nonDeletableAdAccounts: customPropTypes.adAccounts.isRequired,
    enforceFocus: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
    const { notRemovedAdAccountIds } = ownProps;
    const selectAdAccountsByIds = makeSelectAdAccountsByIds();
    return {
        nonDeletableAdAccounts: selectAdAccountsByIds(state, notRemovedAdAccountIds)
    };
};

export default connect(mapStateToProps)(ForceDeleteAdAccountModalContainer);
