import AdditionalFilterSelectorComponent from 'src/components/additionalFilter/AdditionalFilterSelector';
import React from 'react';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';

const AdditionalFilterSelector = (props) => {
    const {
        name, value, onChange
    } = props;
    const {
        postTag, postText, postTextExclude, adCampaign
    } = value;

    return (
        <div>
            <AdditionalFilterSelectorComponent
              onApplyClick={onChange}
              postTag={postTag}
              postTextExclude={postTextExclude}
              postText={postText}
              adCampaign={adCampaign}
              showPrefixWhenFilterIsSet={false}
            />
            <input type="hidden" name={name} value={JSON.stringify(value)} />
        </div>
    );
};

AdditionalFilterSelector.propTypes = {
    value: customPropTypes.additionalFilterValues.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired
};

export default AdditionalFilterSelector;
