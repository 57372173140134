import Date from 'src/components/Date';
import UserInvitationBox from 'src/components/userInvitations/UserInvitationBox';
import UserInvitationLinkBox from 'src/components/userInvitations/UserInvitationLinkBox';
import UserInvitationResendButton from 'src/components/userInvitations/UserInvitationResendButton';
import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/userInvitationBox.scss';

const UserInvitationContent = ({ userInvitation, userId }) => (
    <UserInvitationBox cta={<UserInvitationResendButton userId={userId} />}>
        <div className={styles.infoBox}>
            <div>Created at: <Date format="datetime" appendTimezone dateInTimezone={userInvitation.createdAtTimeZone} date={userInvitation.createdAt} /></div>
            <div>Expires at: <Date format="datetime" appendTimezone dateInTimezone={userInvitation.expiresAtTimeZone} date={userInvitation.expiresAt} /></div>
            <div>Invited by: {userInvitation.inviter}</div>
        </div>
        <UserInvitationLinkBox url={userInvitation.invitationUrl} />
        <div className={styles.warning}>This unique invite link may only be used by this member.</div>
    </UserInvitationBox>
);

UserInvitationContent.propTypes = {
    userInvitation: customPropTypes.userInvitation.isRequired,
    userId: PropTypes.string.isRequired
};

export default UserInvitationContent;
