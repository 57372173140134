import { loadUserInvitationRequest } from 'src/actions/userInvitations';
import EmptyInvitationBox from 'src/components/loadingIndicators/EmptyInvitationBox';
import UserInvitationContent from 'src/components/userInvitations/UserInvitationContent';
import UserInvitationError from 'src/components/userInvitations/UserInvitationError';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import { selectUserInvitationAsyncStatesById, selectUserInvitationById } from 'src/selectors/userInvitations';

const UserInvitationLoader = ({
    invitationCode, userId, loadUserInvitationRequestAction, userInvitationAsyncState, userInvitation
}) => {
    useEffect(() => {
        loadUserInvitationRequestAction(invitationCode, userId);
    }, [invitationCode]);

    if (userInvitationAsyncState.isPending) {
        return <EmptyInvitationBox />;
    }
    if (userInvitation) {
        return (<UserInvitationContent userInvitation={userInvitation} userId={userId} />);
    }
    if (userInvitationAsyncState.error) {
        return <UserInvitationError userId={userId} error={userInvitationAsyncState.error} />;
    }

    return null;
};

UserInvitationLoader.propTypes = {
    invitationCode: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    loadUserInvitationRequestAction: PropTypes.func.isRequired,
    userInvitation: customPropTypes.userInvitation,
    userInvitationAsyncState: customPropTypes.asyncDataLoadingState.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    userInvitationAsyncState: selectUserInvitationAsyncStatesById(state, ownProps.invitationCode),
    userInvitation: selectUserInvitationById(state, ownProps.invitationCode)
});

export default connect(mapStateToProps, {
    loadUserInvitationRequestAction: loadUserInvitationRequest,

})(UserInvitationLoader);
