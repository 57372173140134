import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import { createSelector } from 'reselect';
import {
    defaultLoadingState,
    makeOrderBySorter,
    makePropertyExtractor,
    makeSearchQueryFilter,
    makeStringSorter
} from './utils';

export const getGroupIdsFromStore = (state) => state.entities.groups.allIds;

export const getGroupsFromStore = (state) => state.entities.groups.byId;

const getGroupsAsyncStatesFromStore = (state) => state.entities.groups.asyncStatesByAction;

const allPagesOnTop = (groups) => {
    const allPagesIndex = _findIndex(groups, (group) => group.id === '0');
    if (allPagesIndex < 0) {
        return groups;
    }
    const allPages = groups[allPagesIndex];
    const copyWithoutAllPages = groups.slice();
    copyWithoutAllPages.splice(allPagesIndex, 1);
    copyWithoutAllPages.unshift(allPages);
    return copyWithoutAllPages;
};

export const selectGroups = createSelector(
    [
        getGroupIdsFromStore,
        getGroupsFromStore
    ],
    (groupIds, groupsFromStore) => {
        const groups = groupIds.map((id) => groupsFromStore[id]);
        groups.sort(makeStringSorter('name', 'asc'));
        return allPagesOnTop(groups);
    }
);

export const selectUserCreatedGroups = createSelector(
    [
        getGroupIdsFromStore,
        getGroupsFromStore
    ],
    (groupIds, groups) => {
        const userCreatedGroups = [];
        groupIds.forEach((id) => {
            if (id !== '0') {
                userCreatedGroups.push(groups[id]);
            }
        });
        userCreatedGroups.sort(makeStringSorter('name', 'asc'));
        return userCreatedGroups;
    }
);

export const makeSelectUserCreatedGroupsIds = () => {
    const propertyExtractor = makePropertyExtractor('id');
    return (state) => {
        const userCreatedGroups = selectUserCreatedGroups(state);
        return propertyExtractor(userCreatedGroups);
    };
};

export const makeSelectGroupById = () => createSelector(
    [
        getGroupsFromStore,
        (_, id) => id
    ],
    (groups, id) => groups[id] || false
);

export const makeSelectGroupsByIds = () => createSelector(
    [
        getGroupsFromStore,
        (_, groupIds) => groupIds
    ],
    (groups, groupIds) => groupIds.map((id) => groups[id])
);

export const makeSelectGroupsFilteredByQuery = () => {
    const searchQueryFilter = makeSearchQueryFilter();
    const orderBySorter = makeOrderBySorter();
    return createSelector(
        [
            selectGroups,
            (_, filterQuery) => filterQuery
        ],
        (groups, filterQuery) => {
            const filtered = searchQueryFilter(groups, filterQuery);
            const ordered = orderBySorter(filtered);
            return allPagesOnTop(ordered);
        }
    );
};

export const makeSelectIsGroupEditing = () => createSelector(
    [
        (state) => getGroupsAsyncStatesFromStore(state).edit,
        (_, id) => id
    ],
    (asyncStates, id) => _get(asyncStates, [id, 'isPending'], false)
);
export const selectIsAddRemoveGroupsState = createSelector(
    [
        getGroupsAsyncStatesFromStore
    ],
    (asyncStates) => _get(asyncStates, ['addRemoveGroups', 'state'], defaultLoadingState)
);
