import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import styles from 'src/stylesheets/plainList.scss';

const PlainList = ({ children }) => (
    <ul className={styles.list}>
        {
            React.Children.map(children, (listItem) => {
                if (listItem) {
                    return <li className={styles.listItemWrapper}>{listItem}</li>;
                }
                return null;
            })
        }
    </ul>
);

PlainList.propTypes = {
    children: customPropTypes.children.isRequired,
};

export default PlainList;
