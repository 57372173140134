import React from 'react';
import styles from 'src/stylesheets/views/postTags/bindingList.scss';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import Feedback from 'src/components/feedback/Feedback';
import BindingList from 'src/components/bindings/BindingList';

const MultipleEntityBindingList = (props) => {
    const { forceOperationalEntityInfos, label } = props;
    const content = (
        <div>
            <div className={styles.description}>{`The following ${label}s have bindings to metrics in a dashboard, automated report, link export or auto-tag rule.`}</div>
            {
                forceOperationalEntityInfos.map((forceOperationalEntityInfo) => (
                    <div className={styles.tags} key={forceOperationalEntityInfo.name}>
                        <b>{forceOperationalEntityInfo.name}</b>
                        <BindingList forceOperationalEntityInfo={forceOperationalEntityInfo} />
                    </div>
                ))
            }
        </div>
    );

    return <Feedback content={content} type="warning" />;
};

MultipleEntityBindingList.propTypes = {
    label: PropTypes.string.isRequired,
    forceOperationalEntityInfos: customPropTypes.forceOperationalEntityInfos.isRequired
};

export default MultipleEntityBindingList;
