import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as profileSearchActionCreators from 'src/actions/profileSearch';
import FailedAccountView from 'src/components/account/FailedAccountView';

const FailedSearchedProfilesView = (props) => {
    const { links, onRetryClick } = props;
    const profileLabel = links.length > 1 ? 'profiles' : 'profile';

    const handleOnRetry = () => {
        const { profileSearchByUrlsRequestAction } = props;
        profileSearchByUrlsRequestAction(links.join('\n'));
        onRetryClick();
    };
    return <FailedAccountView links={links} onRetryClick={handleOnRetry} label={profileLabel} />;
};

FailedSearchedProfilesView.propTypes = {
    links: PropTypes.arrayOf(PropTypes.string).isRequired,
    profileSearchByUrlsRequestAction: PropTypes.func.isRequired,
    onRetryClick: PropTypes.func.isRequired
};

export default connect(null, {
    profileSearchByUrlsRequestAction: profileSearchActionCreators.profileSearchByUrlsRequest
})(FailedSearchedProfilesView);
