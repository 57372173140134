import Button from 'src/components/buttons/Button';
import buttonStyles from 'src/stylesheets/button.scss';
import logoGoogle from 'src/resources/icons/logo-google.png';
import PropTypes from 'prop-types';
import React from 'react';

const GoogleConnectButton = (props) => {
    const {
        disabled,
        loading,
        onClick
    } = props;
    const iconImage = !loading ? logoGoogle : null;

    return (
        <Button
          label="Continue with Google"
          iconImage={iconImage}
          small
          className={buttonStyles.google}
          disabled={disabled}
          loading={loading}
          onClick={onClick}
        />
    );
};

GoogleConnectButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool
};

GoogleConnectButton.defaultProps = {
    disabled: false,
    loading: false
};

export default GoogleConnectButton;
