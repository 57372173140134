import * as customPropTypes from 'src/customPropTypes';
import { Field } from 'redux-form';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'src/components/forms/fields';
import withReduxForm from 'src/components/forms/withReduxForm';
import { required, makeLengthValidator } from 'src/components/forms/validators';

const postTagNameLenghtValidator = makeLengthValidator(1, 30);

const PostTagAddForm = (props) => {
    const { handleSubmit, error } = props;
    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <Field
              autoFocus
              name="name"
              label="Tag name"
              component={Text}
              validate={[required, postTagNameLenghtValidator]}
            />
        </form>
    );
};

PostTagAddForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: customPropTypes.dataLoadingError
};

export default withReduxForm(PostTagAddForm);
