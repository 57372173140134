import Popover from 'src/components/popovers/layout/Popover';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import InlineListWithSearchbar from 'src/components/inlineList/InlineListWithSearchbar';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styles from 'src/stylesheets/popovers/addGroupsToProfilePopover.scss';
import { getListOption } from 'src/utils/postTag';
import { makeSelectActivePostTags } from 'src/selectors/postTags';
import { makeSelectPostById, selectAddRemovePostTagsState } from 'src/selectors/posts';
import { addRemovePostTagsFromPostRequest, cleanupAddRemovePostTagsFromPostsState } from 'src/actions/posts';
import { postTagAddRequest } from 'src/actions/postTags';
import { renderEmptyActiveTags } from 'src/components/modals/AddOrRemovePostTagsFromPostsModal';
import { makeLengthValidator } from 'src/components/inlineList/validators';

const postTagNameLengthValidator = makeLengthValidator('tag', 1, 30);

const ManageTagsOfPostPopover = (props) => {
    const {
        postId,
        post,
        activePostTags,
        addRemovePostTagState
    } = props;
    const { isPending } = addRemovePostTagState;

    useEffect(() => {
        const { isPending: pending, success, requested } = addRemovePostTagState;
        const { hidePopover, cleanupAddRemovePostTagsFromPostsStateAction } = props;
        if (requested && !pending && success) {
            cleanupAddRemovePostTagsFromPostsStateAction();
            hidePopover();
        }
    }, [addRemovePostTagState]);

    const { id, postPostTags } = post;

    const options = getListOption(activePostTags);

    const handleOnApplyClick = (currentSelectedOptions) => {
        const { addRemovePostTagsFromPostRequestAction, onApplyClick, performPostSearch } = props;
        addRemovePostTagsFromPostRequestAction(id, currentSelectedOptions, performPostSearch);
        onApplyClick();
    };

    const handleOnCreateNewTag = (newTag) => {
        const { postTagAddRequestAction } = props;
        postTagAddRequestAction({ name: newTag });
    };

    const selectedPostTagIds = Object.keys(postPostTags);

    return (
        <Popover id="addPostTagsToPostsPopover" width={360} height={500}>
            <div style={styles} className={styles.wrapper}>
                <div className={styles.body}>
                    <InlineListWithSearchbar
                      options={options}
                      optionName="tag"
                      entityIds={[postId]}
                      selectedOptionIds={selectedPostTagIds}
                      renderEmptyOptions={renderEmptyActiveTags}
                      applyAction={handleOnApplyClick}
                      createNewEntityAction={handleOnCreateNewTag}
                      isApplyActionPending={isPending}
                      validateCreateNewOption={postTagNameLengthValidator}
                    />
                </div>
            </div>
        </Popover>
    );
};

ManageTagsOfPostPopover.propTypes = {
    postId: PropTypes.string.isRequired,
    post: customPropTypes.post.isRequired,
    activePostTags: customPropTypes.postTags.isRequired,
    postTagAddRequestAction: PropTypes.func.isRequired,
    addRemovePostTagsFromPostRequestAction: PropTypes.func.isRequired,
    addRemovePostTagState: customPropTypes.asyncDataLoadingState.isRequired,
    hidePopover: PropTypes.func.isRequired,
    cleanupAddRemovePostTagsFromPostsStateAction: PropTypes.func.isRequired,
    onApplyClick: PropTypes.func.isRequired,
    performPostSearch: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectActivePostTags = makeSelectActivePostTags();
    const selectPostById = makeSelectPostById();
    return (state, ownProps) => {
        const { postId } = ownProps;
        return {
            post: selectPostById(state, postId),
            activePostTags: selectActivePostTags(state),
            addRemovePostTagState: selectAddRemovePostTagsState(state)
        };
    };
};

export default connect(makeMapStateToProps, {
    postTagAddRequestAction: postTagAddRequest,
    cleanupAddRemovePostTagsFromPostsStateAction: cleanupAddRemovePostTagsFromPostsState,
    addRemovePostTagsFromPostRequestAction: addRemovePostTagsFromPostRequest
})(ManageTagsOfPostPopover);
