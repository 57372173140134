import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/authTransactions/authTransactionRequirementsTable.scss';
import UseCaseDescriptionPopover from 'src/components/popovers/UseCaseDescriptionPopover';
import withPopover from 'src/components/withPopover';
import { MAX_NUMBER_OF_COLUMNS } from 'src/utils/accountInsights';
import { getNetworkName } from 'src/utils/profiles';
import ListTableRow from 'src/components/listTable/ListTableRow';
import ListTableCell from 'src/components/listTable/ListTableCell';
import EmptyCells from 'src/components/listTable/EmptyCells';

const IcomoonIconWithPopover = withPopover(IcomoonIcon);

const PlatformUseCasesTableHeader = (props) => {
    const {
        platformUseCases,
        platformType,
        showUseCaseDetail,
        connectButton
    } = props;
    const emptyColumnLength = MAX_NUMBER_OF_COLUMNS - platformUseCases.length;
    return (
        <thead>
            <ListTableRow>
                <ListTableCell isHeaderCell padded={false} isHeaderViewLayout={false} className={classnames(styles.bottomBorder, styles.networkName, styles.paddedHeader)}>
                    {getNetworkName(platformType)}
                </ListTableCell>
                {
                    platformUseCases.map((useCase) => (
                        <ListTableCell key={useCase.id} padded={false} isHeaderCell isHeaderViewLayout={false} align="center" className={classnames(styles.bottomBorder, styles.paddedHeader)}>
                            {
                                    showUseCaseDetail
                                    && (
                                    <div className={styles.useCaseInfo}>
                                        <IcomoonIconWithPopover
                                          icon="info"
                                          placement="top"
                                          className={styles.useCaseInfoIcon}
                                          overlay={<UseCaseDescriptionPopover useCaseId={useCase.id} />}
                                        />
                                    </div>
                                    )
                                }
                            <div>
                                <DivWithTooltip tooltip={useCase.name}>
                                    {useCase.name}
                                </DivWithTooltip>
                            </div>
                        </ListTableCell>
                    ))
                }
                {
                    emptyColumnLength > 0 && <EmptyCells emptyColumnLength={emptyColumnLength} className={styles.bottomBorder} padded isHeader />
                }
                {
                    connectButton && (
                        <ListTableCell className={classnames(styles.bottomBorder, styles.paddedHeader)} padded={false}>
                            {typeof connectButton === 'object' && (
                                connectButton
                            )}
                        </ListTableCell>
                    )
                }
            </ListTableRow>
        </thead>
    );
};

PlatformUseCasesTableHeader.propTypes = {
    platformUseCases: customPropTypes.useCases.isRequired,
    platformType: customPropTypes.platformTypes.isRequired,
    showUseCaseDetail: PropTypes.bool,
    connectButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.node])
};

PlatformUseCasesTableHeader.defaultProps = {
    showUseCaseDetail: false,
    connectButton: false
};

export default PlatformUseCasesTableHeader;
