import { makeSelectAuthTransactionById, selectClosingState } from 'src/selectors/authTransactions';
import React, { useEffect } from 'react';
import * as authTransactionActions from 'src/actions/authTransactions';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import Feedback from 'src/components/feedback/Feedback';
import Modal from 'src/components/modals/layout/Modal';
import PropTypes from 'prop-types';

const AuthTransactionCloseModal = (props) => {
    const {
        hideModal,
        closingState,
        enforceFocus
    } = props;

    useEffect(() => {
        const { id, cleanCloseAuthTransactionStateAction } = props;
        return () => {
            cleanCloseAuthTransactionStateAction(id);
        };
    }, []);

    const handleAuthTransactionClose = () => {
        const { authTransaction, closeAuthTransactionRequestAction } = props;
        closeAuthTransactionRequestAction(authTransaction.id);
    };
    const { isPending, error } = closingState;

    return (
        <Modal dataViewName="auth-transaction-close-modal" onHide={hideModal} enforceFocus={enforceFocus}>
            <Modal.Dialog>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        End authentication
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        Make sure that the authentication is as complete as possible. Ended authentications can be viewed in your authentications list.
                    </span>
                    <div>
                        {
                            (!isPending && error)
                            && <Feedback content={error} type="error" />
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                          label="Cancel"
                          disabled={isPending}
                          onClick={hideModal}
                        />
                        <Button
                          action
                          label="End Authentication"
                          loading={isPending}
                          onClick={handleAuthTransactionClose}
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

AuthTransactionCloseModal.propTypes = {
    authTransaction: customPropTypes.authTransaction.isRequired,
    closeAuthTransactionRequestAction: PropTypes.func.isRequired,
    cleanCloseAuthTransactionStateAction: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    closingState: customPropTypes.asyncReducerState,
    enforceFocus: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired
};

AuthTransactionCloseModal.defaultProps = {
    closingState: {}
};

const makeMapStateToProps = () => {
    const selectAuthTransactionById = makeSelectAuthTransactionById();
    return (state, ownProps) => {
        const { id } = ownProps;
        const authTransaction = selectAuthTransactionById(state, id);
        const closingState = selectClosingState(state, id);

        return {
            authTransaction,
            closingState
        };
    };
};

export default connect(makeMapStateToProps, {
    closeAuthTransactionRequestAction: authTransactionActions.closeAuthTransactionRequest,
    cleanCloseAuthTransactionStateAction: authTransactionActions.cleanCloseAuthTransactionState
})(AuthTransactionCloseModal);
