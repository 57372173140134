import { validateNumberOfLinks } from 'src/components/forms/validators';
import { Field, formValueSelector } from 'redux-form';
import React, { useEffect, useState } from 'react';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/profileSearchInputForm.scss';
import withReduxForm from 'src/components/forms/withReduxForm';
import * as profileSearchActionCreators from 'src/actions/profileSearch';
import { getProfileSearchIsPending } from 'src/selectors/profileSearch';
import SocialNetworkAuthenticatedSearchOptions from 'src/components/views/profiles/search/SocialNetworkAuthenticatedSearchOptions';
import AvailableConnectedProfiles from 'src/components/views/profiles/search/AvailableConnectedProfiles';
import { getLines, getNewLinesWithoutEmptyStrings } from 'src/utils/array';
import { TextWithResetIcon, TextAreaWithResetIcon } from 'src/components/forms/fields';

const validateProfileLinkSize = validateNumberOfLinks(100);

const ProfileSearchInputForm = (props) => {
    const [isUrlSearch, toggleMultiSearch] = useState(false);
    const {
        isLoading, input, isResultPage, invalid, change
    } = props;
    const hasInput = !!input && input.length > 0;

    useEffect(() => {
        const lines = getLines(input);
        if (lines.length <= 1) {
            toggleMultiSearch(false);
        } else if (!isUrlSearch) {
            toggleMultiSearch(true);
        }
    }, [input]);

    const handleOnClearIconClick = () => {
        const { profileSearchResetInputAction } = props;
        if (isResultPage) {
            profileSearchResetInputAction();
        } else {
            change('input', '');
        }
    };

    const handleOnSingleInputPaste = (e) => {
        const content = e.clipboardData.getData('text');
        const lines = getLines(content);
        if (lines.length > 1) {
            toggleMultiSearch(true);
        }
    };

    const handleOnSingleInputKeyDown = (e) => {
        if (e.shiftKey && e.keyCode === 13) {
            setTimeout(() => {
                toggleMultiSearch(true);
                change('input', `${input}\n`);
            }, 10);
            e.preventDefault();
        }
    };

    const performSearch = () => {
        if (!invalid && input.trim() !== '') {
            const { profileSearchByKeywordRequestAction, profileSearchByUrlsRequestAction } = props;
            if (isUrlSearch) {
                profileSearchByUrlsRequestAction(input.trim());
            } else {
                profileSearchByKeywordRequestAction(input.trim());
            }
        }
    };

    const handleOnMultiUrlInputKeyDown = (e) => {
        if (!e.shiftKey && e.keyCode === 13) {
            performSearch();
            e.preventDefault();
        }
    };

    const handleSubmit = (e) => {
        performSearch();
        e.preventDefault();
    };

    const numberOfURLs = getNewLinesWithoutEmptyStrings(input).length;
    const rightInfo = numberOfURLs > 0 ? `${numberOfURLs} URL${numberOfURLs > 1 ? 's' : ''}` : null;

    return (
        <div>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.input}>
                    {
                        !isUrlSearch && (
                            <Field
                              autoFocus
                              name="input"
                              component={TextWithResetIcon}
                              info="Shift + Return to add a new line"
                              layout="profileSearch"
                              placeholder="Search by profile name or paste up to 100 profile URLs"
                              disabled={isLoading}
                              onPaste={handleOnSingleInputPaste}
                              onKeyDown={handleOnSingleInputKeyDown}
                              onResetClick={handleOnClearIconClick}
                            />
                        )
                    }
                    {
                        isUrlSearch && (
                            <Field
                              autoFocus
                              name="input"
                              component={TextAreaWithResetIcon}
                              layout="profileSearch"
                              bellowRightInfo={rightInfo}
                              verticalResize
                              info="Shift + Return to add a new line"
                              placeholder="Search by profile name or paste up to 100 profile URLs"
                              disabled={isLoading}
                              onResetClick={handleOnClearIconClick}
                              onKeyDown={handleOnMultiUrlInputKeyDown}
                              validate={validateProfileLinkSize}
                              height={330}
                            />
                        )
                    }
                </div>
                <div className={styles.button}>
                    <Button type="submit" label="Search" action disabled={invalid || isLoading || !hasInput} />
                </div>
            </form>
            {
                !isResultPage && (
                    <div>
                        <div className={styles.nonResultPage}>
                            {
                                !isUrlSearch && (
                                    <SocialNetworkAuthenticatedSearchOptions hideFacebookButton={isUrlSearch} />
                                )
                            }
                        </div>
                        <AvailableConnectedProfiles />
                    </div>
                )
            }
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector(ownProps.form);
    return {
        isLoading: getProfileSearchIsPending(state),
        input: selector(state, 'input')
    };
};

ProfileSearchInputForm.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    profileSearchResetInputAction: PropTypes.func.isRequired,
    profileSearchByKeywordRequestAction: PropTypes.func.isRequired,
    profileSearchByUrlsRequestAction: PropTypes.func.isRequired,
    input: PropTypes.string,
    change: PropTypes.func.isRequired,
    isResultPage: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired
};

export default withReduxForm(connect(mapStateToProps, {
    profileSearchByKeywordRequestAction: profileSearchActionCreators.profileSearchByKeywordRequest,
    profileSearchByUrlsRequestAction: profileSearchActionCreators.profileSearchByUrlsRequest,
    profileSearchResetInputAction: profileSearchActionCreators.profileSearchResetInput
})(ProfileSearchInputForm), {
    form: 'searchProfilesForm',
    touchOnChange: true,
    enableReinitialize: true
});
