import {
    Checkbox,
    ColorPicker,
    Text
} from 'src/components/forms/fields';
import { Field } from 'redux-form';
import { hexValue, makeLengthValidator } from 'src/components/forms/validators';
import React from 'react';

const appendixValid = makeLengthValidator(0, 30);

const CustomizeProfile = () => (
    <div>
        <Field
          name="appendix"
          label="Profile name appendix"
          component={Text}
          validate={appendixValid}
        />
        <Field
          name="color"
          label="Custom color in charts"
          component={ColorPicker}
          validate={[hexValue]}
        />
        <Field
          name="favorite"
          text="Highlight this profile in line graphs."
          component={Checkbox}
        />
    </div>
);

export default CustomizeProfile;
