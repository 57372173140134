import { orderPlatformTypesByUsage } from 'src/selectors/utils';
import _memoize from 'memoize-one';
import _get from 'lodash/get';

export const getPlatformTypesFromAccounts = _memoize((accounts) => {
    const platformTypes = [];
    accounts.forEach((account) => {
        platformTypes.push(account.platformType);
    });
    return orderPlatformTypesByUsage(platformTypes);
});

export const getUsageFromSpaceLimit = (spaceLimits, identifier) => _get(spaceLimits, ['usage', identifier], 'NaN');
export const getLimitFromSpaceLimit = (spaceLimits, identifier) => _get(spaceLimits, ['limits', identifier], 'NaN');
