import PropTypes from 'prop-types';
import React from 'react';
import {
    DynamicDateSelector, TimezoneSelect, Select, MultiSelect, Text, AdvancedReportScheduler
} from 'src/components/forms/fields';
import { Field, formValueSelector, change } from 'redux-form';
import styles from 'src/stylesheets/forms/dataPushTaskForm.scss';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { required, validateAColumnName } from 'src/components/forms/validators';
import {
    selectDataSourceById, makeSelectDataSourceColumnsByDataSourceId, makeSelectDataSources
} from 'src/selectors/dataSources';
import _get from 'lodash/get';
import { dataPushTaskDataSourceType, getDataSourceOptionsForDataPushTasks } from 'src/utils/dataPushTasks';
import memoize from 'memoize-one';
import { selectDataPushTaskNetworks } from 'src/selectors/client';
import _isArray from 'lodash/isArray';

const memoizedGetDataSourceOptionsForDataPushTasks = memoize(getDataSourceOptionsForDataPushTasks);

const validateDataSourceColumns = (value) => {
    if (!value || value.length === 0) {
        return 'Please select columns.';
    }
    return undefined;
};

const getOptions = (dataSourceColumns) => dataSourceColumns.map(({ id, isDeprecated, nestedIntervalSpecificColumns }) => {
    if (isDeprecated === false) {
        const parentOption = [(
            <option
              key={id}
              value={id}
              disabled={isDeprecated}
            >
                {id}
            </option>
        )];

        if (_isArray(nestedIntervalSpecificColumns) && nestedIntervalSpecificColumns.length > 0) {
            nestedIntervalSpecificColumns.forEach((nestedColumn) => {
                parentOption.push(
                    <option
                      key={nestedColumn}
                      value={nestedColumn}
                      disabled={isDeprecated}
                    >
                        {nestedColumn}
                    </option>
                );
            });
        }

        return parentOption;
    }

    return null;
});

const validateScheduleTime = (value) => (_get(value, 'cronExpression', '') === '' ? 'Schedule time can not be empty' : undefined);

const DataPushTaskEditFields = (props) => {
    const {
        dataSources, allowedNetworksForDataPushTask, existingDataSourceNames, selectedDataSource, dataSourceColumns, dataPushTaskType
    } = props;
    const filteredDataSources = memoizedGetDataSourceOptionsForDataPushTasks(dataSources, allowedNetworksForDataPushTask, existingDataSourceNames);
    const handleOnChange = () => {
        const { changeAction, form } = props;
        changeAction(form, 'dataSourceColumns', []);
    };

    return (
        <div>
            <div className={styles.info}>
                <div className={styles.title}>What data would you like to push?</div>
                <div className={styles.description}>For each data push task, you can select one data source and any number of it’s columns.</div>
            </div>
            {
                dataPushTaskType === dataPushTaskDataSourceType && (
                    <>
                        <Field
                          name="dataSource"
                          component={Select}
                          label="Data source"
                          validate={required}
                          onChange={handleOnChange}
                        >
                            <option value="">Select a data source</option>
                            {
                                filteredDataSources.map(({ name, isDeprecated, isAllowed }) => (
                                    <option
                                      key={name}
                                      value={name}
                                      disabled={isDeprecated || !isAllowed}
                                    >
                                        {
                                            isDeprecated && `${name} (deprecated) ${isAllowed ? '' : '(Not allowed)'}`
                                        }
                                        {
                                            (!isDeprecated) && `${name} ${isAllowed ? '' : '(Not allowed)'}`
                                        }
                                    </option>
                                ))
                            }
                        </Field>
                        <Field
                          name="dataSourceColumns"
                          component={MultiSelect}
                          label="Columns"
                          multiple
                          validate={validateDataSourceColumns}
                          info="Ctrl/Cmd + click to add multiple columns"
                        >
                            {
                               getOptions(dataSourceColumns)
                            }
                        </Field>
                    </>
                )
            }
            <div className={styles.dateWrapper}>
                <div className={styles.dynamicDate}>
                    <Field
                      autoFocus
                      name="dynamicDate"
                      component={DynamicDateSelector}
                      label="Date range"
                      validate={required}
                      rightCurved={false}
                    />
                </div>
                <div className={styles.timezone}>
                    <Field
                      name="timezone"
                      component={TimezoneSelect}
                      label="Time zone"
                      validate={required}
                    />
                </div>
                <Field
                  name="interval"
                  component={Select}
                  label="Interval"
                  disabled={selectedDataSource ? selectedDataSource.isTimeSeries === false : false}
                >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="total">Total</option>
                </Field>
            </div>
            <Field
              name="dataPushTimeColumnName"
              component={Text}
              label="Push time column"
              validate={[required, validateAColumnName]}
            />
            <div className={styles.scheduleTime}>
                <div className={styles.info}>
                    <div className={styles.title}>When would you like your data to be pushed?</div>
                    <div className={styles.description}>Create a rule for how often you would like this data push task to be triggered using our cron expression builder.</div>
                </div>
                <Field
                  name="deliveryCronExpression"
                  title="Schedule time"
                  component={AdvancedReportScheduler}
                  validate={validateScheduleTime}
                  timezoneDisabled
                />
            </div>
        </div>
    );
};

DataPushTaskEditFields.propTypes = {
    form: PropTypes.string.isRequired,
    dataSourceColumns: customPropTypes.dataSourceColumns.isRequired,
    dataPushTaskType: customPropTypes.dataPushTaskType.isRequired,
    dataSources: customPropTypes.dataSources.isRequired,
    selectedDataSource: PropTypes.oneOfType([customPropTypes.dataSource, PropTypes.bool]).isRequired,
    changeAction: PropTypes.func.isRequired,
    existingDataSourceNames: PropTypes.arrayOf(PropTypes.string),
    allowedNetworksForDataPushTask: PropTypes.arrayOf(PropTypes.string)
};

DataPushTaskEditFields.defaultProps = {
    existingDataSourceNames: []
};

const makeMapStateToProps = () => {
    const selectDataSourceColumnsByDataSourceId = makeSelectDataSourceColumnsByDataSourceId();
    const selectDataSources = makeSelectDataSources();

    return (state, ownProps) => {
        const selector = formValueSelector(ownProps.form);
        const selectedDataSourceId = selector(state, 'dataSource');
        return {
            selectedDataSource: selectDataSourceById(state, selectedDataSourceId),
            dataSourceColumns: selectDataSourceColumnsByDataSourceId(state, selectedDataSourceId),
            dataSources: selectDataSources(state),
            allowedNetworksForDataPushTask: selectDataPushTaskNetworks(state)
        };
    };
};

export default connect(makeMapStateToProps, {
    changeAction: change
})(DataPushTaskEditFields);
