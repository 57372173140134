import React, { Component } from 'react';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import sizeMe from 'react-sizeme';
import styles from 'src/stylesheets/expandableContent.scss';
import classnames from 'classnames';

const SizeAwareContent = sizeMe({ monitorHeight: true, noPlaceholder: true })((props) => <div>{props.renderContent()}</div>);

class ExpandableContent extends Component {
    constructor(props) {
        super(props);
        this.expandContent = this.expandContent.bind(this);
        this.onSize = this.onSize.bind(this);
        this.state = {
            expanded: false,
            expandable: true,
            imageHeight: props.minHeight
        };
    }

    onSize(size) {
        const { props } = this;
        const { minHeight } = props;
        const { height, width } = size;
        if (height > 0 && width > 0) {
            if (height > minHeight) {
                this.setState({
                    expandable: true,
                    imageHeight: height
                });
            } else {
                this.setState({
                    expandable: false,
                    expanded: false,
                    imageHeight: height
                });
            }
        }
    }

    expandContent() {
        const { expanded } = this.state;
        this.setState({
            expanded: !expanded
        });
    }

    render() {
        const { minHeight, renderContent, expandButtonClassName } = this.props;
        const { expanded, imageHeight, expandable } = this.state;
        const label = expanded ? 'View less' : 'View more';
        const icon = expanded ? 'chevron-big-up' : 'chevron-big-down';
        const calculatedMinHeight = imageHeight < minHeight ? imageHeight : minHeight;
        const style = !expanded ? { width: '100%', maxHeight: calculatedMinHeight, minHeight: calculatedMinHeight } : { width: '100%', maxHeight: imageHeight + 500 };

        return (
            <div className={styles.wrapper}>
                <div className={styles.body} style={style}>
                    <SizeAwareContent
                      className={styles.content}
                      onSize={this.onSize}
                      renderContent={renderContent}
                    />
                </div>
                {
                    expandable
                    && (
                        <div className={classnames(styles.expander, expandButtonClassName)}>
                            <div className={styles.button} onClick={this.expandContent}>
                                <IcomoonIcon icon={icon} className={styles.icon} />
                                <span className={styles.buttonLabel}>{label}</span>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

ExpandableContent.propTypes = {
    minHeight: PropTypes.number,
    renderContent: PropTypes.func.isRequired,
    expandButtonClassName: PropTypes.string
};

ExpandableContent.defaultProps = {
    minHeight: 400,
    expandButtonClassName: ''
};

export default ExpandableContent;
