import ProfileHealthChart from 'src/components/profileHealth/ProfileHealthChart';
import highchartsChartViewFactory from 'src/components/chartViews/highcharts/highchartsChartViewFactory';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import withFlexDimensionProvider from 'src/components/withFlexDimensionProvider';
import PropTypes from 'prop-types';

const ProfileHealthChartView = highchartsChartViewFactory(ProfileHealthChart);

ProfileHealthChartView.propTypes = {
    profileHealthMetricData: customPropTypes.profileHealthMetricData.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
    accountOptions: selectAccountOptions(state)
});

export default connect(mapStateToProps)(withFlexDimensionProvider(ProfileHealthChartView, true, true));
