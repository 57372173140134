import React, { useEffect, useState } from 'react';
import * as authUserActions from 'src/actions/authUsers';
import * as customPropTypes from 'src/customPropTypes';
import * as graphAuthUserActions from 'src/actions/graphAuthUsers';
import classnames from 'classnames';
import { connect } from 'react-redux';
import GraphConnectButtonAndState from 'src/components/authTransactions/GraphConnectButtonAndState';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/authTransactions/authTransactionRequirementsTable.scss';
import AuthTransactionRequirementsTable from 'src/components/authTransactions/table/AuthTransactionRequirementsTable';
import _memoize from 'memoize-one';

const GraphAuthTransactionRequirementsConnect = (props) => {
    useEffect(() => {
        const { clearAuthUserConnectStateFromReducerAction, authTransaction } = props;
        return () => {
            clearAuthUserConnectStateFromReducerAction(`fb_${authTransaction.id}`);
        };
    }, []);
    const [hovered, changeHoverState] = useState(false);
    const {
        metaAuthTransactionRequirementsByAdAccountId,
        facebookAuthTransactionRequirementsByProfileId,
        instagramAuthTransactionRequirementsByProfileId,
        authTransaction,
        isPublic,
        showOnlyBasicInsightsUseCases
    } = props;
    const handleOnMountEnter = () => {
        changeHoverState(true);
    };

    const handleOnMountLeave = () => {
        changeHoverState(false);
    };

    const handleFacebookConnectRequest = (identifier, authTransactionId) => {
        const { facebookAuthUserConnectRequestAction, facebookAuthUserExternalConnectRequestAction } = props;
        if (isPublic) {
            facebookAuthUserExternalConnectRequestAction(authTransaction.hash, identifier, authTransactionId, facebookAuthTransactionRequirementsByProfileId, instagramAuthTransactionRequirementsByProfileId, metaAuthTransactionRequirementsByAdAccountId);
        } else {
            facebookAuthUserConnectRequestAction(identifier, authTransactionId, facebookAuthTransactionRequirementsByProfileId, instagramAuthTransactionRequirementsByProfileId, metaAuthTransactionRequirementsByAdAccountId);
        }
    };

    const renderConnectButton = () => (
        <div onMouseEnter={handleOnMountEnter} onMouseLeave={handleOnMountLeave}>
            <GraphConnectButtonAndState
              authTransaction={authTransaction}
              connectAction={handleFacebookConnectRequest}
            />
        </div>
    );

    const facebookProfileIds = Object.keys(facebookAuthTransactionRequirementsByProfileId);
    const instagramProfileIds = Object.keys(instagramAuthTransactionRequirementsByProfileId);
    const metaAdAccountIds = Object.keys(metaAuthTransactionRequirementsByAdAccountId);

    const getConnectButtonByAccountId = _memoize(() => {
        const buttonByAccountId = [];
        if (facebookProfileIds.length > 0) {
            buttonByAccountId[facebookProfileIds[0]] = renderConnectButton();
            return buttonByAccountId;
        }
        if (instagramProfileIds.length > 0) {
            buttonByAccountId[instagramProfileIds[0]] = renderConnectButton();
            return buttonByAccountId;
        }
        if (metaAdAccountIds.length > 0) {
            buttonByAccountId[metaAdAccountIds[0]] = renderConnectButton();
            return buttonByAccountId;
        }
        return buttonByAccountId;
    });

    return (
        <div className={classnames({ [styles.highlightRows]: hovered })}>
            {
                facebookProfileIds.length > 0
                && (
                    <AuthTransactionRequirementsTable
                      platformType="facebook"
                      authTransactionRequirementsByProfileId={facebookAuthTransactionRequirementsByProfileId}
                      connectButtonsByAccountId={getConnectButtonByAccountId()}
                      showOnlyBasicInsightsUseCases={showOnlyBasicInsightsUseCases}
                    />
                )
            }
            {
                instagramProfileIds.length > 0
                && (
                    <AuthTransactionRequirementsTable
                      platformType="instagram"
                      authTransactionRequirementsByProfileId={instagramAuthTransactionRequirementsByProfileId}
                      connectButtonsByAccountId={getConnectButtonByAccountId()}
                      showOnlyBasicInsightsUseCases={showOnlyBasicInsightsUseCases}
                    />
                )
            }
            {
                metaAdAccountIds.length > 0
                && (
                    <AuthTransactionRequirementsTable
                      platformType="meta"
                      authTransactionRequirementsByAdAccountId={metaAuthTransactionRequirementsByAdAccountId}
                      connectButtonsByAccountId={getConnectButtonByAccountId()}
                      showOnlyBasicInsightsUseCases={showOnlyBasicInsightsUseCases}
                    />
                )
            }
        </div>
    );
};

GraphAuthTransactionRequirementsConnect.propTypes = {
    facebookAuthUserConnectRequestAction: PropTypes.func.isRequired,
    facebookAuthUserExternalConnectRequestAction: PropTypes.func.isRequired,
    facebookAuthTransactionRequirementsByProfileId: PropTypes.objectOf(customPropTypes.profileAuthTransactionRequirements).isRequired,
    instagramAuthTransactionRequirementsByProfileId: PropTypes.objectOf(customPropTypes.profileAuthTransactionRequirements).isRequired,
    metaAuthTransactionRequirementsByAdAccountId: PropTypes.objectOf(customPropTypes.adAccountAuthTransactionRequirements).isRequired,
    authTransaction: customPropTypes.authTransaction.isRequired,
    isPublic: PropTypes.bool.isRequired,
    clearAuthUserConnectStateFromReducerAction: PropTypes.func.isRequired,
    showOnlyBasicInsightsUseCases: PropTypes.bool.isRequired
};

export default connect(null, {
    clearAuthUserConnectStateFromReducerAction: authUserActions.clearAuthUserConnectStateFromReducer,
    facebookAuthUserConnectRequestAction: graphAuthUserActions.facebookAuthUserConnectRequest,
    facebookAuthUserExternalConnectRequestAction: graphAuthUserActions.facebookAuthUserExternalConnectRequest,
})(GraphAuthTransactionRequirementsConnect);
