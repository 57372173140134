import { makeSearchQueryFilter } from 'src/selectors/accounts';
import { makePropertyExtractor, makeStringSorter } from 'src/selectors/utils';
import { createSelector } from 'reselect';

export const getAdAccountCampaignIdsFromStore = (state) => state.entities.adAccountCampaigns.allIds;
export const getAdAccountCampaignsFromStore = (state) => state.entities.adAccountCampaigns.byId;

export const makeSelectAdAccounts = () => createSelector(
    [
        getAdAccountCampaignIdsFromStore,
        getAdAccountCampaignsFromStore
    ],
    (adAccountCampaignIds, adAccountCampaigns) => adAccountCampaignIds.map((id) => adAccountCampaigns[id])
);

export const makeSelectSortedAdAccountCampaignIds = () => {
    const idsExtractor = makePropertyExtractor();
    const selectAdAccounts = makeSelectAdAccounts();
    const searchQueryFilter = makeSearchQueryFilter((items, filterQuery) => items.filter((item) => (
        item.name.toLowerCase().indexOf(filterQuery) > -1
    )));

    return createSelector(
        [
            selectAdAccounts,
            (_, filterQuery) => filterQuery
        ],
        (adAccountCampaigns, filterQuery) => {
            const sorted = searchQueryFilter(adAccountCampaigns, filterQuery).sort(makeStringSorter('name', 'asc'));
            return idsExtractor(sorted);
        }
    );
};

export const makeSelectAdAccountCampaignById = () => createSelector(
    [
        getAdAccountCampaignsFromStore,
        (_, id) => id
    ],
    (adAccountCampaigns, id) => adAccountCampaigns[id]
);
