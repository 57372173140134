import React, { Component } from 'react';
import _omit from 'lodash/omit';
import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/inputs/select.scss';

const DefaultSelect = (props) => {
    const cleanedProps = _omit(props, 'handleOpen');
    return <select {...cleanedProps} />;
};

class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        this.handleOpen = this.handleOpen.bind(this);
    }

    handleOpen(open) {
        this.setState({ open });
    }

    render() {
        const {
            layout,
            error,
            active,
            alignTextRight,
            baseComponent,
            disabled
        } = this.props;

        const { open } = this.state;

        const cleanedProps = _omit(this.props, ['error', 'layout', 'active', 'alignTextRight', 'baseComponent']);

        if (alignTextRight) {
            cleanedProps.dir = 'rtl';
        }
        const BaseComponent = baseComponent;

        return (
            <div
              className={classnames(
                  styles.wrapper,
                  { [styles.listFilter]: layout === 'listFilter' },
                  { [styles.listFilterWhite]: layout === 'listFilterWhite' },
                  { [styles.light]: layout === 'light' },
                  { [styles.analysis]: layout === 'analysis' }
              )}
            >
                <BaseComponent
                  {...cleanedProps}
                  className={classnames(styles.select, {
                      [styles.error]: error, [styles.active]: active, [styles.open]: open, [styles.disabled]: disabled
                  })}
                  handleOpen={this.handleOpen}
                />
                <div className={classnames(styles.arrow, { [styles.open]: open, [styles.disabledArrow]: disabled })}>
                    <IcomoonIcon icon="chevron-big-down" />
                </div>
            </div>
        );
    }
}

Select.propTypes = {
    layout: PropTypes.oneOf(['default', 'listFilter', 'light', 'analysis', 'listFilterWhite']),
    error: PropTypes.bool,
    active: PropTypes.bool,
    alignTextRight: PropTypes.bool,
    baseComponent: PropTypes.func,
    disabled: PropTypes.bool
};

Select.defaultProps = {
    layout: 'default',
    error: false,
    active: false,
    alignTextRight: false,
    baseComponent: DefaultSelect,
    disabled: false
};

export default Select;
