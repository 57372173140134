import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/profileAuthTransactionConnectError.scss';

const ProfileAuthTransactionConnectErrorPopover = ({ errorMessage }) => (
    <Popover id="profileAuthTransactionConnectError">
        <div className={styles.wrapper}>
            <div className={styles.header}>The following error has occurred:</div>
            <div>
                {errorMessage}
            </div>
        </div>
    </Popover>
);

ProfileAuthTransactionConnectErrorPopover.propTypes = {
    errorMessage: PropTypes.string.isRequired
};

export default ProfileAuthTransactionConnectErrorPopover;
